import React, { useState, useEffect } from "react";
import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { postAsync } from "../core/serviceClient";
import { useNavigate, Link as RouteLink } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  GridActionsCellItem,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import BusinessHelper from "../core/BusinessHelper";
import Loader from "./common/Loader";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { connectToStore } from "../data/store";
import { useDispatch } from "react-redux";
import Utils from "../core/Utils";
import { DataGrid } from "@mui/x-data-grid";
import UserHelper from "../core/UserHelper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ConfirmTypes } from "../core/Constants";
import MessagePopup from "./common/MessagePopUp";
import ConfirmPopup from "./common/ConfirmPopup";
import SideBarMenuItems from "./SideBarMenuItems";
import SettingsMenuItems from "./SettingsMenuItems";
import { SideBarMenuNames } from "../core/Constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
  AddNewConsumablePage,
  ConsumablesPage,
  LoginPage,
} from "../core/PageConstants";
import InputLabel from '@mui/material/InputLabel';

const Consumables = (props) => {
  document.title = BusinessHelper.GetConsumablesTitle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [localSettings, setLocalSettings] = useState({});
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedConsumable, setSelectedConsumable] = useState({});
  const [consumableList, setConsumableList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [originalList, setOriginalList] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [catList, setcatList] = useState([]);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfrimText] = useState("");
  const columns = [
    {
      field: "active",
      headerName: "Active",
      headerClassName: "headerStyle",
      flex: 1,
      type: "boolean",
    },
    {
      field: "shortDesc",
      headerName: "Name",
      headerClassName: "headerStyle",
      flex: 2,
      renderCell: (params) => {
        const shortDesc = params.row.shortDesc;
        const codeText = params.row.code;
        const description = params.row.description;
        
        return (
          <Stack>
            <Typography
              component="strong"
              className="px-1 card"
              title="Code">{codeText}</Typography>
            <Typography
              padding="0"
              component="h4"
              fontSize="2rem"
              fontWeight="600"
              className="mt-1 h4">
              {shortDesc}
            </Typography>
            <p className="mb-0">{description}</p>
          </Stack>
        );
      }
    },
    {
      field: "price",
      valueGetter: (params) => {
        return Utils.GetCurrency(params.row.price);
      },
      headerName: "Price",
      headerClassName: "headerStyle",
      type: "number",
      flex: 1,
    },
    {
      field: "taxPercentage",
      valueGetter: (params) => {
        return params.row.taxPercent + "%";
      },
      type: "number",
      headerName: "Tax",
      headerClassName: "headerStyle",
      flex: 1,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "headerStyle",
      width: 100,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          UserHelper.CanEdit() ? (
            <>
              <GridActionsCellItem
                icon={<EditRoundedIcon className="icon20" />}
                label="Edit"
                className="textPrimary"
                onClick={() => handleEditConsumables(row)}
                color="inherit"
              />

              <GridActionsCellItem
                icon={<DeleteOutlineIcon className="icon20" />}
                label="Delete"
                onClick={() => deleteConsumable(row)}
                color="error"
              />
            </>
          ) : (
            <GridActionsCellItem
              icon={<VisibilityIcon className="icon20" />}
              label="View"
              onClick={() => handleEditConsumables(row)}
              color="inherit"
            />
          ),
        ];
      },
    },
  ];
  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
      if (!Utils.IsNullOrEmptyObject(selectedConsumable)) {
        UserFormDelete(selectedConsumable);
      }
    }
  };
  const handleKeyUp = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleSearch();
    }
  };
  const handleEmpty = () => {
    setCategory("");
  };
  const handleEmptyDept = () => {
    setType("");
  };
  const handleRefresh = () => {
    setSearchInput("");
    getConsumables();
  };
  const handleSearch = () => {
    const filtered = originalList.filter(
      (item) =>
        item.shortDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.description.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.code.toLowerCase().includes(searchInput.toLowerCase())
    );
    setConsumableList(filtered);
  };
  const handleFilter = () => {
    let filteredone = originalList.filter((item) => {
      if (type && category) {
        return item.fineline == type && item.class == category;
      } else if (category) {
        return item.class == category;
      } else if (type) {
        return item.fineline == type;
      }
    });
    setConsumableList(filteredone);
  };
  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(ConsumablesPage, navigate, localSetting)) {
      setLocalSettings(localSetting);
    }
  }, []);

  useEffect(() => {
    getConsumables();
  }, [localSettings]);

  const getConsumables = async () => {
    if (localSettings.authenticated) {
      setIsLoading(true);
      let result1 = await postAsync(
        "Product/GetProduct",
        {},
        "",
        "GetAll",
        "GetAllProductsByBusiness",
        [localSettings.business.id]
      );
      setIsNavigateToLogin(result1.isNavigateToLogin);
      if (result1.error) {
        setMsgOpen(true);
        setMsgText(result1.errorMessage);
      } else {
        if (!Utils.IsNullOrEmptyArray(result1.data)) {
          var Consumable = result1.data.filter(
            (item) =>
              item.isMaterial == true &&
              item.service == false &&
              item.labour == false
          );

          setConsumableList(Consumable);
          setOriginalList(Consumable);
          const temTypellist = [];
          const temCatllist = [];
          Consumable.forEach((element) => {
            if (
              !Utils.IsNullOrEmpty(element.fineline) &&
              !temTypellist.includes(element.fineline)
            ) {
              temTypellist.push(element.fineline);
            }
            if (
              !Utils.IsNullOrEmpty(element.class) &&
              !temCatllist.includes(element.class)
            ) {
              temCatllist.push(element.class);
            }
          });
          setTypeList(temTypellist);
          setcatList(temCatllist);
        }
      }
      setIsLoading(false);
    }
  };
  const deleteConsumable = (Consumable) => {
    setSelectedConsumable(Consumable);
    setConfrimText(
      "Are you sure you want to delete" + Consumable.shortDesc + "?"
    );
    setConfirmOpen(true);
  };
  async function UserFormDelete(Consumable) {
    setIsLoading(true);
    let result = await postAsync(
      "Product/DeleteProduct",
      {
        Id: Consumable.id,
        product: Consumable,
        BusinessId: localSettings.business.id,
      },
      "",
      "Delete",
      "",
      []
    );
    if (result && result.data) {
      setMsgText(" Product has been deleted successfully");
      setMsgOpen(true);
      setSelectedConsumable({});
      getConsumables();
    } else {
      setMsgText("Unable to process your request");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }

  const handleChange = (event) => {
    setShowFilters(event.target.checked);
  };
  const handleEditConsumables = (row) => {
    navigate(AddNewConsumablePage.Path, {
      state: {
        selectedConsumable: row,
      },
    });
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
        />

        <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.BusinessSetup} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <SettingsMenuItems
                selectedMenu={BusinessHelper.GetConsumeableMenuName()}
              />
              <Grid xs={12} md={9}>
                <Grid spacing={2} className="form-sec xs-pd-0">
                <Stack
                    alignSelf="center"
                    justifyContent="end"
                    direction="row"
                    flex="1"
                    className="text-end mb-1"
                  >
                    <IconButton aria-label="Refresh" onClick={handleRefresh}>
                      <RefreshIcon sx={{ fontSize: 25 }} />
                    </IconButton>
                    <Box alignSelf="center" className="ml-1">
                      <Typography
                        align="auto"
                        fontSize="1.2rem"
                        style={{ "white-space": "nowrap" }}
                      >
                        Filter
                        <Switch checked={showFilters} onChange={handleChange} />
                      </Typography>
                    </Box>
                    <Grid alignSelf="center">
                    {UserHelper.CanAdd() && (
                      <RouteLink to={AddNewConsumablePage.Path}>
                        <Button
                          variant="contained"
                          className="btn btn-sm btn-primary"
                        >
                          <AddCircleOutlineOutlinedIcon
                            fontSize="large"
                            sx={{marginRight: "6px"}}
                          />
                          <span>Add New</span>
                        </Button>
                      </RouteLink>
                    )}
                  </Grid>
                </Stack>
                {showFilters && (
                  <Stack sx={{ minWidth: 120 }} className="mb-1">
                    <Stack
                      direction="row"
                      spacing={1}
                      alignContent="space-between"
                      className="font-size-14"
                    >
                      <FormControl fullWidth>
                        <Stack direction="row" justifyContent="space-between">
                          <IconButton onClick={handleEmpty} className="close-icon" title="Clear">
                            <CloseIcon 
                              fontSize="large"  />
                          </IconButton>
                          <InputLabel id="catLabel">Category</InputLabel>
                        </Stack>
                        <Select
                          label="Category"
                          variant="outlined"
                          value={category}
                          sx={{fontSize: "1.5rem"}}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          {!Utils.IsNullOrEmptyArray(catList) &&
                            catList.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <Stack direction="row" justifyContent="space-between">
                          <IconButton onClick={handleEmptyDept} className="close-icon" title="Clear">
                            <CloseIcon 
                              fontSize="large"  />
                          </IconButton>
                          <InputLabel id="typeLabel">Type</InputLabel>
                        </Stack>
                        <Select
                          label="Type"
                          variant="outlined"
                          value={type}
                          sx={{fontSize: "1.5rem"}}
                          onChange={(e) => setType(e.target.value)}
                        >
                          {!Utils.IsNullOrEmptyArray(typeList) &&
                            typeList.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <Stack>
                        <Button
                          variant="contained"
                          className="btn btn-primary h-100"
                          onClick={handleFilter}
                          style={{
                            maxWidth: "40px",
                          }}
                        >
                          Filter
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                )}
                <Grid item xs={12} className="font-size-14 data-grid-box">
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <DataGrid
                      getRowId={(row) => row.id}
                      rows={consumableList}
                      columns={columns}
                      disableColumnFilter={true}
                      disableColumnMenu={true}
                      rowSelection={false}
                      sx={{
                        boxShadow: 1,
                        border: 1,
                        borderColor: "primary.light",
                        "& .MuiDataGrid-cell:hover": {
                          color: "primary.main",
                        },
                      }}
                      slots={{ toolbar: CustomToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                          showFilters: false,
                        },
                      }}
                      className="data-grid"
                    />
                  </Box>
                </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter className="searchbar" />
    </GridToolbarContainer>
  );
}

export default Consumables;
