import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Utils from "../core/Utils";
import { Container } from "@mui/material";
import { postAsync, getToken } from "../core/serviceClient";
import { setIsDarkMode, setLogin, setToken } from "../data/localSettingsActions";
import AppSettings from "../appsettings.json";
import { CanUseWebApp } from "../core/ModuleHelper";
import { connectToStore } from "../data/store";
import { LoginPage, OverviewPage } from "../core/PageConstants";
import UserHelper from "../core/UserHelper";

function Landing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [navigateToLogin, setNavigateToLogin] = useState(false);

  useEffect(() => {
    const asyncFn = async () => {
      let localSettings = connectToStore();

      if (Utils.IsNullOrEmptyObject(localSettings)) {
        setNavigateToLogin(true);
        return;
      }
      setIsDarkMode(false, dispatch);
      var tokenResponse = await getToken();
      if (tokenResponse.error) {
        setNavigateToLogin(true);
        return;
      }
      setToken(tokenResponse.data.token, dispatch);

      if (Utils.IsNullOrEmpty(localSettings.user.businessId) || Utils.IsNullOrEmpty(localSettings.user.countryCode) || Utils.IsNullOrEmpty(localSettings.user.id)) {
        setNavigateToLogin(true);
        return;
      }

      var request = {
        BusinessId: localSettings.user.businessId,
        CountryCode: localSettings.user.countryCode,
        LoginId: localSettings.user.id,
        LoggedInWith: localSettings.loggedInWith,
        ProductsDT: localSettings.productsDT,
        PublicKey: AppSettings.PublicKey,
        PublicKeyType: localSettings.publicKeyType,
        Secret: AppSettings.Secret,
        Token: tokenResponse.data.token,
        IsWebApp: true
      };
      let response = await postAsync("Account/Login", request);
      if (response.error) {
        setNavigateToLogin(true);
        return;
      }
      if (!CanUseWebApp(response.data.businessEntityConfig)) {
        setNavigateToLogin(true);
        return;
      }
      setLogin(response.data, dispatch);
      navigate(OverviewPage.Path);
    };
    asyncFn();
  }, []);

  useEffect(() => {
    login();
  }, [navigateToLogin]);

  const login = () => {
    if (navigateToLogin) {
      UserHelper.LogOut(dispatch);
      var preferredAuthMode = localStorage.getItem("preferredAuthMode");
      localStorage.clear();
      localStorage.setItem("preferredAuthMode", preferredAuthMode);
      navigate(LoginPage.Path);
    }
  };

  return (
    <Container maxWidth="full" className="main" sx={{ display: "flex" }}>
      <div id="indexLoading">
        <p>
          <img src="logo192.png" alt="Infopoint" />
        </p>
      </div>
    </Container>
  );
}

export default Landing;
