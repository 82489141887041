import React, { useEffect, useState } from "react";
import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Stack, ImageList, ImageListItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";

import Utils from "../core/Utils";
import { connectToStore } from "../data/store";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import SideBarMenuItems from "./SideBarMenuItems";
import { ServiceLocationTypes, SideBarMenuNames } from "../core/Constants";
import {
  getBranch,
  getBusiness,
  getCountry,
  getUser,
} from "../data/localSettingsActions";
import moment from "moment";
import {
  getConsumables,
  getLaboursOrServices,
  getSpares,
} from "../helpers/productHelper";
import UserHelper from "../core/UserHelper";
import ConfirmPopup from "./common/ConfirmPopup";
import {
  AMCViewPlanPage,
  DeliveredJobCardDetailsPage,
  JobCardDetailsPage,
  VisitsPage,
} from "../core/PageConstants";
import { ImageEnlarge } from "./common/ImageEnlarge";
import CountryHelper from "../core/CountryHelper";

const DeliveredJobCardDetails = ({ children }) => {
  document.title = "Jobcard Details";
  const navigate = useNavigate();
  const [enlargeImage, setEnlargeImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const localSettings = connectToStore();
  const location = useLocation();
  const [countryInfo, setCountryInfo] = useState({});
  const [branch, setBranch] = useState({});
  const [business, setBusiness] = useState({});
  const [visit, setVisit] = useState({});
  const [isProfitVisible, setIsProfitVisible] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");
  const [spares, setSpares] = useState([]);
  const [services, setServices] = useState([]);
  const [consumables, setConsumables] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [spareTotal, setSpareTotal] = useState(0);
  const [serviceTotal, setServiceTotal] = useState(0);
  const [consumableTotal, setConsumableTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [tips, setTips] = useState(0);
  const [tax, setTax] = useState(0);
  const [isAMCVeiwPlan, setIsAMCVeiwPlan] = useState(false);

  //Vehicle hooks
  const [vehicleNumber, setVehileNumber] = useState("");
  const [makeModel, setMakeModel] = useState("");
  const [chassisNumber, setChasisNumber] = useState("");
  const [mileage, setMileage] = useState(0);
  const [vehicleDetails, setVehicleDetails] = useState({});

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");

  useEffect(() => {
    populateVehicleDetails();
  }, [vehicleDetails]);

  const populateVehicleDetails = () => {
    if (Utils.IsNullOrEmptyObject(vehicleDetails)) {
      return;
    }
    setVehileNumber(vehicleDetails.number);
    setMakeModel(
      Utils.ConvertToUpperCase(vehicleDetails.make) +
      " " +
      Utils.ConvertToUpperCase(vehicleDetails.model)
    );
    setChasisNumber(vehicleDetails.chassisNumber);
    var newMileage =
      vehicleDetails.kmDrivenOut >= vehicleDetails.kmDriven
        ? vehicleDetails.kmDrivenOut
        : vehicleDetails.kmDriven;
    setMileage(newMileage);
  };

  //Service Locations Hooks
  const [jobcardOpenedDT, setJobcardOpenedDT] = useState("");
  const [jobcardClosedDT, setJobcardClosedDT] = useState("");
  const [serviceLocations, setServiceLocations] = useState([]);

  useEffect(() => {
    populateServiceLocations();
  }, [serviceLocations, countryInfo]);

  const populateServiceLocations = () => {
    if (
      Utils.IsNullOrEmptyArray(serviceLocations) ||
      Utils.IsNullOrEmptyObject(countryInfo)
    ) {
      return;
    }
    var startLocation = serviceLocations.filter(
      (c) => c.serviceLocationType === ServiceLocationTypes.ServiceStartLocation
    )[0];
    if (!Utils.IsNullOrEmptyObject(startLocation)) {
      setJobcardOpenedDT(
        Utils.ConvertToLocalDT(startLocation.capturedDT, countryInfo)
      );
    }
    var endLocation = serviceLocations.filter(
      (c) => c.serviceLocationType === ServiceLocationTypes.ServiceEndLocation
    )[0];
    if (!Utils.IsNullOrEmptyObject(endLocation)) {
      setJobcardClosedDT(
        Utils.ConvertToLocalDT(endLocation.capturedDT, countryInfo)
      );
    }
  };

  //Service details hooks
  const [serviceAdvisor, setServiceAdvisor] = useState("");
  const [paymentReceivedBy, setPaymentReceivedBy] = useState("");
  const [jobcardNumber, setJobcardNumber] = useState("");
  const [nextServiceOn, setNextServiceOn] = useState("");
  const [daysRemaining, setDaysRemaining] = useState(0);
  const [serviceItemsCount, setServiceItemsCount] = useState(0);
  const [serviceDetails, setServiceDetails] = useState({});
  const [vehicleImagesCount, setVehicleImagesCount] = useState(0);
  const [vehicleImageUrls, setVehicleImageUrls] = useState([]);
  const [technicians, setTechnicians] = useState([]);

  useEffect(() => {
    populateServiceDetails();
  }, [serviceDetails]);

  const populateServiceDetails = () => {
    if (Utils.IsNullOrEmptyObject(serviceDetails)) {
      return;
    }
    setServiceAdvisor(serviceDetails.technicianName);
    var convertedDT = moment(serviceDetails.nextServiceOn).local();
    var remainingDays = convertedDT.diff(moment(), "days");
    setDaysRemaining(remainingDays < 0 ? 0 : remainingDays);
    setNextServiceOn(moment(serviceDetails.nextServiceOn).format("DD-MM-YYYY"));
    if (!Utils.IsNullOrEmptyArray(serviceDetails.imageUrls)) {
      setVehicleImagesCount(serviceDetails.imageUrls.length);
    }
    setVehicleImageUrls(serviceDetails.imageUrls);
    setTechnicians(serviceDetails.technicians);
  };

  useEffect(() => {
    if (UserHelper.CheckPermission(DeliveredJobCardDetailsPage, navigate)) {
      if (!UserHelper.IsStateValid(location.state, navigate))
        return;

      var visitDetails = location.state.visit;
      setIsAMCVeiwPlan(location.state.isFromAMCViewPlan);
      if (Utils.IsNullOrEmptyObject(visitDetails)) {
        navigate(VisitsPage.Path, {
          state: {
            ...location.state,
            navigationStack: Utils.RemoveLastElementFromArray(
              location.state.navigationStack
            ),
            isProfitVisible: location.state.isProfitVisible,
            isUnPaid: location.state.isUnPaid,
            withoutDateRange: location.state.withoutDateRange,
            selectedCategory: location.state.selectedCategory,
            selectedPaymentTypes: location.state.selectedPaymentTypes,
            startDT: location.state.startDT,
            endDT: location.state.endDT,
            searchText:
              location.state && location.state.searchText
                ? location.state.searchText
                : "",
          },
        });
        return;
      }
      var country = getCountry();
      setCountryInfo(country);
      setBranch(getBranch());
      setBusiness(getBusiness());
      setVehicleDetails(visitDetails.vehicleDetails);
      setServiceDetails(visitDetails.vehicleServiceDetails);
      setServiceLocations(visitDetails.serviceLocations);
      setVisit(visitDetails);
      setPaymentReceivedBy(visitDetails.paymentReceivedBy);
      setJobcardNumber(visitDetails.jobcardNumber);
      setServiceItemsCount(visitDetails.serviceItems.length);
      setInvoiceNumber(visitDetails.invoiceNumber);
      setInvoiceDate(Utils.ConvertToLocalDT(visitDetails.checkOut, country));
      var paidVia =
        visitDetails.upiPayment > 0
          ? "Upi"
          : visitDetails.cashPayment > 0
            ? "Cash"
            : visitDetails.cardPayment > 0
              ? "Card"
              : visitDetails.netBankingPayment > 0
                ? "Net Banking"
                : "";
      setPaymentDescription("Paid with " + paidVia);
      setSpares(getSpares(visitDetails.serviceItems, true));
      setServices(getLaboursOrServices(visitDetails.serviceItems, true));
      setConsumables(getConsumables(visitDetails.serviceItems, true));
      setTax(visitDetails.tax);
      setTips(visitDetails.tip);
      setDiscount(visitDetails.discount);
      setSubTotal(
        visitDetails.serviceItems
          .map((c) => c.totalPrice)
          .reduce((a, b) => a + b)
      );
    }
  }, []);

  useEffect(() => {
    calculateSpareTotal();
  }, [spares]);

  const calculateSpareTotal = () => {
    if (Utils.IsNullOrEmptyArray(spares)) {
      setSpareTotal(0);
      return;
    }
    setSpareTotal(spares.map((r) => r.totalPrice).reduce((a, b) => a + b));
  };

  useEffect(() => {
    calculateServiceTotal();
  }, [services]);

  const calculateServiceTotal = () => {
    if (Utils.IsNullOrEmptyArray(services)) {
      setServiceTotal(0);
      return;
    }
    setServiceTotal(services.map((r) => r.totalPrice).reduce((a, b) => a + b));
  };

  useEffect(() => {
    calculateConsumableTotal();
  }, [consumables]);

  const calculateConsumableTotal = () => {
    if (Utils.IsNullOrEmptyArray(consumables)) {
      setConsumableTotal(0);
      return;
    }
    setConsumableTotal(
      consumables.map((r) => r.totalPrice).reduce((a, b) => a + b)
    );
  };

  var pageTitle = "Jobcard Details";

  const openJobcard = () => {
    var m = getUser().maskCustomerDetails ? 1 : 0;
    var culture = CountryHelper.GetAPICulture(countryInfo.Culture);
    var url =
      Utils.GetServiceUrl() +
      "home/Jobcard?culture=" +
      culture +
      "&bid=" +
      business.id +
      "&vid=" +
      visit.id +
      "&estimate=false&m=" +
      m;
    window.open(encodeURI(url));
  };

  const editJobcardConfirm = async () => {
    setConfirmText(
      "Editing a closed invoice will reset payment history and may not reconcile with online payments received from your end. Are you sure you want to continue?"
    );
    setConfirmOpen(true);
  }

  const editJobcard = () => {
    navigate(JobCardDetailsPage.Path, {
      state: {
        ...location.state,
        navigationStack: Utils.AddElementToArray(
          location.state.navigationStack,
          JobCardDetailsPage.Path
        ),
        isEdit: true,
        fromVisit: true,
        visit: visit,
        searchText:
          location.state && location.state.searchText
            ? location.state.searchText
            : "",
        userList: location.state.userList
      },
    });
  };

  const handleConfirmClose = async (value) => {
    if (value == "OK") {
      editJobcard();
    }
    else {
      setConfirmOpen(false);
    }
  };

  //   Back
  const goBack = () => {
    if (!UserHelper.IsStateValid(location.state, navigate))
      return;
    if (isAMCVeiwPlan === true) {
      navigate(AMCViewPlanPage.Path, {
        state: {
          ...location.state,
          navigationStack: Utils.RemoveLastElementFromArray(
            location.state.navigationStack
          ),
        },
      });
    } else {
      navigate(VisitsPage.Path, {
        state: {
          ...location.state,
          navigationStack: Utils.RemoveLastElementFromArray(
            location.state.navigationStack
          ),
          isProfitVisible: location.state.isProfitVisible,
          isUnPaid: location.state.isUnPaid,
          withoutDateRange: location.state.withoutDateRange,
          selectedCategory: location.state.selectedCategory,
          selectedPaymentTypes: location.state.selectedPaymentTypes,
          startDT: location.state.startDT,
          endDT: location.state.endDT,
          searchText:
            location.state && location.state.searchText
              ? location.state.searchText
              : "",
        },
      });
    }
  };

  const closeImage = () => {
    setEnlargeImage(false);
    setImageUrl("");
  };

  const openImage = (url) => {
    setImageUrl(url);
    setEnlargeImage(true);
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <ImageEnlarge
          open={enlargeImage}
          imgSrc={imageUrl}
          onClose={closeImage}
        />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
          okText="Yes"
          cancelText="No"
          className="warning-modal"
        />
        <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={isAMCVeiwPlan === false ? SideBarMenuNames.Overview : SideBarMenuNames.Customers} />
            <Grid xs={12} className="content-sec">
              <Grid container direction="row" className="mt-2">
                <Stack
                  direction="row"
                  spacing={2}
                  className="justify-content-between mb-2"
                  style={{ width: "100%" }}
                >
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="1.6rem"
                  >
                    <Button onClick={() => goBack()} className="back-btn">
                      <NavigateBeforeIcon />
                    </Button>
                    {pageTitle}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{ "align-self": "center" }}
                  >
                    <Button
                      variant="outlined"
                      className="btn btn-secondary white-text"
                      onClick={openJobcard}
                    >
                      Print
                    </Button>
                    <Button
                      variant="outlined"
                      className="btn btn-secondary white-text"
                      onClick={editJobcardConfirm}
                    >
                      Edit
                    </Button>
                  </Stack>
                </Stack>
                <Typography
                  level="h2"
                  component="h2"
                  fontWeight="600"
                  fontSize="2rem"
                  className="page-title"
                >
                  Jobcard Details
                </Typography>
                <Grid container xs={12} className="mt-2">
                  <Grid container xs={12}>
                    <Grid xs={6}>
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="1.5rem"
                        className="section-title text-center"
                      >
                        Customer Details
                      </Typography>
                      <Stack spacing={1} padding={2}>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Customer Id : &nbsp;
                          </Typography>
                          {location.state &&
                            location.state.visit &&
                            location.state.visit.customerId}
                        </Typography>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Customer Name : &nbsp;
                          </Typography>
                          {location.state &&
                            location.state.visit &&
                            location.state.visit.customerName}
                        </Typography>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Location : &nbsp;
                          </Typography>
                          {location.state &&
                            location.state.visit &&
                            location.state.visit.shippingToAddress &&
                            location.state.visit.shippingToAddress.address}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid xs={6}>
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="1.5rem"
                        className="section-title text-center"
                      >
                        Vehicle Details
                      </Typography>
                      <Stack spacing={1} padding={2}>
                        <Typography
                          level="strong"
                          component="strong"
                          fontSize="1.5rem"
                          fontWeight="600"
                        >
                          {makeModel}
                        </Typography>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Chassis No : &nbsp;
                          </Typography>
                          {chassisNumber}
                        </Typography>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Milage : &nbsp;
                          </Typography>
                          {mileage}
                        </Typography>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Number : &nbsp;
                          </Typography>
                          {vehicleNumber}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container xs={12}>
                    <Grid xs={6}>
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="1.5rem"
                        className="section-title text-center"
                      >
                        Service Details
                      </Typography>
                      <Stack spacing={1} padding={2}>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Service Advisor : &nbsp;
                          </Typography>
                          {serviceAdvisor}
                        </Typography>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Payment Received By : &nbsp;
                          </Typography>
                          {location.state &&
                            location.state.visit &&
                            location.state.visit.paymentReceivedBy}
                        </Typography>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Jobcard No : &nbsp;
                          </Typography>
                          {jobcardNumber}
                        </Typography>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Jobcard Opened DT : &nbsp;
                          </Typography>
                          {jobcardOpenedDT}
                        </Typography>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Jobcard Closed DT : &nbsp;
                          </Typography>
                          {jobcardClosedDT}
                        </Typography>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Next Service On : &nbsp;
                          </Typography>
                          {nextServiceOn}
                        </Typography>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Days Remaining For Next Service : &nbsp;
                          </Typography>
                          {daysRemaining}
                        </Typography>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Service Items : &nbsp;
                          </Typography>
                          {serviceItemsCount}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid xs={6}>
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="1.5rem"
                        className="section-title text-center"
                      >
                        Invoice Details
                      </Typography>
                      <Stack spacing={1} padding={2}>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Invoice No : &nbsp;
                          </Typography>
                          {invoiceNumber}
                        </Typography>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Invoice Date : &nbsp;
                          </Typography>
                          {invoiceDate}
                        </Typography>
                        <Typography level="p" component="p" fontSize="1.5rem">
                          <Typography
                            level="strong"
                            component="strong"
                            fontSize="1.5rem"
                            fontWeight="600"
                          >
                            Total : &nbsp;
                          </Typography>
                          {Utils.IsNullOrEmptyObject(visit) ||
                            Utils.IsNullOrEmptyObject(countryInfo)
                            ? ""
                            : Utils.GetCurrency(
                              visit.paymentAmount,
                              countryInfo
                            ) +
                            " " +
                            paymentDescription}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>

                  {/* Technicians */}
                  <Grid xs={12}>
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="600"
                      fontSize="1.5rem"
                      className="section-title text-center"
                    >
                      Technicians
                    </Typography>
                    {!Utils.IsNullOrEmptyArray(technicians) && (
                      <Stack spacing={2} padding={2} direction="row">
                        {technicians.map((tech) => (
                          <Typography
                            level="h4"
                            component="h4"
                            fontWeight="600"
                            fontSize="1.5rem"
                            className="text-left"
                          >
                            {tech.name}
                          </Typography>
                        ))}
                      </Stack>
                    )}
                  </Grid>

                  {/* Vehicle Images */}
                  {!Utils.IsNullOrEmptyArray(vehicleImageUrls) && (
                    <Grid xs={12}>
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="1.5rem"
                        className="section-title text-center"
                      >
                        Vehicle Images
                      </Typography>
                      <ImageList
                        sx={{ width: 500, height: 450 }}
                        cols={3}
                        rowHeight={164}
                      >
                        {vehicleImageUrls.map((url) => (
                          <ImageListItem key={url}>
                            <img
                              onClick={() => openImage(url)}
                              src={url}
                              srcSet={url}
                              alt="vehicle image"
                              loading="lazy"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Grid>
                  )}

                  {/* Spares */}
                  {!Utils.IsNullOrEmptyArray(spares) && (
                    <Grid xs={12}>
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="1.5rem"
                        className="section-title text-center"
                      >
                        Spares
                      </Typography>
                      {spares.map((spare) => (
                        <Grid xs={12}>
                          <Grid
                            container
                            xs={12}
                            spacing={1}
                            padding={2}
                            direction="row"
                          >
                            <Grid xs={8}>
                              <Typography
                                level="p"
                                component="p"
                                fontSize="1.5rem"
                                fontWeight="500"
                              >
                                {spare.description}
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              <Typography
                                level="p"
                                component="p"
                                fontSize="1.5rem"
                                fontWeight="500"
                                className="text-right"
                              >
                                {spare.quantity +
                                  " x " +
                                  Utils.GetCurrency(spare.price, countryInfo)}
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              <Typography
                                level="p"
                                component="p"
                                fontSize="1.5rem"
                                fontWeight="500"
                                className="text-right"
                              >
                                {Utils.GetCurrency(spare.totalPrice, countryInfo)}
                              </Typography>
                            </Grid>
                          </Grid>
                          {spare.refundPrice > 0 && (
                            <Grid
                              container
                              xs={12}
                              spacing={1}
                              padding={2}
                              direction="row"
                            >
                              <Grid xs={8}>
                                <Typography
                                  level="h2"
                                  component="h2"
                                  fontSize="1.5rem"
                                  fontWeight="600"
                                >
                                  {spare.description}
                                </Typography>
                              </Grid>
                              <Grid xs={2}>
                                <Typography
                                  level="h4"
                                  component="h4"
                                  fontSize="1.5rem"
                                  fontWeight="500"
                                  className="text-right"
                                >
                                  *** Refund ***
                                </Typography>
                              </Grid>
                              <Grid xs={2}>
                                <Typography
                                  level="h4"
                                  component="h4"
                                  fontSize="1.5rem"
                                  fontWeight="500"
                                  className="text-right"
                                >
                                  -
                                  {Utils.GetCurrency(
                                    spare.refundPrice,
                                    countryInfo
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {spare.refundPrice === 0 &&
                            !Utils.IsNullOrEmptyArray(spare.discounts) &&
                            spare.discounts.map((discount) => (
                              <Grid
                                container
                                xs={12}
                                spacing={1}
                                padding={2}
                                direction="row"
                              >
                                <Grid xs={8}>
                                  <Typography
                                    level="h2"
                                    component="h2"
                                    fontSize="1.5rem"
                                    fontWeight="600"
                                  >
                                    {discount.description}
                                  </Typography>
                                </Grid>
                                <Grid xs={2}>
                                  <Typography
                                    level="h4"
                                    component="h4"
                                    fontSize="1.5rem"
                                    fontWeight="500"
                                    className="text-right"
                                  >
                                    {discount.descriptionDetail}
                                  </Typography>
                                </Grid>
                                <Grid xs={2}>
                                  <Typography
                                    level="h4"
                                    component="h4"
                                    fontSize="1.5rem"
                                    fontWeight="500"
                                    className="text-right"
                                  >
                                    -
                                    {Utils.GetCurrency(
                                      discount.discountTotal,
                                      countryInfo
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  {/* Services */}
                  {!Utils.IsNullOrEmptyArray(services) && (
                    <Grid xs={12}>
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="1.5rem"
                        className="section-title text-center"
                      >
                        Services
                      </Typography>
                      {services.map((spare) => (
                        <Grid xs={12}>
                          <Grid
                            container
                            xs={12}
                            spacing={1}
                            padding={2}
                            direction="row"
                          >
                            <Grid xs={8}>
                              <Typography
                                level="p"
                                component="p"
                                fontSize="1.5rem"
                                fontWeight="500"
                              >
                                {spare.description}
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              <Typography
                                level="p"
                                component="p"
                                fontSize="1.5rem"
                                fontWeight="500"
                                className="text-right"
                              >
                                {spare.quantity +
                                  " x " +
                                  Utils.GetCurrency(spare.price, countryInfo)}
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              <Typography
                                level="p"
                                component="p"
                                fontSize="1.5rem"
                                fontWeight="500"
                                className="text-right"
                              >
                                {Utils.GetCurrency(spare.totalPrice, countryInfo)}
                              </Typography>
                            </Grid>
                          </Grid>
                          {spare.refundPrice > 0 && (
                            <Grid
                              container
                              xs={12}
                              spacing={1}
                              padding={2}
                              direction="row"
                            >
                              <Grid xs={8}>
                                <Typography
                                  level="h2"
                                  component="h2"
                                  fontSize="1.5rem"
                                  fontWeight="600"
                                >
                                  {spare.description}
                                </Typography>
                              </Grid>
                              <Grid xs={2}>
                                <Typography
                                  level="h4"
                                  component="h4"
                                  fontSize="1.5rem"
                                  fontWeight="500"
                                  className="text-right"
                                >
                                  *** Refund ***
                                </Typography>
                              </Grid>
                              <Grid xs={2}>
                                <Typography
                                  level="h4"
                                  component="h4"
                                  fontSize="1.5rem"
                                  fontWeight="500"
                                  className="text-right"
                                >
                                  -
                                  {Utils.GetCurrency(
                                    spare.refundPrice,
                                    countryInfo
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {spare.refundPrice === 0 &&
                            !Utils.IsNullOrEmptyArray(spare.discounts) &&
                            spare.discounts.map((discount) => (
                              <Grid
                                container
                                xs={12}
                                spacing={1}
                                padding={2}
                                direction="row"
                              >
                                <Grid xs={8}>
                                  <Typography
                                    level="h2"
                                    component="h2"
                                    fontSize="1.5rem"
                                    fontWeight="600"
                                  >
                                    {discount.description}
                                  </Typography>
                                </Grid>
                                <Grid xs={2}>
                                  <Typography
                                    level="h4"
                                    component="h4"
                                    fontSize="1.5rem"
                                    fontWeight="500"
                                    className="text-right"
                                  >
                                    {discount.descriptionDetail}
                                  </Typography>
                                </Grid>
                                <Grid xs={2}>
                                  <Typography
                                    level="h4"
                                    component="h4"
                                    fontSize="1.5rem"
                                    fontWeight="500"
                                    className="text-right"
                                  >
                                    -
                                    {Utils.GetCurrency(
                                      discount.discountTotal,
                                      countryInfo
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  {/* Consumables */}
                  {!Utils.IsNullOrEmptyArray(consumables) && (
                    <Grid xs={12}>
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="1.5rem"
                        className="section-title text-center"
                      >
                        Consumables
                      </Typography>
                      {consumables.map((spare) => (
                        <Grid xs={12}>
                          <Grid
                            container
                            xs={12}
                            spacing={1}
                            padding={2}
                            direction="row"
                          >
                            <Grid xs={8}>
                              <Typography
                                level="p"
                                component="p"
                                fontSize="1.5rem"
                                fontWeight="500"
                              >
                                {spare.description}
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              <Typography
                                level="p"
                                component="p"
                                fontSize="1.5rem"
                                fontWeight="500"
                                className="text-right"
                              >
                                {spare.quantity +
                                  " x " +
                                  Utils.GetCurrency(spare.price, countryInfo)}
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              <Typography
                                level="p"
                                component="p"
                                fontSize="1.5rem"
                                fontWeight="500"
                                className="text-right"
                              >
                                {Utils.GetCurrency(spare.totalPrice, countryInfo)}
                              </Typography>
                            </Grid>
                          </Grid>
                          {spare.refundPrice > 0 && (
                            <Grid
                              container
                              xs={12}
                              spacing={1}
                              padding={2}
                              direction="row"
                            >
                              <Grid xs={8}>
                                <Typography
                                  level="h2"
                                  component="h2"
                                  fontSize="1.5rem"
                                  fontWeight="600"
                                >
                                  {spare.description}
                                </Typography>
                              </Grid>
                              <Grid xs={2}>
                                <Typography
                                  level="h4"
                                  component="h4"
                                  fontSize="1.5rem"
                                  fontWeight="500"
                                  className="text-right"
                                >
                                  *** Refund ***
                                </Typography>
                              </Grid>
                              <Grid xs={2}>
                                <Typography
                                  level="h4"
                                  component="h4"
                                  fontSize="1.5rem"
                                  fontWeight="500"
                                  className="text-right"
                                >
                                  -
                                  {Utils.GetCurrency(
                                    spare.refundPrice,
                                    countryInfo
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {spare.refundPrice === 0 &&
                            !Utils.IsNullOrEmptyArray(spare.discounts) &&
                            spare.discounts.map((discount) => (
                              <Grid
                                container
                                xs={12}
                                spacing={1}
                                padding={2}
                                direction="row"
                              >
                                <Grid xs={8}>
                                  <Typography
                                    level="h2"
                                    component="h2"
                                    fontSize="1.5rem"
                                    fontWeight="600"
                                  >
                                    {discount.description}
                                  </Typography>
                                </Grid>
                                <Grid xs={2}>
                                  <Typography
                                    level="h4"
                                    component="h4"
                                    fontSize="1.5rem"
                                    fontWeight="500"
                                    className="text-right"
                                  >
                                    {discount.descriptionDetail}
                                  </Typography>
                                </Grid>
                                <Grid xs={2}>
                                  <Typography
                                    level="h4"
                                    component="h4"
                                    fontSize="1.5rem"
                                    fontWeight="500"
                                    className="text-right"
                                  >
                                    -
                                    {Utils.GetCurrency(
                                      discount.discountTotal,
                                      countryInfo
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  {/* Summary */}
                  <Grid xs={12}>
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="600"
                      fontSize="1.5rem"
                      className="section-title text-center"
                    >
                      Summary
                    </Typography>
                    <Stack spacing={1} padding={2} direction="column">
                      <Typography level="p" component="p" fontSize="1.5rem">
                        <Typography
                          level="strong"
                          component="strong"
                          fontSize="1.5rem"
                          fontWeight="600"
                        >
                          Sub Total : &nbsp;
                        </Typography>
                        {Utils.GetCurrency(subTotal, countryInfo)}
                      </Typography>
                      <Typography level="p" component="p" fontSize="1.5rem">
                        <Typography
                          level="strong"
                          component="strong"
                          fontSize="1.5rem"
                          fontWeight="600"
                        >
                          Spares Total : &nbsp;
                        </Typography>
                        {Utils.GetCurrency(spareTotal, countryInfo)}
                      </Typography>
                      <Typography level="p" component="p" fontSize="1.5rem">
                        <Typography
                          level="strong"
                          component="strong"
                          fontSize="1.5rem"
                          fontWeight="600"
                        >
                          Services Total : &nbsp;
                        </Typography>
                        {Utils.GetCurrency(serviceTotal, countryInfo)}
                      </Typography>
                      <Typography level="p" component="p" fontSize="1.5rem">
                        <Typography
                          level="strong"
                          component="strong"
                          fontSize="1.5rem"
                          fontWeight="600"
                        >
                          Consumables Total : &nbsp;
                        </Typography>
                        {Utils.GetCurrency(consumableTotal, countryInfo)}
                      </Typography>
                      <Typography level="p" component="p" fontSize="1.5rem">
                        <Typography
                          level="strong"
                          component="strong"
                          fontSize="1.5rem"
                          fontWeight="600"
                        >
                          Discount : &nbsp;
                        </Typography>
                        {Utils.GetCurrency(discount, countryInfo)}
                      </Typography>
                      <Typography level="p" component="p" fontSize="1.5rem">
                        <Typography
                          level="strong"
                          component="strong"
                          fontSize="1.5rem"
                          fontWeight="600"
                        >
                          Tax : &nbsp;
                        </Typography>
                        {Utils.GetCurrency(tax, countryInfo)}
                      </Typography>
                      <Typography level="p" component="p" fontSize="1.5rem">
                        <Typography
                          level="strong"
                          component="strong"
                          fontSize="1.5rem"
                          fontWeight="600"
                        >
                          Tips : &nbsp;
                        </Typography>
                        {Utils.GetCurrency(tips, countryInfo)}
                      </Typography>
                      <Typography
                        level="h2"
                        component="h2"
                        fontSize="1.5rem"
                        fontWeight="600"
                      >
                        <Typography
                          level="strong"
                          component="strong"
                          fontSize="1.5rem"
                          fontWeight="600"
                        >
                          Grand Total : &nbsp;
                        </Typography>
                        <Typography
                          level="strong"
                          component="strong"
                          fontWeight="600"
                          fontSize="24px"
                        >
                          {Utils.GetCurrency(
                            Utils.IsNullOrEmptyObject(visit)
                              ? 0
                              : visit.paymentAmount,
                            countryInfo
                          )}
                        </Typography>
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default DeliveredJobCardDetails;
