import React, { useState } from "react";
import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";
import {
  TextField,
  Container,
  Grid,
  Stack,
  FormControl,
  Typography,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Box,
  Card,
  FormGroup,
  Button,
  Modal,
  InputLabel,
  IconButton
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { connectToStore } from "../data/store";
import { SideBarMenuNames } from "../core/Constants";
import SideBarMenuItems from "./SideBarMenuItems";
import Utils from "../core/Utils";
import { getCountry, getEnterpriseId } from "../data/localSettingsActions";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from "@mui/icons-material/Close";

// Images
import Email_Img from "../images/email.png";
import PushNotification_Img from "../images/pushNotification.png";
import SMS_Img from "../images/sms.png";
import Insta_Img from "../images/insta.png";
import Preview_Img from "../images/preview-img.png";

// Datepicker
import "../DateRangePickerWithYear.css";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import CountryHelper from "../core/CountryHelper";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Label } from "@mui/icons-material";

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

const AddNewCampaign = () => {
  document.title = "New Campaign";

  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);

  const [campTypeValue, setCampTypeValue] = React.useState('Ad');
  const [campGetTypeValue, setCampGetTypeValue] = React.useState('Cash');
  const [campContentTypeValue, setCampContentTypeValue] = React.useState('Coupon Content');
  const [buyCountValue, setBuyCountValue] = React.useState(0);

  const handleCampaignType = (event) => {
    setCampTypeValue(event.target.value);
  };

  const handleCampaignGetType = (event) => {
    setCampGetTypeValue(event.target.value);
  };

  const handleCampaignContentType = (event) => {
    setCampContentTypeValue(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
    <Container maxWidth="false" className="bg-color p-0 setting-page">
      <Grid className="container">
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Campaigns} />
          <Grid xs={12} className="content-sec" style={{ "padding-bottom": "140px" }}>
            <Stack mt={3} direction="row" spacing={1}>
              <TextField
                label="New Campaign"
                className="page-title"
                placeholder="Enter campaign name"
                InputLabelProps={{ shrink: true }}
                multiline="true"
                sx={{ flex: 1 }}
              />
              <Box className="card" sx={{ "width": "234px", "padding": "8px", "border-color": "rgba(0, 0, 0, 0.23)", "text-align": "center" }}>
                <DateRangePicker
                  showLeadingZeros={true}
                  maxDate={new Date()}
                  value={selectedDate}
                  locale={CountryHelper.GetDateCulture(getCountry().Culture)}
                  clearIcon={null}
                  calendarIcon={
                    <CalendarMonthOutlinedIcon
                      style={{
                        "font-size": "24px",
                        "align-self": "center",
                        color: "#666666",
                      }}
                    />
                  }
                />
              </Box>
            </Stack>

            <Grid container direction="row" spacing={4}>
              <Grid item xs={12} sm={7} md={7} flex="1" spacing={2} >
                <Grid className="mt-2">
                  <Stack spacing={2} rowGap={1} className="mt-2">
                    <Typography
                      level="h3"
                      component="h3"
                      fontSize="1.8rem"
                      fontWeight="600"
                      justifyContent="space-between"
                      display="flex"
                      alignItems="center"
                    >
                      <span>Campaign Type</span>
                      { // if not Ad
                        campTypeValue !== "Ad" && (
                          <span>DO-X</span>
                        )
                      }
                    </Typography>
                    <FormControl className="font-size-14 m-0">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="Ad"
                        name="radio-buttons-group"
                        sx={{ flexDirection: "row" }}
                        value={campTypeValue}
                        onChange={handleCampaignType}
                      >
                        <FormControlLabel value="Ad" control={<Radio />} label="Ad" />
                        <FormControlLabel value="Buy" control={<Radio />} label="Buy" />
                        <FormControlLabel value="Spend" control={<Radio />} label="Spend" />
                        <FormControlLabel value="Visit" control={<Radio />} label="Visit" />
                      </RadioGroup>
                    </FormControl>
                  </Stack>

                  { // Buy
                    campTypeValue === "Buy" && (
                      <Stack className="font-size-14" spacing={1} direction="row">
                        <TextField
                          type="number"
                          label="Count"
                          name="buyCount"
                          sx={{ width: "200px" }}
                          InputProps={{ inputProps: { min: 1 } }} />
                        <Button onClick={handleOpen} className="btn btn-secondary" sx={{ flex: 1, height: "53px" }}>Select Products</Button>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box className="custom-prod" padding={4}>
                            <Stack direction="row" justifyContent="space-between">
                              <Typography
                                level="h2"
                                component="h2"
                                fontWeight="600"
                                fontSize="2rem"
                                alignSelf="center"
                              >
                                Select Products
                              </Typography>
                              <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon />
                              </IconButton>
                            </Stack>
                            <Stack spacing={2}>
                              <FormControl className="font-size-14 m-0">
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue="Category"
                                  name="radio-buttons-group"
                                  sx={{ flexDirection: "row" }}
                                >
                                  <FormControlLabel value="Category" control={<Radio />} label="Category" />
                                  <FormControlLabel value="Department" control={<Radio />} label="Department" />
                                  <FormControlLabel value="Product" control={<Radio />} label="Product" />
                                </RadioGroup>
                              </FormControl>

                              <Paper
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                                className="font-size-14"
                              >
                                <InputBase
                                  sx={{ ml: 1, flex: 1 }}
                                  placeholder="Search products"
                                  inputProps={{ 'aria-label': 'Search products' }}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                  <SearchIcon />
                                </IconButton>
                              </Paper>

                              <Stack className="font-size-16" spacing={1}>
                                <Card className="card p-1">
                                  <Stack direction="row" spacing={2}>
                                    <Checkbox
                                      alignSelf="center"
                                      value="checkedA"
                                      inputProps={{
                                        'aria-label': 'Checkbox A',
                                      }}
                                    />
                                    <Stack>
                                      <Typography
                                        fontSize="1.6rem"
                                        fontWeight="600">
                                        Name here
                                      </Typography>
                                      <Typography
                                        fontSize="13px">
                                        Sub text here
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </Card>
                                <Card className="card p-1">
                                  <Stack direction="row" spacing={2}>
                                    <Checkbox
                                      alignSelf="center"
                                      value="checkedA"
                                      inputProps={{
                                        'aria-label': 'Checkbox A',
                                      }}
                                    />
                                    <Stack>
                                      <Typography
                                        fontSize="1.6rem"
                                        fontWeight="600">
                                        Name here
                                      </Typography>
                                      <Typography
                                        fontSize="13px">
                                        Sub text here
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </Card>
                                <Card className="card p-1">
                                  <Stack direction="row" spacing={2}>
                                    <Checkbox
                                      alignSelf="center"
                                      value="checkedA"
                                      inputProps={{
                                        'aria-label': 'Checkbox A',
                                      }}
                                    />
                                    <Stack>
                                      <Typography
                                        fontSize="1.6rem"
                                        fontWeight="600">
                                        Name here
                                      </Typography>
                                      <Typography
                                        fontSize="13px">
                                        Sub text here
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </Card>
                              </Stack>
                            </Stack>
                          </Box>
                        </Modal>
                      </Stack>
                    )
                  }

                  { // Spend
                    campTypeValue === "Spend" && (
                      <Stack className="font-size-14">
                        <TextField
                          type="number"
                          label="Amount"
                          name="spendAmount"
                          InputProps={{ inputProps: { min: 1 } }} />
                      </Stack>
                    )
                  }

                  { // Visit
                    campTypeValue === "Visit" && (
                      <Stack className="font-size-14">
                        <TextField
                          type="number"
                          label="No of times"
                          name="visitTimes"
                          InputProps={{ inputProps: { min: 1 } }} />
                      </Stack>
                    )
                  }

                  { // if not Ad
                    campTypeValue !== "Ad" && (
                      <Stack>
                        <Typography
                          level="h3"
                          component="h3"
                          fontSize="1.8rem"
                          fontWeight="600"
                          justifyContent="end"
                          display="flex"
                          alignItems="center"
                          marginTop="1rem"
                        >
                          <span>GET-Y</span>
                        </Typography>
                        <FormControl className="font-size-14">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="Cash"
                            name="radio-buttons-group"
                            sx={{ flexDirection: "row" }}
                            value={campGetTypeValue}
                            onChange={handleCampaignGetType}
                          >
                            <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
                            <FormControlLabel value="Discount" control={<Radio />} label="Discount %" />
                            <FormControlLabel value="Free" control={<Radio />} label="Free" />
                            <FormControlLabel value="Points" control={<Radio />} label="Points" />
                            <FormControlLabel value="Points Multiplier" control={<Radio />} label="Points Multiplier" />
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                    )
                  }

                  { // if Buy
                    campTypeValue !== "Ad" && (
                      <>
                        {
                          campGetTypeValue === "Cash" && (
                            <Stack className="font-size-14" spacing={1}>
                              <TextField
                                type="number"
                                label="Amount"
                                name="getCashAmount"
                                placeholder="0"
                                InputProps={{ inputProps: { min: 1 } }} />
                            </Stack>
                          )
                        }

                        {campTypeValue === "Buy" && (
                          <>
                            {campGetTypeValue === "Discount" && (
                              <Stack className="font-size-14" spacing={1} direction="row">
                                <FormControl>
                                  <TextField
                                    type="number"
                                    label="Discount"
                                    name="getDiscountValue"
                                    sx={{ width: "200px" }}
                                    placeholder="0% Offer" />
                                </FormControl>
                                <FormControl>
                                  <TextField
                                    type="number"
                                    label="Count"
                                    name="buyCount"
                                    sx={{ width: "200px" }}
                                    InputProps={{ inputProps: { min: 1 } }} />
                                </FormControl>
                                <Button onClick={handleOpen} className="btn btn-secondary" sx={{ flex: 1, height: "53px" }}>Select Products</Button>
                                <Modal
                                  open={open}
                                  onClose={handleClose}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <Box className="custom-prod" padding={4}>
                                    <Stack direction="row" justifyContent="space-between">
                                      <Typography
                                        level="h2"
                                        component="h2"
                                        fontWeight="600"
                                        fontSize="2rem"
                                        alignSelf="center"
                                      >
                                        Select Products
                                      </Typography>
                                      <IconButton aria-label="close" onClick={handleClose}>
                                        <CloseIcon />
                                      </IconButton>
                                    </Stack>
                                    <Stack spacing={2}>
                                      <FormControl className="font-size-14 m-0">
                                        <RadioGroup
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          defaultValue="Category"
                                          name="radio-buttons-group"
                                          sx={{ flexDirection: "row" }}
                                        >
                                          <FormControlLabel value="Category" control={<Radio />} label="Category" />
                                          <FormControlLabel value="Department" control={<Radio />} label="Department" />
                                          <FormControlLabel value="Product" control={<Radio />} label="Product" />
                                        </RadioGroup>
                                      </FormControl>

                                      <Paper
                                        component="form"
                                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                                        className="font-size-14"
                                      >
                                        <InputBase
                                          sx={{ ml: 1, flex: 1 }}
                                          placeholder="Search products"
                                          inputProps={{ 'aria-label': 'Search products' }}
                                        />
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                          <SearchIcon />
                                        </IconButton>
                                      </Paper>

                                      <Stack className="font-size-16" spacing={1}>
                                        <Card className="card p-1">
                                          <Stack direction="row" spacing={2}>
                                            <Checkbox
                                              alignSelf="center"
                                              value="checkedA"
                                              inputProps={{
                                                'aria-label': 'Checkbox A',
                                              }}
                                            />
                                            <Stack>
                                              <Typography
                                                fontSize="1.6rem"
                                                fontWeight="600">
                                                Name here
                                              </Typography>
                                              <Typography
                                                fontSize="13px">
                                                Sub text here
                                              </Typography>
                                            </Stack>
                                          </Stack>
                                        </Card>
                                        <Card className="card p-1">
                                          <Stack direction="row" spacing={2}>
                                            <Checkbox
                                              alignSelf="center"
                                              value="checkedA"
                                              inputProps={{
                                                'aria-label': 'Checkbox A',
                                              }}
                                            />
                                            <Stack>
                                              <Typography
                                                fontSize="1.6rem"
                                                fontWeight="600">
                                                Name here
                                              </Typography>
                                              <Typography
                                                fontSize="13px">
                                                Sub text here
                                              </Typography>
                                            </Stack>
                                          </Stack>
                                        </Card>
                                        <Card className="card p-1">
                                          <Stack direction="row" spacing={2}>
                                            <Checkbox
                                              alignSelf="center"
                                              value="checkedA"
                                              inputProps={{
                                                'aria-label': 'Checkbox A',
                                              }}
                                            />
                                            <Stack>
                                              <Typography
                                                fontSize="1.6rem"
                                                fontWeight="600">
                                                Name here
                                              </Typography>
                                              <Typography
                                                fontSize="13px">
                                                Sub text here
                                              </Typography>
                                            </Stack>
                                          </Stack>
                                        </Card>
                                      </Stack>
                                    </Stack>
                                  </Box>
                                </Modal>
                              </Stack>
                            )
                            }
                          </>
                        )}

                        {campTypeValue !== "Buy" && (
                          <>
                            {
                              campGetTypeValue === "Discount" && (
                                <Stack className="font-size-14" spacing={1}>
                                  <FormControl>
                                    <TextField
                                      type="number"
                                      label="Discount"
                                      name="getDiscountValue"
                                      placeholder="0% Offer" />
                                  </FormControl>
                                </Stack>
                              )
                            }
                          </>
                        )}

                        {
                          campGetTypeValue === "Free" && (
                            <Stack className="font-size-14" spacing={1} direction="row">
                              <TextField
                                type="number"
                                label="Free"
                                name="getFreeValue"
                                sx={{ width: "200px" }}
                                placeholder="0" />
                              <Button onClick={handleOpen} className="btn btn-secondary" sx={{ flex: 1, height: "53px" }}>Select Products</Button>
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box className="custom-prod" padding={4}>
                                  <Stack direction="row" justifyContent="space-between">
                                    <Typography
                                      level="h2"
                                      component="h2"
                                      fontWeight="600"
                                      fontSize="2rem"
                                      alignSelf="center"
                                    >
                                      Select Products
                                    </Typography>
                                    <IconButton aria-label="close" onClick={handleClose}>
                                      <CloseIcon />
                                    </IconButton>
                                  </Stack>
                                  <Stack spacing={2}>
                                    <FormControl className="font-size-14 m-0">
                                      <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="Category"
                                        name="radio-buttons-group"
                                        sx={{ flexDirection: "row" }}
                                      >
                                        <FormControlLabel value="Category" control={<Radio />} label="Category" />
                                        <FormControlLabel value="Department" control={<Radio />} label="Department" />
                                        <FormControlLabel value="Product" control={<Radio />} label="Product" />
                                      </RadioGroup>
                                    </FormControl>

                                    <Paper
                                      component="form"
                                      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                                      className="font-size-14"
                                    >
                                      <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search products"
                                        inputProps={{ 'aria-label': 'Search products' }}
                                      />
                                      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                      </IconButton>
                                    </Paper>

                                    <Stack className="font-size-16" spacing={1}>
                                      <Card className="card p-1">
                                        <Stack direction="row" spacing={2}>
                                          <Checkbox
                                            alignSelf="center"
                                            value="checkedA"
                                            inputProps={{
                                              'aria-label': 'Checkbox A',
                                            }}
                                          />
                                          <Stack>
                                            <Typography
                                              fontSize="1.6rem"
                                              fontWeight="600">
                                              Name here
                                            </Typography>
                                            <Typography
                                              fontSize="13px">
                                              Sub text here
                                            </Typography>
                                          </Stack>
                                        </Stack>
                                      </Card>
                                      <Card className="card p-1">
                                        <Stack direction="row" spacing={2}>
                                          <Checkbox
                                            alignSelf="center"
                                            value="checkedA"
                                            inputProps={{
                                              'aria-label': 'Checkbox A',
                                            }}
                                          />
                                          <Stack>
                                            <Typography
                                              fontSize="1.6rem"
                                              fontWeight="600">
                                              Name here
                                            </Typography>
                                            <Typography
                                              fontSize="13px">
                                              Sub text here
                                            </Typography>
                                          </Stack>
                                        </Stack>
                                      </Card>
                                      <Card className="card p-1">
                                        <Stack direction="row" spacing={2}>
                                          <Checkbox
                                            alignSelf="center"
                                            value="checkedA"
                                            inputProps={{
                                              'aria-label': 'Checkbox A',
                                            }}
                                          />
                                          <Stack>
                                            <Typography
                                              fontSize="1.6rem"
                                              fontWeight="600">
                                              Name here
                                            </Typography>
                                            <Typography
                                              fontSize="13px">
                                              Sub text here
                                            </Typography>
                                          </Stack>
                                        </Stack>
                                      </Card>
                                    </Stack>
                                  </Stack>
                                </Box>
                              </Modal>
                            </Stack>
                          )
                        }

                        {
                          campGetTypeValue === "Points" && (
                            <Stack className="font-size-14">
                              <TextField
                                type="number"
                                label="Points"
                                name="getPointsValue"
                                placeholder="0" />
                            </Stack>
                          )
                        }

                        {
                          campGetTypeValue === "Points Multiplier" && (
                            <Stack className="font-size-14" spacing={1}>
                              <TextField
                                type="number"
                                label="Points Multiplier (X)"
                                name="getPointsMultiplierValue"
                                placeholder="0x" />
                            </Stack>
                          )
                        }
                      </>
                    )}

                  <Stack spacing={2} rowGap={1} className="mt-2">
                    <Typography
                      level="h3"
                      component="h3"
                      fontSize="1.8rem"
                      fontWeight="600"
                      justifyContent="space-between"
                      display="flex"
                      alignItems="center"
                    >
                      <span>Content Type</span>
                    </Typography>
                    <FormControl className="font-size-14 m-0">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="Coupon Content"
                        name="radio-buttons-group"
                        sx={{ flexDirection: "row" }}
                        value={campContentTypeValue}
                        onChange={handleCampaignContentType}
                      >
                        <FormControlLabel value="Coupon Content" control={<Radio />} label="Coupon Content" />
                        <FormControlLabel value="Banner Image" control={<Radio />} label="Banner Image" />
                        <FormControlLabel value="Html" control={<Radio />} label="Html" />
                      </RadioGroup>
                    </FormControl>

                    {
                      campContentTypeValue === "Coupon Content" && (
                        <FormControl className="font-size-14 m-0">
                          <TextField
                            type="text"
                            multiline="true"
                            rows={5}
                            label="Content"
                            name="campContent" />
                        </FormControl>
                      )
                    }

                    {
                      campContentTypeValue === "Banner Image" && (
                        <FormControl className="font-size-14 m-0">
                          <TextField
                            type="file"
                            label="Image"
                            name="campImage"
                            InputLabelProps={{ shrink: true }} />
                        </FormControl>
                      )
                    }

                    {
                      campContentTypeValue === "Html" && (
                        <FormControl className="font-size-14 m-0">
                          <TextField
                            type="text"
                            multiline="true"
                            rows={5}
                            label="Html Code"
                            name="campHtml" />
                        </FormControl>
                      )
                    }
                  </Stack>

                </Grid>
              </Grid>
              <Grid item xs={12} sm={5} md={5} flex="1" spacing={2}>
                <Card sx={{ boxShadow: "none", borderRadius: "10px", marginTop: "2rem", height: "100%", display: "flex", justifyContent: "center", position: "sticky", top: 0 }}>
                  <Typography
                    fontSize="1.5rem"
                    sx={{ position: "absolute", top: "10px", left: "20px" }}>Preview :</Typography>
                  <Box
                    component="img"
                    alt="Preview"
                    src={Preview_Img}
                    alignSelf="Center"
                    sx={{
                      width: "246px"
                    }}
                  />
                </Card>
              </Grid>
            </Grid>

            <Accordion defaultExpanded sx={{ boxShadow: "none", background: "transparent", margin: "2rem 0 0 0 !important" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ padding: "0px" }}
              >
                <Typography
                  level="h3"
                  component="h3"
                  fontSize="1.8rem"
                  fontWeight="600"
                >
                  Marketing Channels
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px" }}>
                <Stack spacing={2}>
                  <Stack className="font-size-14" spacing={1} direction="row">
                    <FormControl>
                      <Card sx={{ boxShadow: "none", borderRadius: "10px" }}>
                        <Stack spacing={2} padding={2} direction="row" justifyContent="space-between">
                          <Stack direction="row" spacing={2}>
                            <Box
                              component="img"
                              alt="Email"
                              src={Email_Img}
                              alignSelf="Center"
                              sx={{
                                width: "46px"
                              }}
                            />
                            <Stack>
                              <Typography
                                level="h3"
                                component="h3"
                                fontWeight="700"
                              >
                                <span>Email</span>
                                <Box className="toggle-switch" alignSelf="Center" sx={{ position: "absolute", top: "10px", right: "10px" }}>
                                  <input type="checkbox" id="emailSwitch" />
                                  <label for="emailSwitch">Toggle</label>
                                </Box>
                              </Typography>
                              <Typography
                                level="h3"
                                component="h3"
                                fontWeight="400"
                              >
                                Email this campaign to the target customers
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Card>
                    </FormControl>
                    <FormControl>
                      <Card sx={{ boxShadow: "none", borderRadius: "10px" }}>
                        <Stack spacing={2} padding={2} direction="row" justifyContent="space-between">
                          <Stack direction="row" spacing={2}>
                            <Box
                              component="img"
                              alt="Push Notification"
                              src={PushNotification_Img}
                              alignSelf="Center"
                              sx={{
                                width: "46px"
                              }}
                            />
                            <Stack>
                              <Typography
                                level="h3"
                                component="h3"
                                fontWeight="700"
                              >
                                <span>Push Notification</span>
                                <Box className="toggle-switch" alignSelf="Center" sx={{ position: "absolute", top: "10px", right: "10px" }}>
                                  <input type="checkbox" id="pushSwitch" />
                                  <label for="pushSwitch">Toggle</label>
                                </Box>
                              </Typography>
                              <Typography
                                level="h3"
                                component="h3"
                                fontWeight="400"
                              >Send this campaign as push notification
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Card>
                    </FormControl>
                    <FormControl>
                      <Card sx={{ boxShadow: "none", borderRadius: "10px" }}>
                        <Stack spacing={2} padding={2} direction="row" justifyContent="space-between">
                          <Stack direction="row" spacing={2}>
                            <Box
                              component="img"
                              alt="SMS"
                              src={SMS_Img}
                              alignSelf="Center"
                              sx={{
                                width: "46px"
                              }}
                            />
                            <Stack>
                              <Typography
                                level="h3"
                                component="h3"
                                fontWeight="700"
                              >
                                <span>SMS</span>
                                <Box className="toggle-switch" alignSelf="Center" sx={{ position: "absolute", top: "10px", right: "10px" }}>
                                  <input type="checkbox" id="smsSwitch" />
                                  <label for="smsSwitch">Toggle</label>
                                </Box>
                              </Typography>
                              <Typography
                                level="h3"
                                component="h3"
                                fontWeight="400"
                              >
                                SMS this campaign to the target customers
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Card>
                    </FormControl>
                    <FormControl>
                      <Card sx={{ boxShadow: "none", borderRadius: "10px" }}>
                        <Stack spacing={2} padding={2} direction="row" justifyContent="space-between">
                          <Stack direction="row" spacing={2}>
                            <Box
                              component="img"
                              alt="Instagram"
                              src={Insta_Img}
                              alignSelf="Center"
                              sx={{
                                width: "46px"
                              }}
                            />
                            <Stack>
                              <Typography
                                level="h3"
                                component="h3"
                                fontWeight="700"
                              >
                                <span>Instagram</span>
                                <Box className="toggle-switch" alignSelf="Center" sx={{ position: "absolute", top: "10px", right: "10px" }}>
                                  <input type="checkbox" id="instaSwitch" />
                                  <label for="instaSwitch">Toggle</label>
                                </Box>
                              </Typography>
                              <Typography
                                level="h3"
                                component="h3"
                                fontWeight="400"
                              >
                                Post this campaign to the target customers
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Card>
                    </FormControl>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded sx={{ boxShadow: "none", background: "transparent" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{ padding: "0px" }}
              >
                <Typography
                  level="h3"
                  component="h3"
                  fontSize="1.8rem"
                  fontWeight="600"
                >
                  Target Customers
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px" }}>
                <Stack spacing={2}>
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid xs="12" sm="6" md="2">
                      <FormControl className="font-size-14">
                        <Typography
                          level="h3"
                          component="h3"
                          fontSize="1.4rem"
                          fontWeight="600"
                        >
                          Tiers
                        </Typography>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="Silver Tier (0)"
                          name="radio-buttons-group"
                          className="mt-1"
                          sx={{ maxHeight: "180px", overflow: "auto", flexDirection: "row", padding: "1px" }}
                        >
                          <FormControlLabel sx={{ width: "100%" }} value="Base Tier (712)" control={<Radio />} label="Base Tier (712)" />
                          <FormControlLabel sx={{ width: "100%" }} value="Silver Tier (0)" control={<Radio />} label="Silver Tier (0)" />
                          <FormControlLabel sx={{ width: "100%" }} value="Gold Tier (120)" control={<Radio />} label="Gold Tier (120)" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid xs="12" sm="6" md="4" sx={{ paddingRight: "5%" }}>
                      <FormControl className="font-size-14">
                        <Typography
                          level="h3"
                          component="h3"
                          fontSize="1.4rem"
                          fontWeight="600"
                        >
                          Locations
                        </Typography>
                        <FormGroup
                          className="mt-1"
                          sx={{ maxHeight: "180px", overflow: "auto", flexDirection: "row", padding: "1px" }}
                        >
                          <FormControlLabel value="Chennai (575)" control={<Checkbox />} label="Chennai (575)" />
                          <FormControlLabel value="Kochi (458)" control={<Checkbox />} label="Kochi (458)" />
                          <FormControlLabel value="Coimbatore (412)" control={<Checkbox />} label="Coimbatore (412)" />
                          <FormControlLabel value="Hyderabad (302)" control={<Checkbox />} label="Hyderabad (302)" />
                          <FormControlLabel value="Delhi (450)" control={<Checkbox />} label="Delhi (450)" />
                          <FormControlLabel value="Gurugram (84)" control={<Checkbox />} label="Gurugram (84)" />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid xs="12" sm="6" md="3">
                      <FormControl className="font-size-14">
                        <Typography
                          level="h3"
                          component="h3"
                          fontSize="1.4rem"
                          fontWeight="600"
                        >
                          Customer Segment
                        </Typography>
                        <FormGroup
                          className="mt-1"
                          sx={{ maxHeight: "180px", overflow: "auto", flexDirection: "row", padding: "1px" }}
                        >
                          <FormControlLabel sx={{ width: "100%" }} value="Detailing" control={<Checkbox />} label="Detailing" />
                          <FormControlLabel sx={{ width: "100%" }} value="Pre-Owned Cars" control={<Checkbox />} label="Pre-Owned Cars" />
                          <FormControlLabel sx={{ width: "100%" }} value="Accessories" control={<Checkbox />} label="Accessories" />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid xs="12" sm="6" md="3">
                      <FormControl className="font-size-14">
                        <Typography
                          level="h3"
                          component="h3"
                          fontSize="1.4rem"
                          fontWeight="600"
                        >
                          Vehicle Segment
                        </Typography>
                        <FormGroup
                          className="mt-1"
                          sx={{ maxHeight: "180px", overflow: "auto", flexDirection: "row", padding: "1px" }}
                        >
                          <FormControlLabel sx={{ width: "100%" }} value="Under 5L" control={<Checkbox />} label="Under 5L" />
                          <FormControlLabel sx={{ width: "100%" }} value="Sedan" control={<Checkbox />} label="Sedan" />
                          <FormControlLabel sx={{ width: "100%" }} value="SUV" control={<Checkbox />} label="SUV" />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Stack>

                <Card sx={{ boxShadow: "none", borderRadius: "10px", marginTop: "2rem" }}>
                  <Stack spacing={2} padding={2} direction="row" justifyContent="space-between">
                    <Typography
                      level="h3"
                      component="h3"
                      fontSize="1.6rem"
                      fontWeight="700"
                    >
                      Include Non-Loyalty Customers
                    </Typography>
                    <Box className="toggle-switch" alignSelf="Center">
                      <input type="checkbox" id="custSwitch" />
                      <label for="custSwitch">Toggle</label>
                    </Box>
                  </Stack>
                </Card>

              </AccordionDetails>
            </Accordion>

          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddNewCampaign;
