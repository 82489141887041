import React, { useState, useEffect } from "react";
import "../../Style.css";
import Utils from "../../core/Utils";
import UserHelper from "../../core/UserHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connectToStore } from "../../data/store";
import SideBarMenuItems from "../SideBarMenuItems";
import SettingsMenuItems from "../SettingsMenuItems";
import ReportMenuItems from "../ReportMenuItems";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import DirectionsCarFilledTwoToneIcon from '@mui/icons-material/DirectionsCarFilledTwoTone';
import {
  SideBarMenuNames,
  SettingsMenuList,
  MaxDateLimit,
} from "../../core/Constants";

import MessagePopup from "../common/MessagePopUp";
import {
  Typography,
  Switch,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  IconButton,
  Modal,
} from "@mui/material";
import { Grid, Box, Container, Stack } from "@mui/material";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Loader from "../common/Loader";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { LoginPage, StatementReportPage } from "../../core/PageConstants";
import CountryHelper from "../../core/CountryHelper";
import { DataGrid, GridCloseIcon } from "@mui/x-data-grid";
import { getBusiness } from "../../data/localSettingsActions";
import { postAsync } from "../../core/serviceClient";
import { fontSize } from "@mui/system";
import BusinessHelper from "../../core/BusinessHelper";

const StatementReport = (props) => {
  document.title = "Statement";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [statementMethod, setStatementMethod] = useState("Transactional");
  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [reportContent, setReportContent] = useState("");
  const [invoiceLabels, setInvoiceLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [filterByCustomer, setFilterByCustomer] = useState("");
  const [canFilterByInvoiceLabel, setCanFilterByInvoiceLabel] = useState(false);
  const [isInvoiceFilterVisible, setIsInvoiceFilterVisible] = useState(false);

  // Customer table
  const [customerModalPopup, setCustomerModalPopup] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [customer, setCustomer] = useState({});
  const location = useLocation();
  const [searchText, setSearchText] = useState(
    !Utils.IsNullOrEmptyObject(location.state) &&
      !Utils.IsNullOrEmpty(location.state.searchText)
      ? location.state.searchText
      : ""
  );

  const checkAuthentication = () => {
    if (Utils.IsNullOrEmptyObject(localSettings)) {
      return;
    }
    UserHelper.CheckPermission(StatementReportPage, navigate, localSettings);
    var tempInvoiceLabels = [];
    if (!Utils.IsNullOrEmptyObject(localSettings.branch)) {
      if (!Utils.IsNullOrEmptyArray(localSettings.branch.invoiceLabels)) {
        localSettings.branch.invoiceLabels.forEach((label) => {
          tempInvoiceLabels.push(label.description);
        });
      }
      setInvoiceLabels(tempInvoiceLabels);
      setIsInvoiceFilterVisible(
        (CountryHelper.IsBusinessCountryIndia(localSettings.country) ||
          CountryHelper.IsBusinessCountryUAE(localSettings.country)) &&
        !Utils.IsNullOrEmptyArray(tempInvoiceLabels)
      );
    }
    if (!Utils.IsNullOrEmpty(searchText)) fetchCustomerSearchList();
  };

  useEffect(() => {
    checkAuthentication();
  }, []);
  const handleInputStatement = (e) => {
    setStatementMethod(e.target.value);
    setFilterByCustomer(false);
    setSelectedCustomer("");
  };

  const handleSubmit = () => {
    getStatementStatus(statementMethod);
  };

  const getStatementStatus = async (statementMethod) => {
    setIsLoading(true);
    const customerId = selectedCustomer.customerId;
    let url = "";
    var selectInvoiceLabel = canFilterByInvoiceLabel ? selectedLabel : "";
    if (statementMethod === "Transactional") {
      url = "reports/GetSalesTransaction?";
    } else if (statementMethod === "Summary") {
      url = "reports/GetSalesSummary?";
    } else {
      url = "reports/GetSalesOutstanding?";
    }

    let reportUrl =
      Utils.GetServiceUrl() +
      url +
      "branchId=" +
      localSettings.branch.branchId +
      "&businessId=" +
      localSettings.business.id +
      "&startDate=" +
      Utils.GetISODateTimeString(selectedDate[0]) +
      "&endDate=" +
      Utils.GetISODateTimeString(selectedDate[1]) +
      "&invoiceLabel=" +
      selectInvoiceLabel +
      "&token=" +
      localSettings.token +
      "&culture=" +
      CountryHelper.GetAPICulture(localSettings.country.Culture);

    if (!Utils.IsNullOrEmpty(customerId)) {
      reportUrl += "&filteredCustomerID=" + customerId;
    }
    setIsLoading(false);
    window.open(reportUrl, "_blank");
  };

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      let dateDiff = dateArray[1].diff(dateArray[0], "days");
      if (dateArray[0]._isValid == false || dateArray[1]._isValid == false) {
        setMsgText(
          "Please select a valid date range."
        );

        setMsgOpen(true);
      }
      else if (dateDiff > MaxDateLimit) {
        setMsgText(
          "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
        );

        setMsgOpen(true);
      }
      else {
        setSelectedDate(dateArray);
      }
    }
  };

  const handleCustomerSubmit = (e) => {
    fetchCustomerSearchList();
  };

  // customer list
  async function fetchCustomerSearchList() {
    if (Utils.IsNullOrEmpty(searchText) == true) return;
    setIsLoading(true);
    const business = getBusiness();
    let response = await postAsync("Customer/Search", {
      BusinessId: business.id,
      SearchText: searchText,
      IsAutomobileBusiness: business.category == "Automobile",
    });
    setIsLoading(false);
    setIsNavigateToLogin(response.isNavigateToLogin);
    if (response.error) {
      setMsgOpen(true);
      setMsgText(response.errorMessage);
    }
    if (!Utils.IsNullOrEmptyArray(response.data.passList)) {
      getCustomerList(
        response.data.passList.sort((a, b) =>
          a.userName > b.userName ? 1 : -1
        )
      );
    } else {
      getCustomerList([]);
    }
  }

  //customer table
  const customerListColumns = [
    {
      field: "userName",
      headerName: " Name",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "mobileNumber",
      headerName: "Contact",
      headerClassName: "headerStyle",
      flex: 1,
    },
   ...(BusinessHelper.IsAutomobile() ? [{
      field: "vehicleDetails",
      headerName: "Vehicle Details",
      headerClassName: "headerStyle",
      flex: 2,
      renderCell: (params) => (
        <div>
          {params.value.map((vehicle, index) => (
            <div key={index}>
              <div>{vehicle.number} -  {vehicle.make} - {vehicle.model}</div>
            </div>
          ))}
        </div>
      ),
    }] : []),
    {
      field: "visits",
      headerName: "Visits",
      headerClassName: "headerStyle",
      flex: 1,
    },
  ];
  async function getCustomerList(passList) {
    setCustomerList(passList);
  }
  var onClickCustomer = (event) => {
    var customer = event.row;
    getCustomer(customer.customerId, customer.businessId)
    setCustomerModalPopup(false)
    setSelectedCustomer(customer);
  };

  const getCustomer = async (id, businessId) => {
    var request = { Id: id, BusinessId: businessId };
    var result = await postAsync("Customer/GetCustomer", request, "", "Get");

    if (result.error === true) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      return
    }
    setCustomer(result.data)
  }

  const handleSwitchChange = (event) => {
    const isChecked = event.target.checked;
    setFilterByCustomer(isChecked);
    setCustomerModalPopup(isChecked);
    setSelectedCustomer("");
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.InvoiceReport} />
          <Grid xs={12} className="content-sec c-sec2">
            <Grid container direction="row" className="">
              <ReportMenuItems
                selectedMenu={SettingsMenuList.BusinessReports.Statement}
              />
              <Grid xs={12} md={9}>
                <Grid spacing={2} className="form-sec" paddingBottom="100px">
                  <form container spacing={2} onSubmit={handleSubmit}>
                    <Stack
                        alignSelf="center"
                        justifyContent="end"
                        direction="row"
                        flex="1"
                        className="text-end mb-2"
                      >
                      <Grid
                        container
                        xs={12}
                        md={3}
                        lg={3}
                        style={{ "align-self": "stretch" }}
                        justifyContent="end"
                        paddingRight="15px"
                        className="date-picker-sec"
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          style={{ height: "100%" }}
                        >
                          <DateRangePicker
                            showLeadingZeros={true}
                            onChange={onDateChange}
                            maxDate={new Date()}
                            value={selectedDate}
                            locale={CountryHelper.GetDateCulture(localSettings.country.Culture)}
                            clearIcon={null}
                            calendarIcon={
                              <CalendarMonthOutlinedIcon
                                style={{
                                  "font-size": "24px",
                                  "align-self": "center",
                                  color: "#666666",
                                }}
                              />
                            }
                          />
                        </Stack>
                      </Grid>
                    </Stack>

                    <Grid className="card white-bg" padding={2}>
                      {isInvoiceFilterVisible && (
                        <Stack direction="column">
                          <Stack direction="row" justifyContent="space-between">
                              <Typography
                                fontSize="1.6rem"
                                fontWeight="600"
                            >
                              Filter by invoice label
                            </Typography>
                            <Switch
                              checked={canFilterByInvoiceLabel}
                              onChange={() =>
                                setCanFilterByInvoiceLabel(
                                  !canFilterByInvoiceLabel
                                )
                              }
                            />
                          </Stack>
                          {canFilterByInvoiceLabel && (
                            <Stack style={{ margin: "0" }}>
                              <FormControl
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                className="font-size-14"
                              >
                                <InputLabel>Select Invoice Label</InputLabel>
                                <Select
                                  id="invoiceLabelSelect"
                                  value={selectedLabel}
                                  label="Select Invoice Label"
                                  onChange={(e) => setSelectedLabel(e.target.value)}
                                >
                                  {!Utils.IsNullOrEmptyArray(invoiceLabels) &&
                                    invoiceLabels.map((label) => (
                                      <MenuItem value={label}>{label}</MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Stack>
                          )}

                        </Stack>
                      )}
                      <Stack direction="row" className="font-size-14">
                        <RadioGroup
                          value={statementMethod}
                          onChange={(e) => handleInputStatement(e)}
                          InputLabelProps={{ shrink: true }}
                        >
                          <Stack direction="row">
                            <FormControlLabel
                              value={"Transactional"}
                              control={<Radio />}
                              label="Transactional"
                            />
                            <FormControlLabel
                              value={"Summary"}
                              control={<Radio />}
                              label="Summary"
                            />
                            <FormControlLabel
                              value={"OutStanding"}
                              control={<Radio />}
                              label="Outstanding"
                            />
                          </Stack>
                        </RadioGroup>
                        {statementMethod === "OutStanding" && (
                          <>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="stretch"
                            >
                              <Typography style={{ fontSize: "13px", marginLeft: "44%", marginTop: "8px" }}>
                                Filter by Customer
                              </Typography>
                              <Switch
                                checked={filterByCustomer}
                                onChange={handleSwitchChange}

                              />
                            </Grid>
                          </>
                        )}
                      </Stack>
                      {!Utils.IsNullOrEmpty(selectedCustomer) && (statementMethod === "OutStanding") && (
                        <div>
                          <Stack
                            className="card"
                            style={{ marginLeft: "12px", padding: "5px", height: "auto" }}>
                            <Grid>
                              <Typography
                                level="h4"
                                component="h4"
                                fontSize="13px"
                                style={{ "font-weight": "600" }}
                              >
                                {selectedCustomer.userName}
                              </Typography>
                              <IconButton
                                aria-label="close"
                                onClick={() => {
                                  setSelectedCustomer("");
                                  setFilterByCustomer(false);
                                }}
                                sx={{
                                  position: "absolute",
                                  right: 8,
                                  top: 8,
                                  color: (theme) => theme.palette.grey[500],
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                              <InputLabel className="font-size-12"
                                style={{ "font-weight": "600" }}
                              >
                                {selectedCustomer.mobileNumber}
                              </InputLabel>
                              <InputLabel
                                className="font-size-12"
                                style={{ "font-weight": "600" }}
                              >
                                {customer.homeLocation}
                              </InputLabel>

                              <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                              >
                                {!Utils.IsNullOrEmpty(customer.companyName) && (
                                  <Box className="btn btn-secondary align-self-stretch" style={{ fontSize: "7px" }}>
                                    {customer.companyName}
                                  </Box>)}
                                {!Utils.IsNullOrEmpty(customer.registrationId) && (
                                  <Box
                                    className="btn btn-secondary align-self-stretch"
                                    style={{ fontSize: "7px" }}
                                  >
                                    {customer.registrationId}
                                  </Box>)}

                                <Box
                                  className="btn btn-secondary align-self-stretch"
                                  style={{ fontSize: "10px" }}
                                >
                                  <DirectionsCarFilledTwoToneIcon />
                                  {(selectedCustomer.vehicleDetails).length}

                                </Box>
                              </Stack>
                            </Grid>
                          </Stack>
                        </div>
                      )}
                    </Grid>
                  </form>
                  {/* <iframe
                    className="reportIframe card white-bg my-2"
                    src={reportContent}
                    style={{
                      width: "100%",
                      height: !canFilterByInvoiceLabel
                        ? "calc(95vh - 195px)"
                        : "calc(83vh - 183px)",
                    }}
                  ></iframe>
                  <br />                   */}
                  <Stack
                    direction="row"
                      className="fixed-btn"
                    >
                      <Grid className="container"
                        justifyContent="end"
                      >
                        <Stack direction="row" justifyContent="end">
                          <Button
                              className="btn btn-primary"
                              variant="contained"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              View / Download Statement
                            </Button>
                        </Stack>
                      </Grid>
                  </Stack>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* customer table */}
          <Modal open={customerModalPopup}>
            <Box className="css-modal" padding={2}>
              <Stack direction="row" spacing={2}>
                <FormControl xs={8} fullWidth>
                  <h4 style={{ fontWeight: "bold" }}>
                    Select Customer
                  </h4>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setCustomerModalPopup(false);
                      setFilterByCustomer(false);
                    }}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Stack
                    direction="row"
                    alignSelf="center"
                    spacing={1}
                    style={{ width: "100%" }}
                  >
                    <Grid flex="1">
                      <TextField
                        className="form-control font-size-14"
                        placeholder="Search customer..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{
                          marginBottom: "15px",
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter")
                            handleCustomerSubmit();
                        }}
                      />
                    </Grid>
                    <Grid>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleCustomerSubmit}
                        className="submitBtn cs-btn"
                        fullWidth="true"
                        style={{ height: "52px" }}

                      >
                        <SearchIcon sx={{ fontSize: 24 }} />
                        <span>Search</span>
                      </Button>
                    </Grid>
                  </Stack>
                  <Box style={{ overflow: "auto", height: "58%", }}>
                    <DataGrid
                      getRowId={(row) => row.id}
                      rows={customerList}
                      onCellClick={(e) => onClickCustomer(e)}
                      columns={customerListColumns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10]}
                      className="data-grid font-size-14 cs-data-grid"
                      rowHeight="auto"
                    />
                  </Box>
                </FormControl>
              </Stack>
            </Box>
          </Modal>
        </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default StatementReport;
