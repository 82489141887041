import "../Style.css"; import "../Responsive.css";
import React, { useEffect, useState } from "react";
import { connectToStore } from "../data/store";
import {
  HideSettingsMenus,
  SettingsMenuList,
  SettingsMenuNames,
} from "../core/Constants";
import {
  Box,
  Stack,
  Typography,
  TextField,
  Switch,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Avatar,
  Grid
} from "@mui/material";
import { Link } from "react-router-dom";
import BusinessHelper from "../core/BusinessHelper";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CallIcon from "@mui/icons-material/Call";
import Utils from "../core/Utils";
import CountryHelper from "../core/CountryHelper";
import UserHelper from "../core/UserHelper";
import {
  AdminUsersPage,
  ConsumablesPage,
  ServicesPage,
  UPISetupPage,
  UsersPage,
  SparesPage,
  BusinessSetupPage,
  BranchSetupPage,
  InvoiceReportPage,
  StatementReportPage,
  ExpensesReportPage,
  ProfitAndLossReportPage,
  JobcardsReportPage,
  EmployeeReportPage,
  StaffReportPage,
  OnHandReportPage,
  ConsumptionReportPage,
} from "../core/PageConstants";
import { CanUseProductStockReport } from "../core/ModuleHelper";

const SettingsMenuItems = ({ selectedMenu }) => {
  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [productStockReportAvailable, setProductStockReportAvailable] = useState(true);
  useEffect(() => {
    var productStockReport = CanUseProductStockReport(localSettings.business);
    if (productStockReport === false || UserHelper.IsTechnician() || UserHelper.IsOperator()) {
      setProductStockReportAvailable(false);
    }
  }, []);
  return (
    <Grid xs={12} md={3}>
      <Box className="left-menu">
        <Stack className="left-menu-inner">
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            Reports
          </Typography>
          <nav className="font-size-15">
            <List>
              {UserHelper.IsXUVIAdmin() ||
                UserHelper.IsGlobalAdmin() ||
                UserHelper.IsBranchAdmin() ? (
                <>
                  <Link to={InvoiceReportPage.Path}>
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        className={
                          selectedMenu == SettingsMenuList.BusinessReports.Invoice
                            ? "active"
                            : null
                        }
                      >
                        <ListItemText
                          primary={SettingsMenuList.BusinessReports.Invoice}
                        />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>

                  <Link to={StatementReportPage.Path}>
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        className={
                          selectedMenu ==
                            SettingsMenuList.BusinessReports.Statement
                            ? "active"
                            : null
                        }
                      >
                        <ListItemText
                          primary={SettingsMenuList.BusinessReports.Statement}
                        />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>

                  <Link to={ExpensesReportPage.Path}>
                    <ListItem disablePadding>
                      <ListItemButton component="a"
                        className={
                          selectedMenu ==
                            SettingsMenuList.BusinessReports.Expenses
                            ? "active" : null
                        }>
                        <ListItemText primary={SettingsMenuList.BusinessReports.Expenses} />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>

                  <Link to={ProfitAndLossReportPage.Path}>
                    <ListItem disablePadding>
                      <ListItemButton component="a"
                        className={
                          selectedMenu == SettingsMenuList.BusinessReports.ProfitAndLoss
                            ? "active" : null
                        }>
                        <ListItemText primary={SettingsMenuList.BusinessReports.ProfitAndLoss} />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  {!BusinessHelper.IsSalon() && (
                    <Link to={JobcardsReportPage.Path}>
                      <ListItem disablePadding>
                        <ListItemButton component="a"
                          className={
                            selectedMenu == SettingsMenuList.BusinessReports.JobCards
                              ? "active" : null
                          }>
                          <ListItemText primary={SettingsMenuList.BusinessReports.JobCards} />
                          <NavigateNextIcon />
                        </ListItemButton>
                      </ListItem>
                    </Link>)}

                  <Link to={BusinessHelper.IsSalon() ? StaffReportPage.Path : EmployeeReportPage.Path}>
                    <ListItem disablePadding>
                      <ListItemButton component="a"
                        className={
                          selectedMenu == SettingsMenuList.BusinessReports.Employee
                            ? "active" : null
                        }>
                        <ListItemText primary={SettingsMenuList.BusinessReports.Employee} />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>

                </>
              ) : (
                <>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        className={
                          selectedMenu ==
                            SettingsMenuList.BusinessReports.Expenses
                            ? "active"
                            : null
                        }
                      >
                        <ListItemText
                          primary={SettingsMenuList.BusinessReports.Expenses}
                        />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </>
              )}
            </List>
          </nav>
        </Stack>
      </Box >
    </Grid>
  );
};

export default SettingsMenuItems;
