import React, { useState, useEffect } from "react";
import "../../Style.css";
import Utils from "../../core/Utils";
import UserHelper from "../../core/UserHelper";
import { connectToStore } from "../../data/store";
import SideBarMenuItems from "../SideBarMenuItems";
import SettingsMenuItems from "../SettingsMenuItems";
import ReportMenuItems from "../ReportMenuItems";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {
  SideBarMenuNames,
  SettingsMenuList,
  MaxDateLimit,
} from "../../core/Constants";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MessagePopup from "../common/MessagePopUp";
import { Typography } from "@mui/material";
import "react-calendar/dist/Calendar.css";
import { Grid, Box, Container, Stack, Switch } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Loader from "../common/Loader";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { StaffReportPage, LoginPage } from "../../core/PageConstants";
import InputLabel from "@mui/material/InputLabel";
import { getBranch, getBusiness } from "../../data/localSettingsActions";
import { postAsync } from "../../core/serviceClient";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CountryHelper from "../../core/CountryHelper";

const StaffsReport = (props) => {
  document.title = "Employee ";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [reportContent, setReportContent] = useState("");
  const [filterByBranch, setFilterByBranch] = useState(false);
  const [filterByStaff, setFilterByStaff] = useState(false);
  const [filterGroupStaff, setFilterGroupStaff] = useState(false);
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [statementMethod, setStatementMethod] = useState("Summary");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [selectedStaffBranch, setSelectedStaffBranch] = useState("");
  const [branchStaff, setBranchStaff] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branches, setBranches] = useState([]);

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };
  const handleFilterByBranchChange = async (event) => {
    var checked = event.target.checked;
    setFilterByBranch(checked);
    setSelectedStaffBranch("");
    if (!checked) {
      setSelectedBranch("");
      await StaffList();
    }
  };

  const handleFilterByStaffBranchChange = (event) => {
    setFilterByStaff(event.target.checked);
  };
  const handleFilterGroupStaffChange = (event) => {
    setFilterGroupStaff(event.target.checked);
  };
  const handleSubmitDownload = () => {
    getStatementDownload(statementMethod);
  };

  const getStatementDownload = async (statementMethod) => {
    let url = "Reports/GetStaffDetailedReport?";
    var branchId =
      filterByBranch && selectedBranch !== null ? selectedBranch : "";
    const staffId =
      filterByStaff && selectedStaffBranch !== null ? selectedStaffBranch : "";

    if (statementMethod === "Summary") {
      url = "Reports/GetStaffSummaryReport?";
    }
    if (statementMethod === "Stylist") {
      url = "Reports/GetStyleReport?";
    }
    if (filterGroupStaff) {
      url = "Reports/GroupStaffReport?";
    }
    let reportUrl =
      Utils.GetServiceUrl() +
      url +
      "branchId=" +
      branchId +
      "&businessId=" +
      localSettings.business.id +
      "&startDate=" +
      Utils.GetISODateTimeString(selectedDate[0]) +
      "&endDate=" +
      Utils.GetISODateTimeString(selectedDate[1]) +
      "&staffId=" +
      staffId +
      "&token=" +
      localSettings.token +
      "&culture=" +
      CountryHelper.GetAPICulture(localSettings.country.Culture);
    window.open(reportUrl, "_blank");

    setIsLoading(false);
  };

  const handleSubmit = () => {
    // handleShowReport(statementMethod);
    getStatementDownload(statementMethod)
  };

  const handleShowReport = async (statementMethod) => {
    let url = "Reports/StaffDetailedReport";
    var branchId =
      filterByBranch && selectedBranch !== null ? selectedBranch : "";
    const staffId =
      filterByStaff && selectedStaffBranch !== null ? selectedStaffBranch : "";
    if (statementMethod === "Summary") {
      url = "Reports/StaffSummaryReport";
    }
    setIsLoading(true);
    let response = await postAsync(url, {
      branchId: branchId,
      businessId: localSettings.business.id,
      startDate: selectedDate[0],
      endDate: selectedDate[1],
      staffId: staffId,
      Token: localSettings.token,
    });
    replaceImg(response.data);
    setIsLoading(false);
  };
  const replaceImg = (reportContent) => {
    var imgURL = Utils.GetServiceUrl() + "/images/";
    var webcontent = reportContent.replaceAll("/images/", imgURL);
    setReportContent(webcontent);
  };

  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      if (dateArray[0]._isValid == false || dateArray[1]._isValid == false) {
        setMsgText(
          "Please select a valid date range."
        );
        setMsgOpen(true);
      }
      else {
        let dateDiff = dateArray[1].diff(dateArray[0], "days");
        if (dateDiff > MaxDateLimit) {
          setMsgText(
            "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
          );

          setMsgOpen(true);
        } else {
          setSelectedDate(dateArray);
        }
      }
    }
  };

  const checkAuthentication = async () => {
    if (UserHelper.CheckPermission(StaffReportPage, navigate, localSettings)) {
      const business = getBusiness();
      if (
        !Utils.IsNullOrEmptyObject(business) &&
        !Utils.IsNullOrEmptyArray(business.branchLocations)
      ) {
        setBranches(business.branchLocations);
        await StaffList();
      }
    }
  };
  const handleInputStatement = (e) => {
    setStatementMethod(e.target.value);
    setFilterGroupStaff(false);
  };
  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleSelect = (event) => {
    setSelectedBranch(event.target.value);
    setStaffs(event.target.value);
  };
  const getStaffs = async () => {
    setIsLoading(true);
    let result = await postAsync("Account/GetUsers", {
      ServiceStaff: true,
    });
    setIsNavigateToLogin(result.isNavigateToLogin);
    setIsLoading(false);
    if (result.error) {
      setMsgOpen(true);
      setMsgText(result.errorMessage);
      return;
    }
    return result.data.userProfiles;
  };

  const StaffList = async (id) => {
    var staffs = await getStaffs()
    let temptech = !Utils.IsNullOrEmpty(id)
      ? staffs.filter((s) => s.branchId === id)
      : staffs;

    setBranchStaff(temptech);
  };

  const setStaffs = async (branchId) => {
    await StaffList(branchId);
  };
  const handleStaffSelect = (event) => {
    setSelectedStaffBranch(event.target.value);
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />

        <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.InvoiceReport} />
            <Grid xs={12} className="content-sec">
              <Grid container direction="row" className="">
                <ReportMenuItems
                  selectedMenu={SettingsMenuList.BusinessReports.Employee}
                />
                <Grid flex="1" spacing={2} className="form-sec">
                  <form container spacing={2} onSubmit={handleSubmitDownload}>
                    <Stack
                      alignSelf="center"
                      justifyContent="space-between"
                      direction="row"
                      flex="1"
                      className="text-end mb-2"
                    >
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        sx={{ flexWrap: "wrap" }}
                      >
                        <Stack direction="row" alignItems="center">
                          <Switch checked={filterByBranch} onChange={handleFilterByBranchChange} />
                          <Typography variant="h6" sx={{ whiteSpace: "nowrap", marginLeft: "8px" }}>
                            Filter by branch
                          </Typography>
                          {filterByBranch && (
                            <FormControl variant="outlined" className="font-size-13" sx={{ marginLeft: "5px", width: "150px" }}>
                              <InputLabel id="Branch-label">Branch</InputLabel>
                              <Select
                                value={selectedBranch}
                                onChange={handleSelect}
                                label="Branch"
                              >
                                                              {branches.map((branch, index) => (
                                                                  <MenuItem key={index} value={branch.branchId} onClick={() => setStaffs(branch.branchId)}>
                                                                      {branch.branchName}
                                                                  </MenuItem>
                                                              ))}
                              </Select>
                            </FormControl>
                          )}
                        </Stack>

                        <Stack direction="row" alignItems="center">
                          <Switch checked={filterByStaff} onChange={handleFilterByStaffBranchChange} />
                          <Typography variant="h6" sx={{ whiteSpace: "nowrap", marginLeft: "8px" }}>
                            Filter by staff
                          </Typography>
                          {filterByStaff && (
                            <FormControl variant="outlined" className="font-size-13" sx={{ marginLeft: "5px", width: "150px" }}>
                              <InputLabel id="Staff-label">Staff</InputLabel>
                              <Select
                                value={selectedStaffBranch}
                                onChange={handleStaffSelect}
                                label="Staff"
                              >
                                {!Utils.IsNullOrEmptyArray(branchStaff) &&
                                  branchStaff.map((staff, index) => (
                                    <MenuItem key={index} value={staff.id}>
                                      {staff.userName}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          )}
                        </Stack>
                        {statementMethod === "Detailed" && (
                          <Stack direction="row" alignItems="center">
                            <Switch checked={filterGroupStaff} onChange={handleFilterGroupStaffChange} />
                            <Typography variant="h6" sx={{ whiteSpace: "nowrap", marginLeft: "8px" }}>
                              Group Staff
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ height: "100%" }}
                      >
                        <DateRangePicker
                          showLeadingZeros={true}
                          onChange={onDateChange}
                          maxDate={new Date()}
                          value={selectedDate}
                          locale={CountryHelper.GetDateCulture(localSettings.country.Culture)}
                          clearIcon={null}
                          calendarIcon={
                            <CalendarMonthOutlinedIcon
                              style={{
                                "font-size": "24px",
                                "align-self": "center",
                                color: "#666666",
                              }}
                            />
                          }
                        />
                      </Stack>
                    </Stack>

                    <Grid
                      spacing={2}
                      rowSpacing={2}
                      className="card mt-1 font-size-14 white-bg"
                      padding={1}
                    >
                      <RadioGroup
                        value={statementMethod}
                        onChange={(e) => handleInputStatement(e)}
                        InputLabelProps={{ shrink: true }}
                      >
                        <Stack direction="row" spacing={4} justifyContent="space-between">
                          <FormControlLabel
                            value={"Summary"}
                            control={<Radio />}
                            label="Summary"
                          />
                          <FormControlLabel
                            value={"Detailed"}
                            control={<Radio />}
                            label="Detailed"
                          />
                          <FormControlLabel
                            value={"Stylist"}
                            control={<Radio />}
                            label="Stylist"
                          />
                        </Stack>
                      </RadioGroup>
                    </Grid>
                  </form>
                  {/* <iframe
                    className="reportIframe card white-bg"
                    srcdoc={reportContent}
                    style={{
                      width: "100%",
                      height: "calc(99vh - 200px)",
                      marginTop: "7px",
                    }}
                  ></iframe>
                  <br /> */}

                  <Stack
                    direction="row"
                    className="fixed-btn"
                  >
                    <Grid container className="container"
                      justifyContent="end"
                    >
                      <Grid xs={12} md={3}></Grid>
                      <Grid xs={12} md={9}>
                        <Stack direction="row" justifyContent="end" spacing={1} className="font-size-14">
                          <FormControl>
                            <Button
                              className="btn btn-primary"
                              variant="contained"
                              type="submit"
                              onClick={handleSubmitDownload}
                            >
                              View / Download Employee Report
                            </Button>
                          </FormControl>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default StaffsReport;
