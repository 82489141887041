import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Utils from "../../core/Utils";

const InsuranceClaimComponent = ({
  insuranceClaimNumber,
  setInsuranceClaimNumber,
  insuranceType,
  setInsuranceType,
  insuranceEstimatedRepairDays,
  setInsuranceEstimatedRepairDays,
  insuranceEstimateValidity,
  setInsuranceEstimateValidity,
  insuranceInDate,
  setInsuranceInDate,
  insuranceOutDate,
  setInsuranceOutDate
}) => {
    const insuranceTypes = [
        "Abu Dhabi National Insurance (ADNIC)",
        "Abu Dhabi National Takaful (ADNTC)",
        "Al Ain Ahlia Car Insurance",
        "Al Buhaira National Insurance Company (ABNIC)",
        "Al Fujairah Insurance (AFNIC)",
        "Al Wathba (AWNIC)",
        "Al Wathba National Insurance Co",
        "Dubai Insurance Company (DIC)",
        "Insurance House",
        "Qatar Insurance (QIC)",
        "RSA Insurance",
        "Salama Car Insurance",
        "SALAMA Islamic Arab Insurance Company",
        "Salama Takaful Insurance",
        "Sharjah Insurance",
        "Sukoon Insurance Company",
        "Yas Takaful ",
      ];
  return (
    <>
      <Stack direction="row" spacing={1} className="xs-column mt-3">
        <Typography
          level="h3"
          component="h3"
          fontWeight="600"
          fontSize="2rem"
          className="h3 title-lbl"
        >
          Insurance claim Details
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormControl fullWidth>
          <TextField
            id="jobcardClaimNumber"
            label="Claim Number"
            variant="outlined"
            value={insuranceClaimNumber}
            inputProps={{ maxLength: 30 }}
            onChange={(e) => {
              setInsuranceClaimNumber(e.target.value);
            }}
            placeholder="Enter claim number"
          />
        </FormControl>
        <FormControl fullWidth className="font-size-14">
          <InputLabel id="demo-simple-select-label">
            Type of Insurance
          </InputLabel>
          <Select
            id="jobcardInsuranceType"
            label="Type of Insurance"
            variant="outlined"
            onChange={(e) => {
              setInsuranceType(e.target.value);
            }}
            value={insuranceType}
            title={insuranceType}
          >
            {!Utils.IsNullOrEmptyArray(insuranceTypes) &&
              insuranceTypes.map((item, index) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            id="jobcardEstimatedNumberOfRepairDays"
            label="Estimated No. of Repair Days"
            variant="outlined"
            value={insuranceEstimatedRepairDays}
            type="number"
            inputProps={{ maxLength: 3 }}
            onChange={(e) => {
              setInsuranceEstimatedRepairDays(e.target.value);
            }}
            placeholder="Enter days"
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            id="jobcardEstimateValidity"
            label="Estimate Validity"
            type="number"
            variant="outlined"
            value={insuranceEstimateValidity}
            inputProps={{ maxLength: 3 }}
            onChange={(e) => {
              setInsuranceEstimateValidity(e.target.value);
            }}
            placeholder="Enter validity"
          />
        </FormControl>
        <FormControl fullWidth className="my-xs-1">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="veihcleInDate"
              label="Vehicle In Date"
              value={insuranceInDate}
              disablePast={false}
              onChange={(newValue) => setInsuranceInDate(newValue)}
              closeOnSelect={true}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth className="my-xs-1">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="veicleOutDate"
              label="Vehicle Out Date"
              value={insuranceOutDate}
              disablePast={false}
              onChange={(newValue) => setInsuranceOutDate(newValue)}
              closeOnSelect={true}
            />
          </LocalizationProvider>
        </FormControl>
      </Stack>
    </>
  );
};
export default InsuranceClaimComponent;
