import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";
import { Stack, Card, Typography, Grid, Box, Switch, Modal, Button, FormControl } from "@mui/material";
import Logo_Icon from "../images/Infopoint.png";
import { useNavigate } from "react-router-dom";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import NoCrashOutlinedIcon from "@mui/icons-material/NoCrashOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Handshake from "@mui/icons-material/Handshake";
import { Link } from "react-router-dom";
import Utils from "../core/Utils";
import UserHelper from "../core/UserHelper";
import support_white from "../images/support_white.png";
import ConfirmPopup from "./common/ConfirmPopup";
import { CardActionArea } from "@mui/material";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import {
  HideOtherMenues,
  BusinessCategories,
  ConfirmTypes,
  SideBarMenuNames,
  UserRoles,
  UAE,
  USA,
  India,
} from "../core/Constants";
import {
  getBusiness,
  getBranch,
  getCountry,
  getUser,
  getIsDarkMode,
  setIsDarkMode,
} from "../data/localSettingsActions";
import BranchPopup from "./common/BranchPopup";
import BusinessHelper from "../core/BusinessHelper";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import {
  LoginPage,
  CheckOutPage,
  CustomersPage,
  CampaignsPage,
  OverviewPage,
  DetailedOverviewPage,
  SettingsPage,
  VisitsPage,
  AMCPlanPage,
  EstimatesPage,
  CalanderPage,
  BusinessSetupPage,
  InvoiceReportPage,
  ProfilePage,
  SparesPage,
} from "../core/PageConstants";
import SessionTimeOut from "./common/SessionTimeOut";
import { connectToStore } from "../data/store";
import IndiaFlag from "../images/IndiaFlag.png";
import UAEFlag from "../images/UAE.png";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';


import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import EditNoteIcon from '@mui/icons-material/EditNote';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import { DescriptionOutlined, EditNote, EditOutlined } from "@mui/icons-material";
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

import WhatsApp from "../images/whatsapp.png";
import CloseIcon from "@mui/icons-material/Close";
import Support_image from "../images/support.png";
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import MessagePopup from "./common/MessagePopUp";

const SideBarMenuItems = ({ selectedTab }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [showBranch, setShowBranch] = useState(false);
  const [branch, setBranch] = useState({});
  const [user, setUser] = useState({});
  const [countryInfo, setCountryInfo] = useState({});
  const [businessCategory, setBusinessCategory] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDark, setIsDark] = useState(getIsDarkMode());
  const [isSessionTimeOut, setIsSessionTimeOut] = useState(false);
  const localSettings = connectToStore();
  const [isContctUs, setIsContctUs] = useState(false);
  const [supportNumber, setSupportNumber] = useState([]);
  const [setupPath, setSetupPath] = useState(SparesPage.Path);
  const [anchorEl, setAnchorEl] = useState(null);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const openAccount = Boolean(anchorEl);
  const handleClickAccount = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const LogoutUser = () => {
    setConfirmText("Continue logging out?");
    setConfirmOpen(true);
  };

  useEffect(() => {
    var head = document.getElementsByTagName("head")[0];
    if (isDark) {
      document.getElementById("DarkMode").href = "./DarkMode.css";
    } else {
      document.getElementById("DarkMode").href = "./LightMode.css";
    }
  }, [isDark]);

  useEffect(() => {
    loadValues();
  }, [selectedTab]);

  const loadValues = () => {
    if (Utils.IsNullOrEmpty(selectedTab)) {
      return;
    }
    setBranch(getBranch());
    setUser(getUser());
    setCountryInfo(getCountry());
    setBusinessCategory(BusinessHelper.GetBusinessCategory(getBusiness()));
  };

  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleBranchChange = (branchSelected, branch) => {
    setShowBranch(false);
    if (branchSelected) {
      navigate(OverviewPage.Path);
    }
  };

  const handleBranchOpen = () => {
    setShowBranch(user.role === UserRoles.GlobalAdmin);
  };

  const handleDarkModeChange = (event) => {
    setIsDark(event.target.checked);
    setIsDarkMode(event.target.checked, dispatch);
    if (event.target.checked) {
      document.getElementById("DarkMode").href = "./DarkMode.css";
    } else {
      document.getElementById("DarkMode").href = "./LightMode.css";
    }
  };
  const checkSupportNumber = () => {
    if (isContctUs === true) {
      if (Utils.IsNullOrEmptyArray(localSettings.apiWhatsAppSupportNumbers)) {
        let temp = localSettings.LocalWhatsAppSupportMobileNumber.filter(
          i => i.Code === countryInfo.Code.toString()
        );
        setSupportNumber(temp.length > 0 ? temp : []);
      } else {
        let supportNumbers = Object.entries(localSettings.apiWhatsAppSupportNumbers).map(
          ([code, number]) => ({ Code: code, MobileNumber: number })
        );

        let temp = supportNumbers.filter(
          i => i.Code === countryInfo.Code.toString()
        );
        setSupportNumber(temp.length > 0 ? temp : []);
      }
    }
  };
  useEffect(() => {
    checkSupportNumber()
  }, [isContctUs]);

  const whatsAppContactUs = (number) => {
    if (!Utils.IsNullOrEmpty(number)) {
      let mNumber = number.Code + number.MobileNumber;
      let url = `https://api.whatsapp.com/send?phone=${mNumber.substring(1)}`;
      window.open(encodeURI(url), "_blank");
    }
  };
  const handleCheckOutClick = (e) => {
    if (!UserHelper.CanBilling()) {
      e.preventDefault();
      setMsgOpen(true);
      setMsgText("You do not have access to the checkout/billing module. Please contact your supervisor to gain access.");
    }
  };
  const handleMsgClose = () => {
    setMsgOpen(false);
  };

  return (
    <Grid className="leftMenu">
      <Grid className="container">
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <SessionTimeOut open={isSessionTimeOut} />
        <BranchPopup
          dispatch={dispatch}
          open={showBranch}
          onClose={handleBranchChange}
        />
        <Modal open={isContctUs}>
          <Box className="custom-prod" padding={4}>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                level="h2"
                component="h2"
                fontWeight="600"
                fontSize="2rem"
                alignSelf="center"
              >
                We're here to assist you
              </Typography>
              <IconButton aria-label="close" onClick={() => setIsContctUs(false)}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack spacing={2}>
              <Typography
                level="h4"
                component="h4"
                fontWeight="400"
                fontSize="1.4rem"
              >
                Give us a call or send a message!
              </Typography>
              {(!Utils.IsNullOrEmptyArray(supportNumber) &&
                supportNumber.map((number, index) => (
                  <Box key={index} style={{ cursor: "pointer" }} className="w-100">
                    <Card className="card"
                      direction="row"
                      sx={{
                        padding: "20px",
                      }}
                      onClick={() => whatsAppContactUs(number)}
                    >
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        {/* <Box
                          component="img"
                          sx={{
                            height: "28px",
                            width: 34,
                          }}
                          alt="Contact us"
                          src={number.Code === India.Code ? IndiaFlag : UAEFlag}
                        /> */}
                        <Stack direction="row">
                          <Box
                            component="img"
                            sx={{
                              height: "28px",
                            }}
                            alt="WhatsApp"
                            src={WhatsApp}
                          />
                          <Typography
                            level="h2"
                            component="h2"
                            fontWeight="600"
                            fontSize="1.8rem"
                            alignSelf="center"
                            marginLeft="10px"
                          >
                            {number.Code} {number.MobileNumber}
                          </Typography>
                        </Stack>
                        <NavigateNextOutlinedIcon sx={{ fontSize: "24px" }} />
                      </Stack>
                    </Card>
                  </Box>
                ))) || <p>No WhatsApp support numbers available.</p>}
            </Stack>
            <Box
              alignSelf="center"
              component="img"
              sx={{ position: "absolute", bottom: 0, left: 0, right: 0, width: "70%", margin: "auto" }}
              alt="Support"
              src={Support_image}
            />
          </Box>
        </Modal>
        <input type="checkbox" className="menu-check" id="menuCheck" />
        <label
          for="menuCheck"
          size="large"
          className="btn btn-secondary refresh-icon menu-icon"
        >
          <MenuOutlinedIcon className="menuOpen" />
          <CloseOutlinedIcon className="menuClose" />
        </label>
        <Grid container justifyContent="space-between" className="leftMenuInner" spacing={0}>
          <Stack direction="row" spacing={1} className="logo-sec">
            <Box
              alignSelf="center"
              className="logo-img"
              component="img"
              sx={{
                width: 34,
              }}
              src={Logo_Icon}
              onClick={handleBranchOpen}
            />
            <Typography
              level="h1"
              component="h1"
              fontWeight="600"
              fontSize="1.7rem"
              className="h1 logo-text"
              alignSelf="center"
              onClick={handleBranchOpen}
            >
              {branch.branchName}
            </Typography>
          </Stack>
          <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} className="topMenu">
              <Link to={OverviewPage.Path}>
                <Tooltip arrow title="Overview Dashboard">
                  <IconButton
                    aria-label="Overview Dashboard"
                    style={{
                      "align-self": "center",
                      "color": "#000000"
                    }}
                  >
                    <HomeOutlinedIcon style={{ "font-size": "28px" }} />
                  </IconButton>
                </Tooltip>
              </Link>
              <Link to={CustomersPage.Path} state={{ fromJobcard: false }}>
                <Tooltip arrow title="Customers">
                  <IconButton
                    aria-label="Customers"
                    style={{
                      "align-self": "center",
                      "color": "#000000"
                    }}
                  >
                    <PeopleAltOutlinedIcon style={{ "font-size": "28px" }} />
                  </IconButton>
                </Tooltip>
              </Link>
              {businessCategory == BusinessCategories.Automobile ? (
                <>
                  <Link to={CheckOutPage.Path} state={{ onSuccess: OverviewPage.Path, navigationStack: [OverviewPage.Path], }} onClick={handleCheckOutClick} className="btn-secondary mx-1">
                    <CheckCircleOutlineOutlinedIcon sx={{ fontSize: "24px", alignSelf: "Center" }} /><span className="ml-h" style={{ transform: "translate(0px, 1px" }}>Check-Out</span>
                  </Link>
                  <Link to={CustomersPage.Path} state={{ fromJobcard: true }} className="cs-btn mr-1">
                    <EditNoteIcon sx={{ fontSize: "24px" }} alignSelf="Center" /><span> &nbsp; Create Job Card</span>
                  </Link>
                </>

              ) : (
                <>
                  <Link to={CheckOutPage.Path} state={{ onSuccess: OverviewPage.Path, navigationStack: [OverviewPage.Path], }} className="btn-secondary mx-1">
                    <CheckCircleOutlineOutlinedIcon sx={{ fontSize: "24px", alignSelf: "Center" }} /><span className="ml-h" style={{ transform: "translate(0px, 1px" }}> Billing</span>
                  </Link>
                  <Link to={CalanderPage.Path} className="cs-btn mr-1">
                    <EditNoteIcon sx={{ fontSize: "24px" }} alignSelf="Center" /><span> &nbsp; Appointments</span>
                  </Link>
                </>
              )}
              {/* <IconButton
                    aria-label="Notification"
                    style={{
                      "align-self": "center",
                      "color": "#000000"
                    }}
                  >
                <NotificationsNoneIcon style={{"font-size":"28px"}} />
              </IconButton> */}
              <Tooltip arrow title="Account settings">
                <IconButton
                  onClick={handleClickAccount}
                  size="Large"
                  sx={{ ml: 1, padding: 0 }}
                  aria-controls={openAccount ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openAccount ? 'true' : undefined}
                >
                  <Avatar sx={{ width: 46, height: 46, padding: 0 }}
                    alt={localSettings.user.firstName}
                    src={`${localSettings.business.logoUrl}?${Date.now()}`}
                    className="profile-pic" />
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              className="account-menu"
              open={openAccount}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

              <Stack alignItems="center" className="avatar-sec">
                <Link to={ProfilePage.Path}>
                  <IconButton className="profile-icon" title="Profile">
                    <ManageAccountsOutlinedIcon style={{ "font-size": "28px" }} />
                  </IconButton>
                </Link>
                <Avatar
                  alt={localSettings.user.firstName}
                  src={`${localSettings.business.logoUrl}?${Date.now()}`}
                  className="profile-pic"
                />
                <Box className="text-center">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="1.5rem"
                    className="h2"
                  >
                    {localSettings.user.firstName}
                  </Typography>
                  <Typography
                    level="p"
                    component="p"
                    fontSize="12px"
                    marginBottom="10px"
                  >
                    Referral Code: <b>{localSettings.business.referralCode}</b>
                  </Typography>
                </Box>
              </Stack>

              <Card
                className={
                  "card " +
                  (selectedTab == SideBarMenuNames.Overview ? "active" : null)
                }
              >
                <Link to={OverviewPage.Path}>
                  <CardActionArea>
                    <PieChartOutlineIcon fontSize="large" />
                    <Typography component="span">Overview</Typography>
                  </CardActionArea>
                </Link>
              </Card>

              <Card
                className={
                  "card " +
                  (selectedTab == SideBarMenuNames.Visits ? "active" : null)
                }
              >
                <Link to={VisitsPage.Path} state={{}}>
                  <CardActionArea>
                    <FactCheckOutlinedIcon fontSize="large" />
                    <Typography component="span">Visits</Typography>
                  </CardActionArea>
                </Link>
              </Card>
              {businessCategory === BusinessCategories.Salon && (
                <Card
                  className={
                    "card " +
                    (selectedTab == SideBarMenuNames.Calendar ? "active" : null)
                  }
                >
                  <Link to={CalanderPage.Path} state={{}}>
                    <CardActionArea>
                      <EventAvailableIcon fontSize="large" />
                      <Typography component="span">Appointments</Typography>
                    </CardActionArea>
                  </Link>
                </Card>)}

              {countryInfo.Code !== USA.Code && (businessCategory === BusinessCategories.Automobile || businessCategory === BusinessCategories.Salon) && (
                <Card
                  className={
                    "card " +
                    (selectedTab == SideBarMenuNames.AMCPlan ? "active" : null)
                  }
                >
                  <Link to={AMCPlanPage.Path}>
                    <CardActionArea>
                      <Handshake fontSize="large" />
                      <Typography component="span">
                        Membership Plan
                      </Typography>
                    </CardActionArea>
                  </Link>
                </Card>
              )}

              {businessCategory == BusinessCategories.Automobile && (
                <>
                  {/* <Card
                    className={
                      "card " +
                      (selectedTab == SideBarMenuNames.JobCard ? "active" : null)
                    }
                  >
                    <Link to={CustomersPage.Path} state={{ fromJobcard: true }}>
                      <CardActionArea>
                        <NoCrashOutlinedIcon fontSize="large" />
                        <Typography component="span">Job Card</Typography>
                      </CardActionArea>
                    </Link>
                  </Card> */}
                  {countryInfo.Code === UAE.Code && businessCategory == BusinessCategories.Automobile && (
                    <Card
                      className={
                        "card " +
                        (selectedTab == SideBarMenuNames.Estimate ? "active" : null)
                      }
                    >
                      <Link to={EstimatesPage.Path} state={{}}>
                        <CardActionArea>
                          <RequestQuoteIcon fontSize="large" />
                          <Typography component="span">Estimate</Typography>
                        </CardActionArea>
                      </Link>
                    </Card>
                  )}
                </>
              )}
              {/* <Card
                className={
                  "card " +
                  (selectedTab == SideBarMenuNames.CheckOut ? "active" : null)
                }
              >
                <Link
                  to={CheckOutPage.Path}
                  state={{
                    onSuccess: OverviewPage.Path,
                    navigationStack: [OverviewPage.Path],
                  }}
                >
                  <CardActionArea>
                    <CheckCircleOutlineOutlinedIcon fontSize="large" />
                    <Typography component="span">
                      {businessCategory == BusinessCategories.Salon
                        ? "Billing"
                        : "Check-Out"}
                    </Typography>
                  </CardActionArea>
                </Link>
              </Card> */}
              <Card
                className={
                  "card " +
                  (selectedTab == SideBarMenuNames.Customers ? "active" : null)
                }
              >
                <Link to={CustomersPage.Path} state={{ fromJobcard: false }}>
                  <CardActionArea>
                    <GroupsOutlinedIcon fontSize="large" />
                    <Typography component="span">Customers</Typography>
                  </CardActionArea>
                </Link>
              </Card>

              {/* Campaigns */}

              {/* <Card
                className={
                  "card " +
                  (selectedTab == SideBarMenuNames.Campaigns ? "active" : null)
                }
              >
                <Link to={CampaignsPage.Path}>
                  <CardActionArea>
                    <CampaignOutlinedIcon fontSize="large" />
                    <Typography component="span">Campaigns</Typography>
                  </CardActionArea>
                </Link>
              </Card> */}


              {/* <Card
              className={
                "card " +
                (selectedTab == SideBarMenuNames.Settings ? "active" : null)
              }
            >
              <Link to={SettingsPage.Path}>
                <CardActionArea>
                  <TuneOutlinedIcon fontSize="large" />
                  <Typography component="span">Settings</Typography>
                </CardActionArea>
              </Link>
              </Card>
               */}
              <Card
                className={
                  "card " +
                  (selectedTab == SideBarMenuNames.BusinessSetup ? "active" : null)
                }
              >
                <Link to={setupPath}>
                  <CardActionArea>
                    <TuneOutlinedIcon fontSize="large" />
                    <Typography component="span">Setups</Typography>
                  </CardActionArea>
                </Link>
              </Card>

              <Card
                className={
                  "card " +
                  (selectedTab == SideBarMenuNames.InvoiceReport ? "active" : null)
                }
              >
                <Link to={InvoiceReportPage.Path}>
                  <CardActionArea>
                    <DescriptionOutlined fontSize="large" />
                    <Typography component="span">Reports</Typography>
                  </CardActionArea>
                </Link>
              </Card>

              <Card
                className="card"
                onClick={() => setIsContctUs(true)}
              >
                <Link>
                  <CardActionArea>
                    <SupportAgentIcon fontSize="large" />
                    <Typography component="span">Support</Typography>
                  </CardActionArea>
                </Link>
              </Card>
              <Stack className="last-login topHeader">
                <Card
                  className={
                    "card " +
                    (selectedTab == SideBarMenuNames.Logout ? "active" : null)
                  }
                >
                  <Link to="javascript:void(0)" onClick={LogoutUser}>
                    <CardActionArea>
                      <LogoutOutlinedIcon fontSize="large" />
                      <Typography component="span">Logout</Typography>
                    </CardActionArea>
                  </Link>
                </Card>
              </Stack>
              <Typography
                level="p"
                component="p"
                fontSize="12px"
                className="op-h mt-0 last-login-datetime text-center"
                mt={0}
                padding={1}
              >
                Your last login was on &nbsp; <br />
                {Utils.IsNullOrEmptyObject(user)
                  ? ""
                  : Utils.GetDate(user.lastAccessed, countryInfo)}
              </Typography>
            </Menu>
          </React.Fragment>
          <ConfirmPopup
            confirmOpen={confirmOpen}
            confirmText={confirmText}
            onConfirmClose={handleConfirmClose}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SideBarMenuItems;


