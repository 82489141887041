import React, { useState, useEffect } from "react";
import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";
import Switch from "@mui/material/Switch";
import { Button } from "@mui/material";
import { postAsync } from "../core/serviceClient";
import { connectToStore } from "../data/store";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Loader from "./common/Loader";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import ConfirmPopup from "./common/ConfirmPopup";
import { ConfirmTypes } from "../core/Constants";
import { ImageEnlarge } from "./common/ImageEnlarge";
import { MaxFileSizeLimit } from "../core/Constants";

import { useLocation } from "react-router-dom";
import UserHelper from "../core/UserHelper";
import MessagePopup from "./common/MessagePopUp";
import SideBarMenuItems from "./SideBarMenuItems";
import { SideBarMenuNames } from "../core/Constants";
import SettingsMenuItems from "./SettingsMenuItems";
import Modal from "@mui/material/Modal";
import Utils from "../core/Utils";
import BusinessHelper from "../core/BusinessHelper";
import DecimalEntryField from "./common/controls/DecimalEntryField";
import {
  LoginPage,
  AddNewConsumablePage,
  ConsumablesPage,
} from "../core/PageConstants";
import { Cases } from "@mui/icons-material";
import SalesInfoComponent from "./common/SalesInfoComponent";

const AddNewConsumable = (props) => {
  document.title = "AddNewConsumables";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [localSettings, setLocalSettings] = useState({});
  const location = useLocation();
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [data, setData] = useState([]);
  const [description, setDescription] = useState("");
  const [consumableCode, setConsumableCode] = useState("");
  const [active, setActive] = useState(true);
  const [categoryDescription, setCategoryDescription] = useState("");
  const [type, setType] = useState("");
  const [typeDescription, setTypeDescription] = useState("");
  const [cost, setCost] = useState("");
  const [priceExclusive, setPriceExclusive] = useState("");
  const [taxPercentage, setTaxPercentage] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [priceExcludedTax, setPriceExcludedTax] = useState("");
  const [isDropdown, setIsDropdown] = useState(false);
  const [isDropdown1, setIsDropdown1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [priceModalopen, setPriceModalopen] = useState(false);

  const [Typelist, setTypelist] = useState([]);
  const [Catlist, setCatlist] = useState([]);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [enlargeImage, setEnlargeImage] = useState(false);
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isNavigateToConsumable, setIsNavigateToConsumable] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [setalertTitle, setAlertTitle] = useState("");
  const [confirmFor, setConfirmFor] = useState("");
  const [selectedConsumable, setselectedConumable] = useState(
    location.state && location.state.selectedConsumable
      ? location.state.selectedConsumable
      : {}
  );
  const [inclusiveOfTax, setInclusiveOfTax] = useState(false);
  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    } else if (isNavigateToConsumable) navigate(ConsumablesPage.Path);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorMsg = "";

    if (Utils.IsNullOrEmpty(name)) {
      errorMsg += "Please enter a proper Name.<br />";
    }
    if (Utils.IsNullOrEmpty(description)) {
      errorMsg += "Please enter a proper Description.<br />";
    }
    if (Utils.IsNullOrEmpty(consumableCode)) {
      errorMsg += "Please enter a valid Consumable Code.<br />";
    }
    if (Utils.IsNullOrEmpty(priceExclusive)) {
      errorMsg += "Please enter Price Exclusive of Tax.<br />";
    }

    if (!Utils.IsNullOrEmpty(errorMsg)) {
      setMsgText(errorMsg);
      setMsgOpen(true);
    } else {
      if (!Utils.IsNullOrEmptyObject(selectedConsumable)) {
        setConfirmFor("Update");
      } else if (Utils.IsNullOrEmptyObject(selectedConsumable)) {
        setConfirmFor("Submit");
      }
      setConfirmText("Are you sure you want to save changes?");
      setConfirmOpen(true);
    }
  };
  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
      if (confirmFor == "Submit") {
        ConsumablesSubmit();
      }
      if (confirmFor == "Update") {
        editConsumableDetails();
      }
    }
  };

  useEffect(() => {
    let localSetting = connectToStore();
    if (
      UserHelper.CheckPermission(AddNewConsumablePage, navigate, localSetting)
    ) {
      setLocalSettings(localSetting);
    }
  }, []);
  useEffect(() => {
    getConsumables();
  }, [localSettings]);

  const handleSwitchChange = (event) => {
    setIsDropdown1(event.target.checked);
    setCategory("");
  };

  const handleSwitchType = (event) => {
    setIsDropdown(event.target.checked);
    setType("");
  };

  const getImgObj = () => {
    return {
      url: Utils.IsNullOrEmpty(imageUrl)
        ? ""
        : imageUrl.startsWith("http")
          ? imageUrl
          : Utils.GetStringFromBase64(imageUrl),
      newFile: !Utils.IsNullOrEmpty(imageUrl) && !imageUrl.startsWith("http"),
    };
  };

  async function ConsumablesSubmit() {
    setIsLoading(true);
    const imgObj = getImgObj();
    var consumables = {
      BusinessId: JSON.parse(localStorage.getItem("business-entity")).id,
      Active: active,
      Description: description,
      Code: consumableCode,
      ShortDesc: name,
      Cost: Utils.IsNullOrEmpty(cost) ? 0 : parseFloat(cost),
      Price: Utils.IsNullOrEmpty(priceExclusive)
        ? 0
        : parseFloat(priceExclusive),
      TaxPercent: Utils.IsNullOrEmpty(taxPercentage)
        ? 0
        : parseFloat(taxPercentage),
      RetailPrice: Utils.IsNullOrEmpty(taxAmount) ? 0 : parseInt(taxAmount),
      Class: category,
      imageUrl: imgObj.url,
      ClassDescription: categoryDescription,
      Fineline: type,
      FinelineDescription: typeDescription,
      IsMaterial: true,
      Labour: false,
      Service: false,
      InclusiveOfTax: inclusiveOfTax,
      PriceIncludingTax: inclusiveOfTax ? Utils.ConvertToFloat(totalPrice) : 0
    };
    let result2 = await postAsync(
      "Product/SaveProduct",
      {
        BusinessId: localSettings.business.id,
        NewProduct: true,
        Product: consumables,
      },
      "",
      "Execute",
      "",
      [],
      imgObj.newFile
    );
    if (result2 && result2.data) {
      var isAutomobile = BusinessHelper.IsAutomobile(localSettings.business);

      setIsNavigateToConsumable(true);

      setMsgText(isAutomobile ? "Consumable Added successfully" : "Material Added successfully");
      setMsgOpen(true);
    } else {
      setMsgText("Unable to process your request");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }
  async function editConsumableDetails() {
    setConfirmText("Are you sure you want to make changes");
    setConfirmOpen(true);
    setIsLoading(true);
    const imgObj = getImgObj();
    let ss = selectedConsumable;
    ss["Active"] = active;
    ss["description"] = description;
    ss["shortDesc"] = name;
    ss["code"] = consumableCode;
    ss["class"] = category;
    ss["classDescription"] = categoryDescription;
    ss["fineline"] = type;
    ss["finelineDescription"] = typeDescription;
    ss["cost"] = parseFloat(cost);
    ss["imageUrl"] = imgObj.url;
    ss["price"] = parseFloat(priceExclusive);
    ss["taxPercent"] = parseFloat(taxPercentage);
    ss["inclusiveOfTax"] = inclusiveOfTax;
    ss["priceIncludingTax"] = inclusiveOfTax ? Utils.ConvertToFloat(totalPrice) : 0;

    let result2 = await postAsync(
      "Product/SaveProduct",
      {
        BusinessId: localSettings.business.id,
        NewProduct: false,
        Product: ss,
      },
      "",
      "Execute",
      "",
      [],
      imgObj.newFile
    );

    if (result2 && result2.data) {
      setIsNavigateToConsumable(true);
      setMsgText("Changes Saved successfully");
      setMsgOpen(true);
    } else {
      setMsgText("Unable to process your request");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }
  const getConsumables = async () => {
    if (localSettings.authenticated) {
      setIsLoading(true);
      let result1 = await postAsync(
        "Product/GetProduct",
        {},
        "",
        "GetAll",
        "AllProductsAndMaterialsForBusiness",
        [localSettings.business.id]
      );
      setIsNavigateToLogin(result1.isNavigateToLogin);
      if (result1.error) {
        setMsgOpen(true);
        setMsgText(result1.errorMessage);
      } else {
        if (!Utils.IsNullOrEmptyArray(result1.data)) {
          var Consumable = result1.data.filter(
            (item) =>
              item.isMaterial == true &&
              item.service == false &&
              item.labour == false
          );

          const temTypellist = [];
          const temCatllist = [];
          Consumable.forEach((element) => {
            if (
              !Utils.IsNullOrEmpty(element.fineline) &&
              !temTypellist.includes(element.fineline)
            ) {
              temTypellist.push(element.fineline);
            }
            if (
              !Utils.IsNullOrEmpty(element.class) &&
              !temCatllist.includes(element.class)
            ) {
              temCatllist.push(element.class);
            }
          });
          setTypelist(temTypellist);
          setCatlist(temCatllist);
        }
      }
      setIsLoading(false);
    }
  };
  const UploadConsumableImage = async (event) => {
    var file = event.target.files[0];
    if (file.size > MaxFileSizeLimit) {
      setAlertTitle("Warning");
      setMsgText(
        "Unable to upload. File size exceeds the maximum limit, please ensure that it's less than 10 MB."
      );
      setMsgOpen(true);
      return;
    }
    var base64 = await convertBase64(file);
    setImageUrl(base64);
  };
  const handleConsumablePhoto = (event) => {
    const fileInput = event.target;
    const ext = fileInput.value.match(/\.(.+)$/)[1];

    switch (ext) {
      case "jpg":
      case "jpeg":
      case "png":
        UploadConsumableImage(event);
        break;
      default:
        setMsgOpen(true);
        setMsgText("/jpg,/jpeg,/png file types only allowed .");
        fileInput.value = "";
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const deleteConsumablePhoto = () => {
    setMsgText("Consumable image has been removed");
    setMsgOpen(true);
    setImageUrl("");
  };
  const closeImage = () => {
    setEnlargeImage(false);
  };

  const openImage = () => {
    setEnlargeImage(true);
  };
  useEffect(() => {
    populateSpare();
  }, [selectedConsumable]);

  const populateSpare = () => {
    if (Utils.IsNullOrEmptyObject(selectedConsumable)) {
      return;
    }
    setName(selectedConsumable.shortDesc);
    setActive(selectedConsumable.active);
    setCategory(selectedConsumable.class);
    setCategoryDescription(selectedConsumable.classDescription);
    setImageUrl(selectedConsumable.imageUrl);
    setConsumableCode(selectedConsumable.code);
    setCost(selectedConsumable.cost);
    setDescription(selectedConsumable.description);
    setTypeDescription(selectedConsumable.finelineDescription);
    setPriceExclusive(selectedConsumable.price);
    setType(selectedConsumable.fineline);
    setPriceExclusive(selectedConsumable.price);
    setTaxPercentage(selectedConsumable.taxPercent);
    setInclusiveOfTax(selectedConsumable.inclusiveOfTax);
    setTotalPrice(Utils.IsNullOrEmpty(selectedConsumable.priceIncludingTax) ? "" : selectedConsumable.priceIncludingTax.toFixed(2))
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
        />
        <ImageEnlarge
          open={enlargeImage}
          imgSrc={imageUrl}
          onClose={closeImage}
        />

        <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.BusinessSetup} />
            <Grid xs={12} className="content-sec">
              <Grid container direction="row" className="">
                <SettingsMenuItems
                  selectedMenu={BusinessHelper.GetConsumeableMenuName()}
                />

                <Grid flex="1" spacing={2} className="form-sec">
                  <form
                    className="cs-form"
                    style={{ paddingBottom: "60px" }}
                    onSubmit={handleSubmit}
                  >
                    <Stack spacing={3}>
                      {Utils.IsNullOrEmpty(imageUrl) && (
                        <Stack direction="row" spacing={2}>
                          <label>
                            <AddAPhotoIcon style={{ "font-size": "120px", "opacity": "0.3" }} />
                            <Input
                              type="file"
                              name="myImage"
                              className="hide"
                              accept="image/png, image/jpeg, image/jpg"
                              onChange={(e) => handleConsumablePhoto(e)}
                            />
                          </label>
                        </Stack>
                      )}

                      {!Utils.IsNullOrEmpty(imageUrl) && (
                        <div className="card imgBox">
                          <img
                            alt=" image formate Should be in /png,/jpg,/jpeg,/ico"
                            width={"150px"}
                            src={imageUrl}
                            onClick={openImage}
                          />
                          <br />
                          <button
                            className="p-0 mt-1 btn btn-default"
                            onClick={deleteConsumablePhoto}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      )}

                      <Typography
                        level="h3"
                        component="h3"
                        fontWeight="600"
                        fontSize="1.2rem"
                        className="h3 m-0"
                      >
                        Tap to select Image
                      </Typography>
                    </Stack>

                    <Stack sx={{ minWidth: 120 }} spacing={2} className="mt-3">
                      <FormControl fullWidth>
                        <TextField
                          label="Name"
                          value={name}
                          error={!name}
                          onChange={(e) => setName(e.target.value)}
                          inputProps={{ style: { fontSize: 13 } }}
                          placeholder="Enter name"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          label="Description"
                          value={description}
                          error={!description}
                          onChange={(e) => setDescription(e.target.value)}
                          inputProps={{ style: { fontSize: 13 } }}
                          placeholder="Enter description"
                        />
                      </FormControl>
                      <Stack direction="row" spacing={2}>
                        <FormControl fullWidth>
                          <TextField
                            label="Consumable Code "
                            value={consumableCode}
                            error={!consumableCode}
                            onChange={(e) => setConsumableCode(e.target.value)}
                            inputProps={{ style: { fontSize: 13 }, maxLength: 5 }}
                            placeholder="Enter code"
                          />
                        </FormControl>
                        <FormControl fullWidth style={{ "align-self": "center" }}>
                          <Box>
                            <FormControlLabel
                              style={{ "margin-bottom": "0" }}
                              control={
                                <Switch
                                  checked={active}
                                  onChange={(e) => setActive(e.target.checked)}
                                  InputLabelProps={{ shrink: true }}
                                  name="Active"
                                  color="primary"
                                />
                              }
                              value={active}
                              label="Active"
                              labelPlacement="start"
                            />
                          </Box>
                        </FormControl>
                      </Stack>
                      <Stack alignContent="space-between">
                        <Stack
                          direction="row"
                          alignItems="baseline"
                          justifyContent="space-between"
                        >
                          <Typography
                            level="h3"
                            component="h3"
                            fontWeight="700"
                            fontSize="1.5rem"
                            className="h3"
                          >
                            Enter New Category
                          </Typography>

                          <FormControlLabel
                            style={{ margin: "0" }}
                            control={
                              <Switch
                                checked={isDropdown1}
                                onChange={handleSwitchChange}
                              />
                            }
                          />
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          {!isDropdown1 ? (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              margin="normal"
                            >
                              <InputLabel id="category-label">
                                Category
                              </InputLabel>
                              <Select
                                inputProps={{ style: { fontSize: 13 } }}
                                labelId="Category-label"
                                id="category-select"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                label="Category"
                                style={{ height: "51.5px" }}
                              >
                                {!Utils.IsNullOrEmptyArray(Catlist) &&
                                  Catlist.map((item) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <TextField
                              inputProps={{ style: { fontSize: 13 } }}
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              label="Category"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                              placeholder="Enter category"
                            />
                          )}
                          <FormControl fullWidth>
                            <TextField
                              inputProps={{ style: { fontSize: 13 } }}
                              fullWidth
                              variant="outlined"
                              margin="0"
                              label="Category Description"
                              value={categoryDescription}
                              onChange={(e) =>
                                setCategoryDescription(e.target.value)
                              }
                              placeholder="Enter description"
                            />
                          </FormControl>
                        </Stack>
                      </Stack>

                      <Stack alignContent="space-between">
                        <Stack
                          direction="row"
                          alignItems="baseline"
                          justifyContent="space-between"
                        >
                          <Typography
                            level="h3"
                            component="h3"
                            fontWeight="700"
                            fontSize="1.5rem"
                            className="h3"
                          >
                            Enter New Type
                          </Typography>
                          <FormControlLabel
                            style={{ margin: "0" }}
                            control={
                              <Switch
                                checked={isDropdown}
                                onChange={handleSwitchType}
                              />
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          {!isDropdown ? (
                            <FormControl fullWidth variant="outlined" margin="0">
                              <InputLabel id="Type-label">Type</InputLabel>
                              <Select
                                inputProps={{ style: { fontSize: 13 } }}
                                labelId="Type-label"
                                id="Type-select"
                                label="Type"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                style={{ height: "51.5px" }}
                              >
                                {!Utils.IsNullOrEmptyArray(Typelist) &&
                                  Typelist.map((item) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <TextField
                              inputProps={{ style: { fontSize: 13 } }}
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              label="Type"
                              value={type}
                              onChange={(e) => setType(e.target.value)}
                              placeholder="Enter type"
                            />
                          )}
                          <FormControl fullWidth>
                            <TextField
                              inputProps={{ style: { fontSize: 13 } }}
                              fullWidth
                              variant="outlined"
                              margin="0"
                              label="Type Description"
                              value={typeDescription}
                              onChange={(e) => setTypeDescription(e.target.value)}
                              placeholder="Enter description"
                            />
                          </FormControl>
                        </Stack>
                      </Stack>

                      <SalesInfoComponent
                        inclusiveOfTax={inclusiveOfTax}
                        setInclusiveOfTax={setInclusiveOfTax}
                        cost={cost}
                        setCost={setCost}
                        priceExclusive={priceExclusive}
                        setPriceExclusive={setPriceExclusive}
                        taxPercentage={taxPercentage}
                        setTaxPercentage={setTaxPercentage}
                        totalPrice={totalPrice}
                        setTotalPrice={setTotalPrice}
                        taxAmount={taxAmount}
                        setTaxAmount={setTaxAmount}
                      />

                      {UserHelper.CanEdit() && (
                        <Stack
                          direction="row"
                          className="fixed-btn"
                        >
                          <Grid className="container"
                            justifyContent="end"
                          >
                            <Stack direction="row" justifyContent="end">
                              <FormControl>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  className="btn btn-primary"
                                >
                                  Save
                                </Button>
                              </FormControl>
                            </Stack>
                          </Grid>
                        </Stack>
                      )}
                    </Stack>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default AddNewConsumable;

