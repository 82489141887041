import React, { useState, useEffect } from "react";
import "../../Style.css";
import "../../bootstrap.min.css";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Grid, Button, Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Utils from "../../core/Utils";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SideBarMenuItems from "./../SideBarMenuItems";
import { SideBarMenuNames, VisitTypes } from "../../core/Constants";
import MessagePopup from "./../common/MessagePopUp";
import { postAsync } from "../../core/serviceClient";
import moment from "moment";
import Loader from "./../common/Loader";
import UserHelper from "../../core/UserHelper";
import { AMCPlanPage, AMCViewPlanPage, CheckoutDetailsPage, CustomersPage, DeliveredJobCardDetailsPage, JobCardDetailsPage, LoginPage, VisitsPage } from "../../core/PageConstants";
import { styled } from "@mui/system";
import { GridCloseIcon } from "@mui/x-data-grid";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const AMCViewPlan = () => {
  document.title = "Customer Membership Plans";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [AMCPlanDetails, setAMCPlanDetails] = useState([]);
  const [planCheckVisits, setPlanCheckVisits] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [AMCBenefits, setAMCBenefits] = useState([]);
  const [isBenefitsView, setIsBenefitsView] = useState(false);
  const [isPlanViewCheck, setIsPlanViewCheck] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [msgText, setMsgText] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);

  useEffect(() => {
    if (UserHelper.CheckPermission(AMCPlanPage, navigate)) {
      if (Utils.IsNullOrEmptyObject(location.state)) {
        navigate(CustomersPage.Path);
        return;
      }
      var customer = location.state.pass;
      setSearchText(location.state.searchText);
      if (!Utils.IsNullOrEmpty(customer)) {
        getAMCPlan(customer);
      }
    }
  }, []);

  async function getAMCPlan(customer) {
    setIsloading(true)
    var response = await postAsync("AMC/GetAllCustomerAMCPlans", {
      PassId: customer.id,
    });
    if (response.error) {
      setMsgOpen(true);
      setMsgText(response.errorMessage);
    }
    if (!Utils.IsNullOrEmptyArray(response.data)) {
      var amcplan = response.data;
      setAMCPlanDetails(amcplan);
    }
    setIsloading(false)
  }

  const alertClose = () => {
    setShowAlert(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const viewBenefits = (benefits) => {
    setIsBenefitsView(true);
    setAMCBenefits(benefits);
  };

  const goBack = () => {
    navigate(CustomersPage.Path, {
      state: {
        searchText: searchText,
      },
    });
  };
  const onCloseAMCPlanBenfits = () => {
    setIsBenefitsView(false);
  };

  const onClosePlanChecks = () => {
    setIsPlanViewCheck(false)
  }
  async function planViewVisits(benefits) {
    setIsloading(true);
    var visitIds = benefits.redemptions.map(r => r.visitId);
    var response = await postAsync("AMC/GetCustomerVisitsByRedemptions",
      visitIds
    );
    if (response.error) {
      setMsgOpen(true);
      setMsgText(response.errorMessage);
    }
    if (!Utils.IsNullOrEmptyArray(response.data)) {
      var planCheck = response.data;
      setPlanCheckVisits(planCheck);
      setIsPlanViewCheck(true);
    }
    setIsloading(false);
  }
  const viewVisitDetails = (Details) => {
    if (Details.visitType === VisitTypes.CheckOut) {
      navigate(CheckoutDetailsPage.Path, {
        state: {
          ...location.state,
          visit: Details,
          isFromAMCViewPlan: true,
          navigationStack: Utils.AddElementToArray(
            location.state.navigationStack,
            AMCViewPlanPage.Path
          ),
        },
      });
    } else {
      navigate(DeliveredJobCardDetailsPage.Path, {
        state: {
          ...location.state,
          visit: Details,
          isFromAMCViewPlan: true,
          navigationStack: Utils.AddElementToArray(
            location.state.navigationStack,
            AMCViewPlanPage.Path
          ),
        },
      });
    }
  }

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={showAlert}
          msgText={alertText}
          onMsgClose={alertClose} />
        <Box>
        {/* Benefits view */}
        <BootstrapDialog
          onClose={onCloseAMCPlanBenfits}
          aria-labelledby="customized-dialog-title"
          className="cs-modal-sec"
          open={isBenefitsView}
          maxWidth="50px"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={onCloseAMCPlanBenfits}>
            <Typography
              level="h3"
              component="h3"
              fontWeight="600"
              fontSize="2rem"
              className="h3">
              Plan Benefits
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent dividers className="modal-dialog-content font-size-14">
            {AMCBenefits.map((Details) => (
              <Grid
                container
                xs={12}
                padding="10px"
                className="card expense-card mb-1"
                onClick={() => planViewVisits(Details)}
                key={Details.id}
                style={{ minWidth: "100px" }}
              >
                <Grid item xs={12} className="">
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={6}
                    style={{ marginBottom: "10px" }} >
                    <Typography
                      variant="h6"
                      component="h6"
                      fontSize="17px"
                      className="h6 m-0"
                      fontWeight="600"
                    >
                      {Details.description}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={10}>
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={1}>
                      <Typography>Redemptions Done</Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        fontWeight="600"
                        fontSize="14px"
                        className="h6 m-0"
                      >
                        {Details.redemptions.length}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={1}>
                      <Typography>Remainig Redemption</Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        fontWeight="600"
                        fontSize="14px"
                        className="h6 m-0"
                      >
                        {Details.maximumNoOfRedemptions > 0 ? Details.remainingRedemptions : "Unlimited"}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                  </Stack>
                </Grid>
                <Stack
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  spacing={1}
                  className="text-right">
                  <Typography style={{ marginRight: "10px" }}>Total Amount Saved</Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    fontWeight="600"
                    fontSize="14px"
                    className="h6 m-0"
                  >
                    {Utils.GetCurrency(Utils.IsNullOrEmptyArray(Details.redemptions)
                      ? 0
                      : Details.redemptions.reduce((sum, redemption) => sum + redemption.discountPrice, 0)
                    )}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </DialogContent>
        </BootstrapDialog>

        {/* Veiw the checkout */}
        <BootstrapDialog
          onClose={onClosePlanChecks}
          aria-labelledby="customized-dialog-title"
          className="cs-modal-sec"
          open={isPlanViewCheck}
          maxWidth="50px"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={onClosePlanChecks}>
            <Typography
              level="h3"
              component="h3"
              fontWeight="600"
              fontSize="2rem"
              className="h3">
              Visits
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent dividers className="modal-dialog-content font-size-14">
            {planCheckVisits.map((Details) => (
              <Grid
                container
                xs={12}
                padding="10px"
                className="card expense-card mb-1"
                onClick={() => viewVisitDetails(Details)}
                key={Details.id}
                style={{ minWidth: "100px" }}
              >
                <Grid item xl={15} className="">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={6}
                    style={{ marginBottom: "10px" }} >
                    <Typography
                      variant="h5"
                      component="h5"
                      fontSize="17px"
                      className="h6 m-0"
                      fontWeight="600"
                    >
                      {Details.customerName}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      fontSize="17px"
                      className="h6 m-0"
                      fontWeight="600"
                    >
                      {Details.customerMobileNumber}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={10}>
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={1}>
                      <Typography>Invoice Number</Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        fontWeight="600"
                        fontSize="14px"
                        className="h6 m-0"
                      >
                        {Details.invoiceNumber}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={20}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={10}
                      className="text-right">
                      <Typography style={{ marginRight: "10px" }}>Visits Date</Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        fontWeight="600"
                        fontSize="14px"
                        className="h6 m-0"
                      >
                        {moment(Details.creatDT).format("DD-MM-YYYY")}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      spacing={3}>
                      <Typography>Status</Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        fontWeight="600"
                        fontSize="14px"
                        className="h6 m-0"
                      >
                        {Details.status}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            ))}
          </DialogContent>
        </BootstrapDialog>

        <Grid container m={0}>
          <SideBarMenuItems
            selectedTab={
              location.state && location.state.fromJobcard ? SideBarMenuNames.JobCard : SideBarMenuNames.Customers
            }
          />
          <Grid xs={12} className="content-sec">
            <Grid container className="title-sec">
              <Typography
                level="h2"
                component="h2"
                fontWeight="600"
                fontSize="2rem"
                className="page-title text-center"
              >
                <Button onClick={goBack} className="back-btn">
                  <NavigateBeforeIcon />
                </Button>
                Customer Membership Plans
              </Typography>
            </Grid>

            <Grid container direction="row">
              <Grid flex="1">
                <Grid container spacing={2} padding="5px 10px">
                  <Stack padding={2} style={{ width: "100%" }}>
                    {AMCPlanDetails.map((Details) => (
                      <Grid
                        container
                        xs={12}
                        padding="10px"
                        className="card expense-card white-bg mb-1"
                        onClick={() => viewBenefits(Details.benefits)}
                        key={Details.id}
                      >
                        <Grid xs={6} className="">
                          <Stack style={{ marginBottom: "10px" }}>
                            <Typography
                              variant="h6"
                              component="h6"
                              fontSize="17px"
                              className="h6 m-0"
                              fontWeight="600"
                            >
                              {Details.planName}
                            </Typography>
                          </Stack>
                          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={6}>
                            <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>
                              <Typography>Start Date</Typography>
                              <Typography
                                variant="h6"
                                component="h6"
                                fontWeight="600"
                                fontSize="14px"
                                className="h6 m-0"
                              >
                                {moment(Details.startDT).format("DD-MM-YYYY")}
                              </Typography>
                            </Stack>
                            <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>
                              <Typography>End Date</Typography>
                              <Typography
                                variant="h6"
                                component="h6"
                                fontWeight="600"
                                fontSize="14px"
                                className="h6 m-0"
                              >
                                {moment(Details.endDT).format("DD-MM-YYYY")}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-end"
                          spacing={2}
                          style={{ marginLeft: "35%" }}
                        >
                          <Typography
                            variant="h6"
                            component="h6"
                            fontWeight="600"
                            fontSize="14px"
                            className="h6 m-0"
                          >
                            {Details.status}
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={6}>
                            <Stack
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={1}>
                              <Typography>Benefits</Typography>
                              <Typography
                                variant="h6"
                                component="h6"
                                fontWeight="600"
                                fontSize="14px"
                                className="h6 m-0"
                              >
                                {Details.benefits.length}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={1}>
                              <Typography>Total Discount</Typography>
                              <Typography
                                variant="h6"
                                component="h6"
                                fontWeight="600"
                                fontSize="14px"
                                className="h6 m-0"
                              >
                                {Utils.GetCurrency(Utils.IsNullOrEmptyArray(Details.benefits)
                                  ? 0
                                  : Details.benefits
                                    .flatMap((benefit) => benefit.redemptions)
                                    .reduce((sum, redemption) => sum + redemption.discountPrice, 0))}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default AMCViewPlan;
