import { Button, Grid, IconButton, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ConfirmTypes, SettingsMenuList, SideBarMenuNames } from "../core/Constants";
import SideBarMenuItems from "./SideBarMenuItems";
import SettingsMenuItems from "./SettingsMenuItems";
import { Link as RouteLink, Navigate, useNavigate } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import UserHelper from "../core/UserHelper";
import { AddNewPackagePage, AddPackagesPage, LoginPage } from "../core/PageConstants";
import RefreshIcon from "@mui/icons-material/Refresh";
import { postAsync } from "../core/serviceClient";
import { connectToStore } from "../data/store";
import Utils from "../core/Utils";
import { DataGrid, GridActionsCellItem, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Loader from "./common/Loader";
import MessagePopup from "./common/MessagePopUp";
import ConfirmPopup from "./common/ConfirmPopup";
import { useDispatch } from "react-redux";

const PackagesPage = () => {
  const [localSettings, setLocalSettings] = useState({});
  const dispatch = useDispatch();
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [packageList, setPackageList] = useState([]);
  const [originalList, setoriginalList] = useState({});
  const [selectedPackage, setSelectedPackage] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfrimText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const columns = [
      {
        field: "active",
        headerName: "Active",
        headerClassName: "headerStyle",
        flex: 1,
        type: "boolean",
      },
      {
        field: "shortDesc",
        headerName: "Service Details",
        headerClassName: "headerStyle",
        flex: 2,
        renderCell: (params) => {
          const shortDesc = params.row.shortDesc;
          const description = params.row.description;
          
          return (
            <Stack>
              <Typography
                padding="0"
                component="h4"
                fontSize="2rem"
                fontWeight="600"
                className="h4">
                {shortDesc}
              </Typography>
              <p className="mb-0">{description}</p>
            </Stack>
          );
        }
      },
      {
        field: "price",
        valueGetter: (params) => {
          return Utils.GetCurrency(params.row.price);
        },
  
        headerName: "Price",
        headerClassName: "headerStyle",
        flex: 1,
      },
      {
        field: "taxPercentage",
        valueGetter: (params) => {
          return params.row.taxPercent + "%";
        },
  
        headerName: "Tax",
        headerClassName: "headerStyle",
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        headerClassName: "headerStyle",
        width: 100,
        cellClassName: "actions",
        getActions: ({ row }) => {
          return [
            UserHelper.CanEdit() ? (
              <>
                <GridActionsCellItem
                  icon={<EditRoundedIcon className="icon20" />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => handleEditPackage(row)}
                  color="inherit"
                />
  
                <GridActionsCellItem
                  icon={<DeleteOutlineIcon className="icon20" />}
                  label="Delete"
                  onClick={() => deletePackage(row)}
                  color="error"
                />
              </>
            ) : (
              <GridActionsCellItem
                icon={<VisibilityIcon className="icon20" />}
                label="View"
                // onClick={() => handleEditService(row)}
                color="inherit"
              />
            ),
          ];
        },
      },
    ];

  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(AddPackagesPage, Navigate)) {
      setLocalSettings(localSetting);
    }
  }, []);

  useEffect(() => {
    getPackage();
  }, [localSettings]);

  const getPackage = async () => {
    if (localSettings.authenticated) {
      setIsLoading(true);
      let result = await postAsync(
        "Package/GetPackageProduct",
        {},
        "",
        "GetAll",
        "GetAllPackageProductsByBusiness",
        [localSettings.business.id]
      );
      setIsNavigateToLogin(result.isNavigateToLogin);
      if (result.error) {
        setMsgOpen(true);
        setMsgText(result.errorMessage);
      } else {
        if (!Utils.IsNullOrEmptyArray(result.data)) {
          setPackageList(result.data);
          setoriginalList(result.data);
        }
      }
      setIsLoading(false);
    }
  };

  const handleEditPackage = (rows) => {
      navigate(AddNewPackagePage.Path, {
        state: {
          selectedPackage: rows,
        },
      });
    };

    const deletePackage = (Package) => {
      setSelectedPackage(Package);
      setConfrimText(
        "Are you sure you want to delete the " + Package.shortDesc + "?"
      );
      setConfirmOpen(true);
    };

    const handleMsgClose = () => {
      setMsgOpen(false);
      if (isNavigateToLogin) {
        UserHelper.LogOut(dispatch);
        navigate(LoginPage.Path);
      }
    };
    const handleConfirmClose = (value) => {
      setConfirmOpen(false);
      if (value == ConfirmTypes.OK) {
        if (!Utils.IsNullOrEmptyObject(selectedPackage)) {
          UserFormDelete(selectedPackage);
        }
      }
    };

    async function UserFormDelete(Package) {
      setIsLoading(true);
      let result = await postAsync(
            "Package/DeletePackage",
            {
              Id: Package.id,
              PackageProduct: Package,
              BusinessId: localSettings.business.id,
            },
            "",
            "Delete",
            "",
            []
          );
          if (result && result.data) {
            setMsgText("Deleted successfully");
            setMsgOpen(true);
            setSelectedPackage({});
            getPackage();
          } else {
            setMsgText("Unable to process your request");
            setMsgOpen(true);
          }
          setIsLoading(false);

    }

  const handleRefresh = () => {
    getPackage();
  };
  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
          <MessagePopup
            msgOpen={msgOpen}
            msgText={msgText}
            onMsgClose={handleMsgClose}
          />
          <ConfirmPopup
            confirmOpen={confirmOpen}
            confirmText={confirmText}
            onConfirmClose={handleConfirmClose}
          />
        <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.BusinessSetup} />
            <Grid xs={12} className="content-sec">
              <Grid container direction="row" className="">
                <SettingsMenuItems
                  selectedMenu={SettingsMenuList.BusinessSettings.PackagesSetup}
                />
                <Grid xs={12} md={9}>
                  <Grid className="form-sec xs-pd-0" direction="column">
                    <Grid container direction="row" className="mb-1">
                      <Stack
                        alignSelf="center"
                        justifyContent="end"
                        direction="row"
                        flex="1"
                      >
                        <IconButton
                          aria-label="Refresh"
                          onClick={handleRefresh}
                        >
                          <RefreshIcon sx={{ fontSize: 25 }} />
                        </IconButton>
                        {UserHelper.CanAdd() && (
                          <RouteLink
                            to={AddNewPackagePage.Path}
                            style={{ "align-self": "center" }}
                          >
                            <Button
                              variant="contained"
                              className="btn btn-sm btn-primary"
                            >
                              <AddCircleOutlineOutlinedIcon
                                fontSize="large"
                                sx={{ marginRight: "6px" }}
                              />
                              <span>Add New</span>
                            </Button>
                          </RouteLink>
                        )}
                      </Stack>

                      <Grid mt={2} item xs={12} className="font-size-14 data-grid-box">
                        <Box
                          sx={{
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <DataGrid
                            getRowId={(row) => row.id}
                            rows={packageList}
                            columns={columns}
                            disableColumnFilter={true}
                            disableColumnMenu={true}
                            rowSelection={false}
                            sx={{
                              boxShadow: 1,
                              border: 1,
                              borderColor: "primary.light",
                              "& .MuiDataGrid-cell:hover": {
                                color: "primary.main",
                              },
                            }}
                            slots={{ toolbar: CustomToolbar }}
                            slotProps={{
                              toolbar: {
                                showQuickFilter: true,
                                showFilters: false,
                              },
                            }}
                            className="data-grid"
                          />
                        </Box>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter className="searchbar" />
    </GridToolbarContainer>
  );
}

export default PackagesPage;
