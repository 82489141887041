import React, { useState, useEffect } from "react";
import "../../Style.css";
import Utils from "../../core/Utils";
import UserHelper from "../../core/UserHelper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connectToStore } from "../../data/store";
import SideBarMenuItems from "../SideBarMenuItems";
import SettingsMenuItems from "../SettingsMenuItems";
import ReportMenuItems from "../ReportMenuItems";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {
  SideBarMenuNames,
  SettingsMenuList,
  MaxDateLimit,
} from "../../core/Constants";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MessagePopup from "../common/MessagePopUp";
import { Typography } from "@mui/material";
import { Grid, Box, Container, Stack, Switch } from "@mui/material";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Loader from "../common/Loader";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { ExpensesReportPage, LoginPage } from "../../core/PageConstants";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { getBusiness } from "../../data/localSettingsActions";
import { postAsync } from "../../core/serviceClient";
import { UserRoles } from "../../core/Constants";
import CountryHelper from "../../core/CountryHelper";

const ExpensesReport = (props) => {
  document.title = "Expenses ";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [showMethod, setShowMethod] = useState("");
  const [reportContent, setReportContent] = useState("");
  const [filterByBranch, setFilterByBranch] = useState(false);
  const [filterByPaymentType, setFilterByPaymentType] = useState(false);
  const [vendorPaymentStatus, setVendorPaymentStatus] = useState("");
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [statementMethod, setStatementMethod] = useState("Summary");
  const [_IsDetailed, setIsDetailed] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branches, setBranches] = useState([]);
  const [isUAEBusiness, setIsUAEBusiness] = useState(false);

  const handleFilterByBranchChange = (event) => {
    setFilterByBranch(event.target.checked);
  };
  const handleFilterByPaymentChange = (event) => {
    setFilterByPaymentType(event.target.checked);
  }

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleSubmit = () => {
    setShowMethod(statementMethod);
    getStatementStatus(statementMethod);
  };

  const getStatementStatus = async (statementMethod) => {
    setIsLoading(true);
    let url = "reports/GetExpenseDetailed?";
    const branchId =
      filterByBranch && selectedBranch !== null ? selectedBranch : "";

    if (statementMethod === "Summary") {
      url = "reports/GetExpenseSummary?";
    }
    if (statementMethod === "VATInvoice") {
      url = "reports/GetExpenseVATInvoice?";
    }
    let reportUrl =
      Utils.GetServiceUrl() +
      url +
      "branchId=" +
      branchId +
      "&businessId=" +
      localSettings.business.id +
      "&paymentType=" +
      (filterByPaymentType ? vendorPaymentStatus : "") +
      "&startDate=" +
      Utils.GetISODateTimeString(selectedDate[0]) +
      "&endDate=" +
      Utils.GetISODateTimeString(selectedDate[1]) +
      "&token=" +
      localSettings.token +
      "&culture=" +
      CountryHelper.GetAPICulture(localSettings.country.Culture);
    setReportContent(reportUrl);

    setIsLoading(false);
  };
  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      if (dateArray[0]._isValid == false || dateArray[1]._isValid == false) {
        setMsgText(
          "Please select a valid date range."
        );
        setMsgOpen(true);
      }
      else {
        let dateDiff = dateArray[1].diff(dateArray[0], "days");
        if (dateDiff > MaxDateLimit) {
          setMsgText(
            "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
          );

          setMsgOpen(true);
        } else {
          setSelectedDate(dateArray);
        }
      }
    }
  };

  const handleInputStatement = (e) => {
    setStatementMethod(e.target.value);
    setIsDetailed(e.target.value === "Detailed");
  };

  const checkAuthentication = () => {
    if (
      UserHelper.CheckPermission(ExpensesReportPage, navigate, localSettings)
    ) {
      const business = getBusiness();
      if (
        !Utils.IsNullOrEmptyObject(business) &&
        !Utils.IsNullOrEmptyArray(business.branchLocations)
      ) {
        setBranches(business.branchLocations);
      }
    }
    var country = localSettings.country;
    setIsUAEBusiness(CountryHelper.IsBusinessCountryUAE(country));
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleSelect = (event) => {
    setSelectedBranch(event.target.value);
  };

  const handleEmailClicked = async () => {
    const branchId =
      filterByBranch && selectedBranch !== null ? selectedBranch : "";
    setIsLoading(true);
    setMsgOpen(true);
    if (!Utils.IsEmailValid(emailId)) {
      setIsNavigateToLogin(false);
      setIsLoading(false);
      setMsgText("Please enter a valid email ID.");
      setMsgOpen(true);
      return false;
    } else {
      let response = await postAsync("reports/EmailExpense", {
        BusinessId: localSettings.business.id,
        CompanyName: localSettings.business.companyName,
        EmailIds: [emailId],
        IsDetailed: _IsDetailed,
        startDate: selectedDate[0],
        endDate: selectedDate[1],
        branchId: branchId,
        IsAdmin: localSettings.user.role === UserRoles.GlobalAdmin,
      });

      setIsNavigateToLogin(response.isNavigateToLogin);
      let errorMsg = "Unable to process your request.";
      if (response.error) {
        errorMsg = response.errorMessage;
      } else if (response.data && response.data.success) {
        errorMsg = "Email sent successfully.";
      }
      setMsgText(errorMsg);
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />

        <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.InvoiceReport} />
            <Grid xs={12} className="content-sec">
              <Grid container direction="row" className="">
                <ReportMenuItems
                  selectedMenu={SettingsMenuList.BusinessReports.Expenses}
                />
                <Grid xs={12} md={9}>
                  <Grid spacing={2} className="form-sec pb-0">
                    <form container spacing={2} onSubmit={handleSubmit}>
                      <Stack
                        alignSelf="center"
                        direction="row"
                        flex="1"
                        className="mb-2"
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          style={{ height: "100%", width: "100%" }}
                          justifyContent="space-between"
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Switch
                              checked={filterByBranch}
                              onChange={handleFilterByBranchChange}
                            />

                            <Typography
                              variant="h6"
                              style={{ "white-space": "nowrap", marginLeft: "0" }}
                              alignSelf="center"
                            >
                              Filter by branch
                            </Typography>
                            {filterByBranch && (
                              <FormControl
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                className="font-size-14"
                                sx={{width: "300px"}}
                              >
                                <InputLabel id="Branch-label">Branch Name</InputLabel>
                                <Select
                                  value={selectedBranch}
                                  onChange={handleSelect}
                                  label="Branch Name"
                                  InputLabelProps={{ shrink: true }}
                                  style={{
                                    height: "50px",
                                  }}
                                >
                                  {branches.map((branch, index) => (
                                    <MenuItem key={index} value={branch.branchId}>
                                      {branch.branchName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </Stack>
                          <DateRangePicker
                            showLeadingZeros={true}
                            onChange={onDateChange}
                            maxDate={new Date()}
                            value={selectedDate}
                            locale={CountryHelper.GetDateCulture(localSettings.country.Culture)}
                            clearIcon={null}
                            calendarIcon={
                              <CalendarMonthOutlinedIcon
                                style={{
                                  "font-size": "24px",
                                  "align-self": "center",
                                  color: "#666666",
                                }}
                              />
                            }
                          />
                        </Stack>
                      </Stack>

                      {/* filter by payment status */}
                      {statementMethod === "Detailed" && (
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="end"
                          marginTop={1}
                        >
                          {filterByPaymentType && (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              className="font-size-14"
                            >
                              <InputLabel id="Branch-label">Payment Status</InputLabel>
                              <Select
                                value={vendorPaymentStatus}
                                onChange={(e) => setVendorPaymentStatus(e.target.value)}
                                label="Payment Status"
                                InputLabelProps={{ shrink: true }}
                                style={{ height: "50px" }}
                              >
                                <MenuItem value="Paid">Paid</MenuItem>
                                <MenuItem value="Unpaid">Unpaid</MenuItem>
                              </Select>
                            </FormControl>
                          )}

                          <Switch
                            checked={filterByPaymentType}
                            onChange={handleFilterByPaymentChange}
                          />

                          <Typography
                            variant="h6"
                            style={{ "white-space": "nowrap", marginLeft: "0" }}
                            alignSelf="center"
                          >
                            Filter by payment Status
                          </Typography>
                        </Stack>)}


                      <Grid
                        spacing={2}
                        rowSpacing={2}
                        className="card white-bg mt-1 font-size-14"
                        padding={1}
                      >
                        <RadioGroup
                          value={statementMethod}
                          onChange={(e) => handleInputStatement(e)}
                          InputLabelProps={{ shrink: true }}
                        >
                          <Stack direction="row" spacing={10}>
                            <FormControlLabel
                              value={"Summary"}
                              control={<Radio />}
                              label="Summary"
                            />
                            <FormControlLabel
                              value={"Detailed"}
                              control={<Radio />}
                              label="Detailed"
                            />
                            {isUAEBusiness === true && (
                              <FormControlLabel
                                value={"VATInvoice"}
                                control={<Radio />}
                                label="VAT Invoice"
                              />)}
                          </Stack>
                        </RadioGroup>
                      </Grid>
                    </form>
                    <iframe
                      className="reportIframe card white-bg mt-1"
                      src={reportContent}
                      style={{
                        width: "100%",
                        height: "calc(100vh - 255px)",
                        marginBottom: "100px"
                      }}
                    ></iframe>
                    <br />

                    <Stack
                      direction="row"
                      className="fixed-btn"
                    >
                      <Grid container className="container"
                        justifyContent="end"
                      >
                        <Grid xs={12} md={3}></Grid>
                        <Grid xs={12} md={9}>
                          <Stack direction="row" justifyContent="end" spacing={1} className="font-size-14">
                            <FormControl fullWidth style={{ "flex": "1" }}>
                              <TextField
                                label="Email"
                                value={emailId}
                                type="email"
                                onChange={(e) => {
                                  setEmailId(e.target.value);
                                }}
                              />
                            </FormControl>
                            <Stack
                              direction="row"
                              xs={12}
                              justifyContent="end"
                              alignSelf="center"
                              spacing={1}
                              style={{ "flex": "1" }}
                            >
                              <FormControl fullWidth>
                                <Button
                                  className="btn btn-primary"
                                  variant="contained"
                                  type="submit"
                                  onClick={handleEmailClicked}
                                >
                                  Send Email
                                </Button>
                              </FormControl>
                              <FormControl fullWidth>
                                <Button
                                  className="btn btn-primary"
                                  variant="contained"
                                  type="submit"
                                  onClick={handleSubmit}
                                >
                                  View Expenses
                                </Button>
                              </FormControl>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default ExpensesReport;
