import React, { useEffect, useState } from "react";
import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Utils from "../core/Utils";
import Loader from "./common/Loader";
import SideBarMenuItems from "./SideBarMenuItems";
import { SideBarMenuNames } from "../core/Constants";
import { useDispatch } from "react-redux";
import UserHelper from "../core/UserHelper";
import MessagePopup from "./common/MessagePopUp";
import { postAsync } from "../core/serviceClient";
import { getCountry, getEnterpriseId } from "../data/localSettingsActions";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { LoginPage, BirthdayPage, OverviewPage } from "../core/PageConstants";
import "../DateRangePickerWithYear.css";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import CountryHelper from "../core/CountryHelper";
import { Button } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const Birthday = (props) => {
  document.title = "Birthday";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [birthList, setBirthList] = useState([]);
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);

  useEffect(() => {
    if (UserHelper.CheckPermission(BirthdayPage, navigate)) {
      fetchBirthdayDetails(selectedDate[0], selectedDate[1]);
    }
  }, []);

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  async function fetchBirthdayDetails(startDate, endDate) {
    setIsLoading(true);
    var request = {
      BirthdayStartDate: Utils.GetISODateTimeString(moment(startDate)),
      BirthdayEndDate: Utils.GetISODateTimeString(moment(endDate)),
      EnterpriseId: getEnterpriseId(),
    };
    let result = await postAsync(
      "Customer/GetCustomerPassByCustomerBirthDate",
      request
    );
    setIsNavigateToLogin(result.isNavigateToLogin);
    if (result.error) {
      setMsgOpen(true);
      setMsgText(result.errorMessage);
    }
    else if (!Utils.IsNullOrEmptyArray(result.data.customerPassList)) {
      getBirthData(result.data.customerPassList);
    }
    setIsLoading(false);
  }

  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      if (dateArray[0]._isValid == false || dateArray[1]._isValid == false) {
        setMsgText(
          "Please select a valid date range."
        );
        setMsgOpen(true);
      }
      else {
        setSelectedDate(date);
        fetchBirthdayDetails(date[0], date[1]);
      }
    }
  };

  const columns = [
    {
      field: "customerName",
      headerName: "Customer Name",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "profit",
      headerName: "profit",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "visits",
      headerName: "Visits",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "points",
      headerName: "Points",
      headerClassName: "headerStyle",
      flex: 1,
    },
  ];
  async function getBirthData(data) {
    const birthRows = [];
    for (var i = 0; i < data.length; i++) {
      birthRows.push({
        id: i,
        customerName: data[i].userName,
        mobile: data[i].mobileNumber,
        city: data[i].locality,
        type: data[i].tierName,
        profit: data[i].profit,
        revenue: data[i].revenue,
        visits: data[i].visits,
        points: data[i].points,
      });
    }
    setBirthList(birthRows);
  }

  var pageTitle = "Birthday";
  const backNavigation = () => {
    navigate(OverviewPage.Path, {});
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Overview} />
          <Grid xs={12} className="content-sec" sx={{ "padding-bottom": "100px" }}>
            <Grid container direction="row" className="">
              <Grid
                flex="1"
                spacing={2}
                className="form-sec p-0"
              >
                <Grid container className="title-sec">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title text-center"
                  >
                    <Button onClick={backNavigation} className="back-btn">
                      <NavigateBeforeIcon />
                    </Button>
                    {pageTitle}
                  </Typography>
                  <Stack direction="row" className="text-right date-picker-sec fixed-xs-btn">
                    <DateRangePicker
                      showLeadingZeros={true}
                      onChange={onDateChange}
                      maxDate={new Date()}
                      value={selectedDate}
                      locale={CountryHelper.GetDateCulture(getCountry().Culture)}
                      clearIcon={null}
                      calendarIcon={
                        <CalendarMonthOutlinedIcon
                          style={{
                            "font-size": "24px",
                            "align-self": "center",
                            color: "#666666",
                          }}
                        />
                      }
                    />
                  </Stack>
                </Grid>
                <Grid container xs={12} className="font-size-14 data-grid-box">
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <DataGrid
                      getRowId={(row) => row.id}
                      rows={birthList}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10]}
                      className="data-grid font-size-14"
                      slots={{ toolbar: CustomToolbar }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter className="searchbar" />
    </GridToolbarContainer>
  );
}

export default Birthday;
