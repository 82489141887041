import React, { useState, useEffect } from "react";
import "../Style.css"; import "../Responsive.css";
import {
  Container,
  Grid,
  Stack,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Box,
} from "@mui/material";
import { Modal } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import Utils from "../core/Utils";
import UserHelper from "../core/UserHelper";
import { CanRaisePaymentLink } from "../core/ModuleHelper";
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import "../bootstrap.min.css";
// import "../daterangepicker.css";
import "../DateRangePickerWithYear.css";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";

// Images
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { connectToStore } from "../data/store";
import { postAsync } from "../core/serviceClient";
import CountryHelper from "../core/CountryHelper";
import BusinessHelper from "../core/BusinessHelper";
import {
  SideBarMenuNames,
  UserRoles,
  VisitStatuses,
  PaymentMethodTypes,
  VisitTypes,
  MaxDateLimit,
  ProductDetailsTypes,
} from "../core/Constants";
import MessagePopup from "./common/MessagePopUp";
import ConfirmPopup from "./common/ConfirmPopup";
import SideBarMenuItems from "./SideBarMenuItems";
import OverviewDetails from "./OverviewDetails";
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import OverviewProductDetails from "./OverviewProductDetails";
import Loader from "./common/Loader";
import automobile_image from "../images/automobile.png";
import salon_image from "../images/salon.png";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import Expense_image from "../images/calculation.png";
import Birthday_image from "../images/birthday-cake.png";
import Outstanding_image from "../images/money-bag.png";
import InProgressJobCard_image from "../images/service.png";
import AssignJobCard_image from "../images/assign.png";
import OpenJobCard_image from "../images/task.png";
import AMC_image from "../images/amc-img.png";
import Support_image from "../images/support.png";
import Deliver_image from "../images/deliver.png";
import Delay_image from "../images/delay.png";
import Schedule_image from "../images/schedule.png";
import PartialPaid_image from "../images/partial-paid.png";
import {
  LoginPage,
  BirthdayPage,
  ExpensePage,
  InsurancePage,
  ServiceReminderPage,
  OverviewPage,
  VisitsPage,
  PaymentLinksPage,
  AMCPlanPage,
  CalanderPage,
  DetailedOverviewPage
} from "../core/PageConstants";
import { useLocation } from "react-router-dom";

// Calendar

// Rating
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

import WhatsApp from "../images/whatsapp.png";
import CloseIcon from "@mui/icons-material/Close";

import {
  India,
} from "../core/Constants";
import { getCountry } from "../data/localSettingsActions";

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};


const Overview = (props) => {
  document.title = "Overview";
  document.body.classList.remove("login");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [localSettings, setLocalSettings] = useState({});
  const [spareTitle, setSpareTitle] = useState("");
  const [serviceTitle, setServiceTitle] = useState("");
  const [consumableTitle, setConsumableTitle] = useState("");
  const [isSalon, setIsSalon] = useState(false);
  // const [startDate, setStartDate] = useState(Utils.GetStartDate());
  // const [endDate, setEndDate] = useState(Utils.GetEndDate());
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  const location = useLocation();
  const [visitsStat, setVisitsStat] = useState("");
  const [paymentLinkStat, setPaymentLinkStat] = useState(0);
  const [partiallyPaidStat, setPartiallyPaidStat] = useState(0);
  const [totalExpenseStat, setTotalExpenseStat] = useState("");
  const [discountsStat, setDiscountsStat] = useState("");
  const [refundsStat, setRefundsStat] = useState("");
  const [taxStat, setTaxStat] = useState("");
  const [pointsStat, setPointsStat] = useState("");
  const [cardStat, setCardStat] = useState("");
  const [upiStat, setUPIStat] = useState("");
  const [cashStat, setCashStat] = useState("");
  const [outstandingStat, setOutstandingStat] = useState("");
  const [productRevenue, setProductRevenueStat] = useState("");
  const [serviceRevenueStat, setServiceRevenueStat] = useState("");
  const [materialRevenueStat, setMaterialRevenueStat] = useState("");
  const [revenueStat, setRevenueStat] = useState("");
  const [netbankingStat, setNetbankingStat] = useState("");
  const [profitStat, setProfitStat] = useState("");
  const [openedStat, setOpenedStat] = useState("");
  const [assignedStat, setAssignedStat] = useState("");
  const [inprogressStat, setInProgressStat] = useState("");
  const [delayedStat, setDelayedStat] = useState("");
  const [completedStat, setCompletedStat] = useState("");
  const [deliveredStat, setDeliveredStat] = useState("");
  const [reservationsStat, setReservationsStat] = useState("");
  const [signupsStat, setSignupsStat] = useState("");
  const [activeStat, setActiveStat] = useState("");
  const [feesStat, setFeesStat] = useState("");
  const [tipsStat, setTipsStat] = useState("");
  const [payoutSettledStat, setPayoutSettledStat] = useState("");
  const [payoutTotalStat, setPayoutTotalStat] = useState("");
  const [showStats, setShowStats] = useState(false);
  const [showPayouts, setShowPayouts] = useState(false);
  const [isUSABusiness, setIsUSABusiness] = useState(false);
  const [isOtherPaymentsAvailable, setIsOtherPaymentsAvailable] =
    useState(false);
  const [isAutomobile, setIsAutomobile] = useState(false);
  const [isTechnician, setIsTechnician] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [revenueOpen, setRevenueOpen] = useState(false);
  const [productDetailsOpen, setProductDetailsOpen] = useState(false);
  const [productDetailsType, setProductDetailsType] = useState(
    ProductDetailsTypes.Services
  );
  const [isRevenue, setIsRevenue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [remainingDays, setRemainingDays] = useState(0);
  const [subscriptionEndDateReminderActive, setSubscriptionEndDateReminderActive] = useState(false);
  const [isContctUs, setIsContctUs] = useState(false);
  const [countryInfo, setCountryInfo] = useState({});
  const [supportNumber, setSupportNumber] = useState([]);

  const overviewStat = async (startDT, endDT) => {
    if (localSettings.authenticated) {
      setIsLoading(true);

      var sDate = Utils.GetISODateTimeString(moment(startDT));
      var eDate = Utils.GetISODateTimeString(moment(endDT));
      var request = {
        BranchId: localSettings.branch.branchId,
        BusinessId: localSettings.business.id,
        MessagingId: localSettings.branch.messagingId,
        ReservationMaxDays: localSettings.branch.reservationMaxDays,
        Start: sDate,
        End: eDate,
      };
      let response = await postAsync("Account/GetStats", request);
      setIsLoading(false);
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgOpen(true);
        setMsgText(response.errorMessage);
      } else if (response.data && response.data.stats) {
        setPaymentLinkStat(response.data.stats.paymentLinks);
        setPartiallyPaidStat(response.data.stats.partiallyPaidVisits);
        setVisitsStat(response.data.stats.visits);
        setTotalExpenseStat(response.data.stats.totalExpenses);
        setDiscountsStat(response.data.stats.discount);
        setPointsStat(response.data.stats.pointsAccrued);
        setCardStat(response.data.stats.card);
        setUPIStat(response.data.stats.upi);
        setNetbankingStat(response.data.stats.netBanking);
        setProfitStat(response.data.stats.profit);
        setCashStat(response.data.stats.cash);
        setOutstandingStat(response.data.stats.outstandingPayments);
        setProductRevenueStat(response.data.stats.productRevenue);
        setServiceRevenueStat(response.data.stats.serviceRevenue);
        setMaterialRevenueStat(response.data.stats.materialRevenue);
        setRevenueStat(response.data.stats.revenue);
        setOpenedStat(response.data.stats.jobcardsOpened);
        setAssignedStat(response.data.stats.jobcardsAssigned);
        setInProgressStat(response.data.stats.jobcardsInProgress);
        setDelayedStat(response.data.stats.jobcardsDelayed);
        setCompletedStat(response.data.stats.jobcardsCompleted);
        setDeliveredStat(response.data.stats.vehiclesDelivered);
        if (!Utils.IsNullOrEmptyArray(response.data.appointments)) {
          const count = response.data.appointments.filter(reservation => !(reservation.invoiceNumber)).length;
          setReservationsStat(count);
        } else {
          setReservationsStat(response.data.appointments?.length);
        }
        setSignupsStat(response.data.stats.signups);
        setActiveStat(response.data.stats.active);
        setFeesStat(response.data.stats.fees);
        setTipsStat(response.data.stats.tips);
        setRefundsStat(response.data.stats.refunds);
        setTaxStat(response.data.stats.tax);
        let isUSA = CountryHelper.IsBusinessCountryUSA(localSettings.country);
        setShowStats(
          localSettings.user &&
          localSettings.user.role &&
          (localSettings.user.role == UserRoles.GlobalAdmin ||
            localSettings.user.role == UserRoles.BranchAdmin)
        );
        setIsUSABusiness(isUSA);
        setIsOtherPaymentsAvailable(
          CountryHelper.IsOtherPaymentsAvailable(localSettings.country)
        );
        setIsAutomobile(BusinessHelper.IsAutomobile(localSettings.business));
        setShowPayouts(
          isUSA && localSettings.user.role == UserRoles.GlobalAdmin
        );
        setPayoutSettledStat(response.data.stats.payoutSettled);
        setPayoutTotalStat(response.data.stats.payoutTotal);
        setIsTechnician(localSettings.user.role == UserRoles.Technician);
        checkSubscriptionEndDateReminder(response);
      }
    }
  };

  const checkSupportNumber = () => {
    if (isContctUs === true) {
      if (Utils.IsNullOrEmptyArray(localSettings.apiWhatsAppSupportNumbers)) {
        let temp = localSettings.LocalWhatsAppSupportMobileNumber.filter(
          i => i.Code === countryInfo.Code.toString()
        );
        setSupportNumber(temp.length > 0 ? temp : []);
      } else {
        let supportNumbers = Object.entries(localSettings.apiWhatsAppSupportNumbers).map(
          ([code, number]) => ({ Code: code, MobileNumber: number })
        );
        let temp = supportNumbers.filter(
          i => i.Code === countryInfo.Code.toString()
        );
        setSupportNumber(temp.length > 0 ? temp : []);
      }
    }
  };
  useEffect(() => {
    setCountryInfo(getCountry());
    checkSupportNumber()
  }, [isContctUs]);

  const whatsAppContactUs = (number) => {
    if (!Utils.IsNullOrEmpty(number)) {
      let mNumber = number.Code + number.MobileNumber;
      let url = `https://api.whatsapp.com/send?phone=${mNumber.substring(1)}`;
      window.open(encodeURI(url), "_blank");
    }
  };

  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(OverviewPage, navigate, localSetting)) {
      setLocalSettings(localSetting);
      let isSalonBusiness = BusinessHelper.IsSalon(localSetting.business);
      setSpareTitle(BusinessHelper.GetSparesTitle(isSalonBusiness));
      setServiceTitle(BusinessHelper.GetServicesTitle(isSalonBusiness));
      setConsumableTitle(BusinessHelper.GetConsumablesTitle(isSalonBusiness));
      setIsSalon(isSalonBusiness);
    }
  }, []);

  const checkSubscriptionEndDateReminder = (response) => {
    let localSetting = connectToStore();
    if (
      !Utils.IsNullOrEmpty(response.data.business) && !Utils.IsNullOrEmpty(response.appConfiguration.subscriptionEndDateReminder)
    ) {
      var subscriptionReminderDate = localSetting.business.subscriptionEndDate;
      var datePart = subscriptionReminderDate.split('T')[0];
      var subscriptionEndDate = new Date(datePart);
      var daysBeforeReminder = response.appConfiguration.subscriptionEndDateReminder.remindXDaysBeforeSubscriptionEnd;
      var presentDay = new Date(Utils.GetCurrentDateFromLocal());

      subscriptionEndDate = new Date(subscriptionEndDate.getFullYear(), subscriptionEndDate.getMonth(), subscriptionEndDate.getDate());
      presentDay = new Date(presentDay.getFullYear(), presentDay.getMonth(), presentDay.getDate());
      var timeDifference = subscriptionEndDate - presentDay;
      var daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      if (daysDifference <= daysBeforeReminder && daysDifference >= 0) {
        setSubscriptionEndDateReminderActive(true);
        setRemainingDays(daysDifference);
      } else {
        setSubscriptionEndDateReminderActive(false);
      }
    }
  }
  useEffect(() => {
    checkState();
  }, [localSettings]);

  const checkState = () => {
    if (
      !Utils.IsNullOrEmptyObject(location.state) &&
      !Utils.IsNullOrEmpty(location.state.startDate) &&
      !Utils.IsNullOrEmpty(location.state.endDate)
    ) {
      var startDate = moment(location.state.startDate);
      var endDate = moment(location.state.endDate);
      setSelectedDate([startDate, endDate]);
      overviewStat(startDate, endDate);
    } else {
      overviewStat(selectedDate[0], selectedDate[1]);
    }
  };

  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      if (dateArray[0]._isValid == false || dateArray[1]._isValid == false) {
        setMsgText(
          "Please select a valid date range."
        );
        setMsgOpen(true);
      }
      else {
        let dateDiff = dateArray[1].diff(dateArray[0], "days");
        if (dateDiff > MaxDateLimit) {
          setMsgText(
            "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
          );

          setMsgOpen(true);
        } else {
          setSelectedDate(dateArray);
          overviewStat(dateArray[0], dateArray[1]);
        }
      }
    }
  };
  const handleRefresh = () => {
    overviewStat(selectedDate[0], selectedDate[1]);
  };

  const handleMyJobCards = () => {
    navigate(VisitsPage.Path, {
      state: {
        startDT: Utils.GetISODateTimeString(selectedDate[0]),
        endDT: Utils.GetISODateTimeString(selectedDate[1]),
        visitStatus: VisitTypes.MyJobCard,
      },
    });
  };

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
  };

  const handleRevenueClose = (response) => {
    setRevenueOpen(false);
    if (!Utils.IsNullOrEmptyObject(response)) {
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgOpen(true);
        setMsgText(response.errorMessage);
      }
    }
  };
  const handleProductDetailsClose = (response) => {
    setProductDetailsOpen(false);
    if (!Utils.IsNullOrEmptyObject(response)) {
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgOpen(true);
        setMsgText(response.errorMessage);
      }
    }
  };

  const handleRevenueOpen = (isRevenue) => {
    setIsRevenue(isRevenue);
    setRevenueOpen(true);
  };

  const handleProductDetailsOpen = (detailsType) => {
    setProductDetailsType(detailsType);
    setProductDetailsOpen(true);
  };

  const valueFormatter = (value) => `${value}`;

  const chartSetting = {
    xAxis: [
      {
        scaleType: "band",
        dataKey: "jobcard",
        categoryGapRatio: 0.3,
        barGapRatio: 0.5,
      },
    ],
    series: [
      {
        dataKey: "status",
        valueFormatter,
        color: "#fdb462",
      },
    ],
    yAxis: [
      {
        tickMinStep: 1,
      },
    ],
    width: 550,
    height: 300,
  };
  const dataset = [
    {
      status: openedStat,
      jobcard: "Open",
    },
    {
      status: assignedStat,
      jobcard: "Assigned",
    },
    {
      status: inprogressStat,
      jobcard: "In Progress",
    },
    {
      status: completedStat,
      jobcard: "Completed",
    },
    {
      status: deliveredStat,
      jobcard: "Delivered",
    },
    {
      status: delayedStat,
      jobcard: "Delayed",
    },
  ];

  const [isPaymentVisible, setIsPaymentVisible] = useState(false);
  const handlePaymentSection = (event) => {
    if (event.target.checked) {
      setIsPaymentVisible(true);
    } else {
      setIsPaymentVisible(false);
    }
  };

  const [isViewMoreVisible, setIsViewMoreVisible] = useState(false);
  const handleViewMoreSection = (event) => {
    if (event.target.checked) {
      setIsViewMoreVisible(true);
    } else {
      setIsViewMoreVisible(false);
    }
  };
  const openDetailedDashboard = () => {
    navigate(DetailedOverviewPage.Path, {
      state: {
        startDate: Utils.GetISODateTimeString(selectedDate[0]),
        endDate: Utils.GetISODateTimeString(selectedDate[1])
      },
    });
  }
  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Modal open={isContctUs}>
          <Box className="custom-prod" padding={4}>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                level="h2"
                component="h2"
                fontWeight="600"
                fontSize="2rem"
                alignSelf="center"
              >
                We're here to assist you
              </Typography>
              <IconButton aria-label="close" onClick={() => setIsContctUs(false)}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack spacing={2}>
              <Typography
                level="h4"
                component="h4"
                fontWeight="400"
                fontSize="1.4rem"
              >
                Give us a call or send a message!
              </Typography>
              {(!Utils.IsNullOrEmptyArray(supportNumber) &&
                supportNumber.map((number, index) => (
                  <Box key={index} style={{ cursor: "pointer" }} className="w-100">
                    <Card className="card"
                      direction="row"
                      sx={{
                        padding: "20px",
                      }}
                      onClick={() => whatsAppContactUs(number)}
                    >
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        {/* <Box
                          component="img"
                          sx={{
                            height: "28px",
                            width: 34,
                          }}
                          alt="Contact us"
                          src={number.Code === India.Code ? IndiaFlag : UAEFlag}
                        /> */}
                        <Stack direction="row">
                          <Box
                            component="img"
                            sx={{
                              height: "28px",
                            }}
                            alt="WhatsApp"
                            src={WhatsApp}
                          />
                          <Typography
                            level="h2"
                            component="h2"
                            fontWeight="600"
                            fontSize="1.8rem"
                            alignSelf="center"
                            marginLeft="10px"
                          >
                            {number.Code} {number.MobileNumber}
                          </Typography>
                        </Stack>
                        <NavigateNextOutlinedIcon sx={{ fontSize: "24px" }} />
                      </Stack>
                    </Card>
                  </Box>
                ))) || <p>No WhatsApp support numbers available.</p>}
            </Stack>
            <Box
              alignSelf="center"
              component="img"
              sx={{ position: "absolute", bottom: 0, left: 0, right: 0, width: "70%", margin: "auto" }}
              alt="Support"
              src={Support_image}
            />
          </Box>
        </Modal>
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
        />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
        />
        <OverviewDetails
          open={revenueOpen}
          onClose={handleRevenueClose}
          isRevenue={isRevenue}
          startDT={selectedDate[0]}
          endDT={selectedDate[1]}
        />
        <OverviewProductDetails
          open={productDetailsOpen}
          onClose={handleProductDetailsClose}
          detailsType={productDetailsType}
          startDT={selectedDate[0]}
          endDT={selectedDate[1]}
        />
        <Loader open={isLoading} />
        <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.Overview} />
            <Grid xs={12} className="content-sec">
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                paddingBottom="15px"
              >
                {/* Activity */}
                <Grid container mb={3}>
                  {/* Title Section */}
                  <Grid container className="title-sec">
                    <Grid xs={12} sm={8} md={4} lg={4} alignSelf="center">
                      <Stack direction="row" className="justify-xs-between">
                        <Tooltip arrow title="Detailed Dashboard">
                          <IconButton
                            onClick={openDetailedDashboard}
                            aria-label="Detailed Dashboard"
                            style={{
                              "align-self": "center",
                              "color": "#000000"
                            }}
                          >
                            <SpaceDashboardOutlinedIcon style={{ "font-size": "28px" }} />
                          </IconButton>
                        </Tooltip>
                        <Typography
                          level="h2"
                          component="h2"
                          fontWeight="600"
                          fontSize="2rem"
                          className="h1"
                        >
                          Overview Dashboard
                        </Typography>
                        <IconButton
                          aria-label="Refresh"
                          onClick={handleRefresh}
                          style={{
                            "align-self": "center",
                            "margin-left": "10px",
                          }}
                        >
                          <RefreshIcon sx={{ fontSize: 25 }} />
                        </IconButton>
                      </Stack>
                    </Grid>
                    <Grid xs={12} sm={4} md={4} lg={4} alignSelf="center">
                      <Stack
                        direction="row"
                        justifyContent="center"
                        style={{ height: "100%" }}
                      >
                        <DateRangePicker
                          showLeadingZeros={true}
                          onChange={onDateChange}
                          maxDate={new Date()}
                          value={selectedDate}
                          locale={CountryHelper.GetDateCulture(
                            localSettings.country != null ? localSettings.country.Culture : India.Culture
                          )}
                          clearIcon={null}
                          calendarIcon={
                            <CalendarMonthOutlinedIcon
                              style={{
                                "font-size": "24px",
                                "align-self": "center",
                                color: "#666666",
                              }}
                            />
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid xs={12} sm={4} md={4} lg={4}
                      className="form-inline text-right quote-sec"
                      style={{ "align-self": "inherit" }}
                    >
                      <h4>Hi, Good day!</h4>
                      <p>For things to change, you have to change. <br /> - <strong>JIM ROHN</strong></p>
                    </Grid>
                  </Grid>

                  <Grid container rowGap={2}>
                    <Grid
                      xs={12}
                      sm={4}
                      md={3}
                      lg={3}>
                      <Card className="card visit-card-box h-100">
                        <Link
                          to={VisitsPage.Path}
                          state={{
                            startDT: Utils.GetISODateTimeString(
                              selectedDate[0]
                            ),
                            endDT: Utils.GetISODateTimeString(
                              selectedDate[1]
                            ),
                            navigationStack: [OverviewPage.Path],
                          }}
                        >
                          <CardActionArea className="p-2" style={{ "justify-content": "space-between", "flex-direction": "column", "align-items": "start", "height": "100%" }}>
                            <ArrowUpwardIcon style={{ "font-size": "32px", "fill": "#88C941", "align-self": "end" }} />
                            <Typography
                              level="h2"
                              component="h2"
                              fontWeight="600"
                              fontSize="1.6rem"
                            >
                              <strong>{visitsStat}</strong>
                              <span>Visits</span>
                            </Typography>
                          </CardActionArea>
                        </Link>
                      </Card>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={4}
                      md={3}
                      lg={3}
                      paddingLeft={2}
                      paddingRight={2}
                      className="p-xs-0">
                      {isAutomobile ? (
                        <Card className="card">
                          <Link
                            to={VisitsPage.Path}
                            state={{
                              startDT: Utils.GetISODateTimeString(
                                selectedDate[0]
                              ),
                              endDT: Utils.GetISODateTimeString(
                                selectedDate[1]
                              ),
                              visitStatus: VisitStatuses.Completed,
                              navigationStack: [OverviewPage.Path],
                            }}
                          >
                            <CardActionArea className="p-2" style={{ "justify-content": "space-between", "flex-direction": "column", "align-items": "start", "height": "100%" }}>
                              <TaskAltIcon style={{ "font-size": "32px", "fill": "#6BB577", "align-self": "end" }} />
                              <Typography
                                level="h2"
                                component="h2"
                                fontWeight="600"
                                fontSize="1.6rem"
                                className="h1"
                              >
                                <strong>{completedStat}</strong>
                                <span>Completed Job Cards</span>
                              </Typography>
                            </CardActionArea>
                          </Link>
                        </Card>
                      ) : (
                        <Card className="card">
                          <Link
                            to={CalanderPage.Path}
                          >
                            <CardActionArea className="p-2" style={{ "justify-content": "space-between", "flex-direction": "column", "align-items": "start", "height": "100%" }}>
                              <Box
                                component="img"
                                sx={{
                                  height: 30,
                                  width: 30,
                                  alignSelf: "end"
                                }}
                                alt="Appointments"
                                src={Schedule_image}
                              />
                              <Typography
                                level="h2"
                                component="h2"
                                fontWeight="600"
                                fontSize="1.6rem"
                                className="h1"
                              >
                                <strong>{reservationsStat}</strong>
                                <span>Appointments</span>
                              </Typography>
                            </CardActionArea>
                          </Link>
                        </Card>
                      )}
                    </Grid>
                    <Grid
                      xs={12}
                      sm={4}
                      md={3}
                      lg={3}>
                      {isAutomobile ? (
                        <Grid container className="h-100">
                          <Grid xs={12}>
                            <Card className="card" sx={{ height: "90%" }}>
                              <Link
                                to={VisitsPage.Path}
                                state={{
                                  startDT: Utils.GetISODateTimeString(
                                    selectedDate[0]
                                  ),
                                  endDT: Utils.GetISODateTimeString(
                                    selectedDate[1]
                                  ),
                                  visitStatus: VisitStatuses.Delivered,
                                  navigationStack: [OverviewPage.Path],
                                }}
                                style={{ display: "flex", "flex-direction": "row", "justify-content": "space-between" }}
                              >
                                <CardActionArea style={{ "justify-content": "space-between" }}>
                                  <Typography
                                    level="h2"
                                    component="h2"
                                    fontWeight="600"
                                    style={{ "font-size": "24px" }}
                                  >
                                    <strong>{deliveredStat}</strong>
                                    <span>Delivered</span>
                                  </Typography>
                                  <Box
                                    className="text-end"
                                    component="img"
                                    sx={{
                                      height: 32,
                                      width: 32,
                                    }}
                                    style={{ "align-self": "start" }}
                                    alt="Delivered"
                                    src={Deliver_image}
                                  />
                                </CardActionArea>
                              </Link>
                            </Card>
                          </Grid>
                          <Stack justifyContent="end" className="w-100">
                            <Card className="card w-100" sx={{ height: "90%" }}>
                              <Link
                                to={VisitsPage.Path}
                                state={{
                                  startDT: Utils.GetISODateTimeString(
                                    selectedDate[0]
                                  ),
                                  endDT: Utils.GetISODateTimeString(
                                    selectedDate[1]
                                  ),
                                  visitStatus: VisitTypes.Delayed,
                                  navigationStack: [OverviewPage.Path],
                                }}
                                style={{ display: "flex", "flex-direction": "row", "justify-content": "space-between" }}
                              >
                                <CardActionArea style={{ "justify-content": "space-between" }}>
                                  <Typography
                                    level="h2"
                                    component="h2"
                                    fontWeight="600"
                                    style={{ "font-size": "24px" }}
                                  >
                                    <strong>{delayedStat}</strong>
                                    <span>Delayed</span>
                                  </Typography>
                                  <Box
                                    className="text-end"
                                    component="img"
                                    sx={{
                                      height: 32,
                                      width: 32,
                                    }}
                                    style={{ "align-self": "start" }}
                                    alt="Delayed"
                                    src={Delay_image}
                                  />
                                </CardActionArea>
                              </Link>
                            </Card>
                          </Stack>
                        </Grid>
                      ) : (
                        <Card className="card">
                          <Link
                            to={VisitsPage.Path}
                            state={{
                              startDT: Utils.GetISODateTimeString(
                                selectedDate[0]
                              ),
                              endDT: Utils.GetISODateTimeString(
                                selectedDate[1]
                              ),
                              navigationStack: [OverviewPage.Path],
                              partiallyPaid: true,
                            }}
                          >
                            <CardActionArea className="p-2" style={{ "justify-content": "space-between", "flex-direction": "column", "align-items": "start", "height": "100%" }}>
                              <Box
                                component="img"
                                sx={{
                                  height: 30,
                                  width: 30,
                                  alignSelf: "end"
                                }}
                                alt="Appointments"
                                src={PartialPaid_image}
                              />
                              <Typography
                                level="h2"
                                component="h2"
                                fontWeight="600"
                                fontSize="1.6rem"
                                className="h1"
                              >
                                <strong>{partiallyPaidStat}</strong>
                                <span>Partially Paid</span>
                              </Typography>
                            </CardActionArea>
                          </Link>
                        </Card>
                      )}
                    </Grid>
                    <Grid
                      xs={12}
                      sm={3}
                      md={3}
                      lg={3}
                      className="text-end quote-img">
                      {isAutomobile ? (
                        <Box
                          component="img"
                          sx={{
                            width: "220px",
                          }}
                          alt="Automobile"
                          src={automobile_image}
                        />
                      ) : (<Box
                        component="img"
                        sx={{
                          width: "240px",
                        }}
                        alt="Salon"
                        src={salon_image}
                      />)}
                    </Grid>
                  </Grid>
                </Grid>

                {isAutomobile && (
                  <Grid xs alignSelf="center">
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="700"
                      fontSize="1.6rem"
                      className="h1"
                    >
                      Job Card Status
                    </Typography>
                  </Grid>
                )}
                <Grid container>
                  <Grid xs={12} md={6} lg={6}>
                    {/* Job Cards */}
                    {isAutomobile && (
                      <Grid container>
                        <Grid container>
                          {/* <Grid xs alignSelf="center">
                            <Typography
                              level="h2"
                              component="h2"
                              fontWeight="700"
                              fontSize="1.6rem"
                              className="h1"
                            >
                              Job Card Status
                            </Typography>
                          </Grid> */}
                          {/* <Stack direction="row">
                          <Link to={CheckOutPage.Path} state={{ onSuccess: OverviewPage.Path, navigationStack: [OverviewPage.Path], }} className="btn-secondary white-bg" style={{"display":"flex"}}>
                            <CheckCircleOutlineOutlinedIcon sx={{ fontSize: "24px", marginRight: "6px", alignSelf : "Center"  }}/><span>Check-Out</span>
                          </Link>
                          <Link to={CustomersPage.Path} state={{ fromJobcard: true }} className="btn-primary ml-1" style={{"display":"flex"}}>
                            <EditNoteIcon sx={{ fontSize: "24px", marginRight: "6px", alignSelf : "Center" }} /><span>Create Job Card</span>
                          </Link>
                        </Stack> */}
                          <Grid
                            container
                            spacing={1}
                            marginTop="1px"
                          >
                            <Grid item flex="1">
                              <Card className="card">
                                <Link
                                  to={VisitsPage.Path}
                                  state={{
                                    startDT: Utils.GetISODateTimeString(
                                      selectedDate[0]
                                    ),
                                    endDT: Utils.GetISODateTimeString(
                                      selectedDate[1]
                                    ),
                                    visitStatus: VisitStatuses.Open,
                                    navigationStack: [OverviewPage.Path],
                                  }}
                                >
                                  <CardActionArea style={{ "justify-content": "space-between" }}>
                                    <Typography
                                      level="h2"
                                      component="h2"
                                      fontWeight="600"
                                      style={{ "font-size": "24px" }}
                                    >
                                      <strong>{openedStat}</strong>
                                      <span>Open</span>
                                    </Typography>
                                    <Box
                                      className="text-end"
                                      component="img"
                                      sx={{
                                        height: 30,
                                        width: 30,
                                      }}
                                      style={{ "align-self": "start" }}
                                      alt="Open"
                                      src={OpenJobCard_image}
                                    />
                                  </CardActionArea>
                                </Link>
                              </Card>
                            </Grid>
                            <Grid item flex="1">
                              <Card className="card">
                                <Link
                                  to={VisitsPage.Path}
                                  state={{
                                    startDT: Utils.GetISODateTimeString(
                                      selectedDate[0]
                                    ),
                                    endDT: Utils.GetISODateTimeString(
                                      selectedDate[1]
                                    ),
                                    visitStatus: VisitStatuses.Assigned,
                                    navigationStack: [OverviewPage.Path],
                                  }}
                                >
                                  <CardActionArea style={{ "justify-content": "space-between" }}>
                                    <Typography
                                      level="h2"
                                      component="h2"
                                      fontWeight="600"
                                      style={{ "font-size": "24px" }}
                                    >
                                      <strong>{assignedStat}</strong>
                                      <span>Assigned</span>
                                    </Typography>
                                    <Box
                                      className="text-end"
                                      component="img"
                                      sx={{
                                        height: 30,
                                        width: 30,
                                      }}
                                      style={{ "align-self": "start" }}
                                      alt="Assigned"
                                      src={AssignJobCard_image}
                                    />
                                  </CardActionArea>
                                </Link>
                              </Card>
                            </Grid>
                            <Grid item flex="1">
                              <Card className="card">
                                <Link
                                  to={VisitsPage.Path}
                                  state={{
                                    startDT: Utils.GetISODateTimeString(
                                      selectedDate[0]
                                    ),
                                    endDT: Utils.GetISODateTimeString(
                                      selectedDate[1]
                                    ),
                                    visitStatus: VisitStatuses.WorkInProgress,
                                    navigationStack: [OverviewPage.Path],
                                  }}
                                >
                                  <CardActionArea style={{ "justify-content": "space-between" }}>
                                    <Typography
                                      level="h2"
                                      component="h2"
                                      fontWeight="600"
                                      style={{ "font-size": "24px" }}
                                    >
                                      <strong>{inprogressStat}</strong>
                                      <span>In&nbsp;Progress</span>
                                    </Typography>
                                    <Box
                                      className="text-end"
                                      component="img"
                                      sx={{
                                        height: 30,
                                        width: 30,
                                      }}
                                      style={{ "align-self": "start" }}
                                      alt="In Progress"
                                      src={InProgressJobCard_image}
                                    />
                                  </CardActionArea>
                                </Link>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {/* Events & Manage */}
                    <Grid xs={12} container>
                      <Stack
                        justifyContent="space-between"
                        direction="row"
                        style={{ width: "100%" }}
                        spacing={1}
                        className="xs-column"
                      >
                        {/* Events */}
                        <Grid container spacing={1} xs={12} md={6} lg={6} className="m-xs-0">
                          <Grid item spacing container xs={12} className="pl-0">
                            {isAutomobile && (
                              <Grid item xs={6}>
                                <Card className="card h-100">
                                  <Link to={InsurancePage.Path}>
                                    <CardActionArea style={{ "justify-content": "center" }}>
                                      <Typography
                                        level="h2"
                                        component="h2"
                                        fontWeight="600"
                                        style={{ "font-size": "24px" }}
                                        className="text-center"
                                      >
                                        <HealthAndSafetyOutlinedIcon style={{ "font-size": "32px" }} />
                                        <span>Insurance</span>
                                      </Typography>
                                    </CardActionArea>
                                  </Link>
                                </Card>
                              </Grid>
                            )}

                            {isAutomobile ? (
                              <Grid item xs={6}>
                                <Card className="card h-100">
                                  <Link to={BirthdayPage.Path}>
                                    <CardActionArea style={{ "justify-content": "center" }}>
                                      <Typography
                                        level="h2"
                                        component="h2"
                                        fontWeight="600"
                                        style={{ "font-size": "24px" }}
                                        className="text-center"
                                      >
                                        <CakeOutlinedIcon style={{ "font-size": "32px" }} />
                                        <span>Birthday</span>
                                      </Typography>
                                    </CardActionArea>
                                  </Link>
                                </Card>
                              </Grid>
                            ) : (
                              <Grid item xs={12} style={{ "height": "273px" }} className="h-xs-100">
                                <Link to={BirthdayPage.Path} mx={1}>
                                  <Card className="card h-100">
                                    <CardActionArea className="h-100" style={{ "justify-content": "center" }}>
                                      <Typography
                                        level="h2"
                                        component="h2"
                                        fontWeight="600"
                                        className="text-center"
                                      >
                                        <Box
                                          className="text-end mb-1"
                                          component="img"
                                          sx={{
                                            height: "74px !important",
                                            width: "74px !important",
                                            background: "transparent !important"
                                          }}
                                          alt="Birthday"
                                          src={Birthday_image}
                                        />
                                        <span>Birthday</span>
                                      </Typography>

                                    </CardActionArea>
                                  </Card>
                                </Link>
                              </Grid>
                            )}
                          </Grid>
                          {isAutomobile && (
                            <Grid item spacing container xs={12} className="pl-0">
                              <Grid item xs={6}>
                                <Card className="card h-100">
                                  <Link
                                    to={ServiceReminderPage.Path}
                                    state={{
                                      startDT: Utils.GetISODateTimeString(
                                        selectedDate[0]
                                      ),
                                      endDT: Utils.GetISODateTimeString(
                                        selectedDate[1]
                                      ),
                                      navigationStack: [OverviewPage.Path],
                                    }}
                                  >
                                    <CardActionArea style={{ "justify-content": "center" }}>
                                      <Typography
                                        level="h2"
                                        component="h2"
                                        fontWeight="600"
                                        style={{ "font-size": "24px" }}
                                        className="text-center"
                                      >
                                        <InsertInvitationOutlinedIcon style={{ "font-size": "32px" }} />
                                        <span>Service <br /> Reminder</span>
                                      </Typography>
                                    </CardActionArea>
                                  </Link>
                                </Card>
                              </Grid>
                              <Grid item xs={6}>
                                {CanRaisePaymentLink(localSettings.business) && (
                                  <Card className="card h-100">
                                    <Link
                                      to={PaymentLinksPage.Path}
                                      state={{
                                        ...location.state,
                                        startDT: Utils.GetISODateTimeString(
                                          selectedDate[0]
                                        ),
                                        endDT: Utils.GetISODateTimeString(
                                          selectedDate[1]
                                        ),
                                        navigationStack: [OverviewPage.Path],
                                        activeMenu: SideBarMenuNames.Overview,
                                      }}
                                    >
                                      <CardActionArea style={{ "justify-content": "center" }}>
                                        <Typography
                                          level="h2"
                                          component="h2"
                                          fontWeight="600"
                                          style={{ "font-size": "24px" }}
                                          className="text-center"
                                        >
                                          <strong style={{ "margin": "3px 0", "display": "block" }}>{paymentLinkStat}</strong>{" "}
                                          <span>Payment <br /> Links</span>
                                        </Typography>
                                      </CardActionArea>
                                    </Link>
                                  </Card>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                        { }

                        <Grid container spacing={1} xs={12} md={6} lg={6} className="m-xs-0">
                          {isAutomobile ? (
                            <Grid item xs={12} md={6} lg={6} className="pl-0">
                              <Link to={ExpensePage.Path} mx={1}>
                                <Card className="card h-100">
                                  <CardActionArea className="h-100" style={{ "justify-content": "center" }}>
                                    <Typography
                                      level="h2"
                                      component="h2"
                                      fontWeight="600"
                                      style={{ "font-size": "24px" }}
                                      className="text-center"
                                    >
                                      <Box
                                        className="text-end mb-1"
                                        component="img"
                                        sx={{
                                          height: "74px !important",
                                          width: "74px !important",
                                          background: "transparent !important"
                                        }}
                                        alt="Expense"
                                        src={Expense_image}
                                      />
                                      <span>Expense</span>
                                    </Typography>
                                  </CardActionArea>
                                </Card>
                              </Link>
                            </Grid>
                          ) : (
                            <Grid item xs={12} className="h-100 pl-0">
                              <Link to={ExpensePage.Path} mx={1}>
                                <Card className="card h-100">
                                  <CardActionArea className="h-100" style={{ "justify-content": "center" }}>
                                    <Typography
                                      level="h2"
                                      component="h2"
                                      fontWeight="600"
                                      style={{ "font-size": "24px" }}
                                      className="text-center"
                                    >
                                      <Box
                                        className="text-end mb-1"
                                        component="img"
                                        sx={{
                                          height: "74px !important",
                                          width: "74px !important",
                                          background: "transparent !important"
                                        }}
                                        alt="Expense"
                                        src={Expense_image}
                                      />
                                      <span>Expense</span>
                                    </Typography>
                                  </CardActionArea>
                                </Card>
                              </Link>
                            </Grid>
                          )}
                          <Grid item xs={12} md={6} lg={6} className="pl-xs-0">
                            {isAutomobile && (
                              <Link
                                to={VisitsPage.Path}
                                state={{
                                  startDT: Utils.GetISODateTimeString(
                                    selectedDate[0]
                                  ),
                                  endDT: Utils.GetISODateTimeString(
                                    selectedDate[1]
                                  ),
                                  paymentType: PaymentMethodTypes.UnPaid,
                                  navigationStack: [OverviewPage.Path],
                                }}
                                mx={1}
                              >
                                <Card className="card h-100">
                                  <CardActionArea className="h-100" style={{ "justify-content": "center" }}>
                                    <Typography
                                      level="h2"
                                      component="h2"
                                      fontWeight="600"
                                      style={{ "font-size": "24px" }}
                                      className="text-center"
                                    >
                                      <Box
                                        className="text-end mb-1"
                                        component="img"
                                        sx={{
                                          height: "74px !important",
                                          width: "74px !important",
                                          background: "transparent !important"
                                        }}
                                        alt="Outstanding"
                                        src={Outstanding_image}
                                      />
                                      <span>Outstanding</span>
                                    </Typography>
                                  </CardActionArea>
                                </Card>
                              </Link>
                            )}

                          </Grid>
                        </Grid>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid xs={12} container md={6} lg={6}>
                    <Stack
                      justifyContent="space-between"
                      direction="row"
                      style={{ width: "100%" }}
                      spacing={1}
                      className="xs-column"
                    >
                      <Grid container spacing={1} className="w-100">
                        <Grid item xs={12} md={6} lg={6} className="px-xs-0">
                          {/* <Typography
                            level="h4"
                            component="h4"
                            fontWeight="600"
                            fontSize="1.6rem"
                            className="h1 text-center"
                          >
                            Monthly Planner
                          </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateCalendar />
                        </LocalizationProvider> */}
                          <Card className="card p-2">
                            <Grid>
                              <Grid>
                                <Typography
                                  level="strong"
                                  component="strong"
                                  fontWeight="600"
                                  fontSize="1.6rem"
                                  className="mt-4"
                                >
                                  Membership<br />Plan
                                </Typography>
                                <Link to={AMCPlanPage.Path} style={{ "text-decoration": "underline" }} className="mt-2">
                                  View all
                                </Link>
                              </Grid>
                              <Grid className="text-end">
                                <Box
                                  className="text-end"
                                  component="img"
                                  sx={{
                                    height: "135px",
                                  }}
                                  alt="AMC"
                                  src={AMC_image}
                                />
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} className="pl-xs-0">
                          <Card className="card p-2">
                            <Grid>
                              <Grid>
                                <Typography
                                  level="strong"
                                  component="strong"
                                  fontWeight="600"
                                  fontSize="1.6rem"
                                  className="mt-4"
                                >
                                  We're here <br /> to assist you
                                </Typography>
                                <Link onClick={() => setIsContctUs(true)} style={{ "text-decoration": "underline" }} className="mt-2">
                                  Contact us
                                </Link>
                              </Grid>
                              <Grid className="text-end">
                                <Box
                                  className="text-end"
                                  component="img"
                                  sx={{
                                    height: "135px",
                                  }}
                                  alt="Support"
                                  src={Support_image}
                                />
                              </Grid>
                            </Grid>
                          </Card>

                          {/* <Card className="card p-2 pb-1 mt-2">
                          <Typography
                              level="strong"
                              component="strong"
                              fontWeight="600"
                              fontSize="1.6rem"
                              className="mt-4"
                          >
                            How is your business <br /> management going?
                          </Typography>
                          <Stack class="mt-1">
                            <small>Review Rating</small>
                            <StyledRating
                              name="highlight-selected-only"
                              defaultValue={4}
                              IconContainerComponent={IconContainer}
                              getLabelText={(value) => customIcons[value].label}
                              highlightSelectedOnly
                              />
                          </Stack>
                        </Card> */}
                        </Grid>
                      </Grid>
                    </Stack>
                  </Grid>
                </Grid>

                {/* Payouts */}
                {showPayouts && (
                  <Grid container>
                    <Grid container mt={3}>
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="1.6rem"
                        className="h1"
                      >
                        Payouts
                      </Typography>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs>
                        <Card className="card">
                          <Link href="/Overview/">
                            <CardActionArea>
                              <CardContent className="p-h">
                                <Typography
                                  level="h3"
                                  component="h3"
                                  fontSize="2rem"
                                  fontWeight="600"
                                >
                                  {Utils.GetCurrency(
                                    payoutTotalStat - payoutSettledStat,
                                    localSettings.country,
                                    0
                                  )}
                                </Typography>
                                <Typography
                                  level="span"
                                  component="span"
                                  fontSize="1rem"
                                >
                                  Pending
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Link>
                        </Card>
                      </Grid>
                      <Grid item xs>
                        <Card className="card">
                          <Link href="/Overview/">
                            <CardActionArea>
                              <CardContent className="p-h">
                                <Typography
                                  level="h3"
                                  component="h3"
                                  fontSize="2rem"
                                  fontWeight="600"
                                >
                                  {Utils.GetCurrency(
                                    payoutSettledStat,
                                    localSettings.country,
                                    0
                                  )}
                                </Typography>
                                <Typography
                                  level="span"
                                  component="span"
                                  fontSize="1rem"
                                >
                                  Paid
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Link>
                        </Card>
                      </Grid>
                      <Grid item xs>
                        <Card className="card">
                          <Link href="/Overview/">
                            <CardActionArea>
                              <CardContent className="p-h">
                                <Typography
                                  level="h3"
                                  component="h3"
                                  fontSize="2rem"
                                  fontWeight="600"
                                >
                                  {Utils.GetCurrency(
                                    payoutTotalStat,
                                    localSettings.country,
                                    0
                                  )}
                                </Typography>
                                <Typography
                                  level="span"
                                  component="span"
                                  fontSize="1rem"
                                >
                                  Total
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Link>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default Overview;


