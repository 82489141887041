import React, { useState, useEffect } from "react";
import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";
import { postAsync } from "../core/serviceClient";
import { useDispatch } from "react-redux";
import Switch from "@mui/material/Switch";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { useNavigate, Link as RouteLink } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BusinessHelper from "../core/BusinessHelper";
import Loader from "./common/Loader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import MessagePopup from "./common/MessagePopUp";
import ConfirmPopup from "./common/ConfirmPopup";
import SideBarMenuItems from "./SideBarMenuItems";
import { GridActionsCellItem } from "@mui/x-data-grid";

import SettingsMenuItems from "./SettingsMenuItems";
import { SideBarMenuNames, ConfirmTypes } from "../core/Constants";
import CloseIcon from "@mui/icons-material/Close";
import { connectToStore } from "../data/store";
import Utils from "../core/Utils";
import UserHelper from "../core/UserHelper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoginPage, AddNewSparePage, SparesPage } from "../core/PageConstants";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const Spares = () => {
  document.title = BusinessHelper.GetSparePageTitle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [localSettings, setLocalSettings] = useState({});
  const [selectedSpares, setSelectedSpares] = useState({});
  const [department, setDepartment] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [SpareList, setSpareList] = useState([]);
  const [originalList, setoriginalList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [catList, setcatList] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [category, setCategory] = useState("");
  const [valuestoAdd, setValuestoAdd] = useState(0);
  const [valuestosub, setValuestoSub] = useState(0);
  const [openAddQty, setOpenAddQty] = useState(false);
  const [opensubQty, setOpenSubQty] = useState(false);
  const [Onhandset, setOnhandSet] = useState(0);
  const [modallist, setModalList] = useState([]);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfrimText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    {
      field: "active",
      headerName: "Active",
      headerClassName: "headerStyle",
      type: "boolean",
      flex: 1
    },
    {
      field: "shortDesc",
      headerName: "Product Details",
      headerClassName: "headerStyle",
      flex: 2,
      renderCell: (params) => {
        const shortDesc = params.row.shortDesc;
        const codeText = params.row.code;
        const description = params.row.description;
        return (
          <Stack>
            <Typography
              component="strong"
              className="px-1 card"
              title="Code">{codeText}</Typography>
            <Typography
              padding="0"
              component="h4"
              fontSize="2rem"
              fontWeight="600"
              className="mt-1 h4">
              {shortDesc}
            </Typography>
            <p className="mb-0">{description}</p>
          </Stack>
        );
      }
    },
    , {
      field: "price",
      valueGetter: (params) => {
        return Utils.GetCurrency(params.row.price);
      },
      headerName: "Price",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "taxPercentage",
      type: "number",
      valueGetter: (params) => {
        return params.row.taxPercent + "%";
      },
      flex: 1,
      headerName: "Tax",
      headerClassName: "headerStyle",
    },
    {
      field: "onHandQuantity",
      type: "number",
      headerName: "On Hand",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "headerStyle",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          UserHelper.CanEdit() ? (
            <Box style={{ "text-align": "center" }}>
              <GridActionsCellItem
                icon={<IndeterminateCheckBoxOutlinedIcon className="icon20" />}
                label=" Remove"
                className="textPrimary"
                onClick={() => onHandRemovemod(row)}
                color="inherit"
              />

              <GridActionsCellItem
                icon={<AddBoxOutlinedIcon className="icon20" />}
                label="Add"
                className="textPrimary"
                onClick={() => onHandAddmod(row)}
                color="inherit"
              />
              <GridActionsCellItem
                icon={<EditRoundedIcon className="icon20" />}
                label="Edit"
                className="textPrimary"
                onClick={() => handleEditSpares(row)}
                color="inherit"
              />
              <GridActionsCellItem
                icon={<DeleteOutlineIcon className="icon20" />}
                label="Delete"
                onClick={() => deleteSpares(row)}
                color="error"
              />
            </Box>
          ) : (
            <GridActionsCellItem
              icon={<VisibilityIcon className="icon20" />}
              label="View"
              onClick={() => handleEditSpares(row)}
              color="inherit"
            />
          ),
        ];
      },
    },
  ];
  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };
  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
      if (!Utils.IsNullOrEmptyObject(selectedSpares)) {
        UserFormDelete(selectedSpares);
      }
    }
  };
  const onHandAddmod = (rows) => {
    setOnhandSet(rows.onHandQuantity);
    setModalList(rows);
    setOpenAddQty(true);
  };
  const onHandRemovemod = (rows) => {
    setOnhandSet(rows.onHandQuantity);
    setModalList(rows);
    setOpenSubQty(true);
  };
  const handleEmpty = () => {
    setCategory("");
  };
  const handleEmptyDept = () => {
    setDepartment("");
  };
  const handleincrementQty = (e) => {
    setIsLoading(true);
    e.preventDefault();

    SparesSubmit(modallist);
    setOpenAddQty(false);
    setIsLoading(false);
  };
  const handledecrementQty = (e) => {
    setIsLoading(true);
    e.preventDefault();

    SparesSubmit(modallist);
    setOpenSubQty(false);
    setIsLoading(false);
  };

  const handleClosesub = () => {
    setOpenSubQty(false);
  };
  const handleKeyUp = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleSearch();
    }
  };
  const handleCloseAdd = () => {
    setOpenAddQty(false);
  };

  const handleRefresh = () => {
    setSearchInput("");
    getSpares();
  };

  const handleSearch = () => {
    setIsLoading(true);
    const filtered = originalList.filter(
      (item) =>
        item.shortDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.description.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.code.toLowerCase().includes(searchInput.toLowerCase())
    );
    setSpareList(filtered);
    setIsLoading(false);
  };
  const handleFilter = () => {
    setIsLoading(true);
    let filteredone = originalList.filter((item) => {
      if (department && category) {
        return item.department == department && item.class == category;
      } else if (category) {
        return item.class == category;
      } else if (department) {
        return item.department == department;
      }
    });
    setSpareList(filteredone);
    setIsLoading(false);
  };
  async function SparesSubmit(modallist) {
    setIsLoading(true);
    if (valuestoAdd > 0) {
      var qtyAdd =
        parseFloat(modallist.onHandQuantity) + parseFloat(valuestoAdd);
      modallist.onHandQuantity = qtyAdd;
      // setMsgText("No of units has been Added successfully");
      // setMsgOpen(true);
      if (qtyAdd < 0) {
        setMsgText("No of Units should be more than on Hand");
        setMsgOpen(true);
      }
    }
    if (valuestosub > 0) {
      var qtySub =
        parseFloat(modallist.onHandQuantity) - parseFloat(valuestosub);
      modallist.onHandQuantity = qtySub;

      if (qtySub < 0) {
        setMsgText("No of Units should be less than on Hand");
        setMsgOpen(true);
        return;
      }
    }

    let result = await postAsync(
      "Product/SaveProduct",
      {
        BusinessId: localSettings.business.id,
        NewProduct: false,
        Product: modallist,
      },
      "",
      "Execute",
      "",
      []
    );
    setIsNavigateToLogin(result.isNavigateToLogin);
    if (result.error) {
      setMsgOpen(true);
      setMsgText(result.errorMessage);
    } else {
      setMsgText("On Hand Quantity has been updated successfully");
      setMsgOpen(true);
      setValuestoAdd(0);
      setValuestoSub(0);
      navigate(SparesPage.Path);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(SparesPage, navigate, localSetting)) {
      setLocalSettings(localSetting);
    }
  }, []);

  useEffect(() => {
    getSpares();
  }, [localSettings]);

  const getSpares = async () => {
    if (localSettings.authenticated) {
      setIsLoading(true);
      let result = await postAsync(
        "Product/GetProduct",
        {},
        "",
        "GetAll",
        "GetAllProductsByBusiness",
        [localSettings.business.id]
      );
      setIsNavigateToLogin(result.isNavigateToLogin);
      if (result.error) {
        setMsgOpen(true);
        setMsgText(result.errorMessage);
      } else {
        if (!Utils.IsNullOrEmptyArray(result.data)) {
          var Spares = result.data.filter(
            (item) =>
              item.isMaterial == false &&
              item.service == false &&
              item.labour == false
          );
          setSpareList(Spares);
          setoriginalList(Spares);
          const temDeptllist = [];
          const temCatllist = [];
          Spares.forEach((element) => {
            if (
              !Utils.IsNullOrEmpty(element.department) &&
              !temDeptllist.includes(element.department)
            ) {
              temDeptllist.push(element.department);
            }
            if (
              !Utils.IsNullOrEmpty(element.class) &&
              !temCatllist.includes(element.class)
            ) {
              temCatllist.push(element.class);
            }
          });
          setDeptList(temDeptllist);
          setcatList(temCatllist);
        }
      }
      setIsLoading(false);
    }
  };
  const deleteSpares = (Spares) => {
    setSelectedSpares(Spares);
    setConfrimText(
      "Are you sure you want to delete the " + Spares.shortDesc + "?"
    );
    setConfirmOpen(true);
  };
  async function UserFormDelete(Spares) {
    setIsLoading(true);
    let result = await postAsync(
      "Product/DeleteProduct",
      {
        Id: Spares.id,
        product: Spares,
        BusinessId: localSettings.business.id,
      },
      "",
      "Delete",
      "",
      []
    );
    if (result && result.data) {
      setMsgText("Deleted successfully");
      setMsgOpen(true);
      setSelectedSpares({});
      getSpares();
    } else {
      setMsgText("Unable to process your request");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }

  const handleChange = (event) => {
    setShowFilters(event.target.checked);
  };

  const handleEditSpares = (rows) => {
    navigate(AddNewSparePage.Path, {
      state: {
        selectedSpares: rows,
      },
    });
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
        />

        <Box>
          <Modal open={openAddQty}>
            <Box className="cs-modal font-size-14" padding={2}>
              <Stack direction="column" spacing={2}>
                <FormControl xs={8} fullWidth>
                  On Hand
                  <TextField
                    type="number"
                    value={Onhandset}
                    disabled
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>

                <FormControl xs={10} fullWidth>
                  No of Units
                  <TextField
                    type="number"
                    value={valuestoAdd}
                    onChange={(e) => setValuestoAdd(e.target.value)}
                    className="number-input"
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Stack>
              <br />
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                <Button
                  variant="contained"
                  className="btn btn-secondary"
                  type="close"
                  onClick={handleCloseAdd}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  className="btn btn-primary"
                  type="close"
                  onClick={handleincrementQty}
                >
                  Add
                </Button>
              </Stack>
            </Box>
          </Modal>
          <Modal open={opensubQty}>
            <Box className="cs-modal font-size-14" padding={3}>
              <Stack direction="column" spacing={2}>
                <FormControl xs={8} fullWidth>
                  On Hand
                  <TextField
                    type="number"
                    value={Onhandset}
                    disabled
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>

                <FormControl xs={8} fullWidth>
                  No of Units
                  <TextField
                    className="inputfield number-input"
                    type="number"
                    value={valuestosub}
                    onChange={(e) => setValuestoSub(e.target.value)}
                  />
                </FormControl>
              </Stack>
              <br />
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                <Button
                  className="btn btn-secondary"
                  variant="contained"
                  type="close"
                  onClick={handleClosesub}
                >
                  Close
                </Button>
                <Button
                  className="btn btn-primary white-text"
                  variant="contained"
                  type="close"
                  onClick={handledecrementQty}
                >
                  Remove
                </Button>
              </Stack>
            </Box>
          </Modal>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.BusinessSetup} />
            <Grid xs={12} className="content-sec">
              <Grid container direction="row" className="">
                <SettingsMenuItems
                  selectedMenu={BusinessHelper.GetSpareMenuName()}
                />
                <Grid xs={12} md={9}>
                  <Grid className="form-sec xs-pd-0" direction="column">
                    <Grid container direction="row" className="mb-1">
                      <Stack
                        alignSelf="center"
                        justifyContent="end"
                        direction="row"
                        flex="1"
                      >
                        <IconButton aria-label="Refresh" onClick={handleRefresh}>
                          <RefreshIcon sx={{ fontSize: 25 }} />
                        </IconButton>
                        <Typography
                          align="auto"
                          fontSize="1.2rem"
                          style={{ "white-space": "nowrap" }}
                        >
                          Filter
                          <Switch checked={showFilters} onChange={handleChange} />
                        </Typography>
                        {UserHelper.CanAdd() && (
                          <RouteLink to={AddNewSparePage.Path} style={{ "align-self": "center" }}>
                            <Button
                              variant="contained"
                              className="btn btn-sm btn-primary"
                            >
                              <AddCircleOutlineOutlinedIcon
                                fontSize="large"
                                sx={{ marginRight: "6px" }}
                              />
                              <span>Add New</span>
                            </Button>
                          </RouteLink>
                        )}
                      </Stack>
                    </Grid>
                    <Stack direction="row">
                      {showFilters && (
                        <Stack className="font-size-14 mb-1" sx={{ flex: 1 }}>
                          <Stack direction="row" spacing={1}>
                            <FormControl fullWidth className="select-sm">
                              <IconButton onClick={handleEmpty} className="close-icon" title="Clear">
                                <CloseIcon
                                  fontSize="large" />
                              </IconButton>
                              <InputLabel id="catLabel">Category</InputLabel>
                              <Select
                                labelId="catLabel"
                                label="Category"
                                variant="outlined"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                {!Utils.IsNullOrEmptyArray(catList) &&
                                  catList.map((item) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            <FormControl fullWidth className="select-sm">
                              <IconButton onClick={handleEmptyDept} className="close-icon" title="Clear">
                                <CloseIcon
                                  fontSize="large" />
                              </IconButton>
                              <InputLabel id="deptLabel">Department</InputLabel>
                              <Select
                                label="Department"
                                labelId="catLabel"
                                variant="outlined"
                                value={department}
                                onChange={(e) => setDepartment(e.target.value)}
                              >
                                {!Utils.IsNullOrEmptyArray(deptList) &&
                                  deptList.map((item) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            <Stack>
                              <Button
                                variant="contained"
                                className="btn btn-primary"
                                onClick={handleFilter}
                                style={{
                                  maxWidth: "40px",
                                  height: "100%",
                                }}
                              >
                                Filter
                              </Button>
                            </Stack>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                    <Grid item xs={12} className="font-size-14 data-grid-box">
                      <Box>
                        <DataGrid
                          getRowId={(row) => row.id}
                          rows={SpareList}
                          columns={columns}
                          disableColumnFilter={true}
                          disableColumnMenu={true}
                          rowSelection={false}
                          sx={{
                            boxShadow: 1,
                            border: 1,
                            borderColor: "primary.light",
                            "& .MuiDataGrid-cell:hover": {
                              color: "primary.main",
                            },
                          }}
                          slots={{ toolbar: CustomToolbar }}
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                              showFilters: false,
                            },
                          }}
                          className="data-grid"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter className="searchbar" />
    </GridToolbarContainer>
  );
}

export default Spares;
