import {
  DialogTitle,
  DialogContent,
  FormControl,
  FormControlLabel,
  Dialog,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Divider,
  Button,
  Typography,
  Stack,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Utils from "../../core/Utils";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { getCountry } from "../../data/localSettingsActions";
import AmountTypography from "./controls/AmountTypography";
import CompValueEntryField from "./controls/CompValueEntryField";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const ComplimentDiscountListTemplate = ({
  row,
  removeCompItem,
  i,
  country,
}) => {
  const handleRemove = () => {
    removeCompItem(i, row);
  };
  return (
    <Grid
      container
      xs={12}
      spacing={2}
      className="justify-content-between m-0 mt-1"
      padding="5px 10px"
    >
      <Grid xs={1} className="remove-icon">
        <RemoveCircleOutlineIcon onClick={() => handleRemove()} />
      </Grid>
      <Grid xs={3}>
        {row.type === "PercentDiscount" ? (
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            {row.value + "%"}
          </Typography>
        ) : (
          <AmountTypography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
            value={row.value}
            country={country}
          />
        )}
      </Grid>
      <Grid xs={4}>
        <Typography
          level="h3"
          component="h3"
          fontWeight="600"
          fontSize="2rem"
          className="h3"
        >
          {row.type === "PercentDiscount" ? "Percentage" : "Cash"}
        </Typography>
      </Grid>
      <Grid xs={4}>
        <AmountTypography
          level="h3"
          component="h3"
          fontWeight="600"
          fontSize="2rem"
          className="h3 text-right"
          value={row.discount}
          country={country}
        />
      </Grid>
    </Grid>
  );
};

ComplimentDiscountListTemplate.propTypes = {
  row: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  country: PropTypes.object.isRequired,
  removeCompItem: PropTypes.func.isRequired,
};

const ComplimentaryPopup = ({
  open,
  compType,
  compTypeSetter,
  closeComplimentary,
  existItemComp = { orderLevel: true },
  removeCompItem,
  addComp,
  compValueSetter,
  compValue,
  orderComplimentaryList = [],
  itemComplimentaryList = [],
  countryInfo = {},
  serviceItems = [],
  maxLimit,
}) => {
  const [country, setCountry] = useState(countryInfo);
  useEffect(() => {
    populateCountry();
  }, []);
  const populateCountry = () => {
    if (Utils.IsNullOrEmptyObject(country)) {
      setCountry(getCountry());
    }
  };
  const checkForUseConfirmation = () => {
    addComp();
  };

  const onChangeCompValue = (newValue) => {
    compValueSetter(newValue);
  };

  return (
    <>
      {/* Add Complimentary Modal */}
      <BootstrapDialog
        onClose={closeComplimentary}
        aria-labelledby="customized-dialog-title"
        open={open}
        className=""
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closeComplimentary}
        >
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            Complimentary
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className="cs-form font-size-14">
          <Stack direction="row" spacing={2}>
            <RadioGroup
              style={{ width: "100%" }}
              value={compType}
              onChange={(e) => {
                compTypeSetter(e.target.value);
                compValueSetter("");
              }}
            >
              <FormControlLabel
                value="Discount"
                control={<Radio name="complimentaryAmount" />}
                label="Discount %"
              />
              <FormControlLabel
                value="Cash"
                control={<Radio name="complimentaryAmount" />}
                label="Cash"
              />
            </RadioGroup>
            <FormControl fullWidth>
              <CompValueEntryField
                variant="outlined"
                maxLimit={maxLimit}
                compType={compType}
                value={compValue}
                setValue={onChangeCompValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <Button
                className="btn btn-primary white-text"
                variant="contained"
                style={{ height: "54px" }}
                onClick={() => checkForUseConfirmation()}
              >
                Add
              </Button>
            </FormControl>
          </Stack>
          <Divider className="my-1" />
          {!Utils.IsNullOrEmptyObject(existItemComp) &&
            existItemComp.orderLevel &&
            !Utils.IsNullOrEmptyArray(orderComplimentaryList) &&
            orderComplimentaryList.map((row, index) => (
              <ComplimentDiscountListTemplate
                row={row}
                i={index}
                removeCompItem={removeCompItem}
                country={country}
              />
            ))}
          {!Utils.IsNullOrEmptyObject(existItemComp) &&
            !Utils.IsNullOrEmptyArray(serviceItems) &&
            !existItemComp.orderLevel &&
            !Utils.IsNullOrEmptyArray(
              itemComplimentaryList.filter(
                (v) =>
                  !Utils.IsNullOrEmptyObject(
                    serviceItems.find((s) => s.Sequence === existItemComp.index)
                  ) &&
                  v.index ===
                  serviceItems.find((s) => s.Sequence === existItemComp.index)
                    .Sequence
              )
            ) &&
            itemComplimentaryList
              .filter(
                (v) =>
                  v.index ===
                  serviceItems.find((s) => s.Sequence === existItemComp.index)
                    .Sequence
              )
              .map((row, index) => (
                <ComplimentDiscountListTemplate
                  row={row}
                  i={index}
                  removeCompItem={removeCompItem}
                  country={country}
                />
              ))}
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
export default ComplimentaryPopup;

ComplimentaryPopup.propTypes = {
  compType: PropTypes.string.isRequired,
  closeComplimentary: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  useConfirmation: PropTypes.bool.isRequired,
  orderComplimentaryList: PropTypes.array.isRequired,
  itemComplimentaryList: PropTypes.array.isRequired,
  removeCompItem: PropTypes.func.isRequired,
  addComp: PropTypes.func.isRequired,
  existItemComp: PropTypes.object.isRequired,
  serviceItems: PropTypes.array.isRequired,
  countryInfo: PropTypes.object.isRequired,
};
