import React, { useEffect } from "react";
import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";
import { Container, Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SettingsMenuItems from "./SettingsMenuItems";
import ReportMenuItems from "./ReportMenuItems";
import SideBarMenuItems from "./SideBarMenuItems";
import { SideBarMenuNames } from "../core/Constants";
import UserHelper from "../core/UserHelper";
import { SettingsPage } from "../core/PageConstants";

const Settings = (props) => {
  document.title = "Settings";
  const navigate = useNavigate();

  useEffect(() => {
    UserHelper.CheckPermission(SettingsPage, navigate);
  }, []);
  return (
    <Container maxWidth="false" className="bg-color p-0 setting-page">
      <Grid className="container">
        <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
            <Grid xs={12} className="content-sec">
              <Grid container direction="column" className="">
                <SettingsMenuItems selectedMenu={" "} />
                <ReportMenuItems selectedMenu={" "} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default Settings;
