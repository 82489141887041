import React, { useState, useEffect } from "react";
import "../../Style.css";
import Utils from "../../core/Utils";
import UserHelper from "../../core/UserHelper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connectToStore } from "../../data/store";
import SideBarMenuItems from "../SideBarMenuItems";
import SettingsMenuItems from "../SettingsMenuItems";
import ReportMenuItems from "../ReportMenuItems";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {
  SideBarMenuNames,
  SettingsMenuList,
  MaxDateLimit,
} from "../../core/Constants";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MessagePopup from "../common/MessagePopUp";
import { Typography } from "@mui/material";
import { Grid, Box, Container, Stack, Switch } from "@mui/material";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Loader from "../common/Loader";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { getBusiness } from "../../data/localSettingsActions";
import { postAsync } from "../../core/serviceClient";
import { UserRoles } from "../../core/Constants";
import { LoginPage, ProfitAndLossReportPage } from "../../core/PageConstants";
import CountryHelper from "../../core/CountryHelper";

const ProfitAndLossReport = (props) => {
  document.title = "Profit And Loss";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [filterByBranch, setFilterByBranch] = useState(false);
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);

  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [reportContent, setReportContent] = useState("");
  const [emailId, setEmailId] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branches, setBranches] = useState([]);

  const handleFilterByBranchChange = (event) => {
    setFilterByBranch(event.target.checked);
  };

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      if (dateArray[0]._isValid == false || dateArray[1]._isValid == false) {
        setMsgText(
          "Please select a valid date range."
        );
        setMsgOpen(true);
      }
      else {
        let dateDiff = dateArray[1].diff(dateArray[0], "days");
        if (dateDiff > MaxDateLimit) {
          setMsgText(
            "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
          );

          setMsgOpen(true);
        } else {
          setSelectedDate(dateArray);
        }
      }
    }
  };

  const checkAuthentication = () => {
    if (
      UserHelper.CheckPermission(
        ProfitAndLossReportPage,
        navigate,
        localSettings
      )
    ) {
      const business = getBusiness();
      if (
        !Utils.IsNullOrEmptyObject(business) &&
        !Utils.IsNullOrEmptyArray(business.branchLocations)
      ) {
        setBranches(business.branchLocations);
      }
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleSelect = (event) => {
    setSelectedBranch(event.target.value);
  };

  const handleSubmit = async () => {
    const branchId =
      filterByBranch && selectedBranch !== null ? selectedBranch : "";
    const Admin =
      localSettings.user.role === UserRoles.GlobalAdmin && !filterByBranch;
    const currentDate = Utils.GetCurrentDate();
    setIsLoading(true);
    let response = await postAsync("Reports/ProfitandLossReport", {
      BusinessId: localSettings.business.id,
      CompanyName: localSettings.business.companyName,
      EmailId: emailId,
      startDate: selectedDate[0],
      endDate: selectedDate[1],
      branchId: branchId,
      UserProfile: localSettings.user,
      IsAdmin: Admin,
      LocalDate: Utils.GetISODateTimeString(currentDate),
      IsSendEmail: true,
      Token: localSettings.token,
    });
    setIsLoading(false);
    setIsNavigateToLogin(response.isNavigateToLogin);
    if (response.error) {
      setMsgText(response.errorMessage);
      setMsgOpen(true);
      return;
    }
    setReportContent(response.data.webContent);
  };

  const handleEmailClicked = async () => {
    const branchId =
      filterByBranch && selectedBranch !== null ? selectedBranch : "";
    const Admin =
      localSettings.user.role === UserRoles.GlobalAdmin && !filterByBranch;
    const currentDate = Utils.GetCurrentDate();
    setIsLoading(true);
    let dateDiff = selectedDate[1].diff(selectedDate[0], "days");
    if (dateDiff >= 365) {
      setIsLoading(false);
      setMsgText("The date range should be less than or equal to 365 days.");
      setMsgOpen(true);
      return false;
    } else if (!Utils.IsEmailValid(emailId)) {
      setIsLoading(false);
      setMsgText("Please enter a valid email ID.");
      setMsgOpen(true);
      return false;
    } else {
      let response = await postAsync("Reports/ProfitandLossReport", {
        BusinessId: localSettings.business.id,
        CompanyName: localSettings.business.companyName,
        EmailId: emailId,
        startDate: selectedDate[0],
        endDate: selectedDate[1],
        branchId: branchId,
        UserProfile: localSettings.user,
        IsAdmin: Admin,
        LocalDate: Utils.GetISODateTimeString(currentDate),
        IsSendEmail: true,
        Token: localSettings.token,
      });
      setIsNavigateToLogin(response.isNavigateToLogin);
      let errorMsg = "Unable to process your request.";
      if (response.error) {
        errorMsg = response.errorMessage;
      } else if (response.data.emailSent) {
        errorMsg = "Email sent successfully.";
      }
      setMsgText(errorMsg);
      setMsgOpen(true);
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />

        <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.InvoiceReport} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <ReportMenuItems
                selectedMenu={SettingsMenuList.BusinessReports.ProfitAndLoss}
              />
              <Grid xs={12} md={9}>
                <Grid spacing={2} className="form-sec">
                  <form container spacing={2} onSubmit={handleSubmit}>
                    <Stack
                        alignSelf="center"
                        justifyContent="space-between"
                        direction="row"
                        flex="1"
                        className="mb-1"
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                        >
                          <Switch
                            checked={filterByBranch}
                            onChange={handleFilterByBranchChange}
                          />

                          <Typography
                            variant="h6"
                            style={{ "white-space": "nowrap", marginLeft: "0" }}
                            alignSelf="center"
                          >
                            Filter by branch
                          </Typography>
                          {filterByBranch && (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              className="font-size-14"
                              sx={{width: "300px"}}
                            >
                              <InputLabel id="Branch-label">Branch Name</InputLabel>
                              <Select
                                value={selectedBranch}
                                onChange={handleSelect}
                                label="Branch Name"
                                InputLabelProps={{ shrink: true }}
                                style={{
                                  height: "50px",
                                }}
                              >
                                {branches.map((branch, index) => (
                                  <MenuItem key={index} value={branch.branchId}>
                                    {branch.branchName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          style={{ height: "100%" }}
                        >
                          <DateRangePicker
                            showLeadingZeros={true}
                            onChange={onDateChange}
                            maxDate={new Date()}
                            value={selectedDate}
                            locale={CountryHelper.GetDateCulture(localSettings.country.Culture)}
                            clearIcon={null}
                            calendarIcon={
                              <CalendarMonthOutlinedIcon
                                style={{
                                  "font-size": "24px",
                                  "align-self": "center",
                                  color: "#666666",
                                }}
                              />
                            }
                          />
                        </Stack>
                    </Stack>
                  </form>

                  <iframe
                    className="reportIframe card white-bg"
                    srcdoc={reportContent}
                    style={{
                      width: "100%",
                      height: "calc(99vh - 200px)",
                      marginTop: "7px",
                    }}
                  ></iframe>
                    <br />
                    
                  <Stack
                    direction="row"
                      className="fixed-btn"
                    >
                      <Grid container className="container"
                        justifyContent="end"
                    >
                      <Grid xs={12} md={3}></Grid>
                      <Grid xs={12} md={9}>
                        <Stack direction="row" justifyContent="end" spacing={1} className="font-size-14">
                          <FormControl fullWidth style={{"flex":"1"}}>
                            <TextField
                              label="Email"
                              value={emailId}
                              type="email"
                              onChange={(e) => {
                                setEmailId(e.target.value);
                              }}
                            />
                          </FormControl>
                          <Stack
                              direction="row"
                              xs={12}
                              justifyContent="end"
                              alignSelf="center"
                              spacing={1}
                              style={{"flex":"1"}}
                          >
                            <FormControl fullWidth>
                              <Button
                                variant="contained"
                                className="btn btn-primary"
                                onClick={handleEmailClicked}
                              >
                                Send Email
                              </Button>
                            </FormControl>
                            <FormControl fullWidth>
                              <Button
                                className="btn btn-primary"
                                variant="contained"
                                type="submit"
                                onClick={handleSubmit}
                              >
                                View Profit & Loss
                              </Button>
                            </FormControl>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                    
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default ProfitAndLossReport;
