import {
  DialogTitle,
  IconButton,
  styled,
  Dialog,
  Typography,
  Stack,
  DialogContent,
  FormControl,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Cash_Icon from "../../images/cash.png";
import Card_Icon from "../../images/creditCard.png";
import Netbanking_Icon from "../../images/Netbanking.png";
import Upi_Icon from "../../images/Upi.png";
import PaymentLink_Icon from "../../images/paymentlink.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
const PaymentTypeDialog = ({
  open,
  close,
  cash,
  card,
  upi,
  netbanking,
  paymentLink,
  isIndia,
  isUS,
  paymentLinkEnabled,
  ...other
}) => {
  return (
    <BootstrapDialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={open}
      {...other}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={close}>
        <Typography
          level="h3"
          component="h3"
          fontWeight="600"
          fontSize="2rem"
          className="h3"
        >
          Payment Type
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers className="font-size-14">
        {/*Cash Option*/}
        <Stack
          direction="row"
          spacing={2}
          className="card justify-content-between"
          padding="10px"
          onClick={cash}
        >
          <Stack direction="row">
            <img alt="Cash Pay" width={"50px"} src={Cash_Icon} style={{alignSelf:"Center"}} />
            <Stack
              direction="column"
              spacing={1}
              name="radio-buttons-group"
              className="pl-1"
            >
              <Typography
                level="h4"
                component="h4"
                fontWeight="600"
                fontSize="1.5rem"
              >
                Cash Pay
              </Typography>
              <Typography level="p" component="p" fontSize="1rem" className="m-0">
                Customer uses cash to pay
              </Typography>
            </Stack>
          </Stack>
          <NavigateNextIcon className="align-center" />
        </Stack>

        {/*Card Option*/}
        {!isUS && (
          <Stack
            direction="row"
            spacing={2}
            className="card justify-content-between mt-1"
            padding="10px"
            onClick={card}
          >
            <Stack direction="row">
              <img alt="Cash Pay" width={"50px"} src={Card_Icon} style={{alignSelf:"Center"}} />
              <Stack
                direction="column"
                spacing={1}
                name="radio-buttons-group"
                className="pl-1"
              >
                <Typography
                  level="h4"
                  component="h4"
                  fontWeight="600"
                  fontSize="1.5rem"
                >
                  Card Pay
                </Typography>
                <Typography level="p" component="p" fontSize="1rem" className="m-0">
                  Customer presents physical card
                </Typography>
              </Stack>
            </Stack>
            <NavigateNextIcon className="align-center" />
          </Stack>
        )}

        {/*NetBanking option */}
        {isIndia && (
          <Stack
            direction="row"
            spacing={2}
            className="card justify-content-between mt-1"
            padding="10px"
            onClick={netbanking}
          >
            <Stack direction="row">
              <img alt="Net Banking Pay" width={"50px"} src={Netbanking_Icon} style={{alignSelf:"Center"}} />
              <Stack
                direction="column"
                spacing={1}
                name="radio-buttons-group"
                className="pl-1"
              >
                <Typography
                  level="h4"
                  component="h4"
                  fontWeight="600"
                  fontSize="1.5rem"
                >
                  Net Banking Pay
                </Typography>
                <Typography level="p" component="p" fontSize="1rem" className="m-0">
                  Customer uses net banking to pay
                </Typography>
              </Stack>
            </Stack>
            <NavigateNextIcon className="align-center" />
          </Stack>
        )}

        {/*UPI Option*/}
        {isIndia && (
          <Stack
            direction="row"
            spacing={2}
            className="card justify-content-between mt-1"
            padding="10px"
            onClick={upi}
          >
            <Stack direction="row">
              <img alt="UPI Pay" width={"50px"} src={Upi_Icon} style={{alignSelf:"Center"}} />
              <Stack
                direction="column"
                spacing={1}
                name="radio-buttons-group"
                className="pl-1"
              >
                <Typography
                  level="h4"
                  component="h4"
                  fontWeight="600"
                  fontSize="1.5rem"
                >
                  UPI Pay
                </Typography>
                <Typography level="p" component="p" fontSize="1rem" className="m-0">
                  Customer uses UPI app to scan QR code and pay
                </Typography>
              </Stack>
            </Stack>
            <NavigateNextIcon className="align-center" />
          </Stack>
        )}

        {/* Payment Link*/}
        {paymentLinkEnabled && (
          <Stack
            direction="row"
            spacing={2}
            className="card justify-content-between mt-1"
            padding="10px"
            onClick={paymentLink}
          >
            <Stack direction="row">
              <img alt="Payment link" width={"50px"} src={PaymentLink_Icon} style={{alignSelf:"Center"}} />
              <Stack
                direction="column"
                spacing={1}
                name="radio-buttons-group"
                className="pl-1"
              >
                <Typography
                  level="h4"
                  component="h4"
                  fontWeight="600"
                  fontSize="1.5rem"
                >
                  Payment Link
                </Typography>
                <Typography level="p" component="p" fontSize="1rem" className="m-0">
                  Customer will receive a link in sms to make payments online
                </Typography>
              </Stack>
            </Stack>
            <NavigateNextIcon className="align-center" />
          </Stack>
        )}

        {/*Close Option */}
        <Stack
          direction="row"
          spacing={2}
          padding="10px 0 0px 0"
          justifyContent="flex-end"
          className="modal-footer"
        >
          <FormControl>
            <Button
              className="btn btn-secondary"
              type="submit"
              variant="contained"
              onClick={close}
            >
              Close
            </Button>
          </FormControl>
        </Stack>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default PaymentTypeDialog;
