import React, { useEffect, useState, useReducer, useRef, version } from "react";
import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import validator from "validator";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";

import Button from "@mui/material/Button";
import SideBarMenuItems from "./SideBarMenuItems";
import { useCallbackPrompt } from "./hooks/useCallbackPrompt";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { getMakeModels } from "../data/localSettingsActions";
import EditIcon from "@mui/icons-material/Edit";
import {
  getAuthentication,
  getBranch,
  getBusiness,
  getCountry,
  getEnterpriseId,
  getProducts,
} from "../data/localSettingsActions";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import PersonIcon from '@mui/icons-material/Person';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import Utils from "../core/Utils";
import { ConfirmTypes, MaxFileSizeLimit, NotApplicable, SideBarMenuNames, UAE, VisitTypeEnum } from "../core/Constants";
import { serviceItemReducer } from "./reducers/serviceItem/serviceItemReducer";
import { serviceItemActionTypes } from "./reducers/serviceItem/serviceItemActionTypes";
import { getServiceItemFromProduct } from "../helpers/productHelper";
import CartComponent from "./common/CartComponent";
import MessagePopup from "./common/MessagePopUp";
import PromptDialog from "./common/PromptDialog";
import UserHelper from "../core/UserHelper";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  CheckOutPage,
  CustomersPage,
  OverviewPage,
  PaymentPage,
  VisitsPage,
} from "../core/PageConstants";
import CustomProduct from "./CustomProduct";
import BusinessHelper from "../core/BusinessHelper";
import moment from "moment";
import CountryHelper from "../core/CountryHelper";
import { Regexes } from "../core/Regexes";
import { Divider, FormControl, FormControlLabel, IconButton, InputLabel, Input, MenuItem, Select, Radio, RadioGroup, Switch, TextField } from "@mui/material";
import { getGeocodeAddress, postAsync } from "../core/serviceClient";
import NumericTextField from "./common/controls/NumericTextField";
import AddressField from "./common/controls/AddressField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import PropaneIcon from "@mui/icons-material/Propane";
import EvStationIcon from "@mui/icons-material/EvStation";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AutoMobileHelper from "../core/AutomobileHelper";
import { connectToStore } from "../data/store";
import ConfirmPopup from "./common/ConfirmPopup";
import Loader from "./common/Loader";
import Users from "./Users";
import { ImageEnlarge } from "./common/ImageEnlarge";

const customerActionTypes = {
  RaiseJobcard: "RaiseJobcard",
  DeleteCustomer: "DeleteCustomer",
  DeleteVehicle: "DeleteVehicle",
  SaveCustomer: "SaveCustomer",
  SaveVehicle: "SaveVehicle",
  RaiseJobcardOnCustomerSave: "RaiseJobcardOnCustomerSave",
  DeleteInsuranceImage: "DeleteInsuranceImage",
  DeleteRCImage: "DeleteRCImage",
};

const CheckOut = () => {
  document.title = "CheckOut";
  const navigate = useNavigate();
  const location = useLocation();
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const [countryInfo, setCountryInfo] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [frequentlyUsedProducts, setFrequentlyUsedProducts] = useState([]);
  const [filteredServiceItems, itemDispatch] = useReducer(
    serviceItemReducer,
    []
  );
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [canProceedPayment, setCanProceedPayment] = useState(false);
  const [isSplit, setIsSplit] = useState(false);
  const [splitScreen, setSplitScreen] = useState(false);

  const setAlert = (msg) => {
    setAlertMessage(msg);
    setShowAlert(true);
  };



  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [localSettings, setLocalSettings] = useState({});
  const [openCustomerSelection, setOpenCustomerSelection] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [openAddNewCustomer, setOpenAddNewCustomer] = useState(false);
  const [customerKey, setCustomerKey] = useState();
  const [customerFirstName, setCustomerFirstName] = useState();
  const [customerLastName, setCustomerLastName] = useState();
  const [customerMobileNumber, setCustomerMobileNumber] = useState();
  const [customerEmailId, setCustomerEmailId] = useState("");
  const [isOpenedCustomAddress, setIsOpenedCustomAddress] = useState(false);
  const [customerCustomAddress, setCustomerCustomAddress] = useState("");
  const [customerCity, setCustomerCity] = useState("");
  const [customerState, setCustomerState] = useState("");
  const [customerPincode, setCustomerPincode] = useState("");
  const [customerAddress, setCustomerAddress] = useState();
  const [customerBusinessName, setCustomerBusinessName] = useState("");
  const [customerBusinessGSTIN, setCustomerBusinessGSTIN] = useState("");
  const [customerGSTINCode, setCustomerGSTINCode] = useState("");
  const [customerDOB, setCustomerDOB] = useState(moment("1973-01-01T00:00:00"));
  const [customerGender, setCustomerGender] = useState();
  const [saveCustomerDisabled, setSaveCustomerDisabled] = useState(false);
  const [useCustomAddress, setUseCustomAddress] = useState(true);
  const [isIndianBusiness, setIsIndianBusiness] = useState(false);
  const [regIdLength, setRegIdLength] = useState(0);
  const [isUAEBusiness, setIsUAEBusiness] = useState(false);
  const [isUSBusiness, setIsUSBusiness] = useState(false);
  const [gst, setGST] = useState(false);
  const [addressISOCode, setAddressISOCode] = useState("");
  const [showStateCode, setShowStateCode] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [customerAction, setCustomerAction] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [customerId, setCustomerId] = useState();
  const [selectedPass, setSelectedPass] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [customerVehicleList, setCustomerVehicleList] = useState([]);
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isAutomobileBusiness, setIsAutomobileBusiness] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [checkOutSelectedCustomer, setCheckOutSelectedCustomer] = useState([])


  // vehicle 
  const [makeModelList, setMakeModelList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [plateCode, setPlateCode] = useState("");
  const [vehicleYear, setVehicleYear] = useState("");
  const [vehicleColor, setVehicleColor] = useState("");
  const [vehicleChassisNumber, setVehicleChassisNumber] = useState("");
  const [vehicleEngineNumber, setVehicleEngineNumber] = useState("");

  const [vehicleInsPolicyNumber, setVehicleInsPolicyNumber] = useState("");
  const [vehicleInsPlaceOfIssue, setVehicleInsPlaceOfIssue] = useState("");
  const [vehicleInsExpiryDate, setVehicleInsExpiryDate] = useState(moment());
  const [vehicleInsCompany, setVehicleInsCompany] = useState("");

  const [vehicleMake, setVehicleMake] = useState("");
  const [selectedEmirate, setSelectedEmirate] = useState("");
  const [emirateType, setEmiratetype] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleFuelType, setVehicleFuelType] = useState("Petrol");
  const [vehicleFuelTypes, setVehicleFuelTypes] = useState([]);
  const [rcImage, setRCImage] = useState("");
  const [insuranceImage, setInsuranceImage] = useState("");
  const [vehicleSubmitCat, setVehicleSubmitCat] = useState("");
  const [addItems, setAddItems] = useState(false);
  const [addVehicle, setAddVehicle] = useState(false);
  const [vehicleType, setVehicleType] = useState("");
  const [openCustomMakeModel, setOpenCustomMakeModel] = useState(false);
  const [isVehicleEdit, setIsVehicleEdit] = useState(false);
  const [saveVehiclesDisabled, setSaveVehiclesDisabled] = useState(false);
  const [plateCodeDisabled, setPlateCodeDisabled] = useState(true);
  const [registrationValidated, setRegistrationValidated] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [insuranceGSTIN, setInsuranceGSTIN] = useState("");
  const [insuranceBranchAddress, setInsuranceBranchAddress] = useState("");
  const [enlargeImage, setEnlargeImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [modalTitle, setModalTitle] = useState("Add Vehicle");
  const [isSalonBusiness, setIsSalonBusiness] = useState(false);
  const [isEstimateEnabled, setIsEstimateEnabled] = useState(false);

  const hasRun = useRef(false);

  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(CheckOutPage, navigate)) {
      setCountryInfo(getCountry());
      var allProducts = getProducts();
      var defaultProducts = [];
      if (!Utils.IsNullOrEmptyArray(allProducts)) {
        defaultProducts = allProducts.filter((p) => p.default && p.active);
      }
      if (!Utils.IsNullOrEmptyArray(defaultProducts)) {
        setFrequentlyUsedProducts(defaultProducts);
      }
      if (!Utils.IsNullOrEmptyObject(location.state) && !Utils.IsNullOrEmptyArray(location.state.pass)) {
        setCheckOutSelectedCustomer(location.state.pass);
      }
      if (
        !Utils.IsNullOrEmptyObject(location.state) &&
        !Utils.IsNullOrEmptyArray(location.state.serviceItems)
      ) {
        if (hasRun.current) return;
        hasRun.current = true;
        location.state.serviceItems.forEach((item) => {
          if (
            Utils.IsNullOrEmptyObject(
              filteredServiceItems.find((s) => s.ProductId === item.ProductId)
            )
          )
            itemDispatch({
              type: serviceItemActionTypes.Add,
              value: item,
            });
        });
      }
      setLocalSettings(localSetting);
      const countryInfo = getCountry();
      if (!Utils.IsNullOrEmptyObject(localSetting.business)) {

        var isAutomobile = BusinessHelper.IsAutomobile(localSetting.business);
        var isSalon = BusinessHelper.IsSalon(localSetting.business)
        setIsAutomobileBusiness(isAutomobile);
        setIsSalonBusiness(isSalon);
        setIsEstimateEnabled(
          isAutomobile === true && countryInfo.Code === UAE.Code
        );
      }
      setCountry(countryInfo);
      var branch = getBranch();
      var typeMakeModels = getMakeModels() || [];
      var makeModels = typeMakeModels.filter((t) => t.type === branch.vehicleType);
      var makes = makeModels.map((c) => c.make);
      setMakeList(makes);
      setMakeModelList(makeModels)
    }
  }, []);

  const clickOpenPayment = () => {
    if (Utils.IsNullOrEmptyArray(filteredServiceItems)) {
      setAlert("Please add service items before payment");
      return;
    }
    if (
      filteredServiceItems.some((c) => c.Quantity === 0 || c.Quantity === "")
    ) {
      setAlert("Quantity must be greater than 0");
      return;
    }
    setShowDialog(false);
    setCanProceedPayment(true);
  };

  useEffect(() => {
    navigateToPayment();
  }, [canProceedPayment]);

  const navigateToPayment = () => {
    if (!Utils.IsNullOrEmptyObject(checkOutSelectedCustomer)) {
      location.state.pass = checkOutSelectedCustomer
    }
    if (canProceedPayment && !showDialog) {
      navigate(PaymentPage.Path, {
        state: {
          ...location.state,
          isEdit:
            Utils.IsNullOrEmpty(location.state) ||
              Utils.IsNullOrEmpty(location.state.isEdit)
              ? false
              : location.state.isEdit,
          fromVisit:
            Utils.IsNullOrEmpty(location.state) ||
              Utils.IsNullOrEmpty(location.state.fromVisit)
              ? false
              : location.state.fromVisit,
          visit:
            Utils.IsNullOrEmpty(location.state) ||
              Utils.IsNullOrEmptyObject(location.state.visit)
              ? null
              : location.state.visit,
          checkout: true,
          serviceItems: filteredServiceItems,
          pass:
            Utils.IsNullOrEmptyObject(location.state) ||
              Utils.IsNullOrEmptyObject(location.state.pass)
              ? Utils.IsNullOrEmptyObject(checkOutSelectedCustomer) ? null : checkOutSelectedCustomer
              : location.state.pass,
          vehicleDetails:
            Utils.IsNullOrEmptyObject(location.state) ||
              Utils.IsNullOrEmptyObject(location.state.vehicleDetails)
              ? Utils.IsNullOrEmptyObject(checkOutSelectedCustomer) && Utils.IsNullOrEmptyObject(checkOutSelectedCustomer.vehicleDetails) ? null : checkOutSelectedCustomer.vehicleDetails
              : location.state.vehicleDetails,
          validVehiclesCount:
            Utils.IsNullOrEmpty(location.state) ||
              Utils.IsNullOrEmpty(location.state.validVehiclesCount)
              ? 0
              : location.state.validVehiclesCount,
          searchText:
            location.state && location.state.searchText
              ? location.state.searchText
              : "",
          navigationStack: Utils.AddElementToArray(
            location.state.navigationStack,
            CheckOutPage.Path
          ),
        },
      });
    }
  };

  const handleServiceItemActions = (action) => {
    if (
      action.type === serviceItemActionTypes.Clear ||
      (filteredServiceItems.length === 1 &&
        action.type === serviceItemActionTypes.Delete)
    ) {
      setShowDialog(false);
    }
    itemDispatch(action);
  };

  const addFrequentlyUsedItem = (product) => {
    if (
      !Utils.IsNullOrEmptyObject(
        filteredServiceItems.find((s) => s.ProductId === product.id)
      )
    ) {
      setAlert("Product already added");
      return;
    }
    var item = getServiceItemFromProduct(product);
    if (Utils.IsNullOrEmptyArray(filteredServiceItems)) {
      item.Sequence = 1;
    } else {
      item.Sequence =
        filteredServiceItems[filteredServiceItems.length - 1].Sequence + 1;
    }
    itemDispatch({
      type: serviceItemActionTypes.Add,
      value: item,
    });
    configureDialog();
  };

  const addItemToCart = (product, isCustomProduct = false) => {
    var serviceItem = isCustomProduct
      ? product
      : getServiceItemFromProduct(product);
    serviceItem.reviewProduct = product.reviewProduct
    serviceItem.imageUrl = product.imageUrl
    if (Utils.IsNullOrEmptyArray(filteredServiceItems)) {
      serviceItem.Sequence = 1;
    } else {
      serviceItem.Sequence =
        filteredServiceItems[filteredServiceItems.length - 1].Sequence + 1;
    }
    itemDispatch({
      type: serviceItemActionTypes.Add,
      value: serviceItem,
    });
    configureDialog();
  };

  const configureDialog = () => {
    if (!showDialog) {
      setShowDialog(true);
    }
  };

  const backNavigation = () => {
    navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
      state: {
        ...location.state,
        navigationStack: Utils.RemoveLastElementFromArray(
          location.state.navigationStack
        ),
      },
    });
  };
  const addCustomProduct = (customItem) => {
    if (Utils.IsNullOrEmptyObject(customItem)) {
      return;
    }
    addItemToCart(customItem, true);
  };
  const clickCloseCustomProduct = () => {
    setIsSplit(false);
  };

  // customer Details

  useEffect(() => {
    checkCustomerDetails();
  }, [
    customerFirstName,
    customerLastName,
    customerMobileNumber,
    customerAddress,
    useCustomAddress,
    isOpenedCustomAddress,
    customerCustomAddress,
    customerCity,
    customerPincode,
    customerState,
  ]);

  const setCountry = (country) => {
    setCountryInfo(country);
    setAddressISOCode(country.ISOCode);
    var isIndia = CountryHelper.IsBusinessCountryIndia(country);
    var isUS = CountryHelper.IsBusinessCountryUSA(country);
    var isUAE = CountryHelper.IsBusinessCountryUAE(country);
    if (isIndia) {
      setShowStateCode(true);
      setGST(true);
      setIsIndianBusiness(true);
      setRegIdLength(15);
      return;
    }
    if (isUS) {
      setRegIdLength(9);
      setIsUSBusiness(true);
      return;
    }
    if (isUAE) {
      setRegIdLength(15);
      setIsUAEBusiness(true);
      return;
    }
  };

  const checkCustomerDetails = async (regValid = true) => {
    var validRegistrationId = Utils.IsNullOrEmpty(customerBusinessGSTIN);
    if (!validRegistrationId && isIndianBusiness) {
      validRegistrationId =
        customerBusinessGSTIN.length === regIdLength &&
        Regexes.GST.test(customerBusinessGSTIN);
    } else if (!validRegistrationId) {
      validRegistrationId = customerBusinessGSTIN.length === regIdLength;
    }
    var isAddressValid =
      (isOpenedCustomAddress && !Utils.IsNullOrEmpty(customerCity)) ||
      (!isOpenedCustomAddress && !Utils.IsNullOrEmpty(customerAddress));
    var mobileNumberValid = Utils.IsMobileValid(customerMobileNumber);
    var isDetailsFilled =
      !Utils.IsNullOrEmpty(customerFirstName) &&
      (!isUSBusiness || !Utils.IsNullOrEmpty(customerLastName)) &&
      isAddressValid &&
      validRegistrationId &&
      mobileNumberValid;
    if (
      !regValid &&
      !Utils.IsNullOrEmpty(customerBusinessGSTIN) &&
      validRegistrationId
    ) {
      var result = await postAsync("Business/ValidateRegistration", {
        GSTIN: gst,
        RegistrationId: Utils.ConvertToUpperCase(customerBusinessGSTIN.trim()),
      });
      if (!Utils.IsNullOrEmptyObject(result)) {
        isDetailsFilled = isDetailsFilled && result.data.valid;
        if (!Utils.IsNullOrEmpty(result.data.companyName)) {
          setCustomerBusinessName(result.data.companyName);
        }
      } else {
        isDetailsFilled = false;
      }
    }
    setSaveCustomerDisabled(!isDetailsFilled);
  };

  const handleMsgClose = () => {
    setAlertTitle("");
    setMsgOpen(false);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    customerSearchSubmit();
  };

  async function customerSearchSubmit() {
    if (Utils.IsNullOrEmpty(searchText) == true) return;
    setIsLoading(true);
    const business = getBusiness();
    let response = await postAsync("Customer/Search", {
      BusinessId: business.id,
      SearchText: searchText,
      IsAutomobileBusiness: business.category == "Automobile",
    });
    setIsLoading(false);
    if (!Utils.IsNullOrEmptyArray(response.data.passList)) {
      setCustomerList(
        response.data.passList
          .sort((a, b) => (a.userName > b.userName ? 1 : -1))
          .filter((user) => !user.isBusiness)
      );
    } else {
      setCustomerList([]);
    }
  }

  const handleAddCustomer = (customer) => {
    setCheckOutSelectedCustomer(customer)
    handleCloseCustomer();
  };

  function toggleCustomAddress() {
    setIsOpenedCustomAddress((wasOpened) => !wasOpened);
  }

  const customerSaveSubmit = () => {
    if (customerDOB > moment()) {
      setMsgText("Please enter a valid date of birth");
      setMsgOpen(true);
      return;
    }
    setCustomerAction(customerActionTypes.SaveCustomer);
    setConfirmText("Save customer?");
    setConfirmOpen(true);
  };

  const proceedSaveCustomer = async () => {
    setIsLoading(true);
    var business = localSettings.business;
    var tier = Utils.IsNullOrEmpty(customerId)
      ? business.tiers.find((c) => c.default)
      : business.tiers.find((c) => c.level === selectedPass.tierLevel);
    if (Utils.IsNullOrEmptyObject(tier)) {
      tier = business.tiers.filter((c) => c.default)[0];
    }
    var addressSegment1 = !Utils.IsNullOrEmpty(customerCustomAddress)
      ? customerCustomAddress + ","
      : "";
    var addressSegment2 = !Utils.IsNullOrEmpty(customerCity)
      ? customerCity + ","
      : "";
    var addressSegment3 = !Utils.IsNullOrEmpty(customerState)
      ? customerState + ","
      : "";
    var homeLocation = (
      addressSegment1 +
      addressSegment2 +
      addressSegment3 +
      getBranch().country
    ).trim();
    var addressDetails = null;
    if (!Utils.IsNullOrEmpty(customerAddress, homeLocation)) {
      var geocodeResponse = await getGeocodeAddress(
        customerAddress,
        homeLocation
      );
      if (
        !Utils.IsNullOrEmptyObject(geocodeResponse) &&
        geocodeResponse.error
      ) {
        setIsLoading(false);
        setMsgOpen(true);
        setMsgText(geocodeResponse.errorMessage);
        return;
      }
      if (
        !Utils.IsNullOrEmptyObject(geocodeResponse) &&
        geocodeResponse.status === "OK"
      ) {
        var locality = "";
        var county = "";
        var state = "";
        var country = "";
        var zipcode = "";
        var streetNumber = "";
        var route = "";
        var address = "";
        var lng = 0;
        var lat = 0;
        address = geocodeResponse.results[0].formatted_address;
        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("street_number") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("street_number")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            streetNumber = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("route") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("route")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            route = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("locality") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("locality")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            locality = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("administrative_area_level_1") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("administrative_area_level_1")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            state = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("administrative_area_level_2") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("administrative_area_level_2")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            county = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("country") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("country")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            country = ac.short_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("postal_code") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("postal_code")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            zipcode = ac.long_name;
          }
        }
        lat = geocodeResponse.results[0].geometry.location.lat;
        lng = geocodeResponse.results[0].geometry.location.lng;
        addressDetails = {
          HomeLocation: customerAddress,
          Address: streetNumber + " " + route,
          City: locality,
          State: state,
          Country: country,
          County: county,
          ZipCode: zipcode,
          Locality: locality,
          Latitude: Utils.ConvertToFloat(lat),
          Longitude: Utils.ConvertToFloat(lng),
        };
      } else {
        setIsLoading(false);
        setMsgOpen(true);
        setMsgText("Invalid address");
        return;
      }
    }
    var currentAddress = "";
    var currentHomeLocation = "";
    var currentLocality = "";
    var currentCountry = "";
    var currentState = "";
    var currentCounty = "";
    var currentZipCode = "";
    var currentLng = 0;
    var currentLat = 0;

    if (isOpenedCustomAddress) {
      currentHomeLocation = homeLocation;
      currentAddress = customerCustomAddress;
      currentCountry = getBranch().country;
      currentLocality = customerCity;
      currentState = customerState;
      currentZipCode = customerPincode;
    } else {
      currentAddress = addressDetails.Address;
      currentHomeLocation = addressDetails.HomeLocation;
      currentState = addressDetails.State;
      currentZipCode = addressDetails.ZipCode;
      currentCountry = addressDetails.Country;
      currentCounty = addressDetails.County;
      currentLocality = addressDetails.Locality;
      currentLat = addressDetails.Latitude;
      currentLng = addressDetails.Longitude;
    }

    var newCustomer = {};
    if (Utils.IsNullOrEmptyObject(selectedCustomer)) {
      newCustomer = {
        Id: customerId,
        CustomerKey: customerKey,
        FirstName: customerFirstName,
        LastName: customerLastName,
        MobileNumber: customerMobileNumber,
        EmailId: customerEmailId,
        UsedCustomAddress: isOpenedCustomAddress,
        Address: currentAddress,
        CountryCode: countryInfo.Code,
        City: currentLocality,
        State: currentState,
        ZipCode: currentZipCode,
        Locality: currentLocality,
        Country: currentCountry,
        County: currentCounty,
        HomeLocation: currentHomeLocation,
        CompanyName: customerBusinessName,
        RegistrationId: customerBusinessGSTIN,
        StateCode: customerGSTINCode,
        Latitude: Utils.ConvertToFloat(currentLat),
        Longitude: Utils.ConvertToFloat(currentLng),
        EnterpriseId: getEnterpriseId(),
        Dob: Utils.GetISODateTimeString(customerDOB),
        Gender: customerGender,
      };
    } else {
      selectedCustomer.customerKey = customerKey;
      selectedCustomer.firstName = customerFirstName;
      selectedCustomer.lastName = customerLastName;
      selectedCustomer.mobileNumber = customerMobileNumber;
      selectedCustomer.emailId = customerEmailId;
      selectedCustomer.address = currentAddress;
      selectedCustomer.homeLocation = currentHomeLocation;
      selectedCustomer.usedCustomAddress = isOpenedCustomAddress;
      selectedCustomer.city = currentLocality;
      selectedCustomer.locality = currentLocality;
      selectedCustomer.state = currentState;
      selectedCustomer.zipCode = currentZipCode;
      selectedCustomer.country = currentCountry;
      selectedCustomer.county = currentCounty;
      selectedCustomer.latitude = currentLat;
      selectedCustomer.longitude = currentLng;
      selectedCustomer.companyName = customerBusinessName;
      selectedCustomer.registrationId = customerBusinessGSTIN;
      selectedCustomer.stateCode = customerGSTINCode;
      selectedCustomer.dob = Utils.GetISODateTimeString(customerDOB);
      selectedCustomer.gender = customerGender;
      newCustomer = selectedCustomer;
    }
    var request = {
      BusinessId: business.id,
      Customer: newCustomer,
      VehicleDetails: customerVehicleList,
      PointsEarnRatio: tier.pointsEarnRatio,
      PointsRedemptionRatio: tier.pointsRedemptionRatio,
      SearchText: searchText,
      TierLevel: tier.level,
      TierName: tier.name,
      TierCardColor: tier.cardColor,
    };
    let result = await postAsync("Customer/Save", request);
    setIsNavigateToLogin(result.isNavigateToLogin);
    if (result.error) {
      setIsLoading(false);
      setMsgOpen(true);
      setMsgText(result.errorMessage);
      return;
    }
    setIsLoading(false);
    if (result.data.ifCustomerExists == false) {
      if (isAutomobileBusiness) {
        handleAddCustomer(result.data.passes.find(
          (c) => c.customerId === result.data.customerId
        ))
        setSplitScreen(false);
        setOpenAddNewCustomer(false);
        setOpenCustomerSelection(false);
        closeCustomerSetup();
      } else {
        setMsgText("Customer details saved successfully.");
        setMsgOpen(true);
        closeCustomerSetup();
        if (!Utils.IsNullOrEmptyArray(result?.data?.passes)) {
          let savedCustomerDetails = result.data.passes.filter(
            (pass) => pass.customerId === result.data.customerId
          );
          handleAddCustomer(savedCustomerDetails[0]);
        }
      }
    } else if (result.data.ifCustomerExists == true) {
      setMsgText("Customer exists already.");
      setMsgOpen(true);
      return;
    } else {
      setMsgText("Sorry! Please try again.");
      setMsgOpen(true);
    }
  };

  const closeCustomerSetup = () => {
    setCustomerId("");
    setCustomerKey("");
    setCustomerFirstName("");
    setCustomerLastName("");
    setCustomerMobileNumber("");
    setCustomerEmailId("");
    setCustomerAddress("");
    setUseCustomAddress(true);
    setCustomerCustomAddress("");
    setCustomerCity("");
    setCustomerState("");
    setCustomerPincode("");
    setCustomerDOB(moment("1973-01-01T00:00:00"));
    setCustomerGender("");
    setCustomerGSTINCode("");
    setCustomerBusinessGSTIN("");
    setCustomerBusinessName("");
    setCustomerVehicleList([]);
    setSelectedPass({});
    setSelectedCustomer({});
  };

  const handleCloseCustomer = () => {
    setSplitScreen(false);
    setOpenCustomerSelection(false);
    setSearchText("");
    setCustomerList([]);
  };

  const handleCloseNewCustomer = () => {
    // setSplitScreen(false);
    setOpenCustomerSelection(true);
    setOpenAddNewCustomer(false);
  };
  const handleCloseVehiclesDetails = () => {
    setAddVehicle(false);
    setOpenAddNewCustomer(true);
  }

  const handleOpenAddNewCustomer = () => {
    setOpenAddNewCustomer(true);
    setSplitScreen(true);
    setOpenCustomerSelection(false);
  };



  // Vehicles Details

  const makeHandleChange = (event) => {
    setVehicleMake(event.target.value);
  };

  const modelHandleChange = (event) => {
    setVehicleModel(event.target.value);
  };

  const fuelHandleChange = (event) => {
    setVehicleFuelType(event.target.value);
  };

  const vehicleMakeHandleChange = (e) => {
    var make = e.target.value;
    var models = makeModelList.find((c) => c.make === make).model;
    setVehicleMake(make);
    setModelList(models);
  };

  const vehicleEmirate = (e) => {
    var emirate = e.target.value;
    if (NotApplicable === emirate) {
      Utils.IsNullOrEmpty(setPlateCodeDisabled(true));
      setSelectedEmirate(emirate);
      setPlateCode("");
    } else {
      setSelectedEmirate(emirate);
      setPlateCodeDisabled();
    }
  };
  const vehicleMakeHandleChangeEdit = (e) => {
    var modelObj = makeModelList.find((c) => c.make === e);
    if (Utils.IsNullOrEmptyObject(modelObj)) {
      setOpenCustomMakeModel(true);
      return;
    }
    setModelList(modelObj.model);
  };

  function toggleCustomMakeModel(event) {
    if ((event === true)) {
      setOpenCustomMakeModel(true);
      setVehicleMake("");
      setVehicleModel("");
    } else {
      setOpenCustomMakeModel(false);
      setVehicleMake("");
      setVehicleModel("");
      setModelList([]);
    }
  }

  const deleteVehicleDetail = (index) => {
    setCustomerAction(customerActionTypes.DeleteVehicle);
    setSelectedVehicle(customerVehicleList[index]);
    setConfirmText("Do you wish to delete?");
    setConfirmOpen(true);
  };

  const processPhotoDeletion = async (isRegistraion) => {
    var imageUrl = isRegistraion ? rcImage : insuranceImage;
    if (Utils.IsNullOrEmpty(imageUrl)) {
      return;
    }
    setIsLoading(true);
    let result = await postAsync("Customer/RemoveVehicleDocuments", {
      ImageUrl: imageUrl,
    });
    setIsLoading(false);
    setIsNavigateToLogin(result.isNavigateToLogin);
    if (result.error) {
      setMsgOpen(true);
      setMsgText(result.errorMessage);
      return;
    }
    if (result.data.isDeleted) {
      if (isRegistraion) {
        setRCImage("");
      } else {
        setInsuranceImage("");
      }
      setCustomerAction("");
    }
  };

  const deletePhoto = (isRegistraion) => {
    setCustomerAction(
      isRegistraion
        ? customerActionTypes.DeleteRCImage
        : customerActionTypes.DeleteInsuranceImage
    );
    setConfirmText("Deleted images cannot be restored. Are you sure?");
    setConfirmOpen(true);
  };

  const UploadReturnImage = async (event, isRegistraion) => {
    var file = event.target.files[0];
    if (file.size > MaxFileSizeLimit) {
      setAlertTitle("Warning");
      setMsgText(
        "Unable to upload. File size exceeds the maximum limit, please ensure that it's less than 10 MB."
      );
      setMsgOpen(true);
      return;
    }
    var base64 = await convertBase64(file);
    setIsLoading(true);
    let response = await postAsync("Customer/UploadVehicleDocuments", {
      DocumentImageData: Utils.GetStringFromBase64(base64),
      IsRegistraion: isRegistraion,
      VehicleNumber: vehicleNumber,
    }, "", "", "", [], true);
    setIsLoading(false);
    setIsNavigateToLogin(response.isNavigateToLogin);
    if (response.error) {
      setMsgOpen(true);
      setMsgText(response.errorMessage);
      return;
    } else {
      if (isRegistraion == true) {
        setRCImage(response.data.documentImageUrl);
      } else {
        setInsuranceImage(response.data.documentImageUrl);
      }
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const openVehicleSetup = (vehicle = {}) => {
    if (saveCustomerDisabled) {
      setMsgText("Please fill in mandatory details before proceeding");
      setMsgOpen(true);
      return;
    }
    setEmiratetype(AutoMobileHelper.getEmirate());
    var fuelTypes = AutoMobileHelper.GetFuelTypes();
    setVehicleFuelTypes(fuelTypes);
    setModalTitle("New Vehicle");
    if (!Utils.IsNullOrEmptyObject(vehicle)) {
      setSelectedVehicle(vehicle);
      setIsVehicleEdit(true);
      setModalTitle("Update Vehicle " + vehicle.number);
      setVehicleNumber(vehicle.number);
      setPlateCode(vehicle.plateCode);
      setSelectedEmirate(vehicle.emirate);
      setVehicleMake(vehicle.make);
      setVehicleModel(vehicle.model);
      setVehicleColor(vehicle.color);
      setVehicleType(vehicle.vehicleType);
      setVehicleChassisNumber(vehicle.chassisNumber);
      setVehicleEngineNumber(vehicle.engineNumber);
      setVehicleFuelType(vehicle.fuelType);
      setVehicleYear(vehicle.year);
      vehicleMakeHandleChangeEdit(vehicle.make);
      setInsuranceImage(vehicle.vehicleInsuranceDetails.insuranceData);
      setRCImage(vehicle.registrationCertificateData);
      setVehicleInsCompany(vehicle.vehicleInsuranceDetails.company);
      setInsuranceGSTIN(vehicle.vehicleInsuranceDetails.gstin);
      setVehicleInsPolicyNumber(vehicle.vehicleInsuranceDetails.policyNumber);
      setVehicleInsPlaceOfIssue(vehicle.vehicleInsuranceDetails.placeOfIssue);
      setVehicleInsExpiryDate(
        moment(vehicle.vehicleInsuranceDetails.expiryDate)
      );
      setInsuranceBranchAddress(vehicle.vehicleInsuranceDetails.branchAddress);
    } else {
      setModelList([])
    }

    setAddVehicle(true);
    setOpenAddNewCustomer(false);
  };

  useEffect(() => {
    checkVehicleDetails();
  }, [
    vehicleNumber,
    plateCode,
    selectedEmirate,
    vehicleMake,
    vehicleModel,
    vehicleFuelType,
    vehicleChassisNumber,
    vehicleEngineNumber,
    insuranceGSTIN,
  ]);

  const checkVehicleDetails = () => {
    if (isUAEBusiness === true) {
      var isDetailsFilled =
        !Utils.IsNullOrEmpty(selectedEmirate) &&
        (selectedEmirate === NotApplicable ||
          !Utils.IsNullOrEmpty(plateCode)) &&
        !Utils.IsNullOrEmpty(vehicleNumber) &&
        !Utils.IsNullOrEmpty(vehicleMake) &&
        !Utils.IsNullOrEmpty(vehicleModel) &&
        !Utils.IsNullOrEmpty(vehicleFuelType) &&
        (Utils.IsNullOrEmpty(vehicleChassisNumber) ||
          validator.isAlphanumeric(vehicleChassisNumber)) &&
        (Utils.IsNullOrEmpty(vehicleEngineNumber) ||
          validator.isAlphanumeric(vehicleEngineNumber)) &&
        (Utils.IsNullOrEmpty(insuranceGSTIN) || (insuranceGSTIN.length === 15));
    } else {
      var isDetailsFilled =
        !Utils.IsNullOrEmpty(vehicleNumber) &&
        !Utils.IsNullOrEmpty(vehicleMake) &&
        !Utils.IsNullOrEmpty(vehicleModel) &&
        !Utils.IsNullOrEmpty(vehicleFuelType) &&
        (Utils.IsNullOrEmpty(vehicleChassisNumber) ||
          validator.isAlphanumeric(vehicleChassisNumber)) &&
        (Utils.IsNullOrEmpty(vehicleEngineNumber) ||
          validator.isAlphanumeric(vehicleEngineNumber)) &&
        (Utils.IsNullOrEmpty(insuranceGSTIN) || Regexes.GST.test(insuranceGSTIN));
    }
    setSaveVehiclesDisabled(!isDetailsFilled);
  };

  const navigateToCheckoutWithVehicle = () => {
    //   var validVehicles = [];
    //   if (!Utils.IsNullOrEmptyArray(selectedPass.vehicleDetails)) {
    //     validVehicles = selectedPass.vehicleDetails.filter(
    //       (v) => v.vehicleType === getBranch().vehicleType
    //     );
    //   }
    //   if (Utils.IsNullOrEmptyArray(validVehicles)) {
    //     setMsgText("Please add vehicle before proceeding");
    //     setMsgOpen(true);
    //     return;
    //   }
    //   if (validVehicles.length === 1) {
    //     navigate(CheckOutPage.Path, {
    //       state: {
    //         checkout: true,
    //         pass: selectedPass,
    //         navigationStack: [CustomersPage.Path],
    //         to: CheckOutPage.Path,
    //         onSuccess: CustomersPage.Path,
    //         fromJobcard: location.state && location.state.fromJobcard,
    //         fromVisit: false,
    //         vehicleDetails: validVehicles[0],
    //         validVehiclesCount: validVehicles.length,
    //         searchText: searchText,
    //       },
    //     });
    //     return;
    //   }
    //   navigate(VehiclesPage.Path, {
    //     state: {
    //       checkout: true,
    //       pass: selectedPass,
    //       navigationStack: [CustomersPage.Path],
    //       to: CheckOutPage.Path,
    //       onSuccess: CustomersPage.Path,
    //       fromJobcard: location.state && location.state.fromJobcard,
    //       fromVisit: false,
    //       validVehiclesCount: validVehicles.length,
    //       searchText: searchText,
    //     },
    //   });
  };

  // const onClosePickVehicleConfirmation = (confirmResult) => {
  //   setShowConfirm(false);
  //   switch (confirmResult) {
  //     case ConfirmTypes.YES:
  //       navigateToCheckoutWithVehicle();
  //       break;
  //     case ConfirmTypes.NO:
  //       navigate(CheckOutPage.Path, {
  //         state: {
  //           checkout: true,
  //           navigationStack: [CustomersPage.Path],
  //           onSuccess: CustomersPage.Path,
  //           pass: selectedPass,
  //           fromJobcard: location.state && location.state.fromJobcard,
  //           searchText: searchText,
  //         },
  //       });
  //       break;
  //     case ConfirmTypes.CANCEL:
  //       setSelectedPass({});
  //       return;
  //   }
  // };

  const handleEnlargeImage = (isReg) => {
    setImageUrl(isReg ? rcImage : insuranceImage);
    setEnlargeImage(true);
  };

  const closeImage = () => {
    setImageUrl("");
    setEnlargeImage(false);
  };


  const vehicleSetupSubmit = () => {
    var year = Utils.ConvertToInteger(vehicleYear);
    var currentYear = moment().year();
    var nextYear = currentYear + 1
    if (isUAEBusiness) {
      if (!Utils.IsNullOrEmpty(vehicleYear)) {
        if (vehicleYear.toString().length !== 4 || year > nextYear) {
          let msg = year > nextYear ? "Please enter the current or next year." : "Please enter a valid year.";
          setMsgText(msg);
          setMsgOpen(true);
          return;
        }
      }
    } else {
      if (
        !Utils.IsNullOrEmpty(vehicleYear) &&
        (vehicleYear.toString().length !== 4) || (year > currentYear)
      ) {
        var msg =
          year > currentYear
            ? "Please enter the current or a past year."
            : "Please enter a valid year.";
        setMsgText(msg);
        setMsgOpen(true);
        return;
      }
    }
    setConfirmText("Save vehicle?");
    setConfirmOpen(true);
    setCustomerAction(customerActionTypes.SaveVehicle);
  };


  const proceedSaveVehicle = () => {
    var vehicleList = [...customerVehicleList];
    var year = Utils.ConvertToInteger(vehicleYear);
    var vId = 0;
    if (Utils.IsNullOrEmptyArray(vehicleList)) {
      vId = 1;
    } else {
      var vIds = vehicleList.map((i) => i.id);
      var reversed = vIds.reverse();
      vId = reversed[reversed.length - 1] + 1;
    }
    var vehicleDetailsObj = {
      id: Utils.IsNullOrEmptyObject(selectedVehicle) ? vId : selectedVehicle.id,
      vehicleType: Utils.IsNullOrEmptyObject(selectedVehicle)
        ? localSettings.branch.vehicleType
        : selectedVehicle.vehicleType,
      number: Utils.ConvertToUpperCase(vehicleNumber.trim()),
      make: vehicleMake,
      model: vehicleModel,
      year: year,
      color: vehicleColor,
      plateCode: plateCode,
      emirate: selectedEmirate,
      fuelType: vehicleFuelType,
      chassisNumber: vehicleChassisNumber,
      engineNumber: vehicleEngineNumber,
      registrationCertificateData: rcImage,
      vehicleInsuranceDetails: {
        policyNumber: vehicleInsPolicyNumber,
        placeOfIssue: vehicleInsPlaceOfIssue,
        expiryDate: Utils.GetISODateTimeString(vehicleInsExpiryDate),
        company: vehicleInsCompany,
        insuranceData: insuranceImage,
        gstin: insuranceGSTIN,
        branchAddress: insuranceBranchAddress,
      },
    };

    if (!Utils.IsNullOrEmptyObject(selectedVehicle)) {
      var indexToRemove = vehicleList.findIndex(
        (i) => i.id === selectedVehicle.id
      );
      vehicleList.splice(indexToRemove, 1);
    }
    vehicleList.push(vehicleDetailsObj);
    setCustomerVehicleList(vehicleList);
    closeVehicleSetup();
  };

  const closeVehicleSetup = () => {
    setVehicleNumber("");
    setVehicleYear("");
    setPlateCode("");
    setSelectedEmirate("");
    setVehicleColor("");
    setVehicleChassisNumber("");
    setVehicleEngineNumber("");
    setVehicleInsPolicyNumber("");
    setVehicleInsPlaceOfIssue("");
    setVehicleInsExpiryDate(moment());
    setVehicleInsCompany("");
    setVehicleMake("");
    setVehicleModel("");
    setOpenCustomMakeModel("");
    setVehicleType("");
    setVehicleFuelType("Petrol");
    setRCImage("");
    setInsuranceImage("");
    setInsuranceBranchAddress("");
    setInsuranceGSTIN("");
    setSelectedVehicle({});
    setIsVehicleEdit(false);
    setAddVehicle(false);
    setOpenAddNewCustomer(true);
  };



  const handleConfirmClose = async (value) => {
    setConfirmOpen(false);
    switch (value) {
      case ConfirmTypes.OK:
        switch (customerAction) {
          case customerActionTypes.DeleteVehicle:
            if (!Utils.IsNullOrEmptyObject(selectedVehicle)) {
              var vList = [...customerVehicleList];
              var filteredList = vList.filter(
                (c) => c.id !== selectedVehicle.id
              );
              setCustomerVehicleList(filteredList);
            }
            break;
          case customerActionTypes.DeleteCustomer:
            //await customerFormDelete();
            await customerSearchSubmit();
            break;
          case customerActionTypes.SaveCustomer:
            await proceedSaveCustomer();
            break;
          case customerActionTypes.SaveVehicle:
            proceedSaveVehicle();
            break;
          case customerActionTypes.DeleteInsuranceImage:
            await processPhotoDeletion(false);
            break;
          case customerActionTypes.DeleteRCImage:
            await processPhotoDeletion(true);
            break;
        }
        break;
      default:
        if (customerAction === customerActionTypes.RaiseJobcardOnCustomerSave) {
          closeCustomerSetup();
          await customerSearchSubmit();
          setCustomerAction("");
        }
        break;
    }
  };
  const handleCloseSelectedCustomer = () => {
    setCheckOutSelectedCustomer("");
    location.state.pass = null
  }
  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <PromptDialog
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <ImageEnlarge
          open={enlargeImage}
          imgSrc={imageUrl}
          onClose={closeImage}
        />
        <MessagePopup
          msgTitle={alertTitle}
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
        />
        <MessagePopup
          msgOpen={showAlert}
          msgText={alertMessage}
          onMsgClose={() => setShowAlert(false)}
        />
        <Grid container m={0}>
          <SideBarMenuItems
            selectedTab={
              !Utils.IsNullOrEmptyObject(location.state) &&
                !Utils.IsNullOrEmptyObject(location.state.pass)
                ? SideBarMenuNames.Customers
                : SideBarMenuNames.CheckOut
            }
          />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <Grid flex="1" spacing={2}>
                <Grid container className="title-sec">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title text-center"
                  >
                    {!Utils.IsNullOrEmptyObject(location.state) &&
                      !Utils.IsNullOrEmptyObject(location.state.pass) && (
                        <Button onClick={backNavigation} className="back-btn">
                          <NavigateBeforeIcon />
                        </Button>
                      )}
                    {BusinessHelper.IsSalon() ? "Billing" : "Check-Out"}
                  </Typography>
                  {!Utils.IsNullOrEmptyArray(checkOutSelectedCustomer) && (
                    <Card
                      sx={{ padding: "1px 40px 1px 10px", cursor: "pointer", alignSelf: "center", position: "relative", overflow: "unset" }}>
                      <Stack onClick={() => {
                        setOpenCustomerSelection(true)
                        setSplitScreen(true);
                      }}>
                        <Typography variant="h5" fontWeight="600">
                          <PersonIcon /> &nbsp;
                          {checkOutSelectedCustomer.userName}
                        </Typography>

                        <Typography variant="span" fontWeight="500">
                          <PhoneAndroidIcon /> &nbsp;
                          {checkOutSelectedCustomer.mobileNumber}
                        </Typography>
                      </Stack>
                      <IconButton title="Remove Customer" sx={{ position: "absolute", top: 0, right: 0, color: "#f00" }} onClick={handleCloseSelectedCustomer}>
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </Card>
                  )}
                  <Stack alignSelf="center" flexDirection="row">
                    <Button
                      className="btn btn-primary float-btn"
                      variant="contained"
                      onClick={() => {
                        setOpenCustomerSelection(true)
                        setSplitScreen(true);
                      }}
                    >
                      <PersonAddIcon
                        fontSize="large"
                      />
                      <span className="hidden-xs ml-h">Add Customer</span>
                    </Button>
                  </Stack>
                </Grid>
                <Grid
                  spacing={1}
                  className="mt-1 checkout-customer"
                >
                  <Box
                    className="card white-bg p-2 w-xs-100"
                  >
                    <Grid
                      xs={12}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Grid container direction="column" className="" paddingBottom="140px">
                        <Box style={{ width: isSplit ? "75%" : "100%" }}>
                          <CartComponent
                            serviceItems={filteredServiceItems}
                            onAddItem={addItemToCart}
                            handleItemAction={handleServiceItemActions}
                            includePreview={true}
                            onChangeTotal={setTotalPrice}
                            customerId={
                              !Utils.IsNullOrEmptyObject(location.state) &&
                                !Utils.IsNullOrEmptyObject(location.state.pass)
                                ? location.state.pass.customerId
                                : ""
                            }
                            vehicle={
                              !Utils.IsNullOrEmptyObject(location.state) &&
                                !Utils.IsNullOrEmptyObject(
                                  location.state.vehicleDetails
                                )
                                ? location.state.vehicleDetails
                                : null
                            }
                            visitType={VisitTypeEnum.Checkout}
                            isCustomProductEntry={setIsSplit}
                          />

                          <Stack className="text-right px-1">
                            <Typography
                              level="h4"
                              component="h4"
                              className="my-1"
                              fontWeight="600"
                              fontSize="20px"
                            >
                              <small>Total:</small> &nbsp;
                              {Utils.GetCurrency(totalPrice, countryInfo)}
                            </Typography>
                          </Stack>

                          <Grid flex="1" spacing={2} className="mt-2 fixed-btn">
                            <Grid className="container">
                              <Grid container xs={12}>
                                <Grid xs={1} alignSelf="center"></Grid>
                                <Grid xs={9} className=""></Grid>
                                <Grid xs={12} lg={0} className="text-right" sx={{ display: "flex", justifyContent: "end" }}>
                                  {UserHelper.CanBilling() && (
                                    <Button
                                      className="btn btn-primary align-self-start"
                                      variant="contained"
                                      onClick={clickOpenPayment}
                                    >
                                      Proceed to payment
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            className="mt-2 freq-wrap"
                            style={{ display: "none" }}
                          >
                            <Typography
                              level="h4"
                              className="mt-1"
                              component="h4"
                              fontWeight="600"
                              fontSize="16px"
                            >
                              Frequently used items:
                            </Typography>
                            <Stack
                              direction="row"
                              spacing={3}
                              className="mt-1 freq-items-sec"
                            >
                              {frequentlyUsedProducts.map((product) => (
                                <Grid
                                  item
                                  xs
                                  onClick={() => addFrequentlyUsedItem(product)}
                                >
                                  <Card sx={{ minWidth: 150 }}>
                                    <CardContent style={{ padding: "5px 10px" }}>
                                      <Typography
                                        sx={{ fontSize: 14 }}
                                        fontWeight="600"
                                        gutterBottom
                                      >
                                        {product.shortDesc}
                                      </Typography>
                                      <Typography
                                        variant="p"
                                        component="div"
                                        margin={0}
                                      >
                                        {Utils.GetCurrency(
                                          product.price,
                                          countryInfo
                                        )}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              ))}
                            </Stack>
                          </Grid>
                        </Box>
                        {isSplit && (
                          <Box className="custom-prod">
                            <CustomProduct
                              open={isSplit}
                              onClose={clickCloseCustomProduct}
                              onAdd={addCustomProduct}
                            />
                          </Box>
                        )}
                        <Grid
                          className="mt-2 freq-wrap"
                          style={{ display: "none" }}
                        >
                          <Typography
                            level="h4"
                            className="mt-1"
                            component="h4"
                            fontWeight="600"
                            fontSize="16px"
                          >
                            Frequently used items:
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={3}
                            className="mt-1 freq-items-sec"
                          >
                            {frequentlyUsedProducts.map((product) => (
                              <Grid
                                item
                                xs
                                onClick={() => addFrequentlyUsedItem(product)}
                              >
                                <Card sx={{ minWidth: 150 }}>
                                  <CardContent style={{ padding: "5px 10px" }}>
                                    <Typography
                                      sx={{ fontSize: 14 }}
                                      fontWeight="600"
                                      gutterBottom
                                    >
                                      {product.shortDesc}
                                    </Typography>
                                    <Typography
                                      variant="p"
                                      component="div"
                                      margin={0}
                                    >
                                      {Utils.GetCurrency(
                                        product.price,
                                        countryInfo
                                      )}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                            ))}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>


                  </Box>
                  {splitScreen && openCustomerSelection && (
                    <Box
                      className="card white-bg p-2 fixed-xs-btn customer-sec"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          level="h3"
                          component="h3"
                          fontWeight="600"
                          fontSize="2rem"
                          className="h3"
                        >
                          Select Customer
                        </Typography>
                        <Stack direction="row" spacing={1} style={{ "align-self": "center" }}>
                          <IconButton
                            sx={{ cursor: "pointer" }}
                            onClick={handleCloseCustomer}
                            height="4rem">
                            <CloseIcon />
                          </IconButton>

                          <IconButton
                            variant="contained"
                            onClick={handleOpenAddNewCustomer}
                            title="Create New Customer"
                          >
                            <PersonAddAltOutlinedIcon style={{ "font-size": "24px" }} />
                          </IconButton>
                        </Stack>
                      </Box>
                      <Box className="mt-2">
                        <form
                          method="post"
                          className="search-form"
                          onSubmit={handleSearchSubmit}
                          autoComplete="off"
                          alignSelf="center"
                        >
                          <Stack
                            direction="row"
                            alignSelf="center"
                            spacing={1}
                            style={{ width: "100%" }}
                          >
                            <Grid flex="1">
                              <TextField
                                className="form-control font-size-14"
                                placeholder="Search customer..."
                                value={searchText}
                                onChange={(e) =>
                                  setSearchText(e.target.value)
                                }
                                style={{ "min-width": "280px" }}
                              />
                            </Grid>
                            <Grid>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                className="submitBtn btn-primary h-100"
                                fullWidth="true"
                              >
                                <SearchIcon sx={{ fontSize: 24 }} />
                              </Button>
                            </Grid>
                          </Stack>
                        </form>
                      </Box>
                      <Grid
                        Container
                        className="customers-list"
                      >
                        <Stack direction="column" spacing={2}>
                          {!Utils.IsNullOrEmptyArray(customerList) &&
                            customerList.map((customer, index) => (
                              <Card className="card"
                                sx={{
                                  cursor: "pointer",
                                  paddingBottom:
                                    index === customerList.length - 1
                                      ? "1rem"
                                      : "",
                                }}
                                onClick={() =>
                                  handleAddCustomer(customer)
                                }
                              >
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  justifyContent="space-between"
                                  alignItems="center"
                                  alignContent="center"
                                >
                                  <Stack direction="column" spacing={2}>
                                    <CardContent sx={{ flex: 1 }}>
                                      <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                      >
                                        <Box>
                                          <Typography
                                            variant="h3"
                                            fontWeight="bold"
                                          >
                                            {customer.userName}
                                          </Typography>
                                          <Typography variant="h6">
                                            {customer.mobileNumber}
                                          </Typography>
                                          <Typography variant="h6">
                                            {customer.emailId}
                                          </Typography>
                                        </Box>
                                      </Stack>
                                    </CardContent>
                                  </Stack>
                                  <IconButton sx={{ marginRight: "1rem" }}>
                                    <AddIcon
                                      sx={{ background: "greeen" }}
                                      fontSize="large"
                                      onClick={() =>
                                        handleAddCustomer(customer)
                                      }
                                    />
                                  </IconButton>
                                </Stack>
                              </Card>
                            ))}
                        </Stack>
                      </Grid>
                    </Box>
                  )}
                  {splitScreen && openAddNewCustomer && (
                    <Box
                      className="card white-bg p-2 fixed-xs-btn add-customer-modal"
                    >
                      <Stack>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            level="h3"
                            component="h3"
                            fontWeight="600"
                            fontSize="2rem"
                            className="h3"
                          >
                            Add New Customer
                          </Typography>
                          <CloseIcon
                            onClick={handleCloseNewCustomer}
                            sx={{ cursor: "pointer" }}
                            height="4rem"
                          />
                        </Box>
                        <Stack
                          pt={4}
                          sx={{ minWidth: 120, height: "calc(100vh - 121px)", overflow: "auto", marginBottom: "14px" }}
                          spacing={2}
                          className="font-size-14"
                        >
                          <FormControl fullWidth>
                            <TextField
                              id="customerKey"
                              label="Customer Key"
                              variant="outlined"
                              value={customerKey}
                              onChange={(e) => setCustomerKey(e.target.value)}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                          <Stack direction="row" spacing={2}>
                            <FormControl fullWidth>
                              <TextField
                                id="customerFirstName"
                                label="First Name"
                                variant="outlined"
                                value={customerFirstName}
                                onChange={(e) =>
                                  setCustomerFirstName(e.target.value)
                                }
                                error={!customerFirstName}
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <TextField
                                id="customerLastName"
                                label="Last Name"
                                variant="outlined"
                                value={customerLastName}
                                onChange={(e) =>
                                  setCustomerLastName(e.target.value)
                                }
                                InputLabelProps={{ shrink: true }}
                                required={CountryHelper.IsBusinessCountryUSA(
                                  getCountry()
                                )}
                              />
                            </FormControl>
                          </Stack>
                          <Stack direction="row" spacing={2}>
                            <FormControl fullWidth>
                              <NumericTextField
                                id="mobileNumber"
                                label="Mobile Number"
                                variant="outlined"
                                error={
                                  !Utils.IsMobileValid(customerMobileNumber)
                                }
                                value={customerMobileNumber}
                                setValue={setCustomerMobileNumber}
                                inputProps={{
                                  maxLength:
                                    CountryHelper.GetMobileNumberMaxLength(),
                                }}
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <TextField
                                id="emailId"
                                label="Email"
                                variant="outlined"
                                value={customerEmailId}
                                error={
                                  Utils.IsNullOrEmpty(customerEmailId)
                                    ? false
                                    : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                                      customerEmailId
                                    )
                                }
                                onChange={(e) =>
                                  setCustomerEmailId(e.target.value)
                                }
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                          </Stack>
                          <Stack className="address-wrap">
                            <Typography
                              component="label"
                              style={{ marginBottom: "10px" }}
                            >
                              Address
                            </Typography>
                            {isOpenedCustomAddress ? (
                              <Grid>
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  className="justify-content-between mt-2"
                                >
                                  <Typography
                                    level="h5"
                                    component="h5"
                                    fontWeight="600"
                                    fontSize="1.5rem"
                                    className="h5"
                                  >
                                    Use Custom Address
                                  </Typography>
                                  <Switch
                                    checked={isOpenedCustomAddress}
                                    edge="end"
                                    onClick={toggleCustomAddress}
                                  />
                                </Stack>
                                <FormControl fullWidth>
                                  <TextField
                                    id="customerCustomAddress"
                                    label="Address"
                                    variant="outlined"
                                    value={customerCustomAddress}
                                    onChange={(e) =>
                                      setCustomerCustomAddress(e.target.value)
                                    }
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </FormControl>
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  className="m-t-2"
                                >
                                  <FormControl fullWidth>
                                    <TextField
                                      id="customerCity"
                                      label="City"
                                      variant="outlined"
                                      error={!customerCity}
                                      value={customerCity}
                                      onChange={(e) =>
                                        setCustomerCity(e.target.value)
                                      }
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </FormControl>
                                  <FormControl fullWidth>
                                    <TextField
                                      id="customerState"
                                      label={
                                        CountryHelper.IsBusinessCountryUAE(
                                          getCountry()
                                        )
                                          ? "Emirate"
                                          : "State"
                                      }
                                      variant="outlined"
                                      value={customerState}
                                      onChange={(e) =>
                                        setCustomerState(e.target.value)
                                      }
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </FormControl>
                                  <FormControl fullWidth>
                                    <TextField
                                      id="customerPincode"
                                      label="Pincode"
                                      variant="outlined"
                                      value={customerPincode}
                                      onChange={(e) =>
                                        setCustomerPincode(e.target.value)
                                      }
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </FormControl>
                                </Stack>
                              </Grid>
                            ) : (
                              <>
                                {!Utils.IsNullOrEmpty(
                                  getCountry().ISOCode
                                ) && (
                                    <AddressField
                                      id="branchAddress"
                                      variant="outlined"
                                      label="Branch Address"
                                      required
                                      isValidate={true}
                                      countryISoCode={getCountry().ISOCode}
                                      value={customerAddress}
                                      setValue={setCustomerAddress}
                                    />
                                  )}
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  className="justify-content-between mt-2"
                                >
                                  <Typography
                                    level="h5"
                                    component="h5"
                                    fontWeight="600"
                                    fontSize="1.5rem"
                                    className="h5"
                                  >
                                    Use Custom Address
                                  </Typography>
                                  <Switch
                                    checked={isOpenedCustomAddress}
                                    edge="end"
                                    onClick={toggleCustomAddress}
                                  />
                                </Stack>
                              </>
                            )}
                          </Stack>

                          <Stack direction="row" spacing={2}>
                            <FormControl>
                              <LocalizationProvider
                                dateAdapter={AdapterMoment}
                              >
                                <DatePicker
                                  id="businessOpen"
                                  label="Date of Birth"
                                  value={customerDOB}
                                  onChange={(newValue) =>
                                    setCustomerDOB(newValue)
                                  }
                                  closeOnSelect={true}
                                  minDate={moment("1973-01-01T00:00:00")}
                                />
                              </LocalizationProvider>
                            </FormControl>
                            <FormControl>
                              <Typography
                                level="h3"
                                component="h3"
                                fontWeight="600"
                                fontSize="2rem"
                                className="h3"
                              >
                                Gender :
                              </Typography>
                              <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="Male"
                                name="radio-buttons-group"
                              >
                                <FormControlLabel
                                  value="Male"
                                  control={<Radio />}
                                  label="Male"
                                  onChange={(e) =>
                                    setCustomerGender(e.target.value)
                                  }
                                />
                                <FormControlLabel
                                  value="Female"
                                  control={<Radio />}
                                  label="Female"
                                  onChange={(e) =>
                                    setCustomerGender(e.target.value)
                                  }
                                />
                              </RadioGroup>
                            </FormControl>
                          </Stack>

                          <Typography
                            level="h3"
                            component="h3"
                            fontWeight="600"
                            fontSize="2rem"
                            className="h3"
                          >
                            Business Details :
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={2}
                            className="xs-column"
                          >
                            <FormControl fullWidth className="my-xs-1">
                              <TextField
                                id="customerBusinessName"
                                label="Company Name"
                                variant="outlined"
                                value={customerBusinessName}
                                onChange={(e) =>
                                  setCustomerBusinessName(e.target.value)
                                }
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                            <FormControl fullWidth className="my-xs-1">
                              <TextField
                                id="customerBusinessGSTIN"
                                label={
                                  CountryHelper.IsBusinessCountryIndia(
                                    getCountry()
                                  )
                                    ? "Company GSTIN"
                                    : CountryHelper.IsBusinessCountryUSA(
                                      getCountry()
                                    )
                                      ? "Company Tax Id"
                                      : "Tax Id"
                                }
                                variant="outlined"
                                value={customerBusinessGSTIN}
                                onChange={(e) =>
                                  setCustomerBusinessGSTIN(e.target.value)
                                }
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                            {CountryHelper.IsBusinessCountryIndia(
                              getCountry()
                            ) && (
                                <FormControl fullWidth className="my-xs-1">
                                  <TextField
                                    id="customerGSTINCode"
                                    label="GSTIN Code"
                                    variant="outlined"
                                    value={customerGSTINCode}
                                    error={Utils.IsNullOrEmpty(customerGSTINCode) ? false : !Regexes.GST.test(customerGSTINCode)}
                                    onChange={(e) =>
                                      setCustomerGSTINCode(e.target.value)
                                    }
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </FormControl>
                              )}
                          </Stack>
                          {isAutomobileBusiness && (
                            <Stack direction="column">
                              <Stack
                                direction="row"
                                spacing={2}
                                className="justify-content-between"
                              >
                                <Typography
                                  level="h3"
                                  component="h3"
                                  fontWeight="600"
                                  fontSize="2rem"
                                  className="h3"
                                >
                                  Vehicles
                                </Typography>
                                {(customerVehicleList.length <= 30 || (CountryHelper.IsBusinessCountryUAE(getCountry()) && customerVehicleList.length <= 150)) && (
                                  <Button
                                    className="btn btn-secondary refresh-icon"
                                    variant="contained"
                                    onClick={() => openVehicleSetup()}
                                    style={{ padding: "1px 10px", "margin-bottom": "4px" }}
                                  >
                                    <AddCircleIcon
                                      fontSize="Large"
                                      title="Upload files"
                                      style={{ color: "#588ce9" }}
                                    />
                                    <span className="hidden-xs ml-h">Add</span>
                                  </Button>
                                )}
                              </Stack>
                              <Divider />
                              <Grid container xs={12} className="mt-1 font-size-12">
                                {customerVehicleList.map((item, i) => (
                                  <Grid xs={12} md={6} lg={6} className="mb-1">
                                    <Stack
                                      className="card mr-1 m-xs-0"
                                      padding="10px"
                                      style={{ height: "100%" }}
                                      direction="row"
                                    >
                                      <Stack>
                                        <h3 className="mt-0">
                                          <strong className="input-label" fontWeight="600">
                                            {item.number}
                                          </strong>
                                        </h3>
                                        <span className="input-label">{item.make}</span>
                                        <span className="input-label">{item.model}</span>
                                        {item.color && (
                                          <span className="input-label">
                                            {item.color}
                                            <DirectionsCarIcon
                                              style={{
                                                color: item.color,
                                                "vertical-align": "middle",
                                              }}
                                            />
                                          </span>
                                        )}
                                        <span>
                                          {item.fuelType} &nbsp;
                                          {item.fuelType == "Petrol" && (
                                            <WaterDropIcon
                                              style={{
                                                color: "gold",
                                                "vertical-align": "middle",
                                              }}
                                            />
                                          )}
                                          {item.fuelType == "Diesel" && (
                                            <WaterDropIcon
                                              style={{
                                                color: "#cddc39",
                                                "vertical-align": "middle",
                                              }}
                                            />
                                          )}
                                          {item.fuelType == "CNG" && (
                                            <PropaneIcon
                                              style={{
                                                color: "#03a9f4",
                                                "vertical-align": "middle",
                                              }}
                                            />
                                          )}
                                          {item.fuelType == "LPG" && (
                                            <LocalGasStationIcon
                                              style={{
                                                color: "red",
                                                "vertical-align": "middle",
                                              }}
                                            />
                                          )}
                                          {item.fuelType == "Electric" && (
                                            <EvStationIcon
                                              style={{
                                                color: "green",
                                                "vertical-align": "middle",
                                              }}
                                            />
                                          )}
                                        </span>
                                      </Stack>
                                      <Grid
                                        container
                                        className="text-right"
                                        justifyContent="end"
                                      >
                                        <Stack direction="column">
                                          <Button
                                            className="btn btn-secondary"
                                            style={{ padding: "3px", "min-width": "auto" }}
                                          >
                                            <EditIcon
                                              onClick={() => openVehicleSetup(item)}
                                              className="icon-btn icon20"
                                              title="Edit"
                                            />
                                          </Button>
                                          <Button
                                            className="btn btn-secondary mt-1"
                                            style={{
                                              padding: "3px",
                                              "min-width": "auto",
                                            }}
                                          >
                                            <DeleteIcon
                                              onClick={() => deleteVehicleDetail(i)}
                                              className="icon-btn icon20"
                                              title="Delete"
                                            />
                                          </Button>
                                        </Stack>
                                      </Grid>
                                    </Stack>
                                  </Grid>
                                ))}
                              </Grid>
                            </Stack>
                          )}

                        </Stack>
                        <Stack
                          direction="row"
                        >
                          <Grid container className="container"
                            justifyContent="end"
                          >
                            <Stack direction="row" justifyContent="end" spacing={1} className="font-size-14">
                              <Button
                                style={{ background: "#FFA500" }}
                                className="btn btn-secondary"
                                variant="contained"
                                onClick={handleCloseNewCustomer}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="btn btn-primary"
                                variant="contained"
                                onClick={() => customerSaveSubmit()}
                                disabled={saveCustomerDisabled}
                              >
                                Save
                              </Button>
                            </Stack>
                          </Grid>
                        </Stack>
                      </Stack>
                    </Box>
                  )}
                  {splitScreen && addVehicle && (
                    <Box
                      className="card white-bg p-2 fixed-xs-btn add-customer-modal"
                      style={{
                        width: "40%",
                        transition: "width 0.3s ease-in-out",
                        "margin-bottom": "auto",
                      }}
                    >
                      <Stack>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            level="h3"
                            component="h3"
                            fontWeight="600"
                            fontSize="2rem"
                            className="h3"
                          >
                            Vehicles Details
                          </Typography>
                          <CloseIcon
                            onClick={handleCloseVehiclesDetails}
                            sx={{ cursor: "pointer" }}
                            height="4rem"
                          />
                        </Box>
                        <Stack
                          sx={{ minWidth: 120, height: "calc(100vh - 141px)", overflow: "auto", marginBottom: "14px" }}
                          spacing={2}
                          className="font-size-14"
                        >
                          <TextField type="hidden" value={vehicleSubmitCat} />
                          {isUAEBusiness && (
                            <>
                              <FormControl fullWidth style={{ "margin-right": "15px" }}>
                                <InputLabel id="demo-simple-select-error-label" className="dropfocused ">Emirate</InputLabel>
                                <Select
                                  id="Emirate"
                                  value={selectedEmirate}
                                  error={!selectedEmirate}
                                  label="Emirate"
                                  required
                                  variant="outlined"
                                  onChange={(e) => vehicleEmirate(e)}
                                >
                                  {!Utils.IsNullOrEmptyArray(emirateType) &&
                                    emirateType.map((data) => (
                                      <MenuItem value={data}>{data}</MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                              <FormControl fullWidth className="mt-2">
                                <TextField
                                  id="plateCode"
                                  label="Plate Code"
                                  variant="outlined"
                                  disabled={plateCodeDisabled}
                                  error={!plateCodeDisabled === true ? Utils.IsNullOrEmpty(plateCode) : false}
                                  inputProps={{ maxLength: 20 }}
                                  value={plateCode}
                                  onChange={(e) => setPlateCode(e.target.value)}
                                />
                              </FormControl>
                            </>
                          )}
                          <FormControl fullWidth className="mt-2">
                            <TextField
                              id="vehicleNumber"
                              label="Vehicle Number"
                              variant="outlined"
                              value={vehicleNumber}
                              error={!vehicleNumber}
                              inputProps={{ maxLength: 16 }}
                              onChange={(e) =>
                                setVehicleNumber(Utils.ConvertToUpperCase(e.target.value))
                              }
                            />
                          </FormControl>
                          <Stack
                            direction="row"
                            spacing={2}
                            className="mt-2"
                            style={{ width: "100%" }}
                          >
                            <FormControl>
                              <FormControlLabel
                                control={<Switch
                                  onChange={(e) =>
                                    toggleCustomMakeModel(e.target.checked)
                                  } />}
                                label="Custom Select"
                              />
                            </FormControl>
                            {openCustomMakeModel ? (
                              <Stack direction="row" style={{ width: "70%" }}>
                                <FormControl fullWidth style={{ "margin-right": "15px" }}>
                                  <TextField
                                    id="vehicleMakeSelect"
                                    label="Make"
                                    variant="outlined"
                                    error={!vehicleMake}
                                    onChange={(e) => setVehicleMake(e.target.value)}
                                    style={{ "width": "160px" }}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </FormControl>
                                <FormControl fullWidth>
                                  <TextField
                                    id="vehicleModelSelect"
                                    label="Model"
                                    variant="outlined"
                                    error={!vehicleModel}
                                    onChange={(e) => setVehicleModel(e.target.value)}
                                    style={{ "width": "160px" }}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </FormControl>
                              </Stack>
                            ) : (
                              <Stack direction="row" style={{ width: "70%" }}>
                                <FormControl fullWidth
                                  style={{ "margin-right": "15px" }}
                                >
                                  <InputLabel id="demo-simple-select-label" className="dropfocused">Make </InputLabel>
                                  <Select
                                    id="vehicleMake"
                                    value={vehicleMake}
                                    error={!vehicleMake}
                                    label="Make"
                                    variant="outlined"
                                    onChange={(e) => vehicleMakeHandleChange(e)}
                                    style={{ "width": "160px" }}
                                    InputLabelProps={{ shrink: true }}
                                  >
                                    {makeList.map((data) => (
                                      <MenuItem value={data}>{data}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label" className="dropfocused ">Model </InputLabel>
                                  <Select
                                    id="vehicleModel"
                                    required

                                    value={vehicleModel}
                                    error={!vehicleModel}
                                    label="Model"
                                    variant="outlined"
                                    onChange={modelHandleChange}
                                    style={{ "width": "160px" }}
                                    InputLabelProps={{ shrink: true }}
                                  >
                                    {modelList.map((data) => (
                                      <MenuItem value={data}>{data}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Stack>
                            )}
                          </Stack>
                          <Stack direction="row" spacing={2} className="mt-2">
                            <FormControl fullWidth>
                              <NumericTextField
                                id="vehicleYear"
                                label="Year"
                                variant="outlined"
                                value={vehicleYear}
                                setValue={setVehicleYear}
                                inputProps={{ maxLength: 4 }}
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <TextField
                                id="vehicleColor"
                                label="Color"
                                variant="outlined"
                                value={vehicleColor}
                                onChange={(e) => setVehicleColor(e.target.value)}
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Fuel Type</InputLabel>
                              <Select
                                id="jobcardFuelType"
                                label="Fuel Type"
                                variant="outlined"
                                onChange={fuelHandleChange}
                                value={vehicleFuelType}
                              >
                                {!Utils.IsNullOrEmptyArray(vehicleFuelTypes) &&
                                  vehicleFuelTypes.map((item, index) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Stack>
                          <Stack direction="row" spacing={2} className="mt-2">
                            <FormControl fullWidth>
                              <TextField
                                id="vehicleChassisNumber"
                                label="Chassis Number"
                                variant="outlined"
                                value={vehicleChassisNumber}
                                onChange={(e) => setVehicleChassisNumber(e.target.value)}
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <TextField
                                id="vehicleEngineNumber"
                                label="Engine Number"
                                variant="outlined"
                                value={vehicleEngineNumber}
                                onChange={(e) => setVehicleEngineNumber(e.target.value)}
                              />
                            </FormControl>
                          </Stack>
                          <Typography
                            level="h3"
                            component="h3"
                            fontWeight="600"
                            fontSize="1.3rem"
                            className="h3 mt-2"
                          >
                            Insurance Details
                          </Typography>
                          <Stack direction="row" spacing={2} className="mt-2">
                            <FormControl fullWidth>
                              <TextField
                                id="vehicleInsPolicyNumber"
                                label="Policy Number"
                                variant="outlined"
                                value={vehicleInsPolicyNumber}
                                onChange={(e) => setVehicleInsPolicyNumber(e.target.value)}
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <TextField
                                id="vehicleInsPlaceOfIssue"
                                label="Place of issue"
                                variant="outlined"
                                value={vehicleInsPlaceOfIssue}
                                onChange={(e) => setVehicleInsPlaceOfIssue(e.target.value)}
                              />
                            </FormControl>
                          </Stack>
                          <Stack direction="row" spacing={2} className="mt-2">
                            <FormControl fullWidth>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  id="businessOpen"
                                  label="Expiry Date"
                                  value={vehicleInsExpiryDate}
                                  onChange={(newValue) => setVehicleInsExpiryDate(newValue)}
                                  closeOnSelect={true}
                                />
                              </LocalizationProvider>
                            </FormControl>
                            <FormControl fullWidth>
                              <TextField
                                id="vehicleInsCompany"
                                label="Company"
                                variant="outlined"
                                value={vehicleInsCompany}
                                onChange={(e) => setVehicleInsCompany(e.target.value)}
                              />
                            </FormControl>
                          </Stack>
                          <Stack direction="row" spacing={2} className="mt-2">
                            {(isIndianBusiness) ?
                              (<FormControl fullWidth>
                                <TextField
                                  id="vehicleInsCompany"
                                  label="Insurance GSTIN"
                                  variant="outlined"
                                  value={insuranceGSTIN}
                                  inputProps={{ maxLength: 15 }}
                                  error={!Utils.IsNullOrEmpty(insuranceGSTIN) && !Regexes.GST.test(insuranceGSTIN)}
                                  onChange={(e) => setInsuranceGSTIN(e.target.value.toUpperCase())}
                                />
                              </FormControl>) : (
                                <FormControl fullWidth>
                                  <TextField
                                    id="vehicleInsCompany"
                                    label={isUAEBusiness ? "Insurance TRN No" : "Insurance Tax ID"}
                                    variant="outlined"
                                    value={insuranceGSTIN}
                                    error={Utils.IsNullOrEmpty(insuranceGSTIN) ? false : insuranceGSTIN.length !== 15}
                                    inputProps={{ maxLength: 15 }}
                                    onChange={(e) => setInsuranceGSTIN(e.target.value)}
                                  />
                                </FormControl>
                              )}
                            <FormControl fullWidth>
                              <TextField
                                id="vehicleInsCompany"
                                label="Insurance Branch Address"
                                variant="outlined"
                                value={insuranceBranchAddress}
                                onChange={(e) => setInsuranceBranchAddress(e.target.value)}
                              />
                            </FormControl>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={2}
                            className="justify-content-between mt-2"
                          >
                            <Typography
                              level="h3"
                              component="h3"
                              fontWeight="600"
                              fontSize="2rem"
                              className="h3"
                            >
                              Registration Certificate
                            </Typography>
                            {Utils.IsNullOrEmpty(rcImage) && (
                              <Stack direction="row" spacing={2}>
                                <label>
                                  <AddAPhotoIcon
                                    className="icon-btn icon20"
                                    title="Upload files"
                                  />
                                  <Input
                                    type="file"
                                    name="myImage"
                                    className="hide"
                                    inputProps={{
                                      accept: "image/png, image/jpeg, image/ico, image/jpg",
                                    }}
                                    disabled={saveVehiclesDisabled}
                                    onChange={(e) => UploadReturnImage(e, true)}
                                  />
                                </label>
                              </Stack>
                            )}
                          </Stack>
                          <Divider />
                          <Grid>
                            {!Utils.IsNullOrEmpty(rcImage) && (
                              <div className="card imgBox">
                                <img
                                  alt="not found"
                                  width={"150px"}
                                  src={rcImage}
                                  onClick={() => handleEnlargeImage(true)}
                                />
                                <br />
                                <button
                                  className="p-0 mt-1 btn btn-default"
                                  onClick={() => deletePhoto(true)}
                                >
                                  <DeleteIcon />
                                </button>
                              </div>
                            )}
                          </Grid>
                          <Stack
                            direction="row"
                            spacing={2}
                            className="justify-content-between mt-2"
                          >
                            <Typography
                              level="h3"
                              component="h3"
                              fontWeight="600"
                              fontSize="2rem"
                              className="h3"
                            >
                              Insurance
                            </Typography>
                            {Utils.IsNullOrEmpty(insuranceImage) && (
                              <Stack direction="row" spacing={2}>
                                <label>
                                  <AddAPhotoIcon
                                    className="icon-btn icon20"
                                    title="Upload files"
                                  />
                                  <Input
                                    type="file"
                                    disabled={saveVehiclesDisabled}
                                    name="myImage"
                                    className="hide"
                                    inputProps={{
                                      accept: "image/png, image/jpeg, image/ico, image/jpg",
                                    }}
                                    onChange={(event) => UploadReturnImage(event, false)}
                                  />
                                </label>
                              </Stack>
                            )}
                          </Stack>
                          <Divider />
                          <Grid>
                            {!Utils.IsNullOrEmpty(insuranceImage) && (
                              <div className="card imgBox">
                                <img
                                  alt="not found"
                                  width={"150px"}
                                  src={insuranceImage}
                                  onClick={() => handleEnlargeImage(false)}
                                />
                                <br />
                                <button
                                  className="p-0 mt-1 btn btn-default"
                                  onClick={() => deletePhoto(false)}
                                >
                                  <DeleteIcon className="icon20" />
                                </button>
                              </div>
                            )}
                          </Grid>
                        </Stack>
                        <Stack
                          direction="row"
                        >
                          <Grid container className="container"
                            justifyContent="end"
                          >
                            <Stack direction="row" justifyContent="end" spacing={1} className="font-size-14">
                              <FormControl>
                                <Button
                                  type="submit"
                                  onClick={closeVehicleSetup}
                                  variant="contained"
                                  className="btn btn-secondary"
                                >
                                  Cancel
                                </Button>
                              </FormControl>
                              {isVehicleEdit ? (
                                <FormControl>
                                  <Button
                                    type="submit"
                                    onClick={vehicleSetupSubmit}
                                    variant="contained"
                                    disabled={saveVehiclesDisabled}
                                    className="btn btn-primary"
                                  >
                                    Update
                                  </Button>
                                </FormControl>
                              ) : (
                                <FormControl>
                                  <Button
                                    type="submit"
                                    onClick={vehicleSetupSubmit}
                                    variant="contained"
                                    disabled={saveVehiclesDisabled}
                                    className="btn btn-primary"
                                  >
                                    Add
                                  </Button>
                                </FormControl>
                              )}
                            </Stack>
                          </Grid>
                        </Stack>
                      </Stack>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default CheckOut;
