import React, { useRef, useEffect, useState } from "react";
import AddProduct from "./AddProduct";
import Utils from "../../core/Utils";
import { Box, Typography, Grid, Stack, Tooltip } from "@mui/material";
import { serviceItemActionTypes } from "../reducers/serviceItem/serviceItemActionTypes";
import MessagePopup from "./MessagePopUp";
import { useDispatch } from "react-redux";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import UserHelper from "../../core/UserHelper";
import { LoginPage } from "../../core/PageConstants";
import ProductItem from "./ProductItem";
import { loadProductsFromServer } from "../../core/serviceClient";

const ProductConfigComponent = ({
    serviceItems,
    onAddItem,
    handleItemAction,
    fromServiceSetup = false,
    isFromAddNewPackage = false,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [productsLoading, setProductsLoading] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
    const dummy = useRef(null);

    useEffect(() => {
        loadProducts();
    }, []);

    const loadProducts = async () => {
        setProductsLoading(true);
        let result = await loadProductsFromServer();
        var products = [];
        var data = result.data;
        if (!Utils.IsNullOrEmptyArray(data)) {
            if (fromServiceSetup === true) {
                data = data.filter(
                    (c) =>
                        c.service === false && c.isMaterial === false && c.labour === false
                );
            }
            if (!Utils.IsNullOrEmptyArray(data)) {
                data = data.filter(
                    (c) =>
                        c.active === true
                );
            }
            data.forEach((item) => {
                var stringItem = JSON.stringify(item);
                products.push(stringItem);
            });
        }
        setAllProducts(products);
        setProductsLoading(false);
        return data;
    };


    const add = (item) => {
        onAddItem(item, false);
    };

    const reversedIndexOf = (ind) => {
        var length = serviceItems.length;
        if (ind === -1) {
            return -1;
        }
        return length - ind - 1;
    };

    const handleAction = (action) => {
        if (action.type === serviceItemActionTypes.Delete) {
            action.value = reversedIndexOf(action.value);
        }
        handleItemAction(action);
    };

    const clearServiceItems = () => {
        var action = {
            type: serviceItemActionTypes.Clear,
        };
        handleItemAction(action);
    };

    useEffect(() => {
        setServiceItemsInReverseOrderAndCalculateTotal();
    }, [serviceItems]);

    const setServiceItemsInReverseOrderAndCalculateTotal = () => {
        var newItems = [...serviceItems];
        setItems(newItems.reverse());
        if (!Utils.IsNullOrEmptyArray(newItems)) {
            dummy.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
        if (isNavigateToLogin) {
            UserHelper.LogOut(dispatch);
            navigate(LoginPage.Path);
        }
    };

    return (
        <Box>
            <Loader open={loading} />
            <MessagePopup
                msgOpen={showAlert}
                msgText={alertMessage}
                onMsgClose={handleCloseAlert}
            />
            <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                fullWidth
                className="card"
                padding={1}
            >
                <Stack style={{ flex: "1" }}>
                    <AddProduct isLoading={productsLoading} allProducts={allProducts}
                        onAddItem={add} fromServiceSetup={true} isFromAddNewPackage={isFromAddNewPackage} />
                </Stack>
            </Stack>
            <Grid flex="1" spacing={2}>
                <Grid container xs={12} padding="0px 30px 0px 20px">
                    <Grid xs={2} lg={1} className="">
                        <Tooltip title="Remove All">
                            <Box
                                onClick={clearServiceItems}
                                sx={{
                                    width: 26,
                                    padding: "10px 3px",
                                    cursor: "pointer",
                                    color: "#ff0000",
                                }}
                                alt="Remove All"
                            >
                                <DeleteForeverRoundedIcon />
                            </Box>
                        </Tooltip>
                    </Grid>
                    <Grid xs={4}>
                        <Typography
                            level="p"
                            className="mt-1"
                            component="p"
                            fontSize="12px"
                            fontWeight="Bold"
                        >
                            Description
                        </Typography>
                    </Grid>
                    {!isFromAddNewPackage && <Grid
                        xs={2}
                        className="text-center hidden-xs"
                        style={{ marginRight: "25px" }}
                    >
                        <Typography
                            level="p"
                            className="mt-1"
                            component="p"
                            fontSize="12px"
                            fontWeight="Bold"
                        >
                            Quantity
                        </Typography>
                    </Grid>}
                    {!isFromAddNewPackage && <Grid xs={3} className="text-center hidden-xs"
                        style={{ marginRight: "25px" }}>
                        <Typography
                            level="p"
                            className="mt-1"
                            component="p"
                            fontSize="12px"
                            fontWeight="Bold"
                        >
                            Measuring Unit
                        </Typography>
                    </Grid>}
                </Grid>
            </Grid>
            <Grid
                xs={12}
                className="service-items"
                maxHeight={150}
                padding="0px 10px 0px 10px"
            >
                {!Utils.IsNullOrEmptyArray(items) &&
                    items.map((item, i) => (
                        <ProductItem
                            serviceItem={item}
                            index={i}
                            handleAction={handleAction}
                            isFromAddNewPackage={isFromAddNewPackage}
                        />
                    ))}
                <div ref={dummy} />
            </Grid>

        </Box>
    );
};

export default ProductConfigComponent;
