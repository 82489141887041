import React, { useRef, useEffect, useState } from "react";
import ServiceItem from "./ServiceItem";
import AddProduct from "./AddProduct";
import Utils from "../../core/Utils";
import Preview_Icon from "../../images/preview.png";
import Form_Icon from "../../images/form.png";
import { Box, Typography, Grid, Stack, Button, Tooltip, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { serviceItemActionTypes } from "../reducers/serviceItem/serviceItemActionTypes";
import CustomProduct from "../CustomProduct";
import MessagePopup from "./MessagePopUp";
import { postAsync } from "../../core/serviceClient";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import { generateTemporaryVisit } from "../../helpers/provisionalInvoiceHelper";
import { BusinessCategories, VisitTypeEnum } from "../../core/Constants";
import {
  getBranch,
  getBusiness,
  getCountry,
  getUser,
} from "../../data/localSettingsActions";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteOutline";
import UserHelper from "../../core/UserHelper";
import { LoginPage } from "../../core/PageConstants";
import CountryHelper from "../../core/CountryHelper";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';

const CartComponent = ({
  serviceItems,
  onAddItem,
  handleItemAction,
  onChangeTotal,
  includePreview = true,
  customerId = "",
  vehicle = {},
  invoiceLabels = [],
  itemComplimentaryList = [],
  onAddComp,
  onRemoveComp,
  onEditComp,
  setSelectedLabel,
  selectedLabel = {},
  visitType = VisitTypeEnum.Checkout,
  isCustomProductEntry,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showCustomProductEntry, setShowCustomProductEntry] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [isCustomProductVisible, setIsCustomProductVisible] = useState(false);
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [selectedInvoiceLabel, setSelectedInvoiceLabel] = useState({});
  const [selectedInvoiceLabelJSONString, setSelectedInvoiceLabelJSONString] =
    useState("");
  const dummy = useRef(null);

  useEffect(() => {
    setCustomProductButton(getBusiness());
  }, []);

  useEffect(() => {
    setInvoiceLabels();
  }, [selectedLabel]);

  const setInvoiceLabels = async () => {
    if (
      visitType === VisitTypeEnum.Jobcard &&
      !Utils.IsNullOrEmptyArray(invoiceLabels)
    ) {
      var activelabels = invoiceLabels.filter((i) => i.active);
      var label = activelabels.find(l => l.description == selectedLabel.description);
      if (Utils.IsNullOrEmptyObject(label)) {
        label = activelabels[0];
      }
      setSelectedInvoiceLabelJSONString(JSON.stringify(label));
      setSelectedInvoiceLabel(label);
      setSelectedLabel(label);
      if (!Utils.IsNullOrEmptyArray(serviceItems)) {
        await recalculateServiceItemsOnInvoiceLabelChange(activelabels[0]);
      }
    }
  };

  const recalculateServiceItemsOnInvoiceLabelChange = async (label) => {
    if (Utils.IsNullOrEmptyObject(label)) {
      return;
    }
    if (Utils.IsNullOrEmptyArray(serviceItems)) {
      return;
    }
    serviceItems.forEach((serviceItem) => {
      if (label.taxIncluded) {
        var totalPrice = serviceItem.TotalPrice - serviceItem.DiscountTotal;
        var totalpriceIncludingTax =
          (totalPrice * (serviceItem.TaxPercent + 100)) / 100;
        var taxamount = totalpriceIncludingTax - totalPrice;
        serviceItem.TaxAmount = taxamount;
        serviceItem.TotalPriceIncludingTax = totalpriceIncludingTax;
      } else {
        serviceItem.TotalPriceIncludingTax =
          serviceItem.TotalPriceIncludingTax - serviceItem.TaxAmount;
        serviceItem.TaxAmount = 0;
      }
      var action = {
        type: serviceItemActionTypes.Edit,
        value: serviceItem,
      };
      handleItemAction(action);
    });
  };
  const setCustomProductButton = (currentBusiness) => {
    if (Utils.IsNullOrEmptyObject(currentBusiness)) {
      setIsCustomProductVisible(false);
      return;
    }
    if (CountryHelper.IsBusinessCountryUSA(getCountry())) {
      setIsCustomProductVisible(false);
      return;
    }
    switch (currentBusiness.category) {
      case BusinessCategories.Automobile:
      case BusinessCategories.Salon:
      case BusinessCategories.Other:
        setIsCustomProductVisible(true);
        break;
      default:
        setIsCustomProductVisible(false);
        break;
    }
  };

  const add = (item) => {
    onAddItem(item);
  };

  const reversedIndexOf = (ind) => {
    var length = serviceItems.length;
    if (ind === -1) {
      return -1;
    }
    return length - ind - 1;
  };

  const handleAction = (action) => {
    if (action.type === serviceItemActionTypes.Delete) {
      action.value = reversedIndexOf(action.value);
    }
    handleItemAction(action);
  };

  const clearServiceItems = () => {
    var action = {
      type: serviceItemActionTypes.Clear,
    };
    handleItemAction(action);
  };

  const clickCloseCustomProductModal = () => {
    setShowCustomProductEntry(false);
  };

  // const addCustomProduct = (customItem) => {
  //   if (Utils.IsNullOrEmptyObject(customItem)) {
  //     return;
  //   }
  //   onAddItem(customItem, true);
  // };

  useEffect(() => {
    setServiceItemsInReverseOrderAndCalculateTotal();
  }, [serviceItems]);

  const setServiceItemsInReverseOrderAndCalculateTotal = () => {
    var newItems = [...serviceItems];
    setItems(newItems.reverse());
    if (!Utils.IsNullOrEmptyArray(newItems)) {
      dummy.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
    if (!Utils.IsNullOrEmpty(onChangeTotal)) {
      if (Utils.IsNullOrEmptyArray(serviceItems)) {
        onChangeTotal(0);
        return;
      }
      var total = serviceItems
        .map((f) => f.TotalPriceIncludingTax)
        .reduce((a, b) => a + b);
      onChangeTotal(total);
    }
  };

  const showPreview = async () => {
    if (Utils.IsNullOrEmptyArray(serviceItems)) {
      setAlertMessage("Please add at least one item before continuing.");
      setShowAlert(true);
      return;
    }
    var newVehicle = Utils.IsNullOrEmptyObject(vehicle) ? null : vehicle;
    var visit = generateTemporaryVisit(
      serviceItems,
      customerId,
      visitType,
      newVehicle
    );
    if (Utils.IsNullOrEmptyObject(visit)) {
      return;
    }
    setLoading(true);
    var request = {
      CountryCode: getBranch().countryCode,
      CustomerVisit: visit,
      Mask: getUser().maskCustomerDetails,
      IsPreview: true,
    };
    var result = await postAsync("Customer/InvoiceBeforePayment", request);
    setLoading(false);
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlertMessage(result.errorMessage);
      setShowAlert(true);
      return;
    }
    let child = window.open("about:blank", "myChild");
    child.document.write(result.data);
    child.document.close();
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };
  const handleInvoiceLabelChange = async (e) => {
    var jsonString = e.target.value;
    var taxEnabledOnPreviousLabel = selectedInvoiceLabel.taxIncluded;
    var newInvoiceLabel = JSON.parse(jsonString);
    var taxIncludedOnNewLabel = newInvoiceLabel.taxIncluded;
    setSelectedInvoiceLabelJSONString(jsonString);
    setSelectedInvoiceLabel(newInvoiceLabel);
    setSelectedLabel(newInvoiceLabel);
    if (
      !Utils.IsNullOrEmptyArray(serviceItems) &&
      taxEnabledOnPreviousLabel !== taxIncludedOnNewLabel
    ) {
      await recalculateServiceItemsOnInvoiceLabelChange(newInvoiceLabel);
    }
  };

  return (
    <Box>
      <Loader open={loading} />
      <MessagePopup
        msgOpen={showAlert}
        msgText={alertMessage}
        onMsgClose={handleCloseAlert}
      />
      {/* <CustomProduct
        open={showCustomProductEntry}
        onClose={clickCloseCustomProductModal}
        onAdd={addCustomProduct}
      /> */}
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        fullWidth
        className="card"
        padding={1}
      >
        <Stack style={{ width: visitType === VisitTypeEnum.Estimate ? "85%" : "75%" }}>
          <AddProduct onAddItem={add} />
        </Stack>
        <Stack direction="row" spacing={1} className="font-size-14">
          {includePreview && (
            <Button
              className="btn btn-secondary align-self-stretch"
              variant="contained"
              onClick={() => showPreview()}
              style={{ height: "54px" }}
            >
              <VisibilityIcon title="Preview" sx={{color: "#9C27B0"}} />
              <span
                className="hidden-xs ml-h"
                style={{ "align-self": "center" }}
              >
                Preview Invoice
              </span>
            </Button>
          )}
          { }
          {visitType === VisitTypeEnum.Jobcard &&
            !Utils.IsNullOrEmptyArray(
              invoiceLabels.filter((i) => i.active)
            ) && (
              <FormControl className="invoice-label font-size-14">
                <InputLabel id="demo-simple-select-label">
                  Invoice Label
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Invoice Label"
                  value={selectedInvoiceLabelJSONString || ""}
                  onChange={(e) => handleInvoiceLabelChange(e)}
                >
                  {!Utils.IsNullOrEmptyArray(invoiceLabels) &&
                    invoiceLabels
                      .filter((i) => i.active)
                      .map((label) => (
                        <MenuItem value={JSON.stringify(label)}>
                          {label.description}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            )}
          {isCustomProductVisible && (
            <Button
              className="btn btn-secondary align-self-start"
              variant="contained"
              onClick={() => isCustomProductEntry(true)}
              style={{ height: "54px" }}
            >
              <AddCircleOutlinedIcon title="Add Custom" />
              <span
                className="hidden-xs ml-h"
                style={{ "align-self": "center" }}
              >
                Add Custom Product
              </span>
            </Button>
          )}
        </Stack>
      </Stack>
      <Grid flex="1" spacing={2}>
        <Grid container xs={12} padding="0px 15px 0px 15px">

          <Grid xs={1} className="">
            <Tooltip title="Remove All">
              <Box
                onClick={clearServiceItems}
                sx={{
                  width: 26,
                  padding: "10px 3px",
                  cursor: "pointer",
                  color: "#ff0000",
                }}
                alt="Remove All"
              >
                <DeleteForeverRoundedIcon />
              </Box>
            </Tooltip>
          </Grid>
          <Grid xs={4}>
            <Typography
              level="p"
              className="mt-1"
              component="p"
              fontSize="12px"
              fontWeight="Bold"
            >
              Description
            </Typography>
          </Grid>
          <Grid xs={2}
            className="text-center hidden-xs"
            style={{ "padding-right": "20px" }}
          >
            <Typography
              level="p"
              className="mt-1"
              component="p"
              fontSize="12px"
              fontWeight="Bold"
            >
              Unit Price
            </Typography>
          </Grid>
          <Grid xs={2} className="text-center hidden-xs">
            <Typography
              level="p"
              className="mt-1"
              component="p"
              fontSize="12px"
              fontWeight="Bold"
            >
              Qty
            </Typography>
          </Grid>

          <Grid xs={1} className="text-center hidden-xs">
            {(visitType === VisitTypeEnum.Jobcard || visitType === VisitTypeEnum.Estimate) && (
              <Typography
                level="p"
                className="mt-1"
                component="p"
                fontSize="12px"
                fontWeight="Bold"
              >
                Discount
              </Typography>
            )}
          </Grid>
          <Grid xs={1} className="text-right hidden-xs">
            <Typography
              level="p"
              className="mt-1"
              component="p"
              fontSize="12px"
              fontWeight="Bold"
            >
              Tax (%)
            </Typography>
          </Grid>
          <Grid xs={1} className="text-right hidden-xs">
            <Typography
              level="p"
              className="mt-1"
              component="p"
              fontSize="12px"
              fontWeight="Bold"
            >
              Total Price
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        className="service-items"
      >
        {!Utils.IsNullOrEmptyArray(items) &&
          items.map((item, i) => (
            <ServiceItem
              serviceItem={item}
              index={i}
              handleAction={handleAction}
              itemComplimentaryList={itemComplimentaryList}
              isDiscount={(visitType === VisitTypeEnum.Jobcard || visitType === VisitTypeEnum.Estimate)}
              onAddComp={onAddComp}
              onRemoveComp={onRemoveComp}
              onEditComp={onEditComp}
              taxIncluded={Utils.IsNullOrEmptyObject(selectedInvoiceLabel) || selectedInvoiceLabel.taxIncluded}
            />
          ))}
        <div ref={dummy} />
      </Grid>
      {/* <Stack direction="row" spacing={2} justifyContent="flex-end" fullWidth>
        <Typography
          level="h4"
          className="mt-1"
          component="h4"
          fontWeight="600"
          fontSize="16px"
        >
          <small>Total:</small>
        </Typography>
        <Typography
          level="h4"
          className="mt-1"
          component="h4"
          fontWeight="600"
          fontSize="16px"
        >
          {Utils.GetCurrency(totalPrice, countryInfo)}
        </Typography>
      </Stack> */}
    </Box>
  );
};

export default CartComponent;
