import React, { useState, useEffect } from "react";
import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";
import {
  Button,
  Container,
  Box,
  Grid,
  Stack,
  Typography,
  Divider,
  Rating,
  Tooltip,
  Chip,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import SideBarMenuItems from "./SideBarMenuItems";
import { connectToStore } from "../data/store";
import Utils from "../core/Utils";
import { SideBarMenuNames } from "../core/Constants";
import Loader from "./common/Loader";

// Images
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CountryHelper from "../core/CountryHelper";
import { postAsync } from "../core/serviceClient";
import { convertServiceItemObject } from "../helpers/productHelper";
import EditIcon from "@mui/icons-material/Edit";
import {
  AMCViewPlanPage,
  CalanderPage,
  CheckOutPage,
  CheckoutDetailsPage,
  VisitsPage,
} from "../core/PageConstants";
import UserHelper from "../core/UserHelper";
import ConfirmPopup from "./common/ConfirmPopup";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const CheckoutDetails = (props) => {
  document.title = "Checkout Details";
  const navigate = useNavigate();
  const location = useLocation();
  const [localSettings, setLocalSettings] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0.0);
  const [subTotalPrice, setSubTotalPrice] = useState(0.0);
  const [isAMCVeiwPlan, setIsAMCVeiwPlan] = useState(false);
  const [tax, setTax] = useState(0);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [isReviewSubmitted, setIsReviewSubmitted] = useState(false);

  useEffect(() => {
    let localSetting = connectToStore();
    if (
      UserHelper.CheckPermission(CheckoutDetailsPage, navigate, localSetting)
    ) {
      setLocalSettings(localSetting);
      var visit = location.state.visit;
      setIsAMCVeiwPlan(location.state.isFromAMCViewPlan);
      setIsReviewSubmitted(location.state.visit.reviewSubmited)
      if (!Utils.IsNullOrEmptyObject(visit)) {
        var tempTax = visit.taxFree ? 0 : visit.tax;
        var subTotal = visit.spend - visit.discount;
        var total =
          subTotal + tempTax + visit.tip + (visit.includeFees ? visit.fees : 0);
        setTax(tempTax);
        setSubTotalPrice(subTotal);
        setTotalPrice(total);
      }
    }
  }, []);

  //   Back
  const goBack = () => {
    if (!UserHelper.IsStateValid(location.state, navigate)) 
      return;
    if (isAMCVeiwPlan === true) {
      navigate(AMCViewPlanPage.Path, {
        state: {
          ...location.state,
          navigationStack: Utils.RemoveLastElementFromArray(
            location.state.navigationStack
          ),
        },
      });
    } else {
      if(location.state.onSuccess === "/Calendar") {
        navigate(CalanderPage.Path);
      } else {
        navigate(VisitsPage.Path, {
          state: {
            isProfitVisible: location.state.isProfitVisible,
            isUnPaid: location.state.isUnPaid,
            withoutDateRange: location.state.withoutDateRange,
            selectedCategory: location.state.selectedCategory,
            selectedPaymentTypes: location.state.selectedPaymentTypes,
            startDT: location.state.startDT,
            endDT: location.state.endDT,
            searchText:
              location.state && location.state.searchText
                ? location.state.searchText
                : "",
          },
        });
      }
      
    }
  };

  const calculateProfit = (visit) => {
    let profit = 0;
    if (!Utils.IsNullOrEmptyObject(visit)) {
      if (!Utils.IsNullOrEmptyArray(visit.serviceItems))
        visit.serviceItems.forEach((si) => {
          profit += si.totalPrice - si.cost * si.quantity - si.discountTotal;
        });
      profit -= visit.fees;
    }
    return profit;
  };

  const editVisitConfirm = async () => {
    setConfirmText(
      "Editing a closed invoice will reset payment history and may not reconcile with online payments received from your end. Are you sure you want to continue?"
    );
    setConfirmOpen(true);
  }

  const editVisit = async () => {
    setIsLoading(true);
    var result = await postAsync(
      "Customer/GetCustomerPass",
      {
        Id: localSettings.business.id + location.state.visit.customerId,
        BusinessId: localSettings.business.id,
      },
      "",
      "Get"
    );
    setIsLoading(false);
    if (result.error) {
      alert(result.errorMessage);
      return;
    }
    var cus = result.data;
    var newItems = [];
    location.state.visit.serviceItems.forEach((item) => {
      var newItem = convertServiceItemObject(item);
      newItems.push(newItem);
    });
    var state = {
      ...location.state,
      navigationStack: 
      Utils.AddElementToArray(
        location.state.navigationStack,
        CheckoutDetailsPage.Path
      ),
      visit: location.state.visit,
      checkout: true,
      pass: cus,
      serviceItems: newItems,
      vehicleDetails: location.state.visit.vehicleDetails,
      fromJobcard: false,
      fromVisit: true,
      isEdit: true,
    };
    navigate(CheckOutPage.Path, { state: state });
  };

  const handleConfirmClose = async (value) => {
    if (value == "OK") {
      editVisit();
    } 
    else {
      setConfirmOpen(false);
    }
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
          okText="Yes"
          cancelText="No"
          className="warning-modal"
        />
        <Box>
        <Grid container m={0}>
          <SideBarMenuItems
            selectedTab={
              isAMCVeiwPlan === false
                ? SideBarMenuNames.Overview
                : SideBarMenuNames.Customers
            }
          />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="form-sec p-0">
              <Grid container className="title-sec">
                <Typography
                  level="h2"
                  component="h2"
                  fontWeight="600"
                  fontSize="2rem"
                  className="page-title text-center"
                >
                  <Button onClick={() => goBack()} className="back-btn">
                    <NavigateBeforeIcon />
                  </Button>
                  Checkout Details
                </Typography>
                {isAMCVeiwPlan === false && (
                  <Stack alignSelf="center">
                    <Button
                      variant="outlined"
                      className="btn btn-secondary"
                      onClick={() => editVisitConfirm()}
                    >
                      <EditIcon fontSize="large" style={{ color: "#2196f3" }} />
                      <span className="hidden-xs ml-h">Edit</span>
                    </Button>
                  </Stack>
                )}
              </Grid>
              <Grid container direction="row" justifyContent="space-between">
                <Typography
                  level="h2"
                  component="h2"
                  fontWeight="600"
                  fontSize="1.5rem"
                  className="page-title"
                >
                  {location.state.desc}
                </Typography>
                {isReviewSubmitted && <Rating
                  name={`overall-rating`}
                  value={location.state.visit.overAllRating}
                  precision={0.5}
                  size="large"
                  readOnly
                />}
               {isReviewSubmitted && <Tooltip direction="row" alignItems="center"  title={location.state.visit?.reviewComment} arrow>
                   <InfoOutlinedIcon sx={{fontSize: '2rem', cursor: "pointer", alignSelf: 'center'}} />
                  </Tooltip>}
                {!Utils.IsNullOrEmpty(location.state.visit.invoiceNumber) && (
                  <Stack direction="row">
                    <Typography
                      level="h4"
                      component="h4"
                      fontWeight="400"
                      fontSize="1.5rem"
                    >
                      Invoice #:&nbsp;
                    </Typography>
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="600"
                      fontSize="1.5rem"
                      className="page-title text-right"
                    >
                      {location.state.visit.invoiceNumber}
                    </Typography>
                  </Stack>
                )}
              </Grid>
              <Grid container xs={12} className="mt-2 cs-form">
                <Grid xs={12} spacing={2}>
                  <Grid container xs={12} padding="0 10px">
                    <Grid xs={1} className="">
                      <Typography
                        level="p"
                        className="mt-1"
                        component="p"
                        fontSize="12px"
                      >
                        #
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      <Typography
                        level="p"
                        className="mt-1"
                        component="p"
                        fontSize="12px"
                      >
                        Description
                      </Typography>
                    </Grid>
                    <Grid xs={1} className="text-right hidden-xs">
                      <Typography
                        level="p"
                        className="mt-1"
                        component="p"
                        fontSize="12px"
                      >
                        Qty
                      </Typography>
                    </Grid>
                    <Grid xs={2} className="text-right hidden-xs">
                      <Typography
                        level="p"
                        className="mt-1"
                        component="p"
                        fontSize="12px"
                      >
                        Unite Price
                      </Typography>
                    </Grid>
                    <Grid xs={2} className="text-right hidden-xs">
                      <Typography
                        level="p"
                        className="mt-1"
                        component="p"
                        fontSize="12px"
                      >
                        Total Price
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} spacing={2}>
                  {!Utils.IsNullOrEmptyArray(
                    location.state.visit.serviceItems
                  ) &&
                    location.state.visit.serviceItems.map((row, ind) => (
                      <Grid xs={12}>
                        <Grid
                          container
                          xs={12}
                          padding="10px"
                          className="card mt-1"
                          direction="row"
                        >
                          <Grid xs={1} lg={1} className="">
                            <Typography
                              level="h4"
                              className="mt-1"
                              component="h4"
                              fontWeight="600"
                              fontSize="16px"
                            >
                              {ind + 1}
                            </Typography>
                          </Grid>
                          <Grid xs={11} sm={6} md={6} lg={6}>
                            <Typography
                              level="h4"
                              className="mt-1"
                              component="h4"
                              fontWeight="600"
                              fontSize="16px"
                            >
                              {row.description}
                            </Typography>
                            {!Utils.IsNullOrEmpty(row.descriptionDetail) && (
                              <Typography
                                level="h5"
                                className="mt-1"
                                component="h5"
                                fontSize="14px"
                              >
                                {row.descriptionDetail}
                              </Typography>
                            )}
                            <p className="m-0">
                            {!Utils.IsNullOrEmptyArray(
                              row.serviceItemStaffs
                            ) && (
                                <>
                                <strong>{" Stylist: "}</strong>
                                <Chip
                              className="font-size-14"
                              label=
                              {row.serviceItemStaffs
                                .map(
                                (staff, j) => (
                                  <span key={j}>
                                    {staff.name}
                                    {j <
                                        row.serviceItemStaffs
                                        .length -
                                      1
                                      ? ", "
                                      : ""}
                                  </span>
                                )
                              )}
                            />
                                </>)}
                            </p>
                           {isReviewSubmitted && row.reviewProduct && 
                           <Grid sx={{display: "flex", gap: "4px"}}>
                           <Rating
                              name={`${ind}-rating`}
                              value={row.productRating}
                              precision={0.5}
                              readOnly 
                              size="large"
                            />
                            <Typography
                            level="h6"
                            component="h6"
                            fontWeight="600"
                            fontSize="12px"
                            >
                           { row.productRating}
                            </Typography>
                            </Grid>}
                          </Grid>
                          <Grid xs={12} sm={1} md={1} lg={1} className="text-right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              className="mt-1 mb-jc-sb"
                            >
                              <small className="visible-xs">Qty</small>
                              <Typography
                                level="h4"
                                component="h4"
                                fontWeight="600"
                                fontSize="16px"
                              >
                                {row.quantity}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid xs={12} sm={2} md={2} lg={2} className="text-right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              className="mt-1 mb-jc-sb"
                            >
                              <small className="visible-xs">Unit Price</small>
                              <Typography
                                level="h4"
                                component="h4"
                                fontWeight="600"
                                fontSize="16px"
                              >
                                {Utils.GetCurrency(
                                  row.price,
                                  localSettings.country
                                )}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid xs={12} sm={2} md={2} lg={2} className="text-right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              className="mt-1 mb-jc-sb"
                            >
                              <small className="visible-xs">
                                Total Price
                              </small>
                              <Typography
                                level="h4"
                                component="h4"
                                fontWeight="600"
                                fontSize="16px"
                              >
                                {Utils.GetCurrency(
                                  row.totalPrice +
                                    Math.round(
                                      (row.totalPrice * row.taxPercent) / 100
                                    ),
                                  localSettings.country
                                )}
                              </Typography>
                            </Stack>
                            {
                              <Typography
                                level="h5"
                                className="mt-1"
                                component="h5"
                                fontSize="14px"
                              >
                                {row.taxPercent > 0
                                  ? "+ " + row.taxPercent + "% Tax"
                                  : ""}
                              </Typography>
                            }
                          </Grid>
                        </Grid>
                        {row.refundPrice > 0 && (
                          <Grid
                            container
                            xs={12}
                            spacing={1}
                            padding={2}
                            direction="row"
                          >
                            <Grid xs={1}></Grid>
                            <Grid xs={6}>
                              <Typography
                                level="h4"
                                className="mt-1"
                                component="h4"
                                fontWeight="600"
                                fontSize="16px"
                              >
                                {row.description}
                              </Typography>
                              <Typography
                                level="h4"
                                className="mt-1"
                                component="h4"
                                fontWeight="600"
                                fontSize="16px"
                              >
                                *** Refund ***
                              </Typography>
                            </Grid>
                            <Grid xs={1}>
                              <Typography
                                level="h4"
                                component="h4"
                                fontSize="1.5rem"
                                fontWeight="500"
                                className="text-right"
                              >
                                {row.quantity}
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              <Typography
                                level="h4"
                                component="h4"
                                fontSize="1.5rem"
                                fontWeight="500"
                                className="text-right"
                              >
                                {Utils.GetCurrency(
                                  row.price,
                                  localSettings.country
                                )}
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              <Typography
                                level="h4"
                                component="h4"
                                fontSize="1.5rem"
                                fontWeight="500"
                                className="text-right"
                              >
                                -
                                {Utils.GetCurrency(
                                  row.refundPrice,
                                  localSettings.country
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        {row.refundPrice === 0 &&
                          !Utils.IsNullOrEmptyArray(row.discounts) &&
                          row.discounts.map((discount) => (
                            <Grid
                              container
                              xs={12}
                              spacing={1}
                              padding={2}
                              direction="row"
                            >
                              <Grid xs={1}></Grid>
                              <Grid xs={6}>
                                <Typography
                                  level="h4"
                                  component="h4"
                                  fontSize="1.5rem"
                                  fontWeight="500"
                                  className="text-right"
                                >
                                  {discount.description}
                                </Typography>
                                <Typography
                                  level="h4"
                                  component="h4"
                                  fontSize="1.5rem"
                                  fontWeight="500"
                                  className="text-right"
                                >
                                  {discount.descriptionDetail}
                                </Typography>
                              </Grid>
                              <Grid xs={1} className="text-right"></Grid>
                              <Grid xs={2} className="text-right"></Grid>
                              <Grid xs={2} className="text-right">
                                <Typography
                                  level="h4"
                                  className="mt-1"
                                  component="h4"
                                  fontWeight="600"
                                  fontSize="16px"
                                >
                                  -
                                  {Utils.GetCurrency(
                                    discount.discountTotal,
                                    localSettings.country
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    ))}
                </Grid>

                <Grid flex="1" spacing={2} className="mt-2">
                  <Grid container xs={12}>
                    <Grid item xs={8} className="">
                    {isReviewSubmitted && <Typography
                        level="h5"
                        component="h5"
                        fontWeight="600"
                        fontSize="16px"
                            >
                            Stylist's Rating :
                            </Typography>}
                    {!Utils.IsNullOrEmptyArray(
                    location.state.visit.serviceItems
                  ) && isReviewSubmitted &&
                  location.state.visit.serviceItems.map((row,ind) => (
                    <div>
                    {row.serviceItemStaffs?.length > 0 && row.serviceItemStaffs[0].addStaffReview && 
                      <Grid container>
                      <Grid item xs={4}>
                        {row.serviceItemStaffs[0].name}
                      </Grid>
                      <Grid sx={{display: "flex", gap: "4px"}} item xs={8}>
                      <Rating
                          name={`${ind}-personrating`}
                          value={row.serviceItemStaffs[0].servicePersonRating}
                          precision={0.5}
                          readOnly 
                          size="large"
                        />
                        <Typography
                        level="h6"
                        component="h6"
                        fontWeight="600"
                        fontSize="12px"
                            >
                           { row.serviceItemStaffs[0].servicePersonRating}
                            </Typography>
                      </Grid>
                      </Grid>}
                    </div>
                  ))
                   }
                   
                    </Grid>
                    <Grid xs={12} className="text-right">
                      <Typography
                        level="h4"
                        component="h4"
                        fontWeight="600"
                        fontSize="16px"
                      >
                        <small>Total:</small> &nbsp;
                        {Utils.GetCurrency(
                          CountryHelper.IsBusinessCountryUSA(
                            localSettings.country
                          )
                            ? subTotalPrice
                            : totalPrice
                        )}
                      </Typography>
                      {CountryHelper.IsBusinessCountryUSA(
                        localSettings.country
                      ) && (
                        <Typography
                          level="h4"
                          component="h4"
                          fontWeight="600"
                          fontSize="16px"
                        >
                          <small>Tax:</small> &nbsp;
                          {Utils.GetCurrency(tax, localSettings.country)}
                        </Typography>
                      )}
                      <Typography
                        level="h4"
                        component="h4"
                        fontWeight="600"
                        fontSize="16px"
                      >
                        <small>Tips:</small> &nbsp;
                        {location.state &&
                          location.state.visit &&
                          Utils.GetCurrency(
                            location.state.visit.tip,
                            localSettings.country
                          )}
                      </Typography>
                      {location.state &&
                        location.state.visit &&
                        location.state.visit.includeFees && (
                          <Typography
                            level="h4"
                            component="h4"
                            fontWeight="600"
                            fontSize="16px"
                          >
                            <small>Fees:</small> &nbsp;
                            {location.state &&
                              location.state.visit &&
                              Utils.GetCurrency(
                                location.state.visit.fees,
                                localSettings.country
                              )}
                          </Typography>
                        )}
                      <Divider className="mt-1" />
                    </Grid>
                    <Grid
                      xs={12}
                      className="align-xs-center xs-column mt-1"
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography
                        level="p"
                        className="mt-1"
                        component="p"
                        fontSize="12px"
                      >
                        Payment received by:
                        <strong className="ml-h">
                            {location.state &&
                              location.state.visit &&
                              location.state.visit.paymentReceivedBy}
                        </strong>
                      </Typography>
                      <Typography
                        level="h4"
                        component="h4"
                        fontWeight="600"
                        fontSize="24px"
                      >
                        {Utils.GetCurrency(totalPrice, localSettings.country)}
                      </Typography>
                    </Grid>
                    {location.state && location.state.isProfitVisible && (
                      <Typography
                        level="h4"
                        component="h4"
                        fontWeight="600"
                        fontSize="16px"
                      >
                        <small>Profit:</small>
                        {location.state &&
                          location.state.visit &&
                          Utils.GetCurrency(
                            calculateProfit(location.state.visit),
                            localSettings.country
                          )}
                      </Typography>
                    )}
                    {location.state &&
                      location.state.visit &&
                      location.state.visit.refundAmount > 0 && (
                        <Grid
                          xs={12}
                          className="text-right hidden-xs"
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Typography
                            level="p"
                            className="mt-1"
                            component="p"
                            fontSize="12px"
                          >
                            Refund issued '
                            {location.state &&
                              location.state.visit &&
                              location.state.visit.refundReason}
                            '
                          </Typography>
                          <Typography
                            variant="h5"
                            className="mt-1"
                            fontWeight="600"
                          >
                            {location.state &&
                              location.state.visit &&
                              Utils.GetCurrency(
                                location.state.visit.refundAmount,
                                localSettings.country
                              )}
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default CheckoutDetails;
