import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Avatar,
  Stack,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { useLocation } from "react-router-dom";
import { postAsync } from "../core/serviceClient";
import MessagePopup from "./common/MessagePopUp";
import Loader from "./common/Loader";
import moment from "moment";

const ReviewSection = ({
  title,
  handleRating,
  ratingValue,
  reviewSubmited,
  serviceImageUrl,
}) => {

  const handleNewRating = (value) => {
    if (!reviewSubmited) {
      handleRating(value);
    }
  };

  return (
    <Grid
      flex="1"
      className="mt-2 p-2"
      sx={{
        background: "#fff",
        borderRadius: "4px",
        boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
      }}
    >
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={2} maxWidth="100%">
          {serviceImageUrl && <img src={serviceImageUrl} alt="" height="80" />}
        </Grid>
      </Grid>
      <Rating
        name={`${title}-rating`}
        value={ratingValue}
        onChange={(event, newValue) => handleNewRating(newValue)}
        precision={0.5}
        size="large"
        readOnly={reviewSubmited}
        sx={{ fontSize: "2.5rem", marginTop: "0.5rem" }}
      />
    </Grid>
  );
};

const ReviewPage = () => {
  const [comment, setComment] = useState("");
  const [serviceItems, setServiceItems] = useState("");
  const [overAllRating, setOverAllRating] = useState(0);
  const [businessDetails, setBusinessDetails] = useState("");
  const [branchDetails, setBranchDetails] = useState("");
  const [visitorDetails, setVisitorDetails] = useState("");
  const [reviewSubmited, SetReviewSubmitted] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [productReviewExist, setProductReviewExist] = useState(false);
  const [personReviewExist, setPersonReviewExist] = useState(false);
  const [visitedDate, setVisitedDate] = useState("");

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const visiterId = params.get("v");
  const businessId = params.get("b");

  const handleComment = (value) => {
    setComment(value);
  };

  const handleRating = (value, section, index) => {
    if (section === "Overall") {
      setOverAllRating(value);
    }
    if (section === "productRating") {
      setServiceItems((prevServiceItems) => {
        const updatedServiceItems = [...prevServiceItems];
        updatedServiceItems[index] = {
          ...updatedServiceItems[index],
          productRating: value,
        };
        return updatedServiceItems;
      });
    }
    if (section === "personRating") {
      setServiceItems((prevServiceItems) => {
        const updatedServiceItems = [...prevServiceItems];
        updatedServiceItems[index] = {
          ...updatedServiceItems[index],
          serviceItemStaffs: [
            {
              ...updatedServiceItems[index].serviceItemStaffs[0],
              servicePersonRating: value,
            },
          ],
        };
        return updatedServiceItems;
      });
    }
  };

  useEffect(() => {
    if (visiterId && businessId) {
      getVisitorDetails(visiterId, businessId);
    }
    const originalPadding = document.body.style.padding;
    document.body.style.padding = "0";
    document.body.style.margin = "0";
    return () => {
      document.body.style.padding = originalPadding;
      document.title = "Salon Feedback Form by Customer";
    };
  }, []);

  const getVisitorDetails = async (vId, bId) => {
    setIsLoading(true);
    var result = await postAsync(
      "Customer/GetCustomerVisit",
      { BusinessId: businessId, Id: visiterId },
      "",
      "Get"
    );

    setVisitorDetails(result.data);
    let visitordetails = result.data;
    setOverAllRating(visitordetails.overAllRating);
    setComment(visitordetails.reviewComment);
    SetReviewSubmitted(visitordetails.reviewSubmited);
    let paymentdate = visitordetails?.paidDT;
    if (paymentdate) {
      let newdate = moment(paymentdate).format("DD/MM/YYYY");
      setVisitedDate(newdate);
    }

    var result2 = await postAsync("Business/SaveBranch", {
      BusinessId: businessId,
      Branch: { Id: result.data.branchId },
    });
    setBusinessDetails(result2.data.business);
    result2?.data?.business?.branchLocations.map((branch) => {
      if (branch.branchId === result.data.branchId) {
        setBranchDetails(branch);
      }
    });

    setServiceItems(result.data.serviceItems);
    const ServiceItems = result.data.serviceItems;
    if (ServiceItems.length > 0) {
      ServiceItems.map((serviceItem) => {
        if (serviceItem.reviewProduct) {
          setProductReviewExist(true);
        }
        if (serviceItem.serviceItemStaffs.length > 0) {
          if (serviceItem.serviceItemStaffs[0].addStaffReview) {
            setPersonReviewExist(true);
          }
        }
      });
    }

    setIsLoading(false);
  };

  const handleOpenConfirm = (e) => {
    e.preventDefault();
    setMsgOpen(true);
    setMsgText(
      "Thank you for your feedback! <br /> <br /> We look forward to serving you again soon."
    );
  };

  const handleMsgClose = () => {
    setMsgOpen(false);
    handleSubmit();
  };

  const handleSubmit = async () => {
    var newresult = {
      ...visitorDetails,
      overAllRating: overAllRating,
      reviewComment: comment,
      serviceItems: serviceItems,
      reviewSubmited: true,
    };

    var finalresult = await postAsync(
      "Customer/GetCustomerVisit",
      newresult,
      "",
      "Save"
    );
    getVisitorDetails(visiterId, businessId);
  };

  return (
    <div sx={{ margin: "0px !important" }}>
      <style>
        {`
        .TextAreaInput:focus {
            border: none;
            outline: none;
          }
        `}
      </style>
      {!reviewSubmited ? <Container maxWidth="md" sx={{ background: "#78c223", color: "black" }}>
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            className="avatar-sec"
            sx={{ paddingTop: "2rem" }}
            gap={2}
          >
            <Avatar
              alt={businessDetails.companyName}
              src={`${businessDetails?.logoUrl}?${Date.now()}`}
              className="profile-pic"
              sx={{ background: "#ffffff" }}
            />
            <Typography
              level="h2"
              component="h2"
              fontWeight="600"
              fontSize="2rem"
              className="h2"
              sx={{ color: "white" }}
            >
              {businessDetails?.companyName}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              level="h4"
              component="h4"
              fontWeight="600"
              fontSize="16px"
              style={{ color: "white" }}
            >
              <span style={{ color: "black" }}>Branch Name :</span>
              {` ${branchDetails && branchDetails.branchName}`}
            </Typography>
            <Typography
              level="h4"
              component="h4"
              fontWeight="600"
              fontSize="16px"
              style={{ color: "white" }}
            >
              <span style={{ color: "black" }}>Visited:</span>
              {` ${visitedDate && visitedDate}`}
            </Typography>
          </Stack>
          <Typography
            level="h4"
            component="h4"
            fontWeight="600"
            fontSize="14px"
          >
            <div
              style={{ marginTop: "2rem" }}
              dangerouslySetInnerHTML={{
                __html: `Dear Valued Customer,<br /><br />
                Thank you for choosing <span style="">${
                  businessDetails?.companyName
                    ? businessDetails?.companyName
                    : ""
                }</span>. We appreciate your feedback as it helps us improve our services. Please take a moment to fill out this feedback form.`,
              }}
            />
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <Grid mt={3} item xs={12}>
            <ReviewSection
              reviewSubmited={reviewSubmited}
              ratingValue={overAllRating}
              reviewTitle="overall"
              title="On a scale of 1 to 5, how happy are you with your visit to our salon?"
              handleRating={(value) => {
                handleRating(value, "Overall", 0);
              }}
            />
          </Grid>
          {serviceItems?.length > 0 && productReviewExist && (
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
                Please rate below products or services provided to you:
              </Typography>
              {serviceItems.map((serviceItem, index) => {
                return (
                  <div>
                    {serviceItem.reviewProduct && (
                      <div>
                        <ReviewSection
                          serviceImageUrl={
                            serviceItem.imageUrl ? serviceItem.imageUrl : ""
                          }
                          reviewSubmited={reviewSubmited}
                          ratingValue={serviceItem.productRating}
                          reviewTitle="serviceItems"
                          key={index}
                          title={serviceItem.description}
                          handleRating={(value) => {
                            handleRating(value, "productRating", index);
                          }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </Grid>
          )}

          {serviceItems?.length > 0 && personReviewExist && (
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
                Please provide a rating for the stylists below who served you:
              </Typography>
              {serviceItems.map((serviceItem, index) => {
                return (
                  <div>
                    {serviceItem.serviceItemStaffs?.length > 0 &&
                      serviceItem.serviceItemStaffs[0].addStaffReview && (
                        <ReviewSection
                          reviewSubmited={reviewSubmited}
                          key={index}
                          ratingValue={
                            serviceItem.serviceItemStaffs[0].servicePersonRating
                          }
                          reviewTitle="personReview"
                          title={serviceItem.serviceItemStaffs[0].name}
                          handleRating={(value) => {
                            handleRating(value, "personRating", index);
                          }}
                        />
                      )}
                  </div>
                );
              })}
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
              Please provide your feedback or suggestions on how can we improve
              our product or service to serve you better?
            </Typography>
            <Box mt={2}>
              {/* <TextField
                multiline
                rows={4}
                value={comment}
                onChange={(e) => {
                  handleComment(e.target.value);
                }}
                fullWidth
                variant="outlined"
                inputProps={{
                  style: { fontSize: 14, background: "white" },
                  readOnly: reviewSubmited
                }}
                InputLabelProps={{
                  style: { color: "white", border: "black", fontSize: 14 },
                }}
              /> */}
              <textarea
                className="TextAreaInput"
                style={{
                  width: "100%",
                  resize: "none",
                  background: "#fff",
                  borderRadius: "4px",
                  boxShadow:
                    "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                }}
                onChange={(e) => {
                  handleComment(e.target.value);
                }}
                value={comment}
                rows="4"
                data-min-rows="4"
                placeholder="Your Comments"
              ></textarea>
            </Box>
          </Grid>
          <Grid
            item
            mb={4}
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                handleOpenConfirm(e);
              }}
              disabled={reviewSubmited}
              sx={{ mt: 2 }}
            >
              SUBMIT REVIEW
            </Button>
          </Grid>
        </Grid>
      </Container> : 
      <Box sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}>
      <Typography variant="h4" color="#78c223">
        Your feedback has been submitted! 😊
      </Typography>
      <Typography variant="h6" color="textSecondary">
        We look forward to serving you again soon.
      </Typography>
    </Box>}
       
    </div>
  );
};

export default ReviewPage;
