import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";
import React, { useState, useEffect } from "react";
import { Button, Container, Box, Grid, Typography } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Utils from "../core/Utils";
import UserHelper from "../core/UserHelper";
import Loader from "./common/Loader";
import MessagePopup from "./common/MessagePopUp";
import SideBarMenuItems from "./SideBarMenuItems";
import ConfirmPopup from "./common/ConfirmPopup";
import SettingsMenuItems from "./SettingsMenuItems";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
  SideBarMenuNames,
  SettingsMenuList,
  ConfirmTypes,
} from "../core/Constants";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { GridToolbarQuickFilter, GridToolbarContainer } from "@mui/x-data-grid";
import { connectToStore } from "../data/store";
import EditIcon from "@mui/icons-material/Edit";
//import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  LoginPage,
  AddNewBranchPage,
  BranchSetupPage,
} from "../core/PageConstants";
import { Stack } from "@mui/system";

const BranchSetup = (props) => {
  document.title = "Branch Setup";
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [localSettings, setLocalSettings] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [confirmFor, setConfirmFor] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [branches, setBranches] = useState([]);
  const columns = [
    {
      field: "branchName",
      headerName: "Name",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "headerStyle",
      width: 100,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon fontSize="large" />}
            label="Edit"
            className="textPrimary"
            onClick={() => {
              handleEditClick(row);
            }}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={() => {handleDeleteClick(row)}}
          //   color="inherit"
          // />,
        ];
      },
    },
  ];

  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(BranchSetupPage, navigate, localSetting)) {
      setLocalSettings(localSetting);
      if (
        !Utils.IsNullOrEmptyObject(localSetting.business) &&
        !Utils.IsNullOrEmptyArray(localSetting.business.branchLocations)
      ) {
        setBranches(localSetting.business.branchLocations.filter((branchlocation) => branchlocation.branchId !== null));
      }
    }
  }, []);

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
    }
  };

  const handleAddBranchClick = () => {
    if (
      localSettings.business.branchLocations.length >=
      localSettings.business.maximumAllowedBranches
    ) {
      setMsgText(
        "Maximum number of branches limit reached. Please contact Infopoint support team to increase the number of branches."
      );
      setMsgOpen(true);
    } else {
      navigate(AddNewBranchPage.Path);
    }
  };

  const handleEditClick = (branch) => {
    navigate(AddNewBranchPage.Path, { state: { selectedBranch: branch } });
  };

  const handleDeleteClick = (branch) => { };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
        />
        <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.BusinessSetup} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <SettingsMenuItems
                selectedMenu={SettingsMenuList.BusinessSettings.BranchSetup}
              />
              <Grid xs={12} md={9}>
                <Grid spacing={2} className="form-sec xs-pd-0">
                  <Stack className="text-end mb-2">
                    <Link
                      to="javascript:void(0)"
                      onClick={() => {
                        handleAddBranchClick();
                      }}
                    >
                      <Button variant="contained" className="btn btn-sm btn-primary">
                        <AddCircleOutlineOutlinedIcon
                            fontSize="large"
                            sx={{marginRight: "6px"}}
                          />
                        <span>Add New</span>
                      </Button>
                    </Link>
                  </Stack>
                <Grid item xs={12} className="font-size-14 data-grid-box">
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <DataGrid
                      getRowId={(row) => row.branchId}
                      rows={branches}
                      columns={columns}
                      disableColumnFilter={true}
                      disableColumnMenu={true}
                      rowSelection={false}
                      sx={{
                        boxShadow: 1,
                        border: 1,
                        borderColor: "primary.light",
                        "& .MuiDataGrid-cell:hover": {
                          color: "primary.main",
                        },
                      }}
                      slots={{ toolbar: CustomToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                          showFilters: false,
                        },
                      }}
                      className="data-grid"
                      pageSizeOptions={[]}
                      pagination={false}
                      sortModel={[
                        {
                          field: 'branchName', // Replace 'yourColumnName' with the actual column field name you want to sort by
                          sort: 'asc', // 'asc' for ascending order, 'desc' for descending order
                        },
                      ]}
                    />
                  </Box>
                </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter className="searchbar" />
    </GridToolbarContainer>
  );
}

export default BranchSetup;
