import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle, TextField, Typography, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState } from "react";

const AuthenticationDialog = ({
    open,
    POSId,
    authorizationPassword,
    setPOSId,
    setAuthorizationPassword,
    handleClose,
    checkUserAuthentication
}) => {
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle id="customized-dialog-title">
                <Typography fontWeight="600" fontSize="1.5rem">
                    Authentication Required
                </Typography>
            </DialogTitle>

            <DialogContent dividers>
                <Stack aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    justifyContent="space-between"
                    className="font-size-14"
                    py={1}>

                    <Stack spacing={2}>
                        <FormControl fullWidth>
                            <TextField
                                label="POS Id"
                                type="text"
                                value={POSId}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value) && value.length <= 3) {
                                        setPOSId(value);
                                    }
                                }}
                                inputProps={{
                                    maxLength: 3,
                                }}
                            />
                        </FormControl>

                        <FormControl className="mt-1 font-size-16" variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Authorization Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showCurrentPassword ? "text" : "password"}
                                value={authorizationPassword}
                                onChange={(e) => setAuthorizationPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            tabIndex={-1}
                                            aria-label={showCurrentPassword ? "Hide password" : "Show password"}
                                            onClick={() => setShowCurrentPassword((show) => !show)}
                                            edge="end"
                                        >
                                            {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Authorization Password"
                            />
                        </FormControl>
                    </Stack>
                </Stack>
            </DialogContent>

            <Box className="modal-footer">
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <FormControl>
                        <Button
                            className="btn btn-secondary"
                            variant="outlined"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </FormControl>
                    <FormControl>
                        <Button
                            className="btn btn-primary"
                            variant="outlined"
                            onClick={checkUserAuthentication}
                        >
                            Confirm
                        </Button>
                    </FormControl>
                </Stack>
            </Box>
        </Dialog>
    );
};

export default AuthenticationDialog;