import React, { useState, useEffect } from "react";
import "../../Style.css";
import Utils from "../../core/Utils";
import UserHelper from "../../core/UserHelper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connectToStore } from "../../data/store";
import { postAsync } from "../../core/serviceClient";
import MessagePopup from "../common/MessagePopUp";
import InvoiceTypePopup from "../common/InvoiceTypePopup";
import SideBarMenuItems from "../SideBarMenuItems";
import ConfirmPopup from "../common/ConfirmPopup";
import SettingsMenuItems from "../SettingsMenuItems";
import ReportMenuItems from "../ReportMenuItems";
import {
  SideBarMenuNames,
  SettingsMenuList,
  ConfirmTypes,
  InvoiceFilters,
  MaxDateLimit,
} from "../../core/Constants";

import {
  Switch,
  TextField,
  Typography,
  Chip,
  Button,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { FormControl, FormControlLabel } from "@mui/material";
import { Grid, Box, Container, Stack, Paper } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Loader from "../common/Loader";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { InvoiceReportPage, LoginPage } from "../../core/PageConstants";
import CountryHelper from "../../core/CountryHelper";

const InvoiceReport = (props) => {
  document.title = "Invoice Report";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  // const [filters, setFilters] = useState([
  //   { text: InvoiceFilters.All, isActive: true },
  //   { text: InvoiceFilters.GSTInvoices, isActive: false },
  //   { text: InvoiceFilters.NonGSTInvoices, isActive: false },
  // ]);
  const [invoiceLabelFilter, setInvoiceLabelFilter] = useState(false);
  const [invoiceLabel, setInvoiceLabel] = useState("");
  const [invoiceLabels, setInvoiceLabels] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [tempInvoiceList, setTempInvoiceList] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");

  const checkAuthentication = () => {
    if (
      UserHelper.CheckPermission(InvoiceReportPage, navigate, localSettings)
    ) {
      invoiceReportInit(selectedDate[0], selectedDate[1]);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  useEffect(() => {
    filterInvoice();
  }, [
    invoiceList,
    //filters,
    invoiceLabelFilter,
    invoiceLabel,
  ]);

  useEffect(() => {
    invoiceFilterBySearchText();
  }, [tempInvoiceList, searchText]);

  const handleInvoiceClose = (value) => {
    setInvoiceOpen(false);
    if (value == ConfirmTypes.OK || value == ConfirmTypes.CANCEL) {
      var country = CountryHelper.GetAPICulture(localSettings.country);
      var url =
        Utils.GetServiceUrl() +
        "home/invoice?culture=" +
        country.Culture +
        "&bid=" +
        selectedInvoice.businessId +
        "&cid=" +
        selectedInvoice.customerId +
        "&vid=" +
        selectedInvoice.id +
        "&split=" +
        (value == ConfirmTypes.OK);
      window.open(encodeURI(url));
    }
  };
  const handleInvoiceClick = (event, data) => {
    setSelectedInvoice(data);
    setInvoiceOpen(true);
  };

  // const chipClicked = (index) => {
  //   let tempFilters = [...filters];
  //   tempFilters.forEach((element, i) => {
  //     if (i == index) element.isActive = true;
  //     else element.isActive = false;
  //   });
  //   setFilters(tempFilters);
  // };
  const handleInvoiceLabelChange = (event) => {
    setInvoiceLabel(event.target.value);
  };
  const handleInvoiceLabelFilterChange = (event) => {
    setInvoiceLabelFilter(event.target.checked);
  };
  const handleInvoiceCheckChange = (event, index) => {
    var tempInvoices = [...invoices];
    tempInvoices[index].isChecked = event.target.checked;
    setInvoices(tempInvoices);
    var tempSelectedInvoices = selectedInvoices;
    if (event.target.checked) {
      tempSelectedInvoices.push(tempInvoices[index]);
    } else {
      tempSelectedInvoices = tempSelectedInvoices.filter(
        (item) => item.id !== tempInvoices[index].id
      );
    }
    setSelectedInvoices(tempSelectedInvoices);
    if (
      tempSelectedInvoices.length > 0 &&
      tempSelectedInvoices.length == tempInvoices.length
    )
      setSelectAll(true);
    else setSelectAll(false);
  };

  const handleSelectAllCheckChange = (event) => {
    setSelectAll(event.target.checked);
    var tempInvoices = invoices.map((item) => {
      return { ...item, isChecked: event.target.checked };
    });
    if (event.target.checked) setSelectedInvoices(tempInvoices);
    else setSelectedInvoices([]);
    setInvoices(tempInvoices);
  };
  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      if (dateArray[0]._isValid == false || dateArray[1]._isValid == false) {
        setMsgText(
          "Please select a valid date range."
        );
        setMsgOpen(true);
      }
      else {
        let dateDiff = dateArray[1].diff(dateArray[0], "days");
        if (dateDiff > MaxDateLimit) {
          setMsgText(
            "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
          );

          setMsgOpen(true);
        } else {
          setSelectedDate(dateArray);
          invoiceReportInit(dateArray[0], dateArray[1]);
        }
      }
    }
  };

  const invoiceReportInit = async (startDT, endDT) => {
    if (localSettings) {
      setIsLoading(true);
      setEmailId(localSettings.user.emailId);
      setInvoiceLabels(localSettings.branch.invoiceLabels);
      var sDate = Utils.GetISODateTimeString(startDT);
      var eDate = Utils.GetISODateTimeString(endDT);
      let response = await postAsync(
        "Customer/GetCustomerVisit",
        {},
        "",
        "GetAll",
        "AllInvoicesForPeriod",
        [localSettings.business.id, localSettings.branch.branchId, sDate, eDate]
      );
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgOpen(true);
        setMsgText(response.errorMessage);
      } else {
        setInvoiceList(response.data);
      }
      setIsLoading(false);
    }
  };

  const filterInvoice = () => {
    var tempInvoices = invoiceList;
    // var filter = filters.find((f) => f.isActive == true);
    // if (filter.text == InvoiceFilters.GSTInvoices) {
    //   tempInvoices = tempInvoices.filter((item) => item.tax > 0);
    // }
    // else if (filter.text == InvoiceFilters.NonGSTInvoices) {
    //   tempInvoices = tempInvoices.filter((item) => item.tax == 0);
    // }
    if (invoiceLabelFilter && invoiceLabel) {
      tempInvoices = tempInvoices.filter(
        (item) => item.invoiceLabelDescription == invoiceLabel
      );
    }
    setTempInvoiceList(tempInvoices);
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const invoiceFilterBySearchText = () => {
    if (searchText) {
      var tempInvoices = tempInvoiceList;
      var upperCaseSearch = Utils.ConvertToUpperCase(searchText);
      tempInvoices = tempInvoices.filter(
        (item) =>
          Utils.ConvertToUpperCase(item.invoiceNumber).includes(
            upperCaseSearch
          ) ||
          Utils.ConvertToUpperCase(item.customerId).includes(upperCaseSearch) ||
          Utils.ConvertToUpperCase(item.customerName).includes(upperCaseSearch)
      );
      setInvoices(tempInvoices);
    } else setInvoices(tempInvoiceList);
  };

  const handleEmailClicked = async () => {
    if (!Utils.IsEmailValid(emailId)) {
      setIsNavigateToLogin(false);
      setMsgText("Please enter a valid email ID.");
      setMsgOpen(true);
      return false;
    } else if (isInvoiceSelected()) {
      setIsLoading(true);
      var visitIds = selectedInvoices.map((v) => v.id);
      let response = await postAsync("Customer/EmailInvoice", {
        BusinessId: localSettings.business.id,
        CompanyName: localSettings.business.companyName,
        EmailIds: [emailId],
        VisitIds: visitIds,
      });
      setIsLoading(false);
      setIsNavigateToLogin(response.isNavigateToLogin);
      let errorMsg = "Unable to process your request.";
      if (response.error) {
        errorMsg = response.errorMessage;
      } else if (response.data && response.data.success) {
        errorMsg = "Email sent successfully.";
      }
      setMsgOpen(true);
      setMsgText(errorMsg);
    }
  };
  const handlePrintClicked = () => {
    if (isInvoiceSelected()) {
      //TODO :Print invoice
    }
  };
  const isInvoiceSelected = () => {
    if (!Utils.IsNullOrEmptyArray(selectedInvoices)) return true;
    else {
      setIsNavigateToLogin(false);
      setMsgText("Please select at least one invoice before proceeding.");
      setMsgOpen(true);
      return false;
    }
  };

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <InvoiceTypePopup
          invoiceOpen={invoiceOpen}
          onInvoiceClose={handleInvoiceClose}
        />
        <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.InvoiceReport} />
            <Grid xs={12} className="content-sec">
              <Grid container direction="row" className="">
                <ReportMenuItems
                  selectedMenu={SettingsMenuList.BusinessReports.Invoice}
                />
                <Grid xs={12} md={9}>
                  <Grid spacing={2} className="form-sec" paddingBottom="140px">
                    <Stack
                        alignSelf="center"
                        direction="row"
                        flex="1"
                        className="mb-2"
                        style={{ height: "100%", width: "100%" }}
                        justifyContent="space-between"
                    >
                      <Stack direction="row" spacing={2}>
                        <Box alignSelf="center">
                          <Switch
                            color="primary"
                            onChange={handleInvoiceLabelFilterChange}
                          />
                        </Box>
                        <Typography
                          variant="h6"
                          style={{ "white-space": "nowrap", marginLeft: "0" }}
                          alignSelf="center"
                        >
                          Filter by invoice label
                        </Typography>
                        <Stack direction="row" spacing={2} justifyContent="end">
                          {invoiceLabelFilter && (
                            <FormControl fullWidth sx={{width: "300px"}} className="font-size-14">
                              <InputLabel id="invoice-label">
                                Select Label
                              </InputLabel>
                              <Select
                                value={invoiceLabel}
                                labelId="invoice-label"
                                label="Select Label"
                                onChange={handleInvoiceLabelChange}
                              >
                                {!Utils.IsNullOrEmptyArray(invoiceLabels) &&
                                  invoiceLabels.map((row) => (
                                    <MenuItem value={row.description}>
                                      {row.description}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          )}
                        </Stack>
                      </Stack>
                      <Grid>
                        <Stack direction="row" style={{ height: "100%" }}>
                          <DateRangePicker
                            showLeadingZeros={true}
                            onChange={onDateChange}
                            maxDate={new Date()}
                            value={selectedDate}
                            locale={CountryHelper.GetDateCulture(localSettings.country.Culture)}
                            clearIcon={null}
                            calendarIcon={
                              <CalendarMonthOutlinedIcon
                                style={{
                                  "font-size": "24px",
                                  "align-self": "center",
                                  color: "#666666",
                                }}
                              />
                            }
                          />
                        </Stack>
                      </Grid>
                    </Stack>

                    {/* <Grid item className="invoice-sec">
                      <Stack direction="row" spacing={2}>
                        {!Utils.IsNullOrEmptyArray(filters) &&
                          filters.map((filter, index) => (
                            <Chip
                              label={
                                <Typography variant="h6">{filter.text}</Typography>
                              }
                              style={{ padding: "5px 20px" }}
                              onClick={() => chipClicked(index)}
                              className={
                                filter.isActive
                                  ? "btn btn-primary white-text"
                                  : "btn btn-secondary"
                              }
                            />
                          ))}
                      </Stack>
                    </Grid> */}
                    <Grid xs={12} container className="font-size-14">
                      <Grid container xs={12}>
                        <Grid xs={6} alignSelf="end">
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                                  checked={selectAll}
                                  onChange={handleSelectAllCheckChange}
                                />
                              }
                              label={
                                <Typography variant="h5">Select All</Typography>
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid xs={6} alignSelf="end">
                          <TextField
                            className="form-control font-size-14 searchInput"
                            value={searchText}
                            onChange={handleSearchTextChange}
                            placeholder="Search..."
                            style={{ "min-width": "180px" }}
                          />
                        </Grid>
                      </Grid>
                      <TableContainer
                        alignItems="stretch"
                        component={Paper}
                        className="invoice-table mt-1"
                      >
                        <Table aria-label="simple table">
                          <TableBody>
                            {!Utils.IsNullOrEmptyArray(invoices) ? (
                              invoices.map((row, index) => (
                                <TableRow
                                  key={row.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  onClick={(e) => handleInvoiceClick(e, row)}
                                >
                                  <TableCell component="td" scope="row">
                                    <Stack direction="row" alignItems="center">
                                      <Checkbox
                                        checked={!!row.isChecked}
                                        onChange={(e) =>
                                          handleInvoiceCheckChange(e, index)
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                        inputProps={{ "aria-label": "controlled" }}
                                      />
                                      <Typography fontWeight="600">
                                        {row.invoiceLabelDescription +
                                          "-" +
                                          row.invoiceNumber}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell component="td" scope="row">
                                    <Stack direction="column" alignContent="center">
                                      <Typography>{row.customerInfo}</Typography>
                                      <Typography>
                                        {Utils.GetDate(
                                          row.checkOut,
                                          localSettings.country
                                        )}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    align="right"
                                  >
                                    {Utils.GetCurrency(
                                      row.paymentAmount,
                                      localSettings.country
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  <Typography>No data found...</Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    
                    <Stack
                      direction="row"
                        className="fixed-btn"
                      >
                        <Grid container className="container"
                          justifyContent="end"
                      >
                        <Grid xs={12} md={3}></Grid>
                        <Grid xs={12} md={9}>
                          <Stack direction="row" justifyContent="end" spacing={1} className="font-size-14">
                            <FormControl fullWidth>
                              <TextField
                                label="Email"
                                value={emailId}
                                type="email"
                                onChange={(e) => {
                                  setEmailId(e.target.value);
                                }}
                              />
                            </FormControl>
                            <Stack
                              direction="row"
                              xs={12}
                              justifyContent="end"
                              alignSelf="center"
                            >
                              <FormControl>
                                <Button
                                  variant="contained"
                                  className="btn btn-primary"
                                  onClick={handleEmailClicked}
                                >
                                  Send Email
                                </Button>
                              </FormControl>
                              {/* <FormControl>
                                  <Button
                                      variant="contained"
                                      style={{ backgroundColor: "gray" }}
                                      onClick={handlePrintClicked}
                                  >
                                      Print
                                  </Button>
                              </FormControl> */}
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default InvoiceReport;
