import React, { useState } from "react";
import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";
import {
  TextField,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  Card,
  Chip
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { connectToStore } from "../data/store";
import {
  SideBarMenuNames,
} from "../core/Constants";
import SideBarMenuItems from "./SideBarMenuItems";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// Images
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';

// Menu
import SearchIcon from "@mui/icons-material/Search";

import { AddNewCampaignPage } from "../core/PageConstants";
import { display } from "@mui/system";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Campaigns = (props) => {
  document.title = "Campaigns";
  const navigate = useNavigate();

  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [searchText, setSearchText] = useState("");
  const [value, setValue] = React.useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Container maxWidth="false" className="bg-color p-0 setting-page">
      <Grid className="container">
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Campaigns} />
          <Grid xs={12} className="content-sec" style={{ "padding-bottom": "140px" }}>
            <Grid container direction="row" className="">
              <Grid flex="1" spacing={2} >
                <Grid container className="title-sec">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title text-center"
                  >
                    Campaigns - 0 <span style={{ "font-size": "12px", "font-weight": "400" }}>(found)</span>
                  </Typography>
                  <form
                    method="post"
                    className="search-form fixed-xs-btn"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    alignSelf="center"
                  >
                    <Stack
                      direction="row"
                      alignSelf="center"
                      spacing={1}
                      style={{ width: "100%" }}
                      className="mr-1"
                    >
                      <Grid flex="1">
                        <TextField
                          className="form-control font-size-14"
                          placeholder="Search campaigns..."
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          style={{ "min-width": "300px" }}
                        />
                      </Grid>
                      <Grid>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          className="submitBtn btn-primary h-100"
                          fullWidth="true"
                        >
                          <SearchIcon sx={{ fontSize: 24 }} />
                          <span className="btn-text">Search</span>
                        </Button>
                      </Grid>
                    </Stack>
                    <Stack alignSelf="center">
                      <Link
                        to={AddNewCampaignPage.Path}
                        className="btn btn-primary float-btn"
                        variant="contained"
                        style={{ display: "flex" }}
                      >
                        <LibraryAddOutlinedIcon
                          fontSize="large"
                          alignSelf="center"
                        />
                        <span className="hidden-xs ml-h">Create</span>
                      </Link>
                    </Stack>
                  </form>
                </Grid>

                <Grid container direction="row">
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="font-size-14">
                      <Tabs value={value} onChange={handleChange} aria-label="campaigns tabs">
                        <Tab label="All" {...a11yProps(0)} />
                        <Tab label="Active" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                      <Box sx={{ flexGrow: 1 }} py={3}>
                        <Grid container xs={12} spacing={2}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Card sx={{ boxShadow: "none" }}>
                              <Stack className="campaign-img" sx={{ backgroundImage: "url('https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/ea838e220913701.Y3JvcCwxNjE2LDEyNjQsMCww.png')" }}></Stack>
                              <Stack p={2}>
                                <Stack justifyContent="space-between" flexDirection="row">
                                  <Typography
                                    component="h4"
                                    fontSize="1.2rem"
                                    className="text-muted">
                                    Mar 24, 2025 - Apr 24, 2025
                                  </Typography>
                                  <Chip label="Active" color="success" alignSelf="end" />
                                </Stack>
                                <Typography
                                  component="h3"
                                  fontSize="2rem"
                                  fontWeight="600">
                                  Summer Bonanza Offer
                                </Typography>
                              </Stack>
                              <Grid container className="text-center" p={2} sx={{ borderTop: 1, borderColor: 'divider' }}>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Invited
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Participants
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Revenue
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Profit
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Card sx={{ boxShadow: "none" }}>
                              <Stack className="campaign-img" sx={{ backgroundImage: "url('https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/4a5b66220283929.Y3JvcCw0Nzk0LDM3NTAsMCww.jpg')" }}></Stack>
                              <Stack p={2}>
                                <Stack justifyContent="space-between" flexDirection="row">
                                  <Typography
                                    component="h4"
                                    fontSize="1.2rem"
                                    className="text-muted">
                                    Mar 24, 2025 - Apr 24, 2025
                                  </Typography>
                                  <Chip label="Active" color="success" alignSelf="end" />
                                </Stack>
                                <Typography
                                  component="h3"
                                  fontSize="2rem"
                                  fontWeight="600">
                                  Summer Bonanza Offer
                                </Typography>
                              </Stack>
                              <Grid container className="text-center" p={2} sx={{ borderTop: 1, borderColor: 'divider' }}>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Invited
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Participants
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Revenue
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Profit
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Card sx={{ boxShadow: "none" }}>
                              <Stack className="campaign-img" sx={{ backgroundImage: "url('https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/232efe221245977.Y3JvcCwxMzk4LDEwOTQsMCww.jpg')" }}></Stack>
                              <Stack p={2}>
                                <Stack justifyContent="space-between" flexDirection="row">
                                  <Typography
                                    component="h4"
                                    fontSize="1.2rem"
                                    className="text-muted">
                                    Mar 24, 2025 - Apr 24, 2025
                                  </Typography>
                                </Stack>
                                <Typography
                                  component="h3"
                                  fontSize="2rem"
                                  fontWeight="600">
                                  Summer Bonanza Offer
                                </Typography>
                              </Stack>
                              <Grid container className="text-center" p={2} sx={{ borderTop: 1, borderColor: 'divider' }}>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Invited
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Participants
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Revenue
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Profit
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        </Grid>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <Box sx={{ flexGrow: 1 }} py={3}>
                        <Grid container xs={12} spacing={2}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Card sx={{ boxShadow: "none" }}>
                              <Stack className="campaign-img" sx={{ backgroundImage: "url('https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/ea838e220913701.Y3JvcCwxNjE2LDEyNjQsMCww.png')" }}></Stack>
                              <Stack p={2}>
                                <Stack justifyContent="space-between" flexDirection="row">
                                  <Typography
                                    component="h4"
                                    fontSize="1.2rem"
                                    className="text-muted">
                                    Mar 24, 2025 - Apr 24, 2025
                                  </Typography>
                                  <Chip label="Active" color="success" alignSelf="end" />
                                </Stack>
                                <Typography
                                  component="h3"
                                  fontSize="2rem"
                                  fontWeight="600">
                                  Summer Bonanza Offer
                                </Typography>
                              </Stack>
                              <Grid container className="text-center" p={2} sx={{ borderTop: 1, borderColor: 'divider' }}>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Invited
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Participants
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Revenue
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Profit
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Card sx={{ boxShadow: "none" }}>
                              <Stack className="campaign-img" sx={{ backgroundImage: "url('https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/4a5b66220283929.Y3JvcCw0Nzk0LDM3NTAsMCww.jpg')" }}></Stack>
                              <Stack p={2}>
                                <Stack justifyContent="space-between" flexDirection="row">
                                  <Typography
                                    component="h4"
                                    fontSize="1.2rem"
                                    className="text-muted">
                                    Mar 24, 2025 - Apr 24, 2025
                                  </Typography>
                                  <Chip label="Active" color="success" alignSelf="end" />
                                </Stack>
                                <Typography
                                  component="h3"
                                  fontSize="2rem"
                                  fontWeight="600">
                                  Summer Bonanza Offer
                                </Typography>
                              </Stack>
                              <Grid container className="text-center" p={2} sx={{ borderTop: 1, borderColor: 'divider' }}>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Invited
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Participants
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Revenue
                                  </Typography>
                                </Grid>
                                <Grid xs={12} sm={3} md={3}>
                                  <Typography
                                    component="h3"
                                    fontSize="1.8rem"
                                    fontWeight="600">
                                    100
                                  </Typography>
                                  <Typography
                                    component="h4"
                                    fontSize="1.3rem"
                                    className="text-muted">
                                    Profit
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        </Grid>
                      </Box>
                    </CustomTabPanel>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Campaigns;
