import React, { useState, useEffect } from "react";
import {
  Stack,
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  Container,
  Grid,
  Box,
  Modal,
  Typography,
  IconButton,
  Tooltip
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppSettings from "../appsettings.json";
import Utils from "../core/Utils";
import { postAsync, postAsyncUnauthorized } from "../core/serviceClient";
import { useDispatch } from "react-redux";
import { connectToStore } from "../data/store";
import {
  setLogin,
  setToken,
  getEnterpriseId,
  setCountry
} from "../data/localSettingsActions";
import MessagePopup from "./common/MessagePopUp";
import ConfirmPopup from "./common/ConfirmPopup";
import { India, HideRegisteration, Enterprises, AlertSMSNotSupported } from "../core/Constants";
import CountryHelper from "../core/CountryHelper";
import Loader from "./common/Loader";
import NumericTextField from "./common/controls/NumericTextField";
import { CanUseWebApp } from "../core/ModuleHelper";
import Infopoint_Logo from "../images/Infopoint.png";
import support_black from "../images/support_black.png";
import up_arrow from "../images/up-arrow.png";
import IndiaFlag from "../images/IndiaFlag.png";
import UAE from "../images/UAE.png";
import { LandingPage, OverviewPage, RegisterPage } from "../core/PageConstants";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import c_pro_logo from "../images/clients/c_pro.png";
import d_daddy_logo from "../images/clients/d_daddy.png";
import d_bull_logo from "../images/clients/d_bull.png";
import carzspa_logo from "../images/clients/carzspa.png";
import vag_master from "../images/clients/vag_master.png";
import h_2_o from "../images/clients/h_2_o.png";
import sk_auto_detailing from "../images/clients/sk_auto_detailing.png";
import mint_auto_works from "../images/clients/mint_auto_works.png";
import elie_logo from "../images/clients/elie.png";
import vervebeauty_logo from "../images/clients/vervebeauty.png";
import nailchicuae_logo from "../images/clients/nail_chic_uae.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from '@mui/material/InputAdornment';


const Login = () => {
  document.body.classList.add("login");
  document.title = "Infopoint";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [loginId, setLoginId] = useState("");
  const [loggedInWith, setLoggedInWith] = useState("Email");
  const [otp, setOTP] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [isMobileLogin, setIsMobileLogin] = useState(false);
  const [loginType, setLoginType] = useState(false);
  const [loginResponse, setLoginResponse] = useState("");
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [loginDisabled, setLoginDisabled] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [countries, setCountries] = useState([]);
  const [loginCountry, setLoginCountry] = useState({});
  const [selectedCountry, setSelectedCountry] = useState({});
  const localSettings = connectToStore();
  const [isContctUs, setIsContctUs] = useState(false);
  const [supportNumber, setSupportNumber] = useState([]);
  const [isShowOTP, setIsShowOTP] = useState(true);
  const [isShowPWD, setIsShowPWD] = useState(false);
  const [loginButton, setLoginButton] = useState("Get OTP");
  const [isShowSignUp, setIsShowSignUp] = useState(false);
  const [loginAuthMode, setLoginAuthMode] = useState("OTP");
  const [loginPassword, setLoginPassword] = useState("");
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [pwdChecked, setPwdChecked] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    processLogin();
  };

  const handleSwitchToPwd = (e) => {
    e.preventDefault();
    setIsShowOTP(false);
    setIsShowPWD(true);
  };

  const handleSwitchToOtp = (e) => {
    e.preventDefault();
    setIsShowOTP(true);
    setIsShowPWD(false);
  };

  const fetchLoginInfo = () => {
    var isDisconnected = localStorage.getItem("IsDisconnected");
    if (localSettings.authenticated && isDisconnected !== "true") {
      navigate(LandingPage.Path);
    }
  };

  useEffect(() => {
    var countries = CountryHelper.GetSupportedCountries();
    if (!Utils.IsNullOrEmptyArray(countries)) {
      setCountries(countries);
      var index = Utils.GetIndex(India.Code, countries, "Code");
      var tempLoginCountry = countries[index > -1 ? index : 0];
      setLoginCountry(tempLoginCountry);
      var tempSelectedCountry = CountryHelper.GetCountryObject(tempLoginCountry.Code);
      setSelectedCountry(tempSelectedCountry);
      setCountry(tempSelectedCountry, dispatch);
    }
    var preferredAuthMode = localStorage.getItem("preferredAuthMode");
    if (preferredAuthMode == "Password") {
      setLoginAuthMode("Password");
      setPwdChecked(true);
      setLoginButton("Sign In");
    } else {
      setLoginAuthMode("OTP");
      setPwdChecked(false);
      setLoginButton("Get OTP");
    }
    console.log(process.env.REACT_APP_SERVICE_URL);
    fetchLoginInfo();
  }, []);

  const handleKeyUp = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      processLogin();
    }
  };
  function loginMobile() {
    setShowOTP(false);
    setLoginId("");
    setLoggedInWith("Mobile");
    setIsMobileLogin(true);
    setLoginType(true);
  }

  function loginEmail() {
    setShowOTP(false);
    setLoginId("");
    setLoggedInWith("Email");
    setLoginType(false);
    setIsMobileLogin(false);
  }

  async function processLogin() {
    setIsLoading(true);
    if (Utils.IsNullOrEmpty(loggedInWith))
      setLoggedInWith(!isMobileLogin ? "Email" : "Mobile");
    if (!showOTP) {
      if (!isMobileLogin && !Utils.IsEmailValid(loginId)) {
        setMsgOpen(true);
        setMsgText("Please enter a valid email address");
      } else if (isMobileLogin && !Utils.IsMobileValid(loginId, selectedCountry)) {
        setMsgOpen(true);
        setMsgText("Please enter a valid mobile number");
      } else await summitLoginDetails();
    } else {
      if (Utils.IsNullOrEmpty(otp) || otp.length != 6) {
        setMsgOpen(true);
        setMsgText("Please enter a valid OTP");
      } else await processOTP();
    }
    setIsLoading(false);
  }
  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == "OK") {
      navigate(RegisterPage.Path);
    }
  };
  async function summitLoginDetails() {
    let enterpriseId = getEnterpriseId();
    var request = {
      LoginId: loginId.toLowerCase(),
      LoggedInWith: loggedInWith,
      Secret: AppSettings.Secret,
      CountryCode: loginCountry.Code,
      OTP: true,
      OTPSender:
        enterpriseId === Enterprises.XUVI ? "Infopoint" : "Ceramic Pro",
      EnterpriseId: enterpriseId,
      LoginAuthMode: loginAuthMode,
      Password: loginPassword
    };

    setOTP("");
    setIsLoading(true);
    let result = await postAsyncUnauthorized("Auth/Login", request);
    setIsLoading(false);
    if (result.error) {
      setMsgOpen(true);
      setMsgText(result.errorMessage);
      return;
    } else if (
      Utils.IsNullOrEmptyObject(result.data) ||
      Utils.IsNullOrEmptyObject(result.data.user)
    ) {
      if (loginAuthMode == "Password" && result.data.passwordAuthSuccess == false) {
        setMsgText("Username or Password not valid, please try again.");
        setMsgOpen(true);
      }
      else if (HideRegisteration) {
        setMsgText(
          "Account not found - Please use Android or iOS device to create an account."
        );
        setMsgOpen(true);
      } else {
        setConfirmText(
          "Account not found - would you like to register for a new account?"
        );
        setConfirmOpen(true);
      }
      return;
    } else if (result.data.admin) {
      setMsgText(
        "Admin feature is not available in web, please use the mobile or desktop."
      );
      setMsgOpen(true);
      return true;
    } else {
      const business = result.data.businessEntityConfig;
      //var webModule = business.enabledModules.find((m) => m.key == "WebApp");
      if (!CanUseWebApp(business)) {
        setMsgText(
          "Web app access is not available for this business. Please contact our support team."
        );
        setMsgOpen(true);
        return;
      }
      var subscriptionEndDate = new Date(business.subscriptionEndDate);
      var trialEndDate = new Date(business.trialEndDate);
      if (
        subscriptionEndDate.getFullYear() != 1 &&
        subscriptionEndDate <= new Date()
      ) {
        setMsgText(
          `Your subscription expired on ${new Date(
            business.subscriptionEndDate
          ).toLocaleDateString()} . Please contact our support team to renew your subscription.`
        );
        setMsgOpen(true);
        return;
      }
      if (
        subscriptionEndDate.getFullYear() == 1 &&
        trialEndDate <= new Date()
      ) {
        setMsgText(
          `Your trial expired on ${new Date(
            business.trialEndDate
          ).toLocaleDateString()} . Please contact our support team to buy a subscription.`
        );
        setMsgOpen(true);
        return;
      }
      if (business.locked) {
        setMsgText(
          "This business is locked from access. Please contact our support team to buy a subscription."
        );
        setMsgOpen(true);
        return;
      }
      if (!result.data.user.active) {
        setMsgText(
          "This user is not active in this business. Please contact your business owner to enable access."
        );
        setMsgOpen(true);
        return;
      }
      var userInfoObj = {
        u_info: {
          userId: result.data.user.id,
          emailId: result.data.user.emailId,
          firstName: result.data.user.firstName,
          language: result.data.user.language,
          lastAccessed: result.data.user.lastAccessed,
        },
      };
      localStorage.setItem("user-info", JSON.stringify(userInfoObj));
      localStorage.setItem(
        "business-entity",
        JSON.stringify(result.data.businessEntityConfig)
      );
      setLoginResponse(result);
      if (loginAuthMode == "Password") {
        if (result.data.passwordAuthSuccess == true) {
          localStorage.setItem("preferredAuthMode", "Password");
          setToken(result.data.token, dispatch);
          setLogin(result.data, dispatch);
          navigate(OverviewPage.Path);
        } else {
          setMsgOpen(true);
          setMsgText("Invalid Password");
        }
      } else {
        setShowOTP(true);
        setLoginButton("Sign In");
      }
    }
  }

  async function processOTP() {
    setToken(loginResponse.data.token, dispatch);
    var request = {
      UserId: loginId.toLowerCase(),
      OTP: otp,
    };
    let result = await postAsync("Account/OTPAuthentication", request, "");
    if (result.data) {
      localStorage.setItem("preferredAuthMode", "OTP");
      setLogin(loginResponse.data, dispatch);
      navigate(OverviewPage.Path);
    } else {
      setMsgOpen(true);
      setMsgText("Invalid OTP");
      setShowOTP(false);
    }
  }
  const handleMsgClose = () => {
    setMsgOpen(false);
  };

  const onSelectCountry = (event) => {
    var tempCountry = event.target.value;
    var countryObj = CountryHelper.GetCountryObject(tempCountry.Code);
    if (!countryObj.SMSEnabled) {
      setMsgOpen(true);
      setMsgText(AlertSMSNotSupported);
      return;
    }
    setSelectedCountry(countryObj);
    setCountry(countryObj, dispatch);
    setLoginCountry(tempCountry);
    setLoginId("");
  };


  const whatsAppContactUs = (number) => {
    if (!Utils.IsNullOrEmpty(number)) {
      let mNumber = number.Code + number.MobileNumber;
      let url = `https://api.whatsapp.com/send?phone=${mNumber.substring(1)}`;
      window.open(encodeURI(url), "_blank");
    }
  };
  const checkSupportNumber = () => {
    if (isContctUs === true) {
      if (Utils.IsNullOrEmptyArray(localSettings.apiWhatsAppSupportNumbers)) {
        setSupportNumber(localSettings.LocalWhatsAppSupportMobileNumber)
      } else {
        let supportNumbers = Object.entries(localSettings.apiWhatsAppSupportNumbers).map(
          ([code, number]) => ({ Code: code, MobileNumber: number })
        );
        setSupportNumber(supportNumbers)
      }
    }
  }
  useEffect(() => {
    checkSupportNumber()
  }, [isContctUs]);


  const handleSignInChange = (event) => {
    setPwdChecked(event.target.checked);
    setShowOTP(false);
    if (event.target.checked == false) {
      setLoginAuthMode("OTP");
      setLoginButton("Get OTP");
    }
    else {
      setLoginAuthMode("Password");
      setLoginButton("Sign In");
    }
  };

  return (
    <Container
      maxWidth="full"
      className="main login-page"
      sx={{ display: "flex", padding: "0px !important", flexDirection: 'column' }}
    >
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <ConfirmPopup
        confirmOpen={confirmOpen}
        confirmText={confirmText}
        onConfirmClose={handleConfirmClose}
      />
      <Modal open={isContctUs}>
        <Box className="cs-modal" style={{ width: "auto" }} padding={2}>
          <Stack direction="row" spacing={2}>
            <FormControl xs={8} fullWidth>
              <Box style={{ overflow: "auto", height: "98%", }}>
                {(!Utils.IsNullOrEmptyArray(supportNumber) &&
                  supportNumber.map((number, index) => (
                    <p key={index} style={{ cursor: "pointer" }}>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        style={{ padding: "10px", borderRadius: "30px", margin: "10px" }}
                        onClick={() => whatsAppContactUs(number)}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: "28px",
                            width: 34,
                          }}
                          alt="Contact us"
                          src={number.Code === India.Code ? IndiaFlag : UAE}
                        />
                        <h4>
                          {number.Code} {number.MobileNumber}
                        </h4>
                      </Stack>
                    </p>
                  ))) || <p className="my-5 text-center" style={{ "min-width": "300px" }}>No WhatsApp support <br /> numbers are available.</p>}
              </Box>
              <FormControl>
                <Button
                  variant="contained"
                  className="btn btn-primary"
                  type="submit"
                  onClick={() => setIsContctUs(false)}
                >
                  OK
                </Button>
              </FormControl>
            </FormControl>
          </Stack>
        </Box>
      </Modal>

      <Grid container xs={12} className="container w-100 my-1 mx-2">
        <Grid xs={6} alignSelf="Center">
          <Stack className="logo-sec" direction="row">
            <Box component="img" src={Infopoint_Logo} />
            <h1>Infopoint</h1>
          </Stack>
        </Grid>
        <Grid xs={6} alignSelf="Center" className="text-right">
          <Button className="btn-primary link-btn"><a href="https://infopoint.com/" target="_blank" alignSelf="Center">Back to Home</a></Button>
        </Grid>
      </Grid>

      <Stack className="login-sec">
        <Grid className="login-bg container-fluid w-100">
          <Grid container xs={12} className="container w-100">
            <Grid xs={12} sm={6} md={6} alignSelf="End" className="login-title">
              <h1 className="h1 text-white">Stay connected with <br /> your business always!</h1>
              <p className="mt-3">
                <a href="https://infopoint.com/" target="_blank" className="white-text">Explore more <Box component="img" sx={{ height: 16, width: 16, }} style={{ cursor: "pointer" }} alt="Contact us" src={up_arrow} /></a>
              </p>
            </Grid>
            <Grid xs={12} sm={6} md={6} className="login-form">
              <form onSubmit={handleSubmit}>
                <div className="lg-sec form-sec">
                  {isShowSignUp ? (
                    <div className="lg-form">
                      <div className="p-a-0">
                        <h1>Sign up to Infopoint</h1>
                        <h3>
                          Best business management software - Automobiles, Saloon &amp;
                          Spa, Health &amp; Wellness
                        </h3>
                      </div>
                      <div className="p-a-0 m-t-2">
                        <div
                          className="cs-form text-left"
                        >
                          <TextField
                            className="input"
                            type="text"
                            id="name"
                            variant="outlined"
                            label="Name"
                            fullWidth="true"
                            name="name"
                            required
                          />

                          <TextField
                            className="input mt-2"
                            type="email"
                            id="emailId"
                            variant="outlined"
                            label="Email address"
                            fullWidth="true"
                            name="email"
                            required
                          />

                          <TextField
                            className="input mt-2"
                            type="tel"
                            id="mobile"
                            variant="outlined"
                            label="Mobile number"
                            fullWidth="true"
                            name="mobile"
                            required
                          />
                          <div className="mt-3 text-center">
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              size="large"
                              className="submitBtn cs-btn"
                              fullWidth="true"
                            >
                              Continue
                            </Button>
                          </div>
                          <div className="p-a-0 text-center mt-3">
                            <p>
                              Already have an account? <a href="javascript:;" onClick={() => setIsShowSignUp(false)}>Sign in</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="lg-form">
                      <div className="p-a-0 text-center" alignSelf="end">
                        <h1>Sign in to Infopoint</h1>
                      </div>
                      <div className="p-a-0 m-t-5 cs-form text-left">
                        {loginType ? (
                          <Tooltip direction="row" alignItems="center" title="Email address" arrow>
                            <IconButton onClick={loginEmail} className="signinwith-btn">
                              <MailOutlineIcon fontSize="Large" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip direction="row" alignItems="center" title="Mobile number" arrow>
                            <IconButton onClick={loginMobile} className="signinwith-btn">
                              <SmartphoneIcon fontSize="Large" />
                            </IconButton>
                          </Tooltip>
                        )}

                        {isMobileLogin ? (
                          <div>
                            <label className="mb-h">Mobile Number</label>
                            <Stack direction="row" spacing={1}>
                              <FormControl sx={{ m: 1, minWidth: 80 }}>
                                <Select
                                  labelId="country-label"
                                  className="country-option text-center"
                                  id="country"
                                  value={loginCountry}
                                  variant="outlined"
                                  onChange={(e) => onSelectCountry(e)}
                                >
                                  {!Utils.IsNullOrEmptyArray(countries) &&
                                    countries.map((country) => (
                                      <MenuItem s value={country}>
                                        {country.Name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                              <NumericTextField
                                autoComplete="off"
                                id="mobile"
                                className="input"
                                type="tel"
                                variant="outlined"
                                inputProps={{ maxLength: Utils.IsNullOrEmptyObject(selectedCountry) ? 10 : selectedCountry.MobileNumberMaxLength }}
                                fullWidth={true}
                                name="mobile"
                                placeholder="9876543210"
                                value={loginId}
                                autoFocus={true}
                                setValue={setLoginId}
                                onKeyUp={(e) => handleKeyUp(e)}
                              />
                            </Stack>
                          </div>
                        ) : (
                          <div>
                            <label className="mb-h">Email address</label>
                            <TextField
                              autoComplete="on"
                              className="input"
                              id="username"
                              type="email"
                              variant="outlined"
                              placeholder="abc@company.com"
                              fullWidth={true}
                              name="username"
                              value={loginId}
                              autoFocus={true}
                              onChange={(e) => setLoginId(e.target.value)}
                              onKeyUp={(e) => handleKeyUp(e)}
                            />
                          </div>
                        )}

                        {showOTP ? (
                          <div className="mt-1 otp-sec">
                            <>
                              <Stack justifyContent="space-between" direction="row">
                                <Typography component="label" className="mb-h" alignSelf="end">OTP</Typography>
                              </Stack>
                              <NumericTextField
                                type="text"
                                name="otp"
                                className="input mand-fld"
                                id="otp"
                                fullWidth={true}
                                inputProps={{ maxLength: 6 }}
                                variant="outlined"
                                placeholder="Enter the OTP code"
                                autoComplete="off"
                                value={otp}
                                setValue={setOTP}
                                onKeyUp={(e) => handleKeyUp(e)}
                              />
                            </>
                          </div>
                        ) : null}

                        {pwdChecked && (
                          <div className="mt-1">
                            <Stack justifyContent="space-between" direction="row">
                              <Typography component="label" className="mb-h" alignSelf="end">Password</Typography>
                            </Stack>
                            <TextField
                              type={showLoginPassword ? "text" : "password"}
                              name="password"
                              className="input mand-fld"
                              id="password"
                              fullWidth={true}
                              variant="outlined"
                              required
                              placeholder="Enter password"
                              autoComplete="off"
                              value={loginPassword}
                              onChange={(e) => setLoginPassword(e.target.value)}
                              onKeyUp={(e) => handleKeyUp(e)}
                              InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      tabIndex={-1}
                                      aria-label={showLoginPassword ? "Show Password" : "Hide Password"}
                                      onClick={() => setShowLoginPassword((show) => !show)}
                                      edge="end"
                                    >
                                      {showLoginPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </div>
                        )}

                        <Stack justifyContent="End" flexDirection="row" sx={{ alignItems: "center", marginTop: "10px", fontSize: "12px" }}>
                          <span className="mr-1">Sign in using</span>
                          <input type="checkbox" id="toggle" class="toggleCheckbox" checked={pwdChecked} onChange={handleSignInChange} />
                          <label for="toggle" class='toggleContainer'>
                            <div>OTP</div>
                            <div>Password</div>
                          </label>
                        </Stack>
                        <div className="m-t-3 text-center">
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={loginDisabled}
                            className="submitBtn cs-btn"
                            fullWidth={true}
                            onClick={handleSubmit}
                          >
                            {loginButton}
                          </Button>
                        </div>

                        {/* <div className="p-a-0 text-center m-t-1">
                          <p className="m-t-2">
                            New here? <a href="javascript:;" onClick={() => setIsShowSignUp(true)}>Create an account</a>
                          </p>
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Stack>

      <Grid className="container-fluid w-100 px-7 px-2-xs">
        <Grid container className="my-5 clients-logo" justifyContent="Center">
          <Stack direction="row" justifyContent="space-between" className="w-100">
            <Grid xs={6} sm={2} md={2} className="text-center" alignSelf="Center">
              <Box component="img" style={{ cursor: "pointer" }} alt="Ceramic Pro" src={c_pro_logo} />
            </Grid>
            <Grid xs={6} sm={2} md={2} className="text-center" alignSelf="Center">
              <Box component="img" style={{ cursor: "pointer" }} alt="Detailing Daddy" src={d_daddy_logo} />
            </Grid>
            <Grid xs={6} sm={2} md={2} className="text-center" alignSelf="Center">
              <Box component="img" style={{ cursor: "pointer" }} alt="Detailing Bull" src={d_bull_logo} />
            </Grid>
            <Grid xs={6} sm={2} md={2} className="text-center" alignSelf="Center">
              <Box component="img" style={{ cursor: "pointer" }} alt="Carz Spa" src={carzspa_logo} />
            </Grid>
            <Grid xs={6} sm={2} md={2} className="text-center" alignSelf="Center">
              <Box component="img" style={{ cursor: "pointer" }} alt="Elie Lady Salon" src={elie_logo} />
            </Grid>
            <Grid xs={6} sm={2} md={2} className="text-center" alignSelf="Center">
              <Box component="img" style={{ cursor: "pointer" }} alt="Verve beauty lounge" src={vervebeauty_logo} />
            </Grid>
            <Grid xs={6} sm={2} md={2} className="text-center" alignSelf="Center">
              <Box component="img" style={{ cursor: "pointer" }} alt="Nail Chic" src={nailchicuae_logo} />
            </Grid>
          </Stack>
        </Grid>

        <Grid container className="my-5 text-center" justifyContent="Center">

          <h1 className="h1">Stay on top of your<br /> business no matter where<br /> you are, access the App from<br /> your mobile device !!</h1>

        </Grid>

        <Grid container className="my-5 client-sec">
          <Grid xs={12}>
            <h2 className="h2">More than <strong>600+</strong> <br /> trusted customers</h2>
          </Grid>
          <Grid container xs={12} className="mt-2" direction="row" rowGap={2} justifyContent="Center" margin={0}>
            <Grid item xs={12} sm={12} md={3} justifyContent="space-between" flexDirection="column" display="flex" className="card" padding={4} margin={0}>
              <Stack className="text-end" style={{ height: "60px" }}>
                <Box component="img" style={{ cursor: "pointer" }} alt="Vag Master" src={vag_master} alignSelf="Start" />
              </Stack>
              <Stack alignSelf="End">
                <p className="my-2">
                  The developers are willing to take feedback and adjust immediately to it and that’s  an excellent support we get. Very cost effective, Been using it since day 1 and they have changed quite a lot.
                </p>
                <h4>
                  <strong>- Vag Master</strong>
                </h4>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={3} justifyContent="space-between" flexDirection="column" display="flex" className="card" padding={4} margin={0}>
              <Stack className="text-end" style={{ height: "60px" }}>
                <Box component="img" style={{ cursor: "pointer" }} alt="Vag Master" src={h_2_o} alignSelf="Start" />
              </Stack>
              <Stack alignSelf="End">
                <p className="my-2">
                  Was Struggling to maintain customer database and billing, Finally found info point and it was amazing software to maintain billing and customer database. More over it has additional features which is much needed for a business. Customer support and their updates are very much helpful.
                </p>
                <h4>
                  <strong>- H2O</strong>
                </h4>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={3} justifyContent="space-between" flexDirection="column" display="flex" className="card" padding={4} margin={0}>
              <Stack className="text-end" style={{ height: "60px" }}>
                <Box component="img" style={{ cursor: "pointer" }} alt="Vag Master" src={sk_auto_detailing} alignSelf="Start" />
              </Stack>
              <Stack alignSelf="End">
                <p className="my-2">
                  Infopoint was very useful to collect and segregate my customer details along with their vehicles, also very easy to generate bills, add consumables and other inventory details. Great to be their client and they make changes to the app if we give any valid inputs.
                </p>
                <h4>
                  <strong>- SK Auto Detailing</strong>
                </h4>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={3} justifyContent="space-between" flexDirection="column" display="flex" className="card" padding={4} margin={0}>
              <Stack className="text-end" style={{ height: "60px" }}>
                <Box component="img" style={{ cursor: "pointer" }} alt="Vag Master" src={mint_auto_works} alignSelf="Start" />
              </Stack>
              <Stack alignSelf="End">
                <p className="my-2">
                  I find infopoint to be a very useful app. It's quite handy in maintaining service records and also for billing.
                </p>
                <h4>
                  <strong>- Mint Auto Works</strong>
                </h4>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container xs={12} className="footer-sec">
        <Grid xs={12} className="container">
          <Grid container xs={12}>
            <Grid xs={12} sm={12} md={4} className="my-1">
              <Stack
                direction="row"
              >
                &copy; {new Date().getFullYear()}. All Rights Reserved. &nbsp; Powered by
                <a
                  href="https://xuvi.com/"
                  target="_blank"
                  style={{ "margin-left": "3px" }}
                >
                  XUVI
                </a>
              </Stack>
            </Grid>
            <Grid xs={12} sm={12} md={4} className="my-1">
              <Stack direction="row" justifyContent="Center" onClick={() => setIsContctUs(true)}>
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 16,
                  }}
                  style={{ cursor: "pointer", filter: "grayscale(1) invert(1)" }}
                  alt="Contact us"
                  src={support_black}
                /> &nbsp;
                <p style={{ cursor: "pointer" }}>Contact Support</p>
              </Stack>
            </Grid>
            <Grid xs={12} sm={12} md={4} className="my-1 text-end">
              <p>
                <a href="https://xuvi.com/privacy-policy.html" target="_blank">
                  Privacy Policy
                </a>
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
