import React, { useEffect, useState } from "react";
import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import Utils from "../core/Utils";
import FormControl from "@mui/material/FormControl";
import {
  getServiceItemAMCPlan,
  getBackPaymentPopup,
} from "../helpers/productHelper";
import Loader from "./common/Loader";
import Modal from "@mui/material/Modal";
import SideBarMenuItems from "./SideBarMenuItems";
import { AMCPlanStatus, BusinessCategories, SideBarMenuNames } from "../core/Constants";
import { useDispatch } from "react-redux";
import UserHelper from "../core/UserHelper";
import MessagePopup from "./common/MessagePopUp";
import { postAsync } from "../core/serviceClient";
import { getBusiness, getBranch } from "../data/localSettingsActions";
import CloseIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { LoginPage, AMCPlanPage, PaymentPage, CustomersPage } from "../core/PageConstants";
import "../DateRangePickerWithYear.css";
import "react-calendar/dist/Calendar.css";
import BusinessHelper from "../core/BusinessHelper";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import AmountTypography from "./common/controls/AmountTypography";

const AMCPlan = (props) => {
  document.title = "Membership Plan";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [AMCPLanList, setAMCPLanList] = useState([]);
  const [vehicleDetailsList, setVehicleDetailsList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [selectedVehicleList, setSelectedVehicleList] = useState([]);
  const [AMCPlanModalPopup, setAMCPlanModalPopup] = useState(false);
  const [vehicleModelPopup, setVehicleModalPopup] = useState(false);
  const [paymentModelPopup, setPaymentModelPopup] = useState(false);
  const [paymentTotal, setPaymentTotal] = useState(0);
  const [paymentTax, setPaymentTax] = useState(0);
  const [paymentPrice, setPaymentPrice] = useState(0);
  const [isFromCustomerPage, setIsFromCustomerPage] = useState(false);
  const [selectedAMCPlan, setSelectedAMCPlan] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedVehicleDetails, setSelectedVehicleDetails] = useState("");
  const [isSalonBusiness, setIsSalonBusiness] = useState(false);
  const location = useLocation();
  const [searchText, setSearchText] = useState(
    !Utils.IsNullOrEmptyObject(location.state) &&
      !Utils.IsNullOrEmpty(location.state.searchText)
      ? location.state.searchText
      : ""
  );

  useEffect(() => {
    if (UserHelper.CheckPermission(AMCPlanPage, navigate)) {
      fetchAMCPlansDetails();
      if (!Utils.IsNullOrEmptyObject(location.state) && Utils.IsNullOrEmpty(location.state.isAMCSuccess)) {
        if (
          !Utils.IsNullOrEmptyObject(location.state) &&
          !Utils.IsNullOrEmpty(location.state.serviceItems) &&
          !Utils.IsNullOrEmpty(location.state.pass) &&
          !Utils.IsNullOrEmptyObject(location.state.vehicleDetails)
        ) {
          var AMCPLanDetails = location.state.serviceItems;
          var customerPassDetails = location.state.pass;
          var vehicleDetail = location.state.vehicleDetails;
          setSelectedCustomer(customerPassDetails);
          setSelectedVehicleDetails(vehicleDetail);
          var item = getBackPaymentPopup(AMCPLanDetails);
          setSelectedAMCPlan(item);
          setPaymentTax(item.paymentTax);
          setPaymentPrice(item.price)
          setPaymentTotal(item.paymentTotal);

          setPaymentModelPopup(true);
        }
      }
      if (!Utils.IsNullOrEmptyObject(location.state)) {
        setIsFromCustomerPage(location.state.customerpage)
        if (location.state.customerpage === true) {
          var business = getBusiness();
          customerSelection(location.state.pass, business.category === BusinessCategories.Salon);
        }
      }
      if (!Utils.IsNullOrEmpty(searchText)) fetchCustomerSearchList();
    }
  }, []);

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };
  const paymentProcess = () => {
    var item = getServiceItemAMCPlan(selectedAMCPlan);
    navigate(PaymentPage.Path, {
      state: {
        onSuccess: AMCPlanPage.Path,
        navigationStack: [AMCPlanPage.Path],
        isEdit: false,
        fromVisit: false,
        fromAMCPlan: true,
        visit: null,
        checkout: null,
        serviceItems: [item],
        pass: selectedCustomer,
        vehicleDetails: selectedVehicleDetails,
        validVehiclesCount: 0,
        searchText: "",
        // selectedPlan: selectedAMCPlan.id
      },
    });
  };

  async function fetchAMCPlansDetails() {
    setIsLoading(true);
    var business = getBusiness();
    let result = await postAsync("AMC/GetList", business.id);
    setIsNavigateToLogin(result.isNavigateToLogin);
    if (result.error) {
      setMsgOpen(true);
      setMsgText(result.errorMessage);
    } else if (!Utils.IsNullOrEmptyArray(result.data)) {
      getAMCPlans(result.data);
    } else if (Utils.IsNullOrEmptyArray(result.data)) {
      setMsgOpen(true);
      setMsgText("There are no membership plans. Please add membership plan to apply.")
    }
    setIsLoading(false);
  }
  // customer list
  async function fetchCustomerSearchList() {
    if (Utils.IsNullOrEmpty(searchText) == true) return;
    setIsLoading(true);
    const business = getBusiness();
    setIsSalonBusiness(BusinessHelper.IsSalon(business))
    let response = await postAsync("Customer/Search", {
      BusinessId: business.id,
      SearchText: searchText,
      IsAutomobileBusiness: isSalonBusiness === true ? business.category === "Salon" : business.category === "Automobile",
    });
    setIsLoading(false);
    setIsNavigateToLogin(response.isNavigateToLogin);
    if (response.error) {
      setMsgOpen(true);
      setMsgText(response.errorMessage);
    }
    if (!Utils.IsNullOrEmptyArray(response.data.passList)) {
      getCustomerList(
        response.data.passList.sort((a, b) =>
          a.userName > b.userName ? 1 : -1
        )
      );
    } else {
      getCustomerList([]);
    }
  }
  // Refresh AMC plan
  const refreshVisits = () => {
    fetchAMCPlansDetails();
  };
  const handleSubmit = (e) => {
    fetchCustomerSearchList();
  };

  const onClickAMCPlan = (event) => {
    var plan = event.row;
    var taxAmount = plan.price * 1 * (plan.taxPercent / 100);
    var total = taxAmount + plan.price;
    setPaymentTax(taxAmount);
    setPaymentPrice(plan.price)
    setPaymentTotal(total);
    setAMCPlanModalPopup(true);
    setPaymentModelPopup(true);
    setSelectedAMCPlan(plan);
  };

  const customerSelection = (customer, isSalon) => {
    setSelectedCustomer(customer);
    if (!isSalon) {
      setMsgText("Add vehicle information before checkin");
      if (Utils.IsNullOrEmptyArray(customer.vehicleDetails)) {
        //Error Message
        setMsgOpen(true);
        return;
      }
      if (customer.vehicleDetails.length === 1) {
        setSelectedVehicleDetails(customer.vehicleDetails[0]);
        //Payment
        setAMCPlanModalPopup(true);
      } else {
        setSelectedVehicleList(customer.vehicleDetails);
        setVehicleModalPopup(true);
      }
    } else {
      setAMCPlanModalPopup(true);
    }
    getVehicleList(customer.vehicleDetails);
  }
  const onClickCustomer = (event) => {
    customerSelection(event.row, isSalonBusiness)
  };

  const onClickVehicleDetails = (event) => {
    setSelectedVehicleDetails(event.row);
    setVehicleModalPopup(false);
    setAMCPlanModalPopup(true);
  };
  const AMCPlanColumns = [
    {
      field: "name",
      headerName: "Membership Plan Name",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "benefits",
      headerName: "Benefits",
      headerClassName: "headerStyle",
      flex: 1,
      renderCell: (params) => (
        <span>{params.row.benefits ? params.row.benefits.length : 0}</span>
      ),
    },
    {
      field: "price",
      headerName: "Cost",
      headerClassName: "headerStyle",
      flex: 1,
    },
  ];
  async function getAMCPlans(data) {
    var selectBranch = getBranch();
    var result = data.filter(
      (s) =>
        s.active === true &&
        s.status === AMCPlanStatus.Approved &&
        s.applicableBranches.includes(selectBranch.branchId)
    );
    setAMCPLanList(result);
  }
  // Customer List
  const customerListColumns = [
    {
      field: "userName",
      headerName: " Name",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "mobileNumber",
      headerName: "Contact",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "profit",
      headerName: "Profit",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "visits",
      headerName: "Visits",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "points",
      headerName: "Points",
      headerClassName: "headerStyle",
      flex: 1,
    },
  ];
  async function getCustomerList(passList) {
    setCustomerList(passList);
  }
  // vehicle list
  const vehicleListColumns = [
    {
      field: "number",
      headerName: "Vehicle",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "make",
      headerName: "Make",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "model",
      headerName: "Model",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "fuelType",
      headerName: "Fuel",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "chassisNumber",
      headerName: "Chassis No",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "engineNumber",
      headerName: "Engine No",
      headerClassName: "headerStyle",
      flex: 1,
    },
  ];
  async function getVehicleList(selectedVehicleList) {
    setVehicleDetailsList(selectedVehicleList);
  }
  const backNavigation = () => {
    navigate(CustomersPage.Path, {
      state: {
        searchText: searchText,
      },
    });
  };

  var pageTitle = "Membership Plan";

  const CustomNoRowsOverlay = () => {
    return (
      <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="body1" color="textSecondary">
          Select a customer to get the membership plan.
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Container maxWidth="false" className="bg-color p-0">
        <Grid className="container">
          <Loader open={isLoading} />
          <MessagePopup
            msgOpen={msgOpen}
            msgText={msgText}
            onMsgClose={handleMsgClose}
          />
          <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.AMCPlan} />
            <Grid xs={12} className="content-sec" sx={{ "padding-bottom": "100px" }}>
              <Grid container direction="row">
                <Grid
                  flex="1"
                  spacing={2}
                  className="form-sec xs-pd-0"
                >
                  <Grid container className="title-sec">
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="600"
                      fontSize="2rem"
                      className="page-title text-center"
                    >
                      {isFromCustomerPage && (
                        <Button onClick={backNavigation} className="back-btn">
                          <NavigateBeforeIcon />
                        </Button>
                      )}
                      {pageTitle}
                    </Typography>
                    <Grid alignSelf="center">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className="btn btn-secondary refresh-icon"
                        fullWidth="true"
                        onClick={refreshVisits}
                      >
                        <RefreshIcon sx={{ fontSize: 24 }} />
                        <span className="btn-text ml-h">Refresh</span>
                      </Button>
                    </Grid>
                  </Grid>

                  {/* customer table */}
                  <Grid
                    container
                    xs={12}
                    className="font-size-14 data-grid-box"
                  >
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Stack
                        direction="row"
                        alignSelf="center"
                        spacing={1}
                        style={{ width: "100%" }}
                        className="fixed-xs-btn"
                      >
                        <Grid flex="1">
                          <TextField
                            className="form-control font-size-14"
                            placeholder="Search customer..."
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </Grid>
                        <Grid>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleSubmit}
                            className="submitBtn btn-primary"
                            fullWidth="true"
                            style={{ height: "52px" }}
                          >
                            <SearchIcon sx={{ fontSize: 54 }} />
                            <span className="hidden-xs">Search</span>
                          </Button>
                        </Grid>
                      </Stack>
                      <DataGrid
                        getRowId={(row) => row.id}
                        rows={customerList}
                        onCellClick={(e) => onClickCustomer(e)}
                        columns={customerListColumns}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        className="data-grid font-size-14 cs-data-grid mt-1 m-xs-0"
                        slots={{
                          noRowsOverlay: CustomNoRowsOverlay, 
                        }}
                      />
                    </Box>
                  </Grid>

                  {/* AMC plan table */}
                  <Modal open={AMCPlanModalPopup}>
                    <Box className="css-modal" padding={2}>
                      <Stack direction="row" spacing={2}>
                        <FormControl xs={8} fullWidth>
                          <h4 style={{ fontWeight: "bold" }}>
                            Select Membership Plan
                          </h4>
                          <IconButton
                            aria-label="close"
                            onClick={() => setAMCPlanModalPopup(false)}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 0,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </FormControl>
                      </Stack>
                      <Box className="mt-2" style={{ overflow: "auto", height: "90%", }}>
                        <DataGrid
                          getRowId={(row) => row.id}
                          rows={AMCPLanList}
                          onCellClick={onClickAMCPlan}
                          columns={AMCPlanColumns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              },
                            },
                          }}
                          pageSizeOptions={[10]}
                          className="data-grid font-size-14"
                          slots={{ toolbar: CustomToolbar }}
                        />
                      </Box>
                    </Box>
                  </Modal>
                  <Modal open={vehicleModelPopup}>
                    <Box className="css-modal " padding={2}>
                      <Stack direction="row" spacing={2}>
                        <FormControl xs={8} fullWidth>
                          <h4 style={{ fontWeight: "600" }}>Select Vehicle</h4>
                          <IconButton
                            aria-label="close"
                            onClick={() => setVehicleModalPopup(false)}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                          <Box
                            sx={{
                              height: "67%",
                            }}
                          >
                            <DataGrid
                              getRowId={(row) => row.id}
                              rows={vehicleDetailsList}
                              onCellClick={(e) => onClickVehicleDetails(e)}
                              columns={vehicleListColumns}
                              initialState={{
                                pagination: {
                                  paginationModel: {
                                    pageSize: 10,
                                  },
                                },
                              }}
                              pageSizeOptions={[10]}
                              className="data-grid font-size-14  minHeight- 19px,"
                            />
                          </Box>
                        </FormControl>
                      </Stack>
                    </Box>
                  </Modal>
                  <Modal open={paymentModelPopup}>
                    <Box className="css-modal" padding={2}>
                      <Stack>
                        <IconButton
                          aria-label="close"
                          onClick={() => setPaymentModelPopup(false)}
                          sx={{ "align-self": "end" }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                      <Stack direction="column" spacing={2} style={{ marginTop: "20px", padding:"30px" }}>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            className="text-ellipsis"
                          >
                            {selectedAMCPlan.name}
                          </Typography>
                          <AmountTypography
                            variant="h5"
                            fontWeight="600"
                            value={paymentTotal}
                            color="#539b00"
                          />
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" style={{ marginTop: "25px" }}>
                          <Typography fontSize="13px">
                            Selected Customer
                          </Typography>
                          <Typography fontSize="13px">
                            {selectedCustomer.userName}
                          </Typography>
                        </Stack>
                        {!isSalonBusiness && (
                          <>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography fontSize="13px">
                                Vehicle Number
                              </Typography>
                              <Typography fontSize="13px">
                                {selectedVehicleDetails.number}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography fontSize="13px">
                                Make & Model
                              </Typography>
                              <Typography fontSize="13px">
                                {`${selectedVehicleDetails.make} ${selectedVehicleDetails.model}`}
                              </Typography>
                            </Stack>
                          </>
                        )}
                        <Stack direction="row" justifyContent="space-between">
                          <Typography fontSize="13px">Price</Typography>
                          <AmountTypography
                            fontSize="13px"
                            value={paymentPrice}
                          />
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography fontSize="13px">Tax</Typography>
                          <AmountTypography
                            fontSize="13px"
                            value={paymentTax}
                          />
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography fontSize="13px">Total</Typography>
                          <AmountTypography
                            fontSize="13px"
                            color="#539b00"
                            value={paymentTotal}
                          />
                        </Stack>
                        <Grid className="mt-2 modal-footer">
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => paymentProcess()}
                            className="submitBtn btn-primary"
                            fullWidth="true"
                          >
                            <span>Payment</span>
                          </Button>
                        </Grid>
                      </Stack>
                    </Box>
                  </Modal>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          </Box>
        </Grid>
      </Container>
    </>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter className="searchbar" />
    </GridToolbarContainer>
  );
}

export default AMCPlan;
