import React, { useEffect, useState } from "react";
import { connectToStore } from "../data/store";
import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import SideBarMenuItems from "./SideBarMenuItems";
import { SideBarMenuNames } from "../core/Constants";
import UserHelper from "../core/UserHelper";
import { ProfilePage } from "../core/PageConstants";
import Utils from "../core/Utils";
import {
  Box,
  Stack,
  Typography,
  TextField,
  Grid,
  Container,
  Card,
  CardActionArea,
  Modal,
  IconButton,
  Button,
  InputLabel,
  FormControl,
  OutlinedInput
} from "@mui/material";
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from '@mui/material/InputAdornment';
import { postAsync } from "../core/serviceClient";
import Loader from "./common/Loader";
import MessagePopup from "./common/MessagePopUp";
import PasswordStrengthBar from "react-password-strength-bar";

const Profile = (props) => {
  document.title = "Profile";
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isVerificationCode, setIsVerificationCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordAuthMode, setPasswordAuthMode] = useState("");

  useEffect(() => {

  }, []);

  useEffect(() => {
    UserHelper.CheckPermission(ProfilePage, navigate);
  }, []);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const handleResetPwd = (e) => {
    e.preventDefault();
    setIsResetPassword(true);
    setIsVerificationCode(false);
  };

  const passwordAuthModeSelected = async (mode) => {
    setIsLoading(true);
    setPasswordAuthMode(mode);
    if (mode == "OTP") {
      setPasswordAuthMode(mode);
      await sendVerificationCodeForReset();
    }
    else if (mode == "Password") {
      setIsVerificationCode(true);
      setPasswordAuthMode(mode);
    }
    setIsLoading(false);
  }

  const verifyResetPasswordClicked = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMsgText("Passwords do not match.");
      setMsgOpen(true);
      return;
    }

    await resetPassword();
  }

  async function resetPassword() {
    setIsLoading(true);
    if (newPassword != confirmPassword) {
      setMsgText("Passwords do not match.");
      setMsgOpen(true);
      setIsLoading(false);
      return;
    }
    var requestData = {
      "BusinessId": localSettings.business.businessId,
      "UserProfileId": localSettings.user.id,
      "AuthOTP": otp,
      "NewPassword": newPassword,
      "OldPassword": oldPassword,
      "AuthMode": passwordAuthMode
    }
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setOtp("");
    setShowPassword(false);
    setShowConfirmPassword(false);
    setShowCurrentPassword(false);
    var result = await postAsync(
      "account/SetPassword",
      requestData,
      "",
      "Execute",
      "",
      []
    );
    if (result.isSuccess == true) {
      setIsResetPassword(false);
      setMsgText("Password reset successfully.");
      setMsgOpen(true);
    } else {
      setMsgText(passwordAuthMode == "OTP" ? "Invalid verification code." : "Invalid password.");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }
  async function sendVerificationCodeForReset() {
    var requestData = {
      'BusinessId': localSettings.business.businessId,
      'UserProfileId': localSettings.user.id,
    };
    var result = await postAsync(
      "account/ResetPasswordOTP",
      requestData,
      "",
      "Execute",
      "",
      []
    )
    if (result.isSuccess == true)
      setIsVerificationCode(true);
  }

  // const confirmResetPassword = async (e) => {
  //   var requestData = {
  //     "BusinessId": localSettings.business.businessId,
  //     "UserProfileId": localSettings.user.id,
  //     "AuthOTP": e.target.verificationCode.value,
  //     "NewPassword": ""
  //   }
  // }

  return (
    <Container maxWidth="false" className="bg-color p-0 setting-page">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={() => setMsgOpen(false)} />
      <Modal open={isResetPassword}>
        <Box className="custom-prod" padding={4}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              level="h2"
              component="h2"
              fontWeight="600"
              fontSize="2rem"
              alignSelf="center"
            >
              Reset Password
            </Typography>
            <IconButton tabIndex={-1} aria-label="close" onClick={() => setIsResetPassword(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack spacing={2}>
            {isVerificationCode ? (
              <form autoComplete="off">
                <Stack>
                  <Typography
                    level="h4"
                    component="h4"
                    fontWeight="400"
                    fontSize="1.4rem"
                  >
                    Enter the verification code & Reset password
                  </Typography>
                  <br />

                  {passwordAuthMode == "OTP" ? (
                    <><Typography
                      level="h4"
                      component="h4"
                      fontWeight="600"
                      fontSize="1.4rem"
                    >
                      Enter the verification code sent to your login id.
                    </Typography>
                      <TextField
                        autoComplete="off"
                        name="verificationCode"
                        id="verificationCode"
                        label="Code"
                        placeholder="000000"
                        variant="outlined"
                        className="mt-1 font-size-16"
                        onChange={(e) => setOtp(e.target.value)}
                        inputProps={{ maxLength: 6 }} /></>) : ""
                  }
                  {passwordAuthMode == "Password" ? (
                    <><Typography
                      level="h4"
                      component="h4"
                      fontWeight="600"
                      fontSize="1.4rem"
                    >
                      Enter current password
                    </Typography><FormControl className="mt-1 font-size-16" variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showCurrentPassword ? 'text' : 'password'}
                          endAdornment={<InputAdornment position="end">
                            <IconButton tabIndex={-1}
                              aria-label={showCurrentPassword ? 'hide the password' : 'display the password'}
                              onClick={() => setShowCurrentPassword((show) => !show)}
                              edge="end"
                            >
                              {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>}
                          label="Old Password"
                          onChange={(e) => setOldPassword(e.target.value)} />
                      </FormControl></>) : ""
                  }
                  <Typography
                    level="h4"
                    component="h4"
                    className="mt-2"
                    sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                  >
                    <Typography
                      component="span"
                      fontWeight="600"
                      fontSize="1.4rem">Enter new password</Typography>

                    <HtmlTooltip
                      tabIndex={-1}
                      title={
                        <React.Fragment>
                          <Stack spacing={2} padding={2} className="font-size-13">
                            <Typography component="h4" fontSize="1.4rem" fontWeight="600">Password Guidelines</Typography>
                            <Typography color="inherit">The password must be at least eight characters long and can have a maximum length of sixteen characters.</Typography>
                            <Typography color="inherit">The password must have at least one lower, one upper, one special (!#$%^&*) and one number character(s).</Typography>
                          </Stack>
                        </React.Fragment>
                      }
                    >
                      <IconButton tabIndex={-1}>
                        <InfoOutlinedIcon alignSelf="End" />
                      </IconButton>
                    </HtmlTooltip>
                  </Typography>
                  <FormControl className="mt-1 font-size-16" variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      inputProps={{ minLength: 8 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton tabIndex={-1}
                            aria-label={
                              showPassword ? 'hide the password' : 'display the password'
                            }
                            onClick={() => setShowPassword((show) => !show)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="New Password"
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <PasswordStrengthBar password={newPassword} />
                  </FormControl>
                  <FormControl className="mt-1 font-size-16" variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-cpassword">Confirm Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-cpassword"
                      type={showConfirmPassword ? 'text' : 'password'}
                      inputProps={{ minLength: 8 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton tabIndex={-1}
                            aria-label={
                              showConfirmPassword ? 'hide the password' : 'display the password'
                            }
                            onClick={() => setShowConfirmPassword((show) => !show)}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Confirm Password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </FormControl>
                  <Button className="btn btn-primary mt-2" onClick={(e) => verifyResetPasswordClicked(e)}>Verify & Reset Password</Button>
                </Stack>
              </form>
            ) : (
              <Stack>
                <Typography
                  level="h4"
                  component="h4"
                  fontWeight="400"
                  fontSize="1.4rem"
                >
                  Get a Verification Code
                </Typography>
                <br />
                <Typography
                  level="h4"
                  component="h4"
                  fontWeight="400"
                  fontSize="1.4rem"
                >
                  Click the button below, and we'll send a verification code to your registered email address.
                </Typography>
                <Button className="btn btn-primary mt-2" onClick={() => passwordAuthModeSelected("OTP")}>Send Verification Code</Button>

                <Button className="btn btn-primary mt-2" onClick={() => passwordAuthModeSelected("Password")}>Use password</Button>
              </Stack>
            )}
          </Stack>
        </Box>
      </Modal>

      <Grid container className="container">
        <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
        <Grid xs={12} className="content-sec mt-3">
          <Grid container>
            <Grid xs={12} md={4}>
              <Stack>
                {/* <Avatar
                  alt={localSettings.user.firstName}
                  src={`${localSettings.business.logoUrl}?${Date.now()}`}
                  className="profile-pic"
                  style={{"width":"120px", "height":"120px"}}
                /> */}
                <Typography
                  level="h2"
                  component="h2"
                  fontWeight="600"
                  fontSize="2rem"
                  className="h2"
                >
                  {localSettings.user.firstName}
                </Typography>
                <Typography
                  level="p"
                  component="p"
                  fontSize="14px"
                >
                  {localSettings.user.emailId}
                </Typography>
              </Stack>
            </Grid>
            <Grid xs={12} md={8} alignSelf="end" className="text-end">
              <Box className="font-size-14">
                <Typography component="span">Version 2.4.02</Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid className="card white-bg mt-3" p={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Stack className="card h-100" justifyContent="center">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="1.6rem"
                    sx={{ padding: "2rem" }}
                    className="text-center"
                  >
                    {localSettings.business.referralCode}
                    <span>Referral Code</span>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card className="card h-100">
                  <CardActionArea className="h-100">
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="600"
                      fontSize="1.6rem"
                      sx={{ padding: "2rem" }}
                      className="text-center"
                    >
                      <ErrorOutlineOutlinedIcon fontSize="46px" /> <br />
                      <span>Your usage plan ends on</span>
                      <span style={{ "color": "#f00" }}>
                        {" " + Utils.GetDate(
                          localSettings.business.subscriptionEndDate,
                          localSettings.country
                        )}
                      </span>
                    </Typography>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card className="card h-100" onClick={handleResetPwd}>
                  <CardActionArea className="h-100">
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="600"
                      fontSize="1.6rem"
                      sx={{ padding: "2rem" }}
                      className="text-center"
                    >
                      <LockResetOutlinedIcon fontSize="42px" />
                      <span>Reset Password</span>
                    </Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </Container>
  );
};

export default Profile;
