import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React, { useEffect, useReducer, useState } from "react";
import SideBarMenuItems from "./SideBarMenuItems";
import SettingsMenuItems from "./SettingsMenuItems";
import BusinessHelper from "../core/BusinessHelper";
import {
  ConfirmTypes,
  MaxFileSizeLimit,
  SideBarMenuNames,
} from "../core/Constants";
import Utils from "../core/Utils";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import NumericTextField from "./common/controls/NumericTextField";
import SalesInfoComponent from "./common/SalesInfoComponent";
import { serviceItemActionTypes } from "./reducers/serviceItem/serviceItemActionTypes";
import ProductConfigComponent from "./common/ProductConfigComponent";
import { serviceItemReducer } from "./reducers/serviceItem/serviceItemReducer";
import {
  convertProductItemObject,
  getServiceItemProductsFromProduct,
} from "../helpers/productHelper";
import UserHelper from "../core/UserHelper";
import DeleteIcon from "@mui/icons-material/Delete";
import { ImageEnlarge } from "./common/ImageEnlarge";
import { useLocation, useNavigate } from "react-router-dom";
import MessagePopup from "./common/MessagePopUp";
import {
  AddNewPackagePage,
  AddPackagesPage,
  LoginPage,
} from "../core/PageConstants";
import { useDispatch } from "react-redux";
import ConfirmPopup from "./common/ConfirmPopup";
import { connectToStore } from "../data/store";
import { postAsync } from "../core/serviceClient";
import Loader from "./common/Loader";
import { set } from "react-hook-form";

const AddNewPackage = () => {
  const [packageName, setPackageName] = useState("");
  const [packageDescription, setPackageDescription] = useState("");
  const [packageCode, setPackageCode] = useState("");
  const [termsConditions, setTermsConditions] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [packageServiceTime, setPackageServiceTime] = useState("");
  const [packagePostServiceTime, setPackagePostServiceTime] = useState("");
  const [frequentlyused, setfrequentlyused] = useState(false);
  const [active, setActive] = useState(true);
  const [reviewPackageService, setReviewPackageService] = useState(false);
  const [Allowreservation, setAllowreservation] = useState(false);
  const [Blockcalender, setblockcalender] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isDropdown, setIsDropdown] = useState(false);
  const [isDropdown1, setIsDropdown1] = useState(false);
  const [packageCategory, setPackageCategory] = useState("");
  const [Catlist, setCatlist] = useState([]);
  const [Typelist, setTypelist] = useState([]);
  const [categoryDescription, setCategoryDescription] = useState("");
  const [packageType, setPackageType] = useState("");
  const [packageTypeDescription, setPackageTypeDescription] = useState("");
  const [inclusiveOfTax, setInclusiveOfTax] = useState(false);
  const [cost, setCost] = useState("");
  const [priceExclusive, setPriceExclusive] = useState("");
  const [taxPercentage, setTaxPercentage] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [TotalPrice, setTotalPrice] = useState("");
  const [filteredstafflist, setFilteredStafflist] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [enlargeImage, setEnlargeImage] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [selectedPackage, setselectedPackage] = useState(
    location.state && location.state.selectedPackage
      ? location.state.selectedPackage
      : {}
  );
  const [localSettings, setLocalSettings] = useState({});

  const [isProductConfig, setIsProductConfig] = useState(true);
  const [serviceItems, itemDispatch] = useReducer(serviceItemReducer, []);
  const [showDialog, setShowDialog] = useState(false);
  const [confirmFor, setConfirmFor] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isNavigateToPackage, setIsNavigateToPackage] = useState(false);
  const [labour, setLabour] = useState(false);
  const [productStockReportAvailable, setProductStockReportAvailable] = useState(false);
  const [packageProductList, setPackageProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isServiceItemsDeleted, setIsServiceItemsDeleted] = useState(false);

  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(AddNewPackagePage, navigate, localSetting)) {
      setLocalSettings(localSetting);
    }
  },[]);

  useEffect(() => {
    populatePackage();
  }, [selectedPackage]);

  useEffect(() => {
    getPackage();
  }, [localSettings]);

  useEffect(() => {
    if (isServiceItemsDeleted) {
      handleDeletedServiceItems();
    }
  }, [serviceItems]);

  const handleDeletedServiceItems = () => {
    let filterList = packageProductList.filter(
      (item) =>
        !serviceItems.some((serviceItem) => serviceItem.ProductId === item.id)
    );
    setPackageProductList(filterList);
    if (serviceItems.length === 0) {
      setPackageProductList([]);
    }
    setIsServiceItemsDeleted(false);
  };

  const populatePackage = () => {
    if (Utils.IsNullOrEmptyObject(selectedPackage)) {
      return;
    }
    setPackageName(selectedPackage.shortDesc);
    setPackageDescription(selectedPackage.description);
    setActive(selectedPackage.active);
    setCost(selectedPackage.cost);
    setPackageCategory(selectedPackage.class);
    setCategoryDescription(selectedPackage.classDescription);
    setPackageType(selectedPackage.fineline);
    setPackageTypeDescription(selectedPackage.finelineDescription);
    setPriceExclusive(selectedPackage.price);
    setTaxPercentage(selectedPackage.taxPercent);
    setTaxAmount(selectedPackage.retailPrice);
    setfrequentlyused(selectedPackage.default);
    setImageUrl(selectedPackage.imageUrl);
    setAllowreservation(selectedPackage.availableForReservation);
    setPackageServiceTime(selectedPackage.serviceTime);
    setPackagePostServiceTime(selectedPackage.postServiceTime);
    setInclusiveOfTax(selectedPackage.inclusiveOfTax);
    setReviewPackageService(selectedPackage.reviewProduct);
    setTotalPrice(
      Utils.IsNullOrEmpty(selectedPackage.priceIncludingTax)
        ? ""
        : selectedPackage.priceIncludingTax.toFixed(2)
    );
    populatePackageItems(selectedPackage.packageProductsList);
    setPackageCode(selectedPackage.code);
    setPackageProductList(selectedPackage.packageProductsList);
    setTermsConditions(selectedPackage.termsConditions);
  };

  const getPackage = async () => {
    if (localSettings.authenticated) {
      let result1 = await postAsync(
        "Package/GetPackageProduct",
        {},
        "",
        "GetAll",
        "GetAllPackageProductsByBusiness",
        [localSettings.business.id]
      );

      setIsNavigateToLogin(result1.isNavigateToLogin);

      if (result1.error) {
        setMsgOpen(true);
        setMsgText(result1.errorMessage);
      } else {
        if (!Utils.IsNullOrEmptyArray(result1.data)) {
          var Packages = result1.data;
          const temTypellist = [];
          const temCatllist = [];
          Packages.forEach((element) => {
            if (
              !Utils.IsNullOrEmpty(element.fineline) &&
              !temTypellist.includes(element.fineline)
            ) {
              temTypellist.push(element.fineline);
            }
            if (
              !Utils.IsNullOrEmpty(element.class) &&
              !temCatllist.includes(element.class)
            ) {
              temCatllist.push(element.class);
            }
          });
          setTypelist(temTypellist);
          setCatlist(temCatllist);
        }
      }
    }
  };

  const getServiceItemObj = (item) => {
    var packageItem = {
      Description: item.description,
      ProductCode: item.code,
      ProductId: item.id,
      Quantity: 1,
      MeasuringUnit: ""
    }
    return packageItem;
  };

  const populatePackageItems = (selectedProducts) => {
    itemDispatch({
      type: serviceItemActionTypes.Clear,
    });
    if (!Utils.IsNullOrEmptyArray(selectedProducts)) {
      selectedProducts.forEach((product) => {
        var newItem = getServiceItemObj(product);
        if (!Utils.IsNullOrEmpty(newItem)) {
          itemDispatch({
            type: serviceItemActionTypes.Add,
            value: newItem,
          });
        }
      });
    }
  };

  const openImage = () => {
    setEnlargeImage(true);
  };

  const closeImage = () => {
    setEnlargeImage(false);
  };

  const getImgObj = () => {
    return {
      url: Utils.IsNullOrEmpty(imageUrl)
        ? ""
        : imageUrl.startsWith("http")
        ? imageUrl
        : Utils.GetStringFromBase64(imageUrl),
      newFile: !Utils.IsNullOrEmpty(imageUrl) && !imageUrl.startsWith("http"),
    };
  };

  const deletePackagePhoto = () => {
    setMsgText("Service image has been removed");
    setMsgOpen(true);
    setImageUrl("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorMsg = "";
    if (Utils.IsNullOrEmpty(packageName)) {
      errorMsg += "Please enter a proper Package Name.<br />";
    }
    if (Utils.IsNullOrEmpty(packageDescription)) {
      errorMsg += "Please enter a proper Package Description.<br />";
    }
    if (Utils.IsNullOrEmpty(packageCode)) {
      errorMsg += "Please enter a valid Package Code.<br />";
    }
    if (Utils.IsNullOrEmpty(priceExclusive)) {
      errorMsg += "Please enter Price Exclusive Tax.<br />";
    }
    if (Utils.IsNullOrEmptyArray(serviceItems) || serviceItems.length === 1) {
      errorMsg +=
        "Please select atleast two service items to add package.<br />";
    }
    if (!Utils.IsNullOrEmpty(errorMsg)) {
      setMsgText(errorMsg);
      setMsgOpen(true);
    } else {
      if (!Utils.IsNullOrEmptyObject(selectedPackage)) {
        setConfirmFor("Update");
      } else {
        setConfirmFor("Submit");
      }
      setConfirmText("Are you sure you want to save changes?");
      setConfirmOpen(true);
    }
  };

  const handleSwitchChange = (event) => {
    setIsDropdown1(event.target.checked);
    setPackageCategory("");
  };

  const handleSwitchType = (event) => {
    setIsDropdown(event.target.checked);
    setPackageType("");
  };

  const handleCheckboxChange = (event, rows) => {};

  const handlePackagePhoto = (event) => {
    const fileInput = event.target;
    const ext = fileInput.value.match(/\.(.+)$/)[1];

    switch (ext) {
      case "jpg":
      case "jpeg":
      case "png":
        UploadServiceImage(event);
        break;
      default:
        setMsgOpen(true);
        setMsgText("/jpg,/jpeg,/png file types only allowed .");
        fileInput.value = "";
    }
  };

  const UploadServiceImage = async (event) => {
    var file = event.target.files[0];

    if (file.size > MaxFileSizeLimit) {
      setAlertTitle("Warning");
      setMsgText(
        "Unable to upload. File size exceeds the maximum limit, please ensure that it's less than 10 MB."
      );
      setMsgOpen(true);
      return;
    }
    var base64 = await convertBase64(file);
    setImageUrl(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleServiceItemActions = (action) => {
    if (
      action.type === serviceItemActionTypes.Clear ||
      (serviceItems.length === 1 &&
        action.type === serviceItemActionTypes.Delete)
    ) {
      setShowDialog(false);
    }
    itemDispatch(action);
    if (
      action.type === serviceItemActionTypes.Clear ||
      action.type === serviceItemActionTypes.Delete
    ) {
      setIsServiceItemsDeleted(true);
    }
  };

  const addItemToCart = (product, isCustomProduct = false) => {
    setPackageProductList((prev) => [...prev, product]);
    configureDialog();
    var serviceItem = isCustomProduct
      ? product
      : getServiceItemProductsFromProduct(product);
    if (Utils.IsNullOrEmptyArray(serviceItems)) {
      serviceItem.Sequence = 1;
    } else {
      serviceItem.Sequence = serviceItems[serviceItems.length - 1].Sequence + 1;
    }
    itemDispatch({
      type: serviceItemActionTypes.Add,
      value: serviceItem,
    });
  };

  const configureDialog = () => {
    if (!showDialog) {
      setShowDialog(true);
    }
  };

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    } else if (isNavigateToPackage) navigate(AddPackagesPage.Path);
  };

  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
      if (confirmFor == "Submit") {
        PackageSubmit();
      }
      if (confirmFor == "Update") {
        editPackageDetails();
      }
    }
  };

  async function PackageSubmit() {
    setIsLoading(true);
    const imgObj = getImgObj();
    var Package = {
      BusinessId: JSON.parse(localStorage.getItem("business-entity")).id,
      ShortDesc: packageName,
      Description: packageDescription,
      Active: active,
      Code: packageCode,
      Cost: Utils.IsNullOrEmpty(cost) ? 0 : parseFloat(cost),
      Class: packageCategory,
      ClassDescription: categoryDescription,
      Fineline: packageType,
      FinelineDescription: packageTypeDescription,
      Price: Utils.IsNullOrEmpty(priceExclusive)
        ? 0
        : parseFloat(priceExclusive),
      TaxPercent: Utils.IsNullOrEmpty(taxPercentage)
        ? 0
        : parseFloat(taxPercentage),
      RetailPrice: Utils.IsNullOrEmpty(taxAmount) ? 0 : parseInt(taxAmount),
      Default: frequentlyused,
      ImageUrl: imgObj.url,
      AvailableForReservation: Allowreservation,
      ServiceTime: Utils.IsNullOrEmpty(packageServiceTime)
        ? 0
        : parseInt(packageServiceTime),
      PostServiceTime: Utils.IsNullOrEmpty(packagePostServiceTime)
        ? 0
        : parseInt(packagePostServiceTime),
      Staff: checkedItems,
      InclusiveOfTax: inclusiveOfTax,
      PriceIncludingTax: inclusiveOfTax ? Utils.ConvertToFloat(TotalPrice) : 0,
      ReviewProduct: reviewPackageService,
      TermsConditions: termsConditions,
    };
    if (!Utils.IsNullOrEmptyArray(serviceItems)) {
      Package = {
        ...Package,
        PackageProductsList: packageProductList,
      };
    }
    let result2 = await postAsync(
      "Package/SavePackageProduct",
      {
        BusinessId: localSettings.business.id,
        NewPackageProduct: true,
        PackageProduct: Package,
      },
      "",
      "Execute",
      "",
      [],
      imgObj.newFile
    );
    if (result2 && result2.data) {
      setIsNavigateToPackage(true);
      setMsgText("Package Added successfully");
      setMsgOpen(true);
    } else {
      setMsgText("Unable to process your request");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }

  async function editPackageDetails() {
    setIsLoading(true);
    const imgObj = getImgObj();
    let ss = selectedPackage;
    ss["shortDesc"] = packageName;
    ss["description"] = packageDescription;
    ss["active"] = active;
    ss["code"] = packageCode;
    ss["cost"] = cost;
    ss["class"] = packageCategory;
    ss["classDescription"] = categoryDescription;
    ss["fineline"] = packageType;
    ss["finelineDescription"] = packageTypeDescription;
    ss["price"] = Utils.IsNullOrEmpty(priceExclusive)
      ? 0
      : parseFloat(priceExclusive);
    ss["taxPercent"] = Utils.IsNullOrEmpty(taxPercentage)
      ? 0
      : parseFloat(taxPercentage);
    ss["retailPrice"] = Utils.IsNullOrEmpty(taxAmount)
      ? 0
      : parseInt(taxAmount);
    ss["default"] = frequentlyused;
    ss["imageUrl"] = imgObj.url;
    ss["availableForReservation"] = Allowreservation;
    ss["serviceTime"] = Utils.IsNullOrEmpty(packageServiceTime)
      ? 0
      : parseInt(packageServiceTime);
    ss["postServiceTime"] = Utils.IsNullOrEmpty(packagePostServiceTime)
      ? 0
      : parseInt(packagePostServiceTime);
    ss["staff"] = checkedItems;
    ss["inclusiveOfTax"] = inclusiveOfTax;
    ss["priceIncludingTax"] = inclusiveOfTax
      ? Utils.ConvertToFloat(TotalPrice)
      : 0;
    ss["reviewProduct"] = reviewPackageService;
    ss["packageProductsList"] = packageProductList;
    ss['termsConditions'] = termsConditions;
    let result2 = await postAsync(
      "Package/SavePackageProduct",
      {
        BusinessId: localSettings.business.id,
        NewPackageProduct: false,
        PackageProduct: ss,
      },
      "",
      "Execute",
      "",
      [],
      imgObj.newFile
    );
    if (result2 && result2.data) {
      setIsNavigateToPackage(true);
      setMsgText("Changes Saved successfully");
      setMsgOpen(true);
    } else {
      setMsgText("Unable to process your request");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <ImageEnlarge
          open={enlargeImage}
          imgSrc={imageUrl}
          onClose={closeImage}
        />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
        />
        <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.BusinessSetup} />
            <Grid xs={12} className="content-sec">
              <Grid container direction="row" className="">
                <SettingsMenuItems
                  selectedMenu={BusinessHelper.GetPackagesMenuName()}
                />
                <Grid flex="1" spacing={2} className="form-sec">
                  <form
                    className="cs-form"
                    style={{ paddingBottom: "30px" }}
                    onSubmit={handleSubmit}
                  >
                    <Stack spacing={3}>
                      {Utils.IsNullOrEmpty(imageUrl) && (
                        <Stack direction="row" spacing={2}>
                          <label>
                            <AddAPhotoIcon
                              style={{ "font-size": "120px", opacity: "0.3" }}
                            />
                            <Input
                              type="file"
                              name="myImage"
                              className="hide"
                              inputProps={{
                                accept: "image/png, image/jpeg, image/jpg",
                              }}
                              onChange={(e) => handlePackagePhoto(e)}
                            />
                          </label>
                        </Stack>
                      )}

                      {!Utils.IsNullOrEmpty(imageUrl) && (
                        <div className="card imgBox">
                          <img
                            alt="not found"
                            width={"150px"}
                            src={imageUrl}
                            onClick={openImage}
                          />
                          <br />
                          <button
                            className="p-0 mt-1 btn btn-default"
                            onClick={deletePackagePhoto}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      )}
                      <Typography
                        level="h3"
                        component="h3"
                        fontWeight="600"
                        fontSize="1.2rem"
                        className="h3 m-0"
                      >
                        Tap to select Image
                      </Typography>
                    </Stack>
                    <Stack sx={{ minWidth: 120 }} spacing={2} className="mt-3">
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          label="Package Name "
                          type="text"
                          value={packageName}
                          error={!packageName}
                          onChange={(e) => setPackageName(e.target.value)}
                          placeholder="Enter package name"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          label="Package Description "
                          type="text"
                          value={packageDescription}
                          error={!packageDescription}
                          onChange={(e) =>
                            setPackageDescription(e.target.value)
                          }
                          placeholder="Enter package  description"
                        />
                      </FormControl>
                    </Stack>
                    <Stack direction="row" mt={2} spacing={2}>
                      <FormControl fullWidth>
                        <TextField
                          maxRows={5}
                          inputProps={{
                            style: { fontSize: 13 },
                            maxLength: 5,
                          }}
                          label="Package Code"
                          type="text"
                          value={packageCode}
                          error={!packageCode}
                          onChange={(e) => setPackageCode(e.target.value)}
                          placeholder="Enter package code"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <NumericTextField
                          inputProps={{ maxLength: 3 }}
                          value={packageServiceTime}
                          setValue={setPackageServiceTime}
                          label="Package Service Time (Min)"
                          placeholder="Enter time"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <NumericTextField
                          inputProps={{ maxLength: 3 }}
                          value={packagePostServiceTime}
                          setValue={setPackagePostServiceTime}
                          label="Post Service Time (Min)"
                          placeholder="Enter time"
                        />
                      </FormControl>
                    </Stack>
                    <br />
                    <Stack
                      direction="row"
                      spacing={5}
                      mt={1}
                      justifyContent="space-between"
                      style={{
                        border: "1px solid #cccccc",
                        "border-radius": "4px",
                      }}
                      className="font-size-14"
                    >
                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={frequentlyused}
                                onChange={(e) =>
                                  setfrequentlyused(e.target.checked)
                                }
                                InputLabelProps={{ shrink: true }}
                                name="frequently used"
                                color="primary"
                              />
                            }
                            value={frequentlyused}
                            label="Frequently Used"
                            labelPlacement="start"
                          />
                        </Box>
                      </FormControl>
                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={active}
                                onChange={(e) => setActive(e.target.checked)}
                                InputLabelProps={{ shrink: true }}
                                name="Active"
                                color="primary"
                              />
                            }
                            value={active}
                            label="Active"
                            fontSize="1.5rem"
                            labelPlacement="start"
                          />
                        </Box>
                      </FormControl>
                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={reviewPackageService}
                                onChange={(e) =>
                                  setReviewPackageService(e.target.checked)
                                }
                                InputLabelProps={{ shrink: true }}
                                name="Review Package Service"
                                color="primary"
                              />
                            }
                            value={reviewPackageService}
                            label="Review Package Service"
                            labelPlacement="start"
                          />
                        </Box>
                      </FormControl>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      mt={2}
                      justifyContent="space-between"
                      style={{
                        border: "1px solid #cccccc",
                        "border-radius": "4px",
                      }}
                      className="font-size-14"
                    >
                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={Allowreservation}
                                onChange={(e) =>
                                  setAllowreservation(e.target.checked)
                                }
                                InputLabelProps={{ shrink: true }}
                                name="Allow Reservation"
                                color="primary"
                              />
                            }
                            value={Allowreservation}
                            label="Allow Reservation"
                            labelPlacement="start"
                          />
                        </Box>
                      </FormControl>
                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={Blockcalender}
                                onChange={(e) =>
                                  setblockcalender(e.target.checked)
                                }
                                InputLabelProps={{ shrink: true }}
                                name="Block calender"
                                color="primary"
                              />
                            }
                            value={Blockcalender}
                            label="Block Calender"
                            labelPlacement="start"
                          />
                        </Box>
                      </FormControl>
                    </Stack>
                    <Typography
                      level="h3"
                      component="h3"
                      className="title-lbl mt-3"
                    >
                      {" "}
                      Package Classification
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="baseline"
                      justifyContent="space-between"
                      className="m-0"
                    >
                      <Typography
                        level="h3"
                        component="h3"
                        fontWeight="700"
                        fontSize="1.5rem"
                        className="h3"
                      >
                        Enter New Category:
                      </Typography>
                      <FormControlLabel
                        style={{ margin: "0" }}
                        control={
                          <Switch
                            checked={isDropdown1}
                            onChange={handleSwitchChange}
                          />
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      className="justify-content-between m-0"
                    >
                      {!isDropdown1 ? (
                        <FormControl fullWidth variant="outlined" margin="0">
                          <InputLabel id="Category-label">
                            Package Category
                          </InputLabel>
                          <Select
                            inputProps={{ style: { fontSize: 13 } }}
                            labelId="Category-label"
                            id="Category-select"
                            value={packageCategory}
                            onChange={(e) => setPackageCategory(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            label="Package Category"
                            style={{ height: "51.5px", fontSize: "13px" }}
                          >
                            {!Utils.IsNullOrEmptyArray(Catlist) &&
                              Catlist.map((item) => (
                                <MenuItem value={item}>{item}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          fullWidth
                          variant="outlined"
                          margin="0"
                          label="Package Category"
                          value={packageCategory}
                          onChange={(e) => setPackageCategory(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          placeholder="Enter category"
                        />
                      )}
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          fullWidth
                          variant="outlined"
                          margin="0"
                          label="Package Category Description"
                          value={categoryDescription}
                          onChange={(e) =>
                            setCategoryDescription(e.target.value)
                          }
                          placeholder="Enter description"
                        />
                      </FormControl>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <Typography
                        level="h3"
                        component="h3"
                        fontWeight="700"
                        fontSize="1.5rem"
                        className="h3"
                      >
                        Enter New Type:
                      </Typography>
                      <FormControlLabel
                        style={{ margin: "0" }}
                        control={
                          <Switch
                            checked={isDropdown}
                            onChange={handleSwitchType}
                          />
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      className="justify-content-between"
                    >
                      {!isDropdown ? (
                        <FormControl fullWidth variant="outlined" margin="0">
                          <InputLabel id="Category-label">
                            Package Type
                          </InputLabel>
                          <Select
                            inputProps={{ style: { fontSize: 13 } }}
                            labelId="Servicetype-label"
                            id="Servicetype-select"
                            value={packageType}
                            onChange={(e) => setPackageType(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            label="Package Type"
                            style={{ height: "51.5px" }}
                          >
                            {!Utils.IsNullOrEmptyArray(Typelist) &&
                              Typelist.map((item) => (
                                <MenuItem value={item}>{item}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          fullWidth
                          variant="outlined"
                          margin="0"
                          label="Package Type"
                          value={packageType}
                          onChange={(e) => setPackageType(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          placeholder="Enter package type"
                        />
                      )}
                      <FormControl fullWidth variant="outlined" margin="0">
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          fullWidth
                          variant="outlined"
                          margin="0"
                          label="Package Type Description"
                          value={packageTypeDescription}
                          onChange={(e) =>
                            setPackageTypeDescription(e.target.value)
                          }
                          placeholder="Enter description"
                        />
                      </FormControl>
                    </Stack>

                    <SalesInfoComponent
                      inclusiveOfTax={inclusiveOfTax}
                      setInclusiveOfTax={setInclusiveOfTax}
                      cost={cost}
                      setCost={setCost}
                      priceExclusive={priceExclusive}
                      setPriceExclusive={setPriceExclusive}
                      taxPercentage={taxPercentage}
                      setTaxPercentage={setTaxPercentage}
                      totalPrice={TotalPrice}
                      setTotalPrice={setTotalPrice}
                      taxAmount={taxAmount}
                      setTaxAmount={setTaxAmount}
                      costMandatory={false}
                    />

                    {isProductConfig && (
                      <>
                        <br />
                        <Box>
                          <Typography
                            level="h3"
                            component="h3"
                            fontWeight="700"
                            fontSize="1.6rem"
                            className="h3"
                          >
                            Package Configuration
                          </Typography>
                          <br />
                          <Stack className="card">
                            <ProductConfigComponent
                              serviceItems={serviceItems}
                              handleItemAction={handleServiceItemActions}
                              onAddItem={addItemToCart}
                              isFromAddNewPackage={true}
                              fromServiceSetup={false}
                            />
                          </Stack>
                        </Box>
                      </>
                    )}
                    <Stack className="mt-3">
                      <Typography
                        level="h3"
                        component="h3"
                        className="title-lbl"
                      >
                        Package Staff
                      </Typography>
                      {!Utils.IsNullOrEmptyArray(filteredstafflist) &&
                        filteredstafflist.map((rows, index) => (
                          <div key={index}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Stack>
                                <FormControl>
                                  <Typography
                                    level="h3"
                                    className="mt-1"
                                    component="h3"
                                    fontSize="18px"
                                    fontWeight="600"
                                  >
                                    {rows.firstName}
                                  </Typography>
                                  <Typography
                                    level="p"
                                    className="mb-1"
                                    component="p"
                                    fontSize="13px"
                                  >
                                    {rows.id}
                                  </Typography>
                                </FormControl>
                              </Stack>

                              <Stack
                                xs={2}
                                justifyContent="space-between"
                                alignContent="end"
                              >
                                <Checkbox
                                  size="large"
                                  checked={
                                    !Utils.IsNullOrEmptyObject(
                                      checkedItems.find((s) => s.id === rows.id)
                                    )
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(e, rows)
                                  }
                                  InputLabelProps={{ shrink: true }}
                                  input
                                />
                              </Stack>
                            </Stack>
                            <Divider />
                          </div>
                        ))}
                    </Stack>
                    <Stack mt={3} direction="row">
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          label="Terms and conditons "
                          type="text"
                          value={termsConditions}
                          onChange={(e) => setTermsConditions(e.target.value)}
                          placeholder="Enter terms and conditions "
                        />
                      </FormControl>
                    </Stack>
                    <br />
                    {UserHelper.CanEdit() && (
                      <Stack direction="row" className="fixed-btn">
                        <Grid className="container" justifyContent="end">
                          <Stack direction="row" justifyContent="end">
                            <FormControl>
                              <Button
                                type="submit"
                                variant="contained"
                                className="btn btn-primary"
                              >
                                Save
                              </Button>
                            </FormControl>
                          </Stack>
                        </Grid>
                      </Stack>
                    )}
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default AddNewPackage;
