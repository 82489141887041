import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Typography,
  Table,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ConfirmTypes,
  MaxDateLimit,
  SideBarMenuNames,
} from "../core/Constants";
import SideBarMenuItems from "./SideBarMenuItems";
import {
  Calendar as BigCalendar,
  momentLocalizer,
  Views,
} from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getGeocodeAddress, postAsync } from "../core/serviceClient";
import Loader from "./common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AddReservationsPage,
  CalanderPage,
  CheckoutDetailsPage,
  CheckOutPage,
  StatementReportPage,
} from "../core/PageConstants";
import MessagePopup from "./common/MessagePopUp";
import DialogPopup from "./common/DialogPopup";
import { GridCloseIcon } from "@mui/x-data-grid";
import { borderLeft, fontSize, styled, width } from "@mui/system";
import PersonIcon from "@mui/icons-material/Person";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { connectToStore } from "../data/store";
import { useTheme } from "@mui/material/styles";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Utils from "../core/Utils";
import {
  getBranch,
  getBusiness,
  getCountry,
  getEnterpriseId,
} from "../data/localSettingsActions";
import UserHelper from "../core/UserHelper";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import NumericTextField from "./common/controls/NumericTextField";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import PropaneIcon from "@mui/icons-material/Propane";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import EvStationIcon from "@mui/icons-material/EvStation";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CountryHelper from "../core/CountryHelper";
import AddressField from "./common/controls/AddressField";
import { Regexes } from "../core/Regexes";
import ConfirmPopup from "./common/ConfirmPopup";
import { ArrowRight } from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Calendar_image from "../images/calendar.png";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const localizer = momentLocalizer(moment);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName?.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const events = [
  {
    title: "Meeting",
    allDay: true,
    start: new Date(2025, 0, 5),
    end: new Date(2025, 0, 5),
  },
  {
    title: "Conference",
    start: new Date(2025, 0, 6),
    end: new Date(2025, 0, 8),
  },
];

const customerActionTypes = {
  RaiseJobcard: "RaiseJobcard",
  DeleteCustomer: "DeleteCustomer",
  DeleteVehicle: "DeleteVehicle",
  SaveCustomer: "SaveCustomer",
  SaveVehicle: "SaveVehicle",
  RaiseJobcardOnCustomerSave: "RaiseJobcardOnCustomerSave",
  DeleteInsuranceImage: "DeleteInsuranceImage",
  DeleteRCImage: "DeleteRCImage",
};

const Calendar = (props) => {
  var pageTitle = "Appointments";
  document.title = pageTitle;
  const [myEvents, setMyEvents] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [calenderView, setCalenderView] = useState(Views.MONTH);
  const [date, setDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isReservationInfoView, setIsReservationInfoView] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState({});
  const [splitScreen, setSplitScreen] = useState(false);
  const [reservationDate, setReservationDate] = useState("");
  const [localSettings, setLocalSettings] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [serviceList, setServiceList] = useState([]);
  const [selectedServiceList, setSelectedServiceList] = useState({});
  const [personName, setPersonName] = useState([]);
  const [servicePersonList, setServicePersonList] = useState([]);
  const [countryInfo, setCountryInfo] = useState({});
  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [overallTotal, setOverallTotal] = useState(0);
  const [openCustomerSelection, setOpenCustomerSelection] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [openAddNewCustomer, setOpenAddNewCustomer] = useState(false);
  const [customerKey, setCustomerKey] = useState();
  const [customerFirstName, setCustomerFirstName] = useState();
  const [customerLastName, setCustomerLastName] = useState();
  const [customerMobileNumber, setCustomerMobileNumber] = useState();
  const [customerEmailId, setCustomerEmailId] = useState("");
  const [isOpenedCustomAddress, setIsOpenedCustomAddress] = useState(false);
  const [customerCustomAddress, setCustomerCustomAddress] = useState("");
  const [customerCity, setCustomerCity] = useState("");
  const [customerState, setCustomerState] = useState("");
  const [customerPincode, setCustomerPincode] = useState("");
  const [customerAddress, setCustomerAddress] = useState();
  const [customerBusinessName, setCustomerBusinessName] = useState("");
  const [customerBusinessGSTIN, setCustomerBusinessGSTIN] = useState("");
  const [customerGSTINCode, setCustomerGSTINCode] = useState("");
  const [customerDOB, setCustomerDOB] = useState(moment("1973-01-01T00:00:00"));
  const [customerGender, setCustomerGender] = useState();
  const [saveCustomerDisabled, setSaveCustomerDisabled] = useState(false);
  const [useCustomAddress, setUseCustomAddress] = useState(true);
  const [isIndianBusiness, setIsIndianBusiness] = useState(false);
  const [regIdLength, setRegIdLength] = useState(0);
  const [isUAEBusiness, setIsUAEBusiness] = useState(false);
  const [isUSBusiness, setIsUSBusiness] = useState(false);
  const [gst, setGST] = useState(false);
  const [addressISOCode, setAddressISOCode] = useState("");
  const [showStateCode, setShowStateCode] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [customerAction, setCustomerAction] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [customerId, setCustomerId] = useState();
  const [selectedPass, setSelectedPass] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [customerVehicleList, setCustomerVehicleList] = useState([]);
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isAutomobileBusiness, setIsAutomobileBusiness] = useState(false);
  const [isShowCalender, setIsShowCalender] = useState(false);
  const [reservationDetails, setReservationDetails] = useState({});
  const [branchDetails, setBranchDetails] = useState();
  const [selectedDate, setSelectedDate] = useState([
    moment().utc().startOf("day"),
    moment().utc().endOf("day"),
  ]);
  const [disableBillingBtn, setDisableBillingBtn] = useState(true);
  const [disableEditBtn, setDisableEditBtn] = useState(true);
  const [openReservationDetails, setOpenReservationDetails] = useState([]);
  const [closedReservationDetails, setClosedReservationDetails] = useState([]);
  const [navigationStack, setNavigationStack] = useState([]);

  const theme = useTheme();
  useEffect(() => {
    if (UserHelper.CheckPermission(CalanderPage, navigate)) {
      let localSetting = connectToStore();
      setLocalSettings(localSetting);
      getReservationCalenderDetails(new Date(), localSetting);
      const countryInfo = getCountry();
      setCountry(countryInfo);
      var branch = getBranch();
      setBranchDetails(branch);
      getReservationDetail(selectedDate[0], selectedDate[1]);
      var isStateEmpty = Utils.IsNullOrEmptyObject(location.state);
      var isNavigationStackEmpty =
        isStateEmpty ||
        Utils.IsNullOrEmptyArray(location.state.navigationStack);
      setNavigationStack(
        isNavigationStackEmpty ? [] : location.state.navigationStack
      );
    }
  }, []);

  useEffect(() => {
    checkCustomerDetails();
  }, [
    customerFirstName,
    customerLastName,
    customerMobileNumber,
    customerAddress,
    useCustomAddress,
    isOpenedCustomAddress,
    customerCustomAddress,
    customerCity,
    customerPincode,
    customerState,
  ]);

  const setCountry = (country) => {
    setCountryInfo(country);
    setAddressISOCode(country.ISOCode);
    var isIndia = CountryHelper.IsBusinessCountryIndia(country);
    var isUS = CountryHelper.IsBusinessCountryUSA(country);
    var isUAE = CountryHelper.IsBusinessCountryUAE(country);
    if (isIndia) {
      setShowStateCode(true);
      setGST(true);
      setIsIndianBusiness(true);
      setRegIdLength(15);
      return;
    }
    if (isUS) {
      setRegIdLength(9);
      setIsUSBusiness(true);
      return;
    }
    if (isUAE) {
      setRegIdLength(15);
      setIsUAEBusiness(true);
      return;
    }
  };

  const checkCustomerDetails = async (regValid = true) => {
    var validRegistrationId = Utils.IsNullOrEmpty(customerBusinessGSTIN);
    if (!validRegistrationId && isIndianBusiness) {
      validRegistrationId =
        customerBusinessGSTIN.length === regIdLength &&
        Regexes.GST.test(customerBusinessGSTIN);
    } else if (!validRegistrationId) {
      validRegistrationId = customerBusinessGSTIN.length === regIdLength;
    }
    var isAddressValid =
      (isOpenedCustomAddress && !Utils.IsNullOrEmpty(customerCity)) ||
      (!isOpenedCustomAddress && !Utils.IsNullOrEmpty(customerAddress));
    var mobileNumberValid = Utils.IsMobileValid(customerMobileNumber);
    var isDetailsFilled =
      !Utils.IsNullOrEmpty(customerFirstName) &&
      (!isUSBusiness || !Utils.IsNullOrEmpty(customerLastName)) &&
      isAddressValid &&
      validRegistrationId &&
      mobileNumberValid;
    if (
      !regValid &&
      !Utils.IsNullOrEmpty(customerBusinessGSTIN) &&
      validRegistrationId
    ) {
      var result = await postAsync("Business/ValidateRegistration", {
        GSTIN: gst,
        RegistrationId: Utils.ConvertToUpperCase(customerBusinessGSTIN.trim()),
      });
      if (!Utils.IsNullOrEmptyObject(result)) {
        isDetailsFilled = isDetailsFilled && result.data.valid;
        if (!Utils.IsNullOrEmpty(result.data.companyName)) {
          setCustomerBusinessName(result.data.companyName);
        }
      } else {
        isDetailsFilled = false;
      }
    }
    setSaveCustomerDisabled(!isDetailsFilled);
  };

  const handleMsgClose = () => {
    setAlertTitle("");
    setMsgOpen(false);
  };

  const handleConfirmClose = async (value) => {
    setConfirmOpen(false);
    if (value === "OK") {
      proceedSaveCustomer();
    }
  };

  const getReservationCalenderDetails = async (newDate, localSetting) => {
    setIsLoading(true);
    const now = new Date(newDate);
    const startOfMonth = new Date(Date.UTC(
      now.getFullYear(),
      now.getMonth(),
      1,
      0,
      0,
      0
    ));
    const endOfMonth = new Date(Date.UTC(
      now.getFullYear(),
      now.getMonth() + 1,
      0,
      23,
      59,
      59
    ));
    if (localSetting?.business?.id) {
      var result = await postAsync(
        "Reservation/GetReservations",
        {
          BranchId: localSetting.branch.branchId,
          BusinessId: localSetting.business.id,
          GetReservations: true,
          Start: startOfMonth.toISOString(),
          End: endOfMonth.toISOString(),
        },
        "",
        "Get"
      );
      if (result.data?.reservations?.length > 0) {
        setReservations(result.data.reservations);
        let reservationsData = result.data.reservations;
        let newEvents = [];
        reservationsData.map((data) => {
          const reservationDate = new Date(data.reservationDT);
          const enddate = new Date(reservationDate);
          enddate.setHours(enddate.getHours() + 1);
          newEvents.push({
            title: Utils.IsNullOrEmpty(data.customerName) ? data.mobileNumber : data.customerName,
            start: reservationDate,
            end: enddate,
            reservationData: data,
          });
        });
        setMyEvents(newEvents);
      }
    }
    setIsLoading(false);
  };

  const getReservationDetail = async (startDate, endDate) => {
    setIsLoading(true);
    var business = getBusiness();
    var branch = getBranch();
    var startDT = Utils.GetISODateTimeString(startDate);
    var endDT = Utils.GetISODateTimeString(endDate);
    if (business !== null) {
      var result = await postAsync(
        "Reservation/GetReservations",
        {
          BranchId: branch.branchId,
          BusinessId: business.id,
          GetReservations: true,
          Start: startDT,
          End: endDT,
        },
        "",
        "Get"
      );
      if (!result.error) {
        setReservations(result.data.reservations);
        const reservationsData = result.data.reservations;
        const closedReservationsData = reservationsData.filter((data) => data.invoiceNumber).sort((a, b) => new Date(a.reservationDT) - new Date(b.reservationDT));
        const openReservationsData = reservationsData.filter((data) => !data.invoiceNumber).sort((a, b) => new Date(a.reservationDT) - new Date(b.reservationDT));
        setOpenReservationDetails(
          Array.isArray(openReservationsData) ? openReservationsData : []
        )
        setClosedReservationDetails(
          Array.isArray(closedReservationsData) ? closedReservationsData : []
        )
        setReservationDetails(
          Array.isArray(reservationsData) ? reservationsData : []
        );
      }
    }
    setIsLoading(false);
  };

  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      if (dateArray[0]._isValid == false || dateArray[1]._isValid == false) {
        setMsgText("Please select a valid date range.");
        setMsgOpen(true);
      } else {
        let dateDiff = dateArray[1].diff(dateArray[0], "days");
        if (dateDiff > MaxDateLimit) {
          setMsgText(
            "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
          );

          setMsgOpen(true);
        } else {
          setSelectedDate(dateArray);
          getReservationDetail(dateArray[0], dateArray[1]);
        }
      }
    }
  };

  useEffect(() => {
    let localSetting = connectToStore();
    getReservationCalenderDetails(new Date(), localSetting);
  }, [isShowCalender]);

  function showAppointmentReceipt(row) {
    var business = getBusiness();
    var branch = getBranch();

    if (!business || !branch) {
      return;
    }
    var startDate = new Date(selectedDate[0]).toISOString().split("T")[0];
    var endDate = new Date(selectedDate[1]).toISOString().split("T")[0];
    var reserveId =
      !row || Utils.IsNullOrEmpty(row.reservationId) ? "" : row.reservationId;
    const url = encodeURI(
      Utils.GetServiceUrl() +
      "home/GetPrintReservationDetails?" +
      "businessId=" +
      business.id +
      "&branchId=" +
      branch.branchId +
      "&visitId=" +
      reserveId +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate
    );
    window.open(url, "_blank");
  }

  const handleNavigate = (newDate, view, action) => {
    let adjustedDate = new Date(newDate);

    if (action === "PREV") {
      if (view === Views.MONTH) {
        adjustedDate.setMonth(date.getMonth() - 1);
      } else if (view === Views.DAY) {
        adjustedDate.setDate(date.getDate() - 1);
      }
    } else if (action === "NEXT") {
      if (view === Views.MONTH) {
        adjustedDate.setMonth(date.getMonth() + 1);
      } else if (view === Views.DAY) {
        adjustedDate.setDate(date.getDate() + 1);
      }
    }

    setDate(adjustedDate);
    getReservationCalenderDetails(adjustedDate, localSettings);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    customerSearchSubmit();
  };

  async function customerSearchSubmit() {
    if (Utils.IsNullOrEmpty(searchText) == true) return;
    setIsLoading(true);
    const business = getBusiness();
    let response = await postAsync("Customer/Search", {
      BusinessId: business.id,
      SearchText: searchText,
      IsAutomobileBusiness: business.category == "Automobile",
    });
    setIsLoading(false);
    if (!Utils.IsNullOrEmptyArray(response.data.passList)) {
      setCustomerList(
        response.data.passList
          .sort((a, b) => (a.userName > b.userName ? 1 : -1))
          .filter((user) => !user.isBusiness)
      );
    } else {
      setCustomerList([]);
    }
  }

  const handleAddCustomer = (customer) => {
    navigate(AddReservationsPage.Path, {
      state: {
        navigationStack: [CalanderPage.Path],
        to: AddReservationsPage.Path,
        onSuccess: CalanderPage.Path,
        selectedReservation: [],
        customerDetails: customer,
      },
    });
  };

  function toggleCustomAddress() {
    setIsOpenedCustomAddress((wasOpened) => !wasOpened);
  }

  const customerSaveSubmit = () => {
    if (customerDOB > moment()) {
      setMsgText("Please enter a valid date of birth");
      setMsgOpen(true);
      return;
    }
    setCustomerAction(customerActionTypes.SaveCustomer);
    setConfirmText("Save customer?");
    setConfirmOpen(true);
  };

  const proceedSaveCustomer = async () => {
    setIsLoading(true);
    var business = localSettings.business;
    var tier = Utils.IsNullOrEmpty(customerId)
      ? business.tiers.find((c) => c.default)
      : business.tiers.find((c) => c.level === selectedPass.tierLevel);
    if (Utils.IsNullOrEmptyObject(tier)) {
      tier = business.tiers.filter((c) => c.default)[0];
    }
    var addressSegment1 = !Utils.IsNullOrEmpty(customerCustomAddress)
      ? customerCustomAddress + ","
      : "";
    var addressSegment2 = !Utils.IsNullOrEmpty(customerCity)
      ? customerCity + ","
      : "";
    var addressSegment3 = !Utils.IsNullOrEmpty(customerState)
      ? customerState + ","
      : "";
    var homeLocation = (
      addressSegment1 +
      addressSegment2 +
      addressSegment3 +
      getBranch().country
    ).trim();
    var addressDetails = null;
    if (!Utils.IsNullOrEmpty(customerAddress, homeLocation)) {
      var geocodeResponse = await getGeocodeAddress(
        customerAddress,
        homeLocation
      );
      if (
        !Utils.IsNullOrEmptyObject(geocodeResponse) &&
        geocodeResponse.error
      ) {
        setIsLoading(false);
        setMsgOpen(true);
        setMsgText(geocodeResponse.errorMessage);
        return;
      }
      if (
        !Utils.IsNullOrEmptyObject(geocodeResponse) &&
        geocodeResponse.status === "OK"
      ) {
        var locality = "";
        var county = "";
        var state = "";
        var country = "";
        var zipcode = "";
        var streetNumber = "";
        var route = "";
        var address = "";
        var lng = 0;
        var lat = 0;
        address = geocodeResponse.results[0].formatted_address;
        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("street_number") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("street_number")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            streetNumber = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("route") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("route")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            route = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("locality") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("locality")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            locality = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("administrative_area_level_1") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("administrative_area_level_1")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            state = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("administrative_area_level_2") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("administrative_area_level_2")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            county = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("country") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("country")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            country = ac.short_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("postal_code") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("postal_code")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            zipcode = ac.long_name;
          }
        }
        lat = geocodeResponse.results[0].geometry.location.lat;
        lng = geocodeResponse.results[0].geometry.location.lng;
        addressDetails = {
          HomeLocation: customerAddress,
          Address: streetNumber + " " + route,
          City: locality,
          State: state,
          Country: country,
          County: county,
          ZipCode: zipcode,
          Locality: locality,
          Latitude: Utils.ConvertToFloat(lat),
          Longitude: Utils.ConvertToFloat(lng),
        };
      } else {
        setIsLoading(false);
        setMsgOpen(true);
        setMsgText("Invalid address");
        return;
      }
    }
    var currentAddress = "";
    var currentHomeLocation = "";
    var currentLocality = "";
    var currentCountry = "";
    var currentState = "";
    var currentCounty = "";
    var currentZipCode = "";
    var currentLng = 0;
    var currentLat = 0;

    if (isOpenedCustomAddress) {
      currentHomeLocation = homeLocation;
      currentAddress = customerCustomAddress;
      currentCountry = getBranch().country;
      currentLocality = customerCity;
      currentState = customerState;
      currentZipCode = customerPincode;
    } else {
      currentAddress = addressDetails.Address;
      currentHomeLocation = addressDetails.HomeLocation;
      currentState = addressDetails.State;
      currentZipCode = addressDetails.ZipCode;
      currentCountry = addressDetails.Country;
      currentCounty = addressDetails.County;
      currentLocality = addressDetails.Locality;
      currentLat = addressDetails.Latitude;
      currentLng = addressDetails.Longitude;
    }

    var newCustomer = {};
    if (Utils.IsNullOrEmptyObject(selectedCustomer)) {
      newCustomer = {
        Id: customerId,
        CustomerKey: customerKey,
        FirstName: customerFirstName,
        LastName: customerLastName,
        MobileNumber: customerMobileNumber,
        EmailId: customerEmailId,
        UsedCustomAddress: isOpenedCustomAddress,
        Address: currentAddress,
        CountryCode: countryInfo.Code,
        City: currentLocality,
        State: currentState,
        ZipCode: currentZipCode,
        Locality: currentLocality,
        Country: currentCountry,
        County: currentCounty,
        HomeLocation: currentHomeLocation,
        CompanyName: customerBusinessName,
        RegistrationId: customerBusinessGSTIN,
        StateCode: customerGSTINCode,
        Latitude: Utils.ConvertToFloat(currentLat),
        Longitude: Utils.ConvertToFloat(currentLng),
        EnterpriseId: getEnterpriseId(),
        Dob: Utils.GetISODateTimeString(customerDOB),
        Gender: customerGender,
      };
    } else {
      selectedCustomer.customerKey = customerKey;
      selectedCustomer.firstName = customerFirstName;
      selectedCustomer.lastName = customerLastName;
      selectedCustomer.mobileNumber = customerMobileNumber;
      selectedCustomer.emailId = customerEmailId;
      selectedCustomer.address = currentAddress;
      selectedCustomer.homeLocation = currentHomeLocation;
      selectedCustomer.usedCustomAddress = isOpenedCustomAddress;
      selectedCustomer.city = currentLocality;
      selectedCustomer.locality = currentLocality;
      selectedCustomer.state = currentState;
      selectedCustomer.zipCode = currentZipCode;
      selectedCustomer.country = currentCountry;
      selectedCustomer.county = currentCounty;
      selectedCustomer.latitude = currentLat;
      selectedCustomer.longitude = currentLng;
      selectedCustomer.companyName = customerBusinessName;
      selectedCustomer.registrationId = customerBusinessGSTIN;
      selectedCustomer.stateCode = customerGSTINCode;
      selectedCustomer.dob = Utils.GetISODateTimeString(customerDOB);
      selectedCustomer.gender = customerGender;
      newCustomer = selectedCustomer;
    }
    var request = {
      BusinessId: business.id,
      Customer: newCustomer,
      VehicleDetails: customerVehicleList,
      PointsEarnRatio: tier.pointsEarnRatio,
      PointsRedemptionRatio: tier.pointsRedemptionRatio,
      SearchText: searchText,
      TierLevel: tier.level,
      TierName: tier.name,
      TierCardColor: tier.cardColor,
    };
    let result = await postAsync("Customer/Save", request);
    setIsNavigateToLogin(result.isNavigateToLogin);
    if (result.error) {
      setIsLoading(false);
      setMsgOpen(true);
      setMsgText(result.errorMessage);
      return;
    }
    setIsLoading(false);
    if (result.data.ifCustomerExists == false) {
      if (isAutomobileBusiness) {
        setSelectedPass(
          result.data.passes.find(
            (c) => c.customerId === result.data.customerId
          )
        );
        setCustomerAction(customerActionTypes.RaiseJobcardOnCustomerSave);
        setConfirmText(
          "Customer saved successfully. Do you wish to raise jobcard?"
        );
        setConfirmOpen(true);
      } else {
        setMsgText("Customer details saved successfully.");
        setMsgOpen(true);
        closeCustomerSetup();
        if (!Utils.IsNullOrEmptyArray(result?.data?.passes)) {
          let savedCustomerDetails = result.data.passes.filter(
            (pass) => pass.customerId === result.data.customerId
          );
          handleAddCustomer(savedCustomerDetails[0]);
        }
      }
    } else if (result.data.ifCustomerExists == true) {
      setMsgText("Customer exists already.");
      setMsgOpen(true);
      return;
    } else {
      setMsgText("Sorry! Please try again.");
      setMsgOpen(true);
    }
  };

  const closeCustomerSetup = () => {
    setCustomerId("");
    setCustomerKey("");
    setCustomerFirstName("");
    setCustomerLastName("");
    setCustomerMobileNumber("");
    setCustomerEmailId("");
    setCustomerAddress("");
    setUseCustomAddress(true);
    setCustomerCustomAddress("");
    setCustomerCity("");
    setCustomerState("");
    setCustomerPincode("");
    setCustomerDOB(moment("1973-01-01T00:00:00"));
    setCustomerGender("");
    setCustomerGSTINCode("");
    setCustomerBusinessGSTIN("");
    setCustomerBusinessName("");
    setCustomerVehicleList([]);
    setSelectedPass({});
    setSelectedCustomer({});
  };

  const handleBilling = async (detials) => {
    var _reservationDetails = Utils.IsNullOrEmptyArray(detials) ? selectedReservation : detials;
    let structuredServiceItems = [];
    if (_reservationDetails?.serviceItems?.length > 0) {
      _reservationDetails.serviceItems.map((serviceItem, index) => {
        let newStaffStructure = [];
        serviceItem.selectedStaffs.map((staff) => {
          newStaffStructure.push({
            Id: staff.id,
            Name: staff.name,
            Status: staff.status
          })
        })
        structuredServiceItems.push({
          AdditionalAttributes: serviceItem.additionalAttributes,
          BriefDescription: serviceItem.briefDescription,
          Cess: serviceItem.cess,
          Cost: serviceItem.cost,
          Description: serviceItem.description,
          DiscountTotal: serviceItem.discountTotal,
          Discounts: serviceItem.discounts,
          DiscountsLocal: serviceItem.discountsLocal,
          ImageUrl: serviceItem.imageUrl,
          InclusiveOfTax: serviceItem.inclusiveOfTax,
          IsAnonymous: serviceItem.isAnonymous,
          IsInventoryUpdated: serviceItem.isInventoryUpdated,
          Labour: serviceItem.labour,
          Material: serviceItem.material,
          MeasuringUnit: serviceItem.measuringUnit,
          Price: serviceItem.price,
          PriceIncludingTax: serviceItem.priceIncludingTax,
          ProductClass: serviceItem.productClass,
          ProductCode: serviceItem.productCode,
          ProductDepartment: serviceItem.productDepartment,
          ProductFineline: serviceItem.productFineline,
          ProductHSN: serviceItem.productHSN,
          ProductId: serviceItem.productId,
          ProductRating: serviceItem.productRating,
          Quantity: serviceItem.quantity,
          RefundCost: serviceItem.refundCost,
          RefundDiscountTotal: serviceItem.refundDiscountTotal,
          RefundPrice: serviceItem.refundPrice,
          RefundQuantity: serviceItem.refundQuantity,
          RefundTotal: serviceItem.refundTotal,
          RetailPrice: serviceItem.retailPrice,
          ReviewProduct: serviceItem.reviewProduct,
          Sequence: index + 1,
          SerialNumberMandatory: serviceItem.serialNumberMandatory,
          SerialNumbers: serviceItem.serialNumbers,
          Service: serviceItem.service,
          ServiceItemProducts: serviceItem.serviceItemProducts,
          ServiceItemProductsLocal: serviceItem.serviceItemProductsLocal,
          ServiceItemStaffs: newStaffStructure,
          ServiceItemStaffsLocal: serviceItem.serviceItemStaffsLocal,
          ServiceItemType: serviceItem.serviceItemType,
          ServiceTime: serviceItem.serviceTime,
          Sku: serviceItem.sku,
          TaxAmount: serviceItem.taxAmount,
          TaxPercent: serviceItem.taxPercent,
          TotalPrice: serviceItem.totalPrice,
          TotalPriceIncludingTax: serviceItem.totalPriceIncludingTax,
          TrackSerialNumber: serviceItem.trackSerialNumber,
          TrackWarrantyInfo: serviceItem.trackWarrantyInfo,
        });
      });

      let response = await postAsync("Customer/Search", {
        BusinessId: _reservationDetails.businessId,
        SearchText: _reservationDetails.customerId,
        IsAutomobileBusiness: false,
      });
      let customerPass = response.data.passList[0];

      navigate(CheckOutPage.Path, {
        state: {
          navigationStack: [CalanderPage.Path],
          to: CheckOutPage.Path,
          onSuccess: CalanderPage.Path,
          checkout: true,
          pass: customerPass,
          fromJobcard: location.state && location.state.fromJobcard,
          searchText: _reservationDetails.customerName,
          serviceItems: structuredServiceItems,
        },
      });
    }
  };

  const handleSelect = ({ start, end }) => {
    if (!UserHelper.CanEditAppointments()) {
      return;
    }
    setOpenCustomerSelection(true);
    setSplitScreen(true);
  };
  const handleBiilingBtnVisible = (data) => {
    if (!Utils.IsNullOrEmptyArray(data)) {
      const today = new Date();
      const dateOnly = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
      const reservationDateStr = new Date(data.reservationDT).toISOString().split("T")[0];
      const todayDateStr = dateOnly.toISOString().split("T")[0];
      if (((data.status === "Active" || data.status === "CheckedIn" || !data.invoiceNumber) && reservationDateStr == todayDateStr)) {
        return true;
      }
      return false;
      //TODO Revisit when advance payment on reservation is implemented
      // if(data.paidAmount == 0){
      // debugger;
      // }
      // return data.paidAmount == 0;
    }
  }
  const handleEditBtnVisible = (data) => {
    if (!Utils.IsNullOrEmptyArray(data)) {
      const today = new Date();
      const dateOnly = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
      const reservationDateStr = new Date(data.reservationDT).toISOString().split("T")[0];
      const todayDateStr = dateOnly.toISOString().split("T")[0];
      if (data.status === "Active" || data.status === "CheckedIn" || !data.invoiceNumber) {
        return true;
      }
      return false;
      //TODO Revisit when advance payment on reservation is implemented
      // if(data.paidAmount == 0){
      //   debugger;
      //   }    
      //     return data.paidAmount == 0;
    }
  }

  const passVisitorObj = async (reservationObj, e) => {
    e.stopPropagation();
    setIsLoading(true);
    let response = await postAsync("Customer/SearchVisit", {
      BusinessId: localSettings.business.id,
      SearchText: reservationObj.invoiceNumber,
    });
    if (!Utils.IsNullOrEmptyArray(response.data)) {
      let visitorobj = response.data[0];
      navigate(CheckoutDetailsPage.Path, {
        state: {
          visit: visitorobj,
          desc: Utils.GetDate(
            visitorobj.createdDT,
            localSettings.country
          ) +
            " " + visitorobj.serviceDesc,
          navigationStack: Utils.AddElementToArray(
            navigationStack,
            CalanderPage.Path
          ),
          onSuccess: CalanderPage.Path,
        }
      })
    }

    setIsLoading(false);

  }

  const handleOpenReservation = (data) => {
    if (data?.reservationData) {
      setDisableBillingBtn(true);
      setDisableEditBtn(true);
      setSelectedReservation(data.reservationData);
      const today = new Date();
      const dateOnly = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
      const reservationDateStr = new Date(data.reservationData.reservationDT).toISOString().split("T")[0];
      const todayDateStr = dateOnly.toISOString().split("T")[0];
      if (((data.reservationData.status === "Active" || data.reservationData.status === "CheckedIn" || !data.reservationData.invoiceNumber) && reservationDateStr == todayDateStr)) {
        setDisableBillingBtn(false);
      }
      if ((data.reservationData.status === "Active" || data.reservationData.status === "CheckedIn" || !data.reservationData.invoiceNumber)) {
        setDisableEditBtn(false);
      }
    }
    setOpenCustomerSelection(false);
    setSplitScreen(true);
  };

  const handleSelectEvent = async (event) => {
    if (calenderView === Views.DAY) {
      handleOpenReservation(event);
    }
    if (calenderView !== Views.DAY) {
      handleOpenReservation(event);
    }
  };

  const handleEditReservation = async (detials) => {
    var _reservationDetails = Utils.IsNullOrEmptyArray(detials) ? selectedReservation : detials
    setReservationDate(_reservationDetails?.reservationDT);
    navigate(AddReservationsPage.Path, {
      state: {
        navigationStack: [CalanderPage.Path],
        to: AddReservationsPage.Path,
        onSuccess: CalanderPage.Path,
        selectedReservation: _reservationDetails,
      },
    });
  };

  const handleCheckboxChange = (id, serviceItem) => {
    setSelectedServiceList((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
    if (!selectedServiceList[id]) {
      setTax((prev) => prev + (serviceItem?.taxAmount ?? 0));
      setSubTotal((prev) => prev + (serviceItem?.price ?? 0));
      setOverallTotal(
        (prev) => prev + (serviceItem.price ?? 0 + serviceItem.taxAmount ?? 0)
      );
    } else {
      setTax((prev) => prev - (serviceItem?.taxAmount ?? 0));
      setSubTotal((prev) => prev - (serviceItem?.price ?? 0));
      setOverallTotal(
        (prev) => prev - (serviceItem.price ?? 0 + serviceItem.taxAmount ?? 0)
      );
    }
  };

  const handleSelectPersonChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const getCurrentMonthRange = (newDate) => {
    const now = new Date(newDate);
    const startOfMonth = new Date(
      now.getFullYear(),
      now.getMonth(),
      1,
      0,
      0,
      0
    );
    const endOfMonth = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      0,
      23,
      59,
      59
    );
    return { startOfMonth, endOfMonth };
  };

  const getCurrentWeekRange = (newDate) => {
    const now = new Date(newDate);
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - now.getDay()); // Set to start of the week (Sunday)
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Set to end of the week (Saturday)
    endOfWeek.setHours(23, 59, 59, 999);

    return { startOfWeek, endOfWeek };
  };

  const handleCloseReservation = () => {
    setSplitScreen(false);
  };

  const handleCloseCustomer = () => {
    setSplitScreen(false);
    setOpenCustomerSelection(false);
    setSearchText("");
    setCustomerList([]);
  };

  const handleCloseNewCustomer = () => {
    setSplitScreen(false);
    setOpenAddNewCustomer(false);
  };

  const handleOpenAddNewCustomer = () => {
    setOpenAddNewCustomer(true);
    setSplitScreen(true);
    setOpenCustomerSelection(false);
  };

  const onCloseReservationInfo = () => {
    setIsReservationInfoView(false);
  };

  const getCurrentDayRange = (newDate) => {
    const startOfDay = new Date(newDate);
    startOfDay.setHours(0, 0, 0, 0); // Start of the day at 00:00:00

    const endOfDay = new Date(startOfDay);
    endOfDay.setHours(23, 59, 59, 999); // End of the day at 23:59:59

    return { startOfDay, endOfDay };
  };

  const handleViewChange = (newView) => {
    setCalenderView(newView);

    let startOfMonth, endOfMonth, startOfWeek, endOfWeek, startOfDay, endOfDay;

    if (newView === Views.MONTH) {
      ({ startOfMonth, endOfMonth } = getCurrentMonthRange(date));
    } else if (newView === Views.WEEK) {
      ({ startOfWeek, endOfWeek } = getCurrentWeekRange(date));
    } else if (newView === Views.DAY) {
      ({ startOfDay, endOfDay } = getCurrentDayRange(date));
    } else if (newView === Views.AGENDA) {
    }
  };
  const getTotalServiceTime = (serviceItems) => {
    if (!Array.isArray(serviceItems)) {
      console.warn("serviceItems is not an array or is undefined:", serviceItems);
      return 0;
    }

    var total = serviceItems.reduce((sum, item) => sum + item.serviceTime, 0);
    return total > 0 ? total : null;

  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function NoAppointments() {
    return (
      <Stack justifyContent="Center" padding={4} marginTop={5} className="text-center">
        <Box
          alignSelf="center"
          component="img"
          sx={{
            height: "250px",
          }}
          alt="Calendar"
          src={Calendar_image}
        />
        <Typography
          component="p"
          fontSize="14px"
          className="mt-2 text-muted"
        >No appointments found.</Typography>
      </Stack>
    );
  }

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgTitle={alertTitle}
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
        />
        {/* <Box  style={{
            width: splitScreen ? "60%" : "100%",
            transition: "width 0.3s ease-in-out",
          }}> */}
        <Box>
          {/* <SideBarMenuItems selectedTab={SideBarMenuNames.Calendar} /> */}
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.Calendar} />
            <Grid xs={12} className="content-sec">
              <Grid container direction="row" className="" sx={{ "padding-bottom": "100px" }}>
                <Grid
                  flex="1"
                  spacing={2}
                  className="form-sec xs-pd-0"
                >
                  <Grid container className="title-sec">
                    <Stack direction="row" className="w-xs-100" justifyContent="space-between">
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="2rem"
                        className="page-title text-center"
                      >
                        {isShowCalender === false || splitScreen === false ? <Box>{pageTitle} - {openReservationDetails.length} <span style={{ "font-size": "12px", "font-weight": "400" }}> </span></Box> : "New Appointment"}
                      </Typography>
                    </Stack>

                    <Stack direction="row" className="fixed-xs-btn" rowGap="10px">
                      <FormControlLabel
                        alignSelf="center"
                        style={{ "margin": "0 20px 0 0", "align-self": "center" }}
                        className="m-xs-0"
                        control={
                          <>
                            <Switch
                              checked={isShowCalender}
                              onChange={(e) => {
                                setIsShowCalender(e.target.checked);
                                setSplitScreen(false);
                              }}

                            />
                            <EventNoteOutlinedIcon
                              className="hidden-xs"
                              style={{
                                fontSize: "24px",
                                color: "#666666",
                              }} />
                          </>
                        }
                        title="Show Calendar"
                      />
                      {/* Left Side: DateRangePicker */}
                      {isShowCalender === false && (
                        <Grid
                          item
                          alignSelf="center"
                          className="card"
                          padding="4px"
                        >
                          <DateRangePicker
                            showLeadingZeros={true}
                            format="dd/MM/yyyy"
                            onChange={onDateChange}
                            maxDate={
                              new Date(
                                new Date().setDate(
                                  new Date().getDate() +
                                  (branchDetails?.reservationMaxDays || 0)
                                )
                              )
                            }
                            value={selectedDate}
                            locale={localSettings && localSettings.authenticated}
                            clearIcon={null}
                            calendarIcon={
                              <CalendarMonthOutlinedIcon
                                style={{
                                  fontSize: "20px",
                                  color: "#666666",
                                }}
                              />
                            }
                          />
                        </Grid>
                      )}
                      {UserHelper.CanEditAppointments() && (
                        <>
                          {(splitScreen === false || isShowCalender === false) && (
                            <Button className="btn btn-primary ml-1 float-btn"
                              onClick={() => {
                                setOpenCustomerSelection(true);
                                setIsShowCalender(true);
                                setSplitScreen(true);
                              }}>
                              <AddCircleOutlineOutlinedIcon fontSize="Large" sx={{ marginRight: "6px" }} />
                              <span>New Appointment</span>
                            </Button>
                          )}
                        </>
                      )}
                    </Stack>
                  </Grid>

                  {isShowCalender ? (
                    <Stack
                      direction="row"
                      spacing={1}
                      className="mt-1"
                    >
                      <Box
                        className="card white-bg p-2 w-xs-100"
                        style={{
                          width: splitScreen ? "60%" : "100%",
                          transition: "width 0.3s ease-in-out",
                        }}
                      >
                        <BigCalendar
                          localizer={localizer}
                          events={myEvents}
                          startAccessor="start"
                          endAccessor="end"
                          style={{ height: "93vh" }}
                          selectable
                          onSelectSlot={handleSelect}
                          date={date}
                          view={calenderView}
                          views={["month", "week", "day"]}
                          toolbar={true}
                          step={30}
                          timeslots={2}
                          onNavigate={handleNavigate}
                          onView={handleViewChange}
                          onSelectEvent={handleSelectEvent}
                        />
                      </Box>
                      {splitScreen && (
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ backgroundColor: "#cccccc", width: "2px" }}
                          className="hidden-xs"
                        />
                      )}
                      {splitScreen &&
                        !openCustomerSelection &&
                        !openAddNewCustomer && (
                          <Box
                            className="card white-bg p-2"
                            style={{
                              width: "40%",
                              transition: "width 0.3s ease-in-out",
                              "margin-bottom": "auto",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                margin: "1rem 0.5rem 0rem 0.5rem",
                              }}
                            >
                              <Typography
                                level="h3"
                                component="h3"
                                fontWeight="600"
                                fontSize="2rem"
                                className="h3"
                              >
                                Reservation Plan Info
                              </Typography>
                              <CloseIcon
                                sx={{ cursor: "pointer" }}
                                onClick={handleCloseReservation}
                                height="4rem"
                              />
                            </Box>
                            {selectedReservation && (
                              <Box my={2}>
                                <Stack spacing={0.6}>
                                  <Stack className="text-center" >
                                    {selectedReservation.customerName && <Avatar sx={{ alignSelf: "center", marginBottom: "6px" }}>{selectedReservation.customerName.substring(0, 1)}</Avatar>}
                                    <Typography
                                      level="h1"
                                      component="h1"
                                      fontWeight="600"
                                      className="h1 font-size-20"
                                    >
                                      {selectedReservation.customerName}
                                    </Typography>
                                    <Typography
                                      level="h4"
                                      component="h4"
                                      fontWeight="600"
                                      fontSize="2rem"
                                      className="h4 font-size-14"
                                    >
                                      <PhoneAndroidIcon
                                        sx={{ marginRight: "0.5rem" }}
                                      />
                                      {selectedReservation.mobileNumber}
                                    </Typography>
                                    <Typography
                                      level="h4"
                                      component="h4"
                                      fontWeight="600"
                                      fontSize="2rem"
                                      alignItems="center"
                                      className="h4 font-size-14"
                                    >
                                      <AccessTimeIcon
                                        sx={{ marginRight: "0.5rem" }}
                                      />
                                      {moment(
                                        selectedReservation.reservationDT
                                      ).format("DD/MM/YYYY, hh:mm A")}
                                    </Typography>
                                  </Stack>
                                  <Divider style={{ margin: "10px 0" }} />
                                  {/* <Typography
                                  level="h4"
                                  component="h4"
                                  fontWeight="600"
                                  fontSize="2rem"
                                  marginTop="1rem"
                                  className="h4 font-size-16">
                                  {`Stylists(s):`} 
                                </Typography>
                                {selectedReservation?.selectedStaffs?.length > 0 && <ul style={{marginLeft: "2rem"}}>
                                  {selectedReservation.selectedStaffs.map((selectedStaff) => {
                                      return (
                                        <li>{selectedStaff.name}</li>
                                      )
                                  }) }
                                </ul> } */}
                                  <Typography
                                    level="h4"
                                    component="h4"
                                    fontWeight="700"
                                    fontSize="1.6rem"
                                    className="h4 font-size-16"
                                  >
                                    {`Service list`}
                                  </Typography>
                                  {selectedReservation?.serviceItems?.length >
                                    0 && (
                                      <ul
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          height: "60vh",
                                          overflow: "auto",
                                        }}
                                      >
                                        {selectedReservation.serviceItems.map(
                                          (serviceItem, index) => {
                                            return (
                                              <Card className="card white-bg p-1 mb-1">
                                                <h3 className="m-0">{`${index + 1}) ${serviceItem.description
                                                  }`}</h3>
                                                {!Utils.IsNullOrEmptyArray(
                                                  serviceItem.selectedStaffs
                                                ) && (
                                                    <>
                                                      <Typography
                                                        level="h4"
                                                        component="h4"
                                                        fontWeight="700"
                                                        fontSize="1.2rem"
                                                        marginTop="1rem"
                                                      >
                                                        {`Stylists:`}
                                                      </Typography>
                                                      {serviceItem.selectedStaffs.map(
                                                        (staff) => {
                                                          return (
                                                            <Chip
                                                              className="font-size-14"
                                                              label={staff.name} />
                                                          );
                                                        }
                                                      )}
                                                    </>
                                                  )}
                                              </Card>
                                            );
                                          }
                                        )}
                                      </ul>
                                    )}
                                </Stack>
                              </Box>
                            )}


                            <Stack
                              direction="row"
                              className="fixed-btn"
                            >
                              <Grid container className="container"
                                justifyContent="end"
                              >
                                <Stack direction="row" justifyContent="end" spacing={1} className="font-size-14">
                                  {UserHelper.CanEditAppointments() && (
                                    <Button
                                      className="btn btn-secondary"
                                      variant="contained"
                                      onClick={(e) => handleEditReservation(selectedReservation)}
                                      disabled={disableEditBtn}
                                    >
                                      Edit
                                    </Button>
                                  )}
                                  {UserHelper.CanBilling() && (
                                    <Button
                                      style={{ background: "#FFA500" }}
                                      className="btn btn-primary"
                                      variant="contained"
                                      onClick={(e) => handleBilling(selectedReservation)}
                                      disabled={disableBillingBtn}
                                    >
                                      Billing
                                    </Button>
                                  )}
                                </Stack>
                              </Grid>
                            </Stack>


                          </Box>
                        )}
                      {splitScreen && openCustomerSelection && (
                        <Box
                          className="card white-bg p-2 fixed-xs-btn customer-sec"
                          style={{
                            width: "40%",
                            transition: "width 0.3s ease-in-out",
                            marginBottom: "auto",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              level="h3"
                              component="h3"
                              fontWeight="600"
                              fontSize="2rem"
                              className="h3"
                            >
                              Select Customer
                            </Typography>
                            <Stack direction="row" spacing={1} style={{ "align-self": "center" }}>
                              <IconButton
                                sx={{ cursor: "pointer" }}
                                onClick={handleCloseCustomer}
                                height="4rem">
                                <CloseIcon />
                              </IconButton>

                              <IconButton
                                variant="contained"
                                onClick={handleOpenAddNewCustomer}
                                title="Create New Customer"
                              >
                                <PersonAddAltOutlinedIcon style={{ "font-size": "24px" }} />
                              </IconButton>
                            </Stack>
                          </Box>
                          <Box className="mt-2">
                            <form
                              method="post"
                              className="search-form"
                              onSubmit={handleSearchSubmit}
                              autoComplete="off"
                              alignSelf="center"
                            >
                              <Stack
                                direction="row"
                                alignSelf="center"
                                spacing={1}
                                style={{ width: "100%" }}
                              >
                                <Grid flex="1">
                                  <TextField
                                    className="form-control font-size-14"
                                    placeholder="Search customer..."
                                    value={searchText}
                                    onChange={(e) =>
                                      setSearchText(e.target.value)
                                    }
                                    style={{ "min-width": "280px" }}
                                  />
                                </Grid>
                                <Grid>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className="submitBtn btn-primary h-100"
                                    fullWidth="true"
                                  >
                                    <SearchIcon sx={{ fontSize: 24 }} />
                                  </Button>
                                </Grid>
                              </Stack>
                            </form>
                          </Box>
                          <Grid
                            Container
                            className="customers-list"
                          >
                            <Stack direction="column" spacing={2}>
                              {!Utils.IsNullOrEmptyArray(customerList) &&
                                customerList.map((customer, index) => (
                                  <Card className="card"
                                    sx={{
                                      cursor: "pointer",
                                      paddingBottom:
                                        index === customerList.length - 1
                                          ? "1rem"
                                          : "",
                                    }}
                                    onClick={() =>
                                      handleAddCustomer(customer)
                                    }
                                  >
                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      justifyContent="space-between"
                                      alignItems="center"
                                      alignContent="center"
                                    >
                                      <Stack direction="column" spacing={2}>
                                        <CardContent sx={{ flex: 1 }}>
                                          <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                          >
                                            <Box>
                                              <Typography
                                                variant="h3"
                                                fontWeight="bold"
                                              >
                                                {customer.userName}
                                              </Typography>
                                              <Typography variant="h6">
                                                {customer.mobileNumber}
                                              </Typography>
                                              <Typography variant="h6">
                                                {customer.emailId}
                                              </Typography>
                                            </Box>
                                          </Stack>
                                        </CardContent>
                                      </Stack>
                                      <IconButton sx={{ marginRight: "1rem" }}>
                                        <AddIcon
                                          sx={{ background: "greeen" }}
                                          fontSize="large"
                                          onClick={() =>
                                            handleAddCustomer(customer)
                                          }
                                        />
                                      </IconButton>
                                    </Stack>
                                  </Card>
                                ))}
                            </Stack>
                          </Grid>
                          {/* <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={20}
                          m={2}
                          mt={6}
                          sx={{
                            position: "fixed",
                            bottom: 0,
                            background: "white",
                            padding: "10px",
                            marginBottom: 0,
                            boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
                          }}
                        >
                          <Button
                            style={{ background: "#FFA500" }}
                            className="btn btn-info"
                            variant="contained"
                            onClick={handleCloseCustomer}
                          >
                            Close
                          </Button>
                          <Button
                            className="btn btn-primary"
                            variant="contained"
                            onClick={handleOpenAddNewCustomer}
                          >
                            New Customer
                          </Button>
                        </Stack> */}
                        </Box>
                      )}
                      {splitScreen && openAddNewCustomer && (
                        <Box
                          className="card white-bg p-2 fixed-xs-btn add-customer-modal"
                          style={{
                            width: "40%",
                            transition: "width 0.3s ease-in-out",
                            "margin-bottom": "auto",
                          }}
                        >
                          <Stack>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                level="h3"
                                component="h3"
                                fontWeight="600"
                                fontSize="2rem"
                                className="h3"
                              >
                                Add New Customer
                              </Typography>
                              <CloseIcon
                                onClick={handleCloseNewCustomer}
                                sx={{ cursor: "pointer" }}
                                height="4rem"
                              />
                            </Box>
                            <Stack
                              mt={4}
                              sx={{ minWidth: 120 }}
                              spacing={2}
                              className="font-size-14"
                            >
                              <FormControl fullWidth>
                                <TextField
                                  id="customerKey"
                                  label="Customer Key"
                                  variant="outlined"
                                  value={customerKey}
                                  onChange={(e) => setCustomerKey(e.target.value)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </FormControl>
                              <Stack direction="row" spacing={2}>
                                <FormControl fullWidth>
                                  <TextField
                                    id="customerFirstName"
                                    label="First Name"
                                    variant="outlined"
                                    value={customerFirstName}
                                    onChange={(e) =>
                                      setCustomerFirstName(e.target.value)
                                    }
                                    error={!customerFirstName}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </FormControl>
                                <FormControl fullWidth>
                                  <TextField
                                    id="customerLastName"
                                    label="Last Name"
                                    variant="outlined"
                                    value={customerLastName}
                                    onChange={(e) =>
                                      setCustomerLastName(e.target.value)
                                    }
                                    InputLabelProps={{ shrink: true }}
                                    required={CountryHelper.IsBusinessCountryUSA(
                                      getCountry()
                                    )}
                                  />
                                </FormControl>
                              </Stack>
                              <Stack direction="row" spacing={2}>
                                <FormControl fullWidth>
                                  <NumericTextField
                                    id="mobileNumber"
                                    label="Mobile Number"
                                    variant="outlined"
                                    error={
                                      !Utils.IsMobileValid(customerMobileNumber)
                                    }
                                    value={customerMobileNumber}
                                    setValue={setCustomerMobileNumber}
                                    inputProps={{
                                      maxLength:
                                        CountryHelper.GetMobileNumberMaxLength(),
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </FormControl>
                                <FormControl fullWidth>
                                  <TextField
                                    id="emailId"
                                    label="Email"
                                    variant="outlined"
                                    value={customerEmailId}
                                    error={
                                      Utils.IsNullOrEmpty(customerEmailId)
                                        ? false
                                        : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                                          customerEmailId
                                        )
                                    }
                                    onChange={(e) =>
                                      setCustomerEmailId(e.target.value)
                                    }
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </FormControl>
                              </Stack>
                              <Stack className="address-wrap">
                                <Typography
                                  component="label"
                                  style={{ marginBottom: "10px" }}
                                >
                                  Address
                                </Typography>
                                {isOpenedCustomAddress ? (
                                  <Grid>
                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      className="justify-content-between mt-2"
                                    >
                                      <Typography
                                        level="h5"
                                        component="h5"
                                        fontWeight="600"
                                        fontSize="1.5rem"
                                        className="h5"
                                      >
                                        Use Custom Address
                                      </Typography>
                                      <Switch
                                        checked={isOpenedCustomAddress}
                                        edge="end"
                                        onClick={toggleCustomAddress}
                                      />
                                    </Stack>
                                    <FormControl fullWidth>
                                      <TextField
                                        id="customerCustomAddress"
                                        label="Address"
                                        variant="outlined"
                                        value={customerCustomAddress}
                                        onChange={(e) =>
                                          setCustomerCustomAddress(e.target.value)
                                        }
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </FormControl>
                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      className="m-t-2"
                                    >
                                      <FormControl fullWidth>
                                        <TextField
                                          id="customerCity"
                                          label="City"
                                          variant="outlined"
                                          error={!customerCity}
                                          value={customerCity}
                                          onChange={(e) =>
                                            setCustomerCity(e.target.value)
                                          }
                                          InputLabelProps={{ shrink: true }}
                                        />
                                      </FormControl>
                                      <FormControl fullWidth>
                                        <TextField
                                          id="customerState"
                                          label={
                                            CountryHelper.IsBusinessCountryUAE(
                                              getCountry()
                                            )
                                              ? "Emirate"
                                              : "State"
                                          }
                                          variant="outlined"
                                          value={customerState}
                                          onChange={(e) =>
                                            setCustomerState(e.target.value)
                                          }
                                          InputLabelProps={{ shrink: true }}
                                        />
                                      </FormControl>
                                      <FormControl fullWidth>
                                        <TextField
                                          id="customerPincode"
                                          label="Pincode"
                                          variant="outlined"
                                          value={customerPincode}
                                          onChange={(e) =>
                                            setCustomerPincode(e.target.value)
                                          }
                                          InputLabelProps={{ shrink: true }}
                                        />
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                ) : (
                                  <>
                                    {!Utils.IsNullOrEmpty(
                                      getCountry().ISOCode
                                    ) && (
                                        <AddressField
                                          id="branchAddress"
                                          variant="outlined"
                                          label="Branch Address"
                                          required
                                          isValidate={true}
                                          countryISoCode={getCountry().ISOCode}
                                          value={customerAddress}
                                          setValue={setCustomerAddress}
                                        />
                                      )}
                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      className="justify-content-between mt-2"
                                    >
                                      <Typography
                                        level="h5"
                                        component="h5"
                                        fontWeight="600"
                                        fontSize="1.5rem"
                                        className="h5"
                                      >
                                        Use Custom Address
                                      </Typography>
                                      <Switch
                                        checked={isOpenedCustomAddress}
                                        edge="end"
                                        onClick={toggleCustomAddress}
                                      />
                                    </Stack>
                                  </>
                                )}
                              </Stack>

                              <Stack direction="row" spacing={2}>
                                <FormControl>
                                  <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                  >
                                    <DatePicker
                                      id="businessOpen"
                                      label="Date of Birth"
                                      value={customerDOB}
                                      onChange={(newValue) =>
                                        setCustomerDOB(newValue)
                                      }
                                      closeOnSelect={true}
                                      minDate={moment("1973-01-01T00:00:00")}
                                    />
                                  </LocalizationProvider>
                                </FormControl>
                                <FormControl>
                                  <Typography
                                    level="h3"
                                    component="h3"
                                    fontWeight="600"
                                    fontSize="2rem"
                                    className="h3"
                                  >
                                    Gender :
                                  </Typography>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="Male"
                                    name="radio-buttons-group"
                                  >
                                    <FormControlLabel
                                      value="Male"
                                      control={<Radio />}
                                      label="Male"
                                      onChange={(e) =>
                                        setCustomerGender(e.target.value)
                                      }
                                    />
                                    <FormControlLabel
                                      value="Female"
                                      control={<Radio />}
                                      label="Female"
                                      onChange={(e) =>
                                        setCustomerGender(e.target.value)
                                      }
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Stack>

                              <Typography
                                level="h3"
                                component="h3"
                                fontWeight="600"
                                fontSize="2rem"
                                className="h3"
                              >
                                Business Details :
                              </Typography>
                              <Stack
                                direction="row"
                                spacing={2}
                                className="xs-column"
                              >
                                <FormControl fullWidth className="my-xs-1">
                                  <TextField
                                    id="customerBusinessName"
                                    label="Company Name"
                                    variant="outlined"
                                    value={customerBusinessName}
                                    onChange={(e) =>
                                      setCustomerBusinessName(e.target.value)
                                    }
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </FormControl>
                                <FormControl fullWidth className="my-xs-1">
                                  <TextField
                                    id="customerBusinessGSTIN"
                                    label={
                                      CountryHelper.IsBusinessCountryIndia(
                                        getCountry()
                                      )
                                        ? "Company GSTIN"
                                        : CountryHelper.IsBusinessCountryUSA(
                                          getCountry()
                                        )
                                          ? "Company Tax Id"
                                          : "Tax Id"
                                    }
                                    variant="outlined"
                                    value={customerBusinessGSTIN}
                                    onChange={(e) =>
                                      setCustomerBusinessGSTIN(e.target.value)
                                    }
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </FormControl>
                                {CountryHelper.IsBusinessCountryIndia(
                                  getCountry()
                                ) && (
                                    <FormControl fullWidth className="my-xs-1">
                                      <TextField
                                        id="customerGSTINCode"
                                        label="GSTIN Code"
                                        variant="outlined"
                                        value={customerGSTINCode}
                                        error={Utils.IsNullOrEmpty(customerGSTINCode) ? false : !Regexes.GST.test(customerGSTINCode)}
                                        onChange={(e) =>
                                          setCustomerGSTINCode(e.target.value)
                                        }
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </FormControl>
                                  )}
                              </Stack>

                            </Stack>
                            <Stack
                              direction="row"
                              className="fixed-btn"
                            >
                              <Grid container className="container"
                                justifyContent="end"
                              >
                                <Stack direction="row" justifyContent="end" spacing={1} className="font-size-14">
                                  <Button
                                    style={{ background: "#FFA500" }}
                                    className="btn btn-secondary"
                                    variant="contained"
                                    onClick={handleCloseNewCustomer}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    className="btn btn-primary"
                                    variant="contained"
                                    onClick={() => customerSaveSubmit()}
                                    disabled={saveCustomerDisabled}
                                  >
                                    Save
                                  </Button>
                                </Stack>
                              </Grid>
                            </Stack>


                          </Stack>
                        </Box>
                      )}
                    </Stack>
                  ) : (
                    <>
                      <Box className="w-100">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="font-size-14">
                          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label={"Open - " + openReservationDetails.length} {...a11yProps(0)} />
                            <Tab label={"Completed - " + closedReservationDetails.length} {...a11yProps(1)} />
                          </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                          {openReservationDetails.length !== 0 && (
                            <Stack justifyContent="end">
                              <IconButton
                                variant="contained"
                                onClick={() => showAppointmentReceipt()}
                                title="Print All"
                                sx={{ marginRight: "16px", alignSelf: "end" }}
                              >
                                <PrintIcon style={{ "font-size": "24px" }} />
                              </IconButton>
                            </Stack>
                          )}
                          <TableContainer component={Paper} className="visit-table">
                            <Table stickyHeader area-lang="simple table">
                              {/* <TableHead>
                            <TableRow>
                              <TableCell colSpan="5">
                                <Typography
                                  component="h3"
                                  fontSize="1.6rem"
                                  fontWeight="600">
                                  Appointment Details
                                  </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead> */}
                              <TableBody>
                                {(Array.isArray(openReservationDetails)
                                  ? openReservationDetails
                                  : []
                                ).map((row, index) => (
                                  (row != null &&
                                    <TableRow key={index}>
                                      <TableCell>
                                        <Stack direction="row" spacing={1}>
                                          {((Utils.IsNullOrEmpty(row.customerName) == false) && <Avatar>{row.customerName.substring(0, 1)}</Avatar>)}
                                          <Box>
                                            <Typography
                                              component="h3"
                                              className="m-0"
                                              fontWeight="800"
                                              fontSize="18px"
                                            >
                                              {row.customerName}
                                            </Typography>
                                            <Typography component="p">
                                              <PhoneAndroidIcon sx={{ marginRight: "0.5rem" }} />
                                              {row.mobileNumber}
                                            </Typography>
                                          </Box>
                                        </Stack>
                                      </TableCell>
                                      <TableCell>
                                        <ol style={{ margin: 0, paddingLeft: "20px" }}>
                                          {Array.isArray(row.serviceItems) &&
                                            row.serviceItems.length > 0 ? (
                                            row.serviceItems.map((service, i) => (
                                              <li key={i} className="card p-1 mb-1">
                                                <h3 className="mt-0 mb-1">{service.description}</h3>
                                                <p className="m-0">
                                                  {service.selectedStaffs?.length > 0 || service.serviceItemStaffs?.length > 0 ? (
                                                    <>
                                                      <strong>{" Stylist: "}</strong>
                                                      <Chip
                                                        className="font-size-14"
                                                        label=
                                                        {(service.selectedStaffs?.length > 0 ? service.selectedStaffs : service.serviceItemStaffs)
                                                          .map(
                                                            (staff, j) => (
                                                              <span key={j}>
                                                                {staff.name}
                                                                {j <
                                                                  (service.selectedStaffs?.length > 0 ? service.selectedStaffs : service.serviceItemStaffs)
                                                                    .length -
                                                                  1
                                                                  ? ", "
                                                                  : ""}
                                                              </span>
                                                            )
                                                          )}
                                                      />
                                                    </>
                                                  ) : (
                                                    " - No Stylist"
                                                  )}
                                                </p>
                                              </li>
                                            ))
                                          ) : (
                                            <li>No Services</li>
                                          )}
                                        </ol>
                                      </TableCell>
                                      <TableCell>{row.assignedStylist}</TableCell>
                                      <TableCell>
                                        <p className="mb-0">
                                          <strong>
                                            {Utils.ToLocalDT(
                                              row.reservationDT,
                                              localSettings.country
                                            )}
                                          </strong>
                                        </p>
                                        <Typography fontSize="1.2rem">
                                          <AccessTimeIcon
                                            sx={{ marginRight: "0.5rem" }}
                                          />
                                          {getTotalServiceTime(row.serviceItems) ? `Duration: ${getTotalServiceTime(row.serviceItems)} mins` : ""}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="right">
                                        <Grid container spacing={1} justifyContent="flex-end">
                                          <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{
                                              justifyContent: "center",
                                              alignItems: "flex-end",
                                            }}
                                          >
                                            {UserHelper.CanBilling() && (
                                              <>
                                                {handleBiilingBtnVisible(row) && (
                                                  <Button
                                                    className="btn btn-sm btn-primary"
                                                    variant="contained"
                                                    onClick={() => handleBilling(row)}
                                                  >
                                                    Billing
                                                  </Button>
                                                )}
                                              </>
                                            )}
                                            {UserHelper.CanEditAppointments() && (
                                              <>
                                                {handleEditBtnVisible(row) && (
                                                  <Button
                                                    className="btn btn-sm btn-primary"
                                                    variant="contained"
                                                    onClick={() => handleEditReservation(row)}
                                                  >
                                                    Edit
                                                  </Button>
                                                )}
                                              </>
                                            )}
                                            <IconButton
                                              variant="contained"
                                              onClick={() => showAppointmentReceipt(row)}
                                              title="Print"
                                            >
                                              <PrintIcon style={{ "font-size": "24px" }} />
                                            </IconButton>
                                          </Stack>
                                        </Grid>
                                      </TableCell>
                                    </TableRow>
                                  )))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          {openReservationDetails.length === 0 && (
                            <NoAppointments />
                          )}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                          <TableContainer component={Paper} className="visit-table mt-3">
                            <Table stickyHeader area-lang="simple table">
                              {/* <TableHead>
                            <TableRow>
                              <TableCell colSpan="5">
                                <Typography
                                  component="h3"
                                  fontSize="1.6rem"
                                  fontWeight="600">
                                  Appointment Details
                                  </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead> */}
                              <TableBody>
                                {(Array.isArray(closedReservationDetails)
                                  ? closedReservationDetails
                                  : []
                                ).map((row, index) => (
                                  <TableRow onClick={(e) => passVisitorObj(row, e)} key={index}>
                                    <TableCell>
                                      <Stack direction="row" spacing={1}>
                                        {row.customerName && <Avatar>{row.customerName.substring(0, 1)}</Avatar>}
                                        <Stack rowGap="5px">
                                          <Typography
                                            component="h3"
                                            className="m-0"
                                            fontWeight="800"
                                            fontSize="18px"
                                          >
                                            {row.customerName}
                                          </Typography>
                                          <Typography component="p" sx={{ "display": "flex" }}>
                                            <PhoneAndroidIcon sx={{ marginRight: "0.5rem" }} />
                                            {row.mobileNumber}
                                          </Typography>
                                          <Typography component="p" sx={{ "display": "flex" }} title="Invoice Number">
                                            <DescriptionOutlinedIcon sx={{ marginRight: "0.5rem" }} /> {row.invoiceNumber}
                                          </Typography>
                                        </Stack>
                                      </Stack>
                                    </TableCell>
                                    <TableCell>
                                      <ol style={{ margin: 0, paddingLeft: "10px", width: "100%" }}>
                                        {Array.isArray(row.serviceItems) &&
                                          row.serviceItems.length > 0 ? (
                                          row.serviceItems.map((service, i) => (
                                            <li key={i} className="card p-1 mb-1">
                                              <h3 className="mt-0 mb-1">{service.description}</h3>
                                              <p className="m-0">
                                                {service.selectedStaffs?.length > 0 || service.serviceItemStaffs?.length > 0 ? (
                                                  <>
                                                    <strong>{" Stylist: "}</strong>
                                                    <Chip
                                                      className="font-size-14"
                                                      label=
                                                      {(service.selectedStaffs?.length > 0 ? service.selectedStaffs : service.serviceItemStaffs).map(
                                                        (staff, j) => (
                                                          <span key={j}>
                                                            {staff.name}
                                                            {j <
                                                              (service.selectedStaffs?.length > 0 ? service.selectedStaffs : service.serviceItemStaffs)
                                                                .length -
                                                              1
                                                              ? ", "
                                                              : ""}
                                                          </span>
                                                        )
                                                      )}
                                                    />
                                                  </>
                                                ) : (
                                                  " - No Stylist"
                                                )}
                                              </p>
                                            </li>
                                          ))
                                        ) : (
                                          <li>No Services</li>
                                        )}
                                      </ol>
                                    </TableCell>
                                    <TableCell>{row.assignedStylist}</TableCell>
                                    <TableCell>
                                      <p className="mb-0">
                                        <strong>
                                          {Utils.ToLocalDT(
                                            row.reservationDT,
                                            localSettings.country
                                          )}
                                        </strong>
                                      </p>
                                      <Typography fontSize="1.2rem">
                                        <AccessTimeIcon
                                          sx={{ marginRight: "0.5rem" }}
                                        />
                                        {getTotalServiceTime(row.serviceItems) ? `Duration: ${getTotalServiceTime(row.serviceItems)} mins` : ""}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                      <Grid container spacing={1} justifyContent="flex-end">
                                        <Stack
                                          direction="row"
                                          spacing={2}
                                          sx={{
                                            justifyContent: "center",
                                            alignItems: "flex-end",
                                          }}
                                        >
                                          {handleBiilingBtnVisible(row) && (
                                            <Button
                                              className="btn btn-sm btn-primary"
                                              variant="contained"
                                              onClick={() => handleBilling(row)}
                                            >
                                              Billing
                                            </Button>
                                          )}
                                          {handleEditBtnVisible(row) && (
                                            <Button
                                              className="btn btn-sm btn-primary"
                                              variant="contained"
                                              onClick={() => handleEditReservation(row)}
                                            >
                                              Edit
                                            </Button>
                                          )}
                                          {/* <IconButton
                                            variant="contained"
                                            onClick={() => showAppointmentReceipt(row)}
                                            title="Print"
                                          >
                                            <PrintIcon style={{ "font-size": "24px" }} />
                                          </IconButton> */}
                                        </Stack>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          {closedReservationDetails.length === 0 && (
                            <NoAppointments />
                          )}
                        </CustomTabPanel>
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default Calendar;
