import React, { useState, useEffect } from "react";
import "../../Style.css";
import Utils from "../../core/Utils";
import UserHelper from "../../core/UserHelper";
import { connectToStore } from "../../data/store";
import SideBarMenuItems from "../SideBarMenuItems";
import SettingsMenuItems from "../SettingsMenuItems";
import ReportMenuItems from "../ReportMenuItems";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {
  SideBarMenuNames,
  SettingsMenuList,
  MaxDateLimit,
  UserRoles,
} from "../../core/Constants";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MessagePopup from "../common/MessagePopUp";
import { Typography } from "@mui/material";
import "react-calendar/dist/Calendar.css";
import { Grid, Box, Container, Stack, Switch } from "@mui/material";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Loader from "../common/Loader";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { EmployeeReportPage, LoginPage } from "../../core/PageConstants";
import InputLabel from "@mui/material/InputLabel";
import { getBusiness } from "../../data/localSettingsActions";
import { postAsync } from "../../core/serviceClient";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CountryHelper from "../../core/CountryHelper";

const JobcardsReport = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [reportContent, setReportContent] = useState("");
  const [filterByBranch, setFilterByBranch] = useState(false);
  const [filterByTechBranch, setFilterByTechBranch] = useState(false);
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [statementMethod, setStatementMethod] = useState("Summary");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [selectedTechBranch, setSelectedTechBranch] = useState("");
  const [techBranches, setTechBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branches, setBranches] = useState([]);
  const [allTechnicians, setAllTechnicians] = useState([]);

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };
  const handleFilterByBranchChange = (event) => {
    setFilterByBranch(event.target.checked);
  };

  const handleFilterByTechBranchChange = (event) => {
    setFilterByTechBranch(event.target.checked);
  };

  const handleSubmit = () => {
    getStatementStatus(statementMethod);
  };

  const getStatementStatus = async (statementMethod) => {
    setIsLoading(true);
    let url = "reports/EmployeeReport?";
    const branchId =
      filterByBranch && selectedBranch !== null ? selectedBranch : "";
    const UserId =
      filterByTechBranch && selectedTechBranch !== null
        ? selectedTechBranch
        : "";
    let reportUrl =
      Utils.GetServiceUrl() +
      url +
      "branchId=" +
      branchId +
      "&businessId=" +
      localSettings.business.id +
      "&startDate=" +
      Utils.GetISODateTimeString(selectedDate[0]) +
      "&endDate=" +
      Utils.GetISODateTimeString(selectedDate[1]) +
      "&userId=" +
      UserId +
      "&token=" +
      localSettings.token +
      "&token=" +
      localSettings.token +
      "&isDownloadVisible=" +
      true;
    window.open(reportUrl, "_blank");

    setIsLoading(false);
  };

  const handleShowReport = () => {
    setIsLoading(true);
    let url = "reports/EmployeeReport?";
    const branchId =
      filterByBranch && selectedBranch !== null ? selectedBranch : "";
    const UserId =
      filterByTechBranch && selectedTechBranch !== null
        ? selectedTechBranch
        : "";
    let reportUrl =
      Utils.GetServiceUrl() +
      url +
      "branchId=" +
      branchId +
      "&businessId=" +
      localSettings.business.id +
      "&startDate=" +
      Utils.GetISODateTimeString(selectedDate[0]) +
      "&endDate=" +
      Utils.GetISODateTimeString(selectedDate[1]) +
      "&userId=" +
      UserId +
      "&token=" +
      localSettings.token;
    setReportContent(reportUrl);

    setIsLoading(false);
  };

  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      if (dateArray[0]._isValid == false || dateArray[1]._isValid == false) {
        setMsgText(
          "Please select a valid date range."
        );
        setMsgOpen(true);
      }
      else {
        let dateDiff = dateArray[1].diff(dateArray[0], "days");
        if (dateDiff > MaxDateLimit) {
          setMsgText(
            "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
          );

          setMsgOpen(true);
        } else {
          setSelectedDate(dateArray);
        }
      }
    }
  };

  const checkAuthentication = () => {
    if (
      UserHelper.CheckPermission(EmployeeReportPage, navigate, localSettings)
    ) {
      const business = getBusiness();
      if (
        !Utils.IsNullOrEmptyObject(business) &&
        !Utils.IsNullOrEmptyArray(business.branchLocations)
      ) {
        setBranches(business.branchLocations);
      }
      setTechnicians();
    }
  };
  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleSelect = (event) => {
    setSelectedBranch(event.target.value);
    if (!Utils.IsNullOrEmpty(event.target.value) && !Utils.IsNullOrEmptyArray(allTechnicians)) {
      var branchTechnicians = allTechnicians.filter((x) => x.branchId === event.target.value);
      setTechBranches(branchTechnicians);
    }
    else {
      setTechBranches(!Utils.IsNullOrEmptyArray(allTechnicians) ? allTechnicians : []);
    }
  };
  const getTechnicians = async () => {
    setIsLoading(true);
    let result = await postAsync("Account/GetUsers", {
      Role: UserRoles.Technician,
    });
    setIsNavigateToLogin(result.isNavigateToLogin);
    setIsLoading(false);
    if (result.error) {
      setMsgOpen(true);
      setMsgText(result.errorMessage);
      return;
    }
    return result.data.userProfiles;
  };

  const setTechnicians = async () => {
    const technicians = await getTechnicians();
    setAllTechnicians(technicians);
    if (!Utils.IsNullOrEmptyArray(technicians)) {
      if (!Utils.IsNullOrEmpty(selectedBranch)) {
        var branchTechnicians = technicians.filter((x) => x.branchId === selectedBranch);
        setTechBranches(technicians);
      }
      else
        setTechBranches(technicians);
    }
  };

  const handleTechSelect = (event) => {
    setSelectedTechBranch(event.target.value);
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />

        <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.InvoiceReport} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <ReportMenuItems
                selectedMenu={SettingsMenuList.BusinessReports.Employee}
              />
              <Grid xs={12} md={9}>
                <Grid spacing={2} className="form-sec">
                  <form container spacing={2} onSubmit={handleSubmit}>
                    <Stack
                        alignSelf="center"
                        justifyContent="end"
                        direction="row"
                        flex="1"
                        className="text-end mb-2"
                      >
                      <Grid>
                        <Stack
                          direction="row"
                          spacing={1}
                          style={{ height: "100%" }}
                        >
                          <DateRangePicker
                            showLeadingZeros={true}
                            onChange={onDateChange}
                            maxDate={new Date()}
                            value={selectedDate}
                            locale={CountryHelper.GetDateCulture(localSettings.country.Culture)}
                            clearIcon={null}
                            calendarIcon={
                              <CalendarMonthOutlinedIcon
                                style={{
                                  "font-size": "24px",
                                  "align-self": "center",
                                  color: "#666666",
                                }}
                              />
                            }
                          />
                        </Stack>
                      </Grid>
                    </Stack>

                    <Grid container className="card" padding={1}>
                      <Grid xs={6}>
                        <Stack direction="column" alignItems="start">
                          <Stack direction="row">
                            <Switch
                              checked={filterByBranch}
                              onChange={handleFilterByBranchChange}
                            />

                            <Typography
                              variant="h6"
                              style={{ "white-space": "nowrap", marginLeft: "0" }}
                              alignSelf="center"
                            >
                              Filter by branch
                            </Typography>
                          </Stack>

                          {filterByBranch && (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              className="font-size-14"
                            >
                              <InputLabel id="Branch-label">
                                Branch Name
                              </InputLabel>
                              <Select
                                value={selectedBranch}
                                onChange={handleSelect}
                                label="Branch Name"
                                InputLabelProps={{ shrink: true }}
                                style={{
                                  height: "50px",
                                }}
                              >
                                {branches.map((branch, index) => (
                                  <MenuItem key={index} value={branch.branchId}>
                                    {branch.branchName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        </Stack>
                      </Grid>
                      <Grid xs={6}>
                        <Stack
                          direction="column"
                          alignItems="center"
                          style={{ "padding-left": "10px" }}
                        >
                          <Stack direction="row">
                            <Switch
                              checked={filterByTechBranch}
                              onChange={handleFilterByTechBranchChange}
                            />

                            <Typography
                              variant="h6"
                              style={{ "white-space": "nowrap", marginLeft: "0" }}
                              alignSelf="center"
                            >
                              Filter by technician
                            </Typography>
                          </Stack>
                          {filterByTechBranch && (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              className="font-size-14"
                            >
                              <InputLabel id="Branch-label">
                                Tech. Name
                              </InputLabel>
                              <Select
                                value={selectedTechBranch}
                                onChange={handleTechSelect}
                                label="Tech. Name"
                                InputLabelProps={{ shrink: true }}
                                style={{
                                  height: "50px",
                                }}
                              >
                                {!Utils.IsNullOrEmptyArray(techBranches) &&
                                  techBranches.map((branch, index) => (
                                    <MenuItem key={index} value={branch.id}>
                                      {branch.userName}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                  <iframe
                    className="reportIframe card"
                    src={reportContent}
                    style={{
                      width: "100%",
                      height: "calc(100vh - 270px)",
                    }}
                  ></iframe>
                  <br />

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      container
                      justifyContent="center" // Horizontally center the content
                      alignItems="center" // Vertically center the content
                    >
                      <FormControl fullWidth>
                        <Button
                          className="btn btn-primary"
                          variant="contained"
                          type="submit"
                          onClick={handleShowReport}
                        >
                          Show Report
                        </Button>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      container
                      justifyContent="center" // Horizontally center the content
                      alignItems="center" // Vertically center the content
                    >
                      <FormControl fullWidth>
                        <Button
                          className="btn btn-primary"
                          variant="contained"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          DOWNLOAD
                        </Button>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default JobcardsReport;
