import "./App.css";
import React from "react";
import {
  unstable_HistoryRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { LandingPage, Pages, UnAuthorizedPage } from "./core/PageConstants";
import history from "./history";
import { ThemeProvider, createMuiTheme } from '@mui/material/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Aspekta',
      'Arial',
      'sans-serif'
    ].join(','),
  }
});

export default function App() {
  var el = document.getElementById("indexLoading");
  if (el) el.remove();
  return (
    <ThemeProvider theme={theme}>
    <Router history={history}>
      <Routes>
        <Route path="*" element={<Navigate to={UnAuthorizedPage.Path} />} />
        <Route path="/" element={<Navigate to={LandingPage.Path} />} />
        {Pages.map((page, index) => (
          <Route path={page.Path} element={page.Element} />
        ))}
      </Routes>
      </Router>
      </ThemeProvider>
  );
}
