import Dialog from "@mui/material/Dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Card, DialogActions, DialogContentText, FormHelperText, InputAdornment, Modal, OutlinedInput } from "@mui/material";
import Assign_Icon from "../images/assign_user.png";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Utils from "../core/Utils";
import { ConfirmDialog } from "./common/ConfirmDialog";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import {
  getBranch,
  getBusiness,
  getCampaigns,
  getCountry,
  getProducts,
  setBusiness as setUpdatedBusiness,
  setBranch as setUpdatedBranch,
  getMakeModels,
  getEnterpriseId,
  getUser,
} from "../data/localSettingsActions";
import { getGeocodeAddress, postAsync } from "../core/serviceClient";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ShareIcon from "@mui/icons-material/Share";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Cash_Icon from "../images/cash.png";
import Activate_Now from "../images/Activate_Now.png";
import Add_To_Queue from "../images/Add_To_Queue.png";
import Card_Icon from "../images/creditCard.png";
import Netbanking_Icon from "../images/Netbanking.png";
import Upi_Icon from "../images/Upi.png";
import Split_Icon from "../images/partial-paid.png";
import {
  BenefitTypes,
  BusinessCategories,
  CampaignProductCategories,
  CampaignSegmentTypes,
  CampaignTypes,
  ClassificationTypes,
  ConfirmTypes,
  India,
  OfferClassificationTypes,
  OfferTypes,
  PartialPaymentErrorTypes,
  PassEventTypes,
  PaymentMethodTypes,
  PaymentTypes,
  ServiceItemTypes,
  SideBarMenuNames,
  VisitStatuses,
  UAE,
  VisitTypeEnum,
  AlertSMSNotSupported,
  MaxRazorPayLimit,
  MinRazorPayLimit,
} from "../core/Constants";
import {
  convertServiceItemObject,
  getConsumables,
  getLabours,
  getServiceItemFromProduct,
  getServices,
  getSpares,
  getTaxAmount,
  getTotalPrice,
} from "../helpers/productHelper";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import CountryHelper from "../core/CountryHelper";
import BusinessHelper from "../core/BusinessHelper";
import moment from "moment";
import SideBarMenuItems from "./SideBarMenuItems";
import Loader from "./common/Loader";
import MessagePopup from "./common/MessagePopUp";
import PaymentLink_Icon from "../images/paymentlink.png";
import NumericTextField from "./common/controls/NumericTextField";
import UserHelper from "../core/UserHelper";
import {
  LoginPage,
  OverviewPage,
  PaymentPage,
  PaymentsPage,
  VisitsPage,
} from "../core/PageConstants";
import AddressField from "./common/controls/AddressField";
import { CanRaisePaymentLink } from "../core/ModuleHelper";
import AmountTypography from "./common/controls/AmountTypography";
import CompValueEntryField from "./common/controls/CompValueEntryField";
import ItemDiscountComponent from "./common/ItemDiscountComponent";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import UPIComponent from "./common/UPIComponent";
import CardComponent from "./common/CardComponent";
import PaymentTypeDialog from "./common/PaymentTypeDialog";
import DecimalEntryField from "./common/controls/DecimalEntryField";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { DeleteOutline } from "@mui/icons-material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function TaxComponent({ currentTax, country }) {
  return (
    <Typography
      level="p"
      component="p"
      fontSize="12px"
      className="p m-0"
    >
      Tax:
      <strong className="ml-h">{Utils.GetCurrency(currentTax, country, 2)}</strong>
    </Typography>
  );
}

const partialPayActions = {
  FullPay: "FullPay",
  PartialPay: "PartialPay",
  PartialPayWithoutCustomer: "PartialPayWithoutCustomer",
  ProcessingPartialPay: "ProcessingPartialPay",
  Close: "Close",
  Book: "Book",
};

const customerSaveType = {
  Error: "Error",
  Success: "Success",
};

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);

  //Alert Hooks
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");

  //Loader hook
  const [isLoading, setIsLoading] = useState(false);

  const setAlert = (msg) => {
    setAlertMessage(msg);
    setShowAlert(true);
  };

  const [masterServiceItemsList, setMasterServiceItemsList] = useState([]);
  const [pass, setPass] = useState({});
  const [visit, setVisit] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [amcPurchase, setAMCPurchase] = useState(false);
  const [productsInventory, setProductsInventory] = useState([]);
  const [anonymous, setAnonymous] = useState(false);
  const [branch, setBranch] = useState({});
  const [business, setBusiness] = useState({});
  const [openAddressSelector, setOpenAddressSelector] = useState(false);
  const [openPaymentSelector, setOpenPaymentSelector] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [serviceItems, setServiceItems] = useState([]);
  const [orderComplimentaryList, setOrderComplimentaryList] = useState([]);
  const [itemComplimentaryList, setItemComplimentaryList] = useState([]);
  const [orderLevelDiscounts, setOrderLevelDiscounts] = useState([]);
  const [compType, setCompType] = useState("Discount");
  const [compValue, setCompValue] = useState(0);
  const [countryInfo, setCountryInfo] = useState({});
  const [serviceItemTotalTax, setServiceItemTotalTax] = useState(0);
  const [existItemComp, setExistItemComp] = useState({});
  const [ComplimentaryModal, openComplimentaryModal] = useState(false);
  const [orginalTotal, setOrginalTotal] = useState(0);
  const [serviceItemTotalPrice, setServiceItemTotalPrice] = useState(0);
  const [tipAmount, setTipAmount] = useState(0);
  const [tipModal, openTipModal] = useState(false);
  const [paymentTypeUpiModal, openPaymentTypeUpiModal] = useState(false);
  const [paymentTypeCashModal, openPaymentTypeCashModal] = useState(false);
  const [paymentTypeCardModal, openPaymentTypeCardModal] = useState(false);
  const [csProductName, setCsProductName] = useState("");
  const [isOpened, setIsOpened] = useState(false);
  const [reverseCharge, setReverseCharge] = useState("");
  const [vehicleMake, setVehicleMake] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleFuelType, setVehicleFuelType] = useState("");
  const [vehicleMakeModelList, setVehicleMakeModelList] = useState([]);
  const [vehicleMakeItem, setVehicleMakeItem] = useState([]);
  const [sendInvoiceEnable, setSendInvoiceEnable] = useState(false);
  const [offersViewModel, setOffersViewModel] = useState([]);
  const [applicableServiceItems, setApplicableServiceItems] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [ComplimentaryValue, setComplimentaryValue] = useState("");
  const [shippingFromDefaultAddress, setShippingFromDefaultAddress] =
    useState("");
  const [maxPrice, setMaxPrice] = useState(0);
  const [
    shippingFromAddressDefaultEnabled,
    setShippingFromAddressDefaultEnabled,
  ] = useState(true);
  const [shippingToAddressDefaultEnabled, setShippingToAddressDefaultEnabled] =
    useState(true);
  const [shippingToDefaultAddress, setShippingToDefaultAddress] = useState("");
  const [shippingFromNewAddress, setShippingFromNewAddress] = useState("");
  const [shippingToNewAddress, setShippingToNewAddress] = useState("");
  const [shippingToEmailId, setShippingToEmailId] = useState("");
  const [shippingToPhoneNumber, setShippingToPhoneNumber] = useState("");
  const [shippingToStateCode, setShippingToStateCode] = useState("");
  const [shippingFromEmailId, setShippingFromEmailId] = useState("");
  const [shippingFromPhoneNumber, setShippingFromPhoneNumber] = useState("");
  const [shippingFromStateCode, setShippingFromStateCode] = useState("");
  const [shippingFromAddressObject, setShippingFromAddressObject] =
    useState("");
  const [shippingToAddressObject, setShippingToAddressObject] = useState("");
  const [reverseChargeApplicable, setReverseChargeApplicable] = useState(false);
  const [autoPay, setAutoPay] = useState(false);
  const [cash, setCash] = useState(false);
  const [payNow, setPayNow] = useState(false);
  const [payTerminal, setPayTerminal] = useState(false);
  const [cardPay, setCardPay] = useState(false);
  const [upi, setUPI] = useState(false);
  const [netBanking, setNetBanking] = useState(false);
  const [payLater, setPayLater] = useState(false);
  const [nameOnCard, setNameOnCard] = useState("");
  const [lastFourDigits, setLastFourDigits] = useState("");
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [showUPIDetails, setShowUPIDetails] = useState(false);
  const [cardDetailsDisabled, setCardDetailsDisabled] = useState(true);
  const [upiValue, setUPIValue] = useState("");
  const [upiAmount, setUpiAmount] = useState(0);
  const [comments, setComments] = useState("");
  const [paymentReceivedBy, setPaymentReceivedBy] = useState(
    getUser().userName
  );
  const [isSalon, setIsSalon] = useState(false);
  const [serviceStaffs, setServiceStaffs] = useState([]);
  const [openStaffSelection, setOpenStaffSelection] = useState(false);
  const [selectedServiceItem, setSelectedServiceItem] = useState({});
  const [showReceiptOptions, setShowReceiptOptions] = useState(false);
  const [customerMobileNumber, setCustomerMobileNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmailId, setCustomerEmailId] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [smsSent, setSMSSent] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [isAutomobileWithoutVehicle, setIsAutomobileWithoutVehicle] =
    useState(false);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [customMade, setCustomMade] = useState(false);
  const [vehicleCustomMake, setVehicleCustomMake] = useState("");
  const [vehicleCustomModel, setVehicleCustomModel] = useState("");
  const [addressDetails_Address, setAddresDetails_Address] = useState("");
  const [addressDetails_City, setAddresDetails_City] = useState("");
  const [addressDetails_State, setAddresDetails_State] = useState("");
  const [addressDetails_Pincode, setAddressDetails_Pincode] = useState("");
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [invoiceLabelsList, setInvoiceLabelsList] = useState([]);
  const [invoiceLabel, setInvoiceLabel] = useState();
  const [selectedInvoiceLabel, setSelectedInvoicelabel] = useState({});
  const [paidAmount, setPaidAmount] = useState(0);
  const [isBusinessCountryIndia, setIsBusinessCountryIndia] = useState(false);
  const [isBusinessCountryUAE, setIsBusinessCountryUAE] = useState(false);
  const [isBusinessCountryUS, setIsBusinessCountryUS] = useState(false);
  const [isBusinessCountryMalaysia, setIsBusinessCountryMalaysia] =
    useState(false);
  const [addressISOCode, setAddressISOCode] = useState({});
  const [activeMenu, setActiveMenu] = useState("");
  const [lpo, setLPO] = useState("");

  //Partial pay hooks
  const [isPartialPaySelected, setIsPartialPaySelected] = useState(false);
  const [showConfirmDialogForPartialPay, setShowConfirmDialogForPartialPay] =
    useState(false);
  const [showPartialPayEntryDialog, setShowPartialPayEntryDialog] =
    useState(false);
  const [processingPartialPay, setProcessingPartialPay] = useState(false);
  const [
    showCustomerSetupDialogForPartialPay,
    setShowCustomerSetupDialogForPartialPay,
  ] = useState(false);
  const [outstandingAmount, setOutstandingAmount] = useState(0);
  const [alreadyPaidAmount, setAlreadyPaidAmount] = useState(0);
  const [paidByCustomerAmountString, setPaidByCustomerAmountString] =
    useState("");
  const [paidByCustomerAmount, setPaidByCustomerAmount] = useState(0);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [
    isPartialPayProceedButtonDisabled,
    setIsPartialPayProceedButtonDisabled,
  ] = useState(true);
  const [isCustomerSaveDisabled, setIsCustomerSaveDisabled] = useState(true);

  //Payment links hooks
  const [paymentLink, setPaymentLink] = useState(false);
  const [showPaymentLinkDetails, setShowPaymentLinkDetails] = useState(false);
  const [settlementAccount, setSettlementAccount] = useState({});
  const [settlementAccounts, setSettlementAccounts] = useState([]);
  const [paymentLinkAmount, setPaymentLinkAmount] = useState("");
  const [paymentLinkMobileNumber, setPaymentLinkMobileNumber] = useState("");
  const [selecteSettleAccountName, setSelecteSettleAccountName] = useState("");
  const [paymentLinkDisabled, setPaymentLinkDisabled] = useState(true);
  const [paymentLinkSelected, setPaymentLinkSelected] = useState(false);

  // AddToQueue
  const [planStartDTAMC, setPlanStartDTAMC] = useState(moment());
  const [currentOngoingCustomerAMCPlan, setCurrentOngoingCustomerAMCPlan] =
    useState("");
  const [isActiveNowAMCPlan, setIsActiveNowAMCPlan] = useState(false);
  const [IsConflictingPlansFound, setIsConflictingPlansFound] = useState(false);
  const [isCheckAMCPlanPopup, setIsCheckAMCPlanPopup] = useState(false);
  const [isFromAMCPlan, setIsFromAMCPlan] = useState("");
  const [VisitsType, setVisitsType] = useState(false);

  //previousDate Invoice
  const [showInvocieDTConfirmation, setShowInvocieDTConfirmation] = useState(false);
  const [invoiceDT, setInvoiceDT] = useState(moment());
  const [invoiceTime, setInvoiceTime] = useState(moment());
  const [selectedPersonIndex, setSelectedPersonIndex] = useState(0);
  const [allSelectedStylistTip, setAllSelectedStylistTip] = useState({});
  const [uniqueStylistListName, setUniqueStylistListName] = useState([]);

  const [openPaymentTypeSelector, setOpenPaymentTypeSelector] = useState(false);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  const [allMixedPaymentInputs, setAllMixedPaymentInputs] = useState([]);
  const [paymentModeOptions, setPaymentModeOptions] = useState("");
  const [mixedPaymentModeSelected, setMixedPaymentModeSelected] = useState(false);
  const [openPayments, setOpenPayments] = useState(false);
  const [updatedSinglePayments, setUpdatedSinglePayments] = useState(false);
  const [balanceMixedAmount, setBalanceMixedAmount] = useState(0);
  const [exceededAmount, setExceededAmount] = useState(false);
  const [amountToPaid, setAmountToPaid] = useState(0);
  const [updateCustomerDetails, setUpdateCustomerDetails] = useState(false);
  const [cashPayRecieved, setCashPayRecieved] = useState("");
  const [returnToCutomer, setReturnToCustemer] = useState(0);
  const [updatingCashPayValue, setUpdatingCashPayValue] = useState(false);

  useEffect(() => {
    checkAuthentication();
  }, []);

  useEffect(() => {
    if (updateCustomerDetails) {
      processPayment();
    }
  }, [updateCustomerDetails]);

  useEffect(() => {
    if (updatingCashPayValue) {
      updateReturnCustomerValue(cashPayRecieved);
      setUpdatingCashPayValue(false);
    }
  }, [updatingCashPayValue]);

  const updateReturnCustomerValue = (value) => {
    if (value === "" || Number(value) === 0) {
      setReturnToCustemer("");
      return;
    }
    let filteredcash = allMixedPaymentInputs.filter((payment) => payment.paymentMethod === "Cash Pay");
    if (!Utils.IsNullOrEmptyArray(filteredcash)) {
      let amount = filteredcash[0].amount;
      let amounttogive = value - amount;
      setReturnToCustemer(amounttogive);
    }

  }

  useEffect(() => {
    if (updatedSinglePayments) {
      processPayment();
      setUpdatedSinglePayments(false);
    }
  }, [updatedSinglePayments])

  const checkAuthentication = async () => {
    if (UserHelper.CheckPermission(PaymentPage, navigate)) {
      setIsLoading(true);
      var bus = getBusiness();
      var _processingPartialPay = location.state.partialPayment;
      var customerPass = location.state.pass;
      var _anonymous =
        Utils.IsNullOrEmptyObject(customerPass) || customerPass.isBusiness;
      var menu =
        !Utils.IsNullOrEmptyObject(location.state) &&
          !Utils.IsNullOrEmpty(location.state.activeMenu)
          ? location.state.activeMenu
          : _anonymous
            ? SideBarMenuNames.CheckOut
            : location.state && location.state.fromVisit
              ? SideBarMenuNames.Overview
              : location.state && location.state.fromJobcard
                ? SideBarMenuNames.JobCard
                : location.state && location.state.fromAMCPlan
                  ? SideBarMenuNames.AMCPlan
                  : SideBarMenuNames.Customers;
      setActiveMenu(menu);
      setIsFromAMCPlan(location.state.fromAMCPlan);
      var countryDetails = getCountry();
      setIsBusinessCountryIndia(
        CountryHelper.IsBusinessCountryIndia(countryDetails)
      );
      setIsBusinessCountryUAE(
        CountryHelper.IsBusinessCountryUAE(countryDetails)
      );
      setIsBusinessCountryMalaysia(
        CountryHelper.IsBusinessCountryMalaysia(countryDetails)
      );
      setIsBusinessCountryUS(
        CountryHelper.IsBusinessCountryUSA(countryDetails)
      );
      var currentVisit = location.state.visit;
      if (!Utils.IsNullOrEmpty(currentVisit)) {
        setVisitsType(currentVisit.visitType);
        setTipAmount(currentVisit.tip);
      }
      var currentBranch = getBranch();
      var initialInvoiceLabel = currentBranch.invoiceLabels.filter(
        (i) => i.taxIncluded && i.active
      )[0];
      var previouslySelectedLabel = currentBranch.invoiceLabels.find(
        (i) => i.active && i.description == location.state.selectedLabel
      );
      if (previouslySelectedLabel != undefined)
        initialInvoiceLabel = previouslySelectedLabel;
      var payout = currentBranch.payout;
      setSettlementAccounts(payout);
      var salon = BusinessHelper.IsSalon(bus);
      if (salon) {
        var staffs = await getServiceStaffs();
        if (!Utils.IsNullOrEmptyArray(staffs)) {
          setServiceStaffs(staffs);
        }
      }
      setIsSalon(salon);
      setBusiness(bus);
      setBranch(currentBranch);
      setCountryInfo(countryDetails);
      setAddressISOCode(countryDetails.ISOCode);
      setPass(customerPass);
      var branch = getBranch();
      var typeMakeModels = getMakeModels() || [];
      var makeModels = typeMakeModels.filter((t) => t.type === branch.vehicleType);
      setVehicleMakeModelList(makeModels);
      var automobileWithoutVehicle = false;
      if (_processingPartialPay) {
        setIsPartialPaySelected(true);
        automobileWithoutVehicle =
          bus.category === BusinessCategories.Automobile &&
          Utils.IsNullOrEmptyObject(currentVisit.vehicleDetails);
        setIsAutomobileWithoutVehicle(automobileWithoutVehicle);
        var currentServiceItems = [];
        currentVisit.serviceItems.forEach((item) => {
          var sItem = convertServiceItemObject(item, true);
          currentServiceItems.push(sItem);
        });
        var deepCopy_MasterServiceItems = JSON.parse(
          JSON.stringify(currentServiceItems)
        );
        setMasterServiceItemsList(deepCopy_MasterServiceItems);
        setServiceItems(currentServiceItems);
        setVisit(currentVisit);
        setProcessingPartialPay(true);
        setOpenPayments(true);
        let isbusinesscountryIn = CountryHelper.IsBusinessCountryIndia(countryDetails);
        let isbusinesscountryus = CountryHelper.IsBusinessCountryUSA(countryDetails)
        handleOpenMixedPayment(isbusinesscountryIn, isbusinesscountryus);
        setIsLoading(false);
      } else {
        var selectedItems = location.state.serviceItems.map((item) => ({
          ...item,
          Discounts: item.Discounts.map((discount) => ({ ...discount })),
        }));
        if (!Utils.IsNullOrEmptyArray(location.state.itemComps)) {
          var comps = location.state.itemComps.map((comp) => ({ ...comp }));
          setItemComplimentaryList(comps);
        }
        setSelectedInvoicelabel(initialInvoiceLabel);
        setInvoiceLabel(JSON.stringify(initialInvoiceLabel));
        setInvoiceLabelsList(
          currentBranch.invoiceLabels.filter((i) => i.active)
        );

        setCampaigns(getCampaigns());
        setProductsInventory(getProducts());
        setVehicleDetails(location.state.vehicleDetails);
        var deepCopy_MasterServiceItems = JSON.parse(
          JSON.stringify(selectedItems)
        );
        setMasterServiceItemsList(deepCopy_MasterServiceItems);
        setServiceItems(selectedItems);
        setVisit(currentVisit);
        setAnonymous(_anonymous);
        automobileWithoutVehicle =
          bus.category === BusinessCategories.Automobile &&
          Utils.IsNullOrEmptyObject(location.state.vehicleDetails);
        setIsAutomobileWithoutVehicle(automobileWithoutVehicle);
        setIsLoading(false);
      }
    }
    checkCardDetailsFilled();
  };

  const onChangeInvoiceLabel = (value) => {
    setInvoiceLabel(value);
    var newInvoiceLabel = JSON.parse(value);
    setSelectedInvoicelabel(newInvoiceLabel);
  };

  useEffect(() => {
    recalculateTax();
  }, [selectedInvoiceLabel]);

  const recalculateTax = () => {
    if (
      Utils.IsNullOrEmptyObject(selectedInvoiceLabel) ||
      Utils.IsNullOrEmptyArray(serviceItems)
    ) {
      return;
    }
    var tempServiceItems = [...serviceItems];
    if (!selectedInvoiceLabel.taxIncluded) {
      tempServiceItems.forEach((item) => {
        item.TaxAmount = 0;
        item.TotalPriceIncludingTax = item.TotalPrice - item.DiscountTotal;
      });
    } else {
      tempServiceItems.forEach((item) => {
        if (item.Discount > 0 || !item.InclusiveOfTax) {
          var newTotal =
            getTotalPrice(item.Price, item.Quantity) - item.DiscountTotal;
          item.TaxAmount = getTaxAmount(false, item.TaxPercent, newTotal);
          item.TotalPriceIncludingTax = newTotal + item.TaxAmount;
        } else {
          var _totalPrice = getTotalPrice(item.Price, item.Quantity);
          var _totalPriceIncludingTax = getTotalPrice(
            item.PriceIncludingTax,
            item.Quantity
          );
          item.TaxAmount = getTaxAmount(
            true,
            item.TaxPercent,
            _totalPrice,
            _totalPriceIncludingTax
          );
          item.TotalPriceIncludingTax = _totalPrice + item.TaxAmount;
        }
      });
    }
    setServiceItems(tempServiceItems);
  };

  const getServiceStaffs = async () => {
    var serviceStaffs = [];
    var result = await postAsync("Account/GetUsers", { ServiceStaff: true });
    if (!result.error) {
      result.data.userProfiles.forEach((user) => {
        if (user.active) {
          var serviceStaff = {
            Id: user.id,
            Name: user.userName,
            AddStaffReview: user.addStaffReview
          };
          serviceStaffs.push(serviceStaff);
        }
      });
    }
    return serviceStaffs;
  };

  useEffect(() => {
    loadPaymentPageSegment1();
  }, [masterServiceItemsList]);

  const loadPaymentPageSegment1 = async () => {
    if (Utils.IsNullOrEmptyArray(serviceItems) || isFromAMCPlan === true) {
      await loadAMCPlans([], []);
      return;
    }
    setIsLoading(true);
    var tempServiceItems = [...serviceItems];
    var tempOfferModels = [...offersViewModel];
    var tempOrderLevelDiscounts = [...orderLevelDiscounts];
    var tempApplicableoffers = [];
    if (
      !anonymous &&
      !Utils.IsNullOrEmptyObject(vehicleDetails) &&
      !Utils.IsNullOrEmpty(vehicleDetails.number)
    ) {
      var data = await loadAMCPlans(tempOfferModels, productsInventory);
      if (!Utils.IsNullOrEmptyObject(data)) {
        tempOfferModels = data.offers;
        tempApplicableoffers = data.applicableItems;
      }
    }
    if (!anonymous && isSalon) {
      var data = await loadAMCPlans(tempOfferModels, productsInventory);
      if (!Utils.IsNullOrEmptyObject(data)) {
        tempOfferModels = data.offers;
        tempApplicableoffers = data.applicableItems;
      }
    }
    tempOfferModels = await loadCampaignsAndEvents(
      tempOfferModels,
      productsInventory
    );
    if (!Utils.IsNullOrEmptyArray(tempOfferModels)) {
      for (var i = 0; i < tempOfferModels.length; i++) {
        var data = processOffer(
          tempOfferModels[i],
          tempServiceItems,
          tempOrderLevelDiscounts,
          tempApplicableoffers
        );
        tempOfferModels[i] = data.offer;
        tempServiceItems = data.serviceItems;
        tempOrderLevelDiscounts = data.orderDiscounts;
      }
    }
    tempServiceItems = calculateBiggestDiscount(
      tempServiceItems,
      tempOfferModels
    );
    setServiceItems(tempServiceItems);
    tempOrderLevelDiscounts = recalculateOrderDiscounts(
      tempServiceItems,
      tempOrderLevelDiscounts
    );
    tempOfferModels = recalculateOfferValues(tempOfferModels);
    setOffersViewModel(tempOfferModels);
    setOrderLevelDiscounts(tempOrderLevelDiscounts);
    setIsLoading(false);
  };

  const recalculateOrderDiscounts = (
    tempServiceItems,
    tempOrderLevelDiscounts
  ) => {
    if (Utils.IsNullOrEmptyArray(tempOrderLevelDiscounts)) {
      return tempOrderLevelDiscounts;
    }
    tempOrderLevelDiscounts.forEach((orderDiscount) => {
      var filteredDiscounts = [];
      tempServiceItems.forEach((serviceItem) => {
        serviceItem.Discounts.forEach((disc) => {
          if (disc.OrderLevel && disc.CampaignId === orderDiscount.CampaignId) {
            filteredDiscounts.push(disc);
          }
        });
      });
      if (!Utils.IsNullOrEmptyArray(filteredDiscounts)) {
        orderDiscount.DiscountTotal = filteredDiscounts
          .map((i) => i.DiscountTotal)
          .reduce((a, b) => a + b);
      }
    });
    return tempOrderLevelDiscounts;
  };
  const recalculateOfferValues = (tempOfferModels) => {
    if (Utils.IsNullOrEmptyArray(tempOfferModels)) {
      return tempOfferModels;
    }
    tempOfferModels
      .filter(
        (o) =>
          o.DiscountType !== OfferTypes.Free &&
          o.BenefitType !== BenefitTypes.Free
      )
      .forEach((offer) => {
        var selectedDiscounts = [];
        switch (offer.OffersClassificationType) {
          case OfferClassificationTypes.Campaign:
            serviceItems.forEach((serviceItem) => {
              if (!Utils.IsNullOrEmptyArray(serviceItem.Discounts)) {
                serviceItem.Discounts.forEach((discount) => {
                  if (discount.CampaignId === offer.CampaignId) {
                    selectedDiscounts.push(discount);
                  }
                });
              }
            });
            if (!Utils.IsNullOrEmptyArray(selectedDiscounts)) {
              if (selectedDiscounts.length === 1) {
                offer.DiscountTotal = selectedDiscounts[0].DiscountTotal;
              } else {
                offer.DiscountTotal = selectedDiscounts
                  .map((d) => d.DiscountTotal)
                  .reduce((a, b) => a + b);
              }
            }
            break;
          case OfferClassificationTypes.AMC:
            serviceItems.forEach((serviceItem) => {
              if (!Utils.IsNullOrEmptyArray(serviceItem.Discounts)) {
                serviceItem.Discounts.forEach((discount) => {
                  if (
                    discount.CampaignId === offer.CampaignId &&
                    discount.BenefitId === offer.BenefitId
                  ) {
                    selectedDiscounts.push(discount);
                  }
                });
              }
            });
            if (!Utils.IsNullOrEmptyArray(selectedDiscounts)) {
              if (selectedDiscounts.length === 1) {
                offer.DiscountTotal = selectedDiscounts[0].DiscountTotal;
              } else {
                offer.DiscountTotal = selectedDiscounts
                  .map((d) => d.DiscountTotal)
                  .reduce((a, b) => a + b);
              }
            }
            break;
        }
      });
    return tempOfferModels;
  };

  useEffect(() => {
    recalculateTotals();
  }, [serviceItems, tipAmount, visit]);

  const calculateBiggestDiscount = (tempServiceItems, tempOfferModels) => {
    if (
      Utils.IsNullOrEmptyArray(tempServiceItems) ||
      tempServiceItems.every(
        (c) => Utils.IsNullOrEmptyArray(c.Discounts) || processingPartialPay
      )
    ) {
      return tempServiceItems;
    }
    tempServiceItems.forEach((serviceItem) => {
      if (serviceItem.Discounts.filter((d) => !d.Complimentary).length > 1) {
        var serviceItemLevelDiscounts = serviceItem.Discounts.filter(
          (d) =>
            !d.OrderLevel &&
            !d.Complimentary &&
            d.CampaignType !== CampaignTypes.Event
        );

        //if service item level discounts available
        //Getting biggest discount for an item among the service item level discounts
        if (!Utils.IsNullOrEmptyArray(serviceItemLevelDiscounts)) {
          var biggestDiscount = GetBiggestDiscount(
            serviceItemLevelDiscounts,
            serviceItem.ProductId,
            tempOfferModels
          );
          //remove other item level offers
          serviceItem.Discounts = serviceItem.Discounts.filter(
            (d) => d.OrderLevel || d.Complimentary
          );
          //Add Biggest Offer
          serviceItem.Discounts.push(biggestDiscount);
          serviceItem.DiscountTotal = biggestDiscount.DiscountTotal;
        }

        //Recalculate order level discounts
        serviceItem.Discounts.filter((d) => d.OrderLevel).forEach(
          (orderLevelDiscount) => {
            if (!Utils.IsNullOrEmpty(orderLevelDiscount.CampaignId)) {
              var offerValue = 0;
              var offer = tempOfferModels.find(
                (o) => o.CampaignId === orderLevelDiscount.CampaignId
              );
              if (!Utils.IsNullOrEmptyObject(offer)) {
                var totalSum = tempServiceItems
                  .map((i) => i.TotalPrice)
                  .reduce((a, b) => a + b);
                var totalDiscountSum = tempServiceItems
                  .map((i) => i.DiscountTotal)
                  .reduce((a, b) => a + b);
                var updatedValue =
                  (offer.DiscountValue / (totalSum - totalDiscountSum)) * 100;
                offerValue =
                  offer.DiscountType === OfferTypes.Cash
                    ? updatedValue
                    : offer.DiscountValue;
                orderLevelDiscount.DiscountTotal =
                  ((serviceItem.TotalPrice - serviceItem.DiscountTotal) *
                    offerValue) /
                  100;
              }
            }
          }
        );
        serviceItem.DiscountTotal = serviceItem.Discounts.map(
          (c) => c.DiscountTotal
        ).reduce((a, b) => a + b);
        //Recalculate item level complimentary
        serviceItem.Discounts.filter(
          (d) => !d.OrderLevel && d.Complimentary
        ).forEach((comp) => {
          var totalSum = tempServiceItems
            .map((i) => i.TotalPrice)
            .reduce((a, b) => a + b);
          var totalDiscountSum = tempServiceItems
            .map((i) => i.DiscountTotal)
            .reduce((a, b) => a + b);
          var updatedValue =
            (comp.DiscountValue / (totalSum - totalDiscountSum)) * 100;
          var offerValue =
            comp.DiscountType === OfferTypes.Cash
              ? updatedValue
              : comp.DiscountValue;
          comp.DiscountTotal =
            ((serviceItem.TotalPrice - serviceItem.DiscountTotal) *
              offerValue) /
            100;
        });
        serviceItem.DiscountTotal = serviceItem.Discounts.map(
          (c) => c.DiscountTotal
        ).reduce((a, b) => a + b);
      } else {
        if (
          serviceItem.Discounts.some(
            (d) =>
              d.DiscountType !== OfferTypes.Free &&
              d.BenefitType !== BenefitTypes.Free
          )
        ) {
          var offerValue = 0;
          var offer = tempOfferModels.find(
            (o) => o.CampaignId === serviceItem.Discounts[0].CampaignId
          );
          if (!Utils.IsNullOrEmptyObject(offer)) {
            if (serviceItem.Discounts[0].OrderLevel) {
              var totalSum = tempServiceItems
                .map((i) => i.TotalPrice)
                .reduce((a, b) => a + b);
              var filteredItems = tempServiceItems.filter(
                (s) => s.ProductId !== serviceItem.ProductId
              );
              var totalDiscountSum = Utils.IsNullOrEmptyArray(filteredItems)
                ? 0
                : filteredItems
                  .map((d) => d.DiscountTotal)
                  .reduce((a, b) => a + b);
              var updatedValue =
                (offer.DiscountValue / (totalSum - totalDiscountSum)) * 100;
              offerValue =
                offer.DiscountType === OfferTypes.Cash
                  ? updatedValue
                  : offer.DiscountValue;
              serviceItem.Discounts[0].DiscountTotal =
                (serviceItem.TotalPrice * offerValue) / 100;
            } else {
              offerValue =
                offer.DiscountType === OfferTypes.Cash ||
                  offer.BenefitType === BenefitTypes.FlatDiscount
                  ? offer.DiscountValue
                  : (serviceItem.TotalPrice * offer.DiscountValue) / 100;
              serviceItem.Discounts[0].DiscountTotal = offerValue;
            }
          }
          serviceItem.DiscountTotal = Utils.IsNullOrEmptyArray(
            serviceItem.Discounts
          )
            ? 0
            : serviceItem.Discounts.map((c) => c.DiscountTotal).reduce(
              (a, b) => a + b
            );
        }
      }
    });
    tempServiceItems = recalculateTaxAmountOnDiscountApply(tempServiceItems);
    return tempServiceItems;
  };

  const GetBiggestDiscount = (discounts, productId, tempOfferModels) => {
    var serviceItem = masterServiceItemsList.find(
      (s) => s.ProductId === productId
    );
    discounts.forEach((discount) => {
      var offer =
        discount.OffersClassificationType === OfferClassificationTypes.AMC
          ? tempOfferModels.find(
            (o) =>
              o.Selected &&
              o.CampaignId === discount.CampaignId &&
              o.BenefitId === discount.BenefitId
          )
          : tempOfferModels.find(
            (o) => o.Selected && o.CampaignId == discount.CampaignId
          );
      if (!Utils.IsNullOrEmptyObject(offer)) {
        switch (offer.OffersClassificationType) {
          case OfferClassificationTypes.Campaign:
            switch (discount.DiscountType) {
              case OfferTypes.Cash:
                discount.DiscountTotal = offer.DiscountValue;
                break;
              case OfferTypes.PercentDiscount:
                discount.DiscountTotal =
                  (serviceItem.TotalPrice * offer.DiscountValue) / 100;
                break;
            }
            break;
          case OfferClassificationTypes.AMC:
            switch (discount.BenefitType) {
              case BenefitTypes.DiscountPercentage:
                discount.DiscountTotal =
                  (serviceItem.TotalPrice * offer.DiscountValue) / 100;
                break;
              case BenefitTypes.FlatDiscount:
                discount.DiscountTotal = offer.DiscountValue;
                break;
            }
            break;
        }
      }
    });
    var sortedArray = discounts.sort((a, b) => {
      return a.DiscountTotal - b.DiscountTotal;
    });
    return sortedArray[sortedArray.length - 1];
  };

  const recalculateTotals = () => {
    if (Utils.IsNullOrEmptyArray(serviceItems)) {
      setSubTotal(0);
      setTax(0);
      setTotalPrice(0);
      setOutstandingAmount(0);
      setBalanceAmount(0);
      return;
    }
    var discountTotal = serviceItems
      .map((c) => c.DiscountTotal)
      .reduce((a, b) => a + b);
    var subTotalWODiscount = serviceItems
      .map((c) => c.TotalPrice)
      .reduce((a, b) => a + b);
    var subTotalWithDiscount = subTotalWODiscount - discountTotal;
    setSubTotal(subTotalWithDiscount);
    var _tax = serviceItems.map((c) => c.TaxAmount).reduce((a, b) => a + b);
    setTax(_tax);
    var grandTotal = subTotalWithDiscount + _tax + tipAmount;
    setTotalPrice(grandTotal);
    var isVisitEmpty = Utils.IsNullOrEmptyObject(visit);
    var tempPaidAmount = isVisitEmpty ? 0 : visit.paidAmount;
    var tempRequestedAmount = isVisitEmpty ? 0 : visit.requestedAmount;
    var outstanding = grandTotal - tempPaidAmount - tempRequestedAmount;
    var alreadyCapturedAmount = tempPaidAmount;
    if (alreadyCapturedAmount) {
      setPaidAmount(alreadyCapturedAmount);
      let newPrice = grandTotal;
      newPrice = grandTotal - alreadyCapturedAmount;
      setTotalPrice(newPrice);
    }
    if (!isVisitEmpty && visit.tip) {
      setTipAmount(visit.tip);
    }
    setOutstandingAmount(Utils.ConvertToFloat(outstanding.toFixed(2)));
    setBalanceMixedAmount(Utils.ConvertToFloat(outstanding.toFixed(2)));
    setAlreadyPaidAmount(
      Utils.ConvertToFloat(alreadyCapturedAmount.toFixed(2))
    );
    setBalanceAmount(outstanding);
    setAmountToPaid(Utils.ConvertToFloat(outstanding.toFixed(2)))
    if (isPartialPaySelected === true && outstanding > 0) {
      handleOpenMixedPayment();
    }
  };

  const loadCampaignsAndEvents = async (tempOfferModels, allProducts) => {
    //Events
    var tier = anonymous
      ? null
      : business.tiers.filter((c) => c.level == pass.tierLevel)[0];
    if (!Utils.IsNullOrEmptyObject(tier)) {
      if (!Utils.IsNullOrEmptyArray(pass.events)) {
        pass.events.forEach((passEvent) => {
          if (!Utils.IsNullOrEmptyObject(passEvent)) {
            var total = 0.0;
            var offerValue = 0.0;
            var type = "";
            var value = "";
            switch (passEvent.offerType) {
              case OfferTypes.Points:
                total = passEvent.offerValue / tier.pointsRedemptionRatio;
                offerValue = passEvent.offerValue / tier.pointsRedemptionRatio;
                type = passEvent.offerValue + " Points";
                value = passEvent.offerValue.tostring();
                break;

              case OfferTypes.Cash:
                total = passEvent.offerValue;
                offerValue = passEvent.offerValue;
                type = passEvent.offerValue + " Cash";
                value = passEvent.offerValue.toString();
                break;

              case OfferTypes.PercentDiscount:
                total = (serviceItemTotalPrice * passEvent.offerValue) / 100;
                offerValue = passEvent.offerValue;
                type = "Discount " + passEvent.offerValue + "%";
                value = (
                  (serviceItemTotalPrice * passEvent.offerValue) /
                  100
                ).toString();
                break;

              case OfferTypes.Multiplier:
                total =
                  (serviceItemTotalPrice / tier.pointsEarnRatio) *
                  passEvent.offerValue;
                offerValue =
                  (serviceItemTotalPrice / tier.pointsEarnRatio) *
                  passEvent.offerValue;
                type = passEvent.offerValue + " x Points";
                value = (
                  (serviceItemTotalPrice / tier.pointsEarnRatio) *
                  passEvent.offerValue
                ).toString();
                break;

              default:
                total = 0;
                offerValue = 0;
                type = "";
                value = "Free";
                break;
            }
            var offerModel = createEventOffer(
              Utils.CreateGuid(),
              type,
              value,
              passEvent.type,
              offerValue,
              total,
              passEvent.offerType !== OfferTypes.Multiplier,
              passEvent.offerType
            );
            if (!Utils.IsNullOrEmptyObject(offerModel)) {
              tempOfferModels.push(offerModel);
            }
          }
        });
      }

      //Campaigns
      if (
        !Utils.IsNullOrEmptyArray(campaigns) &&
        campaigns.some(
          (c) =>
            c.type != CampaignTypes.Advertisement &&
            (Utils.IsNullOrEmptyArray(c.segments) ||
              c.segments.some(
                (c) =>
                  c.type === CampaignSegmentTypes.Tier && c.value === tier.level
              ) ||
              c.segments.some(
                (c) =>
                  c.type === CampaignSegmentTypes.Location &&
                  c.value === pass.locality
              ))
        ) === true
      ) {
        var filteredCampaigns = campaigns
          .filter(
            (c) =>
              c.type != CampaignTypes.Advertisement &&
              (Utils.IsNullOrEmptyArray(c.segments) ||
                c.segments.some(
                  (c) =>
                    c.type == CampaignSegmentTypes.Tier && c.value == tier.level
                ) ||
                c.segments.some(
                  (c) =>
                    c.type == CampaignSegmentTypes.Location &&
                    c.value == pass.locality
                ))
          )
          .sort((a, b) => (a.type > b.type ? 1 : 0));
        tempOfferModels = await processCampaigns(
          tempOfferModels,
          filteredCampaigns,
          tier.pointsEarnRatio,
          tier.pointsRedemptionRatio,
          allProducts
        );
      }
      // if (pass.pointsAccrued - pass.pointsExpired - pass.pointsRedeemed > 0) {
      // 	var ofTotal = (pass.pointsAccrued - pass.pointsExpired - pass.pointsRedeemed) / tier.pointsRedemptionRatio
      // 	var ofVal = pass.pointsAccrued - pass.pointsExpired - pass.pointsRedeemed
      // 	var type = ofVal + " Points"
      // 	var value = ofTotal.toString()
      // 	var pointOffer = createPointsOffer('RedeemPoints', ofTotal, ofVal, type, value)
      // 	if (!Utils.IsNullOrEmptyObject(pointOffer)) {
      // 		tempOfferModels.push(pointOffer)
      // 	}
      // }
    } else if (
      campaigns.some(
        (c) => c.type != CampaignTypes.Advertisement && c.anonymous === true
      )
    ) {
      var anonymousCampaigns = campaigns.filter(
        (c) => c.type != CampaignTypes.Advertisement && c.anonymous === true
      );
      tempOfferModels = await processCampaigns(
        tempOfferModels,
        anonymousCampaigns,
        0,
        0,
        allProducts
      );
    }
    return tempOfferModels;
  };

  const createPointsOffer = (desc, offerTotal, offerValue, type, value) => {
    return createOfferModel(
      "",
      "",
      CampaignTypes.Advertisement,
      OfferTypes.Points,
      BenefitTypes.DiscountPercentage,
      offerValue,
      desc,
      OfferClassificationTypes.AMC,
      true,
      "",
      true,
      offerTotal,
      type,
      value,
      true
    );
  };

  const createEventOffer = (
    id,
    type,
    value,
    eventType,
    offerValue,
    total,
    canRedeem,
    offerType
  ) => {
    return createOfferModel(
      id,
      "",
      CampaignTypes.Event,
      offerType,
      BenefitTypes.DiscountPercentage,
      offerValue,
      eventType,
      OfferClassificationTypes.Campaign,
      false,
      eventType,
      canRedeem,
      total,
      type,
      value
    );
  };

  const processCampaigns = async (
    tempviewmodels,
    filteredCampaigns,
    pointsEarnRatio,
    pointsRedemptionRatio,
    allProducts
  ) => {
    var camps = filteredCampaigns.filter((c) =>
      Utils.CreateMomentFromString(Utils.GetUTC()).isBefore(c.end)
    );
    if (Utils.IsNullOrEmptyArray(camps)) {
      return tempviewmodels;
    }
    for (var i = 0; i < camps.length; i++) {
      var campaign = camps[i];
      if (
        (campaign.offerType === OfferTypes.Points ||
          campaign.offerType === OfferTypes.Multiplier) &&
        (pointsEarnRatio <= 0 || pointsRedemptionRatio <= 0)
      ) {
        continue;
      }
      if (campaign.type == CampaignTypes.VisitXGetY && campaign.xValue > 1) {
        var visits = [];
        if (!anonymous && !Utils.IsNullOrEmptyObject(pass)) {
          var visitParams = [
            business.id,
            branch.branchId,
            pass.customerId,
            Utils.CreateMomentFromString(campaign.start).format(),
            Utils.CreateMomentFromString(campaign.end).format(),
          ];
          var visitResult = await postAsync(
            "Customer/GetCustomerVisit",
            {},
            "",
            "GetAll",
            "AllVisitsByCustomerByPeriod",
            visitParams
          );
          if (!visitResult.error) {
            visits = visitResult.data;
          }
        }
        if (
          Utils.IsNullOrEmptyArray(visits) ||
          visits.length + 1 < campaign.xValue
        ) {
          continue;
        }
      } else if (campaign.type == CampaignTypes.BuyXGetY) {
        var matchedCount = 0;
        for (
          var productIndex = 0;
          productIndex < campaign.productsX.length;
          productIndex++
        ) {
          var product = campaign.productsX[productIndex];
          var filterData = [];
          if (
            product.category === CampaignProductCategories.Item &&
            (serviceItems.some(
              (c) =>
                c.productId === product.value || c.productCode === product.value
            ) == false ||
              Utils.NumberSafety(
                serviceItems.filter(
                  (c) =>
                    c.productId === product.value ||
                    c.productCode === product.value
                )[0].quantity
              ) < Math.max(product.quantity, campaign.xValue))
          ) {
            continue;
          }

          filterData = serviceItems.filter(
            (c) => c.productClass === product.value
          );
          if (
            product.category === CampaignProductCategories.Class &&
            (serviceItems.some((c) => c.productClass === product.value) ===
              false ||
              (!Utils.IsNullOrEmptyArray(filterData) &&
                filterData.map((d) => d.quantity).reduce((a, b) => a + b) <
                Math.max(product.quantity, campaign.xValue)))
          ) {
            continue;
          }

          filterData = serviceItems.filter(
            (c) => c.productFineline === product.value
          );
          if (
            product.category === CampaignProductCategories.Fineline &&
            (serviceItems.some((c) => c.productFineline === product.value) ===
              false ||
              (!Utils.IsNullOrEmptyArray(filterData) &&
                filterData.map((d) => d.quantity).reduce((a, b) => a + b) <
                Math.max(product.quantity, campaign.xValue)))
          ) {
            continue;
          }

          filterData = serviceItems.filter(
            (c) => c.productDepartment === product.value
          );
          if (
            product.category === CampaignProductCategories.Department &&
            (serviceItems.some((c) => c.productDepartment === product.value) ===
              false ||
              (!Utils.IsNullOrEmptyArray(filterData) &&
                filterData.map((d) => d.quantity).reduce((a, b) => a + b) <
                Math.max(product.quantity, campaign.xValue)))
          ) {
            continue;
          }

          matchedCount += 1;
        }
        if (
          (campaign.productXAny && matchedCount <= 0) ||
          (campaign.productXAny == false &&
            matchedCount < campaign.productsX.length)
        ) {
          continue;
        }
      } else if (
        campaign.type == CampaignTypes.SpendXGetY &&
        campaign.xValue > 0 &&
        serviceItems.map((c) => c.TotalPrice).reduce((a, b) => a + b) <
        campaign.xValue
      ) {
        continue;
      }
      var total = 0;
      var offerValue = 0;
      var type = "";
      var value = "";
      switch (campaign.offerType) {
        case OfferTypes.Points:
          if (pointsRedemptionRatio > 0) {
            total = campaign.offerValue / pointsRedemptionRatio;
            offerValue = campaign.offerValue / pointsRedemptionRatio;
            type = campaign.offerValue + " Points";
            value = campaign.offerValue.toString();
          }
          break;

        case OfferTypes.Cash:
          total = campaign.offerValue;
          offerValue = campaign.offerValue;
          type = campaign.offerValue + " Cash";
          value = campaign.offerValue.toString();
          break;

        case OfferTypes.PercentDiscount:
          if (
            campaign.type == CampaignTypes.BuyXGetY &&
            !Utils.IsNullOrEmptyArray(campaign.productsY)
          ) {
            var yTotal = 0;
            campaign.productsY.forEach((product) => {
              var filteredItems = serviceItems.filter(
                (s) =>
                  s.productCode === product.value ||
                  s.productId == product.value
              );
              yTotal += !Utils.IsNullOrEmptyArray(filteredItems)
                ? filteredItems.map((s) => s.TotalPrice).reduce((a, b) => a + b)
                : 0;
            });
            total = (yTotal * campaign.offerValue) / 100;
            value = ((yTotal * campaign.offerValue) / 100).toString();
          } else {
            total = (serviceItemTotalPrice * campaign.offerValue) / 100;
            value = (
              (serviceItemTotalPrice * campaign.offerValue) /
              100
            ).toString();
          }
          offerValue = campaign.offerValue;
          type = "Discount " + campaign.offerValue + "%";
          break;

        case OfferTypes.Multiplier:
          if (pointsEarnRatio > 0) {
            total =
              (serviceItemTotalPrice / pointsEarnRatio) * campaign.offerValue;
            offerValue = campaign.offerValue;
            type = campaign.offerValue + " x Points";
            value = (
              (serviceItemTotalPrice / pointsEarnRatio) *
              campaign.offerValue
            ).toString();
          }
          break;
        default:
          total = 0;
          offerValue = 0;
          type = "";
          value = "Free";
          break;
      }
      var offerModel = createCampaignOfferModel(
        campaign.id,
        campaign.type,
        campaign.offerType != OfferTypes.Multiplier,
        campaign.name,
        total,
        campaign.offerType,
        offerValue,
        value,
        type
      );
      if (!Utils.IsNullOrEmptyObject(offerModel)) {
        tempviewmodels.push(offerModel);
      }
    }
    return tempviewmodels;
  };

  const createCampaignOfferModel = (
    id,
    campaignType,
    canRedeem,
    desc,
    offerTotal,
    offerType,
    offerValue,
    value,
    type
  ) => {
    return createOfferModel(
      id,
      "",
      campaignType,
      offerType,
      BenefitTypes.DiscountPercentage,
      offerValue,
      desc,
      OfferClassificationTypes.Campaign,
      false,
      PassEventTypes.Birthday,
      canRedeem,
      offerTotal,
      type,
      value
    );
  };

  const loadAMCPlans = async (tempOfferModels, allProducts) => {
    var utcDate = Utils.CreateMomentFromString(Utils.GetDateFromUTC());
    var currentLocalDate = Utils.CreateMomentFromString(
      Utils.GetCurrentDateFromLocal()
    );
    let validcustomerAMCPlans = [];
    if (
      !Utils.IsNullOrEmptyObject(business) &&
      !Utils.IsNullOrEmptyObject(pass) &&
      (business.category === BusinessCategories.Salon ||
        !Utils.IsNullOrEmptyObject(vehicleDetails))
    ) {
      var result = await postAsync("AMC/GetActiveCustomerAMCPlans", {
        BusinessId: business.id,
        PassId: pass.id,
        VehicleNumber: isSalon ? "" : vehicleDetails.number,
        BusinessCategory: business.category,
      });
      if (result.error) {
        setIsNavigateToLogin(result.isNavigateToLogin);
        setAlert(result.errorMessage);
        return;
      }
      if (Utils.IsNullOrEmptyArray(result.data)) {
        return;
      }
      validcustomerAMCPlans = result.data.filter(
        (a) => utcDate >= moment(a.startDT) && utcDate <= moment(a.endDT)
      );
      setCurrentOngoingCustomerAMCPlan(validcustomerAMCPlans[0]);
    }
    var currentPlan = validcustomerAMCPlans[0];
    if (Utils.IsNullOrEmptyObject(currentPlan)) {
      return;
    }
    var validBenefits = currentPlan.benefits.filter(
      (b) =>
        (b.maximumNoOfRedemptions === 0 || b.remainingRedemptions > 0) &&
        (b.daysInBetweenRedemption === 0 ||
          Utils.IsNullOrEmptyArray(b.redemptions) ||
          currentLocalDate.isAfter(
            Utils.GetDateFromMoment(
              Utils.CreateMomentFromString(
                Utils.GetLastArrayElement(b.redemptions).redemptionDT
              ).add(b.daysInBetweenRedemption, "days")
            )
          ))
    );
    if (Utils.IsNullOrEmptyArray(validBenefits)) {
      return;
    }
    const tempApplicableItems = [...applicableServiceItems];
    validBenefits.forEach((benefit) => {
      var applicableItem = {
        BenefitId: benefit.benefitId,
        BenefitType: benefit.benefitType,
        BenefitApplicableFor: benefit.benefitApplicableFor,
        ApplicableBenefitClassificationType:
          benefit.applicableBenefitClassificationType,
        Items: [],
      };
      var newItems = [];
      switch (benefit.benefitType) {
        case BenefitTypes.Free:
          benefit.items.forEach((i) => {
            newItems.push(i.itemId);
          });
          break;
        default:
          if (
            benefit.applicableBenefitClassificationType ===
            ClassificationTypes.All
          ) {
            var allProds = getProductsByType(
              allProducts,
              benefit.benefitApplicableFor
            );
            if (!Utils.IsNullOrEmptyArray(allProds)) {
              allProds.forEach((prod) => {
                if (
                  !Utils.IsNullOrEmptyObject(
                    masterServiceItemsList.find((v) => v.ProductId === prod.id)
                  )
                ) {
                  newItems.push(prod.id);
                }
              });
            }
          } else {
            benefit.items.forEach((item) => {
              var prods = getProductsByCategoryAndDepartment(
                item,
                allProducts,
                benefit.benefitApplicableFor,
                benefit.applicableBenefitClassificationType
              );
              if (!Utils.IsNullOrEmptyArray(prods)) {
                prods.forEach((prod) => {
                  if (
                    !Utils.IsNullOrEmptyObject(
                      masterServiceItemsList.find(
                        (v) => v.ProductId === prod.id
                      )
                    )
                  ) {
                    newItems.push(prod.id);
                  }
                });
              }
            });
          }
          break;
      }
      if (!Utils.IsNullOrEmptyArray(newItems)) {
        applicableItem.Items = newItems;
      }
      tempApplicableItems.push(applicableItem);
      var offerModel = createAMC(currentPlan.id, currentPlan.planName, benefit);
      if (
        !Utils.IsNullOrEmptyObject(offerModel) &&
        !Utils.IsNullOrEmptyArray(applicableItem.Items)
      ) {
        tempOfferModels.push(offerModel);
      }
    });
    setApplicableServiceItems(tempApplicableItems);
    return { offers: tempOfferModels, applicableItems: tempApplicableItems };
  };

  const getProductsByType = (allProducts, benefitApplicableFor) => {
    switch (benefitApplicableFor) {
      case ServiceItemTypes.Consumable:
        return getConsumables(allProducts);
      case ServiceItemTypes.Spare:
        return getSpares(allProducts);
      case ServiceItemTypes.Service:
        return getServices(allProducts);
      case ServiceItemTypes.Labour:
        return getLabours(allProducts);
      default:
        return [];
    }
  };

  const getProductsByCategoryAndDepartment = (
    itemDetails,
    allProducts,
    benefitApplicableFor,
    applicableBenefitClassificationType
  ) => {
    var productsByCategory = getProductsByType(
      allProducts,
      benefitApplicableFor
    );
    if (Utils.IsNullOrEmptyArray(productsByCategory)) {
      return null;
    }
    switch (applicableBenefitClassificationType) {
      case ClassificationTypes.All:
        return productsByCategory;
      case ClassificationTypes.Class:
        return productsByCategory.filter((p) => p.class === itemDetails.name);
      case ClassificationTypes.Department:
        return productsByCategory.filter(
          (p) => p.department === itemDetails.name
        );
      case ClassificationTypes.Item:
        return productsByCategory.filter((p) => p.id === itemDetails.itemId);
    }
  };

  const createAMC = (campaignId, planName, benefit) => {
    return createOfferModel(
      campaignId,
      benefit.benefitId,
      CampaignTypes.Advertisement,
      OfferTypes.PercentDiscount,
      benefit.benefitType,
      benefit.benefitValue,
      planName + " - " + benefit.description,
      OfferClassificationTypes.AMC,
      false
    );
  };

  const createOfferModel = (
    campaignId,
    benefitId,
    campaignType,
    discountType,
    benefitType,
    discountValue,
    desc,
    offerClassification,
    orderLevel,
    eventType = "",
    canRedeem = true,
    discountTotal = 0,
    type = "",
    value = "",
    pointsRedemption = false
  ) => {
    if (pointsRedemption) {
      return {};
    }
    if (Utils.IsNullOrEmpty(eventType)) {
      eventType = PassEventTypes.Birthday;
    }
    return {
      CampaignId: campaignId,
      BenefitId: benefitId,
      CampaignType: campaignType,
      DiscountType: discountType,
      CanRedeem: canRedeem,
      EventType: eventType,
      BenefitType: benefitType,
      OrderLevel: orderLevel,
      DiscountValue: discountValue,
      DiscountTotal: discountTotal,
      OffersClassificationType: offerClassification,
      Description: desc,
      Selected: false,
      Type: type,
      Value: value,
      PointsRedemption: pointsRedemption,
    };
  };

  // From Address
  const [isOpenedDifferentFromAddress, setIsOpenedDifferentFromAddress] =
    useState(false);
  function toggleFromAddress() {
    setShippingFromAddressDefaultEnabled(true);
    setIsOpenedDifferentFromAddress(false);
  }
  function toggleDifferentFromAddress() {
    setShippingFromAddressDefaultEnabled(false);
    setIsOpenedDifferentFromAddress(true);
  }

  const handleOfferSelection = (offer, index) => {
    if (Utils.IsNullOrEmptyObject(offer)) {
      return;
    }
    var tempOffers = [...offersViewModel];
    var tempServiceItems = [...serviceItems];
    var tempOrderLevelDiscounts = [...orderLevelDiscounts];
    var data = processOffer(
      tempOffers[index],
      tempServiceItems,
      tempOrderLevelDiscounts
    );
    tempOffers[index] = data.offer;
    tempServiceItems = data.serviceItems;
    tempOrderLevelDiscounts = data.orderDiscounts;
    tempServiceItems = calculateBiggestDiscount(tempServiceItems, tempOffers);
    setServiceItems(tempServiceItems);
    tempOrderLevelDiscounts = recalculateOrderDiscounts(
      tempServiceItems,
      tempOrderLevelDiscounts
    );
    tempOffers = recalculateOfferValues(tempOffers);
    setOffersViewModel(tempOffers);
    setOrderLevelDiscounts(tempOrderLevelDiscounts);
  };

  const getOrderLevelDiscounts = (
    tempServiceItems,
    tempOrderLevelDiscounts
  ) => {
    if (Utils.IsNullOrEmptyArray(tempServiceItems)) {
      return;
    }
    var updatedOrderLevelDiscounts = [];
    tempServiceItems.forEach((item) => {
      if (
        !Utils.IsNullOrEmptyArray(item.Discounts) &&
        !Utils.IsNullOrEmptyArray(
          item.Discounts.filter((d) => d.OrderLevel === true)
        )
      ) {
        item.Discounts.filter((d) => d.OrderLevel === true).forEach(
          (discount) => {
            updatedOrderLevelDiscounts.push(discount);
          }
        );
      }
    });
    if (Utils.IsNullOrEmptyArray(updatedOrderLevelDiscounts)) {
      return tempOrderLevelDiscounts;
    }
    //const tempOrderLevelDiscounts = [...orderLevelDiscounts]
    updatedOrderLevelDiscounts.forEach((discount) => {
      var filteredDiscounts = updatedOrderLevelDiscounts.filter(
        (d) => d.CampaignId === discount.CampaignId
      );
      if (
        Utils.IsNullOrEmptyObject(
          tempOrderLevelDiscounts.find(
            (d) => d.CampaignId === discount.CampaignId
          )
        )
      ) {
        var newDiscount = {
          CampaignId: filteredDiscounts[0].CampaignId,
          Description: filteredDiscounts[0].Description,
          DescriptionDetail: filteredDiscounts[0].DescriptionDetail,
          DiscountTotal: filteredDiscounts
            .map((i) => i.DiscountTotal)
            .reduce((a, b) => a + b),
        };
        tempOrderLevelDiscounts.push(newDiscount);
      }
    });
    return tempOrderLevelDiscounts;
    //setOrderLevelDiscounts(tempOrderLevelDiscounts)
  };

  const removeOrderLevelDiscounts = (
    tempOrderLevelDiscounts,
    tempServiceItems
  ) => {
    if (
      Utils.IsNullOrEmptyArray(tempOrderLevelDiscounts) ||
      Utils.IsNullOrEmptyArray(tempServiceItems)
    ) {
      return tempOrderLevelDiscounts;
    }
    var indexesToRemove = [];
    tempOrderLevelDiscounts.forEach((discount, i) => {
      if (
        !tempServiceItems.some(
          (s) =>
            !Utils.IsNullOrEmptyArray(s.Discounts) &&
            s.Discounts.some((d) => d.CampaignId === discount.CampaignId)
        )
      ) {
        indexesToRemove.push(i);
      }
    });
    if (!Utils.IsNullOrEmptyArray(indexesToRemove)) {
      indexesToRemove.forEach((i) => {
        tempOrderLevelDiscounts.splice(i, 1);
      });
    }
    return tempOrderLevelDiscounts;
  };

  const processOffer = (
    offer,
    tempServiceItems,
    tempOrderLevelDiscounts,
    applicableItems = []
  ) => {
    if (Utils.IsNullOrEmptyObject(offer)) {
      return {
        offer: offer,
        serviceItems: tempServiceItems,
        orderDiscounts: tempOrderLevelDiscounts,
      };
    }
    offer.Selected = !offer.Selected;
    switch (offer.OffersClassificationType) {
      case OfferClassificationTypes.Campaign:
        var campaign = campaigns.find((c) => c.id === offer.CampaignId);
        if (!Utils.IsNullOrEmptyObject(campaign)) {
          //Remove Logic
          if (!offer.Selected) {
            switch (offer.DiscountType) {
              case OfferTypes.Free:
                var removeIndexes = [];
                tempServiceItems.forEach((item, index) => {
                  if (!Utils.IsNullOrEmptyArray(item.Discounts)) {
                    if (
                      !Utils.IsNullOrEmptyObject(
                        item.Discounts.find((c) => c.CampaignId === campaign.id)
                      )
                    ) {
                      removeIndexes.push(index);
                    }
                  }
                });
                if (!Utils.IsNullOrEmptyArray(removeIndexes)) {
                  removeIndexes.forEach((i) => {
                    if (
                      masterServiceItemsList.some(
                        (s) => s.ProductId === tempServiceItems[i].ProductId
                      )
                    ) {
                      tempServiceItems[i].Discounts = [];
                      tempServiceItems[i].DiscountTotal = 0;
                    } else {
                      tempServiceItems.splice(i, 1);
                    }
                  });
                }
                break;
              default:
                tempServiceItems
                  .filter(
                    (s) =>
                      !Utils.IsNullOrEmptyArray(s.Discounts) &&
                      s.Discounts.some(
                        (d) =>
                          d.DiscountType !== OfferTypes.Free &&
                          d.BenefitType !== BenefitTypes.Free
                      )
                  )
                  .forEach((item) => {
                    var discountIndex = item.Discounts.findIndex(
                      (d) => d.CampaignId === offer.CampaignId
                    );
                    item.Discounts.splice(discountIndex, 1);
                    item.DiscountTotal = Utils.IsNullOrEmptyArray(
                      item.Discounts
                    )
                      ? 0
                      : item.Discounts.map((s) => s.DiscountTotal).reduce(
                        (a, b) => a + b
                      );
                  });
                tempServiceItems =
                  recalculateTaxAmountOnDiscountApply(tempServiceItems);
                tempOrderLevelDiscounts = removeOrderLevelDiscounts(
                  tempOrderLevelDiscounts,
                  tempServiceItems
                );
                break;
            }
          }
          //Add Logic
          else {
            switch (campaign.type) {
              case CampaignTypes.BuyXGetY:
                var isValidPurchasedItems = false;
                if (!campaign.productXAny) {
                  isValidPurchasedItems = masterServiceItemsList.every(
                    (item) => {
                      var prod = campaign.productsX.find((prodX) => {
                        switch (prodX.category) {
                          case CampaignProductCategories.Item:
                            return (
                              prodX.value === item.ProductId ||
                              prodX.value === item.ProductCode
                            );
                          case CampaignProductCategories.Fineline:
                            return prodX.value === item.ProductFineline;
                          case CampaignProductCategories.Department:
                            return prodX.value === item.ProductDepartment;
                          case CampaignProductCategories.Class:
                            return prodX.value === item.ProductClass;
                        }
                      });
                      return !Utils.IsNullOrEmptyObject(prod);
                    }
                  );
                } else {
                  isValidPurchasedItems = masterServiceItemsList.some(
                    (item) => {
                      var prod = campaign.productsX.find((prodX) => {
                        switch (prodX.category) {
                          case CampaignProductCategories.Item:
                            return (
                              prodX.value === item.ProductId ||
                              prodX.value === item.ProductCode
                            );
                          case CampaignProductCategories.Fineline:
                            return prodX.value === item.ProductFineline;
                          case CampaignProductCategories.Department:
                            return prodX.value === item.ProductDepartment;
                          case CampaignProductCategories.Class:
                            return prodX.value === item.ProductClass;
                        }
                      });
                      return !Utils.IsNullOrEmptyObject(prod);
                    }
                  );
                }
                if (isValidPurchasedItems) {
                  switch (campaign.offerType) {
                    case OfferTypes.Free:
                      campaign.productsY.forEach((prodYItem) => {
                        if (
                          !Utils.IsNullOrEmptyObject(
                            masterServiceItemsList.find((item) => {
                              switch (prodYItem.category) {
                                case CampaignProductCategories.Item:
                                  return (
                                    prodYItem.value === item.ProductId ||
                                    prodYItem.value === item.ProductCode
                                  );
                                case CampaignProductCategories.Fineline:
                                  return (
                                    prodYItem.value === item.ProductFineline
                                  );
                                case CampaignProductCategories.Department:
                                  return (
                                    prodYItem.value === item.ProductDepartment
                                  );
                                case CampaignProductCategories.Class:
                                  return prodYItem.value === item.ProductClass;
                              }
                            })
                          )
                        ) {
                          var itemIndex = tempServiceItems.findIndex((s) => {
                            switch (prodYItem.category) {
                              case CampaignProductCategories.Item:
                                return (
                                  prodYItem.value === s.ProductId ||
                                  prodYItem.value === s.ProductCode
                                );
                              case CampaignProductCategories.Fineline:
                                return prodYItem.value === s.ProductFineline;
                              case CampaignProductCategories.Department:
                                return prodYItem.value === s.ProductDepartment;
                              case CampaignProductCategories.Class:
                                return prodYItem.value === s.ProductClass;
                            }
                          });
                          tempServiceItems[itemIndex].Quantity =
                            campaign.offerValue > 0
                              ? campaign.offerValue
                              : tempServiceItems[itemIndex].Quantity;
                          var discount = {
                            CampaignId: campaign.id,
                            BenefitId: "",
                            CampaignType: campaign.type,
                            Code: tempServiceItems[itemIndex].ProductId,
                            BenefitType: "DiscountPercentage",
                            Description: campaign.name,
                            DescriptionDetail:
                              "Free " + tempServiceItems[itemIndex].Description,
                            Complimentary: true,
                            OrderLevel: false,
                            DiscountTotal:
                              tempServiceItems[itemIndex].Price *
                              tempServiceItems[itemIndex].Quantity,
                            OffersClassificationType:
                              offer.OffersClassificationType,
                            DiscountType: OfferTypes.Free,
                            DiscountValue: offer.DiscountValue,
                            Quantity: tempServiceItems[itemIndex].Quantity,
                            PointsRedemption: false,
                          };
                          tempServiceItems[itemIndex].Discounts = [];
                          tempServiceItems[itemIndex].Discounts.push(discount);
                          tempServiceItems[itemIndex].DiscountTotal =
                            discount.DiscountTotal;
                          tempServiceItems[itemIndex].TaxAmount = 0;
                        } else {
                          var serviceItem = getServiceItemFromProduct(
                            productsInventory.find((product) => {
                              switch (prodYItem.category) {
                                case CampaignProductCategories.Item:
                                  return (
                                    prodYItem.value === product.id ||
                                    prodYItem.value === product.code
                                  );
                                case CampaignProductCategories.Fineline:
                                  return prodYItem.value === product.fineline;
                                case CampaignProductCategories.Department:
                                  return prodYItem.value === product.department;
                                case CampaignProductCategories.Class:
                                  return prodYItem.value === product.class;
                              }
                            })
                          );
                          if (!Utils.IsNullOrEmptyObject(serviceItem)) {
                            serviceItem.Quantity =
                              campaign.offerValue > 0
                                ? campaign.offerValue
                                : tempServiceItems[itemIndex].Quantity;
                            serviceItem.Sequence =
                              Utils.GetLastArrayElement(tempServiceItems)
                                .Sequence + 1;
                            var discount = {
                              CampaignId: campaign.id,
                              BenefitId: "",
                              BenefitType: "DiscountPercentage",
                              CampaignType: campaign.type,
                              Code: serviceItem.ProductId,
                              Description: campaign.name,
                              Complimentary: true,
                              OrderLevel: false,
                              DescriptionDetail:
                                "Free " + serviceItem.Description,
                              DiscountTotal:
                                serviceItem.Price * serviceItem.Quantity,
                              OffersClassificationType:
                                offer.OffersClassificationType,
                              DiscountType: OfferTypes.Free,
                              DiscountValue: offer.DiscountValue,
                              Quantity: serviceItem.Quantity,
                              PointsRedemption: false,
                            };
                            serviceItem.Discounts.push(discount);
                            serviceItem.DiscountTotal = discount.DiscountTotal;
                            serviceItem.TaxAmount = 0;
                            tempServiceItems.push(serviceItem);
                          }
                        }
                      });
                      break;
                    case OfferTypes.Multiplier:
                      break;
                    case OfferTypes.PercentDiscount:
                      campaign.productsY.forEach((prodYItem) => {
                        if (
                          !Utils.IsNullOrEmptyObject(
                            masterServiceItemsList.find((item) => {
                              switch (prodYItem.category) {
                                case CampaignProductCategories.Item:
                                  return (
                                    prodYItem.value === item.ProductId ||
                                    prodYItem.value === item.ProductCode
                                  );
                                case CampaignProductCategories.Fineline:
                                  return (
                                    prodYItem.value === item.ProductFineline
                                  );
                                case CampaignProductCategories.Department:
                                  return (
                                    prodYItem.value === item.ProductDepartment
                                  );
                                case CampaignProductCategories.Class:
                                  return prodYItem.value === item.ProductClass;
                              }
                            })
                          )
                        ) {
                          var itemIndex = tempServiceItems.findIndex((s) => {
                            switch (prodYItem.category) {
                              case CampaignProductCategories.Item:
                                return (
                                  prodYItem.value === s.ProductId ||
                                  prodYItem.value === s.ProductCode
                                );
                              case CampaignProductCategories.Fineline:
                                return prodYItem.value === s.ProductFineline;
                              case CampaignProductCategories.Department:
                                return prodYItem.value === s.ProductDepartment;
                              case CampaignProductCategories.Class:
                                return prodYItem.value === s.ProductClass;
                            }
                          });
                          var discountTotal =
                            ((tempServiceItems[itemIndex].Price *
                              tempServiceItems[itemIndex].Quantity -
                              tempServiceItems[itemIndex].DiscountTotal) *
                              campaign.offerValue) /
                            100;
                          var discount = {
                            CampaignId: campaign.id,
                            BenefitId: "",
                            CampaignType: campaign.type,
                            BenefitType: "DiscountPercentage",
                            OrderLevel: false,
                            Complimentary: false,
                            Code: tempServiceItems[itemIndex].ProductId,
                            Description: campaign.name,
                            DescriptionDetail:
                              tempServiceItems[itemIndex].Description +
                              campaign.offerValue +
                              " %",
                            DiscountTotal: discountTotal,
                            OffersClassificationType:
                              offer.OffersClassificationType,
                            DiscountType: campaign.offerType,
                            DiscountValue: offer.DiscountValue,
                            Quantity: tempServiceItems[itemIndex].Quantity,
                            PointsRedemption: false,
                          };
                          tempServiceItems[itemIndex].Discounts.push(discount);
                          tempServiceItems[itemIndex].DiscountTotal =
                            tempServiceItems[itemIndex].Discounts.map(
                              (i) => i.DiscountTotal
                            ).reduce((a, b) => a + b);
                          tempServiceItems =
                            recalculateTaxAmountOnDiscountApply(
                              tempServiceItems
                            );
                        }
                      });
                      break;
                    case OfferTypes.Cash:
                      var totalSum = tempServiceItems
                        .filter(
                          (t) =>
                            Utils.IsNullOrEmptyArray(t.Discounts) ||
                            t.Discounts.some(
                              (d) => d.DiscountType !== OfferTypes.Free
                            )
                        )
                        .map((i) => i.TotalPrice)
                        .reduce((a, b) => a + b);
                      var totalDiscountSum = tempServiceItems
                        .filter(
                          (t) =>
                            Utils.IsNullOrEmptyArray(t.Discounts) ||
                            t.Discounts.some(
                              (d) => d.DiscountType !== OfferTypes.Free
                            )
                        )
                        .map((i) => i.DiscountTotal)
                        .reduce((a, b) => a + b);
                      var updatedCashValue =
                        (offer.DiscountValue / (totalSum - totalDiscountSum)) *
                        100;
                      tempServiceItems
                        .filter(
                          (t) =>
                            Utils.IsNullOrEmptyArray(t.Discounts) ||
                            t.Discounts.some(
                              (d) => d.DiscountType !== OfferTypes.Free
                            )
                        )
                        .forEach((item) => {
                          var itemTotalPrice = item.Price * item.Quantity;
                          var discountTotal =
                            ((itemTotalPrice - item.DiscountTotal) *
                              updatedCashValue) /
                            100;
                          var discount = {
                            CampaignId: campaign.id,
                            BenefitId: "",
                            Complimentary: false,
                            CampaignType: campaign.type,
                            BenefitType: "DiscountPercentage",
                            Code: "Cash Back",
                            Description: campaign.name,
                            DescriptionDetail:
                              Utils.GetCurrency(
                                offer.DiscountValue,
                                countryInfo
                              ) + " Cash Back",
                            DiscountTotal: discountTotal,
                            OffersClassificationType:
                              offer.OffersClassificationType,
                            DiscountType: campaign.offerType,
                            DiscountValue: offer.DiscountValue,
                            Quantity: item.Quantity,
                            OrderLevel: true,
                            PointsRedemption: false,
                          };
                          if (
                            item.Discounts.every(
                              (d) =>
                                d.BenefitType !== BenefitTypes.Free &&
                                d.OfferType !== OfferTypes.Free
                            )
                          ) {
                            item.Discounts.push(discount);
                          }
                          item.DiscountTotal = item.Discounts.map(
                            (i) => i.DiscountTotal
                          ).reduce((a, b) => a + b);
                        });
                      tempServiceItems =
                        recalculateTaxAmountOnDiscountApply(tempServiceItems);
                      break;
                    case OfferTypes.Points:
                      break;
                  }
                }
                break;
              case CampaignTypes.SpendXGetY:
                switch (campaign.offerType) {
                  case OfferTypes.Free:
                    if (!Utils.IsNullOrEmptyArray(campaign.productsY)) {
                      campaign.productsY.forEach((prodYItem) => {
                        if (
                          !Utils.IsNullOrEmptyObject(
                            masterServiceItemsList.find(
                              (s) =>
                                prodYItem.value === s.ProductId ||
                                prodYItem.value === s.ProductCode
                            )
                          )
                        ) {
                          var itemIndex = tempServiceItems.findIndex(
                            (s) =>
                              prodYItem.value === s.ProductId ||
                              prodYItem.value === s.ProductCode
                          );
                          tempServiceItems[itemIndex].Quantity =
                            campaign.offerValue > 0
                              ? campaign.offerValue
                              : tempServiceItems[itemIndex].Quantity;
                          var discount = {
                            CampaignId: campaign.id,
                            CampaignType: campaign.type,
                            Code: tempServiceItems[itemIndex].ProductId,
                            BenefitType: "DiscountPercentage",
                            Complimentary: false,
                            BenefitId: "",
                            Description: campaign.name,
                            DescriptionDetail:
                              "Free " + tempServiceItems[itemIndex].Description,
                            DiscountTotal:
                              tempServiceItems[itemIndex].Price *
                              tempServiceItems[itemIndex].Quantity,
                            OffersClassificationType:
                              offer.OffersClassificationType,
                            DiscountType: OfferTypes.Free,
                            DiscountValue: offer.DiscountValue,
                            Quantity: tempServiceItems[itemIndex].Quantity,
                            OrderLevel: false,
                            PointsRedemption: false,
                          };
                          tempServiceItems[itemIndex].Discounts = [];
                          tempServiceItems[itemIndex].Discounts.push(discount);
                          tempServiceItems[itemIndex].DiscountTotal =
                            discount.DiscountTotal;
                          tempServiceItems[itemIndex].TaxAmount = 0;
                        } else {
                          var serviceItem = getServiceItemFromProduct(
                            productsInventory.find(
                              (p) =>
                                p.id === prodYItem.value ||
                                p.code === prodYItem.value
                            )
                          );
                          if (!Utils.IsNullOrEmptyObject(serviceItem)) {
                            serviceItem.Sequence =
                              Utils.GetLastArrayElement(tempServiceItems)
                                .Sequence + 1;
                            serviceItem.Quantity =
                              campaign.offerValue > 0
                                ? campaign.offerValue
                                : tempServiceItems[itemIndex].Quantity;
                            var discount = {
                              CampaignId: campaign.id,
                              CampaignType: campaign.type,
                              BenefitId: "",
                              BenefitType: "DiscountPercentage",
                              Code: serviceItem.ProductId,
                              Description: campaign.name,
                              DescriptionDetail:
                                "Free " + serviceItem.Description,
                              DiscountTotal:
                                serviceItem.Price * serviceItem.Quantity,
                              OffersClassificationType:
                                offer.OffersClassificationType,
                              DiscountType: OfferTypes.Free,
                              DiscountValue: offer.DiscountValue,
                              Quantity: serviceItem.Quantity,
                              Complimentary: false,
                              OrderLevel: false,
                              PointsRedemption: false,
                            };
                            serviceItem.Discounts.push(discount);
                            serviceItem.DiscountTotal = discount.DiscountTotal;
                            serviceItem.TaxAmount = 0;
                            tempServiceItems.push(serviceItem);
                          }
                        }
                      });
                    }
                    break;
                  case OfferTypes.Cash:
                    var totalSum = tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .map((i) => i.TotalPrice)
                      .reduce((a, b) => a + b);
                    var totalDiscountSum = tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .map((i) => i.DiscountTotal)
                      .reduce((a, b) => a + b);
                    var updatedCashValue =
                      (offer.DiscountValue / (totalSum - totalDiscountSum)) *
                      100;
                    tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .forEach((item) => {
                        var itemTotalPrice = item.Price * item.Quantity;
                        var discountTotal =
                          ((itemTotalPrice - item.DiscountTotal) *
                            updatedCashValue) /
                          100;
                        var discount = {
                          CampaignId: campaign.id,
                          CampaignType: campaign.type,
                          Code: "Cash Back",
                          BenefitId: "",
                          BenefitType: "DiscountPercentage",
                          Description: campaign.name,
                          DescriptionDetail:
                            Utils.GetCurrency(
                              offer.DiscountValue,
                              countryInfo
                            ) + " Cash Back",
                          DiscountTotal: discountTotal,
                          OffersClassificationType:
                            offer.OffersClassificationType,
                          DiscountType: campaign.offerType,
                          DiscountValue: offer.DiscountValue,
                          Quantity: item.Quantity,
                          OrderLevel: true,
                          PointsRedemption: false,
                          Complimentary: false,
                        };
                        if (
                          item.Discounts.every(
                            (d) =>
                              d.BenefitType !== BenefitTypes.Free &&
                              d.OfferType !== OfferTypes.Free
                          )
                        ) {
                          item.Discounts.push(discount);
                        }
                        item.DiscountTotal = item.Discounts.map(
                          (i) => i.DiscountTotal
                        ).reduce((a, b) => a + b);
                      });
                    tempServiceItems =
                      recalculateTaxAmountOnDiscountApply(tempServiceItems);
                    break;
                  case OfferTypes.PercentDiscount:
                    tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .forEach((item) => {
                        var discountTotal =
                          ((item.Price * item.Quantity - item.DiscountTotal) *
                            offer.DiscountValue) /
                          100;
                        var discount = {
                          CampaignId: campaign.id,
                          CampaignType: campaign.type,
                          Code: "Percent Discount",
                          BenefitId: "",
                          BenefitType: "DiscountPercentage",
                          Description: campaign.name,
                          DescriptionDetail:
                            item.Description + campaign.offerValue + "%",
                          DiscountTotal: discountTotal,
                          OffersClassificationType:
                            offer.OffersClassificationType,
                          DiscountType: campaign.offerType,
                          DiscountValue: offer.DiscountValue,
                          Quantity: item.Quantity,
                          OrderLevel: true,
                          PointsRedemption: false,
                          Complimentary: false,
                        };
                        if (
                          item.Discounts.every(
                            (d) =>
                              d.BenefitType !== BenefitTypes.Free &&
                              d.OfferType !== OfferTypes.Free
                          )
                        ) {
                          item.Discounts.push(discount);
                        }
                        item.DiscountTotal = item.Discounts.map(
                          (i) => i.DiscountTotal
                        ).reduce((a, b) => a + b);
                      });
                    tempServiceItems =
                      recalculateTaxAmountOnDiscountApply(tempServiceItems);
                    break;
                }
                break;
              case CampaignTypes.VisitXGetY:
                switch (campaign.offerType) {
                  case OfferTypes.Free:
                    if (!Utils.IsNullOrEmptyArray(campaign.productsY)) {
                      campaign.productsY.forEach((prodYItem) => {
                        if (
                          !Utils.IsNullOrEmptyObject(
                            masterServiceItemsList.find(
                              (s) =>
                                prodYItem.value === s.ProductId ||
                                prodYItem.value === s.ProductCode
                            )
                          )
                        ) {
                          var itemIndex = tempServiceItems.findIndex(
                            (s) =>
                              prodYItem.value === s.ProductId ||
                              prodYItem.value === s.ProductCode
                          );
                          tempServiceItems[itemIndex].Quantity =
                            campaign.offerValue > 0
                              ? campaign.offerValue
                              : tempServiceItems[itemIndex].Quantity;
                          var discount = {
                            CampaignId: campaign.id,
                            CampaignType: campaign.type,
                            BenefitId: "",
                            BenefitType: "DiscountPercentage",
                            Code: tempServiceItems[itemIndex].ProductId,
                            Description: campaign.name,
                            DescriptionDetail:
                              "Free " + tempServiceItems[itemIndex].Description,
                            DiscountTotal:
                              tempServiceItems[itemIndex].Price *
                              tempServiceItems[itemIndex].Quantity,
                            OffersClassificationType:
                              offer.OffersClassificationType,
                            DiscountType: OfferTypes.Free,
                            DiscountValue: offer.DiscountValue,
                            Quantity: tempServiceItems[itemIndex].Quantity,
                            OrderLevel: false,
                            PointsRedemption: false,
                            Complimentary: false,
                          };
                          tempServiceItems[itemIndex].Discounts = [];
                          tempServiceItems[itemIndex].Discounts.push(discount);
                          tempServiceItems[itemIndex].DiscountTotal =
                            discount.DiscountTotal;
                          tempServiceItems[itemIndex].TaxAmount = 0;
                        } else {
                          var serviceItem = getServiceItemFromProduct(
                            productsInventory.find(
                              (p) =>
                                p.id === prodYItem.value ||
                                p.code === prodYItem.value
                            )
                          );
                          if (!Utils.IsNullOrEmptyObject(serviceItem)) {
                            serviceItem.Sequence =
                              Utils.GetLastArrayElement(tempServiceItems)
                                .Sequence + 1;
                            serviceItem.Quantity =
                              campaign.offerValue > 0
                                ? campaign.offerValue
                                : tempServiceItems[itemIndex].Quantity;
                            var discount = {
                              CampaignId: campaign.id,
                              CampaignType: campaign.type,
                              Code: serviceItem.ProductId,
                              Description: campaign.name,
                              BenefitId: "",
                              BenefitType: "DiscountPercentage",
                              DescriptionDetail:
                                "Free " + serviceItem.Description,
                              DiscountTotal:
                                serviceItem.Price * serviceItem.Quantity,
                              OffersClassificationType:
                                offer.OffersClassificationType,
                              DiscountType: OfferTypes.Free,
                              DiscountValue: offer.DiscountValue,
                              Quantity: serviceItem.Quantity,
                              OrderLevel: false,
                              PointsRedemption: false,
                              Complimentary: false,
                            };
                            serviceItem.Discounts.push(discount);
                            serviceItem.DiscountTotal = discount.DiscountTotal;
                            serviceItem.TaxAmount = 0;
                            tempServiceItems.push(serviceItem);
                          }
                        }
                      });
                    }
                    break;
                  case OfferTypes.Cash:
                    var totalSum = tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .map((i) => i.TotalPrice)
                      .reduce((a, b) => a + b);
                    var totalDiscountSum = tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .map((i) => i.DiscountTotal)
                      .reduce((a, b) => a + b);
                    var updatedCashValue =
                      (offer.DiscountValue / (totalSum - totalDiscountSum)) *
                      100;
                    tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .forEach((item) => {
                        var itemTotalPrice = item.Price * item.Quantity;
                        var discountTotal =
                          ((itemTotalPrice - item.DiscountTotal) *
                            updatedCashValue) /
                          100;
                        var discount = {
                          CampaignId: campaign.id,
                          CampaignType: campaign.type,
                          Code: "Cash Back",
                          Description: campaign.name,
                          DescriptionDetail:
                            Utils.GetCurrency(
                              offer.DiscountValue,
                              countryInfo
                            ) + " Cash Back",
                          DiscountTotal: discountTotal,
                          OffersClassificationType:
                            offer.OffersClassificationType,
                          DiscountType: campaign.offerType,
                          DiscountValue: offer.DiscountValue,
                          Quantity: item.Quantity,
                          OrderLevel: true,
                          Complimentary: false,
                        };
                        if (
                          item.Discounts.every(
                            (d) =>
                              d.BenefitType !== BenefitTypes.Free &&
                              d.OfferType !== OfferTypes.Free
                          )
                        ) {
                          item.Discounts.push(discount);
                        }
                        item.DiscountTotal = item.Discounts.map(
                          (i) => i.DiscountTotal
                        ).reduce((a, b) => a + b);
                      });
                    tempServiceItems =
                      recalculateTaxAmountOnDiscountApply(tempServiceItems);
                    break;
                  case OfferTypes.PercentDiscount:
                    tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .forEach((item) => {
                        var discountTotal =
                          ((item.Price * item.Quantity - item.DiscountTotal) *
                            offer.DiscountValue) /
                          100;
                        var discount = {
                          CampaignId: campaign.id,
                          CampaignType: campaign.type,
                          Code: "Percent Discount",
                          Description: campaign.name,
                          DescriptionDetail:
                            item.Description + campaign.offerValue + "%",
                          DiscountTotal: discountTotal,
                          OffersClassificationType:
                            offer.OffersClassificationType,
                          DiscountType: campaign.offerType,
                          DiscountValue: offer.DiscountValue,
                          Quantity: item.Quantity,
                          OrderLevel: true,
                          Complimentary: false,
                        };
                        if (
                          item.Discounts.every(
                            (d) =>
                              d.BenefitType !== BenefitTypes.Free &&
                              d.OfferType !== OfferTypes.Free
                          )
                        ) {
                          item.Discounts.push(discount);
                        }
                        item.DiscountTotal = item.Discounts.map(
                          (i) => i.DiscountTotal
                        ).reduce((a, b) => a + b);
                      });
                    tempServiceItems =
                      recalculateTaxAmountOnDiscountApply(tempServiceItems);
                    break;
                }
                break;
              case CampaignTypes.Event:
                switch (campaign.offerType) {
                  case OfferTypes.Free:
                    if (!Utils.IsNullOrEmptyArray(campaign.productsY)) {
                      campaign.productsY.forEach((prodYItem) => {
                        if (
                          !Utils.IsNullOrEmptyObject(
                            masterServiceItemsList.find(
                              (s) =>
                                prodYItem.value === s.ProductId ||
                                prodYItem.value === s.ProductCode
                            )
                          )
                        ) {
                          var itemIndex = tempServiceItems.findIndex(
                            (s) =>
                              prodYItem.value === s.ProductId ||
                              prodYItem.value === s.ProductCode
                          );
                          tempServiceItems[itemIndex].Quantity =
                            campaign.offerValue > 0
                              ? campaign.offerValue
                              : tempServiceItems[itemIndex].Quantity;
                          var discount = {
                            CampaignId: campaign.id,
                            CampaignType: campaign.type,
                            Code: tempServiceItems[itemIndex].ProductId,
                            Description: campaign.name,
                            DescriptionDetail:
                              "Free " + tempServiceItems[itemIndex].Description,
                            DiscountTotal:
                              tempServiceItems[itemIndex].Price *
                              tempServiceItems[itemIndex].Quantity,
                            OffersClassificationType:
                              offer.OffersClassificationType,
                            DiscountType: OfferTypes.Free,
                            DiscountValue: offer.DiscountValue,
                            Quantity: tempServiceItems[itemIndex].Quantity,
                          };
                          tempServiceItems[itemIndex].Discounts = [];
                          tempServiceItems[itemIndex].Discounts.push(discount);
                          tempServiceItems[itemIndex].DiscountTotal =
                            discount.DiscountTotal;
                          tempServiceItems[itemIndex].TaxAmount = 0;
                        } else {
                          var serviceItem = getServiceItemFromProduct(
                            productsInventory.find(
                              (p) =>
                                p.id === prodYItem.value ||
                                p.code === prodYItem.value
                            )
                          );
                          if (!Utils.IsNullOrEmptyObject(serviceItem)) {
                            serviceItem.Quantity =
                              campaign.offerValue > 0
                                ? campaign.offerValue
                                : tempServiceItems[itemIndex].Quantity;
                            serviceItem.Sequence =
                              Utils.GetLastArrayElement(tempServiceItems)
                                .Sequence + 1;
                            var discount = {
                              CampaignId: campaign.id,
                              CampaignType: campaign.type,
                              Code: serviceItem.ProductId,
                              Description: campaign.name,
                              DescriptionDetail:
                                "Free " + serviceItem.Description,
                              DiscountTotal:
                                serviceItem.Price * serviceItem.Quantity,
                              OffersClassificationType:
                                offer.OffersClassificationType,
                              DiscountType: OfferTypes.Free,
                              DiscountValue: offer.DiscountValue,
                              Quantity: serviceItem.Quantity,
                            };
                            serviceItem.Discounts.push(discount);
                            serviceItem.DiscountTotal = discount.DiscountTotal;
                            serviceItem.TaxAmount = 0;
                            tempServiceItems.push(serviceItem);
                          }
                        }
                      });
                    }
                    break;
                  case OfferTypes.Cash:
                    var totalSum = tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .map((i) => i.TotalPrice)
                      .reduce((a, b) => a + b);
                    var totalDiscountSum = tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .map((i) => i.DiscountTotal)
                      .reduce((a, b) => a + b);
                    var updatedCashValue =
                      (offer.DiscountValue / (totalSum - totalDiscountSum)) *
                      100;
                    tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .forEach((item) => {
                        var itemTotalPrice = item.Price * item.Quantity;
                        var discountTotal =
                          ((itemTotalPrice - item.DiscountTotal) *
                            updatedCashValue) /
                          100;
                        var discount = {
                          CampaignId: campaign.id,
                          CampaignType: campaign.type,
                          Code: "Cash Back",
                          Description: campaign.name,
                          DescriptionDetail:
                            Utils.GetCurrency(
                              offer.DiscountValue,
                              countryInfo
                            ) + " Cash Back",
                          DiscountTotal: discountTotal,
                          OffersClassificationType:
                            offer.OffersClassificationType,
                          DiscountType: campaign.offerType,
                          DiscountValue: offer.DiscountValue,
                          Quantity: item.Quantity,
                          OrderLevel: true,
                        };
                        if (
                          item.Discounts.every(
                            (d) =>
                              d.BenefitType !== BenefitTypes.Free &&
                              d.OfferType !== OfferTypes.Free
                          )
                        ) {
                          item.Discounts.push(discount);
                        }
                        item.DiscountTotal = item.Discounts.map(
                          (i) => i.DiscountTotal
                        ).reduce((a, b) => a + b);
                      });
                    tempServiceItems =
                      recalculateTaxAmountOnDiscountApply(tempServiceItems);
                    break;
                  case OfferTypes.PercentDiscount:
                    tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .forEach((item) => {
                        var discountTotal =
                          (item.Price * item.Quantity - item.DiscountTotal) *
                          offer.DiscountValue;
                        var discount = {
                          CampaignId: campaign.id,
                          CampaignType: campaign.type,
                          Code: "Percent Discount",
                          Description: campaign.name,
                          DescriptionDetail:
                            item.Description + campaign.offerValue + " %",
                          DiscountTotal: discountTotal,
                          OffersClassificationType:
                            offer.OffersClassificationType,
                          DiscountType: campaign.offerType,
                          DiscountValue: offer.DiscountValue,
                          Quantity: item.Quantity,
                          OrderLevel: true,
                        };
                        if (
                          item.Discounts.every(
                            (d) =>
                              d.BenefitType !== BenefitTypes.Free &&
                              d.OfferType !== OfferTypes.Free
                          )
                        ) {
                          item.Discounts.push(discount);
                        }
                        item.DiscountTotal = item.Discounts.map(
                          (i) => i.DiscountTotal
                        ).reduce((a, b) => a + b);
                      });
                    tempServiceItems =
                      recalculateTaxAmountOnDiscountApply(tempServiceItems);
                    break;
                }
                break;
            }
            tempOrderLevelDiscounts = getOrderLevelDiscounts(
              tempServiceItems,
              tempOrderLevelDiscounts
            );
          }
        }
        break;
      case OfferClassificationTypes.AMC:
        if (Utils.IsNullOrEmptyArray(applicableItems)) {
          applicableItems = [...applicableServiceItems];
        }
        var selectedApplicableItem = applicableItems.find(
          (c) => c.BenefitId === offer.BenefitId
        );
        if (Utils.IsNullOrEmptyObject(selectedApplicableItem)) {
          return {
            offer: offer,
            serviceItems: tempServiceItems,
            orderDiscounts: tempOrderLevelDiscounts,
          };
        }
        //Remove Logic
        if (!offer.Selected) {
          switch (offer.BenefitType) {
            case BenefitTypes.Free:
              selectedApplicableItem.Items.forEach((item) => {
                if (
                  !Utils.IsNullOrEmptyObject(
                    masterServiceItemsList.find((c) => c.ProductId === item)
                  )
                ) {
                  var i = tempServiceItems.findIndex(
                    (i) => i.ProductId === item
                  );
                  var discountIndex = tempServiceItems[i].Discounts.findIndex(
                    (c) =>
                      c.CampaignId === offer.CampaignId &&
                      c.BenefitId === offer.BenefitId
                  );
                  tempServiceItems[i].Discounts.splice(discountIndex, 1);
                  tempServiceItems[i].DiscountTotal = 0;
                } else {
                  var removeIndex = tempServiceItems.findIndex(
                    (i) => i.ProductId === item
                  );
                  tempServiceItems.splice(removeIndex, 1);
                }
              });
              break;
            default:
              tempServiceItems
                .filter(
                  (t) =>
                    !Utils.IsNullOrEmptyArray(t.Discounts) &&
                    t.Discounts.some(
                      (d) =>
                        d.CampaignId === offer.CampaignId &&
                        d.BenefitId === offer.BenefitId
                    )
                )
                .forEach((item) => {
                  var indexToRemove = item.Discounts.findIndex(
                    (d) =>
                      d.CampaignId === offer.CampaignId &&
                      d.BenefitId === offer.BenefitId
                  );
                  item.Discounts.splice(indexToRemove, 1);
                  var discountsTotalList = item.Discounts.map(
                    (d) => d.DiscountTotal
                  );
                  item.DiscountTotal = 0;
                  if (!Utils.IsNullOrEmptyArray(discountsTotalList)) {
                    item.DiscountTotal = discountsTotalList.reduce(
                      (a, b) => a + b
                    );
                  }
                });
              tempServiceItems =
                recalculateTaxAmountOnDiscountApply(tempServiceItems);
              break;
          }
        }
        //Add Logic
        else {
          switch (offer.BenefitType) {
            case BenefitTypes.DiscountPercentage:
              selectedApplicableItem.Items.forEach((item) => {
                var index = tempServiceItems.findIndex(
                  (s) => s.ProductId === item
                );
                var discountTotal =
                  ((tempServiceItems[index].TotalPrice -
                    tempServiceItems[index].DiscountTotal) *
                    offer.DiscountValue) /
                  100;
                var discount = {
                  CampaignId: offer.CampaignId,
                  BenefitId: offer.BenefitId,
                  BenefitType: BenefitTypes.DiscountPercentage,
                  CampaignType: CampaignTypes.Advertisement,
                  Code: "Percent Discount",
                  Description: offer.Description,
                  DescriptionDetail:
                    "Discount " + offer.DiscountValue + "%" + " off",
                  DiscountTotal: discountTotal,
                  OffersClassificationType: offer.OffersClassificationType,
                  DiscountType: OfferTypes.PercentDiscount,
                  DiscountValue: offer.DiscountValue,
                  Quantity: 1,
                  OrderLevel: false,
                };
                tempServiceItems[index].Discounts.push(discount);
                tempServiceItems[index].DiscountTotal = tempServiceItems[
                  index
                ].Discounts.map((d) => d.DiscountTotal).reduce((a, b) => a + b);
              });
              tempServiceItems =
                recalculateTaxAmountOnDiscountApply(tempServiceItems);
              break;
            case BenefitTypes.Free:
              selectedApplicableItem.Items.forEach((item) => {
                if (
                  !Utils.IsNullOrEmptyObject(
                    masterServiceItemsList.find((s) => s.ProductId === item)
                  )
                ) {
                  var index = tempServiceItems.findIndex(
                    (s) => s.ProductId === item
                  );
                  var discount = {
                    CampaignId: offer.CampaignId,
                    BenefitId: offer.BenefitId,
                    BenefitType: BenefitTypes.Free,
                    CampaignType: CampaignTypes.Advertisement,
                    Code: tempServiceItems[index].ProductCode,
                    Description: offer.Description,
                    DescriptionDetail: "Free",
                    DiscountTotal: tempServiceItems[index].Price,
                    OffersClassificationType: offer.OffersClassificationType,
                    DiscountType: OfferTypes.PercentDiscount,
                    DiscountValue: offer.DiscountValue,
                    Quantity: 1,
                    OrderLevel: false,
                  };
                  tempServiceItems[index].Discounts = [];
                  tempServiceItems[index].Discounts.push(discount);
                  tempServiceItems[index].DiscountTotal =
                    tempServiceItems[index].Price;
                } else {
                  var serviceItem = getServiceItemFromProduct(
                    productsInventory.find((p) => p.id === item)
                  );
                  if (!Utils.IsNullOrEmptyObject(serviceItem)) {
                    serviceItem.Sequence =
                      Utils.GetLastArrayElement(tempServiceItems).Sequence + 1;
                    var discount = {
                      CampaignId: offer.CampaignId,
                      BenefitId: offer.BenefitId,
                      CampaignType: CampaignTypes.Advertisement,
                      Code: serviceItem.ProductCode,
                      BenefitType: BenefitTypes.Free,
                      Description: offer.Description,
                      DescriptionDetail: serviceItem.Description + " Free",
                      DiscountTotal: serviceItem.Price,
                      OffersClassificationType: offer.OffersClassificationType,
                      DiscountType: OfferTypes.PercentDiscount,
                      DiscountValue: offer.DiscountValue,
                      Quantity: 1,
                      OrderLevel: false,
                    };
                    serviceItem.Discounts = [];
                    serviceItem.Discounts.push(discount);
                    serviceItem.DiscountTotal = serviceItem.Price;
                    tempServiceItems.push(serviceItem);
                  }
                }
              });
              break;
            case BenefitTypes.FlatDiscount:
              selectedApplicableItem.Items.forEach((item) => {
                var index = tempServiceItems.findIndex(
                  (s) => s.ProductId === item
                );
                var discount = {
                  CampaignId: offer.CampaignId,
                  BenefitId: offer.BenefitId,
                  BenefitType: BenefitTypes.FlatDiscount,
                  CampaignType: CampaignTypes.Advertisement,
                  Code: tempServiceItems[index].ProductCode,
                  Description: offer.Description,
                  DescriptionDetail:
                    Utils.GetCurrency(offer.DiscountValue, countryInfo) +
                    " Cashback",
                  DiscountTotal: offer.DiscountValue,
                  OffersClassificationType: offer.OffersClassificationType,
                  DiscountType: OfferTypes.PercentDiscount,
                  DiscountValue: offer.DiscountValue,
                  Quantity: 1,
                  OrderLevel: false,
                  Complimentary: false,
                };
                tempServiceItems[index].Discounts.push(discount);
                tempServiceItems[index].DiscountTotal = tempServiceItems[
                  index
                ].Discounts.map((d) => d.DiscountTotal).reduce((a, b) => a + b);
              });
              tempServiceItems =
                recalculateTaxAmountOnDiscountApply(tempServiceItems);
              break;
          }
        }
        break;
    }
    return {
      offer: offer,
      serviceItems: tempServiceItems,
      orderDiscounts: tempOrderLevelDiscounts,
    };
  };

  // To Address
  const [isOpenedDifferentToAddress, setIsOpenedDifferentToAddress] =
    useState(false);
  function toggleToAddress() {
    setShippingToAddressDefaultEnabled(true);
    setIsOpenedDifferentToAddress(false);
  }
  function toggleDifferentToAddress() {
    setShippingToAddressDefaultEnabled(false);
    setIsOpenedDifferentToAddress(true);
  }

  const addFrom = async () => {
    var currentAddress = shippingFromNewAddress;
    if (shippingFromAddressDefaultEnabled) {
      currentAddress = shippingFromDefaultAddress;
    }
    if (Utils.IsNullOrEmpty(currentAddress)) {
      return null;
    }
    var geocodeResponse = await getGeocodeAddress(currentAddress);
    if (
      Utils.IsNullOrEmptyObject(geocodeResponse) ||
      geocodeResponse.status !== "OK"
    ) {
      return null;
    }
    var locality = "";
    var county = "";
    var state = "";
    var country = "";
    var zipcode = "";
    var streetNumber = "";
    var route = "";
    var address = "";
    address = geocodeResponse.results[0].formatted_address;
    if (shippingFromAddressDefaultEnabled) {
      address = shippingFromDefaultAddress;
    }
    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("street_number") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("street_number")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        streetNumber = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("route") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("route")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        route = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("locality") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("locality")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        locality = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("administrative_area_level_1") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        state = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("administrative_area_level_2") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("administrative_area_level_2")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        county = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("country") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("country")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        country = ac.short_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("postal_code") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("postal_code")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        zipcode = ac.long_name;
      }
    }
    var res = {
      Address: address,
      EmailId: shippingFromEmailId,
      PhoneNumber: shippingFromPhoneNumber,
      State: state,
      StateCode: shippingFromStateCode,
    };
    setShippingFromAddressObject(res);
  };

  const addTo = async () => {
    var currentAddress = shippingToNewAddress;
    if (shippingToAddressDefaultEnabled) {
      currentAddress = shippingToDefaultAddress;
    }
    if (Utils.IsNullOrEmpty(currentAddress)) {
      return;
    }
    var geocodeResponse = await getGeocodeAddress(currentAddress);
    if (
      Utils.IsNullOrEmptyObject(geocodeResponse) ||
      geocodeResponse.status !== "OK"
    ) {
      return;
    }
    var locality = "";
    var county = "";
    var state = "";
    var country = "";
    var zipcode = "";
    var streetNumber = "";
    var route = "";
    var address = "";
    address = geocodeResponse.results[0].formatted_address;
    if (shippingToAddressDefaultEnabled) {
      address = shippingToDefaultAddress;
    }
    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("street_number") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("street_number")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        streetNumber = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("route") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("route")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        route = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("locality") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("locality")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        locality = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("administrative_area_level_1") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        state = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("administrative_area_level_2") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("administrative_area_level_2")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        county = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("country") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("country")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        country = ac.short_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("postal_code") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("postal_code")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        zipcode = ac.long_name;
      }
    }
    var res = {
      Address: address,
      EmailId: shippingToEmailId,
      PhoneNumber: shippingToPhoneNumber,
      State: state,
      StateCode: shippingToStateCode,
    };
    setShippingToAddressObject(res);
  };

  const clickOpenPaymentType = async () => {
    setIsLoading(true);
    await addFrom();
    await addTo();
    setIsLoading(false);
    setOpenAddressSelector(false);
    showPartialPayConfirmation();
  };

  const clickOpenTip = () => {
    serviceItems.map((service) => {
      if(service?.PackageProductDetails?.length) {
        service.PackageProductDetails.map((SItem) => {
          if(SItem?.ServiceItemStaffs?.length) {
            SItem.ServiceItemStaffs.map((staff) => {
              allSelectedStylistTip[staff.Name] = allSelectedStylistTip[staff.Name] ?? 0;
              if(staff.Tip) {
                handleStylistTipValue(staff.Tip, staff.Name);
              }
            })
          }
        })

      } else {
        service.ServiceItemStaffs.map((staff) => {
          allSelectedStylistTip[staff.Name] = allSelectedStylistTip[staff.Name] ?? 0;
          if (staff.Tip) {
            handleStylistTipValue(staff.Tip, staff.Name);
          }
        })
      }
      

    });
    let store = Object.keys(allSelectedStylistTip);
    setUniqueStylistListName(store);
    openTipModal(true);

  };

  const handleStylistTipValue = (value, stylist) => {
    setAllSelectedStylistTip((prev) => ({
      ...prev,
      [stylist]: parseFloat(value)
    }));
  }

  const clickAddTip = () => {
    if (BusinessHelper.IsSalon(business)) {
      const newStylistTipValues = { ...allSelectedStylistTip };
      setTipAmount(0);
      let tipamount = 0;
      const updatedServiceItems = serviceItems.map((service) => {
        let serviceTipAmount = 0;
        if(!Utils.IsNullOrEmptyArray(service?.PackageProductDetails)) {
          return {
            ...service,
            PackageProductDetails: service.PackageProductDetails.map((product) => {
              if (!Utils.IsNullOrEmptyArray(product.ServiceItemStaffs)) {
                let productTipAmount = 0; // Tip amount for this product
  
                const updatedStaffs = product.ServiceItemStaffs.map((staff) => {
                  const tipValue = newStylistTipValues[staff.Name] || 0;
                  productTipAmount += tipValue;
                  serviceTipAmount += tipValue;
                  newStylistTipValues[staff.Name] = 0; // Reset after assigning
                  
                  return { ...staff, Tip: tipValue };
                });
  
                tipamount += productTipAmount;
  
                return {
                  ...product,
                  ServiceItemStaffs: updatedStaffs,
                  Tip: productTipAmount, // Assigning the tip for this product
                };
              }
              return product;
            }),
            Tip: serviceTipAmount, // Assign the total tip for this service
          };
        }
        else if (!Utils.IsNullOrEmptyArray(service.ServiceItemStaffs)) {
          return {
            ...service,
            ServiceItemStaffs: service.ServiceItemStaffs.map((staff) => {
              const tipValue = newStylistTipValues[staff.Name] || 0;
              tipamount += tipValue;
              setTipAmount((prev) => prev + tipValue)
              newStylistTipValues[staff.Name] = 0; // Reset after assigning
              return {
                ...staff,
                Tip: tipValue
              };
            }),
          };
        }
        service.Tip = tipamount;
        return service;
      });
      setTipAmount(tipamount);
      setServiceItems(updatedServiceItems);
    }

    openTipModal(false);

  };

  const clickOpenComplimentary = (ind = 0, orderLevel = true) => {
    if (!orderLevel) {
      var i = serviceItems.findIndex((s) => s.Sequence === ind);
      setMaxPrice(
        compType === "Discount"
          ? 100
          : serviceItems[i].TotalPrice - serviceItems[i].DiscountTotal
      );
    } else {
      var _totalPrice = Utils.ConvertToFloat(
        serviceItems.map((c) => c.TotalPrice).reduce((a, b) => a + b)
      );
      var _discountPrice = Utils.ConvertToFloat(
        serviceItems.map((c) => c.DiscountTotal).reduce((a, b) => a + b)
      );
      var _maxPrice = _totalPrice - _discountPrice;
      setMaxPrice(
        Utils.ConvertToFloat(compType === "Discount" ? 100 : _maxPrice)
      );
    }
    setExistItemComp({
      index: ind,
      orderLevel: orderLevel,
    });
    setComplimentaryValue("");
    openComplimentaryModal(true);
  };

  const modelHandleChange = (event) => {
    setVehicleModel(event.target.value);
  };

  const fuelHandleChange = (event) => {
    setVehicleFuelType(event.target.value);
  };

  function toggleProfit() {
    setIsOpened((wasOpened) => !wasOpened);
  }

  const tipValue = (tip) => {
    var discountTotal = serviceItems
      .map((c) => c.DiscountTotal)
      .reduce((a, b) => a + b);
    var subTotal = serviceItems
      .map((c) => c.TotalPrice)
      .reduce((a, b) => a + b);
    subTotal = subTotal - discountTotal;
    var tax = serviceItems.map((c) => c.TaxAmount).reduce((a, b) => a + b);
    var updatedTotal = subTotal + tax;
    setOrginalTotal(updatedTotal + (updatedTotal * tip) / 10);
    setTipAmount((updatedTotal * tip) / 100);
  };

  const tipInputValue = (tipInput) => {
    var discountTotal = serviceItems
      .map((c) => c.DiscountTotal)
      .reduce((a, b) => a + b);
    var subTotal = serviceItems
      .map((c) => c.TotalPrice)
      .reduce((a, b) => a + b);
    subTotal = subTotal - discountTotal;
    var tax = serviceItems.map((c) => c.TaxAmount).reduce((a, b) => a + b);
    var updatedTotal = subTotal + tax;
    setOrginalTotal(updatedTotal + parseInt(tipInput));
    setTipAmount(parseInt(tipInput));
  };

  const clickOpenPaymentTypeCash = () => {
    openPaymentTypeCashModal(true);
  };

  const clickClosePaymentTypeCash = () => {
    openPaymentTypeCashModal(false);
  };

  // Card

  const clickOpenPaymentTypeCard = () => {
    openPaymentTypeCardModal(true);
  };

  const clickClosePaymentTypeCard = () => {
    openPaymentTypeCardModal(false);
  };

  // Upi

  const clickOpenPaymentTypeUpi = () => {
    openPaymentTypeUpiModal(true);
  };

  const clickClosePaymentTypeUpi = () => {
    openPaymentTypeUpiModal(false);
  };

  const clickCloseComplimentary = () => {
    openComplimentaryModal(false);
    setCompType("Discount");
  };

  const sendSMSInvoice = async () => {
    var tempCountry = countryInfo;
    if (Utils.IsNullOrEmptyObject(tempCountry)) {
      tempCountry = getCountry();
    }
    if (!tempCountry.SMSEnabled) {
      setAlert(AlertSMSNotSupported);
      return;
    }
    if (smsSent) {
      return;
    }
    var mobileNumbers = customerMobileNumber.split(",");
    mobileNumbers = mobileNumbers.filter((i) => !Utils.IsNullOrEmpty(i));
    if (
      Utils.IsNullOrEmptyArray(mobileNumbers) ||
      !mobileNumbers.every((i) => Utils.IsMobileValid(i))
    ) {
      setAlert("Please enter valid mobile number");
      return;
    }
    setIsLoading(true);
    var request = {
      BusinessId: business.id,
      CompanyName: business.companyName,
      CountryCode: branch.countryCode,
      CustomerId: Utils.IsNullOrEmptyObject(pass) ? "" : pass.customerId,
      CustomerName: customerName,
      Address: customerAddress,
      MobileNumbers: mobileNumbers,
      ServiceUrl: Utils.GetServiceUrl(),
      VisitId: visit.id,
      SendInvoice: sendInvoiceEnable,
    };

    let result = await postAsync("Customer/SMSInvoice", request);
    setIsLoading(false);
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return;
    }
    setSMSSent(true);
    setAlert("SMS Sent Successfully");
  };

  const emailReceipt = async () => {
    if (emailSent) {
      return;
    }
    var emailIds = customerEmailId.split(",");
    emailIds = emailIds.filter((i) => !Utils.IsNullOrEmpty(i));
    if (
      Utils.IsNullOrEmptyArray(emailIds) ||
      !emailIds.every((id) => Utils.IsEmailValid(id))
    ) {
      setAlert("Please Enter Valid EmailId");
      return;
    }
    setIsLoading(true);
    var visitIds = [];
    visitIds.push(visit.id);
    var target =
      branch.country === India.ShortName && sendInvoiceEnable
        ? "Customer/EmailInvoice"
        : "Customer/EmailReceipt";
    var request = {
      BusinessId: business.id,
      CompanyName: business.companyName,
      CustomerName: customerName,
      Address: customerAddress,
      EmailIds: emailIds,
      ServiceUrl: Utils.GetServiceUrl(),
      VisitIds: visitIds,
    };

    let result = await postAsync(target, request);
    setIsLoading(false);
    if (result.error) {
      setAlert(result.errorMessage);
      setIsNavigateToLogin(result.isNavigateToLogin);
      return;
    }
    setEmailSent(true);
    setAlert("Email Sent Successfully");
  };

  const sendWhatsAppReceipt = async () => {
    setIsLoading(true);
    await saveCustomer();
    setIsLoading(false);
    var mobileNumbers = customerMobileNumber.split(",");
    mobileNumbers = mobileNumbers.filter((i) => !Utils.IsNullOrEmpty(i));
    if (
      Utils.IsNullOrEmptyArray(mobileNumbers) ||
      !mobileNumbers.every(
        (i) => i.length === CountryHelper.GetMobileNumberMaxLength()
      )
    ) {
      setAlert("Please enter valid mobile number");
      return;
    }
    setIsLoading(true);
    var request = {
      BusinessId: business.id,
      CompanyName: business.companyName,
      CountryCode: branch.countryCode,
      CustomerId: Utils.IsNullOrEmptyObject(pass) ? "" : pass.customerId,
      CustomerName: customerName,
      MobileNumbers: mobileNumbers,
      ServiceUrl: Utils.GetServiceUrl(),
      VisitId: visit.id,
    };

    let result = await postAsync("Customer/WhatsAppInvoice", request);
    setIsLoading(false);
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return;
    }
    setAlert("Message Sent Successfully");
  };

  const vehicleMakeHandleChange = (make) => {
    setVehicleMake(make);
    setVehicleMakeItem(vehicleMakeModelList.find((v) => v.make === make).model);
  };

  const toggleStaffSelection = (item) => {
    setSelectedServiceItem(item);
    setOpenStaffSelection(true);
  };

  const onChangeStaff = (staffId) => {
    var tempServiceItems = [...serviceItems];
    var itemIndex = tempServiceItems.findIndex(
      (c) => c.ProductId === selectedServiceItem.ProductId
    );

    if (itemIndex === -1) return;

    var staff = serviceStaffs.find((s) => s.Id === staffId);
    if (!staff) return;

    var existingStaffs = tempServiceItems[itemIndex].ServiceItemStaffs || [];
    existingStaffs[selectedPersonIndex] = staff;

    tempServiceItems[itemIndex] = {
      ...tempServiceItems[itemIndex],
      ServiceItemStaffs: existingStaffs
    };

    setServiceItems(tempServiceItems);
    setSelectedServiceItem({});
    setOpenStaffSelection(false);
  };

  const onCloseStaffSelection = () => {
    setOpenStaffSelection(false);
  };
  const onCloseCheckAMCPlan = () => {
    setIsCheckAMCPlanPopup(false);
  };

  const AddCommentSharp = () => {
    var compDesc = existItemComp.orderLevel
      ? "Order Complimentary"
      : "Item Complimentary";
    var discountType =
      compType === "Discount" ? OfferTypes.PercentDiscount : OfferTypes.Cash;
    var compDescDetailed = "";
    var compId = Utils.CreateGuid();
    var discountTotal = 0;
    var discount = {};
    var tempServiceItems = [...serviceItems];
    var itemTotalPrice = 0;
    var newCompValue = Utils.ConvertToFloat(compValue);
    var serviceItemIndex = tempServiceItems.findIndex(
      (t) => t.Sequence === existItemComp.index
    );
    if (newCompValue > 0) {
      switch (discountType) {
        case OfferTypes.PercentDiscount:
          compDescDetailed = "Discount Percentage " + compValue + "%";
          if (existItemComp.orderLevel) {
            tempServiceItems.forEach((item) => {
              itemTotalPrice = item.Price * item.Quantity;
              discountTotal =
                ((itemTotalPrice - item.DiscountTotal) * newCompValue) / 100;
              discount = {
                CampaignId: compId,
                BenefitId: "",
                CampaignType: "Advertisement",
                OffersClassificationType: "Campaign",
                BenefitType: "DiscountPercentage",
                Code: compDescDetailed,
                Description: compDesc,
                DescriptionDetail: compDescDetailed,
                Quantity: 0.0,
                Complimentary: true,
                OrderLevel: true,
                PointsRedemption: false,
                DiscountType: discountType,
                DiscountValue: newCompValue,
                DiscountTotal: discountTotal,
              };
              item.Discounts.push(discount);
              item.DiscountTotal = item.Discounts.map(
                (i) => i.DiscountTotal
              ).reduce((a, b) => a + b);
            });
            var _totalPrice = Utils.ConvertToFloat(tempServiceItems.map((c) => c.TotalPrice).reduce((a, b) => a + b));
            var _discountPrice = Utils.ConvertToFloat(tempServiceItems.map((c) => c.DiscountTotal).reduce((a, b) => a + b));
            var _maxPrice = _totalPrice - _discountPrice;
            if (_maxPrice < 1) {
              setMaxPrice(0);
            }
          } else {
            itemTotalPrice =
              tempServiceItems[serviceItemIndex].Price *
              tempServiceItems[serviceItemIndex].Quantity;
            discountTotal =
              ((itemTotalPrice -
                tempServiceItems[serviceItemIndex].DiscountTotal) *
                newCompValue) /
              100;
            discount = {
              CampaignId: compId,
              BenefitId: "",
              CampaignType: "Advertisement",
              OffersClassificationType: "Campaign",
              BenefitType: "DiscountPercentage",
              Code: tempServiceItems[serviceItemIndex].ProductCode,
              Description: compDesc,
              DescriptionDetail: compDescDetailed,
              Quantity: 0.0,
              Complimentary: true,
              OrderLevel: false,
              PointsRedemption: false,
              DiscountType: discountType,
              DiscountValue: newCompValue,
              DiscountTotal: discountTotal,
            };
            tempServiceItems[serviceItemIndex].Discounts.push(discount);
            tempServiceItems[serviceItemIndex].DiscountTotal = tempServiceItems[
              serviceItemIndex
            ].Discounts.map((i) => i.DiscountTotal).reduce((a, b) => a + b);
            var _maxPrice = tempServiceItems[serviceItemIndex].TotalPrice - tempServiceItems[serviceItemIndex].DiscountTotal;
            if (_maxPrice < 1) {
              setMaxPrice(0);
            }
          }
          break;
        case OfferTypes.Cash:
          compDescDetailed =
            "Cash Back " + Utils.GetCurrency(compValue, countryInfo);
          var updatedCompValue = 0;
          if (existItemComp.orderLevel) {
            var totalSum = tempServiceItems
              .map((i) => i.TotalPrice)
              .reduce((a, b) => a + b);
            var totalDiscountSum = tempServiceItems
              .map((i) => i.DiscountTotal)
              .reduce((a, b) => a + b);
            updatedCompValue =
              (newCompValue / (totalSum - totalDiscountSum)) * 100;
            tempServiceItems.forEach((item) => {
              itemTotalPrice = item.Price * item.Quantity;
              discountTotal =
                ((itemTotalPrice - item.DiscountTotal) * updatedCompValue) /
                100;
              discount = {
                CampaignId: compId,
                BenefitId: "",
                CampaignType: "Advertisement",
                OffersClassificationType: "Campaign",
                BenefitType: "DiscountPercentage",
                Code: "Cash " + newCompValue,
                Description: compDesc,
                DescriptionDetail: compDescDetailed,
                Quantity: 0.0,
                Complimentary: true,
                OrderLevel: true,
                PointsRedemption: false,
                DiscountType: discountType,
                DiscountValue: updatedCompValue,
                DiscountTotal: discountTotal,
              };
              item.Discounts.push(discount);
              item.DiscountTotal = item.Discounts.map(
                (i) => i.DiscountTotal
              ).reduce((a, b) => a + b);
            });
            var _totalPrice = Utils.ConvertToFloat(
              tempServiceItems.map((c) => c.TotalPrice).reduce((a, b) => a + b)
            );
            var _discountPrice = Utils.ConvertToFloat(
              tempServiceItems
                .map((c) => c.DiscountTotal)
                .reduce((a, b) => a + b)
            );
            var _maxPrice = _totalPrice - _discountPrice;
            setMaxPrice(_maxPrice);
          } else {
            itemTotalPrice =
              tempServiceItems[serviceItemIndex].Price *
              tempServiceItems[serviceItemIndex].Quantity;
            discountTotal =
              ((itemTotalPrice -
                tempServiceItems[serviceItemIndex].DiscountTotal) *
                compValue) /
              100;
            discount = {
              CampaignId: compId,
              BenefitId: "",
              CampaignType: "Advertisement",
              OffersClassificationType: "Campaign",
              BenefitType: "DiscountPercentage",
              Code: tempServiceItems[serviceItemIndex].ProductCode,
              Description: compDesc,
              DescriptionDetail: compDescDetailed,
              Quantity: 0.0,
              Complimentary: true,
              OrderLevel: false,
              PointsRedemption: false,
              DiscountType: discountType,
              DiscountValue: newCompValue,
              DiscountTotal: newCompValue,
            };
            tempServiceItems[serviceItemIndex].Discounts.push(discount);
            tempServiceItems[serviceItemIndex].DiscountTotal = tempServiceItems[
              serviceItemIndex
            ].Discounts.map((i) => i.DiscountTotal).reduce((a, b) => a + b);
            var _maxPrice =
              tempServiceItems[serviceItemIndex].TotalPrice -
              tempServiceItems[serviceItemIndex].DiscountTotal;
            setMaxPrice(_maxPrice);
          }
          break;
      }
      tempServiceItems = recalculateTaxAmountOnDiscountApply(tempServiceItems);
      var itemDiscounts = [];
      tempServiceItems.forEach((item) => {
        if (!Utils.IsNullOrEmptyArray(item.Discounts)) {
          item.Discounts.forEach((discount) => {
            if (discount.CampaignId === compId) {
              itemDiscounts.push(discount);
            }
          });
        }
      });
      var newDiscount = !Utils.IsNullOrEmptyArray(itemDiscounts)
        ? itemDiscounts.map((d) => d.DiscountTotal).reduce((a, b) => a + b)
        : 0;
      var newComplimentary = {
        orderLevel: existItemComp.orderLevel,
        productId: !existItemComp.orderLevel
          ? tempServiceItems[serviceItemIndex].ProductId
          : "",
        value: compValue,
        type: discountType,
        discount: !Utils.IsNullOrEmptyArray(itemDiscounts)
          ? itemDiscounts.map((d) => d.DiscountTotal).reduce((a, b) => a + b)
          : 0,
        compId: compId,
      };
      const tempOrderComplimentaryList = [...orderComplimentaryList];
      const tempItemComplimentaryList = [...itemComplimentaryList];
      if (newComplimentary.orderLevel) {
        tempOrderComplimentaryList.push(newComplimentary);
        setOrderComplimentaryList(tempOrderComplimentaryList);
      } else {
        newComplimentary = {
          ...newComplimentary,
          index: tempServiceItems[serviceItemIndex].Sequence,
        };
        tempItemComplimentaryList.push(newComplimentary);
        setItemComplimentaryList(tempItemComplimentaryList);
      }
      var tempOrderLevelDiscounts = [];
      tempOrderLevelDiscounts = getOrderLevelDiscounts(
        tempServiceItems,
        tempOrderLevelDiscounts
      );
      setOrderLevelDiscounts(tempOrderLevelDiscounts);
      setServiceItems(tempServiceItems);
      setCompValue(0);
      setComplimentaryValue("");
    }
  };

  const recalculateTaxAmountOnDiscountApply = (serviceItemsToCalculate) => {
    if (Utils.IsNullOrEmptyArray(serviceItemsToCalculate)) {
      return serviceItemsToCalculate;
    }
    serviceItemsToCalculate.forEach((item) => {
      var subTotal = item.Price * item.Quantity - item.DiscountTotal;
      var grandTotal = (subTotal * (item.TaxPercent + 100)) / 100;
      item.TaxAmount = grandTotal - subTotal;
    });
    return serviceItemsToCalculate;
  };

  async function removeCompItem(index) {
    var indexOfRemoval = 0;
    var comp = existItemComp.orderLevel
      ? orderComplimentaryList[index]
      : itemComplimentaryList[index];
    var orderLevel = comp.orderLevel;
    var discount = {};
    var tempServiceItems = [...serviceItems];
    if (!orderLevel) {
      var serviceItem = tempServiceItems.find(
        (s) =>
          !Utils.IsNullOrEmptyObject(
            s.Discounts.find((d) => d.CampaignId === comp.compId)
          )
      );
      var i = tempServiceItems.findIndex(
        (t) => t.Sequence === serviceItem.Sequence
      );
      discount = tempServiceItems[i].Discounts.find(
        (d) => d.CampaignId === comp.compId
      );
      indexOfRemoval = tempServiceItems[i].Discounts.findIndex(
        (d) => d.CampaignId === discount.CampaignId
      );
      tempServiceItems[i].Discounts.splice(indexOfRemoval, 1);
      if (
        (tempServiceItems[i].TotalPrice !== 0 &&
          tempServiceItems[i].TotalPriceIncludingTax !== 0) ||
        itemComplimentaryList.length === 1
      ) {
        var totalPrice =
          tempServiceItems[i].TotalPrice + discount.DiscountTotal;
        var discountTotal = 0;
        if (!Utils.IsNullOrEmptyArray(tempServiceItems[i].Discounts)) {
          discountTotal = tempServiceItems[i].Discounts.map(
            (d) => d.DiscountTotal
          ).reduce((a, b) => a + b);
        }
        tempServiceItems[i].DiscountTotal = discountTotal;
      }
      var tempitemlist = [...itemComplimentaryList];
      tempitemlist.splice(index, 1);
      setItemComplimentaryList(tempitemlist);
      var _maxPrice =
        tempServiceItems[i].TotalPrice - tempServiceItems[i].DiscountTotal;
      setMaxPrice(_maxPrice);
    } else {
      const tempOrderLevelDiscounts = [...orderLevelDiscounts];
      tempOrderLevelDiscounts.splice(
        tempOrderLevelDiscounts.findIndex((t) => t.CampaignId === comp.compId),
        1
      );
      tempServiceItems.forEach((item) => {
        discount = item.Discounts.find((d) => d.CampaignId === comp.compId);
        if (!Utils.IsNullOrEmptyObject(discount)) {
          indexOfRemoval = item.Discounts.findIndex(
            (d) => d.CampaignId === discount.CampaignId
          );
          item.Discounts.splice(indexOfRemoval, 1);
          if (
            (item.TotalPrice !== 0 && item.TotalPriceIncludingTax !== 0) ||
            orderComplimentaryList.length === 1
          ) {
            var updatedTotalPrice = item.TotalPrice + discount.DiscountTotal;
            var newDiscountTotal = 0;
            if (!Utils.IsNullOrEmptyArray(item.Discounts)) {
              newDiscountTotal = item.Discounts.map(
                (d) => d.DiscountTotal
              ).reduce((a, b) => a + b);
            }
            item.DiscountTotal = newDiscountTotal;
          }
        }
      });
      var _totalPrice = Utils.ConvertToFloat(
        tempServiceItems.map((c) => c.TotalPrice).reduce((a, b) => a + b)
      );
      var _discountPrice = Utils.ConvertToFloat(
        tempServiceItems.map((c) => c.DiscountTotal).reduce((a, b) => a + b)
      );
      var _maxPrice = _totalPrice - _discountPrice;
      setMaxPrice(_maxPrice);
      var temporderlist = [...orderComplimentaryList];
      temporderlist.splice(index, 1);
      setOrderComplimentaryList(temporderlist);
      setOrderLevelDiscounts(tempOrderLevelDiscounts);
    }
    tempServiceItems = recalculateTaxAmountOnDiscountApply(tempServiceItems);
    setServiceItems(tempServiceItems);
  }

  const closeAddressSelector = () => {
    setOpenAddressSelector(false);
  };

  const closePaymentSelector = () => {
    setOpenPaymentSelector(false);
  };

  const clickCloseTip = () => {
    openTipModal(false);
  }

  const showPartialPayConfirmation = () => {
    // if (branch.allowMultiplePayments && !location.state.isEdit) {
    //   setShowConfirmDialogForPartialPay(true);
    //   return;
    // }
    //show payment selector
    // setOpenPaymentTypeSelector(true);
    setAllMixedPaymentInputs([]);
    handleOpenMixedPayment();

  };

  const beginBook = async () => {
    if (!anonymous) {
      //show Address types
      var request = { Id: pass.customerId, BusinessId: pass.customerId };
      setIsLoading(true);
      var result = await postAsync("Customer/GetCustomer", request, "", "Get");
      setIsLoading(false);
      var shippingToDefault = "";
      if (!result.error) {
        shippingToDefault = result.data.homeLocation;
        setCustomerAddress(result.data.homeLocation);
      }
      var shippingFromDefault =
        branch.address +
        "," +
        branch.addressLine2 +
        "," +
        branch.addressLine3 +
        "," +
        branch.city +
        "," +
        branch.state +
        "," +
        branch.country;
      setShippingFromDefaultAddress(shippingFromDefault);
      setShippingToDefaultAddress(shippingToDefault);
      //   if(CountryHelper.IsBusinessCountryIndia(countryInfo))
      //   {
      //   setOpenAddressSelector(true);
      // }
      // else{
      //   showPartialPayConfirmation();
      // }
      showPartialPayConfirmation();
    } else {
      var discountTotal = serviceItems
        .map((i) => i.DiscountTotal)
        .reduce((a, b) => a + b);
      var total = serviceItems.map((i) => i.TotalPrice).reduce((a, b) => a + b);
      if (total - discountTotal > 0) {
        //show Payment Types
        showPartialPayConfirmation();
      } else {
        //process payments
        setCash(true);
      }
    }
  };

  const closePaymentType = (paymentType) => {
    var amount = outstandingAmount.toFixed(2);
    var paymentAmount = isPartialPaySelected
      ? paidByCustomerAmount
      : parseFloat(amount);
    setAutoPay(false);
    setCash(false);
    setNetBanking(false);
    setPayTerminal(false);
    setUPI(false);
    setPayLater(false);
    setPayNow(false);
    setCardPay(false);
    switch (paymentType) {
      case PaymentTypes.Cash:
        setCash(true);
        setOpenPaymentSelector(false);
        break;
      case PaymentTypes.CardPay:
        setCardPay(true);
        setOpenPaymentSelector(false);
        setShowCardDetails(true);
        break;
      case PaymentTypes.NetBanking:
        setNetBanking(true);
        setOpenPaymentSelector(false);
        break;
      case PaymentTypes.UPI:
        var newUpiValue =
          "upi://pay?pa=" +
          branch.upi.upiId +
          "&pn=" +
          branch.upi.upiPayeeName +
          "&cu=INR&am=" +
          paymentAmount;
        if (!Utils.IsNullOrEmpty(newUpiValue)) {
          setOpenPaymentSelector(false);
          setUPIValue(newUpiValue);
          setUPI(true);
          setShowUPIDetails(true);
        }
        break;
      case PaymentTypes.PaymentLink:
        if (paymentAmount > MaxRazorPayLimit) {
          setAlert(
            "The maximum amount allowed via this payment link is " +
            Utils.GetCurrency(MaxRazorPayLimit, countryInfo) +
            ". Please select an alternative payment method or opt for partial payments to proceed."
          );
        }
        else if (paymentAmount < MinRazorPayLimit) {
          setAlert(
            "The minimum amount allowed via this payment link is " +
            Utils.GetCurrency(MinRazorPayLimit, countryInfo) +
            ". Please select an alternative payment method to proceed."
          );
        }
        else {
          setPaymentLinkAmount(paymentAmount);
          setPaymentLinkMobileNumber(
            Utils.IsNullOrEmptyObject(pass) ? "" : pass.mobileNumber
          );
          setOpenPaymentSelector(false);
          setPaymentLink(true);
          setShowPaymentLinkDetails(true);
        }
        break;
      default:
        setOpenPaymentSelector(false);
        if (isPartialPaySelected) {
          setShowPartialPayEntryDialog(true);
        } else if (
          !anonymous &&
          CountryHelper.IsBusinessCountryIndia(countryInfo)
        ) {
          //setOpenAddressSelector(true);
        }
        break;
    }
  };

  // useEffect(() => {
  //   canProceedPayment();
  // }, [cash, upi, cardPay, netBanking]);

  const canProceedPayment = async () => {
    if (cash || upi || cardPay || netBanking) {
      if (cash || netBanking) {
        await processPayment();
        return;
      }
    }
  };

  const updateVisitOnOfferApplied = async (orderDiscounts, orderComps) => {
    var request = {
      VisitId: Utils.IsNullOrEmptyObject(visit) ? "" : visit.id,
      BranchId: branch.branchId,
      CustomerTierLevel: Utils.IsNullOrEmptyObject(pass) ? "" : pass.tierLevel,
      Comps: Utils.IsNullOrEmptyArray(orderComps) ? null : orderComps,
      Offers: Utils.IsNullOrEmptyArray(orderDiscounts) ? null : orderDiscounts,
      ServiceItems: serviceItems,
      IsTaxIncluded:
        !Utils.IsNullOrEmptyObject(selectedInvoiceLabel) &&
        selectedInvoiceLabel.taxIncluded,
    };
    var result = await postAsync("Customer/UpdateVisitOnOfferChange", request);
  };

  const getCampaignOffers = () => {
    var orderDiscounts = [];
    var selectedOffers = offersViewModel.filter(
      (o) => o.Selected && !Utils.IsNullOrEmpty(o.CampaignId)
    );
    selectedOffers.forEach((selectedOffer) => {
      var allDiscounts = serviceItems.map((c) => c.Discounts);
      allDiscounts.forEach((disc) => {
        if (
          !disc.Complimentary &&
          !disc.OrderLevel &&
          disc.CampaignId === selectedOffer.CampaignId
        ) {
          return;
        }
        var newOffer = {
          CampaignId: selectedOffer.CampaignId,
          CampaignType: selectedOffer.CampaignType,
          Description: selectedOffer.Description,
          DescriptionDetail: selectedOffer.Type,
          DiscountTotal: selectedOffer.DiscountTotal,
          DiscountType: selectedOffer.DiscountType,
          DiscountValue: selectedOffer.DiscountValue,
        };
        orderDiscounts.push(newOffer);
      });
    });
    return orderDiscounts;
  };

  const getOrderComps = () => {
    var orderComps = [];
    orderComplimentaryList
      .filter((o) => o.orderLevel)
      .forEach((comp) => {
        var orderComp = {
          Complimentary: true,
          Description: "Order Complimentary",
          DescriptionDetail:
            comp.type === OfferTypes.PercentDiscount
              ? "Discount Percentage " + comp.value + "%"
              : "Cash Back " + Utils.GetCurrency(comp.value, countryInfo),
          DiscountTotal: comp.discount,
          DiscountValue: comp.value,
          DiscountType: comp.type,
        };
        orderComps.push(orderComp);
      });
    orderComps
      .filter((d) => d.DiscountType === OfferTypes.PercentDiscount)
      .forEach((orderComp) => {
        var tp = serviceItems.map((d) => d.TotalPrice).reduce((a, b) => a + b);
        var dp = serviceItems
          .map((d) => d.DiscountTotal)
          .reduce((a, b) => a + b);
        orderComp.DiscountTotal = ((tp - dp) * orderComp.DiscountValue) / 100;
      });
    return orderComps;
  };

  const [transactionFailureResult, setTransactionFailureResult] = useState("");
  const processPayment = async () => {
    setIsLoading(true);
    var orderDiscounts = getCampaignOffers();
    var orderComps = getOrderComps();
    if (
      !Utils.IsNullOrEmptyObject(visit) &&
      BusinessHelper.IsAutomobile(business) &&
      (isBusinessCountryIndia || isBusinessCountryUAE) &&
      visit.visitType === VisitTypeEnum.Jobcard &&
      !processingPartialPay
    ) {
      //upfateVisit on offer Apply
      await updateVisitOnOfferApplied(orderDiscounts, orderComps);
    }
    var paymentMethod = PaymentMethodTypes.Cash;
    if (cash) {
      paymentMethod = PaymentMethodTypes.Cash;
    }
    if (upi) {
      paymentMethod = PaymentMethodTypes.UPI;
    }
    if (netBanking) {
      paymentMethod = PaymentMethodTypes.NetBanking;
    }
    if (cardPay && (isBusinessCountryIndia || isBusinessCountryUAE)) {
      paymentMethod = PaymentMethodTypes.Card;
    }

    if (paymentLink && isBusinessCountryIndia) {
      paymentMethod = PaymentMethodTypes.PaymentLink;
    }
    var redemptions = [];
    var selectedAMCOffers = offersViewModel.filter(
      (o) =>
        o.Selected &&
        o.OffersClassificationType === OfferClassificationTypes.AMC
    );
    if (!Utils.IsNullOrEmptyArray(selectedAMCOffers)) {
      selectedAMCOffers.forEach((amcOffer) => {
        var discountTotal = 0;
        var filteredDiscounts = [];
        serviceItems.forEach((item) => {
          var discounts = [];
          if (!Utils.IsNullOrEmptyArray(item.Discounts)) {
            discounts = item.Discounts.filter(
              (d) =>
                d.CampaignId === amcOffer.CampaignId &&
                d.BenefitId === amcOffer.BenefitId &&
                d.OffersClassificationType === OfferClassificationTypes.AMC
            );
          }
          if (!Utils.IsNullOrEmptyArray(discounts)) {
            discounts.forEach((d) => {
              filteredDiscounts.push(d);
            });
          }
        });
        if (!Utils.IsNullOrEmptyArray(filteredDiscounts)) {
          discountTotal = filteredDiscounts
            .map((d) => d.DiscountTotal)
            .reduce((a, b) => a + b);
        }
        if (discountTotal > 0) {
          var currentDiscountAppliedItems = [];
          serviceItems.forEach((serviceItem) => {
            if (!Utils.IsNullOrEmptyArray(serviceItem.Discounts)) {
              serviceItem.Discounts.forEach((disc) => {
                if (
                  disc.CampaignId === amcOffer.CampaignId &&
                  disc.BenefitId === amcOffer.BenefitId &&
                  disc.OffersClassificationType === OfferClassificationTypes.AMC
                ) {
                  currentDiscountAppliedItems.push(serviceItem);
                }
              });
            }
          });
          var itemTotal = 0;
          if (!Utils.IsNullOrEmptyArray(currentDiscountAppliedItems)) {
            itemTotal = currentDiscountAppliedItems
              .map((s) => s.TotalPrice)
              .reduce((a, b) => a + b);
          }
          var redemptionDictionary = {
            BenefitId: amcOffer.BenefitId,
            Redemption: {
              RedemptionDT: moment().format(),
              DiscountPrice: discountTotal,
              TotalPrice: itemTotal,
            },
          };
          redemptions.push(redemptionDictionary);
        }
      });
    }
    var taxIncluded =
      !Utils.IsNullOrEmptyObject(selectedInvoiceLabel) &&
      selectedInvoiceLabel.taxIncluded;
    const taxExcludedItems = [];
    if (!taxIncluded) {
      var newItems = [...serviceItems];
      newItems.forEach((item) => {
        item.TaxPercent = 0;
        taxExcludedItems.push(item);
      });
    }
    var dtt = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    var currentStart = Utils.GetISODateTimeString(dtt);
    var schduleStart = Utils.GetISODateTimeString(moment(planStartDTAMC));
    var tempvalue =
      Utils.IsNullOrEmptyArray(offersViewModel) ||
        Utils.IsNullOrEmptyObject(
          offersViewModel.find(
            (o) =>
              o.OffersClassificationType === OfferClassificationTypes.AMC &&
              o.Selected === true
          )
        )
        ? ""
        : offersViewModel.find(
          (o) =>
            o.OffersClassificationType === OfferClassificationTypes.AMC &&
            o.Selected === true
        ).CampaignId;

    if (branch.invoiceToPreviousDate) {
      invoiceDT.set({
        hour: invoiceTime.hour(),
        minute: invoiceTime.minute(),
        second: 0,
      });
    }
    let dtIsoString = Utils.GetISODateTimeString(invoiceDT.utc());
    var request = {
      AutoPay: autoPay,
      CardPay: cardPay && isBusinessCountryUS,
      BookingRef: Utils.CreateGuid(),
      BusinessId: business.id,
      Comments: comments,
      PaymentReceivedBy: paymentReceivedBy,
      Comps: Utils.IsNullOrEmptyArray(orderComps) ? null : orderComps,
      ConfirmationMessageId: "",
      CustomerId:
        Utils.IsNullOrEmptyObject(pass) || pass.isBusiness
          ? ""
          : pass.customerId,
      IncludeFees: false,
      Offers: Utils.IsNullOrEmptyArray(orderDiscounts) ? null : orderDiscounts,
      Paid: cash || upi || cardPay,
      PassId: Utils.IsNullOrEmptyObject(pass) || pass.isBusiness ? "" : pass.id,
      PaymentMethod: paymentMethod,
      PayNow: payNow,
      //PointsRedemption:[],
      ServiceItems: taxIncluded ? serviceItems : taxExcludedItems,
      TaxFree: false,
      Tip: tipAmount,
      Upi: upi,
      NetBanking: netBanking,
      IndianCard: cardPay && isBusinessCountryIndia,
      NameOnCard: nameOnCard,
      CardLast4: lastFourDigits,
      VisitId: Utils.IsNullOrEmptyObject(visit) ? "" : visit.id,
      ShippingFromAddress: shippingFromAddressObject,
      ShippingToAddress: shippingToAddressObject,
      ReverseChargeAppilicable: reverseChargeApplicable,
      InvoiceLabelDescription: !Utils.IsNullOrEmptyObject(selectedInvoiceLabel)
        ? selectedInvoiceLabel.description
        : "",
      BranchId: branch.branchId,
      IsTaxIncluded:
        !Utils.IsNullOrEmptyObject(selectedInvoiceLabel) &&
        selectedInvoiceLabel.taxIncluded,
      ActivatePlanNow: isActiveNowAMCPlan,
      PlanStartDT: isActiveNowAMCPlan == true ? currentStart : schduleStart,
      ConflictingPlansFound: IsConflictingPlansFound,
      SelectedPlanId: tempvalue,
      invoiceDT: dtIsoString,
      Redemptions: redemptions,
      UpdateVisit: location.state.isEdit,
      PaidByCustomerAmount: Utils.ConvertToFloat(
        paidByCustomerAmount.toFixed(2)
      ),
      IsPartialPaymentSelected: isPartialPaySelected,
      PaymentAmount: Utils.ConvertToFloat(totalPrice.toFixed(2)),
      LPO: countryInfo.Code === UAE.Code ? lpo : "",
    };
    if (mixedPaymentModeSelected) {
      let mixedpaymentsarr = [];
      allMixedPaymentInputs.map((item) => {
        let obj = {
          PaymentMethod: item.paymentMethod,
          Amount: item.amount
        };
        if (item.paymentMethod === "Payment Link") {
          var settleAcc = JSON.parse(item.settlementAccount);
          obj = {
            ...obj,
            PaymentLinkData: {
              MobileNumber: item.paymentLinkMobileNumber,
              PaymentAmount: Utils.ConvertToFloat(item.amount),
              SettlementAccountId: Utils.IsNullOrEmptyObject(settleAcc)
                ? ""
                : settleAcc.payoutAccountId,
              SettlementAccountAlias: Utils.IsNullOrEmptyObject(settleAcc)
                ? ""
                : settleAcc.payoutAccountName,
            },
          }
        }
        mixedpaymentsarr.push(obj);
      })
      request.Payments = mixedpaymentsarr;
      request.PaymentMode = selectedPaymentMode;
    }
    if (paymentMethod === PaymentMethodTypes.PaymentLink) {
      setPaymentLinkSelected(true);
      var settleAcc = JSON.parse(settlementAccount);
      request = {
        ...request,
        PaymentLinkData: {
          MobileNumber: paymentLinkMobileNumber,
          PaymentAmount: Utils.ConvertToFloat(paymentLinkAmount),
          SettlementAccountId: Utils.IsNullOrEmptyObject(settleAcc)
            ? ""
            : settleAcc.payoutAccountId,
          SettlementAccountAlias: Utils.IsNullOrEmptyObject(settleAcc)
            ? ""
            : settleAcc.payoutAccountName,
        },
      };
    }
    if (
      !Utils.IsNullOrEmptyObject(vehicleDetails) &&
      !Utils.IsNullOrEmpty(vehicleDetails.number)
    ) {
      request = {
        ...request,
        VehicleDetails: vehicleDetails,
      };
    } else {
      request = {
        ...request,
        VehicleDetails: "",
      };
    }
    var result = await postAsync("Payment/Book", request);
    setIsLoading(false);
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return;
    }
    switch (result.data.errorType) {
      case PartialPaymentErrorTypes.None:
        setUpdatedBusiness(result.data.businessEntity, dispatch);
        setUpdatedBranch(
          result.data.businessEntity.branchLocations.find(
            (b) => b.branchId === branch.branchId
          ),
          dispatch
        );
        var newPass = result.data.pass;
        setAutoPay(false);
        setCash(false);
        setUPI(false);
        setCardPay(false);
        setPayTerminal(false);
        setNetBanking(false);
        setPayNow(false);
        setPayLater(false);
        setVisit(result.data.visit);
        if (!Utils.IsNullOrEmptyObject(newPass)) {
          setPass(newPass);
          if (!newPass.isBusiness) {
            setCustomerName(newPass.userName);
            setCustomerEmailId(newPass.emailId);
            setCustomerMobileNumber(newPass.mobileNumber);
          }
        }
        if (
          result.data.visit.status !== VisitStatuses.Delivered &&
          (paymentMethod === PaymentMethodTypes.PaymentLink ||
            isPartialPaySelected === true)
        ) {
          var newState = {
            ...location.state,
            navigationStack: [OverviewPage.Path],
            visit: result.data.visit,
            pass: newPass,
            activeMenu: activeMenu,
          };
          navigate(PaymentsPage.Path, { state: newState });
        } else {
          setShowReceiptOptions(true);
        }
        break;
      case PartialPaymentErrorTypes.Completed:
        setTransactionFailureResult(PartialPaymentErrorTypes.Completed);
        setVisit(result.data.visit);
        setAlertTitle("Transaction Failed");
        setAlert(result.data.errorMessage);
        break;
      case PartialPaymentErrorTypes.AmountExceeds:
        setTransactionFailureResult(PartialPaymentErrorTypes.AmountExceeds);
        setVisit(result.data.visit);
        setAlertTitle("Transaction Failed");
        setAlert(result.data.errorMessage);
        break;
    }
  };

  const getVehicle = () => {
    var vehicleDetails = {};
    if (
      !Utils.IsNullOrEmpty(vehicleNumber) &&
      ((customMade &&
        !Utils.IsNullOrEmpty(vehicleCustomMake) &&
        !Utils.IsNullOrEmpty(vehicleCustomModel)) ||
        (!Utils.IsNullOrEmpty(vehicleMake) &&
          !Utils.IsNullOrEmpty(vehicleModel)))
    ) {
      var insurance = {
        ExpiryDate: moment().format(),
      };
      vehicleDetails = {
        Number: Utils.ConvertToUpperCase(vehicleNumber.trim()),
        Make: customMade ? vehicleCustomMake.trim() : vehicleMake.trim(),
        Model: customMade ? vehicleCustomModel.trim() : vehicleModel.trim(),
        VehicleType: branch.vehicleType,
        FuelType: vehicleFuelType,
        VehicleInsuranceDetails: insurance,
      };
    }
    return vehicleDetails;
  };

  const saveVehicle = async () => {
    var newVehicle = getVehicle();
    if (
      !Utils.IsNullOrEmptyObject(pass) &&
      !Utils.IsNullOrEmptyObject(newVehicle)
    ) {
      var request = {
        IsAutomobile: true,
        VisitId: !Utils.IsNullOrEmptyObject(visit) ? visit.id : "",
        PassId: !Utils.IsNullOrEmptyObject(pass) ? pass.id : "",
        VehicleDetail: newVehicle,
      };
      var result = await postAsync("Customer/SaveVehicleOnCheckout", request);
      return result;
    }
    return { error: false };
  };

  const onClickOkReceiptOptions = async () => {
    var mobileNumbers = customerMobileNumber.split(",");
    mobileNumbers = mobileNumbers.filter((i) => !Utils.IsNullOrEmpty(i));
    if (
      !Utils.IsNullOrEmptyArray(mobileNumbers) &&
      !mobileNumbers.every((i) => Utils.IsMobileValid(i))
    ) {
      setAlert("Please enter valid mobile number");
      return;
    }
    if (isAutomobileWithoutVehicle && !anonymous) {
      var validVehicleDetails = isVehicleDetailsValid();
      if (!validVehicleDetails) {
        setAlert("Please enter valid vehicle details");
        return;
      }
      setIsLoading(true);
      var saved = await saveVehicle();
      setIsLoading(false);
      if (saved.error) {
        setIsNavigateToLogin(saved.isNavigateToLogin);
        setAlert(saved.errorMessage);
        return;
      }
    }
    if (anonymous) {
      if (!isVehicleDetailsValid()) {
        setAlert("Please enter valid vehicle details");
        return;
      }
      if (
        !Utils.IsNullOrEmpty(customerEmailId) ||
        ((isBusinessCountryIndia || isBusinessCountryUAE) &&
          !Utils.IsNullOrEmpty(customerMobileNumber))
      ) {
        setIsLoading(true);
        await saveCustomer();
        setIsLoading(false);
      }
    }

    // if(branch.sendAutomaticMessage && !Utils.IsNullOrEmpty(customerEmailId) && Utils.IsEmailValid(customerEmailId) && !emailSent)
    // {
    //   await emailReceipt();
    // }
    // if(branch.sendAutomaticMessage && !Utils.IsNullOrEmpty(customerMobileNumber) && customerMobileNumber.length === 10 && !smsSent)
    // {
    //   await sendSMSInvoice();
    // }
    goBack();
  };

  const goBack = (cancel = false) => {
    setShowReceiptOptions(false);
    setMasterServiceItemsList([]);
    setServiceItems([]);
    if (cancel) {
      navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
        state: {
          ...location.state,
          navigationStack: Utils.RemoveLastElementFromArray(
            location.state.navigationStack
          ),
          visit: visit,
          pass: location.state.pass,
          activeMenu: activeMenu,
        },
      });
      // var navigateTo = location.state.checkout
      //   ? CheckOutPage.Path
      //   : location.state.AMCPlan
      //     ? JobCardDetailsPage.Path
      //     : AMCPlanPage.Path;
      // var newState = location.state.checkout
      //   ? {
      //     checkout: location.state.checkout,
      //     pass: location.state.pass,
      //     serviceItems: location.state.serviceItems,
      //     vehicleDetails: location.state.vehicleDetails,
      //     fromJobcard: location.state.fromJobcard,
      //     fromVisit: location.state.fromVisit,
      //     isEdit: location.state.isEdit,
      //     validVehiclesCount: location.state.validVehiclesCount,
      //     searchText:
      //       location.state && location.state.searchText
      //         ? location.state.searchText
      //         : "",
      //   }
      //   : location.state.visit
      //     ? {
      //       visit: location.state.visit,
      //       fromJobcard: location.state.fromJobcard,
      //       fromVisit: location.state.fromVisit,
      //       isEdit: location.state.isEdit,
      //       validVehiclesCount: location.state.validVehiclesCount,
      //       searchText:
      //         location.state && location.state.searchText
      //           ? location.state.searchText
      //           : "",
      //     }
      //     : {
      //       pass: pass,
      //       serviceItems: serviceItems,
      //       vehicleDetails: vehicleDetails,
      //       fromJobcard: location.state.fromJobcard,
      //       fromAMCPlan: true,
      //       fromVisit: location.state.fromVisit,
      //       isEdit: location.state.isEdit,
      //       validVehiclesCount: location.state.validVehiclesCount,
      //       searchText:
      //         location.state && location.state.searchText
      //           ? location.state.searchText
      //           : "",
      //     };
      // navigate(navigateTo, { state: newState });
      return;
    }
    var navigationStack = [];
    if (processingPartialPay) {
      navigationStack = Utils.RemoveLastElementFromArray(
        location.state.navigationStack
      );
    }
    navigate(location.state.onSuccess, {
      state: {
        ...location.state,
        navigationStack: navigationStack,
        visit: visit,
        activeMenu: activeMenu,
        isAMCSuccess: location.state.onSuccess,
      },
    });
    // if (anonymous) {
    //   navigate(OverviewPage.Path);
    //   return;
    // }
    // if (location.state.fromVisit) {
    //   navigate(VisitsPage.Path);
    //   return;
    // }
    // if (location.state.fromAMCPlan) {
    //   navigate(AMCPlanPage.Path);
    //   return;
    // }
    // navigate(CustomersPage.Path, {
    //   state: {
    //     fromJobcard: location.state.fromJobcard,
    //     searchText:
    //       location.state && location.state.searchText
    //         ? location.state.searchText
    //         : "",
    //   },
    // });
  };

  const isVehicleDetailsValid = () => {
    if (
      (Utils.IsNullOrEmpty(vehicleNumber) &&
        !customMade &&
        Utils.IsNullOrEmpty(vehicleMake) &&
        Utils.IsNullOrEmpty(vehicleModel)) ||
      (Utils.IsNullOrEmpty(vehicleNumber) &&
        customMade &&
        Utils.IsNullOrEmpty(vehicleCustomMake) &&
        Utils.IsNullOrEmpty(vehicleCustomModel)) ||
      (!Utils.IsNullOrEmpty(vehicleNumber) &&
        customMade &&
        !Utils.IsNullOrEmpty(vehicleCustomMake) &&
        !Utils.IsNullOrEmpty(vehicleCustomModel)) ||
      (!Utils.IsNullOrEmpty(vehicleMake) && !Utils.IsNullOrEmpty(vehicleModel))
    ) {
      return true;
    }
    return false;
  };

  const saveCustomer = async () => {
    var addressDetails = null;
    if (!Utils.IsNullOrEmpty(addressDetails_Address)) {
      var geocodeResponse = await getGeocodeAddress(addressDetails_Address);
      if (
        !Utils.IsNullOrEmptyObject(geocodeResponse) &&
        geocodeResponse.status === "OK"
      ) {
        var locality = "";
        var county = "";
        var state = "";
        var country = "";
        var zipcode = "";
        var streetNumber = "";
        var route = "";
        var address = "";
        var lng = 0;
        var lat = 0;
        address = geocodeResponse.results[0].formatted_address;
        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("street_number") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("street_number")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            streetNumber = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("route") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("route")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            route = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("locality") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("locality")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            locality = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("administrative_area_level_1") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("administrative_area_level_1")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            state = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("administrative_area_level_2") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("administrative_area_level_2")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            county = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("country") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("country")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            country = ac.short_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("postal_code") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("postal_code")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            zipcode = ac.long_name;
          }
        }
        lat = geocodeResponse.results[0].geometry.location.lat;
        lng = geocodeResponse.results[0].geometry.location.lng;
        addressDetails = {
          HomeLocation: addressDetails_Address,
          Address: streetNumber + " " + route,
          City: locality,
          State: state,
          Country: country,
          County: county,
          ZipCode: zipcode,
          Locality: locality,
          Latitude: Utils.ConvertToFloat(lat),
          Longitude: Utils.ConvertToFloat(lng),
        };
      } else {
        setIsLoading(false);
        setAlert("Invalid address");
        return customerSaveType.Error;
      }
    }
    var newNehicle = getVehicle();
    var request = {
      BusinessId: getBusiness().id,
      BranchId: branch.branchId,
      CountryCode: Utils.IsNullOrEmptyObject(countryInfo)
        ? getCountry().Code
        : countryInfo.Code,
      CustomerVisit: Utils.IsNullOrEmptyObject(visit) ? null : visit,
      EmailId: !Utils.IsNullOrEmpty(customerEmailId)
        ? customerEmailId.trim()
        : "",
      CustomerName: !Utils.IsNullOrEmpty(customerName)
        ? customerName.trim()
        : "",
      MobileNumber: !Utils.IsNullOrEmpty(customerMobileNumber)
        ? customerMobileNumber.trim()
        : "",
      AddressDetails: addressDetails,
      VehicleDetail: !Utils.IsNullOrEmptyObject(newNehicle) ? newNehicle : null,
      EnterpriseId: getEnterpriseId(),
    };
    var result = await postAsync("Customer/SaveAnonymousCustomer", request);
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return customerSaveType.Error;
    }
    if (showCustomerSetupDialogForPartialPay) {
      setAddresDetails_Address("");
      setCustomerName("");
      setAnonymous(
        Utils.IsNullOrEmptyObject(result.data.pass) ||
        result.data.pass.isBusiness
      );
      setPass(result.data.pass);
    }
    setUpdatedBusiness(result.data.businessEntity, dispatch);
    return customerSaveType.Success;
  };
  useEffect(() => {
    checkCardDetailsFilled();
  }, [nameOnCard, lastFourDigits]);

  const checkCardDetailsFilled = () => {
    var trueCondition =
      !Utils.IsNullOrEmpty(nameOnCard) &&
      !Utils.IsNullOrEmpty(lastFourDigits) &&
      lastFourDigits.length === 4;
    setCardDetailsDisabled(!trueCondition);
  };

  const onClickCloseCardDetails = () => {
    setShowCardDetails(false);
    setOpenPaymentSelector(true);
  };

  const onClickOkCardDetails = async () => {
    setShowCardDetails(false);
    await processPayment();
    //process payment
  };

  const onClickCloseUPIDetails = () => {
    setShowUPIDetails(false);
    //setOpenPaymentSelector(true);
  };

  const onClickOkUPIDetails = async () => {
    setShowUPIDetails(false);
    await processPayment();
    //process payment
  };

  const onClickCancel = () => {
    setShowCancelConfirmation(true);
  };

  const confirmCancel = async (confirmResult) => {
    setShowCancelConfirmation(false);
    switch (confirmResult) {
      case ConfirmTypes.YES:
        if (!Utils.IsNullOrEmptyObject(visit) && visit.paidAmount === 0) {
          if (business.category !== BusinessCategories.Automobile) {
            setIsLoading(true);
            var request = {
              BusinessId: business.id,
              VisitId: visit.id,
            };
            var result = await postAsync("Payment/Cancel", request);
            setIsLoading(false);
            if (result.error) {
              setIsNavigateToLogin(result.isNavigateToLogin);
              setAlert(result.errorMessage);
              return;
            }
            setAlert(result.data);
          }
        }
        goBack(true);
        break;
      default:
        return;
    }
  };

  const handleAlertClose = () => {
    setAlertTitle("");
    setShowAlert(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
      return;
    }
    if (processingPartialPay && paymentLinkSelected) {
      setPaymentLinkSelected(false);
      setShowPaymentLinkDetails(true);
      return;
    }
    switch (transactionFailureResult) {
      case PartialPaymentErrorTypes.Completed:
        setTransactionFailureResult("");
        goBack();
        break;
      case PartialPaymentErrorTypes.AmountExceeds:
        setTransactionFailureResult("");
        setShowPartialPayEntryDialog(true);
        break;
    }
  };

  const confirmPartialPay = (action) => {
    setShowConfirmDialogForPartialPay(false);
    switch (action) {
      case partialPayActions.FullPay:
        setOpenPaymentSelector(true);
        break;
      case partialPayActions.PartialPayWithoutCustomer:
        //open cutomer setup
        setShowCustomerSetupDialogForPartialPay(true);
        break;
      case partialPayActions.PartialPay:
        //open partial pay entry
        setShowPartialPayEntryDialog(true);
        break;
      case partialPayActions.Close:
        if (CountryHelper.IsBusinessCountryIndia() && !anonymous) {
          setOpenAddressSelector(true);
        }
        break;
    }
  };

  const closePartialPayAmountEntry = () => {
    setShowPartialPayEntryDialog(false);
    if (processingPartialPay) {
      goBack(true);
      return;
    }
    if (branch.allowMultiplePayments && !location.state.isEdit) {
      setShowConfirmDialogForPartialPay(true);
    }
    if (CountryHelper.IsBusinessCountryIndia(countryInfo)) {
      setOpenAddressSelector(true);
    }
  };

  useEffect(() => {
    if (paidByCustomerAmount !== ".") {
      setBalanceAmount(outstandingAmount - paidByCustomerAmount);
    }
  }, [paidByCustomerAmount]);

  useEffect(() => {
    let value = 0;
    allMixedPaymentInputs.map((item) => {
      if (item.amount) {
        value += Number(item.amount)
      }
    });
    if (value) {
      setBalanceMixedAmount(amountToPaid - value);
    } else {
      setBalanceMixedAmount(amountToPaid);
    }
  }, [allMixedPaymentInputs])

  const proceedPartialPayment = () => {
    setShowPartialPayEntryDialog(false);
    setIsPartialPaySelected(true);
    setOpenPaymentSelector(true);
  };

  const validateAndSetPaymentAmountEnteredByCustomer = (e) => {
    var newValue = e.target.value;
    const regex = /^\d*\.?\d{0,2}$/;
    if (!regex.test(newValue)) {
      return;
    }
    var floatValue = Utils.ConvertToFloat(newValue);
    if (floatValue === 0) {
      setIsPartialPayProceedButtonDisabled(true);
      setPaidByCustomerAmountString(newValue);
      setPaidByCustomerAmount(floatValue);
      return;
    }
    if (floatValue <= outstandingAmount && floatValue > 0) {
      setPaidByCustomerAmountString(newValue);
      setPaidByCustomerAmount(floatValue);
      setIsPartialPayProceedButtonDisabled(false);
    }
  };

  const closeCustomerSetup = () => {
    setShowCustomerSetupDialogForPartialPay(false);
  };

  useEffect(() => {
    checkAndValidateCustomerDetails();
  }, [customerName, customerMobileNumber]);

  const checkAndValidateCustomerDetails = () => {
    var isEnabled =
      !Utils.IsNullOrEmpty(customerName) &&
      Utils.IsMobileValid(customerMobileNumber);
    setIsCustomerSaveDisabled(!isEnabled);
  };

  const onClickClosePaymentLinkDetails = () => {
    setShowPaymentLinkDetails(false);
    setOpenPaymentSelector(true);
    setPaymentLink(false);
    setPaymentLinkAmount("");
    setPaymentLinkMobileNumber("");
  };

  const onClickOkPaymentLinkDetails = async () => {
    setShowPaymentLinkDetails(false);
    setPaymentLink(false);
    await processPayment();
  };

  const proceedSaveCustomer = async () => {
    setIsLoading(true);
    var result = await saveCustomer();
    setIsLoading(false);
    if (result === customerSaveType.Success) {
      setShowCustomerSetupDialogForPartialPay(false);
      setUpdateCustomerDetails(true);
    }
  };
  const onChangeSettlementAccount = (e) => {
    var selectedSettlementAccount = JSON.parse(e.target.value);
    setSelecteSettleAccountName(selectedSettlementAccount.accountAlias);
    setSettlementAccount(e.target.value);
  };

  useEffect(() => {
    checkPaymentLinkDetails();
  }, [paymentLinkAmount, settlementAccount, paymentLinkMobileNumber]);

  const checkPaymentLinkDetails = () => {
    var isSettleAccountEmptyObject =
      Utils.IsNullOrEmptyObject(settlementAccount);
    var isEnabled =
      paymentLinkAmount > 0 &&
      paymentLinkAmount <= totalPrice &&
      !isSettleAccountEmptyObject &&
      Utils.IsMobileValid(paymentLinkMobileNumber);
    if (isEnabled) {
      var acc = JSON.parse(settlementAccount);
      isEnabled = isEnabled && !Utils.IsNullOrEmptyObject(acc);
    }
    setPaymentLinkDisabled(!isEnabled);
  };
  const onChangeCompValue = (newValue) => {
    setCompValue(newValue);
    setComplimentaryValue(newValue);
  };
  const CheckForConflictingPlans = () => {
    if (
      !Utils.IsNullOrEmpty(currentOngoingCustomerAMCPlan) &&
      !Utils.IsNullOrEmpty(serviceItems) &&
      serviceItems.some((item) => item.ServiceItemType === "Plan")
    ) {
      setIsConflictingPlansFound(true);
      setIsCheckAMCPlanPopup(true);
    } else {
      setIsActiveNowAMCPlan(true);
      beginBook();
    }
  };

  const CheckAndOpenInvoiceDateToPrevious = () => {
    setInvoiceTime(moment());
    let result = checkStylistSelected();
    if (!result) {
      setAlert("Please assign stylists for all services");
      return
    }

    if (branch.invoiceToPreviousDate === true) {
      setShowInvocieDTConfirmation(true);
    } else {
      CheckForConflictingPlans();
    }
  };
  const addToQueueAMCPlan = () => {
    setIsActiveNowAMCPlan(false);
    setIsCheckAMCPlanPopup(false);
    if (!Utils.IsNullOrEmpty(currentOngoingCustomerAMCPlan)) {
      var date = moment(currentOngoingCustomerAMCPlan.endDT).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      var datestring = Utils.GetISODateTimeString(date);
      var planstart = moment(datestring).add(1, "days");
      setPlanStartDTAMC(Utils.GetISODateTimeString(planstart));
    }
    beginBook();
  };
  const activateNowAMCPlan = () => {
    setIsActiveNowAMCPlan(true);
    setIsCheckAMCPlanPopup(false);
    beginBook();
  };

  const invoiceDTConfirmationNo = () => {
    setShowInvocieDTConfirmation(false);
  };

  const invoiceDTConfirmationYes = () => {
    var invoiceDateVal = moment(invoiceDT);
    var invoiceTimeVal = moment(invoiceTime);
    if (invoiceDateVal._isValid == false || invoiceTimeVal._isValid == false) {
      setAlert('Please enter valid date and time.')
    } else {
      setShowInvocieDTConfirmation(false);
      CheckForConflictingPlans();
    }
  };

  const onChangeCompType = (compTypeValue) => {
    setCompType(compTypeValue);
    setComplimentaryValue("");
    setCompValue(0);
    if (existItemComp.orderLevel) {
      var _discount = Utils.ConvertToFloat(
        serviceItems.map((c) => c.DiscountTotal).reduce((a, b) => a + b)
      );
      var _totalPrice = Utils.ConvertToFloat(
        serviceItems.map((s) => s.TotalPrice).reduce((a, b) => a + b)
      );
      setMaxPrice(compTypeValue === "Discount" ? 100 : _totalPrice - _discount);
    } else {
      var i = serviceItems.findIndex((s) => s.Sequence === existItemComp.index);
      setMaxPrice(
        compTypeValue === "Discount"
          ? 100
          : serviceItems[i].TotalPrice - serviceItems[i].DiscountTotal
      );
    }
  };

  const handleSelectPersonChange = (e, i, j) => {
    const value = e.target.value;
    const selectedStaff = serviceStaffs.find(item => item.Name === value);


    if (!Utils.IsNullOrEmptyObject(selectedStaff)) {
      setServiceItems((prev) => {
        const newServiceItems = [...prev];
        if (newServiceItems[i] && newServiceItems[i].ServiceItemStaffs[j]) {
          newServiceItems[i] = {
            ...newServiceItems[i],
            ServiceItemStaffs: newServiceItems[i].ServiceItemStaffs.map((staff, index) =>
              index === j ? { ...staff, Name: selectedStaff.Name, Id: selectedStaff.Id } : staff
            )
          };
        }
        else if (newServiceItems[i] && !newServiceItems[i].ServiceItemStaffs[j]) {
          newServiceItems[i] = {
            ...newServiceItems[i],
            ServiceItemStaffs: [
              ...newServiceItems[i].ServiceItemStaffs,
              { Name: selectedStaff.Name, Id: selectedStaff.Id },
            ],
          }
        }
        return newServiceItems;
      });
    }
  }

  const handlePackageSelectPersonChange = (e,i,serviceIndex,j) => {
    const value = e.target.value;
    const selectedStaff = serviceStaffs.find(item => item.Name === value);

    if (!Utils.IsNullOrEmptyObject(selectedStaff)) {
      setServiceItems((prev) => {
        const newServiceItems = [...prev];
      
        if (!newServiceItems[i]) return prev; // Ensure `i` exists
      
        const packageProductDetails = [...newServiceItems[i].PackageProductDetails]; // Copy PackageProductDetails
        const serviceItem = { ...packageProductDetails[serviceIndex] }; // Copy the specific serviceItem
      
        if (!serviceItem.ServiceItemStaffs) {
          serviceItem.ServiceItemStaffs = [];
        }
      
        if (serviceItem.ServiceItemStaffs[j]) {
          // Update existing staff
          serviceItem.ServiceItemStaffs = serviceItem.ServiceItemStaffs.map((staff, index) =>
            index === j ? { ...staff, Name: selectedStaff.Name, Id: selectedStaff.Id } : staff
          );
        } else {
          // Add new staff
          serviceItem.ServiceItemStaffs = [
            ...serviceItem.ServiceItemStaffs,
            { Name: selectedStaff.Name, Id: selectedStaff.Id },
          ];
        }
      
        // Update the service item in packageProductDetails
        packageProductDetails[serviceIndex] = serviceItem;
      
        // Update the main item
        newServiceItems[i] = { 
          ...newServiceItems[i], 
          PackageProductDetails: packageProductDetails 
        };
        return newServiceItems;
      });
      
    }

  }
  const checkStylistSelected = () => {
    let isFlag = true;
    let isAllowStylists = branch.allowStylists
    if (BusinessHelper.IsSalon(business) && isAllowStylists) {
      serviceItems.forEach((serviceItem) => {
        if (serviceItem.Service) {
          if (Utils.IsNullOrEmptyArray(serviceItem.ServiceItemStaffs)) {
            isFlag = false;
          } else if (serviceItem.ServiceItemStaffs.length !== serviceItem.Quantity) {
            isFlag = false;
          } else {
            serviceItem.ServiceItemStaffs.map((staff) => {
              if (!staff.Name) {
                isFlag = false;
              }
            })
          }
        }
      })
    }
    return isFlag
  }

  // const handleAddMixedAmount = () => {
  //   setAllMixedPaymentInputs((prev) => [...prev, {
  //     amount: "",
  //     paymentMethod: "",
  //     nameOnCard: "",
  //     cardLast4: "",
  //     paymentLinkMobileNumber: "",
  //     paymentLinkAmount: "",
  //     settlementAccount: "",
  //   }])
  // }

  const handleAddMixedAmount = () => {
    setAllMixedPaymentInputs((prev) => {
      const totalEntered = prev.reduce((sum, item) => sum + (parseFloat(item.amount) || 0), 0);
      const remainingBalance = amountToPaid - totalEntered;

      if (remainingBalance <= 0) return prev;

      const newAmount = remainingBalance.toFixed(2);

      return [
        ...prev,
        {
          amount: newAmount,
          paymentMethod: "",
          nameOnCard: "",
          cardLast4: "",
          paymentLinkMobileNumber: "",
          paymentLinkAmount: "",
          settlementAccount: "",
        },
      ];
    });
  };




  const handleOpenMixedPayment = (isbusinesscountryIn = null, isbusinesscountryus = null) => {
    setOpenPaymentTypeSelector(false);
    setOpenPayments(true);
    var amount = amountToPaid === 0 ? "" : amountToPaid
    let obj = {
      amount: amount,
      paymentMethod: "",
      nameOnCard: "",
      cardLast4: ""
    };
    setAllMixedPaymentInputs([obj]);

    let paymentOptions = ["Cash Pay"]
    if (isBusinessCountryIndia || isbusinesscountryIn) {
      paymentOptions = [...paymentOptions, "Net Banking Pay", "UPI Pay", "Card Pay", "Payment Link"]
    } else if (!isBusinessCountryUS || !isbusinesscountryus) {
      paymentOptions = [...paymentOptions, "Card Pay"]
    }
    setPaymentModeOptions(paymentOptions);
  }

  const handleSubmitMixedPayment = () => {
    let value = 0;
    let isAllValuesFilled = true;
    allMixedPaymentInputs.map((item) => {
      value += Number(item.amount);
      if (!item.paymentMethod || !item.amount) {
        isAllValuesFilled = false;
      }
      if (item.paymentMethod === "Payment Link") {
        if (!item.paymentLinkMobileNumber || !item.settlementAccount) {
          isAllValuesFilled = false;
        }
      }
      if (item.paymentMethod === "Card Pay") {
        if (!isBusinessCountryUAE && (!item?.nameOnCard || !item?.cardLast4)) {
          isAllValuesFilled = false;
        }
      }
    })
    if (!isAllValuesFilled) {
      setAlert("Please fill all details to proceed");
      return
    }
    if (value > amountToPaid) {
      setAlert(`Payments cannot exceed ${amountToPaid}`);
      return
    }
    if (!(branch.allowMultiplePayments) && value !== amountToPaid) {
      setAlert("Please make full payment")
      return
    }

    if (value === 0) {
      setAlert("Please add amount to pay");
      return
    };
    setOpenPayments(false);
    if (allMixedPaymentInputs.length > 1) {
      setSelectedPaymentMode("MixedPayment");
      setMixedPaymentModeSelected(true);
      setCash(true);
      if (value !== amountToPaid || processingPartialPay) {
        setIsPartialPaySelected(true);
        const totalMixedPaymentAmount = allMixedPaymentInputs.reduce((sum, item) => {
          return sum + (parseFloat(item.amount) || 0);
        }, 0);
        setPaidByCustomerAmount(totalMixedPaymentAmount);
        if (value !== amountToPaid && !pass) {
          setShowCustomerSetupDialogForPartialPay(true);
        } else {
          setUpdatedSinglePayments(true);
        }
      } else {
        setUpdatedSinglePayments(true);
      }
      return
    } else if (allMixedPaymentInputs.length === 1) {
      switch (allMixedPaymentInputs[0].paymentMethod) {
        case "Cash Pay":
          setCash(true);
          break;
        case "Net Banking Pay":
          setNetBanking(true);
          break;
        case "UPI Pay":
          setUPI(true);
          break;
        case "Card Pay":
          setCardPay(true);
          setNameOnCard(allMixedPaymentInputs[0].nameOnCard)
          setLastFourDigits(allMixedPaymentInputs[0].cardLast4)
          break;
        case "Payment Link":
          setPaymentLinkAmount(allMixedPaymentInputs[0].amount);
          setPaymentLinkMobileNumber(allMixedPaymentInputs[0].paymentLinkMobileNumber);
          setPaymentLinkSelected(allMixedPaymentInputs[0].settlementAccount);
          setPaymentLink(true);
          setSettlementAccount(allMixedPaymentInputs[0].settlementAccount)
      }
      if (value !== amountToPaid || processingPartialPay) {
        setIsPartialPaySelected(true);
        setPaidByCustomerAmount(Number(allMixedPaymentInputs[0].amount));
        if (value !== amountToPaid && !pass) {
          setShowCustomerSetupDialogForPartialPay(true);
        } else {
          setUpdatedSinglePayments(true);
        }
      } else {
        setUpdatedSinglePayments(true);
      }
      return
    }
    processPayment();

  }

  const checkValidPaymentAmount = (e, i) => {
    var newValue = e.target.value;
    const regex = /^\d*\.?\d{0,2}$/;
    if (!regex.test(newValue)) {
      return false;
    }
    var floatValue = Utils.ConvertToFloat(newValue);
    let checkvalue = amountToPaid - floatValue
    let value = 0;
    allMixedPaymentInputs.map((item, index) => {
      if (index !== i) {
        if (item.amount) {
          value += Number(item.amount)
        }
      }

    })
    value += floatValue;
    let newCheckvalue = amountToPaid - value;
    if (checkvalue < 0 || newCheckvalue < 0) {
      setExceededAmount(true);
      return false;
    }
    setExceededAmount(false);
    return true;
  }

  const hanldeUPIPayment = (paymentAmount) => {
    if (Utils.IsNullOrEmpty(paymentAmount) || paymentAmount === '0') {
      return;
    }
    var newUpiValue =
      "upi://pay?pa=" +
      branch.upi.upiId +
      "&pn=" +
      branch.upi.upiPayeeName +
      "&cu=INR&am=" +
      paymentAmount;
    if (!Utils.IsNullOrEmpty(newUpiValue)) {
      setOpenPaymentSelector(false);
      setUPIValue(newUpiValue);
      var amount = Utils.ConvertToFloat(paymentAmount);
      setUpiAmount(amount);
      setUPI(true);
      setShowUPIDetails(true);
    }
  }

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        {/* Loader */}
        <Loader open={isLoading} />

        {/*Partial pay amount entry dialog*/}
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          className="cs-modal-sec"
          onClose={() => closePartialPayAmountEntry()}
          open={showPartialPayEntryDialog}
        >
          <DialogContent>
            <Stack
              spacing={2}
              className="justify-content-between card mt-1 font-size-13"
              padding="5px"
            >
              <Stack
                direction="row"
                alignItems="baseline"
                justifyContent="space-between"
                spacing={2}
              >
                <label htmlFor="amount" style={{ float: "left" }}>
                  Amount
                </label>
                <TextField
                  id="amount"
                  variant="outlined"
                  type="number"
                  value={paidByCustomerAmountString}
                  style={{ float: "right" }}
                  onChange={(e) =>
                    validateAndSetPaymentAmountEnteredByCustomer(e)
                  }
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="baseline"
                justifyContent="space-between"
                spacing={2}
              >
                <Typography variant="subtitle1" style={{ float: "left" }}>
                  Amount to be Paid
                </Typography>
                <AmountTypography
                  value={outstandingAmount}
                  country={countryInfo}
                  variant="body1"
                  style={{ float: "right" }}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="baseline"
                justifyContent="space-between"
                spacing={2}
              >
                <Typography variant="subtitle1" style={{ float: "left" }}>
                  Balance
                </Typography>
                <AmountTypography
                  value={balanceAmount}
                  country={countryInfo}
                  variant="body1"
                  style={{ float: "right" }}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="baseline"
                justifyContent="space-between"
                spacing={2}
              >
                <Typography variant="subtitle1" style={{ float: "left" }}>
                  Already paid Amount
                </Typography>
                <AmountTypography
                  value={alreadyPaidAmount}
                  country={countryInfo}
                  variant="body1"
                  style={{ float: "right" }}
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              className="btn btn-primary white-text"
              variant="contained"
              color="primary"
              disabled={
                isBusinessCountryUAE === true && VisitsType === "Jobcard"
                  ? false
                  : isPartialPayProceedButtonDisabled
              }
              onClick={() => proceedPartialPayment()}
            >
              Pay
            </Button>
            <Button
              autoFocus
              className="btn btn-secondary"
              variant="contained"
              style={{ backgroundColor: "gray" }}
              // onClick={() => {setShowPartialPayEntryDialog(false);setPaidByCustomerAmountString("");setPaidByCustomerAmount(0)}}
              onClick={() => { closePartialPayAmountEntry(); setShowPartialPayEntryDialog(false); setPaidByCustomerAmountString(""); setPaidByCustomerAmount(0); setShowConfirmDialogForPartialPay(false) }}
            >
              Cancel
            </Button>
          </DialogActions>
        </BootstrapDialog>

        {/* Payment Mode Selector */}
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 300 } }}
          maxWidth="xs"
          open={openPaymentTypeSelector}
        >
          <DialogTitle
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <h4>Different Payment Modes</h4>
            <IconButton
              aria-label="close"
              onClick={() => setOpenPaymentTypeSelector(false)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers className="font-size-14">
            <Stack
              direction="column"
              spacing={2}
            >
              <Stack
                direction="row"
                spacing={2}
                className="card justify-content-between"
                padding="10px"
                onClick={() => {
                  confirmPartialPay(
                    anonymous === true
                      ? partialPayActions.PartialPayWithoutCustomer
                      : partialPayActions.PartialPay
                  );
                  setOpenPaymentTypeSelector(false);
                }}
              >
                <Stack direction="row">
                  <img alt="Cash Pay" width={"50px"} src={Cash_Icon} style={{ alignSelf: "Center" }} />
                  <Stack
                    direction="column"
                    spacing={1}
                    name="radio-buttons-group"
                    className="pl-1"
                  >
                    <Typography
                      level="h4"
                      component="h4"
                      fontWeight="600"
                      fontSize="1.5rem"
                      className="align-center"
                    >
                      Advance Payment
                    </Typography>
                  </Stack>
                </Stack>
                <NavigateNextIcon className="align-center" />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                className="card justify-content-between"
                padding="10px"
                onClick={handleOpenMixedPayment}
              >
                <Stack direction="row">
                  <img alt="Cash Pay" width={"50px"} src={Cash_Icon} style={{ alignSelf: "Center" }} />
                  <Stack
                    direction="column"
                    spacing={1}
                    name="radio-buttons-group"
                    className="pl-1"
                  >
                    <Typography
                      level="h4"
                      component="h4"
                      fontWeight="600"
                      fontSize="1.5rem"
                      className="align-center"
                    >
                      Mixed Payment
                    </Typography>
                  </Stack>
                </Stack>
                <NavigateNextIcon className="align-center" />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                className="card justify-content-between"
                padding="10px"
                onClick={() => { confirmPartialPay(partialPayActions.FullPay); setOpenPaymentTypeSelector(false); }}
              >
                <Stack direction="row">
                  <img alt="Cash Pay" width={"50px"} src={Cash_Icon} style={{ alignSelf: "Center" }} />
                  <Stack
                    direction="column"
                    spacing={1}
                    name="radio-buttons-group"
                    className="pl-1"
                  >
                    <Typography
                      level="h4"
                      component="h4"
                      fontWeight="600"
                      fontSize="1.5rem"
                      className="align-center"
                    >
                      Full Payment
                    </Typography>
                  </Stack>
                </Stack>
                <NavigateNextIcon className="align-center" />
              </Stack>
            </Stack>

          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              className="btn btn-secondary"
              variant="contained"
              style={{ backgroundColor: "gray" }}
              onClick={() => setOpenPaymentTypeSelector(false)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Mixed pay amount entry dialog */}
        <Dialog
          sx={{ "& .MuiDialog-paper": { maxHeight: 300 } }}
          maxWidth="xs"
          open={openPayments}
        >
          <DialogTitle
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <h4>Payments</h4>
            <IconButton
              aria-label="close"
              onClick={() => setOpenPayments(false)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText className="font-size-14 multi-payment">
              <Stack
                direction="row"
                alignItems="baseline"
                justifyContent="space-between"
                spacing={2}
                py={1}
                className="font-size-14"
              >
                <Typography variant="h5" style={{ float: "left" }}>
                  Amount to be Paid
                </Typography>
                <AmountTypography
                  value={amountToPaid}
                  country={countryInfo}
                  variant="h5"
                  style={{ float: "right" }}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="baseline"
                justifyContent="space-between"
                spacing={2}
                mb={2}
              >
                <Typography variant="h5" style={{ float: "left" }}>
                  Balance
                </Typography>
                <AmountTypography
                  value={balanceMixedAmount}
                  country={countryInfo}
                  variant="h5"
                  style={{ float: "right", color: exceededAmount ? "red" : "" }}
                />
              </Stack>
              {allMixedPaymentInputs && allMixedPaymentInputs.map((row, i) => (
                <Stack className="card" sx={{ margin: "4rem 0 1rem 0", padding: "7px 15px" }}>
                  <Stack
                    direction="row"
                    alignItems="baseline"
                    justifyContent="space-between"
                    spacing={2}
                    py={1}
                  >
                    <FormControl error={!row.paymentMethod} required fullWidth variant="outlined" margin="0">
                      <InputLabel id="Payment-Mode">Payment Mode</InputLabel>
                      <Select
                        inputProps={{ style: { fontSize: 13 } }}
                        labelId="Payment-Mode"
                        id="paymentMode-select"
                        value={row.paymentMethod || ""}
                        onChange={(e) => {
                          setAllMixedPaymentInputs((prev) => {
                            const newItems = [...prev];
                            newItems[i] = { ...newItems[i], paymentMethod: e.target.value };
                            return newItems
                          });
                          if (e.target.value === "Payment Link") {
                            setPaymentLinkMobileNumber(
                              Utils.IsNullOrEmptyObject(pass) ? "" : pass.mobileNumber
                            );
                          }
                        }
                        }
                        InputLabelProps={{ shrink: true }}
                        label="Payment Mode"
                      >
                        {paymentModeOptions.map((option) => (
                          <MenuItem value={option}
                            disabled={(option === "Payment Link" && allMixedPaymentInputs.some(item => item.paymentMethod === "Payment Link"))
                              || (option === "Cash Pay" && allMixedPaymentInputs.some(item => item.paymentMethod === "Cash Pay"))
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}

                      </Select>
                      {/* {!row.paymentMethod && (
                        <FormHelperText>payment method is required</FormHelperText>
                      )} */}
                    </FormControl>
                    <FormControl required fullWidth error={!row.amount} variant="outlined">
                      <TextField
                        label="Amount *"
                        error={!row.amount}
                        value={row.amount}
                        onChange={(e) => {
                          if (checkValidPaymentAmount(e, i)) {
                            setAllMixedPaymentInputs((prev) => {
                              const newItems = [...prev];
                              newItems[i] = { ...newItems[i], amount: e.target.value }
                              return newItems;
                            })
                            if (allMixedPaymentInputs[i].paymentMethod === "Cash Pay") {
                              setUpdatingCashPayValue(true);
                            }
                          }

                        }}
                      />
                      {/* {!row.amount && (
                        <FormHelperText>amount is required</FormHelperText>
                      )} */}
                    </FormControl>
                    {row.paymentMethod === "UPI Pay" && (
                      <IconButton
                        aria-label="open"
                        onClick={() => hanldeUPIPayment(row.amount)}
                      >
                        <QrCodeScannerIcon sx={{ fontSize: "24px !important" }} />
                      </IconButton>
                    )}
                    <IconButton
                      className="remove-btn"
                      aria-label="close"
                      onClick={() => setAllMixedPaymentInputs((prev) => [...prev].filter((item, index) => index !== i))}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Stack>

                  {row.paymentMethod === "Card Pay" && <Stack
                    direction="row"
                    alignItems="baseline"
                    justifyContent="space-between"
                    spacing={2}
                    py={1}
                  >
                    <FormControl required error={!row.nameOnCard} fullWidth>
                      <TextField
                        id="csProductName"
                        error={isBusinessCountryUAE ? false : !row.nameOnCard}
                        value={row.nameOnCard || ""}
                        label={isBusinessCountryUAE ? "Name on Card" : "Name on Card *"}
                        variant="outlined"
                        onChange={(e) => {
                          setAllMixedPaymentInputs((prev) => {
                            const newItems = [...prev];
                            newItems[i] = { ...newItems[i], nameOnCard: e.target.value };
                            return newItems
                          });
                        }}
                      />
                      {/* {!row.nameOnCard && (
                        <FormHelperText>name on card is required</FormHelperText>
                      )} */}
                    </FormControl>
                    <FormControl required error={!row.cardLast4} fullWidth className="mt-1" style={{ "width": "180px" }}>
                      <NumericTextField
                        value={row.cardLast4}
                        error={isBusinessCountryUAE ? false : !row.cardLast4}
                        setValue={(value) => {
                          setAllMixedPaymentInputs((prev) => {
                            const newItems = [...prev];
                            newItems[i] = { ...newItems[i], cardLast4: value };
                            return newItems
                          });

                        }}
                        id="csProductName"
                        label={
                          isBusinessCountryUAE
                            ? "Last 4 Digit"
                            : "Last 4 Digit *"
                        }
                        variant="outlined"
                        inputProps={{ maxLength: 4 }}
                      />
                      {/* {!row.cardLast4 && (
                        <FormHelperText>card last 4 digits is required </FormHelperText>
                      )} */}
                    </FormControl>
                  </Stack>}
                  {row.paymentMethod === "Payment Link" && <Stack
                    direction="row"
                    alignItems="baseline"
                    justifyContent="space-between"
                    spacing={2}
                    py={1}
                  >
                    <FormControl required error={!row.paymentLinkMobileNumber} fullWidth>
                      <NumericTextField
                        id="csProductName"
                        label="Mobile Number"
                        error={!row.paymentLinkMobileNumber}
                        variant="outlined"
                        inputProps={{ maxLength: 10 }}
                        value={row.paymentLinkMobileNumber || ""}
                        setValue={(value) => {
                          setAllMixedPaymentInputs((prev) => {
                            const newItems = [...prev];
                            newItems[i] = { ...newItems[i], paymentLinkMobileNumber: value };
                            return newItems
                          });
                        }}
                        required
                      />
                      {!row.paymentLinkMobileNumber && (
                        <FormHelperText>payment link mobile number is required </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl required error={!row.settlementAccount} fullWidth variant="outlined" margin="0">
                      <InputLabel id="Category-label">Settlement Account</InputLabel>
                      <Select
                        inputProps={{ style: { fontSize: 13 } }}
                        labelId="Category-label"
                        id="Category-select"
                        value={row.settlementAccount || ""}
                        onChange={(e) => {
                          setAllMixedPaymentInputs((prev) => {
                            const newItems = [...prev];
                            newItems[i] = { ...newItems[i], settlementAccount: e.target.value };
                            return newItems
                          });

                        }}
                        InputLabelProps={{ shrink: true }}
                        label="Settlement Account"
                        style={{ height: "51.5px" }}
                      >
                        {!Utils.IsNullOrEmptyArray(settlementAccounts) &&
                          settlementAccounts.map((item) => (
                            <MenuItem value={JSON.stringify(item)}>
                              {item.accountAlias}
                            </MenuItem>
                          ))}
                      </Select>
                      {!row.settlementAccount && (
                        <FormHelperText>settlement account is required</FormHelperText>
                      )}
                    </FormControl>
                  </Stack>}
                </Stack>
              ))}

              {allMixedPaymentInputs.length < 3 && <Stack py={1} direction="row" justifyContent="end">
                <Button disabled={balanceMixedAmount <= 0} onClick={handleAddMixedAmount} className="btn add-btn white-text" variant="contained" color="secondary">
                  <IconButton>
                    <AddIcon />
                  </IconButton>
                </Button>
              </Stack>}

              {allMixedPaymentInputs.some(payment => (payment.paymentMethod === "Cash Pay" && payment.amount)) &&
                <Stack className="card" sx={{ margin: "2rem 0 1rem 0", padding: "7px 15px" }}>
                  <Stack
                    direction="row"
                    alignItems="baseline"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <Typography variant="h5" style={{ float: "left" }}>
                      Recieved
                    </Typography>
                    <FormControl>
                      <NumericTextField
                        id="contactPhoneNumber"
                        label="Recieved amount"
                        type="number"
                        variant="outlined"
                        value={cashPayRecieved}
                        inputProps={{ maxLength: 8 }}
                        setValue={(value) => {
                          const regexdec = /^\d+\.$/;
                          if (regexdec.test(value)) {
                            setCashPayRecieved("");
                            setReturnToCustemer("");
                            return
                          }
                          if (value.length < 9) {
                            setCashPayRecieved(value);
                            updateReturnCustomerValue(value);
                          }
                        }}
                        placeholder="Enter recieved amount"
                      />
                    </FormControl>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="baseline"
                    justifyContent="space-between"
                    spacing={2}
                    mt={2}
                  >
                    <Typography variant="h5" style={{ float: "left" }}>
                      Return to customer
                    </Typography>
                    <FormControl>
                      <TextField
                        id="contactPhoneNumber"
                        label="Return to customer"
                        type="tel"
                        variant="outlined"
                        readOnly="true"
                        value={returnToCutomer}
                      />
                    </FormControl>
                  </Stack>
                </Stack>}

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              className="btn btn-primary white-text"
              variant="contained"
              color="primary"
              onClick={handleSubmitMixedPayment}
            >
              Submit
            </Button>
            <Button
              autoFocus
              className="btn btn-secondary"
              variant="contained"
              style={{ backgroundColor: "gray" }}
              onClick={() => {
                setOpenPayments(false);
                processingPartialPay && goBack(true);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>


        {/*Customer setup*/}
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          className="cs-modal-sec"
          open={showCustomerSetupDialogForPartialPay}
        >
          <DialogTitle
            display="flex"
            justifyContent="center"
            alignItems="baseline"
            className="dialog-title"
          >
            <Typography
              level="h2"
              component="h2"
              fontWeight="600"
              fontSize="1.6rem"
            >
              Enter details to proceed payment
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={3}
              className="font-size-13"
              style={{ marginTop: "0px" }}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  name="name"
                  value={customerName}
                  error={!customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  type="email"
                  name="email"
                  value={customerEmailId}
                  onChange={(e) => setCustomerEmailId(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <NumericTextField
                  fullWidth
                  label="Mobile Number"
                  variant="outlined"
                  name="mobileNumber"
                  inputProps={{
                    maxLength:
                      CountryHelper.GetMobileNumberMaxLength(countryInfo),
                  }}
                  error={!Utils.IsMobileValid(customerMobileNumber)}
                  value={customerMobileNumber}
                  setValue={setCustomerMobileNumber}
                />
              </Grid>
              {!Utils.IsNullOrEmpty(addressISOCode) && (
                <Grid item xs={12}>
                  <AddressField
                    id="csProductName"
                    variant="outlined"
                    label="Address"
                    countryISoCode={addressISOCode}
                    value={addressDetails_Address}
                    setValue={setAddresDetails_Address}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              className="btn btn-primary white-text"
              variant="contained"
              color="primary"
              disabled={isCustomerSaveDisabled}
              onClick={() => proceedSaveCustomer()}
            >
              Save
            </Button>
            <Button
              autoFocus
              className="btn btn-secondary"
              variant="contained"
              style={{ backgroundColor: "gray" }}
              onClick={() => closeCustomerSetup()}
            >
              Close
            </Button>
          </DialogActions>
        </BootstrapDialog>

        {/* Partial Pay Dialog */}
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "50%", maxHeight: 300 } }}
          maxWidth="xs"
          open={showConfirmDialogForPartialPay}
        >
          <DialogTitle
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <h4>Partial Payment</h4>
            <IconButton
              aria-label="close"
              onClick={() => confirmPartialPay(partialPayActions.Close)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              <Stack direction="column" alignItems="center">
                <img alt="Split Pay" width={"50px"} src={Split_Icon} />
                <Typography variant="h5" className="py-2">
                  Do you want to make a partial payment?
                </Typography>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              className="btn btn-primary white-text"
              variant="contained"
              color="primary"
              onClick={() =>
                confirmPartialPay(
                  anonymous === true
                    ? partialPayActions.PartialPayWithoutCustomer
                    : partialPayActions.PartialPay
                )
              }
            >
              Yes
            </Button>
            <Button
              autoFocus
              className="btn btn-secondary"
              variant="contained"
              style={{ backgroundColor: "gray" }}
              onClick={() => confirmPartialPay(partialPayActions.FullPay)}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        {/* back date popup */}

        <Modal open={showInvocieDTConfirmation}>
          <Box className="cs-modal" style={{ width: "auto" }} padding={2}>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <Box style={{ overflow: "auto", maxHeight: "500px" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="h6">Invoice Date</Typography>
                    <FormControl className="font-size-14">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          value={invoiceDT}
                          onChange={(newValue) => setInvoiceDT(newValue)}
                          closeOnSelect={true}
                          minDate={moment().subtract(1, "year")}
                          maxDate={moment()}
                          inputProps={{ style: { fontSize: 20 } }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems="center" mt={2}>
                    <Typography variant="h6">Invoice Time</Typography>
                    <FormControl className="font-size-14">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <TimePicker
                          value={invoiceTime}
                          onChange={(newValue) => setInvoiceTime(newValue)}
                          inputProps={{ style: { fontSize: 20 } }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Stack>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    className="btn btn-secondary"
                    variant="contained"
                    style={{ backgroundColor: "red", color: "white" }}
                    onClick={() => invoiceDTConfirmationNo()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary"
                    variant="contained"
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      marginLeft: "1rem",
                    }}
                    onClick={() => invoiceDTConfirmationYes()}
                  >
                    Continue
                  </Button>
                </Box>
              </FormControl>
            </Stack>
          </Box>
        </Modal>

        {/* Alert */}
        <MessagePopup
          msgOpen={showAlert}
          msgText={alertMessage}
          msgTitle={alertTitle}
          onMsgClose={handleAlertClose}
        />

        {/* Confirm */}
        <ConfirmDialog
          open={showCancelConfirmation}
          onClose={confirmCancel}
          message="Do you wish to cancel?"
          yesText="Yes"
          noText="No"
        />

        {/* Shipping Address */}
        <BootstrapDialog
          onClose={closeAddressSelector}
          aria-labelledby="customized-dialog-title"
          className="cs-modal-sec"
          open={openAddressSelector}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={closeAddressSelector}
          >
            <Typography
              level="h3"
              component="h3"
              fontWeight="600"
              fontSize="2rem"
              className="h3"
            >
              Shipping Address
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent dividers className="font-size-16">
            <Stack
              spacing={2}
              className="justify-content-between card mt-1"
              padding="10px"
            >
              <Typography
                level="h3"
                component="h3"
                fontWeight="600"
                fontSize="1.5rem"
                className="h3"
              >
                From:
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={shippingFromDefaultAddress}
                  control={
                    <Radio
                      name="shippingFromAddress"
                      checked={shippingFromAddressDefaultEnabled}
                    />
                  }
                  label={shippingFromDefaultAddress}
                  onClick={toggleFromAddress}
                />
                <FormControlLabel
                  value={0}
                  control={
                    <Radio
                      name="shippingFromAddress"
                      checked={!shippingFromAddressDefaultEnabled}
                    />
                  }
                  label="Use different address"
                  onClick={toggleDifferentFromAddress}
                />
              </RadioGroup>
              {isOpenedDifferentFromAddress && (
                <Grid spacing={2} className="fontSize12">
                  <FormControl fullWidth>
                    {/* <TextField
                      type="text"
                      id="fromDiffAddress"
                      label="Address"
                      variant="outlined"
                      required
                      InputLabelProps={{ shrink: true }}
                      value={shippingFromNewAddress}
                      onChange={(e) => setShippingFromNewAddress(e.target.value)}
                    /> */}
                    {!Utils.IsNullOrEmpty(addressISOCode) && (
                      <AddressField
                        id="fromDiffAddress"
                        variant="outlined"
                        label="Address"
                        required
                        countryISoCode={addressISOCode}
                        value={shippingFromNewAddress}
                        setValue={setShippingFromNewAddress}
                      />
                    )}
                  </FormControl>
                  <FormControl fullWidth className="mt-1">
                    <TextField
                      type="text"
                      id="fromDiffStatecode"
                      label="GSTN State Code"
                      variant="outlined"
                      required
                      InputLabelProps={{ shrink: true }}
                      value={shippingFromStateCode}
                      onChange={(e) => setShippingFromStateCode(e.target.value)}
                    />
                  </FormControl>
                  <FormControl fullWidth className="mt-1">
                    <TextField
                      type="text"
                      id="fromDiffPhone"
                      label="Phone"
                      variant="outlined"
                      required
                      InputLabelProps={{ shrink: true }}
                      value={shippingFromPhoneNumber}
                      onChange={(e) => setShippingFromPhoneNumber(e.target.value)}
                    />
                  </FormControl>
                  <FormControl fullWidth className="mt-1">
                    <TextField
                      type="text"
                      id="fromDiffEmail"
                      label="Email"
                      variant="outlined"
                      required
                      InputLabelProps={{ shrink: true }}
                      value={shippingFromEmailId}
                      onChange={(e) => setShippingFromEmailId(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              )}
            </Stack>
            <Stack
              spacing={2}
              className="justify-content-between card mt-1"
              padding="10px"
            >
              <Typography
                level="h3"
                component="h3"
                fontWeight="600"
                fontSize="1.5rem"
                className="h3"
              >
                To:
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={shippingToDefaultAddress}
                  control={
                    <Radio
                      name="shippingToAddress"
                      checked={shippingToAddressDefaultEnabled}
                    />
                  }
                  label={shippingToDefaultAddress}
                  onClick={toggleToAddress}
                />
                <FormControlLabel
                  value={0}
                  control={
                    <Radio
                      name="shippingToAddress"
                      checked={!shippingToAddressDefaultEnabled}
                    />
                  }
                  label="Use different address"
                  onClick={toggleDifferentToAddress}
                />
              </RadioGroup>
              {isOpenedDifferentToAddress && (
                <Grid spacing={2} className="fontSize12">
                  {!Utils.IsNullOrEmpty(addressISOCode) && (
                    <FormControl fullWidth>
                      <AddressField
                        id="toDiffAddress"
                        variant="outlined"
                        label="Address"
                        required
                        countryISoCode={addressISOCode}
                        value={shippingToNewAddress}
                        setValue={setShippingToNewAddress}
                      />
                    </FormControl>
                  )}
                  <FormControl fullWidth className="mt-1">
                    <TextField
                      type="text"
                      id="toDiffStatecode"
                      label="GSTN State Code"
                      variant="outlined"
                      required
                      InputLabelProps={{ shrink: true }}
                      value={shippingToStateCode}
                      onChange={(e) => setShippingToStateCode(e.target.value)}
                    />
                  </FormControl>
                  <FormControl fullWidth className="mt-1">
                    <TextField
                      type="text"
                      id="toDiffPhone"
                      label="Phone"
                      variant="outlined"
                      required
                      InputLabelProps={{ shrink: true }}
                      value={shippingToPhoneNumber}
                      onChange={(e) => setShippingToPhoneNumber(e.target.value)}
                    />
                  </FormControl>
                  <FormControl fullWidth className="mt-1">
                    <TextField
                      type="text"
                      id="toDiffEmail"
                      label="Email"
                      variant="outlined"
                      required
                      InputLabelProps={{ shrink: true }}
                      value={shippingToEmailId}
                      onChange={(e) => setShippingToEmailId(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              )}
            </Stack>
            <Stack
              spacing={2}
              className="justify-content-between card mt-1"
              padding="10px"
            >
              <Typography
                level="h4"
                component="h4"
                fontWeight="600"
                fontSize="1rem"
              >
                Reverse charge applicable
              </Typography>
              <Stack direction="row" spacing={1} name="radio-buttons-group">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="Yes"
                    control={
                      <Radio
                        name="reverseCharge"
                        checked={reverseChargeApplicable}
                      />
                    }
                    label="Yes"
                    onChange={(e) => setReverseChargeApplicable(true)}
                  />
                  <FormControlLabel
                    value="To"
                    control={
                      <Radio
                        name="reverseCharge"
                        checked={!reverseChargeApplicable}
                      />
                    }
                    label="No"
                    onChange={(e) => setReverseChargeApplicable(false)}
                  />
                </RadioGroup>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              padding="10px 0 0px 0"
              justifyContent="flex-end"
            >
              <FormControl>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  variant="contained"
                  onClick={clickOpenPaymentType}
                >
                  Proceed
                </Button>
              </FormControl>
            </Stack>
          </DialogContent>
        </BootstrapDialog>

        {/*Payment Type New */}
        <PaymentTypeDialog
          open={openPaymentSelector}
          close={() => closePaymentType()}
          isIndia={isBusinessCountryIndia}
          isUS={isBusinessCountryUS}
          cash={() => closePaymentType(PaymentTypes.Cash)}
          netbanking={() => closePaymentType(PaymentTypes.NetBanking)}
          card={() => closePaymentType(PaymentTypes.CardPay)}
          paymentLink={() => closePaymentType(PaymentTypes.PaymentLink)}
          upi={() => closePaymentType(PaymentTypes.UPI)}
          paymentLinkEnabled={
            isBusinessCountryIndia && CanRaisePaymentLink(business)
          }
        />

        {/* Card Component */}
        <CardComponent
          open={showCardDetails}
          close={onClickCloseCardDetails}
          isBusinessCountryUAE={isBusinessCountryUAE}
          cardDetailsDisabled={cardDetailsDisabled}
          nameOnCard={nameOnCard}
          setNameOnCard={setNameOnCard}
          lastFourDigits={lastFourDigits}
          setLastFourDigits={setLastFourDigits}
          ok={onClickOkCardDetails}
        />

        {/* On Choose Payment Link */}
        <BootstrapDialog
          onClose={onClickClosePaymentLinkDetails}
          area-labelledby="customized-dialog-title"
          open={showPaymentLinkDetails}
        >
          <BootstrapDialogTitle onClose={onClickClosePaymentLinkDetails}>
            <Typography
              level="h3"
              component="h3"
              fontWeight="600"
              fontSize="2rem"
              className="h3"
            >
              Payment Link Details
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent deviders className="font-size-14">
            <Stack
              direction="column"
              spacing={2}
              className="card justify-content-between mt-1"
              padding="10px"
              justifyContent="flex-end"
            >
              <FormControl fullWidth>
                <NumericTextField
                  id="csProductName"
                  label="Payment Amount"
                  variant="outlined"
                  disabled
                  value={paymentLinkAmount}
                  setValue={setPaymentLinkAmount}
                  required
                />
              </FormControl>
              <FormControl fullWidth>
                <NumericTextField
                  id="csProductName"
                  label="Mobile Number"
                  variant="outlined"
                  inputProps={{ maxLength: 10 }}
                  value={paymentLinkMobileNumber}
                  setValue={setPaymentLinkMobileNumber}
                  required
                />
              </FormControl>
              <FormControl fullWidth variant="outlined" margin="0">
                <InputLabel id="Category-label">Settlement Account</InputLabel>
                <Select
                  inputProps={{ style: { fontSize: 13 } }}
                  labelId="Category-label"
                  id="Category-select"
                  value={settlementAccount || ""}
                  onChange={(e) => onChangeSettlementAccount(e)}
                  InputLabelProps={{ shrink: true }}
                  label="Settlement Account"
                  style={{ height: "51.5px" }}
                >
                  {!Utils.IsNullOrEmptyArray(settlementAccounts) &&
                    settlementAccounts.map((item) => (
                      <MenuItem value={JSON.stringify(item)}>
                        {item.accountAlias}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              padding="50px 0 0px 0"
              justifyContent="end"
            >
              <FormControl>
                <Button
                  className="btn btn-secondary"
                  variant="outlined"
                  onClick={() => onClickClosePaymentLinkDetails()}
                >
                  Cancel
                </Button>
              </FormControl>
              <FormControl>
                <Button
                  className="btn btn-primary"
                  type="submit"
                  variant="contained"
                  disabled={paymentLinkDisabled}
                  onClick={() => onClickOkPaymentLinkDetails()}
                >
                  Ok
                </Button>
              </FormControl>
            </Stack>
          </DialogContent>
        </BootstrapDialog>

        {/*Upi component */}
        <UPIComponent
          open={showUPIDetails}
          close={onClickCloseUPIDetails}
          upiValue={upiValue}
          amount={upiAmount}
          country={countryInfo}
          ok={onClickOkUPIDetails}
        />

        {/* Add Tip Modal */}
        <BootstrapDialog
          onClose={clickCloseTip}
          aria-labelledby="customized-dialog-title"
          open={tipModal}
          className=""
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={clickCloseTip}
          >
            <Typography
              level="h3"
              component="h3"
              fontWeight="600"
              fontSize="2rem"
              className="h3"
            >
              Tip
            </Typography>
          </BootstrapDialogTitle>
          {BusinessHelper.IsSalon(business) && <DialogContent dividers>
            {!Utils.IsNullOrEmptyArray(uniqueStylistListName) && uniqueStylistListName.map((stylist, index) => (
              <Stack
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                direction="row"
                justifyContent="space-between"
                className="font-size-14"
                py={1}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Box
                    component="img"
                    alt="Assign"
                    src={Assign_Icon}
                    className="staff-icon"
                  />
                  <Typography
                    level="h5"
                    component="h5"
                    className="h5"
                  >
                    {stylist}
                  </Typography>
                </Stack>
                <FormControl>
                  <DecimalEntryField
                    label="Add Tip"
                    variant="outlined"
                    value={allSelectedStylistTip[stylist] || 0}
                    setValue={(e) => handleStylistTipValue(e, stylist)}
                  />
                </FormControl>
              </Stack>
            ))}
            {(uniqueStylistListName == 0 &&
              <span>No stylist chosen. Please select a stylist!</span>
            )}
            <FormControl class="modal-footer">
              <Button
                className="btn btn-secondary"
                variant="outlined"
                onClick={clickAddTip}
              >
                Add
              </Button>
            </FormControl>
          </DialogContent>}
          {BusinessHelper.IsAutomobile(business) && <DialogContent dividers>
            <Stack
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              direction="column"
              className="font-size-14"
            >
              <RadioGroup row style={{ "justify-content": "space-between" }}>
                <FormControlLabel
                  value="0"
                  control={<Radio name="tipAmount" />}
                  label="0%"
                  onChange={(e) => tipValue(e.target.value)}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio name="tipAmount" />}
                  label="5%"
                  onChange={(e) => tipValue(e.target.value)}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio name="tipAmount" />}
                  label="10%"
                  onChange={(e) => tipValue(e.target.value)}
                />
                <FormControlLabel
                  value="15"
                  control={<Radio name="tipAmount" />}
                  label="15%"
                  onChange={(e) => tipValue(e.target.value)}
                />
                <FormControlLabel
                  value="20"
                  control={<Radio name="tipAmount" />}
                  label="20%"
                  onChange={(e) => tipValue(e.target.value)}
                />
                <FormControlLabel
                  value="25"
                  control={<Radio name="tipAmount" />}
                  label="25%"
                  onChange={(e) => tipValue(e.target.value)}
                />
              </RadioGroup>
              <Typography
                level="p"
                component="p"
                fontWeight="600"
                fontSize="14px"
                className="h3 text-center"
                padding="10px"
              >
                or
              </Typography>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  label="Add Tip"
                  variant="outlined"
                  value={tipAmount}
                  onChange={(e) => tipInputValue(e.target.value)}
                />
              </FormControl>
              <FormControl class="modal-footer">
                <Button
                  className="btn btn-secondary"
                  variant="outlined"
                  onClick={clickAddTip}
                >
                  Add
                </Button>
              </FormControl>
            </Stack>
          </DialogContent>}
        </BootstrapDialog>

        {/* Add Complimentary Modal */}
        <BootstrapDialog
          onClose={clickCloseComplimentary}
          aria-labelledby="customized-dialog-title"
          open={ComplimentaryModal}
          className=""
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={clickCloseComplimentary}
          >
            <Typography
              level="h3"
              component="h3"
              fontWeight="600"
              fontSize="2rem"
              className="h3"
            >
              Complimentary
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent dividers className="cs-form font-size-14">
            <Stack direction="row" spacing={2}>
              <RadioGroup
                style={{ width: "100%" }}
                value={compType}
                onChange={(e) => {
                  onChangeCompType(e.target.value);
                }}
              >
                <FormControlLabel
                  value="Discount"
                  control={<Radio name="complimentaryAmount" />}
                  label="Discount %"
                />
                <FormControlLabel
                  value="Cash"
                  control={<Radio name="complimentaryAmount" />}
                  label="Cash"
                />
              </RadioGroup>
              <FormControl fullWidth>
                <CompValueEntryField
                  variant="outlined"
                  maxLimit={maxPrice}
                  compType={compType}
                  value={ComplimentaryValue}
                  setValue={onChangeCompValue}
                />
              </FormControl>
              <FormControl fullWidth>
                <Button
                  className="btn btn-primary"
                  variant="contained"
                  style={{ height: "54px" }}
                  disabled={totalPrice === 0 ? true : false}
                  onClick={() => AddCommentSharp(-1)}
                >
                  Add
                </Button>
              </FormControl>
            </Stack>
            <Divider className="my-1" />
            {!Utils.IsNullOrEmptyObject(existItemComp) &&
              existItemComp.orderLevel &&
              orderComplimentaryList.map((row, index) => (
                <Grid
                  container
                  xs={12}
                  spacing={2}
                  className="justify-content-between m-0 mt-1"
                  padding="5px 10px"
                >
                  <Grid xs={1} className="remove-icon">
                    <RemoveCircleOutlineIcon
                      onClick={() => removeCompItem(index)}
                    />
                  </Grid>
                  <Grid xs={3}>
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      {row.type === "PercentDiscount"
                        ? row.value + "%"
                        : Utils.GetCurrency(row.value, countryInfo)}
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      {row.type === "PercentDiscount" ? "Percentage" : "Cash"}
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3 text-right"
                    >
                      {Utils.GetCurrency(row.discount, countryInfo)}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            {!Utils.IsNullOrEmptyObject(existItemComp) &&
              !Utils.IsNullOrEmptyArray(serviceItems) &&
              !existItemComp.orderLevel &&
              !Utils.IsNullOrEmptyArray(
                itemComplimentaryList.filter(
                  (v) =>
                    !Utils.IsNullOrEmptyObject(
                      serviceItems.find((s) => s.Sequence === existItemComp.index)
                    ) &&
                    v.index ===
                    serviceItems.find((s) => s.Sequence === existItemComp.index)
                      .Sequence
                )
              ) &&
              itemComplimentaryList
                .filter(
                  (v) =>
                    v.index ===
                    serviceItems.find((s) => s.Sequence === existItemComp.index)
                      .Sequence
                )
                .map((row, index) => (
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    className="justify-content-between m-0 mt-1"
                    padding="5px 10px"
                  >
                    <Grid xs={1} className="remove-icon">
                      <RemoveCircleOutlineIcon
                        onClick={() => removeCompItem(index)}
                      />
                    </Grid>
                    <Grid xs={3}>
                      <Typography
                        level="h3"
                        component="h3"
                        fontWeight="600"
                        fontSize="2rem"
                        className="h3"
                      >
                        {row.type === "PercentDiscount"
                          ? row.value + "%"
                          : Utils.GetCurrency(row.value, countryInfo)}
                      </Typography>
                    </Grid>
                    <Grid xs={4}>
                      <Typography
                        level="h3"
                        component="h3"
                        fontWeight="600"
                        fontSize="2rem"
                        className="h3"
                      >
                        {row.type === "PercentDiscount" ? "Percentage" : "Cash"}
                      </Typography>
                    </Grid>
                    <Grid xs={4}>
                      <Typography
                        level="h3"
                        component="h3"
                        fontWeight="600"
                        fontSize="2rem"
                        className="h3 text-right"
                      >
                        {Utils.GetCurrency(row.discount, countryInfo)}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
          </DialogContent>
        </BootstrapDialog>

        {/* Receipt Options */}
        <BootstrapDialog
          onClose={onClickOkReceiptOptions}
          aria-labelledby="customized-dialog-title"
          className="cs-modal-sec"
          open={showReceiptOptions}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={onClickOkReceiptOptions}
          >
            <Typography
              level="h3"
              component="h3"
              fontWeight="600"
              fontSize="2rem"
              className="h3"
            >
              Receipt Options
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent dividers className="font-size-14">
            <Stack
              direction="row"
              spacing={2}
              className="card justify-content-between"
              padding="10px"
              onClick={clickOpenPaymentTypeCash}
            >
              <FormControlLabel
                control={<Switch checked={sendInvoiceEnable} />}
                label="Send Invoice"
                value="Yes"
                onChange={(e) => setSendInvoiceEnable(!sendInvoiceEnable)}
              />
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              className="justify-content-between mt-1"
            >
              <FormControl fullWidth>
                <TextField
                  id="csProductName"
                  label="Name"
                  variant="outlined"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                />
              </FormControl>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              className="justify-content-between mt-1"
            >
              <FormControl fullWidth>
                <TextField
                  id="csProductName"
                  label="Email"
                  value={customerEmailId}
                  variant="outlined"
                  onChange={(e) => setCustomerEmailId(e.target.value)}
                />
              </FormControl>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              className="justify-content-between mt-1"
            >
              <FormControl fullWidth>
                <NumericTextField
                  id="csProductName"
                  label="Mobile Number"
                  inputProps={{
                    maxLength:
                      CountryHelper.GetMobileNumberMaxLength(countryInfo),
                  }}
                  value={customerMobileNumber}
                  variant="outlined"
                  setValue={setCustomerMobileNumber}
                />
              </FormControl>
            </Stack>
            {anonymous && (
              <Stack
                direction="column"
                spacing={2}
                className="justify-content-between mt-1"
              >
                {!Utils.IsNullOrEmpty(addressISOCode) && (
                  <FormControl fullWidth className="mt-1">
                    <AddressField
                      id="csProductName"
                      variant="outlined"
                      label="Address"
                      required
                      countryISoCode={addressISOCode}
                      value={addressDetails_Address}
                      setValue={setAddresDetails_Address}
                    />
                  </FormControl>
                )}
                {/* <FormControl fullWidth className="mt-1">
                  <TextField
                    id="csProductName"
                    label="City"
                    value={addressDetails_City}
                    variant="outlined"
                    onChange={(e) => setAddresDetails_City(e.target.value)}
                  />
                </FormControl>
                <Stack
                  direction="row"
                  spacing={2}
                  className="justify-content-between mt-1"
                >
                  <FormControl fullWidth className="mt-1">
                    <TextField
                      id="csProductName"
                      label={isBusinessCountryUAE ? "Emirate" : "State"}
                      value={addressDetails_State}
                      variant="outlined"
                      onChange={(e) => setAddresDetails_State(e.target.value)}
                    />
                  </FormControl>
                  <FormControl fullWidth className="mt-1">
                    <TextField
                      id="csProductName"
                      label="Pincode"
                      value={addressDetails_Pincode}
                      variant="outlined"
                      onChange={(e) => setAddressDetails_Pincode(e.target.value)}
                    />
                  </FormControl>
                </Stack> */}
              </Stack>
            )}

            {isAutomobileWithoutVehicle && (
              <div>
                <Typography
                  level="h3"
                  component="h3"
                  fontWeight="600"
                  fontSize="1.5rem"
                  className="h3 mt-3"
                >
                  Vehicle Details
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  className="justify-content-between mt-1"
                >
                  <FormControl fullWidth className="mt-1">
                    <TextField
                      id="csProductName"
                      label="Vehicle Number"
                      variant="outlined"
                      onChange={(e) => setVehicleNumber(e.target.value)}
                    />
                  </FormControl>
                  <FormControl fullWidth className="mt-1">
                    <InputLabel id="demo-simple-select-label">
                      Fuel Type
                    </InputLabel>
                    <Select
                      id="jobcardFuelType"
                      value={vehicleFuelType}
                      label="Fuel Type"
                      variant="outlined"
                      onChange={fuelHandleChange}
                    >
                      <MenuItem value="Petrol">Petrol</MenuItem>
                      <MenuItem value="Diesel">Diesel</MenuItem>
                      <MenuItem value="CNG">CNG</MenuItem>
                      <MenuItem value="LPG">LPG</MenuItem>
                      <MenuItem value="Electric">Electric</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  className="mt-2"
                  style={{ width: "100%" }}
                >
                  <FormControl onClick={() => setCustomMade(!customMade)}>
                    <FormControlLabel
                      control={<Switch />}
                      label="Custom Select"
                    />
                  </FormControl>
                  {customMade ? (
                    <Stack direction="row" style={{ width: "70%" }}>
                      <FormControl fullWidth>
                        <TextField
                          id="vehicleMakeSelect"
                          label="Make"
                          variant="outlined"
                          value={vehicleCustomMake}
                          onChange={(e) => setVehicleCustomMake(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="vehicleModelSelect"
                          label="Model"
                          variant="outlined"
                          value={vehicleCustomModel}
                          onChange={(e) => setVehicleCustomModel(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Stack>
                  ) : (
                    <Stack direction="row" style={{ width: "70%" }}>
                      <FormControl fullWidth style={{ "margin-right": "15px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Make
                        </InputLabel>
                        <Select
                          id="vehicleMake"
                          value={vehicleMake}
                          label="Make"
                          variant="outlined"
                          onChange={(e) =>
                            vehicleMakeHandleChange(e.target.value)
                          }
                        >
                          {vehicleMakeModelList.map((data) => (
                            <MenuItem value={data.make}>{data.make}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Model
                        </InputLabel>
                        <Select
                          id="vehicleModel"
                          value={vehicleModel}
                          label="Model"
                          variant="outlined"
                          onChange={(e) => setVehicleModel(e.target.value)}
                        >
                          {vehicleMakeItem.map((data) => (
                            <MenuItem value={data}>{data}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  )}
                </Stack>
              </div>
            )}
          </DialogContent>
          <Stack
            direction="row"
            spacing={2}
            padding="15px 30px"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                className="btn btn-secondary btn-sm"
                onClick={sendSMSInvoice}
                title="Send SMS"
              >
                <SmsIcon fontSize="large" sx={{ alignSelf: "center" }} />
              </Button>
              {isBusinessCountryIndia && (
                <Button
                  variant="outlined"
                  className="btn btn-secondary btn-sm"
                  onClick={sendWhatsAppReceipt}
                  title="Send via WhatsApp"
                >
                  <WhatsAppIcon fontSize="large" sx={{ alignSelf: "center" }} />
                </Button>
              )}
              <Link
                href="mailto:name1@mail.com?subject=Share%20Item&body=Dear%20Customer%2C%0D%0A%0D%0A%0D%0A%0D%0AThank%20you%20for%20your%20purchase%20with%20Test%20Company%20.%20We%20truly%20appreciate%20your%20business%20and%20look%20forward%20to%20serving%20you%20again.%0D%0A%0D%0A%0D%0A%0D%0ATotal%3A%20%E2%82%B9%20330.00%0D%0A%0D%0A%0D%0A%0D%0AClick%20the%20below%20link%20to%20view%20detailed%20Invoice%0D%0A%0D%0Ahttps%3A%2F%2Fwww.xuvi.com%2FInfopoint%2Fhome%2Finvoice%3Fculture%3Den-IN%26bid%3DB20230%26cid%3DB20230001%26vid%3Dvis-B20230001-20230626-110506641%0D%0A"
                className="btn btn-secondary btn-sm"
                variant="outlined"
                sx={{ display: "flex" }}
                title="Share via Email"
              >
                <ShareIcon fontSize="large" sx={{ alignSelf: "center" }} />
              </Link>
              <Button
                variant="outlined"
                className="btn btn-secondary btn-sm"
                onClick={emailReceipt}
                title="Send Mail"
              >
                <EmailIcon fontSize="large" sx={{ alignSelf: "center" }} />
              </Button>
            </Stack>
            <FormControl>
              <Button
                type="submit"
                className="btn btn-primary"
                variant="contained"
                onClick={onClickOkReceiptOptions}
              >
                Ok
              </Button>
            </FormControl>
          </Stack>
        </BootstrapDialog>

        {/* AMC Plan Check */}
        <BootstrapDialog
          onClose={onCloseCheckAMCPlan}
          aria-labelledby="customized-dialog-title"
          open={isCheckAMCPlanPopup}
          className=""
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={onCloseCheckAMCPlan}
          ></BootstrapDialogTitle>
          <DialogContent dividers className="cs-form">
            <Stack direction="row" spacing={2}>
              <Typography
                level="h4"
                component="h4"
                fontWeight="600"
                fontSize="1rem"
              >
                A plan was already ongoing for the selected vehicle.
              </Typography>
            </Stack>
            {/* Activate now */}
            <Stack
              direction="row"
              spacing={2}
              className="card justify-content-between"
              padding="10px"
              onClick={activateNowAMCPlan}
              style={{ marginTop: "10px" }}
            >
              <Stack direction="row">
                <img alt="Cash Pay" width={"50px"} src={Activate_Now} />
                <Stack
                  direction="column"
                  spacing={1}
                  name="radio-buttons-group"
                  className="pl-1"
                >
                  <Typography
                    level="h4"
                    component="h4"
                    fontWeight="600"
                    fontSize="1.5rem"
                  >
                    Activate Now
                  </Typography>
                  <Typography level="p" component="p" fontSize="1rem">
                    This will end the current ongoing plan for the vehicle
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            {/* Add to Queue */}
            <Stack
              direction="row"
              spacing={2}
              className="card justify-content-between"
              padding="10px"
              onClick={addToQueueAMCPlan}
              style={{ marginTop: "10px" }}
            >
              <Stack direction="row">
                <img alt="Cash Pay" width={"50px"} src={Add_To_Queue} />
                <Stack
                  direction="column"
                  spacing={1}
                  name="radio-buttons-group"
                  className="pl-1"
                >
                  <Typography
                    level="h4"
                    component="h4"
                    fontWeight="600"
                    fontSize="1.5rem"
                  >
                    Add to Queue
                  </Typography>
                  <Typography level="p" component="p" fontSize="1rem">
                    This will end the current ongoing plan for the vehicle
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </DialogContent>
        </BootstrapDialog>

        {/* Service Staffs */}
        <BootstrapDialog
          onClose={onCloseStaffSelection}
          aria-labelledby="customized-dialog-title"
          open={openStaffSelection}
          className=""
        >
          <DialogContent dividers className="cs-form font-size-14">
            <Stack direction="row" spacing={2}>
              {isSalon &&
                !Utils.IsNullOrEmptyArray(serviceStaffs) &&
                !Utils.IsNullOrEmptyObject(selectedServiceItem) && (
                  <RadioGroup
                    style={{ width: "100%" }}
                    value={compType}
                    onChange={(e) => onChangeStaff(e.target.value)}
                  >
                    {serviceStaffs.map((staff) => (
                      <FormControlLabel
                        value={staff.Id}
                        control={
                          <Radio
                            checked={
                              !Utils.IsNullOrEmptyArray(
                                selectedServiceItem.ServiceItemStaffs
                              ) &&
                              selectedServiceItem.ServiceItemStaffs[selectedPersonIndex].Id ===
                              staff.Id
                            }
                          />
                        }
                        label={Utils.IsNullOrEmptyObject(staff) ? "" : staff.Name}
                      />
                    ))}
                  </RadioGroup>
                )}
            </Stack>
            <FormControl class="modal-footer">
              <Button
                className="btn btn-secondary"
                variant="outlined"
                onClick={() => setOpenStaffSelection(false)}
              >
                Close
              </Button>
            </FormControl>
          </DialogContent>
        </BootstrapDialog>

        <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={activeMenu} />
            <Grid xs={12} className="content-sec">
              <Grid container direction="row" className="" paddingBottom="120px">
                <Grid flex="1" spacing={2}>
                  <Grid container className="title-sec">
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="600"
                      fontSize="2rem"
                      className="page-title text-center"
                    >
                      Payment
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ "align-self": "center", "min-width": "200px" }}
                      className="font-size-14"
                    >
                      {!Utils.IsNullOrEmptyArray(invoiceLabelsList) &&
                        invoiceLabelsList.length > 1 && (
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">
                              Invoice Label
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={invoiceLabel || ""}
                              label="Invoice Label"
                              onChange={(e) =>
                                onChangeInvoiceLabel(e.target.value)
                              }
                            >
                              {invoiceLabelsList.map((i) => (
                                <MenuItem value={JSON.stringify(i)}>
                                  {i.description}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                    </Stack>
                  </Grid>
                  <Stack
                    spacing={2}
                    className="justify-content-between card font-size-14"
                    padding="10px"
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      className="justify-content-between"
                    >
                      <Typography
                        level="h4"
                        component="h4"
                        fontWeight="600"
                        fontSize="1.5rem"
                        alignSelf="center"
                      >
                        Available Offers
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Button
                          className="btn btn-primary btn-sm"
                          variant="contained"
                          onClick={clickOpenTip}
                        >
                          Add Tip
                        </Button>
                        <Button
                          className="btn btn-primary btn-sm"
                          variant="contained"
                          onClick={() => clickOpenComplimentary()}
                        >
                          Complimentary
                        </Button>
                      </Stack>
                    </Stack>
                    <Box style={{ "max-height": "200px", overflow: "auto", margin: "0" }}>
                      {!Utils.IsNullOrEmptyArray(offersViewModel) &&
                        offersViewModel.map((offer, i) => (
                          <Stack
                            spacing={2}
                            className="justify-content-between mt-1"
                            padding={1}
                          >
                            <Stack direction="row" spacing={2}>
                              <input
                                type="checkbox"
                                checked={offer.Selected}
                                onClick={() => handleOfferSelection(offer, i)}
                              />
                              <Typography level="p" component="p">
                                {offer.Description}
                              </Typography>
                              {(offer.DiscountType === OfferTypes.Free ||
                                offer.BenefitType === BenefitTypes.Free) && (
                                  <Typography
                                    level="h6"
                                    component="h6"
                                    fontWeight="600"
                                    fontSize="14px"
                                    className="h6 text-right"
                                  >
                                    Free
                                  </Typography>
                                )}
                              {offer.DiscountType !== OfferTypes.Free &&
                                offer.BenefitType !== BenefitTypes.Free && (
                                  <Typography level="p" component="p">
                                    {offer.Selected
                                      ? Utils.GetCurrency(
                                        offer.DiscountTotal,
                                        !Utils.IsNullOrEmptyObject(countryInfo)
                                          ? countryInfo
                                          : getCountry(),
                                        2
                                      )
                                      : "-"}
                                  </Typography>
                                )}
                            </Stack>
                          </Stack>
                        ))}
                    </Box>
                  </Stack>

                  <Stack className="card mt-1 font-size-14 white-bg payment-tbl">
                    <Grid container xs={12} padding="10px" justifyContent="space-around" className="hidden-xs">
                      <Grid xs={1} alignSelf="center">
                        <Typography level="h3" component="h3" className="h3">
                          #
                        </Typography>
                      </Grid>
                      <Grid xs={2} alignSelf="center">
                        {isSalon && (
                          <Typography
                            level="h3"
                            component="h3"
                            className="h3"
                            xs={{ marginLeft: "30px" }}
                          >
                            Staff
                          </Typography>
                        )}
                      </Grid>
                      <Grid xs={3}>
                        <Typography level="h3" component="h3" className="h3">
                          Description
                        </Typography>
                      </Grid>
                      <Grid xs={2}>
                        <Typography
                          level="h3"
                          component="h3"
                          className="h3 text-right"
                        >
                          Unit Price
                        </Typography>
                      </Grid>
                      <Grid xs={1}>
                        <Typography
                          level="h3"
                          component="h3"
                          className="h3 text-right"
                        >
                          Qty
                        </Typography>
                      </Grid>
                      <Grid xs={2}>
                        <Typography
                          level="h3"
                          component="h3"
                          className="h3 text-right"
                        >
                          Total Price
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    {!Utils.IsNullOrEmptyArray(serviceItems) &&
                      serviceItems.map((row, ind) => (
                        <div className="payment-tbl-cell">
                          <Grid container xs={12} padding="10px" justifyContent="space-around">
                            <Grid xs={1} alignSelf="center" className="hidden-xs">
                              <Typography
                                level="p"
                                component="p"
                                className="p"
                                onClick={() =>
                                  clickOpenComplimentary(row.Sequence, false)
                                }
                              >
                                {ind + 1}
                              </Typography>
                            </Grid>
                            <Grid xs={2} alignSelf="center">
                            {!Utils.IsNullOrEmptyArray(row.PackageProductDetails) ? (
                              <>
                              {!Utils.IsNullOrEmptyArray(row.PackageProductDetails) && isSalon  && (
                                  <>
                                  {[...Array(row?.Quantity)].map((_,j) => (
                                    <>
                                    {row.PackageProductDetails.filter(item => item.service).map((service, serviceIndex) => (   
                                       <>
                                      {/* {[...Array(service.Quantity)].map((_, j) => ( */}
                                        <FormControl
                                          key={j}
                                          error={branch.allowStylists && !service?.[serviceIndex]?.[j]?.Name}
                                          sx={{ width: 100 }}
                                          className="ml-h"
                                        >
                                          <InputLabel id={`staff-select-label-${serviceIndex}-${j}`}>Staff</InputLabel>
                                          <Select
                                            labelId={`staff-select-label-${serviceIndex}-${j}`}
                                            id={`staff-select-${serviceIndex}-${j}`}
                                            input={<OutlinedInput label="Name" />}
                                            onChange={(e) => handlePackageSelectPersonChange(e, ind, serviceIndex, j)}
                                            value={service.ServiceItemStaffs?.[j]?.Name || ""}
                                            sx={{ fontSize: "1.5rem" }}
                                            endAdornment={
                                              service.ServiceItemStaffs?.[serviceIndex]?.[j]?.Name && (
                                                <InputAdornment position="end">
                                                  <IconButton
                                                    onClick={() => {
                                                      setServiceItems((prev) => {
                                                        const newServiceItems = [...prev];
                                                        newServiceItems[ind] = {
                                                          ...newServiceItems[ind],
                                                          ServiceItemStaffs: newServiceItems[ind].ServiceItemStaffs.map(
                                                            (staffGroup, sIndex) =>
                                                              sIndex === serviceIndex
                                                                ? staffGroup.map((staff, index) =>
                                                                  index === j ? { ...staff, Name: "", Id: "" } : staff
                                                                )
                                                                : staffGroup
                                                          ),
                                                        };
                                                        return newServiceItems;
                                                      });
                                                    }}
                                                    edge="end"
                                                    size="small"
                                                  >
                                                    <ClearIcon />
                                                  </IconButton>
                                                </InputAdornment>
                                              )
                                            }
                                          >
                                            {serviceStaffs.map((staff) => (
                                              <MenuItem key={staff.Id} value={staff.Name} name={staff.Id}>
                                                {staff.Name}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      {/* ))} */}
                                      </>
                                    
                                  ))}
                                    </>
                                  ))}
                                  
                                  </>
                               
                              )}
                               </>
                            ) : (isSalon && row.Service && (
                                <>
                                  {[...Array(row?.Quantity)].map((_, j) => (
                                    <FormControl error={branch.allowStylists && !row.ServiceItemStaffs[j]?.Name} sx={{ width: 100, }} className="ml-h">
                                      <InputLabel id="demo-simple-select-label">Staff</InputLabel>
                                      <Select
                                        labelId="demo-single-name-label"
                                        id="demo-single-name"
                                        input={<OutlinedInput label="Name" />}
                                        onChange={(e) => handleSelectPersonChange(e, ind, j)}
                                        value={row.ServiceItemStaffs[j]?.Name || ""}
                                        sx={{ fontSize: "1.5rem" }}
                                        endAdornment={
                                          row.ServiceItemStaffs[j]?.Name && (<InputAdornment position="end">
                                            <IconButton
                                              onClick={() => {
                                                setServiceItems((prev) => {
                                                  const newServiceItems = [...prev];
                                                  newServiceItems[ind] = {
                                                    ...newServiceItems[ind],
                                                    ServiceItemStaffs: newServiceItems[ind].ServiceItemStaffs.map((staff, index) =>
                                                      index === j ? { ...staff, Name: "", Id: "" } : staff
                                                    )
                                                  }
                                                  return newServiceItems;
                                                })
                                              }}
                                              edge="end"
                                              size="small"
                                            >
                                              <ClearIcon />
                                            </IconButton>
                                          </InputAdornment>)
                                        }
                                      >
                                        {serviceStaffs.map((staff, inx) => (
                                          <MenuItem
                                            key={staff.Id}
                                            value={staff.Name}
                                            name={staff.Id}
                                          >
                                            {staff.Name}
                                          </MenuItem>
                                        ))

                                        }
                                      </Select>
                                    </FormControl>
                                  ))}
                                </>
                              ))}
                              
                            </Grid>
                            <Grid xs={3} alignSelf="center">
                              <Typography
                                level="h4"
                                component="h4"
                                fontSize="14px"
                                fontWeight="600"
                                className="h4 m-0 text-left"
                                onClick={() =>
                                  clickOpenComplimentary(row.Sequence, false)
                                }
                              >
                                {row.Description}
                              </Typography>
                              <Typography>
                                {!Utils.IsNullOrEmptyArray(row.PackageProductDetails) && (
                                  <ul className="package">
                                    {row.PackageProductDetails.map((item, index) => (
                                      <li key={index}>{item.shortDesc}</li>
                                    ))}
                                  </ul>
                                )}
                              </Typography>
                            </Grid>
                            <Grid xs={2} alignSelf="center">
                              <Typography
                                level="p"
                                component="p"
                                fontSize="14px"
                                className="p text-right"
                                onClick={() =>
                                  clickOpenComplimentary(row.Sequence, false)
                                }
                              >
                                {Utils.GetCurrency(
                                  row.Price,
                                  !Utils.IsNullOrEmptyObject(countryInfo)
                                    ? countryInfo
                                    : getCountry(),
                                  2
                                )}
                              </Typography>
                            </Grid>
                            <Grid xs={1} alignSelf="center">
                              <Typography
                                level="p"
                                component="p"
                                className="p text-right d-flex"
                                onClick={() =>
                                  clickOpenComplimentary(row.Sequence, false)
                                }
                              >
                                <span className="visible-xs mr-h">Qty:</span> {row.Quantity}
                              </Typography>
                            </Grid>
                            <Grid
                              xs={2}
                              direction="column"
                              onClick={() =>
                                clickOpenComplimentary(row.Sequence, false)
                              }
                              alignSelf="center"
                            >
                              <Typography
                                level="p"
                                component="p"
                                fontSize="14px"
                                className="p text-right"
                              >
                                {Utils.GetCurrency(
                                  row.TotalPriceIncludingTax,
                                  !Utils.IsNullOrEmptyObject(countryInfo)
                                    ? countryInfo
                                    : getCountry(),
                                  2
                                )}
                              </Typography>
                              {!Utils.IsNullOrEmptyObject(selectedInvoiceLabel) &&
                                selectedInvoiceLabel.taxIncluded && (
                                  <Typography
                                    level="h6"
                                    component="h6"
                                    fontSize="10px"
                                    className="h6 text-right"
                                  >
                                    {row.TaxPercent}% Tax
                                  </Typography>
                                )}
                            </Grid>
                          </Grid>

                          {!Utils.IsNullOrEmptyArray(row.Discounts) &&
                            !Utils.IsNullOrEmptyArray(
                              row.Discounts.filter((c) => !c.OrderLevel)
                            ) &&
                            row.Discounts.filter((c) => !c.OrderLevel).map(
                              (discount) => (
                                <ItemDiscountComponent
                                  discount={discount}
                                  countryInfo={countryInfo}
                                />
                              )
                            )}
                        </div>
                      ))}

                    {/* Order Complimentary */}
                    {!Utils.IsNullOrEmptyArray(orderLevelDiscounts) &&
                      orderLevelDiscounts.map((discount) => (
                        <Grid
                          container
                          xs={12}
                          className="order-comp"
                          padding="5px 10px"
                        >
                          <Grid xs={3}></Grid>
                          <Grid xs={7} direction="column">
                            <Typography
                              level="h6"
                              component="h6"
                              fontSize="12px"
                              className="h6 m-0"
                            >
                              {discount.Description}
                            </Typography>
                            <Typography
                              level="h6"
                              component="h6"
                              fontWeight="600"
                              fontSize="14px"
                              className="h6 m-0"
                            >
                              {discount.DescriptionDetail}
                            </Typography>
                          </Grid>
                          <Grid xs={2}>
                            <Typography
                              level="h6"
                              component="h6"
                              fontWeight="600"
                              fontSize="14px"
                              className="h6 text-right"
                            >
                              -
                              {Utils.GetCurrency(
                                discount.DiscountTotal,
                                !Utils.IsNullOrEmptyObject(countryInfo)
                                  ? countryInfo
                                  : getCountry(),
                                2
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Stack
                      justifyContent="flex-start"
                      spacing={2}
                      className="font-size-13 mt-3 w-xs-100"
                      sx={{ width: "300px", maxWidth: "100%" }}
                    >
                      <FormControl>
                        <TextField
                          label="Payment Received By"
                          value={paymentReceivedBy}
                          onChange={(e) => setPaymentReceivedBy(e.target.value)}
                        />
                      </FormControl>
                      {countryInfo.Code === UAE.Code && (
                        <FormControl>
                          <TextField
                            label="LPO"
                            value={lpo}
                            onChange={(e) => setLPO(e.target.value)}
                            inputProps={{ maxLength: 12 }}
                          />
                        </FormControl>
                      )}
                      <FormControl>
                        <TextField
                          value={comments}
                          placeholder="Comments..."
                          label="Comments"
                          multiline
                          rows={2}
                          onChange={(e) => setComments(e.target.value)}
                        />
                      </FormControl>
                    </Stack>
                    <Stack
                      direction="column"
                      spacing={1}
                      className="text-right"
                      padding={1}
                    >
                      <Typography
                        level="p"
                        component="p"
                        fontSize="12px"
                        className="p m-0"
                      >
                        Total:
                        <strong className="ml-h">
                          {Utils.GetCurrency(
                            subTotal,
                            !Utils.IsNullOrEmptyObject(countryInfo)
                              ? countryInfo
                              : getCountry(),
                            2
                          )}
                        </strong>
                      </Typography>
                      {isBusinessCountryUS && (
                        <TaxComponent
                          currentTax={tax}
                          country={
                            !Utils.IsNullOrEmptyObject(countryInfo)
                              ? countryInfo
                              : getCountry()
                          }
                        />
                      )}
                      {(isBusinessCountryUAE || isBusinessCountryMalaysia) &&
                        !Utils.IsNullOrEmptyObject(selectedInvoiceLabel) &&
                        selectedInvoiceLabel.taxIncluded && (
                          <TaxComponent
                            currentTax={tax}
                            country={
                              !Utils.IsNullOrEmptyObject(countryInfo)
                                ? countryInfo
                                : getCountry()
                            }
                          />
                        )}
                      {!isBusinessCountryUS &&
                        !isBusinessCountryUAE &&
                        !isBusinessCountryMalaysia &&
                        !Utils.IsNullOrEmptyObject(selectedInvoiceLabel) &&
                        selectedInvoiceLabel.taxIncluded && (
                          <Stack className="m-0">
                            <Typography
                              level="p"
                              component="p"
                              fontSize="12px"
                              className="p m-0"
                            >
                              CGST:
                              <strong className="ml-h">
                                {Utils.GetCurrency(
                                  tax / 2,
                                  !Utils.IsNullOrEmptyObject(countryInfo)
                                    ? countryInfo
                                    : getCountry(),
                                  2
                                )}
                              </strong>
                            </Typography>
                            <Typography
                              level="p"
                              component="p"
                              fontSize="12px"
                              className="p m-0"
                            >
                              SGST:
                              <strong className="ml-h">
                                {Utils.GetCurrency(
                                  tax / 2,
                                  !Utils.IsNullOrEmptyObject(countryInfo)
                                    ? countryInfo
                                    : getCountry(),
                                  2
                                )}
                              </strong>
                            </Typography>
                          </Stack>
                        )}
                      <Typography
                        level="p"
                        component="p"
                        fontSize="12px"
                        className="p m-0"
                      >
                        Tips: <strong className="ml-h">{Utils.GetCurrency(tipAmount, countryInfo)}</strong>
                      </Typography>
                      <Typography
                        level="p"
                        component="p"
                        fontSize="12px"
                        className="p m-0"
                      >
                        Paid: <strong className="ml-h">{Utils.GetCurrency(paidAmount, countryInfo)}</strong>
                      </Typography>
                      <Typography
                        level="h4"
                        component="h6"
                        fontWeight="600"
                        fontSize="20px"
                        className="h6 mt-1"
                      >
                        {Utils.GetCurrency(
                          totalPrice,
                          !Utils.IsNullOrEmptyObject(countryInfo)
                            ? countryInfo
                            : getCountry(),
                          2
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    className="fixed-btn"
                  >
                    <Grid container className="container"
                      justifyContent="end"
                    >
                      <Stack direction="row" justifyContent="end" spacing={1} className="font-size-14">
                        <FormControl>
                          <Button
                            className="btn btn-secondary"
                            variant="outlined"
                            onClick={onClickCancel}
                          >
                            Cancel
                          </Button>
                        </FormControl>
                        <FormControl>
                          <Button
                            className="btn btn-primary"
                            type="submit"
                            variant="contained"
                            onClick={() => {
                              if (!isBusinessCountryUS) {
                                // CheckForConflictingPlans();
                                CheckAndOpenInvoiceDateToPrevious();
                              } else {
                                beginBook();
                              }
                            }}
                          >
                            Pay
                          </Button>
                        </FormControl>
                      </Stack>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default Payment;
