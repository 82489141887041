import React, { useState, useEffect } from "react";
import "../Style.css"; import "../Responsive.css";
import {
  Container,
  Grid,
  Stack,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Box,
  Button
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import Utils from "../core/Utils";
import UserHelper from "../core/UserHelper";
import { CanRaisePaymentLink } from "../core/ModuleHelper";
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import "../bootstrap.min.css";
// import "../daterangepicker.css";
import "../DateRangePickerWithYear.css";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";

// Images
import Expense_Icon from "../images/expense.png";
import Discounts_Icon from "../images/discounts.png";
import Points_Icon from "../images/points.png";
import UPI_Icon from "../images/Upi.png";
import Profit_Icon from "../images/profit.png";
import Netbanking_Icon from "../images/Netbanking.png";
import Cash_Icon from "../images/cash.png";
import Tip_Icon from "../images/tips.png";
import Outstanding_Icon from "../images/outstanding.png";
import Spares_Icon from "../images/spares.png";
import Service_Icon from "../images/service.png";
import Consumables_Icon from "../images/consumables.png";
import Revenue_Icon from "../images/revenue.png";
import Open_Icon from "../images/open.png";
import Assigned_Icon from "../images/assigned.png";
import InProgress_Icon from "../images/inprogress.png";
import Delayed_Icon from "../images/delay.png";
import Completed_Icon from "../images/job_card_completed.png";
import Delivered_Icon from "../images/deliver.png";
import Signups_Icon from "../images/signups.png";
import Active_Icon from "../images/active_users.png";
import Insurance_New_Icon from "../images/Insurance_New.png";
import Service_New_Icon from "../images/Service_New.png";
import Birthday_New_Icon from "../images/Birthday_New.png";
import Expense_New_Icon from "../images/Expense_New.png";
import SalonProduct_Icon from "../images/salon_product.png";
import SalonService_Icon from "../images/salon_services.png";
import SalonMaterials_Icon from "../images/salon_materials.png";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import MyJobCardsIcon from "@mui/icons-material/CreditScore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PaidIcon from "@mui/icons-material/Paid";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import NoCrashOutlinedIcon from "@mui/icons-material/NoCrashOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Salon_Services_Pic from "../images/salon_services_pic.png";
import creditCardIcon from "../images/creditCard.png";
import taxIcon from "../images/tax.png";
import refundIcon from "../images/refund.png";
import { connectToStore } from "../data/store";
import { postAsync } from "../core/serviceClient";
import CountryHelper from "../core/CountryHelper";
import BusinessHelper from "../core/BusinessHelper";
import {
  SideBarMenuNames,
  UserRoles,
  VisitStatuses,
  PaymentMethodTypes,
  VisitTypes,
  MaxDateLimit,
  ProductDetailsTypes,
} from "../core/Constants";
import MessagePopup from "./common/MessagePopUp";
import ConfirmPopup from "./common/ConfirmPopup";
import SideBarMenuItems from "./SideBarMenuItems";
import OverviewDetails from "./OverviewDetails";
import OverviewProductDetails from "./OverviewProductDetails";
import Loader from "./common/Loader";
import Outstanding_OverviewIcon from "../images/outstanding_overview.png";
import automobile_image from "../images/automobile.png";
import salon_image from "../images/salon.png";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CarCrashOutlinedIcon from '@mui/icons-material/CarCrashOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import Expense_image from "../images/calculation.png";
import Birthday_image from "../images/birthday-cake.png";
import Outstanding_image from "../images/money-bag.png";
import InProgressJobCard_image from "../images/service.png";
import AssignJobCard_image from "../images/assign.png";
import OpenJobCard_image from "../images/task.png";
import AMC_image from "../images/amc-img.png";
import Deliver_image from "../images/deliver.png";
import Delay_image from "../images/delay.png";
import Schedule_image from "../images/schedule.png";
import PartialPaid_image from "../images/partial-paid.png";
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import {
  LoginPage,
  BirthdayPage,
  BusinessSetupPage,
  CheckOutPage,
  ExpensePage,
  InsurancePage,
  ServiceReminderPage,
  OverviewPage,
  VisitsPage,
  CustomersPage,
  PaymentLinksPage,
  GroupedVisitsPage,
  CalanderPage
} from "../core/PageConstants";
import { useLocation } from "react-router-dom";
import EditNoteIcon from '@mui/icons-material/EditNote';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';

// Calendar
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

// Rating
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

import IndiaFlag from "../images/IndiaFlag.png";
import UAEFlag from "../images/UAE.png";

import {
  HideOtherMenues,
  BusinessCategories,
  ConfirmTypes,
  UAE,
  USA,
  India,
} from "../core/Constants";

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};


const Overview = (props) => {
  document.title = "Overview";
  document.body.classList.remove("login");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [localSettings, setLocalSettings] = useState({});
  const [spareTitle, setSpareTitle] = useState("");
  const [serviceTitle, setServiceTitle] = useState("");
  const [consumableTitle, setConsumableTitle] = useState("");
  const [isSalon, setIsSalon] = useState(false);
  // const [startDate, setStartDate] = useState(Utils.GetStartDate());
  // const [endDate, setEndDate] = useState(Utils.GetEndDate());
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  const location = useLocation();
  const [visitsStat, setVisitsStat] = useState("");
  const [paymentLinkStat, setPaymentLinkStat] = useState(0);
  const [partiallyPaidStat, setPartiallyPaidStat] = useState(0);
  const [totalExpenseStat, setTotalExpenseStat] = useState("");
  const [discountsStat, setDiscountsStat] = useState("");
  const [refundsStat, setRefundsStat] = useState("");
  const [taxStat, setTaxStat] = useState("");
  const [pointsStat, setPointsStat] = useState("");
  const [cardStat, setCardStat] = useState("");
  const [upiStat, setUPIStat] = useState("");
  const [cashStat, setCashStat] = useState("");
  const [outstandingStat, setOutstandingStat] = useState("");
  const [productRevenue, setProductRevenueStat] = useState("");
  const [serviceRevenueStat, setServiceRevenueStat] = useState("");
  const [materialRevenueStat, setMaterialRevenueStat] = useState("");
  const [revenueStat, setRevenueStat] = useState("");
  const [netbankingStat, setNetbankingStat] = useState("");
  const [profitStat, setProfitStat] = useState("");
  const [openedStat, setOpenedStat] = useState("");
  const [assignedStat, setAssignedStat] = useState("");
  const [inprogressStat, setInProgressStat] = useState("");
  const [delayedStat, setDelayedStat] = useState("");
  const [completedStat, setCompletedStat] = useState("");
  const [deliveredStat, setDeliveredStat] = useState("");
  const [reservationsStat, setReservationsStat] = useState("");
  const [signupsStat, setSignupsStat] = useState("");
  const [activeStat, setActiveStat] = useState("");
  const [feesStat, setFeesStat] = useState("");
  const [tipsStat, setTipsStat] = useState("");
  const [payoutSettledStat, setPayoutSettledStat] = useState("");
  const [payoutTotalStat, setPayoutTotalStat] = useState("");
  const [showStats, setShowStats] = useState(false);
  const [showPayouts, setShowPayouts] = useState(false);
  const [isUSABusiness, setIsUSABusiness] = useState(false);
  const [isOtherPaymentsAvailable, setIsOtherPaymentsAvailable] =
    useState(false);
  const [isAutomobile, setIsAutomobile] = useState(false);
  const [isTechnician, setIsTechnician] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [revenueOpen, setRevenueOpen] = useState(false);
  const [productDetailsOpen, setProductDetailsOpen] = useState(false);
  const [productDetailsType, setProductDetailsType] = useState(
    ProductDetailsTypes.Services
  );
  const [isRevenue, setIsRevenue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [remainingDays, setRemainingDays] = useState(0);
  const [subscriptionEndDateReminderActive, setSubscriptionEndDateReminderActive] = useState(false);

  const overviewStat = async (startDT, endDT) => {
    if (localSettings.authenticated) {
      setIsLoading(true);

      var sDate = Utils.GetISODateTimeString(moment(startDT));
      var eDate = Utils.GetISODateTimeString(moment(endDT));
      var request = {
        BranchId: localSettings.branch.branchId,
        BusinessId: localSettings.business.id,
        MessagingId: localSettings.branch.messagingId,
        ReservationMaxDays: localSettings.branch.reservationMaxDays,
        Start: sDate,
        End: eDate,
      };
      let response = await postAsync("Account/GetStats", request);
      setIsLoading(false);
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgOpen(true);
        setMsgText(response.errorMessage);
      } else if (response.data && response.data.stats) {
        setPaymentLinkStat(response.data.stats.paymentLinks);
        setPartiallyPaidStat(response.data.stats.partiallyPaidVisits);
        setVisitsStat(response.data.stats.visits);
        setTotalExpenseStat(response.data.stats.totalExpenses);
        setDiscountsStat(response.data.stats.discount);
        setPointsStat(response.data.stats.pointsAccrued);
        setCardStat(response.data.stats.card);
        setUPIStat(response.data.stats.upi);
        setNetbankingStat(response.data.stats.netBanking);
        setProfitStat(response.data.stats.profit);
        setCashStat(response.data.stats.cash);
        setOutstandingStat(response.data.stats.outstandingPayments);
        setProductRevenueStat(response.data.stats.productRevenue);
        setServiceRevenueStat(response.data.stats.serviceRevenue);
        setMaterialRevenueStat(response.data.stats.materialRevenue);
        setRevenueStat(response.data.stats.revenue);
        setOpenedStat(response.data.stats.jobcardsOpened);
        setAssignedStat(response.data.stats.jobcardsAssigned);
        setInProgressStat(response.data.stats.jobcardsInProgress);
        setDelayedStat(response.data.stats.jobcardsDelayed);
        setReservationsStat(response.data.stats.reservations);
        setCompletedStat(response.data.stats.jobcardsCompleted);
        setDeliveredStat(response.data.stats.vehiclesDelivered);
        setSignupsStat(response.data.stats.signups);
        setActiveStat(response.data.stats.active);
        setFeesStat(response.data.stats.fees);
        setTipsStat(response.data.stats.tips);
        setRefundsStat(response.data.stats.refunds);
        setTaxStat(response.data.stats.tax);
        let isUSA = CountryHelper.IsBusinessCountryUSA(localSettings.country);
        setShowStats(
          localSettings.user &&
          localSettings.user.role &&
          (localSettings.user.role == UserRoles.GlobalAdmin ||
            localSettings.user.role == UserRoles.BranchAdmin)
        );
        setIsUSABusiness(isUSA);
        setIsOtherPaymentsAvailable(
          CountryHelper.IsOtherPaymentsAvailable(localSettings.country)
        );
        setIsAutomobile(BusinessHelper.IsAutomobile(localSettings.business));
        setShowPayouts(
          isUSA && localSettings.user.role == UserRoles.GlobalAdmin
        );
        setPayoutSettledStat(response.data.stats.payoutSettled);
        setPayoutTotalStat(response.data.stats.payoutTotal);
        setIsTechnician(localSettings.user.role == UserRoles.Technician);
        checkSubscriptionEndDateReminder(response);
      }
    }
  };

  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(OverviewPage, navigate, localSetting)) {
      setLocalSettings(localSetting);
      let isSalonBusiness = BusinessHelper.IsSalon(localSetting.business);
      setSpareTitle(BusinessHelper.GetSparesTitle(isSalonBusiness));
      setServiceTitle(BusinessHelper.GetServicesTitle(isSalonBusiness));
      setConsumableTitle(BusinessHelper.GetConsumablesTitle(isSalonBusiness));
      setIsSalon(isSalonBusiness);
    }
  }, []);

  const checkSubscriptionEndDateReminder = (response) => {
    let localSetting = connectToStore();
    if (
      !Utils.IsNullOrEmpty(response.data.business) && !Utils.IsNullOrEmpty(response.appConfiguration.subscriptionEndDateReminder)
    ) {
      var subscriptionReminderDate = localSetting.business.subscriptionEndDate;
      var datePart = subscriptionReminderDate.split('T')[0];
      var subscriptionEndDate = new Date(datePart);
      var daysBeforeReminder = response.appConfiguration.subscriptionEndDateReminder.remindXDaysBeforeSubscriptionEnd;
      var presentDay = new Date(Utils.GetCurrentDateFromLocal());

      subscriptionEndDate = new Date(subscriptionEndDate.getFullYear(), subscriptionEndDate.getMonth(), subscriptionEndDate.getDate());
      presentDay = new Date(presentDay.getFullYear(), presentDay.getMonth(), presentDay.getDate());
      var timeDifference = subscriptionEndDate - presentDay;
      var daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      if (daysDifference <= daysBeforeReminder && daysDifference >= 0) {
        setSubscriptionEndDateReminderActive(true);
        setRemainingDays(daysDifference);
      } else {
        setSubscriptionEndDateReminderActive(false);
      }
    }
  }
  useEffect(() => {
    checkState();
  }, [localSettings]);

  const checkState = () => {
    if (
      !Utils.IsNullOrEmptyObject(location.state) &&
      !Utils.IsNullOrEmpty(location.state.startDate) &&
      !Utils.IsNullOrEmpty(location.state.endDate)
    ) {
      var startDate = moment(location.state.startDate);
      var endDate = moment(location.state.endDate);
      setSelectedDate([startDate, endDate]);
      overviewStat(startDate, endDate);
    } else {
      overviewStat(selectedDate[0], selectedDate[1]);
    }
  };

  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      if (dateArray[0]._isValid == false || dateArray[1]._isValid == false) {
        setMsgText(
          "Please select a valid date range."
        );
        setMsgOpen(true);
      }
      else {
        let dateDiff = dateArray[1].diff(dateArray[0], "days");
        if (dateDiff > MaxDateLimit) {
          setMsgText(
            "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
          );

          setMsgOpen(true);
        } else {
          setSelectedDate(dateArray);
          overviewStat(dateArray[0], dateArray[1]);
        }
      }
    }
  };
  const handleRefresh = () => {
    overviewStat(selectedDate[0], selectedDate[1]);
  };

  const handleMyJobCards = () => {
    navigate(VisitsPage.Path, {
      state: {
        startDT: Utils.GetISODateTimeString(selectedDate[0]),
        endDT: Utils.GetISODateTimeString(selectedDate[1]),
        visitStatus: VisitTypes.MyJobCard,
      },
    });
  };

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
  };

  const handleRevenueClose = (response) => {
    setRevenueOpen(false);
    if (!Utils.IsNullOrEmptyObject(response)) {
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgOpen(true);
        setMsgText(response.errorMessage);
      }
    }
  };
  const handleProductDetailsClose = (response) => {
    setProductDetailsOpen(false);
    if (!Utils.IsNullOrEmptyObject(response)) {
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgOpen(true);
        setMsgText(response.errorMessage);
      }
    }
  };

  const handleRevenueOpen = (isRevenue) => {
    setIsRevenue(isRevenue);
    setRevenueOpen(true);
  };

  const handleProductDetailsOpen = (detailsType) => {
    setProductDetailsType(detailsType);
    setProductDetailsOpen(true);
  };

  const valueFormatter = (value) => `${value}`;

  const chartSetting = {
    xAxis: [
      {
        scaleType: "band",
        dataKey: "jobcard",
        categoryGapRatio: 0.3,
        barGapRatio: 0.5,
      },
    ],
    series: [
      {
        dataKey: "status",
        valueFormatter,
        color: "#fdb462",
      },
    ],
    yAxis: [
      {
        tickMinStep: 1,
      },
    ],
    width: 550,
    height: 300,
  };
  const dataset = [
    {
      status: openedStat,
      jobcard: "Open",
    },
    {
      status: assignedStat,
      jobcard: "Assigned",
    },
    {
      status: inprogressStat,
      jobcard: "In Progress",
    },
    {
      status: completedStat,
      jobcard: "Completed",
    },
    {
      status: deliveredStat,
      jobcard: "Delivered",
    },
    {
      status: delayedStat,
      jobcard: "Delayed",
    },
  ];

  const [isPaymentVisible, setIsPaymentVisible] = useState(false);
  const handlePaymentSection = (event) => {
    if (event.target.checked) {
      setIsPaymentVisible(true);
    } else {
      setIsPaymentVisible(false);
    }
  };

  const [isViewMoreVisible, setIsViewMoreVisible] = useState(false);
  const handleViewMoreSection = (event) => {
    if (event.target.checked) {
      setIsViewMoreVisible(true);
    } else {
      setIsViewMoreVisible(false);
    }
  };

  const backNavigation = () => {
    navigate(OverviewPage.Path, {
      state: {
        startDate: Utils.GetISODateTimeString(selectedDate[0]),
        endDate: Utils.GetISODateTimeString(selectedDate[1]),
      },
    });
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
        />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
        />
        <OverviewDetails
          open={revenueOpen}
          onClose={handleRevenueClose}
          isRevenue={isRevenue}
          startDT={selectedDate[0]}
          endDT={selectedDate[1]}
        />
        <OverviewProductDetails
          open={productDetailsOpen}
          onClose={handleProductDetailsClose}
          detailsType={productDetailsType}
          startDT={selectedDate[0]}
          endDT={selectedDate[1]}
        />
        <Loader open={isLoading} />
        <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.Overview} />
            <Grid xs={12} className="content-sec">
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                paddingBottom="15px"
              >
                {/* Activity */}
                <Grid container>
                  {/* Title Section */}
                  <Grid container className="title-sec">
                    <Grid xs={12} sm={8} md={4} lg={4} alignSelf="center">
                      <Stack direction="row">
                        <Typography
                          level="h2"
                          component="h2"
                          fontWeight="600"
                          fontSize="2rem"
                          className="h1"
                        >
                          <Button onClick={backNavigation} className="back-btn">
                            <NavigateBeforeIcon />
                          </Button>
                          Detailed Dashboard
                        </Typography>
                        <IconButton
                          aria-label="Refresh"
                          onClick={handleRefresh}
                          style={{
                            "align-self": "center",
                            "margin-left": "10px",
                          }}
                        >
                          <RefreshIcon sx={{ fontSize: 25 }} />
                        </IconButton>
                      </Stack>
                    </Grid>
                    <Grid xs={12} sm={4} md={4} lg={4} alignSelf="center">
                      <Stack
                        direction="row"
                        justifyContent="center"
                        style={{ height: "100%" }}
                      >
                        <DateRangePicker
                          showLeadingZeros={true}
                          onChange={onDateChange}
                          maxDate={new Date()}
                          value={selectedDate}
                          locale={CountryHelper.GetDateCulture(
                            localSettings.country != null ? localSettings.country.Culture : India.Culture
                          )}
                          clearIcon={null}
                          calendarIcon={
                            <CalendarMonthOutlinedIcon
                              style={{
                                "font-size": "24px",
                                "align-self": "center",
                                color: "#666666",
                              }}
                            />
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid
                      xs={12}
                      md={4}
                      lg={4}
                      className="form-inline text-right quote-sec"
                      style={{ "align-self": "inherit" }}
                    >
                      <h4>Hi, Good day!</h4>
                      <p>For things to change, you have to change. <br /> - <strong>JIM ROHN</strong></p>
                    </Grid>
                  </Grid>

                  <Grid container rowGap={2}>
                    <Grid
                      xs={12}
                      md={2}
                      lg={3}>
                      <Card className="card visit-card-box">
                        <Link
                          to={VisitsPage.Path}
                          state={{
                            startDT: Utils.GetISODateTimeString(
                              selectedDate[0]
                            ),
                            endDT: Utils.GetISODateTimeString(
                              selectedDate[1]
                            ),
                            navigationStack: [OverviewPage.Path],
                          }}
                        >
                          <CardActionArea className="p-2" style={{ "justify-content": "space-between", "flex-direction": "column", "align-items": "start", "height": "100%" }}>
                            <ArrowUpwardIcon style={{ "font-size": "32px", "fill": "#88C941", "align-self": "end" }} />
                            <Typography
                              level="h2"
                              component="h2"
                              fontWeight="600"
                              fontSize="1.6rem"
                              className="h1"
                            >
                              <strong>{visitsStat}</strong>
                              <span>Visits</span>
                            </Typography>
                          </CardActionArea>
                        </Link>
                      </Card>
                    </Grid>
                    <Grid
                      xs={12}
                      md={2}
                      lg={3}
                      paddingLeft={2}
                      paddingRight={2}
                      className="px-xs-0">
                      {isAutomobile ? (
                        <Card className="card">
                          <Link
                            to={VisitsPage.Path}
                            state={{
                              startDT: Utils.GetISODateTimeString(
                                selectedDate[0]
                              ),
                              endDT: Utils.GetISODateTimeString(
                                selectedDate[1]
                              ),
                              visitStatus: VisitStatuses.Completed,
                              navigationStack: [OverviewPage.Path],
                            }}
                          >
                            <CardActionArea className="p-2" style={{ "justify-content": "space-between", "flex-direction": "column", "align-items": "start", "height": "100%" }}>
                              <TaskAltIcon style={{ "font-size": "32px", "fill": "#6BB577", "align-self": "end" }} />
                              <Typography
                                level="h2"
                                component="h2"
                                fontWeight="600"
                                fontSize="1.6rem"
                                className="h1"
                              >
                                <strong>{completedStat}</strong>
                                <span>Completed Job Cards</span>
                              </Typography>
                            </CardActionArea>
                          </Link>
                        </Card>
                      ) : (
                        <Card className="card">
                          <Link
                            to={CalanderPage.Path}
                          >
                            <CardActionArea className="p-2" style={{ "justify-content": "space-between", "flex-direction": "column", "align-items": "start", "height": "100%" }}>
                              <Box
                                component="img"
                                sx={{
                                  height: 36,
                                  width: 36,
                                  alignSelf: "end"
                                }}
                                alt="Appointments"
                                src={Schedule_image}
                              />
                              <Typography
                                level="h2"
                                component="h2"
                                fontWeight="600"
                                fontSize="1.6rem"
                                className="h1"
                              >
                                <strong>{reservationsStat}</strong>
                                <span>Appointments</span>
                              </Typography>
                            </CardActionArea>
                          </Link>
                        </Card>
                      )}
                    </Grid>
                    <Grid
                      xs={12}
                      md={3}
                      lg={3}>
                      {isAutomobile ? (
                        <Grid container rowGap={2} className="h-100">
                          <Grid xs={12}>
                            <Card className="card">
                              <Link
                                to={VisitsPage.Path}
                                state={{
                                  startDT: Utils.GetISODateTimeString(
                                    selectedDate[0]
                                  ),
                                  endDT: Utils.GetISODateTimeString(
                                    selectedDate[1]
                                  ),
                                  visitStatus: VisitStatuses.Delivered,
                                  navigationStack: [OverviewPage.Path],
                                }}
                                style={{ display: "flex", "flex-direction": "row", "justify-content": "space-between" }}
                              >
                                <CardActionArea style={{ "justify-content": "space-between" }}>
                                  <Typography
                                    level="h2"
                                    component="h2"
                                    fontWeight="600"
                                    style={{ "font-size": "24px" }}
                                  >
                                    <strong>{deliveredStat}</strong>
                                    <span>Delivered</span>
                                  </Typography>
                                  <Box
                                    className="text-end"
                                    component="img"
                                    sx={{
                                      height: 32,
                                      width: 32,
                                    }}
                                    style={{ "align-self": "start" }}
                                    alt="Delivered"
                                    src={Deliver_image}
                                  />
                                </CardActionArea>
                              </Link>
                            </Card>
                          </Grid>
                          <Grid xs={12}>
                            <Card className="card">
                              <Link
                                to={VisitsPage.Path}
                                state={{
                                  startDT: Utils.GetISODateTimeString(
                                    selectedDate[0]
                                  ),
                                  endDT: Utils.GetISODateTimeString(
                                    selectedDate[1]
                                  ),
                                  visitStatus: VisitTypes.Delayed,
                                  navigationStack: [OverviewPage.Path],
                                }}
                                style={{ display: "flex", "flex-direction": "row", "justify-content": "space-between" }}
                              >
                                <CardActionArea style={{ "justify-content": "space-between" }}>
                                  <Typography
                                    level="h2"
                                    component="h2"
                                    fontWeight="600"
                                    style={{ "font-size": "24px" }}
                                  >
                                    <strong>{delayedStat}</strong>
                                    <span>Delayed</span>
                                  </Typography>
                                  <Box
                                    className="text-end"
                                    component="img"
                                    sx={{
                                      height: 32,
                                      width: 32,
                                    }}
                                    style={{ "align-self": "start" }}
                                    alt="Delayed"
                                    src={Delay_image}
                                  />
                                </CardActionArea>
                              </Link>
                            </Card>
                          </Grid>
                        </Grid>
                      ) : (
                        <Card className="card">
                          <Link
                            to={VisitsPage.Path}
                            state={{
                              startDT: Utils.GetISODateTimeString(
                                selectedDate[0]
                              ),
                              endDT: Utils.GetISODateTimeString(
                                selectedDate[1]
                              ),
                              navigationStack: [OverviewPage.Path],
                              partiallyPaid: true,
                            }}
                          >
                            <CardActionArea className="p-2" style={{ "justify-content": "space-between", "flex-direction": "column", "align-items": "start", "height": "100%" }}>
                              <Box
                                component="img"
                                sx={{
                                  height: 36,
                                  width: 36,
                                  alignSelf: "end"
                                }}
                                alt="Appointments"
                                src={PartialPaid_image}
                              />
                              <Typography
                                level="h2"
                                component="h2"
                                fontWeight="600"
                                fontSize="1.6rem"
                                className="h1"
                              >
                                <strong>{partiallyPaidStat}</strong>
                                <span>Partially Paid</span>
                              </Typography>
                            </CardActionArea>
                          </Link>
                        </Card>
                      )}
                    </Grid>
                    <Grid
                      xs={12}
                      md={3}
                      lg={3}
                      className="text-end quote-img">
                      {isAutomobile ? (
                        <Box
                          component="img"
                          sx={{
                            width: "224px",
                          }}
                          alt="Automobile"
                          src={automobile_image}
                        />
                      ) : (<Box
                        component="img"
                        sx={{
                          width: "274px",
                        }}
                        alt="Salon"
                        src={salon_image}
                      />)}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container mt={5}>
                  <Grid xs={12}>
                    {/* Job Cards */}
                    {isAutomobile && (
                      <Grid container>
                        <Grid container>
                          <Grid xs alignSelf="center">
                            <Typography
                              level="h2"
                              component="h2"
                              fontWeight="700"
                              fontSize="1.6rem"
                              className="h1"
                            >
                              Job Card Status
                            </Typography>
                          </Grid>
                          {/* <Stack direction="row">
                            <Link to={CheckOutPage.Path} state={{ onSuccess: OverviewPage.Path, navigationStack: [OverviewPage.Path], }} className="btn-secondary white-bg" style={{"display":"flex"}}>
                              <CheckCircleOutlineOutlinedIcon sx={{ fontSize: "24px", marginRight: "6px", alignSelf : "Center"  }}/><span>Check-Out</span>
                            </Link>
                            <Link to={CustomersPage.Path} state={{ fromJobcard: true }} className="btn-primary ml-1" style={{"display":"flex"}}>
                              <EditNoteIcon sx={{ fontSize: "24px", marginRight: "6px", alignSelf : "Center" }} /><span>Create Job Card</span>
                            </Link>
                          </Stack> */}
                          <Grid
                            container
                            spacing={1}
                            marginTop="1px"
                          >
                            <Grid item flex="1">
                              <Card className="card">
                                <Link
                                  to={VisitsPage.Path}
                                  state={{
                                    startDT: Utils.GetISODateTimeString(
                                      selectedDate[0]
                                    ),
                                    endDT: Utils.GetISODateTimeString(
                                      selectedDate[1]
                                    ),
                                    visitStatus: VisitStatuses.Open,
                                    navigationStack: [OverviewPage.Path],
                                  }}
                                >
                                  <CardActionArea style={{ "justify-content": "space-between" }}>
                                    <Typography
                                      level="h2"
                                      component="h2"
                                      fontWeight="600"
                                      style={{ "font-size": "24px" }}
                                    >
                                      <strong>{openedStat}</strong>
                                      <span>Open</span>
                                    </Typography>
                                    <Box
                                      className="text-end"
                                      component="img"
                                      sx={{
                                        height: 30,
                                        width: 30,
                                      }}
                                      style={{ "align-self": "start" }}
                                      alt="Open"
                                      src={OpenJobCard_image}
                                    />
                                  </CardActionArea>
                                </Link>
                              </Card>
                            </Grid>
                            <Grid item flex="1">
                              <Card className="card">
                                <Link
                                  to={VisitsPage.Path}
                                  state={{
                                    startDT: Utils.GetISODateTimeString(
                                      selectedDate[0]
                                    ),
                                    endDT: Utils.GetISODateTimeString(
                                      selectedDate[1]
                                    ),
                                    visitStatus: VisitStatuses.Assigned,
                                    navigationStack: [OverviewPage.Path],
                                  }}
                                >
                                  <CardActionArea style={{ "justify-content": "space-between" }}>
                                    <Typography
                                      level="h2"
                                      component="h2"
                                      fontWeight="600"
                                      style={{ "font-size": "24px" }}
                                    >
                                      <strong>{assignedStat}</strong>
                                      <span>Assigned</span>
                                    </Typography>
                                    <Box
                                      className="text-end"
                                      component="img"
                                      sx={{
                                        height: 30,
                                        width: 30,
                                      }}
                                      style={{ "align-self": "start" }}
                                      alt="Assigned"
                                      src={AssignJobCard_image}
                                    />
                                  </CardActionArea>
                                </Link>
                              </Card>
                            </Grid>
                            <Grid item flex="1">
                              <Card className="card">
                                <Link
                                  to={VisitsPage.Path}
                                  state={{
                                    startDT: Utils.GetISODateTimeString(
                                      selectedDate[0]
                                    ),
                                    endDT: Utils.GetISODateTimeString(
                                      selectedDate[1]
                                    ),
                                    visitStatus: VisitStatuses.WorkInProgress,
                                    navigationStack: [OverviewPage.Path],
                                  }}
                                >
                                  <CardActionArea style={{ "justify-content": "space-between" }}>
                                    <Typography
                                      level="h2"
                                      component="h2"
                                      fontWeight="600"
                                      style={{ "font-size": "24px" }}
                                    >
                                      <strong>{inprogressStat}</strong>
                                      <span>In Progress</span>
                                    </Typography>
                                    <Box
                                      className="text-end"
                                      component="img"
                                      sx={{
                                        height: 30,
                                        width: 30,
                                      }}
                                      style={{ "align-self": "start" }}
                                      alt="In Progress"
                                      src={InProgressJobCard_image}
                                    />
                                  </CardActionArea>
                                </Link>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {/* Stat Section */}
                    <Grid container className="stat-sec mt-2 pb-5">
                      <Grid container xs={12} item>
                        <Grid container>
                          <Grid xs alignSelf="center">
                            <Typography
                              level="h2"
                              component="h2"
                              fontWeight="700"
                              fontSize="1.6rem"
                              className="h1"
                            >
                              Payments
                            </Typography>
                          </Grid>
                          <Stack direction="row">
                            <Link
                              to={VisitsPage.Path}
                              state={{
                                startDT: Utils.GetISODateTimeString(
                                  selectedDate[0]
                                ),
                                endDT: Utils.GetISODateTimeString(
                                  selectedDate[1]
                                ),
                                navigationStack: [OverviewPage.Path],
                                partiallyPaid: true,
                              }} className="btn-secondary white-bg" style={{ "display": "flex" }}>
                              <Typography
                                level="h2"
                                component="h2"
                                fontWeight="600"
                                fontSize="1.5rem"
                                className="h1"
                              >
                                <span>
                                  {partiallyPaidStat}
                                </span>{" "}
                                <span>Partially Paid</span>
                              </Typography>
                            </Link>
                            {CanRaisePaymentLink(localSettings.business) && (
                              <Link
                                to={PaymentLinksPage.Path}
                                state={{
                                  ...location.state,
                                  startDT: Utils.GetISODateTimeString(
                                    selectedDate[0]
                                  ),
                                  endDT: Utils.GetISODateTimeString(
                                    selectedDate[1]
                                  ),
                                  navigationStack: [OverviewPage.Path],
                                  activeMenu: SideBarMenuNames.Overview,
                                }}
                                className="btn-primary ml-1" style={{ "display": "flex" }}>
                                <Typography
                                  level="h2"
                                  component="h2"
                                  fontWeight="600"
                                  fontSize="1.5rem"
                                  className="h1"
                                >
                                  <span>
                                    {paymentLinkStat}
                                  </span> &nbsp;
                                  <span>Payment Links</span>
                                </Typography>
                              </Link>
                            )}
                          </Stack>
                        </Grid>
                        {showStats && (
                          <Grid xs={12} item className="card-box mt-1">
                            <div>
                              {subscriptionEndDateReminderActive && (
                                <div style={{ width: "400px" }}>
                                  <div className="containerStyle">
                                    <span className="textStyle">Your subscription will end in {remainingDays} day(s). <span className="blinkingText"
                                    >Please renew it soon.</span></span>
                                  </div>
                                </div>
                              )}
                            </div>
                            <Grid container xs={12} spacing={1}>
                              {/* Profit */}
                              <Grid
                                item
                                xs={12}
                                md={!isSalon ? 6 : 4}
                                lg={!isSalon ? 4 : 6}
                              >
                                <Tooltip
                                  title={Utils.GetCurrency(
                                    Math.max(
                                      profitStat - totalExpenseStat - feesStat,
                                      0
                                    ),
                                    localSettings.country,
                                    0
                                  )}
                                  arrow
                                >
                                  <Card className="card">
                                    <Link
                                      to="javascript:void(0)"
                                      onClick={() => handleRevenueOpen(false)}
                                    >
                                      <CardActionArea>
                                        <Stack
                                          direction="row"
                                          style={{ width: "100%", justifyContent: "space-between" }}
                                        >
                                          <CardContent
                                            className="p-h"
                                            style={{ width: "calc(100% - 40px)" }}
                                          >
                                            <Typography
                                              level="h3"
                                              component="h3"
                                              fontSize="2rem"
                                              fontWeight="600"
                                              color="green"
                                              className="text-ellipsis"
                                            >
                                              {Utils.GetCurrency(
                                                profitStat -
                                                totalExpenseStat -
                                                feesStat,
                                                localSettings.country,
                                                0
                                              )}
                                            </Typography>
                                            <Typography
                                              level="span"
                                              component="span"
                                              fontSize="1.3rem"
                                            >
                                              Profit
                                            </Typography>
                                          </CardContent>
                                          <Box
                                            className="flex-end"
                                            component="img"
                                            sx={{
                                              height: 34,
                                              width: 34,
                                            }}
                                            alt="Profit"
                                            src={Profit_Icon}
                                          />
                                        </Stack>
                                      </CardActionArea>
                                    </Link>
                                  </Card>
                                </Tooltip>
                              </Grid>
                              {/* Revenue */}
                              <Grid
                                item
                                xs={12}
                                md={!isSalon ? 6 : 4}
                                lg={!isSalon ? 4 : 6}
                              >
                                <Tooltip
                                  title={Utils.GetCurrency(
                                    revenueStat,
                                    localSettings.country,
                                    0
                                  )}
                                  arrow
                                >
                                  <Card className="card">
                                    <Link
                                      to="javascript:void(0)"
                                      onClick={() => handleRevenueOpen(true)}
                                    >
                                      <CardActionArea>
                                        <Stack
                                          direction="row"
                                          style={{ width: "100%", justifyContent: "space-between" }}
                                        >
                                          <CardContent
                                            className="p-h"
                                            style={{ width: "calc(100% - 40px)" }}
                                          >
                                            <Typography
                                              level="h3"
                                              component="h3"
                                              fontSize="2rem"
                                              fontWeight="600"
                                              className="text-ellipsis"
                                            >
                                              {Utils.GetCurrency(
                                                revenueStat,
                                                localSettings.country,
                                                0
                                              )}
                                            </Typography>
                                            <Typography
                                              level="span"
                                              component="span"
                                              fontSize="1.3rem"
                                            >
                                              Revenue
                                            </Typography>
                                          </CardContent>
                                          <Box
                                            className="flex-end"
                                            component="img"
                                            sx={{
                                              height: 34,
                                              width: 34,
                                            }}
                                            alt="Revenue"
                                            src={Revenue_Icon}
                                          />
                                        </Stack>
                                      </CardActionArea>
                                    </Link>
                                  </Card>
                                </Tooltip>
                              </Grid>
                              {/* Outstanding */}
                              {!isSalon && (
                                <Grid item xs={12} md={4} lg={4}>
                                  <Tooltip
                                    title={Utils.GetCurrency(
                                      outstandingStat,
                                      localSettings.country,
                                      0
                                    )}
                                    arrow
                                  >
                                    <Card className="card">
                                      <Link
                                        to={VisitsPage.Path}
                                        state={{
                                          startDT: Utils.GetISODateTimeString(
                                            selectedDate[0]
                                          ),
                                          endDT: Utils.GetISODateTimeString(
                                            selectedDate[1]
                                          ),
                                          paymentType: PaymentMethodTypes.UnPaid,
                                          navigationStack: [OverviewPage.Path],
                                        }}
                                      >
                                        <CardActionArea>
                                          <Stack
                                            direction="row"
                                            style={{ width: "100%", justifyContent: "space-between" }}
                                          >
                                            <CardContent
                                              className="p-h"
                                              style={{ width: "calc(100% - 40px)" }}
                                            >
                                              <Typography
                                                level="h3"
                                                component="h3"
                                                fontSize="2rem"
                                                fontWeight="600"
                                                color="orange"
                                                className="text-ellipsis"
                                              >
                                                {Utils.GetCurrency(
                                                  outstandingStat,
                                                  localSettings.country,
                                                  0
                                                )}
                                              </Typography>
                                              <Typography
                                                level="span"
                                                component="span"
                                                fontSize="1.3rem"
                                              >
                                                Outstanding
                                              </Typography>
                                            </CardContent>
                                            <Box
                                              className="flex-end"
                                              component="img"
                                              sx={{
                                                height: 34,
                                                width: 34,
                                              }}
                                              alt="Outstanding"
                                              src={Outstanding_Icon}
                                            />
                                          </Stack>
                                        </CardActionArea>
                                      </Link>
                                    </Card>
                                  </Tooltip>
                                </Grid>
                              )}
                            </Grid>
                            <Grid container xs={12} justifyContent="Center">
                              <label className="btn" title="View more">
                                <input
                                  type="checkbox"
                                  onChange={handleViewMoreSection}
                                  style={{ display: "none" }}
                                />
                                <MoreHorizIcon style={{ "font-size": "24px" }} />
                              </label>
                            </Grid>
                            {!isViewMoreVisible && (
                              <Grid container xs={12}>
                                <Grid container xs={12} spacing={1}>
                                  {/* Spares */}
                                  <Grid item xs={12} md={4} lg={4}>
                                    <Tooltip
                                      title={Utils.GetCurrency(
                                        productRevenue,
                                        localSettings.country,
                                        0
                                      )}
                                      arrow
                                    >
                                      <Card className="card">
                                        <Link
                                          to="javascript:void(0)"
                                          onClick={() =>
                                            handleProductDetailsOpen(
                                              ProductDetailsTypes.Spares
                                            )
                                          }
                                        >
                                          <CardActionArea>
                                            <Stack
                                              direction="row"
                                              style={{ width: "100%", justifyContent: "space-between" }}
                                            >
                                              <CardContent
                                                className="p-h"
                                                style={{
                                                  width: "calc(100% - 40px)",
                                                }}
                                              >
                                                <Typography
                                                  level="h3"
                                                  component="h3"
                                                  fontSize="2rem"
                                                  fontWeight="600"
                                                  className="text-ellipsis"
                                                >
                                                  {Utils.GetCurrency(
                                                    productRevenue,
                                                    localSettings.country,
                                                    0
                                                  )}
                                                </Typography>
                                                <Typography
                                                  level="span"
                                                  component="span"
                                                  fontSize="1.3rem"
                                                >
                                                  {spareTitle}
                                                </Typography>
                                              </CardContent>
                                              <Box
                                                className="flex-end"
                                                component="img"
                                                sx={{
                                                  height: 34,
                                                  width: 34,
                                                }}
                                                alt={spareTitle}
                                                src={
                                                  isSalon
                                                    ? SalonProduct_Icon
                                                    : Spares_Icon
                                                }
                                              />
                                            </Stack>
                                          </CardActionArea>
                                        </Link>
                                      </Card>
                                    </Tooltip>
                                  </Grid>
                                  {/* Consumables */}
                                  <Grid item xs={12} md={4} lg={4}>
                                    <Tooltip
                                      title={Utils.GetCurrency(
                                        materialRevenueStat,
                                        localSettings.country,
                                        0
                                      )}
                                      arrow
                                    >
                                      <Card className="card">
                                        <Link
                                          to="javascript:void(0)"
                                          onClick={() =>
                                            handleProductDetailsOpen(
                                              ProductDetailsTypes.Consumables
                                            )
                                          }
                                        >
                                          <CardActionArea>
                                            <Stack
                                              direction="row"
                                              style={{ width: "100%", justifyContent: "space-between" }}
                                            >
                                              <CardContent
                                                className="p-h"
                                                style={{
                                                  width: "calc(100% - 40px)",
                                                }}
                                              >
                                                <Typography
                                                  level="h3"
                                                  component="h3"
                                                  fontSize="2rem"
                                                  fontWeight="600"
                                                  className="text-ellipsis"
                                                >
                                                  {Utils.GetCurrency(
                                                    materialRevenueStat,
                                                    localSettings.country,
                                                    0
                                                  )}
                                                </Typography>
                                                <Typography
                                                  level="span"
                                                  component="span"
                                                  fontSize="1.3rem"
                                                >
                                                  {consumableTitle}
                                                </Typography>
                                              </CardContent>
                                              <Box
                                                className="flex-end"
                                                component="img"
                                                sx={{
                                                  height: 34,
                                                  width: 34,
                                                }}
                                                alt={consumableTitle}
                                                src={
                                                  isSalon
                                                    ? SalonMaterials_Icon
                                                    : Consumables_Icon
                                                }
                                              />
                                            </Stack>
                                          </CardActionArea>
                                        </Link>
                                      </Card>
                                    </Tooltip>
                                  </Grid>
                                  {/* Services */}
                                  <Grid item xs={12} md={4} lg={4}>
                                    <Tooltip
                                      title={Utils.GetCurrency(
                                        serviceRevenueStat,
                                        localSettings.country,
                                        0
                                      )}
                                      arrow
                                    >
                                      <Card className="card">
                                        <Link
                                          to="javascript:void(0)"
                                          onClick={() =>
                                            handleProductDetailsOpen(
                                              ProductDetailsTypes.Services
                                            )
                                          }
                                        >
                                          <CardActionArea>
                                            <Stack
                                              direction="row"
                                              style={{ width: "100%", justifyContent: "space-between" }}
                                            >
                                              <CardContent
                                                className="p-h"
                                                style={{
                                                  width: "calc(100% - 40px)",
                                                }}
                                              >
                                                <Typography
                                                  level="h3"
                                                  component="h3"
                                                  fontSize="2rem"
                                                  fontWeight="600"
                                                  className="text-ellipsis"
                                                >
                                                  {Utils.GetCurrency(
                                                    serviceRevenueStat,
                                                    localSettings.country,
                                                    0
                                                  )}
                                                </Typography>
                                                <Typography
                                                  level="span"
                                                  component="span"
                                                  fontSize="1.3rem"
                                                >
                                                  {serviceTitle}
                                                </Typography>
                                              </CardContent>
                                              <Box
                                                className="flex-end"
                                                component="img"
                                                sx={{
                                                  height: 34,
                                                  width: 34,
                                                }}
                                                alt={serviceTitle}
                                                src={
                                                  isSalon
                                                    ? SalonService_Icon
                                                    : Service_Icon
                                                }
                                              />
                                            </Stack>
                                          </CardActionArea>
                                        </Link>
                                      </Card>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                                <Grid container xs={12} spacing={1} className="mt-1">
                                  {/* Discounts */}
                                  <Grid item xs={12} md={3} lg={3}>
                                    <Tooltip
                                      title={Utils.GetCurrency(
                                        discountsStat,
                                        localSettings.country,
                                        0
                                      )}
                                      arrow
                                    >
                                      <Card className="card">
                                        <CardActionArea disabled={true}>
                                          <Stack
                                            direction="row"
                                            style={{ width: "100%", justifyContent: "space-between" }}
                                          >
                                            <CardContent
                                              className="p-h"
                                              style={{ width: "calc(100% - 40px)" }}
                                            >
                                              <Typography
                                                level="h3"
                                                component="h3"
                                                fontSize="2rem"
                                                fontWeight="600"
                                                className="text-ellipsis"
                                              >
                                                {Utils.GetCurrency(
                                                  discountsStat,
                                                  localSettings.country,
                                                  0
                                                )}
                                              </Typography>
                                              <Typography
                                                level="span"
                                                component="span"
                                                fontSize="1.3rem"
                                              >
                                                Discounts
                                              </Typography>
                                            </CardContent>
                                            <Box
                                              className="flex-end"
                                              component="img"
                                              sx={{
                                                height: 30,
                                                width: 30,
                                              }}
                                              alt="Discounts"
                                              src={Discounts_Icon}
                                            />
                                          </Stack>
                                        </CardActionArea>
                                      </Card>
                                    </Tooltip>
                                  </Grid>
                                  {/* Expense */}
                                  <Grid item xs={12} md={3} lg={3}>
                                    <Tooltip
                                      title={Utils.GetCurrency(
                                        totalExpenseStat,
                                        localSettings.country,
                                        0
                                      )}
                                      arrow
                                    >
                                      <Card className="card">
                                        <CardActionArea
                                          disabled={true}
                                          className="d-flex"
                                        >
                                          <Stack
                                            direction="row"
                                            style={{ width: "100%", justifyContent: "space-between" }}
                                          >
                                            <CardContent
                                              className="p-h"
                                              style={{ width: "calc(100% - 40px)" }}
                                            >
                                              <Typography
                                                level="h3"
                                                component="h3"
                                                fontSize="2rem"
                                                fontWeight="600"
                                                className="text-ellipsis"
                                              >
                                                {Utils.GetCurrency(
                                                  totalExpenseStat,
                                                  localSettings.country,
                                                  0
                                                )}
                                              </Typography>
                                              <Typography
                                                level="span"
                                                component="span"
                                                fontSize="1.3rem"
                                              >
                                                Expense
                                              </Typography>
                                            </CardContent>
                                            <Box
                                              className="flex-end"
                                              component="img"
                                              sx={{
                                                height: 34,
                                                width: 34,
                                              }}
                                              alt="Expense"
                                              src={Expense_Icon}
                                            />
                                          </Stack>
                                        </CardActionArea>
                                      </Card>
                                    </Tooltip>
                                  </Grid>
                                  {/* Refunds */}
                                  <Grid item xs={12} md={3} lg={3}>
                                    <Tooltip
                                      title={Utils.GetCurrency(
                                        refundsStat,
                                        localSettings.country,
                                        0
                                      )}
                                      arrow
                                    >
                                      <Card className="card">
                                        <CardActionArea disabled={true}>
                                          <Stack
                                            direction="row"
                                            style={{ width: "100%", justifyContent: "space-between" }}
                                          >
                                            <CardContent
                                              className="p-h"
                                              style={{ width: "calc(100% - 40px)" }}
                                            >
                                              <Typography
                                                level="h3"
                                                component="h3"
                                                fontSize="2rem"
                                                fontWeight="600"
                                                className="text-ellipsis"
                                              >
                                                {Utils.GetCurrency(
                                                  refundsStat,
                                                  localSettings.country,
                                                  0
                                                )}
                                              </Typography>
                                              <Typography
                                                level="span"
                                                component="span"
                                                fontSize="1.3rem"
                                              >
                                                Refunds
                                              </Typography>
                                            </CardContent>
                                            <Box
                                              className="flex-end"
                                              component="img"
                                              sx={{
                                                height: 34,
                                                width: 34,
                                              }}
                                              alt="Refunds"
                                              src={refundIcon}
                                            />
                                          </Stack>
                                        </CardActionArea>
                                      </Card>
                                    </Tooltip>
                                  </Grid>
                                  {/* Tax */}
                                  <Grid item xs={12} md={3} lg={3}>
                                    <Tooltip
                                      title={Utils.GetCurrency(
                                        taxStat,
                                        localSettings.country,
                                        0
                                      )}
                                      arrow
                                    >
                                      <Card className="card">
                                        <CardActionArea
                                          disabled={true}
                                          className="d-flex"
                                        >
                                          <Stack
                                            direction="row"
                                            style={{ width: "100%", justifyContent: "space-between" }}
                                          >
                                            <CardContent
                                              className="p-h"
                                              style={{ width: "calc(100% - 40px)" }}
                                            >
                                              <Typography
                                                level="h3"
                                                component="h3"
                                                fontSize="2rem"
                                                fontWeight="600"
                                                className="text-ellipsis"
                                              >
                                                {Utils.GetCurrency(
                                                  taxStat,
                                                  localSettings.country,
                                                  0
                                                )}
                                              </Typography>
                                              <Typography
                                                level="span"
                                                component="span"
                                                fontSize="1.3rem"
                                              >
                                                Tax
                                              </Typography>
                                            </CardContent>
                                            <Box
                                              className="flex-end"
                                              component="img"
                                              sx={{
                                                height: 34,
                                                width: 34,
                                              }}
                                              alt="Tax"
                                              src={taxIcon}
                                            />
                                          </Stack>
                                        </CardActionArea>
                                      </Card>
                                    </Tooltip>
                                  </Grid>
                                </Grid>

                                <Grid container xs={12} className="mt-1">
                                  <Grid container xs={12} spacing={1}>
                                    {/* Points */}
                                    {isUSABusiness && (
                                      <Grid item xs={12} md={3} lg={3}>
                                        <Card className="card">
                                          <CardActionArea disabled={true}>
                                            <Box
                                              className="flex-end"
                                              component="img"
                                              sx={{
                                                height: 34,
                                                width: 34,
                                              }}
                                              alt="Points"
                                              src={Points_Icon}
                                            />
                                            <CardContent className="p-h">
                                              <Typography
                                                level="h3"
                                                component="h3"
                                                fontSize="2rem"
                                                fontWeight="600"
                                              >
                                                {Utils.GetCurrency(
                                                  pointsStat,
                                                  localSettings.country,
                                                  0
                                                )}
                                              </Typography>
                                              <Typography
                                                level="span"
                                                component="span"
                                                fontSize="1.3rem"
                                              >
                                                Points
                                              </Typography>
                                            </CardContent>
                                          </CardActionArea>
                                        </Card>
                                      </Grid>
                                    )}

                                    {/* Card */}
                                    <Grid item xs={12} md={3} lg={3}>
                                      <Tooltip
                                        title={Utils.GetCurrency(
                                          cardStat,
                                          localSettings.country,
                                          0
                                        )}
                                        arrow
                                      >
                                        <Card className="card bl-0">
                                          <Link
                                            to={
                                              !isUSABusiness
                                                ? VisitsPage.Path
                                                : "javascript:void(0)"
                                            }
                                            state={{
                                              startDT: Utils.GetISODateTimeString(
                                                selectedDate[0]
                                              ),
                                              endDT: Utils.GetISODateTimeString(
                                                selectedDate[1]
                                              ),
                                              paymentType:
                                                PaymentMethodTypes.Card,
                                              navigationStack: [
                                                OverviewPage.Path,
                                              ],
                                            }}
                                          >
                                            <CardActionArea>
                                              <Stack
                                                direction="row"
                                                style={{ width: "100%", justifyContent: "space-between" }}
                                              >
                                                <CardContent
                                                  className="p-h"
                                                  style={{
                                                    width: "calc(100% - 40px)",
                                                  }}
                                                >
                                                  <Typography
                                                    level="h3"
                                                    component="h3"
                                                    fontSize="2rem"
                                                    fontWeight="600"
                                                    className="text-ellipsis"
                                                  >
                                                    {Utils.GetCurrency(
                                                      cardStat,
                                                      localSettings.country,
                                                      0
                                                    )}
                                                  </Typography>
                                                  <Typography
                                                    level="span"
                                                    component="span"
                                                    fontSize="1.3rem"
                                                    className="text-ellipsis"
                                                  >
                                                    Card
                                                  </Typography>
                                                </CardContent>
                                                <Box
                                                  className="flex-end"
                                                  component="img"
                                                  sx={{
                                                    height: 34,
                                                    width: 34,
                                                  }}
                                                  alt="Card"
                                                  src={creditCardIcon}
                                                />
                                              </Stack>
                                            </CardActionArea>
                                          </Link>
                                        </Card>
                                      </Tooltip>
                                    </Grid>

                                    {/* Cash */}
                                    <Grid item xs={12} md={3} lg={3}>
                                      <Tooltip
                                        title={Utils.GetCurrency(
                                          cashStat,
                                          localSettings.country,
                                          0
                                        )}
                                        arrow
                                      >
                                        <Card className="card">
                                          <Link
                                            to={VisitsPage.Path}
                                            state={{
                                              startDT: Utils.GetISODateTimeString(
                                                selectedDate[0]
                                              ),
                                              endDT: Utils.GetISODateTimeString(
                                                selectedDate[1]
                                              ),
                                              paymentType:
                                                PaymentMethodTypes.Cash,
                                              navigationStack: [
                                                OverviewPage.Path,
                                              ],
                                            }}
                                          >
                                            <CardActionArea>
                                              <Stack
                                                direction="row"
                                                style={{ width: "100%", justifyContent: "space-between" }}
                                              >
                                                <CardContent
                                                  className="p-h"
                                                  style={{
                                                    width: "calc(100% - 40px)",
                                                  }}
                                                >
                                                  <Typography
                                                    level="h3"
                                                    component="h3"
                                                    fontSize="2rem"
                                                    fontWeight="600"
                                                    className="text-ellipsis"
                                                  >
                                                    {Utils.GetCurrency(
                                                      cashStat,
                                                      localSettings.country,
                                                      0
                                                    )}
                                                  </Typography>
                                                  <Typography
                                                    level="span"
                                                    component="span"
                                                    fontSize="1.3rem"
                                                  >
                                                    Cash
                                                  </Typography>
                                                </CardContent>
                                                <Box
                                                  className="flex-end"
                                                  component="img"
                                                  sx={{
                                                    height: 34,
                                                    width: 34,
                                                  }}
                                                  alt="Cash"
                                                  src={Cash_Icon}
                                                />
                                              </Stack>
                                            </CardActionArea>
                                          </Link>
                                        </Card>
                                      </Tooltip>
                                    </Grid>

                                    {/* UPI */}
                                    {isOtherPaymentsAvailable && (
                                      <Grid item xs={12} md={3} lg={3}>
                                        <Tooltip
                                          title={Utils.GetCurrency(
                                            upiStat,
                                            localSettings.country,
                                            0
                                          )}
                                          arrow
                                        >
                                          <Card className="card bl-0">
                                            <Link
                                              to={VisitsPage.Path}
                                              state={{
                                                startDT:
                                                  Utils.GetISODateTimeString(
                                                    selectedDate[0]
                                                  ),
                                                endDT: Utils.GetISODateTimeString(
                                                  selectedDate[1]
                                                ),
                                                paymentType:
                                                  PaymentMethodTypes.UPI,
                                                navigationStack: [
                                                  OverviewPage.Path,
                                                ],
                                              }}
                                            >
                                              <CardActionArea>
                                                <Stack
                                                  direction="row"
                                                  style={{ width: "100%", justifyContent: "space-between" }}
                                                >
                                                  <CardContent
                                                    className="p-h"
                                                    style={{
                                                      width: "calc(100% - 40px)",
                                                    }}
                                                  >
                                                    <Typography
                                                      level="h3"
                                                      component="h3"
                                                      fontSize="2rem"
                                                      fontWeight="600"
                                                      className="text-ellipsis"
                                                    >
                                                      {Utils.GetCurrency(
                                                        upiStat,
                                                        localSettings.country,
                                                        0
                                                      )}
                                                    </Typography>
                                                    <Typography
                                                      level="span"
                                                      component="span"
                                                      fontSize="1.3rem"
                                                    >
                                                      UPI
                                                    </Typography>
                                                  </CardContent>
                                                  <Box
                                                    className="flex-end"
                                                    component="img"
                                                    sx={{
                                                      height: 34,
                                                      width: 34,
                                                    }}
                                                    alt="UPI"
                                                    src={UPI_Icon}
                                                  />
                                                </Stack>
                                              </CardActionArea>
                                            </Link>
                                          </Card>
                                        </Tooltip>
                                      </Grid>
                                    )}

                                    {/* Net Banking */}
                                    {isOtherPaymentsAvailable && (
                                      <Grid item xs={12} md={3} lg={3}>
                                        <Tooltip
                                          title={Utils.GetCurrency(
                                            netbankingStat,
                                            localSettings.country,
                                            0
                                          )}
                                          arrow
                                        >
                                          <Card className="card">
                                            <Link
                                              to={VisitsPage.Path}
                                              state={{
                                                startDT:
                                                  Utils.GetISODateTimeString(
                                                    selectedDate[0]
                                                  ),
                                                endDT: Utils.GetISODateTimeString(
                                                  selectedDate[1]
                                                ),
                                                paymentType:
                                                  PaymentMethodTypes.NetBanking,
                                                navigationStack: [
                                                  OverviewPage.Path,
                                                ],
                                              }}
                                            >
                                              <CardActionArea>
                                                <Stack
                                                  direction="row"
                                                  style={{ width: "100%", justifyContent: "space-between" }}
                                                >
                                                  <CardContent
                                                    className="p-h"
                                                    style={{
                                                      width: "calc(100% - 40px)",
                                                    }}
                                                  >
                                                    <Typography
                                                      level="h3"
                                                      component="h3"
                                                      fontSize="2rem"
                                                      fontWeight="600"
                                                      className="text-ellipsis"
                                                    >
                                                      {Utils.GetCurrency(
                                                        netbankingStat,
                                                        localSettings.country,
                                                        0
                                                      )}
                                                    </Typography>
                                                    <Typography
                                                      level="span"
                                                      component="span"
                                                      fontSize="1.3rem"
                                                    >
                                                      Net Banking
                                                    </Typography>
                                                  </CardContent>
                                                  <Box
                                                    className="flex-end"
                                                    component="img"
                                                    sx={{
                                                      height: 34,
                                                      width: 34,
                                                    }}
                                                    alt="Net Banking"
                                                    src={Netbanking_Icon}
                                                  />
                                                </Stack>
                                              </CardActionArea>
                                            </Link>
                                          </Card>
                                        </Tooltip>
                                      </Grid>
                                    )}
                                    {/* Total Tip */}
                                    <Grid item xs={12} md={3} lg={3}>
                                      <Tooltip
                                        title={Utils.GetCurrency(
                                          tipsStat,
                                          localSettings.country,
                                          0
                                        )}
                                        arrow
                                      >
                                        <Card className="card">
                                          <CardActionArea disabled={true}>
                                            <Stack
                                              direction="row"
                                              style={{ width: "100%", justifyContent: "space-between" }}
                                            >
                                              <CardContent
                                                className="p-h"
                                                style={{ width: "calc(100% - 40px)" }}
                                              >
                                                <Typography
                                                  level="h3"
                                                  component="h3"
                                                  fontSize="2rem"
                                                  fontWeight="600"
                                                  className="text-ellipsis"
                                                >
                                                  {Utils.GetCurrency(
                                                    tipsStat,
                                                    localSettings.country,
                                                    0
                                                  )}
                                                </Typography>
                                                <Typography
                                                  level="span"
                                                  component="span"
                                                  fontSize="1.3rem"
                                                >
                                                  Total Tip
                                                </Typography>
                                              </CardContent>
                                              <Box
                                                className="flex-end"
                                                component="img"
                                                sx={{
                                                  height: 34,
                                                  width: 34,
                                                }}
                                                alt="Cash"
                                                src={Tip_Icon}
                                              />
                                            </Stack>
                                          </CardActionArea>
                                        </Card>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    {/* Chart */}
                    <Grid container mt={3} style={{ display: "none" }}>
                      <Stack
                        justifyContent="space-between"
                        direction="row"
                        style={{ width: "100%" }}
                        spacing={1}
                        className="xs-column m-xs-0"
                      >
                        <Grid xs={12} md={6} lg={6} className="card">
                          <Grid container mt={2} justifyContent="center">
                            <Typography
                              level="h4"
                              component="h4"
                              fontWeight="600"
                              fontSize="1.6rem"
                              className="h1"
                            >
                              Revenue
                            </Typography>
                          </Grid>
                          <Stack
                            justifyContent="center"
                            className="customer-chart"
                          >
                            <PieChart
                              series={[
                                {
                                  data: [
                                    {
                                      id: 0,
                                      value: productRevenue,
                                      label:
                                        BusinessHelper.GetSparesTitle(isSalon),
                                    },
                                    {
                                      id: 1,
                                      value: materialRevenueStat,
                                      label:
                                        BusinessHelper.GetConsumablesTitle(
                                          isSalon
                                        ),
                                    },
                                    {
                                      id: 2,
                                      value: serviceRevenueStat,
                                      label:
                                        BusinessHelper.GetServicesTitle(isSalon),
                                    },
                                  ],
                                  innerRadius: 30,
                                  outerRadius: 100,
                                  paddingAngle: 2,
                                  cornerRadius: 5,
                                },
                              ]}
                              width={400}
                              height={200}
                            />
                          </Stack>
                        </Grid>
                        {isAutomobile ? (
                          <Grid xs={12} md={6} lg={6} className="card">
                            <Grid container mt={2} justifyContent="center">
                              <Typography
                                level="h4"
                                component="h4"
                                fontWeight="600"
                                fontSize="1.6rem"
                                className="h1"
                              >
                                Job Cards
                              </Typography>
                            </Grid>
                            <Stack
                              justifyContent="center"
                              className="jobcard-chart"
                              style={{ "font-size": "10px" }}
                            >
                              {/* <LineChart
                              xAxis={[{ data: [1, 2, 3, 5, 8, 12] }]}
                              series={[
                                {
                                  data: [20, 55, 20, 35, 95, 110],
                                },
                              ]}
                              width={600}
                              height={300}
                            />
                            <Typography
                              component="p"
                              alignSelf="center"
                              marginTop="-20px"
                              paddingBottom="20px"
                            >
                              Months
                            </Typography> */}
                              <BarChart dataset={dataset} {...chartSetting} />
                            </Stack>
                          </Grid>
                        ) : (
                          <Grid
                            xs={12}
                            md={6}
                            lg={6}
                            className="card text-center"
                          >
                            <Link
                              to={BusinessSetupPage.Path}
                              mx={1}
                              style={{ "justify-content": "center" }}
                            >
                              <Box
                                component="img"
                                src={Salon_Services_Pic}
                                style={{ height: "300px" }}
                              />
                            </Link>
                          </Grid>
                        )}
                      </Stack>
                    </Grid>

                  </Grid>
                </Grid>

                {/* Payouts */}
                {showPayouts && (
                  <Grid container>
                    <Grid container mt={3}>
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="1.6rem"
                        className="h1"
                      >
                        Payouts
                      </Typography>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs>
                        <Card className="card">
                          <Link href="/Overview/">
                            <CardActionArea>
                              <CardContent className="p-h">
                                <Typography
                                  level="h3"
                                  component="h3"
                                  fontSize="2rem"
                                  fontWeight="600"
                                >
                                  {Utils.GetCurrency(
                                    payoutTotalStat - payoutSettledStat,
                                    localSettings.country,
                                    0
                                  )}
                                </Typography>
                                <Typography
                                  level="span"
                                  component="span"
                                  fontSize="1.3rem"
                                >
                                  Pending
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Link>
                        </Card>
                      </Grid>
                      <Grid item xs>
                        <Card className="card">
                          <Link href="/Overview/">
                            <CardActionArea>
                              <CardContent className="p-h">
                                <Typography
                                  level="h3"
                                  component="h3"
                                  fontSize="2rem"
                                  fontWeight="600"
                                >
                                  {Utils.GetCurrency(
                                    payoutSettledStat,
                                    localSettings.country,
                                    0
                                  )}
                                </Typography>
                                <Typography
                                  level="span"
                                  component="span"
                                  fontSize="1.3rem"
                                >
                                  Paid
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Link>
                        </Card>
                      </Grid>
                      <Grid item xs>
                        <Card className="card">
                          <Link href="/Overview/">
                            <CardActionArea>
                              <CardContent className="p-h">
                                <Typography
                                  level="h3"
                                  component="h3"
                                  fontSize="2rem"
                                  fontWeight="600"
                                >
                                  {Utils.GetCurrency(
                                    payoutTotalStat,
                                    localSettings.country,
                                    0
                                  )}
                                </Typography>
                                <Typography
                                  level="span"
                                  component="span"
                                  fontSize="1.3rem"
                                >
                                  Total
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Link>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid >
    </Container >
  );
};

export default Overview;


