import "../Style.css"; import "../Responsive.css";
import "../bootstrap.min.css";
import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Box,
  Grid,
  Stack,
  Typography,
  FormControl,
  Radio,
  Checkbox,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "./common/Loader";
import Utils from "../core/Utils";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Input from "@mui/material/Input";
import DeleteIcon from "@mui/icons-material/Delete";
import { ImageEnlarge } from "./common/ImageEnlarge";
import {
  getAuthentication,
  getBusiness,
  getCountry,
  setBusiness,
} from "../data/localSettingsActions";
import { postAsync } from "../core/serviceClient";
import MessagePopup from "./common/MessagePopUp";
import SideBarMenuItems from "./SideBarMenuItems";
import UserHelper from "../core/UserHelper";
import BusinessHelper from "../core/BusinessHelper";
import CountryHelper from "../core/CountryHelper";
import ConfirmPopup from "./common/ConfirmPopup";
import SettingsMenuItems from "./SettingsMenuItems";
import {
  SideBarMenuNames,
  SettingsMenuList,
  ConfirmTypes,
  BusinessCategories,
  USA,
  India,
  UAE,
  MaxFileSizeLimit,
} from "../core/Constants";
import { BusinessSetupPage, LoginPage } from "../core/PageConstants";

const BusinessSetup = () => {
  document.title = "Business Setup";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [business, setBusiness] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [confirmFor, setConfirmFor] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [alertTitle, setAlertTitle] = useState("");

  const [enlargeImage, setEnlargeImage] = useState(false);
  const [businessImageFile, setBusinessImageFile] = useState(business.logoUrl);
  const [isAcceptedTC, setIsAcceptedTC] = useState(false);
  const [taxIdLabel, setTaxIdLabel] = useState("");
  const [countryName, setCountryName] = useState("");
  const [categories, setCategories] = useState("");
  const [registrationId, setRegistrationId] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [distributorCode, setDistributorCode] = useState("");
  const [accountantName, setAccountantName] = useState("");
  const [accountantEmailId, setAccountantEmailId] = useState("");
  const [accountantMobileNumber, setAccountantMobileNumber] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [facebookURL, setFacebookURL] = useState("");
  const [twitterURL, setTwitterURL] = useState("");
  const [instaURL, setInstaURL] = useState("");
  const [youtubeURL, setYoutubeURL] = useState("");
  const [justDialURL, setJustDialURL] = useState("");
  const [googleReviewURL, setGoogleReviewURL] = useState("");

  useEffect(() => {
    if (UserHelper.CheckPermission(BusinessSetupPage, navigate)) {
      var business = getBusiness();
      var country = business.branchLocations[0].country;
      var isVisible = country == USA.ShortName ? false : true;
      var businessCategories = [
        {
          value: BusinessCategories.Automobile,
          isChecked: business.category == BusinessCategories.Automobile,
          isVisible: isVisible,
        },
        {
          value: BusinessCategories.Food,
          isChecked: business.category == BusinessCategories.Food,
          isVisible: !isVisible,
        },
        {
          value: BusinessCategories.Grocery,
          isChecked: business.category == BusinessCategories.Grocery,
          isChecked: false,
          isVisible: !isVisible,
        },
        {
          value: BusinessCategories.Salon,
          isChecked: business.category == BusinessCategories.Salon,
          isVisible: true,
        },
        {
          value: BusinessCategories.Other,
          isChecked: business.category == BusinessCategories.Other,
          isVisible: true,
        },
      ];
      let getCountryName = getCountry();
      let taxIdLbl = "Business Tax Id";
      if (getCountryName.ShortName === India.ShortName) taxIdLbl = "Business GSTIN";
      else if (getCountryName.ShortName === UAE.ShortName) taxIdLbl = "TRN No";
      setBusiness(business);
      setIsVisible(isVisible);
      setCountry(getCountryName.ShortName);
      setTaxIdLabel(taxIdLbl);
      setCountryName(getCountryName.Name);
      setCategories(businessCategories);
      setRegistrationId(business.registrationId);
      setStateCode(business.stateCode);
      setCompanyName(business.companyName);
      setWebsite(business.website);
      setDistributorCode(business.distributorCode);
      setAccountantName(business.accountantName);
      setAccountantEmailId(business.accountantEmailId);
      setAccountantMobileNumber(business.accountantMobileNumber);
      setLogoUrl(`${business.logoUrl}?${Date.now()}`);
      setFacebookURL(business.facebookUrl);
      setTwitterURL(business.twitterUrl);
      setInstaURL(business.instaUrl);
      setYoutubeURL(business.youtubeUrl);
      setJustDialURL(business.justDialUrl);
      setGoogleReviewURL(business.googleReviewUrl);
    }
  }, []);
  // const deleteBusinessClick = () => {
  //   setConfirmFor("Delete");
  //   setConfirmText("Are you sure you want to delete this business?");
  //   setConfirmOpen(true);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorMsg = "";
    if (Utils.IsNullOrEmpty(companyName)) {
      errorMsg += "Please enter the business name.<br />";
    }
    if (!Utils.IsNullOrEmpty(website)) {
      if (!Utils.IsURLValid(website))
        errorMsg = "Please enter the valid website address.<br />";
    }
    if (!Utils.IsNullOrEmpty(registrationId)) {
      if (!Utils.IsTaxIdValid(registrationId))
        errorMsg += "Please enter the valid" + taxIdLabel + ".<br />";
    }
    if (!isVisible && !Utils.IsNullOrEmpty(accountantMobileNumber)) {
      if (!Utils.IsMobileValid(accountantMobileNumber))
        errorMsg += "Please enter the valid accountant mobile number.<br />";
    }
    if (!isVisible && !Utils.IsNullOrEmpty(accountantEmailId)) {
      if (!Utils.IsEmailValid(accountantEmailId))
        errorMsg += "Please enter the valid accountant email.<br />";
    }
    if (!Utils.IsNullOrEmpty(facebookURL)) {
      if (!Utils.IsURLValid(facebookURL))
        errorMsg += "Please enter the valid facebook url.<br />";
    }
    if (!Utils.IsNullOrEmpty(instaURL)) {
      if (!Utils.IsURLValid(instaURL))
        errorMsg += "Please enter the valid instagram url.<br />";
    }
    if (!Utils.IsNullOrEmpty(twitterURL)) {
      if (!Utils.IsURLValid(twitterURL))
        errorMsg += "Please enter the valid twitter url.<br />";
    }
    if (!Utils.IsNullOrEmpty(youtubeURL)) {
      if (!Utils.IsURLValid(youtubeURL))
        errorMsg += "Please enter the valid youtube url.<br />";
    }
    if (!Utils.IsNullOrEmpty(justDialURL)) {
      if (!Utils.IsURLValid(justDialURL))
        errorMsg += "Please enter the valid justDial url.<br />";
    }
    if (!Utils.IsNullOrEmpty(googleReviewURL)) {
      if (!Utils.IsURLValid(googleReviewURL))
        errorMsg += "Please enter the valid google review url.<br />";
    }
    if (!Utils.IsNullOrEmpty(errorMsg)) {
      setMsgText(errorMsg);
      setMsgOpen(true);
    } else {
      setConfirmFor("Update");
      setConfirmText("Are you sure you want to save changes?");
      setConfirmOpen(true);
    }
  };

  async function deleteBusiness() {
    // var request = {
    // 	"Data": businessId,
    // 	"Operation" : "Execute",
    // };
    // let result = await fetch(Utils.GetServiceUrl()+"Business/DeleteBusiness", {
    //     method: 'POST',
    //     headers: {
    //         "Content-Type" : "application/json",
    //         "Accept" : "application/json",
    //     },
    //     body : JSON.stringify(request),
    // });
    // result = await result.json();
  }
  const getLogoUrl = () => {
    var temp = "";
    var newFile = false;
    if (logoUrl.startsWith("http")) {
      temp = logoUrl.split("?")[0];
    } else if (!Utils.IsNullOrEmpty(logoUrl)) {
      temp = Utils.GetStringFromBase64(logoUrl);
      newFile = true;
    }
    return { logo: temp, newFile: newFile };
  };

  async function updateBusiness() {
    setIsLoading(true);
    const imgObj = getLogoUrl();
    var newBusiness = {
      ...business,
      companyName: companyName,
      accountantName: accountantName,
      accountantEmailId: accountantEmailId,
      accountantMobileNumber: accountantMobileNumber,
      distributorCode: distributorCode,
      registrationId: registrationId,
      stateCode: stateCode,

      website: website,
      logoUrl: imgObj.logo,
      distributorCode: distributorCode,
      facebookUrl: facebookURL,
      instaUrl: instaURL,
      twitterUrl: twitterURL,
      youtubeUrl: youtubeURL,
      justDialUrl: justDialURL,
      googleReviewUrl: googleReviewURL,
    };
    let response = await postAsync(
      "Business/SaveBusiness",
      newBusiness,
      "",
      "Execute",
      "",
      [],
      imgObj.newFile
    );
    setIsNavigateToLogin(response.isNavigateToLogin);
    if (response.error) {
      setMsgOpen(true);
      setMsgText(response.errorMessage);
    } else if (response.data) {
      setBusiness(response.data, dispatch);
      setMsgText("Changes saved successfully.");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }
  const UploadBusinessImage = async (event) => {
    var file = event.target.files[0];
    setBusinessImageFile(file);
    if (file.size > MaxFileSizeLimit) {
      setAlertTitle("Warning");
      setMsgText(
        "Unable to upload. File size exceeds the maximum limit, please ensure that it's less than 10 MB."
      );
      setMsgOpen(true);
      return;
    }
    var base64 = await convertBase64(file);
    setLogoUrl(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const deleteBusinessPhoto = () => {
    setConfirmFor("DeletePhoto");
    setConfirmText("Deleted images cannot be restored. Are you sure?");
    setConfirmOpen(true);
  };
  const handleCloseConfirm = async (value) => {
    setConfirmOpen(false);
    switch (value) {
      case ConfirmTypes.OK:
      case ConfirmTypes.YES:
        setLogoUrl("");
        break;
    }
  };
  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleConfirmClose = (value) => {
    setConfirmOpen(false);

    if (value == ConfirmTypes.OK) {
      if (confirmFor == "Update") {
        updateBusiness();
      } else if (confirmFor == "Delete") {
        deleteBusiness();
      } else if (confirmFor == "DeletePhoto") {
        handleCloseConfirm(value);
      }
    }
  };
  const closeImage = () => {
    setEnlargeImage(false);
  };

  const openImage = () => {
    setEnlargeImage(true);
  };
  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
        />
        <ImageEnlarge open={enlargeImage} imgSrc={logoUrl} onClose={closeImage} />

        <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.BusinessSetup} />
            <Grid xs={12} className="content-sec">
              <Grid container direction="row" className="">
                <SettingsMenuItems
                  selectedMenu={SettingsMenuList.BusinessSettings.BusinessSetup}
                />
                <Grid xs={12} md={9}>
                  <Grid spacing={2} className="form-sec">
                    <Stack sx={{ minWidth: 120 }} spacing={2}
                      className="cs-form text-left">
                      <FormControl fullWidth>
                        <TextField
                          id="companyName"
                          label="Business Name"
                          variant="outlined"
                          error={!companyName}
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          placeholder="Enter business name"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="countryName"
                          label="Country"
                          variant="outlined"
                          value={countryName}
                          disabled
                        />
                      </FormControl>
                      {Utils.IsNullOrEmpty(business.id) && (
                        <FormControl fullWidth>
                          <Stack direction="row" alignItems={"center"}>
                            <Typography
                              level="h3"
                              component="h3"
                              fontWeight="600"
                              fontSize="1.5rem"
                              className="h3"
                            >
                              Category
                            </Typography>
                            {!Utils.IsNullOrEmptyArray(categories) &&
                              categories.map((item) => {
                                if (item.isVisible)
                                  return (
                                    <>
                                      <Radio disabled checked={item.isChecked} />
                                      <Typography
                                        level="h4"
                                        component="h4"
                                        fontSize="1.5rem"
                                        className="h4"
                                      >
                                        {item.value}
                                      </Typography>
                                    </>
                                  );
                              })}
                          </Stack>
                        </FormControl>
                      )}
                      {!BusinessHelper.IsSalon() && !country === India.ShortName && (
                        <FormControl fullWidth>
                          <TextField
                            id="registrationId"
                            label={taxIdLabel}
                            variant="outlined"
                            value={registrationId}
                            onChange={(e) => setRegistrationId(e.target.value)}
                            placeholder="Enter id"
                          />
                        </FormControl>
                      )}
                      {!BusinessHelper.IsSalon() &&
                        country === India.ShortName && (
                          <FormControl fullWidth>
                            <TextField
                              id="GSTNStateCode"
                              label="GSTN State Code"
                              variant="outlined"
                              value={stateCode}
                              onChange={(e) => setStateCode(e.target.value)}
                              placeholder="Enter code"
                            />
                          </FormControl>
                        )}
                      {!isVisible && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              id="accountantName"
                              label="Accountant Name"
                              variant="outlined"
                              value={accountantName}
                              onChange={(e) => setAccountantName(e.target.value)}
                              placeholder="Enter name"
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              id="accountantMobile"
                              label="Accountant Mobile Number"
                              variant="outlined"
                              inputProps={{ maxLength: CountryHelper.GetMobileNumberMaxLength() }}
                              value={accountantMobileNumber}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value !== "" && !/[0-9]/.test(value)) {
                                  return;
                                }
                                setAccountantMobileNumber(value);
                              }}
                              placeholder="Enter mobile number"
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              id="accountantEmail"
                              label="Accountant Email"
                              variant="outlined"
                              value={accountantEmailId}
                              onChange={(e) =>
                                setAccountantEmailId(e.target.value)
                              }
                              placeholder="Enter email"
                            />
                          </FormControl>
                        </>
                      )}
                      <Stack direction="row" spacing={2}>
                        <FormControl fullWidth>
                          <TextField
                            id="website"
                            label="Website Address"
                            variant="outlined"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                            placeholder="Enter website address"
                          />
                        </FormControl>
                      </Stack>
                      <Typography
                        level="h3"
                        component="h3"
                        fontWeight="600"
                        fontSize="1.5rem"
                        className="h3"
                      >
                        Logo For Business
                      </Typography>
                      {Utils.IsNullOrEmpty(logoUrl) && (
                        <Stack direction="row">
                          <label>
                            <AddAPhotoIcon fontSize="large" />
                            <Input
                              type="file"
                              name="myImage"
                              className="hide"
                              inputProps={{
                                accept:
                                  "image/png, image/jpeg, image/ico, image/jpg",
                              }}
                              onChange={(e) => UploadBusinessImage(e)}
                            />
                          </label>
                        </Stack>
                      )}

                      {!Utils.IsNullOrEmpty(logoUrl) && (
                        <Grid className="card imgBox">
                          <img
                            alt="not found"
                            width={"150px"}
                            src={logoUrl}
                            onClick={openImage}
                          />
                          <br />
                          <button
                            className="p-0 mt-1 btn btn-default"
                            onClick={deleteBusinessPhoto}
                          >
                            <DeleteIcon />
                          </button>
                        </Grid>
                      )}

                      {isVisible && (
                        <FormControl fullWidth>
                          <TextField
                            id="distributorCode"
                            label="Distributor Code"
                            variant="outlined"
                            value={distributorCode}
                            disabled
                            onChange={(e) => setDistributorCode(e.target.value)}
                          />
                        </FormControl>
                      )}
                      <FormControl fullWidth>
                        <TextField
                          id="facebookURL"
                          label="Facebook URL"
                          variant="outlined"
                          value={facebookURL}
                          onChange={(e) => setFacebookURL(e.target.value)}
                          placeholder="Enter url"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="instagramURL"
                          label="Instagram URL"
                          variant="outlined"
                          value={instaURL}
                          onChange={(e) => setInstaURL(e.target.value)}
                          placeholder="Enter url"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="twitterURL"
                          label="Twitter URL"
                          variant="outlined"
                          value={twitterURL}
                          onChange={(e) => setTwitterURL(e.target.value)}
                          placeholder="Enter url"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="youtubeURL"
                          label="Youtube URL"
                          variant="outlined"
                          value={youtubeURL}
                          onChange={(e) => setYoutubeURL(e.target.value)}
                          placeholder="Enter url"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="justDialURL"
                          label="JustDial URL"
                          variant="outlined"
                          value={justDialURL}
                          onChange={(e) => setJustDialURL(e.target.value)}
                          placeholder="Enter url"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="googleReviewURL"
                          label="Google Review URL"
                          variant="outlined"
                          value={googleReviewURL}
                          onChange={(e) => setGoogleReviewURL(e.target.value)}
                          placeholder="Enter url"
                        />
                      </FormControl>

                      <Stack
                        direction="row"
                        className="fixed-btn"
                      >
                        <Grid className="container"
                          justifyContent="end"
                        >
                          <Stack direction="row" justifyContent="end">
                            <Stack direction="row" alignItems={"center"} className="mr-2 font-size-14">
                              <Checkbox
                                checked={isAcceptedTC}
                                onChange={(e) => setIsAcceptedTC(e.target.checked)}
                              />
                              <Link
                                to={CountryHelper.GetTermsAndConditionUrl()}
                                target="_blank"
                              >
                                <Typography
                                  level="p"
                                  component="p"
                                >
                                  I accept the terms and conditions
                                </Typography>
                              </Link>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={2}
                              justifyContent="flex-end"
                              alignSelf="end"
                            >
                              {/* <FormControl>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => deleteBusinessClick()}
                                  >
                                    Delete
                                  </Button>
                                </FormControl> */}
                              <FormControl>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  className="btn btn-primary"
                                  onClick={handleSubmit}
                                  disabled={!isAcceptedTC}
                                >
                                  Save
                                </Button>
                              </FormControl>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default BusinessSetup;
