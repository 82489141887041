import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Stack,
  TextField,
  Autocomplete,
  Tooltip,
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  InputAdornment,
  IconButton,
  Chip
} from "@mui/material";
import { loadPackagesFromServer, loadProductsFromServer } from "../../core/serviceClient";
import { getCountry } from "../../data/localSettingsActions";
import Utils from "../../core/Utils";
import ClearIcon from "@mui/icons-material/Clear";

const AddProduct = ({ onAddItem, fromServiceSetup = false, isFromAddNewPackage = false }) => {
  const [allProducts, setAllProducts] = useState([]);
  const [countryInfo, setCountryInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [typeList, setTypeList] = useState([]);
  const [overAllTypeList, setOverAllTypeList] = useState([]);
  const [catList, setcatList] = useState([]);
  const [servicecategory, setservicecategory] = useState("");
  const [serviceType, setserviceType] = useState("");
  const [mainProductList, setMainProductList] = useState([]);

  useEffect(() => {
    loadProducts();
  }, []);

  const loadPackageDetails = async () => {
    let _result = await loadPackagesFromServer();
    var data = _result.data;
    if (!Utils.IsNullOrEmptyArray(data)) {
      data = data.filter(
        (c) =>
          c.active === true
      );
    }
    return data;
  }
  const loadProducts = async () => {
    setCountryInfo(getCountry());
    let result = await loadProductsFromServer();
    // var _package = await loadPackageDetails();
    var _package = "";
    var data = result.data
    var products = [];
    if (!Utils.IsNullOrEmptyArray(data)) {
      if (!Utils.IsNullOrEmptyArray(_package)) {
        data = [...data, ..._package]
      }
      data = data.filter(
        (c) =>
          c.active === true
      );
      let temTypellist = [];
      let temCatllist = [];
      data.forEach((element) => {
        if (
          !Utils.IsNullOrEmpty(element.class) &&
          !temCatllist.includes(element.class)
        ) {
          temCatllist.push(element.class);
        }
        if (element.isMaterial == true || element.service == true &&
          !Utils.IsNullOrEmpty(element.fineline) &&
          !temTypellist.includes(element.fineline)
        ) {
          temTypellist.push(element.fineline);
        }
        if (
          !Utils.IsNullOrEmpty(element.department) &&
          !temTypellist.includes(element.department) &&
          !element.service && !element.isMaterial
        ) {
          temTypellist.push(element.department);
        }
      });
      temTypellist = temTypellist.filter((item) => (item?.trim() !== "" || !item));
      temCatllist = temCatllist.filter((item) => (item?.trim() !== "" || !item));

      setTypeList(temTypellist);
      setOverAllTypeList(temTypellist);
      setcatList(temCatllist);
      if (fromServiceSetup === true && !isFromAddNewPackage) {
        data = data.filter((c) =>
          c.active === true && c.service == false && c.isMaterial == false);
      }
      if (isFromAddNewPackage) {
        data = data.filter((c) =>
          c.active === true && ((c.service == true && c.isMaterial == false) || (c.service == false && c.isMaterial == false)));
      }
    }
    if (!Utils.IsNullOrEmptyArray(data)) {
      setMainProductList(data);
      data.forEach((item) => {
        var stringItem = JSON.stringify(item);
        products.push(stringItem);
      });
    }
    setAllProducts(products);
  };

  const handleFilter = (value, type) => {
    if (!Utils.IsNullOrEmpty(value) || !Utils.IsNullOrEmpty(type)) {
      var products = [];
      if (value === "" && type === "category" && serviceType === "") {
        mainProductList.forEach((item) => {
          var stringItem = JSON.stringify(item);
          products.push(stringItem);
        });
        setAllProducts(products);
        setTypeList(overAllTypeList);
        return
      }
      if (value === "" && type === "serviceType" && servicecategory === "") {
        mainProductList.forEach((item) => {
          var stringItem = JSON.stringify(item);
          products.push(stringItem);
        });
        setAllProducts(products);
        setTypeList(overAllTypeList);
        return
      }
      if (type === "category" && value !== "" && !serviceType) {
        getFilterTypes(value);
      }
      // class - servicecategory and fineline - serviceType
      let filterdone = mainProductList.filter((item) => {
        if (type === "serviceType" && value !== "" && servicecategory && !item.isMaterial && !item.service && !item.collection === "PackageProduct") {
          return item.class == servicecategory && item.department == value;
        } else if (type === "serviceType" && value !== "" && servicecategory && (item.isMaterial || item.service) || item.collection === "PackageProduct") {
          return item.class == servicecategory && item.fineline == value
        } else if (type === "serviceType" && value !== "" && servicecategory && !item.isMaterial && !item.service) {
          return item.class == servicecategory && item.department == value;
        } else if (type === "serviceType" && value !== "" && servicecategory && (item.isMaterial || item.service)) {
          return item.class == servicecategory && item.fineline == value
        } else if (type === "category" && value !== "" && serviceType) {
          return item.calss == value && item.department == serviceType
        } else if (type === "category" && value === "" && serviceType) {
          return item.department == serviceType
        } else if (type === "serviceType" && value === "" && servicecategory) {
          return item.class == servicecategory;
        } else if (type === "category" && value !== "" && !serviceType) {
          return item.class == value;
        } else if (type === "serviceType" && value !== "" && !servicecategory && !item.isMaterial && !item.service) {
          return item.department == value;
        } else if (type === "serviceType" && value !== "" && !servicecategory && (item.isMaterial || item.service)) {
          return item.fineline == value;
        }
      });
      if (!Utils.IsNullOrEmptyArray(filterdone)) {
        filterdone.forEach((item) => {
          var stringItem = JSON.stringify(item);
          products.push(stringItem);
        });
      }
      setAllProducts(products);
    }
  }

  const getFilterTypes = (categoryValue) => {
    let newArr = [];
    mainProductList.map((product) => {
      if (product.class === categoryValue && product.department && !product.isMaterial && !product.service) {
        newArr.push(product.department);
      }
      if (product.class === categoryValue && product.fineline && (product.isMaterial || product.service || product.collection === "PackageProduct")) {
        newArr.push(product.fineline);
      }
    })
    const uniqueNewArr = newArr.filter(
      (item, index, self) => index === self.findIndex((obj) => obj.id === item.id)
    );
    setTypeList(uniqueNewArr);
  }

  const filterProducts = (options, state) => {
    if (Utils.IsNullOrEmpty(state.inputValue)) {
      return options;
    }
    var upperCaseSearchText = state.inputValue.trim().toUpperCase();
    var parsedOptions = [];
    options.forEach((item) => {
      var parsedItem = JSON.parse(item);
      parsedOptions.push(parsedItem);
    });
    var newFilteredProducts = parsedOptions.filter(
      (p) =>
        Utils.ConvertToUpperCase(p.code).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.description).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.shortDesc).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.class).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.classDescription).includes(
          upperCaseSearchText
        ) ||
        Utils.ConvertToUpperCase(p.manufacturer).includes(
          upperCaseSearchText
        ) ||
        Utils.ConvertToUpperCase(p.department).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.departmentDescription).includes(
          upperCaseSearchText
        ) ||
        Utils.ConvertToUpperCase(p.fineline).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.finelineDescription).includes(
          upperCaseSearchText
        ) ||
        Utils.ConvertToUpperCase(p.sku).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.upc).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.vendor).includes(upperCaseSearchText)
    );
    var newProducts = [];
    if (!Utils.IsNullOrEmptyArray(newFilteredProducts)) {
      newFilteredProducts.forEach((item) => {
        newProducts.push(JSON.stringify(item));
      });
    }
    return newProducts;
  };

  const addItemToCart = (item) => {
    if (!Utils.IsNullOrEmpty(item)) {
      setSearchText("");
      onAddItem(JSON.parse(item));
    }
  };

  return (
    <Grid xs={12} style={{ padding: "0" }} className="productsListSec">
      <Grid spacing={1} container direction="row" className="form-sec p-0">
        <Grid item xs={2} className="hidden-xs">
          <FormControl fullWidth className="font-size-14">
            <InputLabel
              id="demo-single-name-label"
            >
              Category
            </InputLabel>
            <Select
              label="Category"
              labelId="demo-single-name-label"
              id="demo-single-name"
              value={servicecategory}
              title={servicecategory}
              onChange={(e) => {
                setservicecategory(e.target.value)
                handleFilter(e.target.value, "category");
              }}
              endAdornment={
                servicecategory && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setservicecategory("");
                        handleFilter("", "category");
                      }}
                      edge="end"
                      size="small"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }
              sx={{
                fontSize: "1.2rem",
              }}
            >
              {!Utils.IsNullOrEmptyArray(catList) &&
                catList.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} className="hidden-xs">
          <FormControl fullWidth className="font-size-14">
            <InputLabel
              id="demo-single-name-label"
            >
              Type
            </InputLabel>
            <Select
              label="Type"
              labelId="demo-single-name-label"
              id="demo-single-name"
              value={serviceType}
              title={serviceType}
              onChange={(e) => {
                setserviceType(e.target.value)
                handleFilter(e.target.value, "serviceType");
              }}
              sx={{
                fontSize: "1.2rem",
              }}
              endAdornment={
                serviceType && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setserviceType("");
                        handleFilter("", "serviceType");
                      }}
                      edge="end"
                      size="small"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }
            >
              {!Utils.IsNullOrEmptyArray(catList) &&
                typeList.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <Autocomplete
            className="prod-input"
            disablePortal
            value={searchText}
            onChange={(event, newValue) => {
              addItemToCart(newValue);
            }}
            inputValue={searchText}
            onInputChange={(event, newInputValue) => {
              setSearchText(newInputValue);
            }}
            options={allProducts}
            filterOptions={filterProducts}
            fullWidth
            disableCloseOnSelect
            renderOption={(props, item) => (
              <li style={{ width: "100%", borderBottom: "1px solid #e3e3e3" }} {...props}>
                <Box
                  sx={{ "&:": { mr: 2, flexShrink: 0 } }}
                  style={{ overflow: "visible", width: "100%" }}
                  className="prod-wrap"
                >
                  <Tooltip
                    placement="top"
                    title={
                      Utils.IsNullOrEmpty(JSON.parse(item).class) &&
                        Utils.IsNullOrEmpty(JSON.parse(item).department) ? (
                        ""
                      ) : (
                        <React.Fragment>
                          <Stack direction="column">
                            {!Utils.IsNullOrEmpty(JSON.parse(item).class) && (
                              <Typography>{JSON.parse(item).class}</Typography>
                            )}
                            {!Utils.IsNullOrEmpty(JSON.parse(item).department) && (
                              <Typography>{JSON.parse(item).department}</Typography>
                            )}
                          </Stack>
                        </React.Fragment>
                      )
                    }
                    arrow
                  >
                    <Stack
                      paddingLeft={2}
                      paddingRight={2}
                      justifyContent="space-between"
                      width="100%"
                      className="prod-item"
                    >
                      <Chip label={JSON.parse(item).code} sx={{ alignSelf: "start" }} />
                      <Grid container xs={12}>
                        <Grid xs={12} lg={8}>
                          <Typography
                            padding="6px 0 0px 0"
                            level="h6"
                            component="h6"
                            fontWeight="600"
                            fontSize="16px"
                            className="text-left"
                          >
                            {JSON.parse(item).shortDesc}
                          </Typography>
                          <Typography
                            level="h6"
                            component="h6"
                            fontWeight="500"
                            fontSize="12px"
                            className="text-left"
                          >
                            {JSON.parse(item).description}
                          </Typography>
                        </Grid>
                        <Grid xs={6} lg={2}>
                          <Typography
                            padding="10px 0 0px 0"
                            level="h6"
                            component="h6"
                            fontWeight="600"
                            fontSize="14px"
                            className="text-right text-xs-left"
                          >
                            {Utils.GetCurrency(JSON.parse(item).price, countryInfo)}
                          </Typography>
                        </Grid>
                        <Grid xs={6} lg={2}>
                          <Typography
                            padding="10px 0 0px 0"
                            level="h6"
                            component="h6"
                            fontWeight="600"
                            fontSize="14px"
                            className="text-right"
                            justifyContent="flex-end"
                          >
                            {JSON.parse(item).taxPercent.toFixed(2) + "%"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Tooltip>
                </Box>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add item &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
                className="lbl-text add-prod-box"
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddProduct;
