import React, { useState, useEffect } from "react";
import "../../Style.css";
import "../../Brand.css";
import {
  Button,
  Container,
  Grid,
  Box,
  Stack,
  Typography,
  Avatar,
  Link,
  Tabs,
  Tab,
  Card,
  Checkbox,
  IconButton,
  TextField,
  FormControl
} from "@mui/material";
import PropTypes from 'prop-types';
import Infopoint_Logo from "../../images/Infopoint.png";
import up_arrow from "../../images/up-arrow.png";
import EventIcon from '@mui/icons-material/Event';
import PlaceIcon from '@mui/icons-material/Place';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// Modal
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Stack spacing={2}>{children}</Stack>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BrandHome = () => {
  document.title = "Brand Home";
  var body = document.body;
  body.classList.add("brandHome");

  const [serviceValue, setServiceValue] = React.useState(0);
  const [timeValue, setTimeValue] = React.useState(0);
  const [checked, setChecked] = React.useState(true);
  const [showServies, setShowServies] = React.useState(true);
  const [showTime, setShowTime] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [showOTP, setShowOTP] = React.useState(false);
  const [showMobileNo, setShowMobileNo] = React.useState(false);
  const [isConfirmBooking, setIsConfirmBooking] = React.useState(false);

  const handleServiceTabsChange = (event, newValue) => {
    setServiceValue(newValue);
  };

  const handleTimeTabsChange = (event, newValue) => {
    setTimeValue(newValue);
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleShowTime = (event) => {
    setShowServies(false);
    setShowTime(true);
  };

  const handleShowServices = (event) => {
    setShowServies(true);
    setShowTime(false);
  };

  const handleClickOpenModal = () => {
    setOpenModal(true);
    setShowMobileNo(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setShowOTP(false);
    setIsConfirmBooking(false);
  };

  const handleOpenOTPModal = () => {
    setShowMobileNo(false);
    setShowOTP(true);
  };

  const handleConfirmBookingModal = () => {
    setIsConfirmBooking(true);
    setShowOTP(false);

    body.classList.add("bookingConfirmed");

    setTimeout(function () {
      setOpenModal(false);
      setShowOTP(false);
      setIsConfirmBooking(false);
    }.bind(this), 3000);
  };

  return (
    <Container maxWidth="false" sx={{ paddingBottom: "8rem" }}>

      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseModal}
        aria-describedby="alert-dialog-slide-description"
        className="booknow-modal"
      >
        {showOTP && (
          <>
            <DialogTitle
              fontSize="2.4rem"
              fontWeight="700">
              {"OTP Verification"}
              <Typography fontSize="16px">Enter the 6 digit code received your mobile</Typography>
              <IconButton onClick={handleCloseModal}
                title="Close"
                sx={{ position: "absolute", right: "10px", top: "10px" }}>
                <CloseOutlinedIcon sx={{ fontSize: "24px" }} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <FormControl fullWidth className="otp-field">
                  <TextField
                    name=""
                    placeholder="000000"
                    inputProps={{ maxLength: 6 }} />
                </FormControl>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className="submitBtn cs-btn"
                fullWidth="true"
                onClick={handleConfirmBookingModal}
              >
                Confirm
              </Button>
            </DialogActions>
          </>
        )}

        {showMobileNo && (
          <>
            <DialogTitle
              fontSize="2.4rem"
              fontWeight="700">
              <Typography fontSize="16px">Sign up with your</Typography>
              {"Mobile Number"}
              <IconButton onClick={handleCloseModal}
                title="Close"
                sx={{ position: "absolute", right: "10px", top: "10px" }}>
                <CloseOutlinedIcon sx={{ fontSize: "24px" }} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <FormControl fullWidth>
                  <TextField
                    name=""
                    placeholder="+919876543210" />
                </FormControl>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className="submitBtn cs-btn"
                fullWidth="true"
                onClick={handleOpenOTPModal}
              >
                Get OTP
              </Button>
            </DialogActions>
          </>
        )}

        {
          isConfirmBooking && (
            <Box className="confirmed-box" id="confirmBox">
              <DialogTitle>
                <IconButton onClick={handleCloseModal}
                  title="Close"
                  sx={{ position: "absolute", right: "10px", top: "10px" }}>
                  <CloseOutlinedIcon sx={{ fontSize: "24px" }} />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="text-center" sx={{ color: "#000000" }}>
                  <VerifiedIcon sx={{ fontSize: "8rem", color: "rgb(76 175 80)" }} />
                  <Typography fontSize="20px">Appointment</Typography>
                  <Typography
                    component="h3"
                    fontSize="4em"
                    fontWeight="700">confirmed!
                  </Typography>
                </DialogContentText>
              </DialogContent>
            </Box>
          )
        }
      </Dialog>

      <Grid container xs={12} className="container my-1 mx-2">
        <Grid xs={6} alignSelf="Center">
          <Stack className="logo-sec" direction="row">
            <Box component="img" src={Infopoint_Logo} />
            <h1>Infopoint</h1>
          </Stack>
        </Grid>
        <Grid xs={6} alignSelf="Center" className="text-right">
          <Button className="btn-primary link-btn" sx={{ height: "auto" }}><a href="https://infopoint.com/" target="_blank" alignSelf="Center">Visit Site</a></Button>
        </Grid>
      </Grid>

      <Stack className="login-sec">
        <Grid className="login-bg container-fluid" sx={{ backgroundImage: "url(https://www.elieladysalon.com/assets/img/gtt.jpg)" }}>
          <Grid xs={12} className="container my-1 mx-2" alignSelf="end">
            <Grid container>
              <Grid xs={12} sm={6} md={6} alignSelf="End" className="login-title">
                <h1 className="h1 text-white">Stay connected with <br /> your business always!</h1>
                <p className="mt-3">
                  <a href="https://infopoint.com/" target="_blank" className="white-text">Explore more <Box component="img" sx={{ height: 16, width: 16, }} style={{ cursor: "pointer" }} alt="Contact us" src={up_arrow} /></a>
                </p>
              </Grid>
              <Grid xs={12} sm={6} md={6} className="login-form">
                <form>
                  <div className="lg-sec form-sec">
                  </div>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Stack>

      <Grid container xs={12} className="container" my={3}>
        <Stack direction="row" spacing={3} justifyContent="space-between" width="100%">
          <Stack direction="row" spacing={3}>
            <Avatar sx={{ width: "120px", height: "120px", padding: "4px" }}>
              {/* <Box className="brandLogo">H</Box> */}
              <Box className="brandLogo" sx={{ "background-image": "url(https://elieladysalon.com/assets/img/Frame5.png)", "background-color": "#000", "filter": "invert(1)" }}></Box>
            </Avatar>
            <Stack alignSelf="center">
              <Typography
                component="h3"
                fontSize="4rem"
                fontWeight="800">
                Elie Lady Salon
              </Typography>
              <Typography fontSize="16px">
                <EventIcon sx={{ fontSize: "18px" }} alignSelf="center" /> &nbsp; Saturday 10:00 am - 11:30 pm  to  Sunday 10:00 am - 11:30 pm
              </Typography>
              <Typography fontSize="16px">
                <PlaceIcon sx={{ fontSize: "18px" }} alignSelf="center" /> &nbsp; Ecil - 9th St - Deira - Riggat Al Buteen - Dubai - United Arab Emirates
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={3} alignSelf="center">
            <Link href="#" target="_blank" alignSelf="end">
              <ShareOutlinedIcon sx={{ fontSize: "32px" }} />
            </Link>
            <Button
              variant="outlined"
              endIcon={<ExpandMoreOutlinedIcon />}
              sx={{ fontSize: "16px" }}>
              Details
            </Button>
          </Stack>
        </Stack>

        <Grid container mt={6} spacing={4}>
          {
            showTime && (
              <Grid item xs={12} sm={6} md={6}>
                <Stack direction="row" spacing={1}>
                  <IconButton
                    sx={{ alignItems: "center", alignSelf: "center" }}
                    title="Go back to select services"
                    onClick={handleShowServices}>
                    <ArrowBackIosIcon sx={{ fontSize: "24px" }} />
                  </IconButton>
                  <Typography
                    component="h3"
                    fontSize="3rem"
                    fontWeight="800">
                    Select Time
                  </Typography>
                </Stack>
                <Stack spacing={2} sx={{ width: '100%' }} mt={2}>
                  <Box>
                    <Typography
                      component="h3"
                      fontSize="2rem"
                      fontWeight="600"
                      sx={{ margin: "20px 0 0 0" }}>
                      March 2025
                    </Typography>
                  </Box>
                  <Box sx={{ width: "660px" }}>
                    <Tabs
                      className="cs-tabs"
                      sx={{ paddingRight: "40px" }}
                      value={timeValue}
                      onChange={handleTimeTabsChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example">
                      <Tab label={<div><h4>25</h4> <span>Tue</span></div>} {...a11yProps(0)} />
                      <Tab label={<div><h4>26</h4> <span>Wed</span></div>} {...a11yProps(1)} />
                      <Tab label={<div><h4>27</h4> <span>Thu</span></div>} {...a11yProps(2)} />
                      <Tab label={<div><h4>28</h4> <span>Fri</span></div>} {...a11yProps(3)} />
                      <Tab label={<div><h4>29</h4> <span>Sat</span></div>} {...a11yProps(4)} />
                      <Tab label={<div><h4>30</h4> <span>Sun</span></div>} {...a11yProps(5)} />
                      <Tab label={<div><h4>21</h4> <span>Mon</span></div>} {...a11yProps(6)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={timeValue} index={0}>
                    <Card className="card">
                      <Stack padding={2} paddingRight="70px">
                        <label>
                          <Typography
                            component="h3"
                            fontSize="2.2rem"
                            fontWeight="700">
                            2:30pm
                          </Typography>
                          <Box className="select-service">
                            <input type="checkbox" checked />
                          </Box>
                        </label>
                      </Stack>
                    </Card>
                    <Card className="card">
                      <Stack padding={2} paddingRight="70px">
                        <label>
                          <Typography
                            component="h3"
                            fontSize="2.2rem"
                            fontWeight="700">
                            3:00pm
                          </Typography>
                          <Box className="select-service">
                            <input type="checkbox" />
                          </Box>
                        </label>
                      </Stack>
                    </Card>
                    <Card className="card">
                      <Stack padding={2} paddingRight="70px">
                        <label>
                          <Typography
                            component="h3"
                            fontSize="2.2rem"
                            fontWeight="700">
                            3:30pm
                          </Typography>
                          <Box className="select-service">
                            <input type="checkbox" />
                          </Box>
                        </label>
                      </Stack>
                    </Card>
                    <Card className="card">
                      <Stack padding={2} paddingRight="70px">
                        <label>
                          <Typography
                            component="h3"
                            fontSize="2.2rem"
                            fontWeight="700">
                            4:00pm
                          </Typography>
                          <Box className="select-service">
                            <input type="checkbox" />
                          </Box>
                        </label>
                      </Stack>
                    </Card>
                    <Card className="card">
                      <Stack padding={2} paddingRight="70px">
                        <label>
                          <Typography
                            component="h3"
                            fontSize="2.2rem"
                            fontWeight="700">
                            4:30pm
                          </Typography>
                          <Box className="select-service">
                            <input type="checkbox" />
                          </Box>
                        </label>
                      </Stack>
                    </Card>
                    <Card className="card">
                      <Stack padding={2} paddingRight="70px">
                        <label>
                          <Typography
                            component="h3"
                            fontSize="2.2rem"
                            fontWeight="700">
                            5:00pm
                          </Typography>
                          <Box className="select-service">
                            <input type="checkbox" />
                          </Box>
                        </label>
                      </Stack>
                    </Card>
                  </CustomTabPanel>
                  <CustomTabPanel value={timeValue} index={1}>
                    Wed times
                  </CustomTabPanel>
                  <CustomTabPanel value={timeValue} index={2}>
                    Thu times
                  </CustomTabPanel>
                  <CustomTabPanel value={timeValue} index={3}>
                    Fri times
                  </CustomTabPanel>
                  <CustomTabPanel value={timeValue} index={4}>
                    Sat times
                  </CustomTabPanel>
                  <CustomTabPanel value={timeValue} index={5}>
                    Sun times
                  </CustomTabPanel>
                  <CustomTabPanel value={timeValue} index={6}>
                    Mon times
                  </CustomTabPanel>
                </Stack>
              </Grid>
            )
          }
          {
            showServies && (
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  component="h3"
                  fontSize="3rem"
                  fontWeight="800">
                  Select Services
                </Typography>
                <Box sx={{ width: '100%' }} mt={2}>
                  <Box sx={{ width: "660px", marginBottom: "16px" }}>
                    <Tabs
                      className="cs-tabs"
                      sx={{ paddingRight: "40px" }}
                      value={serviceValue}
                      onChange={handleServiceTabsChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example">
                      <Tab label="Hair & styling" {...a11yProps(0)} />
                      <Tab label="Eyebrows & eyelashes" {...a11yProps(1)} />
                      <Tab label="Hair removal" {...a11yProps(2)} />
                      <Tab label="Exclusive women" {...a11yProps(3)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={serviceValue} index={0}>
                    <Card className="card">
                      <Stack padding={2} paddingRight="70px">
                        <label>
                          <Typography
                            component="h3"
                            fontSize="2.2rem"
                            fontWeight="700">
                            Hair Color Root touch up
                          </Typography>
                          <Typography
                            fontSize="1.4rem">
                            1 hr, 15 mins
                          </Typography>
                          <Typography
                            component="h3"
                            fontSize="1.6rem"
                            fontWeight="700"
                            className="mt-2">
                            from ₹2,000
                          </Typography>
                          <Box className="select-service">
                            <input type="checkbox" checked />
                          </Box>
                        </label>
                      </Stack>
                    </Card>
                    <Card className="card">
                      <Stack padding={2} paddingRight="70px">
                        <label>
                          <Typography
                            component="h3"
                            fontSize="2.2rem"
                            fontWeight="700">
                            Blow Dry (Straight)
                          </Typography>
                          <Typography
                            fontSize="1.4rem">
                            45 mins
                          </Typography>
                          <Typography
                            component="h3"
                            fontSize="1.6rem"
                            fontWeight="700"
                            className="mt-2">
                            from ₹550
                          </Typography>
                          <Box className="select-service">
                            <input type="checkbox" checked />
                          </Box>
                        </label>
                      </Stack>
                    </Card>
                    <Card className="card">
                      <Stack padding={2} paddingRight="70px">
                        <label>
                          <Typography
                            component="h3"
                            fontSize="2.2rem"
                            fontWeight="700">
                            Senior stylist
                          </Typography>
                          <Typography
                            fontSize="1.4rem">
                            45 mins
                          </Typography>
                          <Typography
                            component="h3"
                            fontSize="1.6rem"
                            fontWeight="700"
                            className="mt-2">
                            from ₹550
                          </Typography>
                          <Box className="select-service">
                            <input type="checkbox" />
                          </Box>
                        </label>
                      </Stack>
                    </Card>
                    <Card className="card">
                      <Stack padding={2} paddingRight="70px">
                        <label>
                          <Typography
                            component="h3"
                            fontSize="2.2rem"
                            fontWeight="700">
                            Senior stylist
                          </Typography>
                          <Typography
                            fontSize="1.4rem">
                            45 mins
                          </Typography>
                          <Typography
                            component="h3"
                            fontSize="1.6rem"
                            fontWeight="700"
                            className="mt-2">
                            from ₹550
                          </Typography>
                          <Box className="select-service">
                            <input type="checkbox" />
                          </Box>
                        </label>
                      </Stack>
                    </Card>
                    <Card className="card">
                      <Stack padding={2} paddingRight="70px">
                        <label>
                          <Typography
                            component="h3"
                            fontSize="2.2rem"
                            fontWeight="700">
                            Senior stylist
                          </Typography>
                          <Typography
                            fontSize="1.4rem">
                            45 mins
                          </Typography>
                          <Typography
                            component="h3"
                            fontSize="1.6rem"
                            fontWeight="700"
                            className="mt-2">
                            from ₹550
                          </Typography>
                          <Box className="select-service">
                            <input type="checkbox" />
                          </Box>
                        </label>
                      </Stack>
                    </Card>
                    <Card className="card">
                      <Stack padding={2} paddingRight="70px">
                        <label>
                          <Typography
                            component="h3"
                            fontSize="2.2rem"
                            fontWeight="700">
                            Senior stylist
                          </Typography>
                          <Typography
                            fontSize="1.4rem">
                            45 mins
                          </Typography>
                          <Typography
                            component="h3"
                            fontSize="1.6rem"
                            fontWeight="700"
                            className="mt-2">
                            from ₹550
                          </Typography>
                          <Box className="select-service">
                            <input type="checkbox" />
                          </Box>
                        </label>
                      </Stack>
                    </Card>
                  </CustomTabPanel>
                  <CustomTabPanel value={serviceValue} index={1}>
                    Eyebrows & eyelashes service items
                  </CustomTabPanel>
                  <CustomTabPanel value={serviceValue} index={2}>
                    Hair removal service items
                  </CustomTabPanel>
                  <CustomTabPanel value={serviceValue} index={3}>
                    Exclusive women service items
                  </CustomTabPanel>
                </Box>
              </Grid>
            )
          }
          <Grid item xs={12} sm={1} md={1}></Grid>
          <Grid item xs={12} sm={5} md={5}>
            <Card className="card" sx={{ position: "sticky", top: "30px" }}>
              <Stack padding={3} spacing={1} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Typography
                  component="h3"
                  fontSize="2.2rem"
                  fontWeight="700"
                  marginBottom="10px">
                  Elie Lady Salon
                </Typography>
                {showTime && (
                  <Typography fontSize="16px" fontWeight="700">
                    <EventIcon sx={{ fontSize: "16px" }} alignSelf="center" /> &nbsp; Tuesday 25 March  2:30pm
                  </Typography>
                )}
                <Typography fontSize="16px">
                  <PlaceIcon sx={{ fontSize: "16px" }} alignSelf="center" /> &nbsp; Ecil - 9th St - Deira - Riggat Al Buteen - Dubai - United Arab Emirates
                </Typography>
              </Stack>

              <Stack padding={3} spacing={3}>
                <Stack justifyContent="space-between" direction="row">
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      component="h3"
                      fontSize="2rem"
                      fontWeight="600">
                      Hair Color Root touch up
                    </Typography>
                    <Typography
                      fontSize="1.4rem">
                      1 hr, 15 mins
                    </Typography>
                  </Box>
                  <Typography
                    component="h3"
                    fontSize="1.6rem"
                    fontWeight="700">
                    ₹2,000
                  </Typography>
                </Stack>
                <Stack justifyContent="space-between" direction="row">
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      component="h3"
                      fontSize="2rem"
                      fontWeight="600">
                      Blow Dry (Straight)
                    </Typography>
                    <Typography
                      fontSize="1.4rem">
                      45 mins
                    </Typography>
                  </Box>
                  <Typography
                    component="h3"
                    fontSize="1.6rem"
                    fontWeight="700">
                    ₹550
                  </Typography>
                </Stack>
                <Stack
                  justifyContent="space-between"
                  direction="row"
                  sx={{ borderTop: 1, borderColor: 'divider' }}
                  paddingTop={2}>
                  <Typography
                    component="h3"
                    fontSize="2rem"
                    fontWeight="600">
                    Total
                  </Typography>
                  <Typography
                    component="h3"
                    fontSize="2rem"
                    fontWeight="700">
                    ₹2,550
                  </Typography>
                </Stack>
              </Stack>

              <Stack padding={3} mt={2}>
                {
                  showServies && (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      className="submitBtn cs-btn"
                      fullWidth="true"
                      onClick={handleShowTime}
                    >
                      Continue
                    </Button>
                  )
                }

                {
                  showTime && (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      className="submitBtn cs-btn"
                      fullWidth="true"
                      onClick={handleClickOpenModal}
                    >
                      Book Now
                    </Button>
                  )
                }
              </Stack>
            </Card>
          </Grid>
        </Grid>

      </Grid>

    </Container>
  );
};

export default BrandHome;
