import React, { useState, useEffect } from "react";
import "../../Style.css";
import {
  TextField,
  Button,
  Container,
  Box,
  Grid,
  Checkbox,
  Stack,
  Typography,
  FormControlLabel,
  FormGroup,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { connectToStore } from "../../data/store";
import "../../DateRangePickerWithYear.css";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import Utils from "../../core/Utils";
import Loader from "../common/Loader";
import MessagePopup from "../common/MessagePopUp";
import SideBarMenuItems from "../SideBarMenuItems";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import {
  SideBarMenuNames,
  MaxDateLimit,
  EstimateStatus,
  VehicleTypes,
} from "../../core/Constants";
import { postAsync } from "../../core/serviceClient";

// Images
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import {
  OverviewPage,
  LoginPage,
  EstimateDetailsPage,
  EstimatesPage,
} from "../../core/PageConstants";

const Estimates = () => {
  document.title = "Estimates";
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const [localSettings, setLocalSettings] = useState({});
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);

  const [selectedDate, setSelectedDate] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [allEstimates, setAllEstimates] = useState([]);
  const [filteredEstimates, setFilteredEstimates] = useState([]);
  const [withoutDateRange, setWithoutDateRange] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");

  useEffect(() => {
    var select = [Utils.GetStartDate(), Utils.GetEndDate()];
    let localSetting = connectToStore();
    if (!localSetting.authenticated) {
      navigate(LoginPage.Path);
    } else {
      setLocalSettings(localSetting);
      var _searchText = "";
      var _withoutDateRange = false;
      if (!Utils.IsNullOrEmptyObject(location.state)) {
        if (!Utils.IsNullOrEmpty(location.state.searchText)) {
          _searchText = location.state.searchText;
        }
        if (!Utils.IsNullOrEmpty(location.state.withoutDateRange)) {
          _withoutDateRange = location.state.withoutDateRange;
        }
        if (!Utils.IsNullOrEmpty(location.state.startDT)) {
          select[0] = Utils.ConvertToDate(location.state.startDT);
        }
        if (!Utils.IsNullOrEmpty(location.state.endDT)) {
          select[1] = Utils.ConvertToDate(location.state.endDT);
        }
      }
      setSearchText(_searchText);
      setSelectedDate(select);
      setWithoutDateRange(_withoutDateRange);
    }
  }, []);

  const getMobileNumber = (visit) => {
    let mobileNumber = visit.isBusiness
      ? visit.receiptMobileNumber
      : visit.customerMobileNumber;
    return mobileNumber;
  };

  const getMaskMobileNumber = (visit) => {
    let mask =
      !Utils.IsNullOrEmptyObject(localSettings.user) &&
      localSettings.user.MaskCustomerDetails;
    let mobileNumber = getMobileNumber(visit);
    if (mask) {
      if (!Utils.IsNullOrEmpty(mobileNumber))
        mobileNumber = Utils.MaskMobileNumber(mobileNumber);
    }
    return mobileNumber;
  };

  const getMaskedMobileCell = (visit) => {
    var mobileNumber = getMaskMobileNumber(visit);
    if (!Utils.IsNullOrEmpty(mobileNumber))
      return (
        <Typography component="p" className="m-0">
          <CallIcon /> {mobileNumber}
        </Typography>
      );
  };
  const getEmailId = (visit) => {
    var emailId = visit.isBusiness
      ? visit.receiptEmailId
      : visit.customerEmailId;
    return emailId;
  };
  const getEmailCell = (visit) => {
    var email = getEmailId(visit);
    if (!Utils.IsNullOrEmpty(email))
      return (
        <Typography
          component="p"
          className="text-ellipsis"
          style={{ width: "200px" }}
        >
          <EmailIcon />
          {email}
        </Typography>
      );
  };

  const getCustomerName = (visit) => {
    let customerName = visit.isBusiness
      ? visit.receiptCustomerName
      : visit.customerName;
    return customerName;
  };

  const getCustomerCell = (visit) => {
    var customer = getCustomerName(visit);
    if (!Utils.IsNullOrEmpty(customer))
      return (
        <Typography component="p" className="m-0" fontWeight="600">
          <span>
            <PersonIcon /> {customer}
          </span>
        </Typography>
      );
  };

  const getEstimateCreatedDTCell = (estimate) => {
    return (
      <Typography component="p" className="m-0" fontWeight="600">
        <span>{Utils.ConvertToLocalDT(estimate.createdDT)}</span>
      </Typography>
    );
  };

  const getColorCell = (estimate) => {
    return (
      <Typography component="p" className="m-0" fontWeight="600">
        <span>{estimate.vehicleDetails.color}</span>
      </Typography>
    );
  };

  const getVehicleNumberCell = (estimate) => {
    return (
      <Typography component="p" className="m-0" fontWeight="600">
        <span>
          {getVehicleIcon(estimate.vehicleDetails.vehicleType)}{" "}
          {estimate.vehicleDetails.number}
        </span>
      </Typography>
    );
  };

  const getMakeCell = (estimate) => {
    return (
      <Typography component="p" className="m-0" fontWeight="600">
        <span>{estimate.vehicleDetails.make}</span>
      </Typography>
    );
  };

  const getModelCell = (estimate) => {
    return (
      <Typography component="p" className="m-0" fontWeight="600">
        <span>{estimate.vehicleDetails.model}</span>
      </Typography>
    );
  };

  const getVehicleIcon = (vehicleType) => {
    switch (vehicleType) {
      default:
      case VehicleTypes.FourWheeler:
        return <DirectionsCarFilledIcon />;
      case VehicleTypes.TwoWheeler:
        return <TwoWheelerIcon />;
    }
  };

  const onDateChange = async (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      if (dateArray[0]._isValid == false || dateArray[1]._isValid == false) {
        setMsgText(
          "Please select a valid date range."
        );
        setMsgOpen(true);
      }
      else {
        let dateDiff = dateArray[1].diff(dateArray[0], "days");
        if (dateDiff > MaxDateLimit) {
          setMsgText(
            "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
          );

          setMsgOpen(true);
        } else {
          setSelectedDate(dateArray);
        }
      }
    }
  };

  const getEstimates = async () => {
    if (Utils.IsNullOrEmptyObject(localSettings)) {
      return;
    }
    setIsLoading(true);
    if (withoutDateRange && !Utils.IsNullOrEmpty(searchText)) {
      let response = await postAsync("Customer/SearchEstimate", {
        BusinessId: localSettings.business.id,
        SearchText: searchText,
      });
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgOpen(true);
        setMsgText(response.errorMessage);
      } else if (response.data) {
        setFilteredEstimates(response.data);
      }
    } else if (!withoutDateRange) {
      var queryId = "AllEstimatesForPeriod";
      var parameters = [
        localSettings.business.id,
        localSettings.branch.branchId,
        Utils.GetISODateTimeString(selectedDate[0]),
        Utils.GetISODateTimeString(selectedDate[1]),
      ];
      let response = await postAsync(
        "Customer/GetCustomerEstimate",
        {},
        "",
        "GetAll",
        queryId,
        parameters
      );
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgOpen(true);
        setMsgText(response.errorMessage);
      } else if (response.data) {
        setAllEstimates(response.data);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setEstimates();
  }, [allEstimates]);

  const setEstimates = () => {
    var tempFilteredEstimates = [];
    if (!withoutDateRange && !Utils.IsNullOrEmptyArray(allEstimates)) {
      if (!Utils.IsNullOrEmpty(searchText)) {
        var upperCaseSearch = Utils.ConvertToUpperCase(searchText);
        tempFilteredEstimates = allEstimates.filter(
          (v) =>
            Utils.ConvertToUpperCase(v.serviceDesc).includes(upperCaseSearch) ||
            Utils.ConvertToUpperCase(v.receiptCustomerName).includes(
              upperCaseSearch
            ) ||
            Utils.ConvertToUpperCase(v.receiptEmailId).includes(
              upperCaseSearch
            ) ||
            Utils.ConvertToUpperCase(v.receiptMobileNumber).includes(
              upperCaseSearch
            ) ||
            Utils.ConvertToUpperCase(v.customerName).includes(
              upperCaseSearch
            ) ||
            Utils.ConvertToUpperCase(v.customerKey).includes(upperCaseSearch) ||
            Utils.ConvertToUpperCase(v.customerMobileNumber).includes(
              upperCaseSearch
            ) ||
            Utils.ConvertToUpperCase(v.customerId).includes(upperCaseSearch) ||
            Utils.ConvertToUpperCase(v.estimateNumber).includes(upperCaseSearch)
        );
      } else {
        tempFilteredEstimates = allEstimates;
      }
    }
    setFilteredEstimates(tempFilteredEstimates);
  };

  const handleSearch = async () => {
    if (Utils.IsNullOrEmpty(searchText)) {
      return;
    }
    if (!withoutDateRange) {
      setEstimates();
    } else {
      await getEstimates();
    }
  };

  const editEstimate = (estimate) => {
    navigate(EstimateDetailsPage.Path, {
      state: {
        estimate: estimate,
        isNew: false,
        startDT: Utils.GetISODateTimeString(selectedDate[0]),
        endDT: Utils.GetISODateTimeString(selectedDate[1]),
        searchText: searchText,
        navigationStack: [EstimatesPage.Path],
        onSuccess: EstimatesPage.Path,
        activeMenu: SideBarMenuNames.Estimate,
      },
    });
  };

  // Search without date range
  const searchWithoutDateRange = (e) => {
    setWithoutDateRange(e.target.checked);
  };

  const handleKeyUp = async (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      await handleSearch();
    }
  };

  // Refresh Visits
  const refreshVisits = async () => {
    await getEstimates();
  };

  useEffect(() => {
    getEstimates();
  }, [selectedDate, withoutDateRange]);

  const handleMsgClose = () => {
    setMsgOpen(false);
  };

  const newEstimate = () => {
    navigate(EstimateDetailsPage.Path, {
      state: {
        estimate: null,
        isNew: true,
        startDT: Utils.GetISODateTimeString(selectedDate[0]),
        endDT: Utils.GetISODateTimeString(selectedDate[1]),
        navigationStack: [EstimatesPage.Path],
        onSuccess: EstimatesPage.Path,
        searchText: searchText,
        activeMenu: SideBarMenuNames.Estimate,
      },
    });
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />

        <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.Estimate} />
            <Grid xs={12} className="content-sec">
              <Grid container direction="row" className="">
                <Grid flex="1" spacing={2}>
                  <Grid container className="title-sec">
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="600"
                      fontSize="2rem"
                      className="page-title"
                    >
                      Estimates
                    </Typography>

                    <Stack
                      className="cs-form text-left p-0 search-form"
                      direction="row"
                      alignSelf="center"
                      spacing={1}
                    >
                      <Grid xs>
                        <TextField
                          className="form-control font-size-14"
                          placeholder="Search here..."
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyUp={(e) => handleKeyUp(e)}
                          style={{ "min-width": "300px" }}
                        />
                      </Grid>
                      <Grid>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          className="submitBtn cs-btn"
                          fullWidth="true"
                          onClick={handleSearch}
                        >
                          <SearchIcon sx={{ fontSize: 24 }} />
                          <span className="btn-text">Search</span>
                        </Button>
                      </Grid>
                    </Stack>
                    <Stack direction="row" justifyContent="stretch">
                      <Grid paddingRight="15px" alignSelf="center">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          className="btn btn-secondary refresh-icon"
                          fullWidth="true"
                          onClick={() => refreshVisits()}
                        >
                          <RefreshIcon sx={{ fontSize: 24 }} />
                          <span className="btn-text ml-h">Refresh</span>
                        </Button>
                      </Grid>
                      <Grid alignSelf="center">
                        <Button
                          className="btn btn-secondary refresh-icon"
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth="true"
                          onClick={newEstimate}
                        >
                          <RequestQuoteIcon
                            fontSize="large"
                            style={{ color: "#2196f3" }}
                          />
                          <span className="hidden-xs ml-h">Add New</span>
                        </Button>
                      </Grid>
                    </Stack>
                  </Grid>

                  <input
                    type="checkbox"
                    className="visit-filter-check"
                    id="filter-check"
                    style={{ display: "none" }}
                  />
                  <label
                    for="filter-check"
                    size="large"
                    className="btn btn-secondary refresh-icon visit-filter-check"
                  >
                    <FilterAltOutlinedIcon />
                  </label>
                  <Grid className="card visit-filter-sec">
                    <Grid xs={12} className="font-size-14">
                      <Grid
                        container
                        xs={12}
                        direction="row"
                        className="font-size-14 visit-datepicker"
                        justifyContent="space-between"
                      >
                        <Grid xs>
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox />}
                              label="Search without date range"
                              style={{ "align-self": "end" }}
                              onChange={(e) => searchWithoutDateRange(e)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid className="text-right">
                          <DateRangePicker
                            disabled={withoutDateRange}
                            showLeadingZeros={true}
                            format="dd/MM/yyyy"
                            onChange={onDateChange}
                            maxDate={new Date()}
                            value={selectedDate}
                            locale={localSettings && localSettings.authenticated}
                            clearIcon={null}
                            calendarIcon={
                              <CalendarMonthOutlinedIcon
                                style={{
                                  "font-size": "24px",
                                  "align-self": "center",
                                  color: "#666666",
                                }}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container xs={12} className="mt-2">
                    <TableContainer component={Paper} className="visit-table">
                      <Table stickyHeader area-lang="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Estimate #</TableCell>
                            <TableCell style={{ width: "200px" }}>
                              Customer Details
                            </TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Vehicle</TableCell>
                            <TableCell>Make</TableCell>
                            <TableCell>Model</TableCell>
                            <TableCell>Color</TableCell>
                            <TableCell>Created On</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredEstimates.map((row) => (
                            <TableRow onClick={() => editEstimate(row)}>
                              <TableCell>
                                <Stack spacing={2} py={1}>
                                  {row.estimateNumber}
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  component="p"
                                  className="m-0"
                                  fontWeight="800"
                                >
                                  {row.serviceDesc}
                                </Typography>
                                {getCustomerCell(row)}
                                {getEmailCell(row)}
                                {getMaskedMobileCell(row)}
                              </TableCell>
                              <TableCell>
                                <Stack
                                  style={{ "text-align": "center" }}
                                  direction="row"
                                >
                                  {row.status === EstimateStatus.Open ? (
                                    <Chip label={row.status} color="warning" />
                                  ) : row.status === EstimateStatus.Void ? (
                                    <Chip label={row.status} color="error" />
                                  ) : (
                                    <Chip label={row.status} color="success" />
                                  )}
                                </Stack>
                              </TableCell>
                              <TableCell>{getVehicleNumberCell(row)}</TableCell>
                              <TableCell>{getMakeCell(row)}</TableCell>
                              <TableCell>{getModelCell(row)}</TableCell>
                              <TableCell>{getColorCell(row)}</TableCell>
                              <TableCell>
                                {getEstimateCreatedDTCell(row)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default Estimates;
