import "../Style.css"; import "../Responsive.css";
import React, { useEffect, useState } from "react";
import { connectToStore } from "../data/store";
import {
  HideSettingsMenus,
  SettingsMenuList,
  SettingsMenuNames,
} from "../core/Constants";
import {
  Box,
  Stack,
  Typography,
  TextField,
  Switch,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Avatar,
  Grid
} from "@mui/material";
import { Link } from "react-router-dom";
import BusinessHelper from "../core/BusinessHelper";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CallIcon from "@mui/icons-material/Call";
import Utils from "../core/Utils";
import CountryHelper from "../core/CountryHelper";
import UserHelper from "../core/UserHelper";
import {
  AdminUsersPage,
  ConsumablesPage,
  ServicesPage,
  UPISetupPage,
  UsersPage,
  SparesPage,
  BusinessSetupPage,
  BranchSetupPage,
  InvoiceReportPage,
  StatementReportPage,
  ExpensesReportPage,
  ProfitAndLossReportPage,
  JobcardsReportPage,
  EmployeeReportPage,
  StaffReportPage,
  OnHandReportPage,
  ConsumptionReportPage,
  AddPackagesPage,
} from "../core/PageConstants";
import { CanUseProductStockReport } from "../core/ModuleHelper";

const SettingsMenuItems = ({ selectedMenu }) => {
  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [productStockReportAvailable, setProductStockReportAvailable] = useState(true);
  useEffect(() => {
    var productStockReport = CanUseProductStockReport(localSettings.business);
    if (productStockReport === false || UserHelper.IsTechnician() || UserHelper.IsOperator()) {
      setProductStockReportAvailable(false);
    }
  }, []);
  return (
    <Grid xs={12} md={3}>
      <Box className="left-menu">
        {/* <Stack direction="row" alignItems="center" className="avatar-sec">
          <Avatar
            alt={localSettings.user.firstName}
            src={`${localSettings.business.logoUrl}?${Date.now()}`}
            className="profile-pic"
          />
          <Box>
            <Typography
              level="h2"
              component="h2"
              fontWeight="600"
              fontSize="2rem"
              className="h2"
            >
              {localSettings.user.firstName}
            </Typography>
            <Typography
              level="p"
              component="p"
              fontSize="14px"
              marginBottom="10px"
            >
              {localSettings.user.emailId}
              <br />
              Referral Code: <b>{localSettings.business.referralCode}</b>
            </Typography>
          </Box>
        </Stack>
        <Stack alignItems="center">
          <Link to="" className="btn btn-default plan-btn" color="#fff">
            Your usage plan ends on
            {" " + Utils.GetDate(
              localSettings.business.subscriptionEndDate,
              localSettings.country
            )}
          </Link>
        </Stack>
        <Divider /> */}
        <Stack className="left-menu-inner">
          {!HideSettingsMenus && UserHelper.IsXUVIAdmin() && (
            <>
              <Typography
                level="h3"
                component="h3"
                fontWeight="600"
                fontSize="1.5rem"
                className="h3"
              >
                {SettingsMenuNames.AdminFunctions}
              </Typography>
              <nav className="font-size-15">
                <List>
                  <Link to={AdminUsersPage.Path}>
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        className={
                          selectedMenu ==
                            SettingsMenuList.AdminFunctions.AdminUsers
                            ? "active"
                            : null
                        }
                      >
                        <ListItemText
                          primary={SettingsMenuList.AdminFunctions.AdminUsers}
                        />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        className={
                          selectedMenu ==
                            SettingsMenuList.AdminFunctions.SystemConfig
                            ? "active"
                            : null
                        }
                      >
                        <ListItemText
                          primary={SettingsMenuList.AdminFunctions.SystemConfig}
                        />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        className={
                          selectedMenu ==
                            SettingsMenuList.AdminFunctions.SystemLogs
                            ? "active"
                            : null
                        }
                      >
                        <ListItemText
                          primary={SettingsMenuList.AdminFunctions.SystemLogs}
                        />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        className={
                          selectedMenu ==
                            SettingsMenuList.AdminFunctions.BusinessConfig
                            ? "active"
                            : null
                        }
                      >
                        <ListItemText
                          primary={SettingsMenuList.AdminFunctions.BusinessConfig}
                        />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </List>
              </nav>
              <Divider />
            </>
          )}
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            Setups
          </Typography>
          <nav className="font-size-15">
            <List>
              {(UserHelper.IsXUVIAdmin() || UserHelper.IsGlobalAdmin()) && (
                <Link to={BusinessSetupPage.Path}>
                  <ListItem disablePadding>
                    <ListItemButton
                      component="a"
                      className={
                        selectedMenu ==
                          SettingsMenuList.BusinessSettings.BusinessSetup
                          ? "active"
                          : null
                      }
                    >
                      <ListItemText
                        primary={SettingsMenuList.BusinessSettings.BusinessSetup}
                      />
                      <NavigateNextIcon />
                    </ListItemButton>
                  </ListItem>
                </Link>
              )}
              {(UserHelper.IsXUVIAdmin() ||
                UserHelper.IsGlobalAdmin() ||
                UserHelper.IsBranchAdmin()) && (
                  <>
                    <Link to={BranchSetupPage.Path}>
                      <ListItem disablePadding>
                        <ListItemButton
                          component="a"
                          className={
                            selectedMenu ==
                              SettingsMenuList.BusinessSettings.BranchSetup
                              ? "active"
                              : null
                          }
                        >
                          <ListItemText
                            primary={SettingsMenuList.BusinessSettings.BranchSetup}
                          />
                          <NavigateNextIcon />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                    {!HideSettingsMenus && (
                      <>
                        <Link to="">
                          <ListItem disablePadding>
                            <ListItemButton
                              component="a"
                              className={
                                selectedMenu ==
                                  SettingsMenuList.BusinessSettings.CustomerSetup
                                  ? "active"
                                  : null
                              }
                            >
                              <ListItemText
                                primary={
                                  SettingsMenuList.BusinessSettings.CustomerSetup
                                }
                              />
                              <NavigateNextIcon />
                            </ListItemButton>
                          </ListItem>
                        </Link>
                        <Link to="">
                          <ListItem disablePadding>
                            <ListItemButton
                              component="a"
                              className={
                                selectedMenu ==
                                  SettingsMenuList.BusinessSettings.CustomerGroupSetup
                                  ? "active"
                                  : null
                              }
                            >
                              <ListItemText
                                primary={
                                  SettingsMenuList.BusinessSettings
                                    .CustomerGroupSetup
                                }
                              />
                              <NavigateNextIcon />
                            </ListItemButton>
                          </ListItem>
                        </Link>
                        {!UserHelper.IsBranchAdmin() && (
                          <Link to="">
                            <ListItem disablePadding>
                              <ListItemButton
                                component="a"
                                className={
                                  selectedMenu ==
                                    SettingsMenuList.BusinessSettings.PayoutSetup
                                    ? "active"
                                    : null
                                }
                              >
                                <ListItemText
                                  primary={
                                    SettingsMenuList.BusinessSettings.PayoutSetup
                                  }
                                />
                                <NavigateNextIcon />
                              </ListItemButton>
                            </ListItem>
                          </Link>
                        )}
                      </>
                    )}
                  </>
                )}

              <Link to={SparesPage.Path}>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    className={
                      selectedMenu == BusinessHelper.GetSpareMenuName()
                        ? "active"
                        : null
                    }
                  >
                    <ListItemText primary={BusinessHelper.GetSpareMenuName()} />

                    <NavigateNextIcon />
                  </ListItemButton>
                </ListItem>
              </Link>
              <Link to={ServicesPage.Path}>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    className={
                      selectedMenu == BusinessHelper.GetServiceMenuName()
                        ? "active"
                        : null
                    }
                  >
                    <ListItemText primary={BusinessHelper.GetServiceMenuName()} />
                    <NavigateNextIcon />
                  </ListItemButton>
                </ListItem>
              </Link>
              <Link to={ConsumablesPage.Path}>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    className={
                      selectedMenu == BusinessHelper.GetConsumeableMenuName()
                        ? "active"
                        : null
                    }
                  >
                    <ListItemText
                      primary={BusinessHelper.GetConsumeableMenuName()}
                    />
                    <NavigateNextIcon />
                  </ListItemButton>
                </ListItem>
              </Link>
              {/* Add New Package Setting */}
              {/* <Link to={AddPackagesPage.Path}>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    className={
                      selectedMenu == BusinessHelper.GetPackagesMenuName()
                        ? "active"
                        : null
                    }
                  >
                    <ListItemText
                      primary={BusinessHelper.GetPackagesMenuName()}
                    />
                    <NavigateNextIcon />
                  </ListItemButton>
                </ListItem>
              </Link> */}
              {(UserHelper.IsXUVIAdmin() ||
                UserHelper.IsGlobalAdmin() ||
                UserHelper.IsBranchAdmin()) && (
                  <>
                    {!HideSettingsMenus && (
                      <Link to="">
                        <ListItem disablePadding>
                          <ListItemButton
                            component="a"
                            className={
                              selectedMenu ==
                                SettingsMenuList.BusinessSettings.Enquiries
                                ? "active"
                                : null
                            }
                          >
                            <ListItemText
                              primary={SettingsMenuList.BusinessSettings.Enquiries}
                            />
                            <NavigateNextIcon />
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    )}
                    {CountryHelper.IsBusinessCountryIndia(
                      localSettings.country
                    ) && (
                        <Link to={UPISetupPage.Path}>
                          <ListItem disablePadding>
                            <ListItemButton
                              component="a"
                              className={
                                selectedMenu ==
                                  SettingsMenuList.BusinessSettings.UPISetup
                                  ? "active"
                                  : null
                              }
                            >
                              <ListItemText
                                primary={SettingsMenuList.BusinessSettings.UPISetup}
                              />
                              <NavigateNextIcon />
                            </ListItemButton>
                          </ListItem>
                        </Link>
                      )}
                    <Link to={UsersPage.Path}>
                      <ListItem disablePadding>
                        <ListItemButton
                          component="a"
                          className={
                            selectedMenu ==
                              SettingsMenuList.BusinessSettings.UserSetup
                              ? "active"
                              : null
                          }
                        >
                          <ListItemText
                            primary={SettingsMenuList.BusinessSettings.UserSetup}
                          />
                          <NavigateNextIcon />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                    {!HideSettingsMenus && (
                      <>
                        <Link to="">
                          <ListItem disablePadding>
                            <ListItemButton
                              component="a"
                              className={
                                selectedMenu ==
                                  SettingsMenuList.BusinessSettings.TierSetup
                                  ? "active"
                                  : null
                              }
                            >
                              <ListItemText
                                primary={
                                  SettingsMenuList.BusinessSettings.TierSetup
                                }
                              />
                              <NavigateNextIcon />
                            </ListItemButton>
                          </ListItem>
                        </Link>

                        <Link to="">
                          <ListItem disablePadding>
                            <ListItemButton
                              component="a"
                              className={
                                selectedMenu ==
                                  SettingsMenuList.BusinessSettings.AMCPlanSetup
                                  ? "active"
                                  : null
                              }
                            >
                              <ListItemText
                                primary={
                                  SettingsMenuList.BusinessSettings.AMCPlanSetup
                                }
                              />
                              <NavigateNextIcon />
                            </ListItemButton>
                          </ListItem>
                        </Link>
                      </>
                    )}
                  </>
                )}
            </List>
          </nav>
          {/* <Divider />
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            {SettingsMenuNames.BusinessReports}
          </Typography>
          <nav className="font-size-15">
            <List>
              {UserHelper.IsXUVIAdmin() ||
                UserHelper.IsGlobalAdmin() ||
                UserHelper.IsBranchAdmin() ? (
                <>
                  <Link to={InvoiceReportPage.Path}>
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        className={
                          selectedMenu == SettingsMenuList.BusinessReports.Invoice
                            ? "active"
                            : null
                        }
                      >
                        <ListItemText
                          primary={SettingsMenuList.BusinessReports.Invoice}
                        />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>

                  <Link to={StatementReportPage.Path}>
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        className={
                          selectedMenu ==
                            SettingsMenuList.BusinessReports.Statement
                            ? "active"
                            : null
                        }
                      >
                        <ListItemText
                          primary={SettingsMenuList.BusinessReports.Statement}
                        />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>

                  <Link to={ExpensesReportPage.Path}>
                    <ListItem disablePadding>
                      <ListItemButton component="a"
                        className={
                          selectedMenu ==
                            SettingsMenuList.BusinessReports.Expenses
                            ? "active" : null
                        }>
                        <ListItemText primary={SettingsMenuList.BusinessReports.Expenses} />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>

                  <Link to={ProfitAndLossReportPage.Path}>
                    <ListItem disablePadding>
                      <ListItemButton component="a"
                        className={
                          selectedMenu == SettingsMenuList.BusinessReports.ProfitAndLoss
                            ? "active" : null
                        }>
                        <ListItemText primary={SettingsMenuList.BusinessReports.ProfitAndLoss} />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  {!BusinessHelper.IsSalon() && (
                    <Link to={JobcardsReportPage.Path}>
                      <ListItem disablePadding>
                        <ListItemButton component="a"
                          className={
                            selectedMenu == SettingsMenuList.BusinessReports.JobCards
                              ? "active" : null
                          }>
                          <ListItemText primary={SettingsMenuList.BusinessReports.JobCards} />
                          <NavigateNextIcon />
                        </ListItemButton>
                      </ListItem>
                    </Link>)}

                  <Link to={BusinessHelper.IsSalon() ? StaffReportPage.Path : EmployeeReportPage.Path}>
                    <ListItem disablePadding>
                      <ListItemButton component="a"
                        className={
                          selectedMenu == SettingsMenuList.BusinessReports.Employee
                            ? "active" : null
                        }>
                        <ListItemText primary={SettingsMenuList.BusinessReports.Employee} />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>

                </>
              ) : (
                <>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        className={
                          selectedMenu ==
                            SettingsMenuList.BusinessReports.Expenses
                            ? "active"
                            : null
                        }
                      >
                        <ListItemText
                          primary={SettingsMenuList.BusinessReports.Expenses}
                        />
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </>
              )}
            </List>
          </nav> */}
          {productStockReportAvailable && (
            <>
              <Typography
                level="h3"
                component="h3"
                fontWeight="600"
                fontSize="1.5rem"
                className="h3"
              >
                {SettingsMenuNames.StockReports}
              </Typography>
              <nav className="font-size-15">
                <List>
                  <>
                    <Link to={OnHandReportPage.Path}>
                      <ListItem disablePadding>
                        <ListItemButton
                          component="a"
                          className={
                            selectedMenu === SettingsMenuList.StockReports.OnHand
                              ? "active"
                              : null
                          }
                        >
                          <ListItemText primary={SettingsMenuList.StockReports.OnHand} />
                          <NavigateNextIcon />
                        </ListItemButton>
                      </ListItem>
                    </Link>

                    <Link to={ConsumptionReportPage.Path}>
                      <ListItem disablePadding>
                        <ListItemButton
                          component="a"
                          className={
                            selectedMenu === SettingsMenuList.StockReports.Consumption
                              ? "active"
                              : null
                          }
                        >
                          <ListItemText
                            primary={SettingsMenuList.StockReports.Consumption}
                          />
                          <NavigateNextIcon />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  </>
                </List>
              </nav>
              <Divider />
            </>
          )}
          {!HideSettingsMenus && (
            <>
              {/* <Typography
                level="h3"
                component="h3"
                fontWeight="600"
                fontSize="1.5rem"
                className="h3"
              >
                {SettingsMenuNames.POSSettings}
              </Typography>
              <nav>
                <list>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton component="a" className={selectedMenu == SettingsMenuList.POSSettings.IsCashDrawerInstalled ? "active" : null}>
                        <ListItemText primary={SettingsMenuList.POSSettings.IsCashDrawerInstalled} />
                        <Switch edge="end" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton component="a" className={selectedMenu == SettingsMenuList.POSSettings.FixedPOSTerminal ? "active" : null}>
                        <ListItemText primary={SettingsMenuList.POSSettings.FixedPOSTerminal} />
                        <Switch edge="end" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton component="a" className={selectedMenu == SettingsMenuList.POSSettings.HandheldBarcodeScanner ? "active" : null}>
                        <ListItemText primary={SettingsMenuList.POSSettings.HandheldBarcodeScanner} />
                        <Switch edge="end" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton component="a" className={selectedMenu == SettingsMenuList.POSSettings.LoadProductsBeforeSearch ? "active" : null}>
                        <ListItemText primary={SettingsMenuList.POSSettings.LoadProductsBeforeSearch} />
                        <Switch edge="end" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton component="a" className={selectedMenu == SettingsMenuList.POSSettings.TerminalId ? "active" : null}>
                        <ListItemText primary={SettingsMenuList.POSSettings.TerminalId} />
                        <TextField name="terminla_id" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton component="a" className={selectedMenu == SettingsMenuList.POSSettings.IsThisDeviceIsSidecar ? "active" : null}>
                        <ListItemText primary={SettingsMenuList.POSSettings.IsThisDeviceIsSidecar} />
                        <Switch edge="end" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton component="a" className={selectedMenu == SettingsMenuList.POSSettings.SidecarIdOrOtherDevice ? "active" : null}>
                        <ListItemText primary={SettingsMenuList.POSSettings.SidecarIdOrOtherDevice} />
                        <TextField name="terminla_id" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton component="a" className={selectedMenu == SettingsMenuList.POSSettings.TerminalSoftwareUpdate ? "active" : null}>
                        <ListItemText primary={SettingsMenuList.POSSettings.TerminalSoftwareUpdate} />
                        <Switch edge="end" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </list>
              </nav>
              <Divider /> */}
              <Typography
                level="h3"
                component="h3"
                fontWeight="600"
                fontSize="1.5rem"
                className="h3"
              >
                {SettingsMenuNames.AppSettings}
              </Typography>
            </>
          )}

          <nav className="font-size-15">
            <list>
              {!HideSettingsMenus && (
                <>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        className={
                          selectedMenu ==
                            SettingsMenuList.AppSettings.BiometricAuthentication
                            ? "active"
                            : null
                        }
                      >
                        <ListItemText
                          primary={
                            SettingsMenuList.AppSettings.BiometricAuthentication
                          }
                        />
                        <Switch edge="end" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        className={
                          selectedMenu ==
                            SettingsMenuList.AppSettings.Notifications
                            ? "active"
                            : null
                        }
                      >
                        <ListItemText
                          primary={SettingsMenuList.AppSettings.Notifications}
                        />
                        <Switch edge="end" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="">
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        className={
                          selectedMenu == SettingsMenuList.AppSettings.Language
                            ? "active"
                            : null
                        }
                      >
                        <ListItemText
                          primary={SettingsMenuList.AppSettings.Language}
                        />
                        <Typography component="span">
                          {localSettings.user.language}
                        </Typography>
                        <NavigateNextIcon />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </>
              )}
              {!HideSettingsMenus && (
                <Link
                  to={CountryHelper.GetContactUsUrl(localSettings.country)}
                  target="_blank"
                >
                  <ListItem disablePadding>
                    <ListItemButton
                      component="a"
                      className={
                        selectedMenu == SettingsMenuList.AppSettings.ContactUs
                          ? "active"
                          : null
                      }
                    >
                      <ListItemText
                        primary={SettingsMenuList.AppSettings.ContactUs}
                      />
                      <CallIcon />
                    </ListItemButton>
                  </ListItem>
                </Link>
              )}
            </list>
          </nav>
        </Stack>
      </Box >
    </Grid>
  );
};

export default SettingsMenuItems;
