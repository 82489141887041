import {
  Button,
  Dialog,
  styled,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NumericTextField from "./controls/NumericTextField";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const CardComponent = ({
  open,
  close,
  isBusinessCountryUAE,
  ok,
  cardDetailsDisabled,
  nameOnCard,
  setNameOnCard,
  lastFourDigits,
  setLastFourDigits,
}) => {
  return (
    <BootstrapDialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={open}
      className=""
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={close}>
        <Typography
          level="h3"
          component="h3"
          fontWeight="600"
          fontSize="2rem"
          className="h3"
        >
          Card Details
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers className="font-size-14">
        <FormControl fullWidth>
          <TextField
            id="csProductName"
            value={nameOnCard}
            label={isBusinessCountryUAE ? "Name on Card" : "Name on Card*"}
            variant="outlined"
            onChange={(e) => setNameOnCard(e.target.value)}
          />
        </FormControl>
        <FormControl fullWidth className="mt-1">
          <NumericTextField
            value={lastFourDigits}
            setValue={setLastFourDigits}
            id="csProductName"
            label={
              isBusinessCountryUAE
                ? "Enter Last 4 Digit"
                : "Enter Last 4 Digit*"
            }
            variant="outlined"
            inputProps={{ maxLength: 4 }}
          />
        </FormControl>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          className="modal-footer"
        >
          <FormControl>
            <Button
              className="btn btn-secondary"
              variant="outlined"
              onClick={close}
            >
              Cancel
            </Button>
          </FormControl>
          <FormControl>
            <Button
              className="btn btn-primary"
              type="submit"
              variant="contained"
              onClick={ok}
              disabled={isBusinessCountryUAE ? false : cardDetailsDisabled}
            >
              Pay
            </Button>
          </FormControl>
        </Stack>
      </DialogContent>
    </BootstrapDialog>
  );
};
export default CardComponent;
