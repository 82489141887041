import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import UserHelper from "../core/UserHelper";
import { connectToStore } from "../data/store";
import {
  AddReservationsPage,
  CalanderPage,
  LoginPage,
} from "../core/PageConstants";
import SideBarMenuItems from "./SideBarMenuItems";
import { Box, Container, padding } from "@mui/system";
import { SideBarMenuNames } from "../core/Constants";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Utils from "../core/Utils";
import { postAsync } from "../core/serviceClient";
import { getBranch, getCountry } from "../data/localSettingsActions";
import { useTheme } from "@mui/material/styles";
import Loader from "./common/Loader";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ConfirmPopup from "./common/ConfirmPopup";
import SearchIcon from "@mui/icons-material/Search";
import Avatar from "@mui/material/Avatar";
import MessagePopup from "./common/MessagePopUp";
import { useDispatch } from "react-redux";
import NumericTextField from "./common/controls/NumericTextField";
import ClearIcon from "@mui/icons-material/Clear";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 60,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName?.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const ReservationsPage = () => {
  const [selectedReservation, setSelectedReservation] = useState({});
  const [serviceList, setServiceList] = useState([]);
  const [mainServiceList, setMainServiceList] = useState([]);
  const [localSettings, setLocalSettings] = useState({});
  const [countryInfo, setCountryInfo] = useState({});
  const [selectedServiceIds, setSelectedServiceIds] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedReservationDate, setSelectedReservationDate] = useState(
    Date.now()
  );
  const [servicePersonList, setServicePersonList] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const [disableConfirmBtn, setDisableConfirmBtn] = useState(true);
  const [timeSlotInterval, setTimeSlotInterval] = useState(0);
  const [serviceStartTime, setServiceStartTime] = useState(new Date());
  const [serviceEndTime, setServiceEndTime] = useState(new Date());
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [maxQuantityServiceList, setMaxQunatityServiceList] = useState([]);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [servicecategory, setservicecategory] = useState("");
  const [serviceType, setserviceType] = useState("");
  const [typeList, setTypeList] = useState([]);
  const [catList, setcatList] = useState([]);
  const [overAllTypeList, setOverAllTypeList] = useState([]);

  const hasRun = useRef(false);

  const handleAddReservation = async () => {
    setIsLoading(true);
    let result = await postAsync(
      "Customer/GetCustomer",
      {
        BusinessId: localSettings.business.businessId,
        Id: selectedReservation.customerId || customerDetails.customerId,
      },
      "",
      "Get"
    );
    let newCustomerDetails = result.data;
    let filterServiceList = mainServiceList.filter(
      (service) => selectedServiceIds[service.id]
    );
    let selectedServiceList = [];
    filterServiceList.forEach((product) => {
      let selectedstaffs = [];
      if (!Utils.IsNullOrEmptyArray(product.staff)) {
        product.staff.map((staff) => {
          servicePersonList.map((servicePerson) => {
            if (staff === servicePerson.userName) {
              selectedstaffs.push({
                Id: servicePerson.id,
                Name: servicePerson.userName,
                Status: "",
              });
            }
          });
        })
        // servicePersonList.map((servicePerson) => {
        //   if (product.staff.includes(servicePerson.userName)) {
        //     selectedstaffs.push({
        //       Id: servicePerson.id,
        //       Name: servicePerson.userName,
        //       Status: "",
        //     });
        //   }
        // });
      }
      selectedServiceList.push({
        Cost: product.cost,
        Description: product.shortDesc,
        ProductHSN: product.hsn,
        Price: product.price,
        ProductClass: product.class,
        ProductCode: product.code,
        ProductDepartment: product.department,
        ProductFineline: product.fineline,
        ProductId: product.id,
        Quantity: product.onOrderQuantity ?? 1,
        RetailPrice: product.retailPrice,
        Service: product.service,
        ServiceTime: product.serviceTime,
        Sequence: filterServiceList.length,
        TaxPercent: product.taxPercent,
        TotalPrice: (product.price * product.onOrderQuantity ?? 1).toFixed(2),
        SelectedStaffs: selectedstaffs
      });
    });


    let selectedStaffs = [];
    servicePersonList.map((servicePerson) => {
      if (personName.includes(servicePerson.userName)) {
        selectedStaffs.push({
          Id: servicePerson.id,
          Name: servicePerson.userName,
          Status: "",
        });
      }
    });

    let obj = {
      BranchId: localSettings.branch.branchId,
      BusinessId: localSettings.business.businessId,
      BusinessInitiated: true,
      CustomerId: selectedReservation.customerId || customerDetails.customerId,
      GetReservations: true,
      PassId: `${localSettings.business.businessId}${
        selectedReservation.customerId || customerDetails.customerId
      }`,
      Reservation: moment(selectedReservationDate).utc().format(),
      StartDT: moment(new Date()).utc().format(),
      EndDT: moment(new Date())
        .add(localSettings.branch.reservationMaxDays, "days")
        .utc()
        .format(),
      VisitId: selectedReservation.reservationId || customerDetails.visitId,
      SelectedStaffs: selectedStaffs,
      ServiceItems: selectedServiceList,
      SelectedStaff: newCustomerDetails,
      NewReservation: Utils.IsNullOrEmptyObject(selectedReservation)
        ? true
        : false,
    };
    if (selectedServiceList?.length === 0) {
      setIsLoading(false);
      setMsgText("Please select at least one service.");
      setMsgOpen(true);
      return;
    }
    let result2 = await postAsync("Customer/Reservation", obj, "", "Execute");

    if (!result2.error) {
      navigate(CalanderPage.Path);
    }
    setIsLoading(false);
  };

  const backNavigation = () => {
    navigate(CalanderPage.Path);
  };

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (UserHelper.CheckPermission(AddReservationsPage, navigate)) {
      var selectedReservation = Utils.IsNullOrEmptyObject(
        location.state.selectedReservation
      )
        ? {}
        : location.state.selectedReservation;
      let localSetting = connectToStore();
      if (localSetting.branch.reservationTimeInterval) {
        setTimeSlotInterval(localSetting.branch.reservationTimeInterval);
        roundToNextCurrentTime(localSetting.branch.reservationTimeInterval);
        var getStartTimeOnly = Utils.GetTimeOnly(
          localSetting.branch.serviceStartTime
        );
        var getEndTimeOnly = Utils.GetTimeOnly(
          localSetting.branch.serviceEndTime
        );
        let serviceStartTime = localSetting.branch.startTime;
        let serviceEndTime = localSetting.branch.endTime
        setServiceStartTime(serviceStartTime);
        setServiceEndTime(serviceEndTime);

        let stDt = new Date(localSetting.branch.serviceStartTime);
        let enDt = new Date(localSetting.branch.serviceEndTime);

        // var s1 = new Date();
        // s1.setHours(9, 0, 0, 0);
        // var e1 = new Date();
        // e1.setHours(21, 0, 0, 0);
        // setServiceStartTime(s1);
        // setServiceEndTime(e1);

        // let serviceStartTime = new Date().setHours(stDt.getHours(), stDt.getMinutes(), 0, 0)
        // let serviceEndTime = new Date().setTime(stDt.getHours(), stDt.getMinutes(), 0, 0);
        // setServiceStartTime(serviceStartTime);
        // setServiceEndTime(serviceEndTime);
      }
      setLocalSettings(localSetting);
      if (!Utils.IsNullOrEmptyObject(location.state?.selectedReservation)) {
        setSelectedReservation(selectedReservation);

        setSelectedReservationDate(selectedReservation.reservationDT);
        checkReservationDate(selectedReservation.reservationDT);
        if (hasRun.current) return;
        hasRun.current = true;
        if (selectedReservation?.selectedStaffs.length > 0) {
          selectedReservation.selectedStaffs.forEach((staff) => {
            setPersonName((prev) => [...prev, staff.name]);
          });
        }
      } else {
        if (!Utils.IsNullOrEmptyArray(location.state?.customerDetails)) {
          setCustomerDetails(location.state?.customerDetails);
        }
      }
      getServiceList(localSetting, selectedReservation);
      getStylistList(localSetting);
    }
  }, []);

  useEffect(() => {
    handleTotalAmount(mainServiceList, selectedServiceIds)
  }, [selectedServiceIds, mainServiceList])

  const checkReservationDate = (reservationDate) => {
    let newDate = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss");
    let formattedReservationDate = moment(reservationDate).format("YYYY-MM-DDTHH:mm:ss");
    if (formattedReservationDate > newDate) {
      setDisableConfirmBtn(false);
      return checkBranchReservationTimeInterval(reservationDate);
    } else {
      setDisableConfirmBtn(true);
    }
  }

  const checkBranchReservationTimeInterval = (reservationDate) => {
    let localSetting = connectToStore();
    // const serviceStartTime = new Date(localSetting.branch.serviceStartTime);
    // const serviceEndTime = new Date(localSetting.branch.serviceEndTime);
    // const newReservationDate = new Date(reservationDate);
    var branch = getBranch();
    const serviceStartTime = branch.startTime;
    const serviceEndTime = branch.endTime;
    const serviceStartToday = new Date();
    const serviceEndToday = new Date();
    const [starthours, startminutes, startseconds] = serviceStartTime.split(':').map(Number);
    const [endhours, endminutes, endseconds] = serviceEndTime.split(':').map(Number);
    serviceStartToday.setHours(starthours, startminutes, startseconds, 0);
    serviceEndToday.setHours(endhours, endminutes, endseconds, 0);

    const newReservationDate = new Date(reservationDate);


    const currentHours = newReservationDate.getHours();
    const currentMinutes = newReservationDate.getMinutes();

    const startHours = serviceStartToday.getHours();
    const startMinutes = serviceStartToday.getMinutes();
    const endHours = serviceEndToday.getHours();
    const endMinutes = serviceEndToday.getMinutes();


    const currentTotalMinutes = currentHours * 60 + currentMinutes;
    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;
    if (
      currentTotalMinutes >= startTotalMinutes &&
      currentTotalMinutes <= endTotalMinutes
    ) {
      setDisableConfirmBtn(false);
    } else {
      setDisableConfirmBtn(true);
    }

  }

  const roundToNextCurrentTime = (timeinterval = null) => {
    const now = new Date();

    let minutes = now.getMinutes();
    let remainder = minutes % timeSlotInterval || minutes % timeinterval;

    let roundedMinutes =
      remainder === 0
        ? minutes
        : minutes + ((timeSlotInterval || timeinterval) - remainder);

    if (roundedMinutes >= 60) {
      now.setHours(now.getHours() + 1);
      roundedMinutes = 0;
    }

    now.setMinutes(roundedMinutes, 0, 0);

    setSelectedReservationDate(now);
    checkReservationDate(now);

  }

  const handleCancelReservation = () => {
    setConfirmOpen(true);
    setConfirmText("Delete reservation?");
  }

  const handleConfirmClose = async (value) => {
    if (value === "CANCEL" || value === "CLOSE") {
      setConfirmOpen(false);
      setConfirmText("");
    }
    if (value === "OK") {
      setIsLoading(true);
      let result = await postAsync(
        "Customer/Reservation",
        {
          BranchId: localSettings.branch.branchId,
          BusinessId: localSettings.business.businessId,
          BusinessInitiated: true,
          CustomerId:
            selectedReservation.customerId || customerDetails.customerId,
          Cancel: true,
          VisitId: selectedReservation.reservationId,
          GetReservations: true,
          PassId: `${localSettings.business.businessId}${
            selectedReservation.customerId || customerDetails.customerId
          }`,
          StartDT: moment(new Date()).utc().format(),
          EndDT: moment(new Date())
            .add(localSettings.branch.reservationMaxDays, "days")
            .utc()
            .format(),
        },
        "",
        "Execute"
      );
      if (!result.error) {
        navigate(CalanderPage.Path);
        setConfirmOpen(false);
        setConfirmText("");
      }
      setIsLoading(false);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    let servicelist = serviceList;
    let filtersearch = mainServiceList.filter((item) =>
      Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setServiceList(filtersearch);
  }

  const getServiceList = async (localSetting, selectedReservation) => {
    setIsLoading(true);
    let result = await postAsync(
      "Product/GetProduct",
      {},
      "",
      "GetAll",
      "GetAllProductsByBusiness",
      [localSetting.business.id]
    );
    setIsNavigateToLogin(result.isNavigateToLogin);
    if (Utils.IsNullOrEmptyArray(result.data)) {
      return
    }
    var Service = result.data.filter(
      (item) => item.isMaterial == false && item.service == true
    );
    Service = Service.map((service) => ({
      ...service,
      onOrderQuantity: service?.onOrderQuantity ? service.onOrderQuantity : 1,
    }));
    setServiceList(Service);
    setMainServiceList(Service);
    const temTypellist = [];
          const temCatllist = [];
          Service.forEach((element) => {
            if (
              !Utils.IsNullOrEmpty(element.fineline) &&
              !temTypellist.includes(element.fineline)
            ) {
              temTypellist.push(element.fineline);
            }
            if (
              !Utils.IsNullOrEmpty(element.class) &&
              !temCatllist.includes(element.class)
            ) {
              temCatllist.push(element.class);
            }
          });
          setTypeList(temTypellist);
          setOverAllTypeList(temTypellist);
          setcatList(temCatllist);
    if (!Utils.IsNullOrEmptyArray(selectedReservation.serviceItems)) {
      selectedReservation.serviceItems.map((serviceItem) => {
        Service.map((serviceList, index) => {
          if (serviceItem.productId === serviceList.id) {
            selectedServiceIds[serviceList.id] = true;
            setTotalAmount((prev) => prev + serviceItem.price);
            if (!Utils.IsNullOrEmptyArray(serviceItem.selectedStaffs)) {
              serviceList.onOrderQuantity = serviceItem.selectedStaffs.length;
              let arr = serviceList.staff
              serviceItem.selectedStaffs.forEach((staff) => {
                arr.push(staff.name)
              })
              setServiceList((prev) => {
                const newItems = [...prev];
                newItems[index] = { ...newItems[index], staff: arr };
                return newItems;
              })
              setMainServiceList((prev) => {
                const newItems = [...prev];
                newItems[index] = { ...newItems[index], staff: arr };
                return newItems;
              })
            }
          } else {
            selectedServiceIds[serviceList.id] = selectedServiceIds[
              serviceList.id
            ]
              ? true
              : false;
          }
        });
      });
    }
    setIsLoading(false);
  };

  const handleSelectPersonChange = (event, index, j) => {
    const {
      target: { value },
    } = event;
    setMainServiceList((prev) => {
      const newItems = [...prev];
      const newStaff = [...(newItems[index].staff || [])];
      newStaff[j] = value;
      newItems[index] = { ...newItems[index], staff: newStaff };
      return newItems;
    })

  };

  const handleTotalAmount = (servicelist, serviceIds) => {
    let totalamount = 0;
    servicelist.map((service, index) => {
      if (serviceIds[service.id]) {
        totalamount += service.price * service.onOrderQuantity;
      }
    });
    setTotalAmount(totalamount);
  }

  const getStylistList = async (localSetting) => {
    setIsLoading(true);
    let result2 = await postAsync(
      "Account/GetAccount",
      {},
      "",
      "GetAll",
      "AllUsersForBusiness",
      [localSetting.business.id]
    );
    if (!Utils.IsNullOrEmptyArray(result2.data)) {
      let servicePersonlist = result2.data.filter(
        (user) =>
          (user.role === "Operator" || user.role === "Technician") &&
          user.active
      );
      setServicePersonList(servicePersonlist);
      if (servicePersonlist.length > 4) {
        const numbers = Array.from({ length: 4 }, (_, i) => i + 1);
        setMaxQunatityServiceList(numbers);
      } else {
        const numbers = Array.from(
          { length: servicePersonlist.length },
          (_, i) => i + 1
        );
        setMaxQunatityServiceList(numbers);
      }
    }
    setIsLoading(false);
  };

  const handleReservationDateChange = (newDate) => {
    if (newDate) {
      setSelectedReservationDate((prev) => {
        const prevMoment = moment(prev);
        return moment(newDate).set({
          hour: prevMoment.hours(),
          minute: prevMoment.minutes(),
        });
      });
      let newchangedate = moment(newDate).set({
        hour: moment(selectedReservationDate).hours(),
        minute: moment(selectedReservationDate).minutes(),
      });
      checkReservationDate(newchangedate);
    }
  };
  const handleReservationTimeChange = (newTime) => {
    if (newTime) {
      setSelectedReservationDate((prev) => {
        const updatedDate = moment(prev).set({
          hour: moment(newTime).hours(),
          minute: moment(newTime).minutes(),
        });

        checkReservationDate(updatedDate); // ✅ Use updated value
        return updatedDate;
      });
    }
  };

  const handleAddIcon = (service) => {
    setSelectedServiceIds((prev) => ({
      ...prev,
      [service.id]: !prev[service.id],
    }));
  };

  const handleUnCheck = (service) => {
    setSelectedServiceIds((prev) => ({
      ...prev,
      [service.id]: !prev[service.id],
    }));
  };

  const setQuantityValue = (value, index) => {
    const quantity = parseInt(value, 10) || 0;
    setMainServiceList((prev) => {
      const newList = [...prev];

      newList[index] = {
        ...newList[index],
        onOrderQuantity: quantity,
        staff: quantity === 0 ? [] : newList[index].staff,
      };

      return newList;
    });
  };

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(useDispatch);
      navigate(LoginPage.Path);
    }
  };
    const handleFilter = (value,type) => {
      setIsLoading(true);
      if(value === "" && type === "category" && serviceType === "") {
        setIsLoading(false);
        setServiceList(mainServiceList);
        setTypeList(overAllTypeList);
        return
      }
      if(value === "" && type === "serviceType" && servicecategory === "") {
       
        setServiceList(mainServiceList);
        setIsLoading(false);
        return
      }
      if(type === "category" && value !== "" && !serviceType) {
        getFilterTypes(value);
      }
      // class - servicecategory and fineline - serviceType
      let filterdone = mainServiceList.filter((item) => {
        if(type === "serviceType" && value !== "" && servicecategory) {
          return item.class == servicecategory && item.fineline == value;
        } else if (type === "category" && value !== "" && serviceType) {
          return item.class == value && item.fineline == serviceType
        } else if (type === "category" && value === "" && serviceType) {
          return item.fineline == serviceType
        } else if (type === "serviceType" && value === "" && servicecategory) {
          return item.class == servicecategory;
        } else if (type === "category" && value !== "" && !serviceType) {
          return item.class == value;
        } else if (type === "serviceType" && value !== "" && !servicecategory) {
          return item.fineline == value;
        }
      });
      setIsLoading(false);
      setServiceList(filterdone);
  };

  const getFilterTypes = (categoryValue) => {
    let newArr = [];
    mainServiceList.map((product) => {
      if(product.class === categoryValue && product.fineline) {
        newArr.push(product.fineline)
      }
    })
    setTypeList(newArr);
  }

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Grid className="container">
        <Loader open={isLoading} />
        <MessagePopup
          msgOpen={msgOpen}
          msgText={msgText}
          onMsgClose={handleMsgClose}
        />
        <ConfirmPopup
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          onConfirmClose={handleConfirmClose}
        />

        <Dialog open={msgOpen} onClose={() => setMsgOpen(false)}>
          <DialogTitle>Alert</DialogTitle>
          <DialogContent>
            <p>{msgText}</p>
          </DialogContent>
          <DialogActions>
            <Button
              className="btn btn-primary"
              variant="contained"
              onClick={() => setMsgOpen(false)}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Box>
          <Grid container m={0}>
            <SideBarMenuItems selectedTab={SideBarMenuNames.Calendar} />
            <Grid xs={12} className="content-sec">
              <Grid
                container
                direction="row"
                className=""
                sx={{ "padding-bottom": "100px" }}
              >
                <Grid flex="1">
                  <Grid container className="title-sec">
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="600"
                      fontSize="2rem"
                      className="page-title text-center"
                    >
                      <Button onClick={backNavigation} className="back-btn">
                        <NavigateBeforeIcon />
                      </Button>
                      Reservation
                    </Typography>
                  </Grid>

                  <Grid
                    xs={12}
                    style={{
                      position: "relative",
                      "min-height": "calc(100vh - 100px)",
                    }}
                    className="mt-1"
                  >
                    <Grid container m={0}>
                      <Grid spacing={2} xs={6}>
                        <Box>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                            className="font-size-14"
                          >
                            <FormControl sx={{width: "20%"}} fullWidth>
                              <InputLabel
                                id="demo-single-name-label"
                              >
                                Category
                              </InputLabel>
                              <Select
                              label="Category"
                                labelId="demo-single-name-label"
                                id="demo-single-name"
                                value={servicecategory}
                                onChange={(e) => {
                                  setservicecategory(e.target.value)
                                  handleFilter(e.target.value,"category");
                                }}
                                MenuProps={MenuProps}
                                endAdornment={
                                  servicecategory && (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => {
                                          setservicecategory("");
                                          handleFilter("", "category");
                                        }}
                                        edge="end"
                                        size="small"
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }
                              >
                                
                                {!Utils.IsNullOrEmptyArray(catList) &&
                                    catList.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                            <FormControl sx={{width: "20%"}} fullWidth>
                              <InputLabel
                                id="demo-single-name-label"
                              >
                                Type
                              </InputLabel>
                              <Select
                                label="Type"
                                labelId="demo-single-name-label"
                                id="demo-single-name"
                                value={serviceType}
                                onChange={(e) => {
                                  setserviceType(e.target.value)
                                  handleFilter(e.target.value,"serviceType");
                                }}
                                MenuProps={MenuProps}
                                endAdornment={
                                  serviceType && (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => {
                                          setserviceType("");
                                          handleFilter("", "serviceType");
                                        }}
                                        edge="end"
                                        size="small"
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }
                              >
                                {!Utils.IsNullOrEmptyArray(typeList) &&
                                    typeList.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                              </Select>
                            </FormControl>   
                            <Stack
                              direction="row"
                              alignSelf="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <form
                                method="post"
                                className="search-form"
                                onSubmit={handleSearchSubmit}
                                autoComplete="off"
                                alignSelf="center"
                                style={{ gap: "10px" }}
                              >
                                <Grid flex="1">
                                  <TextField
                                    className="form-control font-size-14"
                                    placeholder="Filter Services..."
                                    value={searchText}
                                    onChange={(e) =>
                                      setSearchText(e.target.value)
                                    }
                                    style={{ "min-width": "260px" }}
                                  />
                                </Grid>
                                <Grid>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className="submitBtn btn-primary h-100"
                                    fullWidth="true"
                                    title="Search"
                                  >
                                    <SearchIcon sx={{ fontSize: 24 }} />
                                  </Button>
                                </Grid>
                              </form>
                            </Stack>
                          </Stack>

                          <Stack direction="column" spacing={2}>
                            {!Utils.IsNullOrEmptyArray(serviceList) &&
                              serviceList.map((service) => (
                                <Card className="card white-bg">
                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    alignContent="center"
                                  >
                                    <Stack direction="column" spacing={2}>
                                      <CardContent sx={{ flex: 1 }}>
                                        <Stack
                                          direction="row"
                                          justifyContent="space-between"
                                        >
                                          <Stack spacing={0.5}>
                                            <Typography
                                              variant="h4"
                                              fontSize="2rem"
                                              fontWeight="700"
                                            >
                                              {service.shortDesc}
                                            </Typography>
                                            <Typography
                                              fontSize="1.6rem"
                                              variant="h4"
                                            >
                                              {service.description}
                                            </Typography>
                                            <Typography
                                              variant="h6"
                                              color="text.secondary"
                                            >
                                              {Utils.GetCurrency(
                                                service.price,
                                                !Utils.IsNullOrEmptyObject(
                                                  countryInfo
                                                )
                                                  ? countryInfo
                                                  : getCountry(),
                                                2
                                              )}
                                            </Typography>
                                          </Stack>
                                        </Stack>
                                      </CardContent>
                                    </Stack>
                                    <Box style={{ margin: "0 1rem" }}>
                                      {selectedServiceIds[service.id] ? (
                                        <IconButton
                                          onClick={() => handleUnCheck(service)}
                                        >
                                          <CheckCircleIcon
                                            fontSize="large"
                                            style={{ fill: "green" }}
                                          />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          onClick={() => handleAddIcon(service)}
                                        >
                                          <AddIcon fontSize="large" />
                                        </IconButton>
                                      )}
                                    </Box>
                                  </Stack>
                                </Card>
                              ))}
                          </Stack>
                        </Box>
                      </Grid>

                      <Grid xs={6}>
                        <Box sx={{ marginLeft: 2 }}>
                          <Card className="card white-bg">
                            <Stack direction="column" spacing={2}>
                              <CardContent sx={{ flex: 1 }}>
                                <Box>
                                  <Stack className="text-center" spacing={0.6}>
                                    <Avatar
                                      sx={{
                                        alignSelf: "center",
                                        marginBottom: "6px",
                                      }}
                                    >
                                      {customerDetails.UserName}
                                    </Avatar>
                                    <Typography variant="h4" fontWeight="bold">
                                      {selectedReservation.customerName ||
                                        customerDetails.userName ||
                                        customerDetails.UserName}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      color="text.secondary"
                                    >
                                      Profit:{" "}
                                      {selectedReservation.profit ||
                                        customerDetails.profit}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      color="text.secondary"
                                    >
                                      Email:{" "}
                                      {selectedReservation.emailId ||
                                        customerDetails.emailId}
                                    </Typography>
                                  </Stack>
                                  <Grid marginTop={1} spacing={2} container>
                                    <Grid
                                      item
                                      spacing={2}
                                      xs={6}
                                      className="font-size-14"
                                    >
                                      <FormControl fullWidth>
                                        <LocalizationProvider
                                          dateAdapter={AdapterMoment}
                                        >
                                          <DatePicker
                                            label="Reservation Date"
                                            value={moment(
                                              selectedReservationDate
                                            )}
                                            minDate={moment()}
                                            onChange={
                                              handleReservationDateChange
                                            }
                                          />
                                        </LocalizationProvider>
                                      </FormControl>
                                    </Grid>
                                    <Grid
                                      item
                                      spacing={2}
                                      xs={6}
                                      className="font-size-14"
                                    >
                                      {/* <FormControl fullWidth>
                                        <LocalizationProvider
                                          dateAdapter={AdapterMoment}
                                        >
                                          <TimePicker
                                            label="Reservation Time"
                                            onChange={handleReservationTimeChange}
                                            minutesStep={timeSlotInterval}
                                            // minTime={serviceStartTime}
                                            // maxTime={serviceEndTime}
                                            // shouldDisableTime={(value, view) => {
                                            //   const hour = value.hour();
                                            //   const minute = value.minute();
                                            //   if (hour < (new Date(serviceStartTime)).getHours() || hour > (new Date(serviceEndTime)).getHours()) return true;
                                            //   if (view === "minutes" && minute % 30 !== 0) return true;
                                            //   if (hour < (new Date(serviceStartTime)).getMinutes() || hour > (new Date(serviceEndTime)).getMinutes()) return true;

                                            //   return false;
                                            // }}
                                            value={moment(
                                              selectedReservationDate
                                            )}
                                          />
                                        </LocalizationProvider>
                                      </FormControl> */}
                                      <FormControl fullWidth>
                                        <LocalizationProvider
                                          dateAdapter={AdapterMoment}
                                        >
                                          <TimePicker
                                            label="Reservation Time"
                                            value={moment.utc(
                                              selectedReservationDate
                                            ).local()}
                                            onChange={
                                              handleReservationTimeChange
                                            }
                                            minutesStep={timeSlotInterval}
                                            minTime={moment(
                                              serviceStartTime,
                                              "HH:mm:ss"
                                            )}
                                            maxTime={moment(
                                              serviceEndTime,
                                              "HH:mm:ss"
                                            )}
                                            shouldDisableTime={(
                                              value,
                                              view
                                            ) => {
                                              const localTime = moment(value).local();
                                              const hour = localTime.hour();
                                              const minute = localTime.minute();

                                              const startHour = moment(
                                                serviceStartTime,
                                                "HH:mm:ss"
                                              ).hour();
                                              const endHour = moment(
                                                serviceEndTime,
                                                "HH:mm:ss"
                                              ).hour();
                                              const startMinute = moment(
                                                serviceStartTime,
                                                "HH:mm:ss"
                                              ).minute();
                                              const endMinute = moment(
                                                serviceEndTime,
                                                "HH:mm:ss"
                                              ).minute();

                                              if (
                                                hour < startHour ||
                                                hour > endHour
                                              )
                                                return true;
                                              if (
                                                view === "minutes" &&
                                                minute % timeSlotInterval !== 0
                                              )
                                                return true;
                                              if (
                                                hour === startHour &&
                                                minute < startMinute
                                              )
                                                return true;
                                              if (
                                                hour === endHour &&
                                                minute > endMinute
                                              )
                                                return true;
                                              return false;
                                            }}
                                          />
                                        </LocalizationProvider>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                  <Box className="mt-2">
                                    <Typography
                                      component="h4"
                                      className="title-lbl mb-1"
                                    >
                                      Selected Service(s)
                                    </Typography>
                                    {mainServiceList.map((service, index) => {
                                      if (selectedServiceIds[service.id]) {
                                        return (
                                          <Grid
                                            direction="row"
                                            alignItems="center"
                                            container
                                            className="card p-1 mb-1"
                                          >
                                            <Stack
                                              xs={12}
                                              direction="row"
                                              justifyContent="space-between"
                                              className="w-100"
                                            >
                                              <Box>
                                                <Typography
                                                  variant="h3"
                                                  fontSize="2rem"
                                                  fontWeight="bold"
                                                >
                                                  {service.shortDesc}
                                                </Typography>
                                                <Typography
                                                  variant="h6"
                                                  fontSize="1.6rem"
                                                  color="text.secondary"
                                                >
                                                  {service.department}
                                                </Typography>
                                              </Box>
                                              <Typography
                                                sx={{ textAlign: "end" }}
                                                variant="h5"
                                                fontWeight="bold"
                                              >
                                                {Utils.GetCurrency(
                                                  service.price,
                                                  !Utils.IsNullOrEmptyObject(
                                                    countryInfo
                                                  )
                                                    ? countryInfo
                                                    : getCountry(),
                                                  2
                                                )}
                                              </Typography>
                                            </Stack>
                                            <Grid
                                              marginTop={2}
                                              direction="row"
                                              alignItems="center"
                                              container
                                            >
                                              <Grid xs={2}>
                                                {servicePersonList?.length >
                                                  0 &&
                                                  maxQuantityServiceList.length >
                                                    0 && (
                                                    <FormControl fullWidth>
                                                      <NumericTextField
                                                        id="ProductQuantity"
                                                        label="Quantity"
                                                        variant="outlined"
                                                        value={
                                                          service?.onOrderQuantity ||
                                                          0
                                                        }
                                                        setValue={(e) => {
                                                          if (e > 25) return;
                                                          setQuantityValue(
                                                            e,
                                                            index
                                                          );
                                                        }}
                                                        inputProps={{
                                                          maxLength: 25,
                                                        }}
                                                        sx={{
                                                          "& .MuiInputBase-input":
                                                            {
                                                              fontSize: "2rem",
                                                              height: "1em",
                                                            },
                                                        }}
                                                      />
                                                    </FormControl>
                                                  )}
                                              </Grid>
                                              {service?.onOrderQuantity > 0 &&
                                                [
                                                  ...Array(
                                                    service?.onOrderQuantity
                                                  ),
                                                ].map((_, j) => (
                                                  <Grid xs={5} p={1}>
                                                    {servicePersonList?.length >
                                                      0 && (
                                                      <FormControl fullWidth>
                                                        <InputLabel
                                                          shrink={true}
                                                          id="demo-single-name-label"
                                                          sx={{
                                                            "font-size": "14px",
                                                            background:
                                                              "#ffffff",
                                                          }}
                                                        >
                                                          Select Staff
                                                        </InputLabel>
                                                        <Select
                                                          labelId="demo-single-name-label"
                                                          id="demo-single-name"
                                                          value={
                                                            service.staff[j]
                                                              ? service.staff[j]
                                                              : []
                                                          }
                                                          input={
                                                            <OutlinedInput label="Name" />
                                                          }
                                                          onChange={(e) => {
                                                            handleSelectPersonChange(
                                                              e,
                                                              index,
                                                              j
                                                            );
                                                          }}
                                                          MenuProps={MenuProps}
                                                          sx={{
                                                            fontSize: "1.5rem",
                                                          }}
                                                        >
                                                          {servicePersonList.map(
                                                            (servicePerson) => (
                                                              <MenuItem
                                                                key={
                                                                  servicePerson.id
                                                                }
                                                                value={
                                                                  servicePerson.userName
                                                                }
                                                                style={getStyles(
                                                                  servicePerson.userName,
                                                                  personName,
                                                                  theme
                                                                )}
                                                              >
                                                                {
                                                                  servicePerson.userName
                                                                }
                                                              </MenuItem>
                                                            )
                                                          )}
                                                        </Select>
                                                      </FormControl>
                                                    )}
                                                  </Grid>
                                                ))}
                                            </Grid>
                                          </Grid>
                                        );
                                      }
                                    })}
                                  </Box>
                                  <Divider sx={{ padding: 2 }} />
                                  <Stack
                                    pt={2}
                                    direction="row"
                                    justifyContent="space-between"
                                    spacing={2}
                                  >
                                    <Typography
                                      sx={{ marginTop: 2 }}
                                      variant="h5"
                                      fontWeight="bold"
                                    >
                                      Total
                                    </Typography>
                                    <Typography
                                      sx={{ marginTop: 2 }}
                                      variant="h5"
                                      fontWeight="bold"
                                    >
                                      {Utils.GetCurrency(
                                        totalAmount,
                                        !Utils.IsNullOrEmptyObject(countryInfo)
                                          ? countryInfo
                                          : getCountry(),
                                        2
                                      )}
                                    </Typography>
                                  </Stack>
                                </Box>
                              </CardContent>
                            </Stack>
                          </Card>

                          <Stack direction="row" className="fixed-btn">
                            <Grid
                              container
                              className="container"
                              justifyContent="space-between"
                            >
                              {!Utils.IsNullOrEmptyObject(
                                selectedReservation
                              ) && (
                                <Button
                                  className="btn btn-primary"
                                  variant="contained"
                                  onClick={handleCancelReservation}
                                >
                                  Cancel Reservation
                                </Button>
                              )}
                              <Stack
                                direction="row"
                                sx={{ marginLeft: "auto" }}
                                spacing={1}
                                className="font-size-14"
                              >
                                <Button
                                  className="btn btn-secondary"
                                  variant="contained"
                                  fullWidth
                                  onClick={backNavigation}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  className="btn btn-primary"
                                  variant="contained"
                                  fullWidth
                                  onClick={handleAddReservation}
                                  disabled={disableConfirmBtn}
                                >
                                  Confirm
                                </Button>
                              </Stack>
                            </Grid>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default ReservationsPage;
